module.exports = {

    SET_INVESTOR_DETAILS: "SET_INVESTOR_DETAILS",

    UPDATING_INVESTOR_OVERVIEW: "UPDATING_INVESTOR_OVERVIEW",
    UPDATING_INVESTOR_OVERVIEW_SUCCESS: "UPDATING_INVESTOR_OVERVIEW_SUCCESS",
    UPDATING_INVESTOR_OVERVIEW_FAIL: "UPDATING_INVESTOR_OVERVIEW_FAIL",

    UPDATING_INVESTOR_THESIS: "UPDATING_INVESTOR_THESIS",
    UPDATING_INVESTOR_THESIS_SUCCESS: "UPDATING_INVESTOR_THESIS_SUCCESS",
    UPDATING_INVESTOR_THESIS_FAIL: "UPDATING_INVESTOR_THESIS_FAIL",

    GET_COMPANY_LIST: "GET_COMPANY_LIST",
    GET_COMPANY_LIST_SUCCESS: "GET_COMPANY_LIST_SUCCESS",
    GET_COMPANY_LIST_FAIL: "GET_COMPANY_LIST_FAIL",

    GET_INVESTED_COMPANY_LIST: "GET_INVESTED_COMPANY_LIST",
    GET_INVESTED_COMPANY_LIST_SUCCESS: "GET_INVESTED_COMPANY_LIST_SUCCESS",
    GET_INVESTED_COMPANY_LIST_FAIL: "GET_INVESTED_COMPANY_LIST_FAIL",

    ADD_INVESTED_COMPANY: "ADD_INVESTED_COMPANY",
    ADD_INVESTED_COMPANY_SUCCESS: "ADD_INVESTED_COMPANY_SUCCESS",
    ADD_INVESTED_COMPANY_FAIL: "ADD_INVESTED_COMPANY_FAIL",

    DELETE_INVESTED_COMPANY: "DELETE_INVESTED_COMPANY",
    DELETE_INVESTED_COMPANY_SUCCESS: "DELETE_INVESTED_COMPANY_SUCCESS",
    DELETE_INVESTED_COMPANY_FAIL: "DELETE_INVESTED_COMPANY_FAIL",

    FETCHING_COMPANY_INVESTORS: "FETCHING_COMPANY_INVESTORS",
    FETCHING_COMPANY_INVESTORS_SUCCESS: "FETCHING_COMPANY_INVESTORS_SUCCESS",
    FETCHING_COMPANY_INVESTORS_FAIL: "FETCHING_COMPANY_INVESTORS_FAIL",

    FETCHING_AUTHORIZED_INVESTORS: "FETCHING_AUTHORIZED_INVESTORS",
    FETCHING_AUTHORIZED_INVESTORS_SUCCESS: "FETCHING_AUTHORIZED_INVESTORS_SUCCESS",
    FETCHING_AUTHORIZED_INVESTORS_FAIL: "FETCHING_AUTHORIZED_INVESTORS_FAIL",

    SELECT_INVESTOR: "SELECT_INVESTOR",
    DELETE_SELECTED_INVESTOR: "DELETE_SELECTED_INVESTOR",
    CLEAR_SELECTED_INVESTORS: "CLEAR_SELECTED_INVESTORS",
    
    CLEAR: "CLEAR"
};