import React, { Component } from "react";
import "./style.css";

class PostInput extends Component {
  componentDidMount() {
    // this.textarea.focus();
    //autosize(this.textarea);
  }

  componentWillReceiveProps(nextprops){
    if(nextprops.value === '' && this.props.value !== ''){
      document.getElementById('txt').style.height = 'auto'
    }
  }

  onChange = (e) => {
      this.props.submit(e);
      document.getElementById('txt').style.height = `${document.getElementById('txt').scrollHeight}px`;
  };

  render() {
    return (
      <textarea
        id="txt"
        ref={c => (this.textarea = c)}
        rows={this.props.rows}
        className="post_input"
        placeholder={this.props.placeholder}
        type={this.props.type}
        value={this.props.value}
        onChange={this.onChange.bind(this)}
      >
        {this.props.chlidren}
      </textarea>
    );
  }
}

PostInput.defaultProps = {
  placeholder: "",
  rows: 0,
  children: null
};

export { PostInput };