import { ALERT, POP_ALERT } from '../types/common';

export function showAlert(dispatch, type, text, duration = 2000, doNotHide=false) {
    
    dispatch({ type: ALERT.SHOW, payload: { type: type, text: text, duration, doNotHide } });
    if(!doNotHide){
        setTimeout(() => {
            dispatch({ type: ALERT.HIDE });
        }, duration)
    }
    
}

export function showPopupAlert(dispatch, alertType, text, title, description="", customClass='', popAlertEvent='') {
    dispatch({ 
        type: POP_ALERT.SHOW, 
        alertType, // alert type
        text, // alert text
        title, // alert title
        description,
        customClass,
        popAlertEvent,
    });
}