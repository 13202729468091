import React, { Component } from "react";
import { getPreview } from "../../../../libs/textFormat"
import "./styles.css";

class EventItem extends Component {
  render() {
    return (
      <div className="d-flex flex-row m-b10">
        {this.getDate(this.props.month, this.props.date)}
        {this.getDetails(this.props.name, this.props.place, this.props.eventId)}
      </div>
    );
  }

  getDate = (month, date) => {
    return (
      <div className="event_item_date d-flex justify-content-center align-items-center">
        <div className="m-t8" style={{ width: '57px' }}>
          <h3 className="uppercase font_14 font_blue font_medium get_zero_space text-center">
            {month.slice(0, 3)}
          </h3>
          <p
            style={{ marginTop: "-5px" }}
            className="font_22 font_dark_gray_two font_bold event_item_date_date  text-center get_zero_space"
          >
            {date}
          </p>
        </div>
      </div>
    );
  };
  getDetails = (name, place, eventId) => {
    return (
      <div className="m-l15 m-t10" style={{ width: "100%" }}>
        <a style={{ textDecoration: 'none' }} href={`/event/${eventId}`} >
          <h4 className="summary_box_name font_15 font_medium font_dark_gray_two">
            {getPreview(name,40)}
          </h4>
        </a>
        <p>{place}</p>
      </div>
    );
  };
}

EventItem.defaultProps = {
  month: null,
  date: null,
  name: null,
  place: null
};
export { EventItem };
