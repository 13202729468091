import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store';
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/index.css';
import ReactGA from 'react-ga';
import { PersistGate } from "redux-persist/lib/integration/react";


import { unregister as registerServiceWorker } from './registerServiceWorker'

ReactGA.initialize(process.env.REACT_APP_GA_TAG);

const { persistor, store } = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
        <Router>
            <App />
        </Router>
        </PersistGate>
    </Provider>, document.getElementById('root'));
registerServiceWorker();