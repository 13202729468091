import React, { useState } from "react";
import { POST_SHARE_HOST } from "./../../config/server";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share";
import "./index.css";
import { SHARED_PLATFORMS, PUBLIC_PENDING_ACTIONS } from "./../../types/common";
import { trackEvent } from './../../actions/eventTracking';
import {EVENT_ACTION} from './../../types/eventTracking';

const getPostPreviewUrl = (postId, platform) => {

  if (localStorage.getItem('userId')) {
    return POST_SHARE_HOST + "/post/preview/" + postId + '/' + localStorage.getItem('userId') + '/' + platform;
  } else {
    console.log('URL ', POST_SHARE_HOST + "/post/preview/" + postId + '/' + localStorage.getItem('openNewsfeedToken') + '/' + platform);
    return POST_SHARE_HOST + "/post/preview/" + postId + '/' + localStorage.getItem('openNewsfeedToken') + '/' + platform;
  }
};

const PostShareOptionsPopup = (props) => {
  const [shareTitle, setShareTitle] = useState('');

  const emailSubject = 'I shared an article with you on Leafwire';
  const emailBody = props.linkPreviewTitle ?
    '\n\nI thought you would find this interesting:\n\n' + props.linkPreviewTitle + '\n\n' + window.location.origin + '/post/' + props.postId :
    '\n\nI thought you would find this interesting:\n\n' + window.location.origin + '/post/' + props.postId;

  /* data project - event trcking */
  const trackEvents = (platform) => {
    if(props.page) {
      const eventPayload = {
        user_id: (localStorage.getItem('userId')) ? localStorage.getItem('userId') : localStorage.getItem('openNewsfeedToken'),
        page:  props.page, 
        section:  props.section,  
        element_type: 'BUTTON',
        element_id: null,
        action_type: EVENT_ACTION.CLICK,
        post_share_platform: platform,
        post_id: props.postId
      }
      props.dispatch(trackEvent(eventPayload));
    }
  }
/* END : data project - event trcking */


  return (
    <div className="share-options-wrapper post-options-popup" id={`${props.idPrefix}_popup`}>
      <div className="share-options">
        {<div className="option-name">{shareTitle && shareTitle}</div>}
        <div className="share-option">
          <FacebookShareButton url={getPostPreviewUrl(props.postId, SHARED_PLATFORMS.FACEBOOK, props.user, props.page, props.section, props.dispatch)} onShareWindowClose={() => props.track(SHARED_PLATFORMS.FACEBOOK)}>
            <i id={`${props.idPrefix}_facebook`} className="fab fa-facebook-square" onClick={()=>trackEvents('FACEBOOK')} onMouseEnter={() => setShareTitle('Via Facebook')} onMouseOut={() => setShareTitle('')} />
          </FacebookShareButton>
        </div>

        <div className="share-option">
          <TwitterShareButton
            url={getPostPreviewUrl(props.postId, SHARED_PLATFORMS.TWITTER, props.user, props.page, props.section, props.dispatch)}
            title={props.linkPreviewTitle ? props.linkPreviewTitle + '\nFor more #CannabisNews visit @Leafwire' : 'Leafwire For more #CannabisNews visit @Leafwire'}
            // via='Leafwire'
            // hashtags={['Leafwire', 'CannabisNews']}
            onShareWindowClose={() => props.track(SHARED_PLATFORMS.TWITTER)}
          >
            <i id={`${props.idPrefix}_twitter`} className="fab fa-twitter" onClick={()=>trackEvents('TWITTER')} onMouseEnter={() => setShareTitle('Via Twitter')} onMouseOut={() => setShareTitle('')} />
          </TwitterShareButton>
        </div>

        <div className="share-option">
          <LinkedinShareButton url={getPostPreviewUrl(props.postId, SHARED_PLATFORMS.LINKEDIN, props.user, props.page, props.section, props.dispatch)} onShareWindowClose={() => props.track(SHARED_PLATFORMS.LINKEDIN)}>
            <i id={`${props.idPrefix}_linkedin`} className="fab fa-linkedin" onClick={()=>trackEvents('LINKEDIN')} onMouseEnter={() => setShareTitle('Via LinkedIn')} onMouseOut={() => setShareTitle('')} />
          </LinkedinShareButton>
        </div>

        <div className="share-option">
          <a href={`mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`}>
            <i id={`${props.idPrefix}_mail`} className="fas fa-envelope" onClick={()=>{ 
                                                                            trackEvents('EMAIL');
                                                                            props.track.bind(this, SHARED_PLATFORMS.EMAIL);
                                                                          }} onMouseEnter={() => setShareTitle('Via Email')} onMouseOut={() => setShareTitle('')} />
          </a>
        </div>

        <div className="share-option">
          <i
            id={`${props.idPrefix}_lwMessage`}
            className="fas fa-comment-alt"
            onClick={() => {
              if (window.location) {
                const url = getPostPreviewUrl(props.postId, SHARED_PLATFORMS.MESSAGING_PANEL) + '\n';
                if (props.isPublicFeed) {
                  props.openLogin(PUBLIC_PENDING_ACTIONS.P_SHARE_MSG, url)
                } else if (props.openSharePost && props.toggleZindex) {
                  props.toggleZindex();
                  props.openSharePost(url)
                } else {
                  console.error('openSharePost or toggleZindex not found')
                }
                trackEvents('MESSAGE');
                props.track(SHARED_PLATFORMS.MESSAGING_PANEL);
              }
            }}
            onMouseEnter={
              () => setShareTitle('Via Leafwire message')
            }
            onMouseOut={
              () => {
                setShareTitle('');
              }
            }
          />
        </div>
      </div>
    </div>
  );
}

export { PostShareOptionsPopup, getPostPreviewUrl };
