module.exports = {
    CLEAR_ACTION: 'CLEAR_ACTION',
    CLEAR_CONNECTION_ACTION: 'CLEAR_CONNECTION_ACTION',
    CLEAR_COMPANY_PROFILE: 'CLEAR_COMPANY_PROFILE',

    FETCH_PROFILE_BEGIN: 'FETCH_PROFILE_BEGIN',
    FETCH_PROFILE_SUCCESS: 'FETCH_PROFILE_SUCCESS',
    FETCH_PROFILE_FAILURE: 'FETCH_PROFILE_FAILURE',
    FETCH_PROFILE_NOT_FOUND: 'FETCH_PROFILE_NOT_FOUND',

    FETCH_PUBLIC_PROFILE_BEGIN: 'FETCH_PUBLIC_PROFILE_BEGIN',
    FETCH_PUBLIC_PROFILE_SUCCESS: 'FETCH_PUBLIC_PROFILE_SUCCESS',
    FETCH_PUBLIC_PROFILE_FAILURE: 'FETCH_PUBLIC_PROFILE_FAILURE',

    GETTING_PUBLIC_PROFILE_IMAGE: "GETTING_PUBLIC_PROFILE_IMAGE",
    GETTING_PUBLIC_PROFILE_IMAGE_SUCCESS: "GETTING_PUBLIC_PROFILE_IMAGE_SUCCESS",
    GETTING_PUBLIC_PROFILE_IMAGE_FAIL: "GETTING_PUBLIC_PROFILE_IMAGE_FAIL",

    GETTING_PUBLIC_COMPANY_IMAGE: "GETTING_PUBLIC_COMPANY_IMAGE",
    GETTING_PUBLIC_COMPANY_IMAGE_SUCCESS: "GETTING_PUBLIC_COMPANY_IMAGE_SUCCESS",
    GETTING_PUBLIC_COMPANY_IMAGE_FAIL: "GETTING_PUBLIC_COMPANY_IMAGE_FAIL",

    REMOVE_PUBLIC_COMPANY_IMAGE_SUCCESS:"REMOVE_PUBLIC_COMPANY_IMAGE_SUCCESS",

    GETTING_EMPLOYEE_PROFILE_IMAGE_SUCCESS: "GETTING_EMPLOYEE_PROFILE_IMAGE_SUCCESS",

    GETTING_CURRENT_INVESTORS_PROFILE_IMAGE_SUCCESS: "GETTING_CURRENT_INVESTORS_PROFILE_IMAGE_SUCCESS",

    GETTING_INVESTED_COMPANIES_PROFILE_IMAGE_SUCCESS: "GETTING_INVESTED_COMPANIES_PROFILE_IMAGE_SUCCESS",

    GETTING_PRODUCT_SHOWCASE_IMAGE_SUCCESS: "GETTING_PRODUCT_SHOWCASE_IMAGE_SUCCESS",

    ADD_CONNECTION : "ADD_CONNECTION",
    ADD_CONNECTION_SUCCESS : "ADD_CONNECTION_SUCCESS",
    ADD_CONNECTION_FAIL : "ADD_CONNECTION_FAIL",

    NEWSFEED_ADD_CONNECTION_SUCCESS : "NEWSFEED_ADD_CONNECTION_SUCCESS",

    ACCEPT_CONNECTION : "ACCEPT_CONNECTION",
    ACCEPT_CONNECTION_SUCCESS : "ACCEPT_CONNECTION_SUCCESS",
    ACCEPT_CONNECTION_FAIL : "ACCEPT_CONNECTION_FAIL",

    REJECT_CONNECTION : "REJECT_CONNECTION",
    REJECT_CONNECTION_SUCCESS : "REJECT_CONNECTION_SUCCESS",
    REJECT_CONNECTION_FAIL : "REJECT_CONNECTION_FAIL",

    FETCH_LAST_POSTS: "FETCH_LAST_POSTS",
    FETCH_LAST_POSTS_SUCCESS: "FETCH_LAST_POSTS_SUCCESS",
    FETCH_LAST_POSTS_FAIL: "FETCH_LAST_POSTS_FAIL",
    CLEAR_LAST_POSTS: "CLEAR_LAST_POSTS",

    FETCH_KEY_EMP_FUNDRAISING: 'FETCH_KEY_EMP_FUNDRAISING',
    FETCH_KEY_EMP_FUNDRAISING_SUCCESS: 'FETCH_KEY_EMP_FUNDRAISING_SUCCESS',
    FETCH_KEY_EMP_FUNDRAISING_FAILURE: 'FETCH_KEY_EMP_FUNDRAISING_FAILURE',

    CLEAR_PROFILE: "CLEAR_PROFILE",
    CLEAR_FUNDRAISING_DETAILS: "CLEAR_FUNDRAISING_DETAILS",

    CLEAR_REQUESTED_CONNECTIONS: "CLEAR_REQUESTED_CONNECTIONS",
    SET_PROFILE_VIEW_LOCATION: 'SET_PROFILE_VIEW_LOCATION',

    CREATE_VANITY_URL: "CREATE_VANITY_URL",
    CREATE_VANITY_URL_SUCCESS: "CREATE_VANITY_URL_SUCCESS",
    VANITY_URL_EXIST: 'That URL is already taken - please choose a different one.',

    UPDATE_CONNECTION_COUNT_IN_USER_PROFILE: "UPDATE_CONNECTION_COUNT_IN_USER_PROFILE",

    FETCH_COMPANY_PEOPLE_BEGIN: 'FETCH_COMPANY_PEOPLE_BEGIN',
    FETCH_COMPANY_PEOPLE_SUCCESS: 'FETCH_COMPANY_PEOPLE_SUCCESS',
    FETCH_COMPANY_PEOPLE_FAILURE: 'FETCH_COMPANY_PEOPLE_FAILURE',
    FETCH_COMPANY_PEOPLE_NOT_FOUND: 'FETCH_COMPANY_PEOPLE_NOT_FOUND',

    FETCH_COMPANY_PEOPLE_COUNT_BEGIN: 'FETCH_COMPANY_PEOPLE_COUNT_BEGIN',
    FETCH_COMPANY_PEOPLE_COUNT_SUCCESS: 'FETCH_COMPANY_PEOPLE_COUNT_SUCCESS',
    FETCH_COMPANY_PEOPLE_COUNT_FAILURE: 'FETCH_COMPANY_PEOPLE_COUNT_FAILURE',
    FETCH_COMPANY_PEOPLE_COUNT_NOT_FOUND: 'FETCH_COMPANY_PEOPLE_COUNT_NOT_FOUND',

    REMOVE_COMPANY_PEOPLE_BEGIN: 'REMOVE_COMPANY_PEOPLE_BEGIN',
    REMOVE_COMPANY_PEOPLE_SUCCESS: 'REMOVE_COMPANY_PEOPLE_SUCCESS',
    REMOVE_COMPANY_PEOPLE_FAILURE: 'REMOVE_COMPANY_PEOPLE_FAILURE',
    REMOVE_COMPANY_PEOPLE_NOT_FOUND: 'REMOVE_COMPANY_PEOPLE_NOT_FOUND',

    IS_USER_COMPANY_ADMIN_CHECK_BEGIN: 'IS_USER_COMPANY_ADMIN_CHECK_BEGIN',
    IS_USER_COMPANY_ADMIN_CHECK_SUCCESS: 'IS_USER_COMPANY_ADMIN_CHECK_SUCCESS',
    IS_USER_COMPANY_ADMIN_CHECK_FAILURE: 'IS_USER_COMPANY_ADMIN_CHECK_FAILURE',
    IS_USER_COMPANY_ADMIN_CHECK_NOT_FOUND: 'IS_USER_COMPANY_ADMIN_CHECK_NOT_FOUND',
};