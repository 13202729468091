import React from 'react'
import classNames from "classnames";

const ModalFormGroup = ({ className, children }) =>{

    let formGroupStyle = classNames(className , "modal-form-group") 
    
    return(
        <div className={formGroupStyle} >{children}</div>
    )
}

export { ModalFormGroup }