import React from 'react'
import "./index.css";

const ToolTipPopup = ({ tooltip, IS_PREVIEW = false, close, openToolTip, toolTipPopupStyle }) => {
    const { HEAD_LINE, TEXT_BODY, IMAGE_URL, b64 } = tooltip
    return (
        <React.Fragment>
            <div className="position-relative">
                <div className={toolTipPopupStyle ? `popup-tooltip-wrapper ${toolTipPopupStyle}` : 'popup-tooltip-wrapper'}>
                    {!IS_PREVIEW && openToolTip && <div className="close" onClick={close}><i className="fas fa-times"></i></div>}
                    <div className='tooltip__head-line'>{HEAD_LINE}</div>
                    <div className='tooltip__body'>{TEXT_BODY}</div>
                    {IMAGE_URL && IMAGE_URL !== '' && <img className='tooltip__image' src={IS_PREVIEW ? (b64 ? b64 : IMAGE_URL) : IMAGE_URL} alt="popupImage" />}
                </div>
            </div>
        </React.Fragment>
    )
}

export default ToolTipPopup;