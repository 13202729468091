import source from './../components/input/countrySelect/source';
import moment from 'moment';
import { ADMIN_SECTION, USER_EMOTION_TYPES, WARNING_DISCLAIMER_TERMS_STATUS } from '../types/common';
import likeReaction from "./../assets/img/reactions/like.png";
import likeReactionHollow from "./../assets/img/reactions/likeHollow.svg";
import loveReaction from "./../assets/img/reactions/love.png";
import hahaReaction from "./../assets/img/reactions/haha.png";
import sadReaction from "./../assets/img/reactions/sad.png";
import angryReaction from "./../assets/img/reactions/angry.png";
import insightfulReaction from "./../assets/img/reactions/insightful.png";
import wowReaction from "./../assets/img/reactions/wow.png";
import React from 'react';
import _ from 'underscore';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

const states = {
    'AL': 'Alabama',
    'AK': 'Alaska',
    'AZ': 'Arizona',
    'AR': 'Arkansas',
    'AA': 'Armed Forces America',
    'AE': 'Armed Forces Europe',
    'AP': 'Armed Forces Pacific',
    'CA': 'California',
    'CO': 'Colorado',
    'CT': 'Connecticut',
    'DE': 'Delaware',
    'DC': 'District of Columbia',
    'FL': 'Florida',
    'GA': 'Georgia',
    'HI': 'Hawaii',
    'ID': 'Idaho',
    'IL': 'Illinois',
    'IN': 'Indiana',
    'IA': 'Iowa',
    'KS': 'Kansas',
    'KY': 'Kentucky',
    'LA': 'Louisiana',
    'ME': 'Maine',
    'MD': 'Maryland',
    'MA': 'Massachusetts',
    'MI': 'Michigan',
    'MN': 'Minnesota',
    'MS': 'Mississippi',
    'MO': 'Missouri',
    'MT': 'Montana',
    'NE': 'Nebraska',
    'NV': 'Nevada',
    'NH': 'New Hampshire',
    'NJ': 'New Jersey',
    'NM': 'New Mexico',
    'NY': 'New York',
    'NC': 'North Carolina',
    'ND': 'North Dakota',
    'OH': 'Ohio',
    'OK': 'Oklahoma',
    'OR': 'Oregon',
    'PA': 'Pennsylvania',
    'RI': 'Rhode Island',
    'SC': 'South Carolina',
    'SD': 'South Dakota',
    'TN': 'Tennessee',
    'TX': 'Texas',
    'UT': 'Utah',
    'VT': 'Vermont',
    'VA': 'Virginia',
    'WA': 'Washington',
    'WV': 'West Virginia',
    'WI': 'Wisconsin',
    'WY': 'Wyoming',

    'AB': 'Alberta',
    'BC': 'British Columbia',
    'MB': 'Manitoba',
    'NB': 'New Brunswick',
    'NL': 'Newfoundland and Labrador',
    'NT': 'Northwest Territories',
    'NS': 'Nova Scotia',
    'NU': 'Nunavut',
    'ON': 'Ontario',
    'PE': 'Prince Edward Island',
    'QC': 'Quebec',
    'SK': 'Saskatchewan',
    'YT': 'Yukon',
};

export const coRegStates = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    // 'Armed Forces America',
    // 'Armed Forces Europe',
    // 'Armed Forces Pacific',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    // 'District of Columbia',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
];


export function rawMarkup(text) {
    return { __html: text };
}

export function getPreview(text, limit, ellipsis = true) {
    if (text) {
        return text.substring(0, limit ? limit : undefined);
    } else {
        return null;
    }
}

export function getHostName(link) {
    if (!(link.includes("http://") || link.includes("https://"))) {
        if (!link.includes("www.")) {
            return link
        }
        let newLink = link.split("www.")
        if (newLink.length > 1) {
            link = 'https://' + newLink[1]
        } else {
            return link
        }
    }
    let parser = document.createElement('a');
    parser.href = link;
    return parser.host
}

export function getLinkUrl(link) {
    if (!(link.includes("http://") || link.includes("https://"))) {
        return 'http://' + link;
    }
    return link;
}

/**
 * this function removes text after last ','
 * @param address
 * @constructor
 * @return string
 */
export function RemoveCountryFromAddress(address = '') {
    if (address.split(',').length > 1) {
        let nAddress = address.split(',');
        nAddress.pop();
        let rt = '';
        rt = nAddress.join();
        return rt
    } else {
        return address
    }
}

export function getHashFromObject(object = {}) {
    let stringToHash = JSON.stringify(object);
    let hash = 0;
    if (stringToHash.length === 0) {
        return hash;
    } else {
        for (let i = 0; i < stringToHash.length; i++) {
            let char = stringToHash.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    }
}

export function getCountryFromCode(countryCode = '', isCode = false) {
    if (!countryCode || countryCode === '') {
        return ''
    }
    if (!isCode) {
        // return countryCode +''
    }
    let countryList = [];
    source.forEach((item, i) => {
        if (item[1].toLowerCase().indexOf(countryCode.toLowerCase()) > -1) {
            countryList.push(item);
        }
    });
    if (countryList.length > 0) {
        return (countryList[0][0]);
    } else {
        return ''
    }
}

export function getStateFromCode(stateCode = '') {
    if (states[stateCode]) {
        return states[stateCode];
    }
    return stateCode
}

export function getStateCodeFromState(stateName = '') {
    try {
        let data = Object.entries(states);
        let code = '';
        data.some(state => {
            if (state && state.includes(stateName)) {
                code = state[0]
            }
            return (state && state.includes(stateName));
        });
        return code
    } catch (ex) {
        return ''
    }
}

export function getAddress(address = '', state = '', country = '') {
    address = address ? address : '';
    if (state) {
        address = address ? (address + ', ' + state) : (state);
    }

    if (country) {
        address = address ? (address + ', ' + getCountryFromCode(country)) : getCountryFromCode(country);
    }
    return address;
}

export function isCountryOnAddress(address = '', country = '', state = '') {
    address = address + '';
    if (state) {
        address = address + ', ' + state + '';
    }
    country = country + '';
    if (address.toLowerCase().includes(country.toLowerCase())) {
        return RemoveCountryFromAddress(address);
    }
    return address;
}

function atou(b64) {
    return decodeURIComponent(escape(atob(b64)));
}

function utoa(data) {
    return btoa(unescape(encodeURIComponent(data)));
}

export function encodeUrlParams(params = {}) {
    if (typeof params !== 'object') {
        return ''
    }
    return (utoa(JSON.stringify(params)));
}

export function decodeUrlParams(encodedString = '') {
    if (typeof encodedString !== 'string' || encodedString === '') {
        return {}
    }
    let res;
    try {
        res = JSON.parse(atou(encodedString.split('/').pop()))
    } catch (ex) {
        res = {};
    }
    return res
}

export const resetUrl = (url = '', prot = '') => {
    if (!(url.includes('https://') || url.includes('http://'))) {
        url = prot ? prot + url : 'https://' + url
    }
    return url
};

export function debounced(delay, fn) {
    let timerId;
    return function (...args) {
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            fn(...args);
            timerId = null;
        }, delay);
    }
}

export const formatText = (text) => {
    const sep = (text.split('\n'));
    let t = '';
    sep.forEach(text => {
        t = t.concat(text + '<br/>');
        return text + '<br/>'
    });
    return t
};

export const getUtmValues = (utmString) => {
    const variablesInURL = utmString.split('?');
    const parametersInURL = variablesInURL.length > 1 ? variablesInURL[1].split('&') : [];
    const UTM_VARIABLES = {};
    parametersInURL.forEach(variable => {
        const variables = (variable.split('='));
        if (variables.includes('utm_source')) {
            UTM_VARIABLES.UTM_SOURCE = variables[1]
        }
        if (variables.includes('utm_medium')) {
            UTM_VARIABLES.UTM_MEDIUM = variables[1]
        }
        if (variables.includes('utm_campaign')) {
            UTM_VARIABLES.UTM_CAMPAIGN = variables[1]
        }
    });
    return UTM_VARIABLES
};

function ReplaceAt(input, search, replace, start, end) {
    return input.slice(0, start)
        + input.slice(start, end).replace(search, replace)
        + input.slice(end);
}

export const formatRichText = (richText, isPost = false) => {
    try {
        let formatedText = '', blockText = ''
        const { blocks, entityMap } = richText;
        if (blocks && blocks.length > 0) {
            blocks.forEach(block => {
                blockText = block.text;
                if (block.entityRanges.length > 0) {
                    let start, end;
                    let taglength = 0, nameslength = 0;
                    let urlLength = 0, urlWordLength = 0;
                    block.entityRanges.forEach((entity, i) => {
                        if (entityMap[entity.key].type === 'mention') {
                            const { _id, name, TYPE } = entityMap[entity.key].data.mention;
                            const checkStringHasSpecialChars = /[^A-Za-z 0-9]/g;
                            const searchRegex = checkStringHasSpecialChars.test(name) ? name : new RegExp(name);
                            const tag = `<a class='mentionTagLink' href=${window.location.origin}/${TYPE === 1 ? 'user-profile/' : 'profile/company/'}${_id}>${name}</a>`

                            taglength += tag.length
                            nameslength += name.length

                            if (i > 0) {
                                start = ((taglength - tag.length) - (nameslength - name.length)) + entity.offset
                                end = start + entity.length
                            } else {
                                start = entity.offset;
                                end = entity.offset + entity.length
                            }
                            blockText = ReplaceAt(blockText, searchRegex, tag, start, end);
                        } else if (entityMap[entity.key].type === 'LINK') {
                            const { url, href } = entityMap[entity.key].data;
                            const URL = `<a class='weblink' target='_blank' href=${href}>${url}</a>`

                            urlLength += URL.length
                            urlWordLength += url.length

                            if (i > 0) {
                                start = ((urlLength - URL.length) - (urlWordLength - url.length)) + entity.offset
                                end = start + entity.length
                            } else {
                                start = entity.offset;
                                end = entity.offset + entity.length
                            }

                            blockText = ReplaceAt(blockText, url, URL, start, end);
                        }
                    })
                }
                blockText += '\n'
                formatedText += blockText;
            })
        } else {
            console.error('empty rich text')
            return '';
        }

        const replaceURL = /((https?|ftps?):\/\/[^"<\s]+)(?![^<>]*?>|[^<>]*?<\/)/;
        return isPost ? formatedText.replace(new RegExp(replaceURL), '') : formatedText.replace(new RegExp(replaceURL), url => '<a target="_blank" href="' + url + '">' + url + '</a>')

    } catch (exception) {
        console.log('error occured on formatRichText[textFormat.]', exception);
        return '';
    }
};

export const resizeTextArea = (event, minRows = 2, maxRows = 10) => {

    try {
        let numberOfLineBreaks = (event.target.value.match(/\n/g) || []).length;
        if (numberOfLineBreaks < minRows) {
            event.target.rows = minRows
        } else if (numberOfLineBreaks >= minRows && numberOfLineBreaks < maxRows) {
            event.target.rows = numberOfLineBreaks + 1;
        } else {
            event.target.rows = maxRows
        }
    } catch (ex) {
        console.error(ex)
    }
};

export const orderConversations = (conversations, conversationId, message, newConversation = null) => {

    try {
        const index = conversations.findIndex(item => item._id === conversationId);
        if (index > 0) {
            const newConversations = conversations.map(conversation => {
                return { ...conversation }
            });
            newConversations.find(conversation => conversation._id === conversationId).RECENT_MESSAGE = message;
            if (newConversation) {
                newConversations.find(conversation => conversation._id === conversationId).LAST_MESSAGE_SEEN = newConversation.LAST_MESSAGE_SEEN;
                newConversations.find(conversation => conversation._id === conversationId).LAST_MESSAGE_PRO_STATUS = newConversation.LAST_MESSAGE_PRO_STATUS;
            }
            newConversations.unshift(newConversations[index]);
            newConversations.splice(index + 1, 1);
            return newConversations;
        } else if (index === 0) {
            const newConversations = conversations.map(conversation => {
                return { ...conversation }
            });
            newConversations.find(conversation => conversation._id === conversationId).RECENT_MESSAGE = message;
            if (newConversation) {
                newConversations.find(conversation => conversation._id === conversationId).LAST_MESSAGE_SEEN = newConversation.LAST_MESSAGE_SEEN;
                newConversations.find(conversation => conversation._id === conversationId).LAST_MESSAGE_PRO_STATUS = newConversation.LAST_MESSAGE_PRO_STATUS;
            }
            return newConversations;
        } else if (newConversation) {
            return [newConversation, ...conversations]
        }
    } catch (ex) {
        console.error(ex)
    }
    return conversations
};

export const deleteSpecificConversation = (conversations, conversationId) => {
    try {
        const index = conversations.findIndex(item => item._id === conversationId);
        const newConversations = conversations.map(conversation => {
            return { ...conversation }
        });
        newConversations.splice(index, 1);
        return newConversations;
    } catch (ex) {
        console.error(ex);
    }
    return conversations;
};

export const seenConversation = (conversations, conversationId, index, loggedInUserId) => {
    try {
        const newConversations = conversations.map(conversation => {
            return { ...conversation }
        });
        if (newConversations[index]) {
            newConversations[index].LAST_MESSAGE_SEEN.IS_SEEN = true
        }
        return newConversations;
    } catch (ex) {
        console.error(ex);
    }
};

export const removeNotifiedConversationId = (notifiedConversationIdSet, conversationId) => {
    try {
        const newNotificationIdSet = notifiedConversationIdSet;
        const index = newNotificationIdSet.indexOf(conversationId);
        if (index !== -1) {
            newNotificationIdSet.splice(index, 1);
        }
        return newNotificationIdSet;
    } catch (ex) {
        console.error(ex);
    }
};

export const removeUnSeenTopNavConversationId = (existingTopNavUnSeenConvIdSet, conversationId) => {
    try {
        const newTopNavUnSeenConvIdSet = existingTopNavUnSeenConvIdSet;
        const index = newTopNavUnSeenConvIdSet.indexOf(conversationId);
        if (index !== -1) {
            newTopNavUnSeenConvIdSet.splice(index, 1);
        }
        return newTopNavUnSeenConvIdSet;
    } catch (ex) {
        console.error(ex);
    }
};

export const orderPendingConnections = (pendingConnections, userId) => {
    try {
        const index = pendingConnections.findIndex(item => item._id === userId);
        if (index >= 0) {
            pendingConnections.splice(index, 1);
        }
        return pendingConnections;
    } catch (ex) {
        console.error(ex);
    }
};

export const formatCurrency = amount => {
    if (amount && !isNaN(amount)) {
        let formattedCurrency = Number(amount)
        return formattedCurrency % 1 !== 0 ? formattedCurrency.toFixed(2) : formattedCurrency
    } else {
        return null
    }
};

export const getLabelFromTimezoneData = (offset, timezone) => {

    if (offset > 0) {
        if (offset >= 10) {
            offset = '+' + offset
        } else {
            offset = '+0' + offset
        }
    } else if (offset === 0) {
        offset = ""
    } else {
        if (offset > -10) {
            offset = offset.replace("-", "-0")
        }
    }
    if (offset === '+00:00') {
        offset = "(UTC)";
    } else {
        offset = "(UTC" + offset.replace(".", ":") + ")";
    }
    return (offset + " " + timezone);
};

export const getDataFromTimezone = (str = "") => {

    try {

        let values = str.split(") ");
        let offset = values[0].replace("(UTC", "");
        let data = {
            offset: offset === "" ? '+00:00' : offset,
            timezone: values[1]
        };
        return (data);

    } catch (ex) {
        console.error('timezone error', ex);
        return (null);
    }
};

export const concatDateTime = (date, time) => {
    try {
        let t = time.split(':');
        return moment(date).utcOffset(0).set({ "hour": t[0], "minute": t[1] }).toISOString();
    } catch (ex) {
        return null;
    }
};

export const splitDateTime = (timestamp) => {
    try {
        return ({
            date: moment(timestamp).utcOffset(0).format('YYYY-MM-DD[T]00:00:00.000[Z]'),
            time: moment(timestamp).utcOffset(0).format('HH:mm:ss')
        });
    } catch (ex) {
        return ({
            date: '',
            time: ''
        });
    }
};

export function numberWithCommas(x = '') {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function isTextContainsUrl(text) {
    if (new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(text)) {
        return true;
    } else {
        return false;
    }
}

export function createTextLinks(text) {
    return (text || "").replace(
        /([^\S]|^)((((https?):\/\/)|(www\.))(\S+))/gi,
        function (match, space, url) {
            var hyperlink = url;
            if (!hyperlink.match('^(https?://)')) {
                hyperlink = 'http://' + hyperlink;
            }
            return space + '<a href="' + hyperlink + '" target="_blank">' + url + '</a>';
        }
    );
}

export function GetTextLinks(text) {
    return text.match(/([^\S]|^)(((https?):\/\/|(www\.))(\S+))/gi);
}

export function createTextLinksForTitle(url, title) {
    return '<a href="' + url + '" target="_blank">' + title + '</a>';
}

export function getConnectionParams(hash = '') {
    try {
        let sections = hash.split('&page=');
        if (sections.length > 0) {
            return ({
                q: sections[0].replace('q=', ''),
                page: sections[1] ? parseInt(sections[1]) : 1
            })
        }
        return ({ q: '', page: 1 })
    } catch (ex) {
        return ({
            q: '',
            page: 1
        })
    }
}

export function getMobileNumber(str = '') {
    return str.replace(' ', '')
}

export function formatMobileNumber(str = '') {
    try {
        if (str.length > 3) {
            str = str.replace(' ', '');
            let areaCode = str.slice(0, 3);
            let mNumb = str.slice(3, str.length);
            return areaCode + ' ' + mNumb

        } else {
            return str
        }
    } catch (ex) {
        return str
    }
}

export function getMyReaction(active) {
    let myReaction = active === USER_EMOTION_TYPES.LIKE
        ? { text: 'Like', className: 'like', img: likeReaction }
        : active === USER_EMOTION_TYPES.HEART
            ? { text: 'Love', className: 'love', img: loveReaction }
            : active === USER_EMOTION_TYPES.INSIGHTFUL
                ? { text: 'Insightful', className: 'love', img: insightfulReaction }
                : active === USER_EMOTION_TYPES.HAHA
                    ? { text: 'Haha', className: 'haha', img: hahaReaction }
                    : active === USER_EMOTION_TYPES.WOW
                        ? { text: 'Wow', className: 'love', img: wowReaction }
                        : active === USER_EMOTION_TYPES.SAD
                            ? { text: 'Sad', className: 'sad', img: sadReaction }
                            : active === USER_EMOTION_TYPES.ANGRY
                                ? { text: 'Angry', className: 'angry', img: angryReaction }
                                : { text: 'Like', className: '', img: likeReactionHollow };
    return myReaction
}

export const checkSourceParameterforEmail = (utmString) => {
    const variablesInURL = utmString.split('?');
    const parametersInURL = variablesInURL.length > 1 ? variablesInURL[1].split('&') : [];
    let result = false;
    if (parametersInURL.includes('s=e')) {
        result = true;
    } else {
        result = false;
    }
    return result
};

//mention tag helper functions

export const defaultTagUrl = (el) => {
    let url = '/'
    let { memberType, type, userId } = el;
    memberType = memberType.toString()
    if (type === "mention") {
        switch (memberType) {
            case '1': // user
                url = url + 'user-profile/' + userId
                break;
            case '2': // company
                url = url + 'profile/company/' + userId
                break;
            default:
                break;
        }
    }
    return url
}

export const generateRichTextDisplay = (richTextObj, tagOpenNewTab = true, getTagUrl, replaceLinks) => {
    try {
        if (typeof richTextObj === 'object' && richTextObj.line) {
            let groupedByLine = _.groupBy(richTextObj.elements, 'line')
            return (
                <>
                    {richTextObj && Object.keys(richTextObj.line).map(li => {
                        if (groupedByLine[li]) {
                            let elementArr = _.groupBy(groupedByLine[li], 'type');
                            let indexArr = _.groupBy(elementArr.mention, 'startIndex');
                            let re = new RegExp(String.fromCharCode(160), "g");
                            let originalText = richTextObj.line[li].text.replace(re, " ").replace('<br>', '');
                            let lastIndex = 0
                            return (<div key={li} style={{ whiteSpace: 'pre-wrap' }}>{
                                Object.keys(indexArr).map((k, i) => {
                                    let newPrivText = originalText.slice(lastIndex, k);
                                    lastIndex = k;
                                    let newEndText = '';
                                    if (i + 1 === Object.keys(indexArr).length) {
                                        newEndText = originalText.slice(k, richTextObj.line[li].endIndex + 1)
                                        lastIndex = richTextObj.line[li].endIndex
                                    }
                                    let tagLink = ''
                                    if (getTagUrl === 'default') {
                                        tagLink = tagLink + defaultTagUrl(indexArr[k][0])
                                    } else if (getTagUrl && typeof getTagUrl === 'function') {
                                        try {
                                            tagLink = tagLink + getTagUrl(indexArr[k][0])
                                        } catch (ex) {
                                            console.error('error in getTagUrl function')
                                            tagLink = '/'
                                        }
                                    } else {
                                        tagLink = '/'
                                    }

                                    return (
                                        <React.Fragment key={k}>
                                            {getLinkText(newPrivText)}
                                            <Link
                                                to={tagLink}
                                                target={tagOpenNewTab ? '_blank' : '_self'}
                                            >{indexArr[k][0].userName}</Link>
                                            {getLinkText(newEndText)}
                                        </React.Fragment>
                                    )
                                })
                            }</div>)
                        } else {
                            return richTextObj.line[li].text ? (
                                <div key={li}>{getLinkText(richTextObj.line[li].text)}</div>) : (
                                <div key={li}><br /></div>)
                        }
                    })}
                </>
            )
        } else if (typeof richTextObj === 'string' && richTextObj !== '') {
            // eslint-disable-next-line
            let returnText = richTextObj.replace(/&nbsp;/g, ' ').replace('<br>', '').replaceAll('<!-- -->', '').replace(/<span([^\<]*?)<\/span>/g, '').replace('&lt;br&gt;', '').replace('&amp;', '&');
            return (
                <>
                    <div key={'0'} style={{ whiteSpace: 'pre-wrap' }}>{returnText}</div>
                </>
            )
        } else {
            return (
                <></>
            )
        }
    } catch (ex) {
        return <></>
    }

}

export const getLinkText = (text) => {
    try {
        let tca = text.split(' ');
        let returnHtml = tca.map((tc, i) => {
            let isLast = i + 1 === tca.length
            if (isTextContainsUrl(tc)) {
                return (<React.Fragment key={generateTagId(5)}><a href={getLinkUrl(tc)} rel="noopener noreferrer"
                    target="_blank">{tc}</a>{!isLast ? ' ' : ''}
                </React.Fragment>)
            } else {
                return (<React.Fragment
                    key={generateTagId(5)}>{`${tc.replace(/(?:\r\n|\r|\n)/g, '').replace('<br>', '')}${!isLast ? ' ' : ''}`}</React.Fragment>)
            }
        })
        return returnHtml
    } catch (ex) {
        console.error(ex)
        return text
    }
}

export const generateRichText = (richTextObj) => {
    if (typeof richTextObj === 'object' && richTextObj.line) {
        let groupedByLine = _.groupBy(richTextObj.elements, 'line');
        const specialCharArray = ['&nbsp;', '<br>', '<!-- -->', '<span', '&lt;br&gt;', '&amp;'];

        return (
            <>
                {richTextObj && Object.keys(richTextObj.line).map(li => {
                    if (groupedByLine[li]) {
                        let elementArr = _.groupBy(groupedByLine[li], 'type');
                        let indexArr = _.groupBy(elementArr.mention, 'startIndex');
                        let re = new RegExp(String.fromCharCode(160), "g");
                        let originalText = richTextObj.line[li].text.replace(re, " ").replace('<br>', '');
                        let lastIndex = 0
                        return (<div key={li} style={{ whiteSpace: 'pre-wrap' }}>{
                            Object.keys(indexArr).map((k, i) => {
                                let newPrivText = originalText.slice(lastIndex, k);
                                lastIndex = k;
                                let newEndText = '';
                                if (i + 1 === Object.keys(indexArr).length) {
                                    newEndText = originalText.slice(k, richTextObj.line[li].endIndex)
                                    lastIndex = richTextObj.line[li].endIndex
                                }
                                return (
                                    <React.Fragment key={k}>{newPrivText}<span
                                        data-type={indexArr[k][0].type}
                                        data-user-name={indexArr[k][0].userName}
                                        data-user-image={indexArr[k][0].userImage}
                                        data-user-id={indexArr[k][0].userId}
                                        data-member-type={indexArr[k][0].memberType}
                                        data-user-status={indexArr[k][0].userStatus}
                                        data-tag-id={indexArr[k][0].tagId}
                                        contentEditable={false}
                                        spellCheck={false}
                                        readOnly={true}
                                        style={{ backgroundColor: '#91c2de' }}
                                    >{indexArr[k][0].userName}&#8203;</span>{newEndText}
                                    </React.Fragment>
                                )
                            })
                        }</div>)
                    } else if (richTextObj.line[li].text && new RegExp(specialCharArray.join("|")).test(richTextObj.line[li].text)) {
                        let returnText = (richTextObj.line[li].text).replace(/&nbsp;/g, ' ').replace('<br>', '').replace(/<!-- -->/g, '').replace(/<span([^<]*?)<\/span>/g, '').replace('&lt;br&gt;', '').replace('&amp;', '&');
                        return (
                            <>
                                <div key={'0'} style={{ whiteSpace: 'pre-wrap' }}>{returnText}</div>
                            </>
                        )
                    }
                    else {
                        return richTextObj.line[li].text ? (<div key={li}>{richTextObj.line[li].text}</div>) : (
                            <div key={li}><br /></div>)
                    }
                })}
            </>
        )
    } else if (typeof richTextObj === 'string' && richTextObj !== '') {
        // eslint-disable-next-line
        let returnText = richTextObj.replace(/&nbsp;/g, ' ').replace('<br>', '').replaceAll('<!-- -->', '').replace(/<span([^\<]*?)<\/span>/g, '').replace('&lt;br&gt;', '').replace('&amp;', '&');
        return (
            <>
                <div key={'0'} style={{ whiteSpace: 'pre-wrap' }}>{returnText}</div>
            </>
        )
    } else {
        return (
            <></>
        )
    }
}

export const generateRichTextObbject = (initText, callback) => {
    try {
        const div = document.createElement("div");
        let text = document.createTextNode(initText.replace('\n', ''));
        div.appendChild(text);
        getRichTextObject(div, callback)
    } catch (error) {
        console.error('richtext generate error')
        callback(null)
    }
}

export const getRichTextObject = (element, callBack) => {

    const div = element
    let htmlTextRows = [];
    let isNewText = false

    const firstDivIndex = element.innerHTML.indexOf('<div')
    if (firstDivIndex === 0) {

    } else if (firstDivIndex === -1) { //newly added text one row
        htmlTextRows = [element.innerHTML]
        isNewText = true
    } else { //newly added text multiple row
        htmlTextRows = [element.innerHTML.substring(0, firstDivIndex)]
    }

    htmlTextRows = [
        ...htmlTextRows,
        ...Array.from(div.querySelectorAll("div"))
            // .filter(p => p.textContent !== "")
            .map(p => p.innerHTML.replace(/&nbsp;/g, ' ').replace(/<!-- -->/g, '').replace('<br>', '').replace('&lt;br&gt;', '').replace('&amp;', '&'))
    ]
    let returnRichText = {
        line: {},
        elements: []
    }
    let lineStartIndex = 0;
    htmlTextRows.forEach((li, i = 0) => {
        let indices = [];
        // eslint-disable-next-line
        let regex = /<span([^\<]*?)<\/span>/g, result
        while ((result = regex.exec(li))) {
            indices.push({
                text: result[0],
                textSize: result[0].length,
                index: result.index
            });
        }

        let richTextCharCount = 0;
        let userTags = indices.map(tg => {
            const div = document.createElement("div");
            div.insertAdjacentHTML("beforeend", tg.text);
            let tags = Array.from(div.querySelectorAll("span")).filter(p => p.textContent !== "").map(p => {
                return { ...p.dataset }
            });

            let singleTag = {
                type: tags[0].type,
                line: i + 1,
                startIndex: tg.index - richTextCharCount,
                userId: tags[0].userId,
                userName: tags[0].userName,
                userImage: tags[0].userImage,
                userStatus: tags[0].userStatus,
                memberType: tags[0].memberType,
                tagId: tags[0].tagId
            }
            richTextCharCount = richTextCharCount + tg.textSize
            return singleTag
        })

        returnRichText.line[i + 1] = {
            text: li.replace(regex, ''),
            startIndex: lineStartIndex,
            endIndex: li.replace(regex, '').length > 0 ? ((lineStartIndex + li.replace(regex, '').length) - 1) : lineStartIndex
        };
        returnRichText.elements = [...returnRichText.elements, ...userTags]
        lineStartIndex = lineStartIndex + li.replace(regex, '').length
        if (i + 1 === htmlTextRows.length) {
            callBack(returnRichText, isNewText)
        }
    });
}

export const getIndicesOf = (searchStr, str, caseSensitive = true) => {
    let searchStrLen = searchStr.length;
    if (searchStrLen === 0) {
        return [];
    }
    let startIndex = 0, index, indices = [];
    if (!caseSensitive) {
        str = str.toLowerCase();
        searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index);
        startIndex = index + searchStrLen;
    }
    return indices;
}

export const replaceStringOccurence = (string, subStr, num) => {
    if (!string.includes(subStr)) {
        return string;
    }
    let start = 0, end = subStr.length;
    let occurences = 0;
    for (; ; end < string.length) {
        if (string.slice(start, end) === subStr) {
            occurences++;
        }
        ;
        if (occurences === num) {
            return string.slice(0, start) + string.slice(end, string.length);
        }
        ;
        end++;
        start++;
    }
    // eslint-disable-next-line
    return string;
}

export const generateTagId = (length) => {
    let result = [];
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result.push(characters.charAt(Math.floor(Math.random() *
            charactersLength)));
    }
    return result.join('');
}

export const isValidRichTextObj = (obj) => {
    return (typeof obj === 'object' && obj && obj.line && obj.elements)
}

export const EmitrichTextEditorEvent = (editorId, data) => {
    let event = new CustomEvent("rich_text_editor", { detail: { editorId, ...data } });
    document.dispatchEvent(event);
}

export const getNodeIndex = (focusNode) => {
    try {
        let previousNodeIndex = 0;
        let previousNode = (focusNode && focusNode.previousSibling) ? focusNode.previousSibling : null
        if (previousNode) {
            while ((previousNode = previousNode.previousSibling) != null) previousNodeIndex++;
        }
        return previousNodeIndex
    } catch (ex) {
        return 0
    }
}

export function messageTextChecker(terms, value) {
    try {
        let textExists = false;

        let termsLowerCase = terms.map(function (v) {
            return v.toLowerCase();
        });
        let msgText = value.toLowerCase();

        termsLowerCase.forEach(word => {
            if (msgText.includes(word)) {
                textExists = true;
            }
        });
        return textExists;
    } catch (ex) {
        return false
    }
}

export function getEventTime(start, end) {
    try {
        if (moment.utc(start).format("YYYY-DD-MM").toString() === moment.utc(end).format("YYYY-DD-MM").toString()) {
            return moment.utc(start).format('MMM Do YYYY, h:mm A') + ' - ' + moment.utc(end).format('h:mm A');
        }
        return moment.utc(start).format('MMM Do YYYY, h:mm A') + ' - ' + moment.utc(end).format('MMM Do YYYY, h:mm A');
    } catch (err) {
        console.error(err)
        return ''
    }
}

export const filterWarningDisclaimerTerms = (terms = []) => {
    try {
        return terms.reduce((filtered, data) => {
            if (data.STATUS === WARNING_DISCLAIMER_TERMS_STATUS.ACTIVE) {
                filtered.push(data.TERM)
            }
            return filtered
        }, [])
    } catch (ex) {
        return []
    }
}

export const getBackupCityName = (address = '') => {
    try {
        let addressComponents = address.split(',');
        return addressComponents.length > 0 ? addressComponents[0] : ''
    } catch (ex) {
        return ('')
    }
}

export const getAdminActiveSection = (text = '') => {
    try {
        const params = queryString.parse(text);
        let searchEmail = params.email || '';
        try {
            searchEmail = atob(searchEmail)
        } catch (ex) {
            searchEmail = ''
            console.error('Invalid Encoded value!')
        }
        if (params.view) return { ...params, view: Number(params.view), searchEmail }
        else return { ...params, view: ADMIN_SECTION.DASHBOARD }
    } catch (ex) {
        console.error(ex)
        return { view: ADMIN_SECTION.DASHBOARD }
    }
}
