import React, { Component } from "react";
import "./styles.css";
import { formatRichText } from "../../../libs/textFormat";

class Description extends Component {

  urlify = (text) => {
    var Regex = /(https?:\/\/[^\s]+)/g;
    return text.replace(Regex, (url) => {
      return '<a target="_blank" href="' + url + '">' + url + '</a>';
    });
  }

  render() {
    let msg = this.props.richText ? formatRichText(this.props.richText) : (this.props.msg !== null && this.props.msg !== '') ? this.urlify(this.props.msg) : '';
    if (this.props.bold) {
      return (
        <div className="p-b20" dangerouslySetInnerHTML={{ __html: msg.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} />
      );
    } else {
      return (
        <div className="p-b20" dangerouslySetInnerHTML={{ __html: msg.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} />
      );
    }
  }

}

Description.defaultProps = {
  bold: null,
  msg: null
};

export { Description };