import React from "react";
import "./styles.css";

const ImageLoading = props => {
  return (
    <svg
      className="image_circle-loader"
      width="40"
      height="40"
      version="1.1"
    >
      <circle cx="20" cy="20" r="15" />
    </svg>
  );
};
export { ImageLoading };
