import React from 'react';

const ModalImage = ({ source }) =>{
  
     return(
        <div className="d-flex justify-content-center align-items-center">
         <img className="modal-logo" src={source} alt="modal logo"/>
        </div> 
     )
}

export { ModalImage }