import React, { Component } from "react";
import LogoMain from "../../assets/img/logo-light.svg";
import "./index.css";
import classname from "classnames";
import { Link } from "react-router-dom";


class NonAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollHeight: null,
      scrolled: false,
      email: "",
      password: "",
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    // window.onscroll = () => this.handleScroll();
    window.addEventListener('scroll', this.handleScroll)
  }
  componentWillUnmount() {
    // window.onscroll = () => this.handleScroll();
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {

    if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0) > 0) {
      this.setState({
        scrolled: true,
        scrollHeight: window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      });
    } else {
      this.setState({ scrolled: false, scrollHeight: null });
    }
  }


  loginSubmit() {

    let credentials = {
      email: this.refs.email.value,
      password: this.state.password
    };

    this.props.getLoginData(credentials);
  }

  enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) { //13 is the enter keycode
      this.loginSubmit();
    }
  }

  render() {

    /*const styles = {
      'color': '#F44336',
      'opacity': '0.6',
      'top': '-18px',
      'marginLeft': '16px'
    };*/

    let navLayout = classname("navbar", "navbar-expand-lg", !this.props.location.pathname.includes('/video-reference') ? "fixed-top" : "", this.state.scrollHeight <= 0 ? "navigation-animate-up" : "navigation-animate-down");

    return (
      <div className="nav-wrapper">
        <nav className={navLayout} style={this.props.alert.show === true ? { top: '70px', zIndex: 99999 } : { top: '0px', zIndex: 99999 }}>
          <div className="container">
            <Link to="/" className="navbar-brand">
              <img src={LogoMain} className={this.state.scrollHeight <= 0 ? "navigation_logo-l" : "navigation_logo-s"} alt="company logo" />
            </Link>
            {this.props.isDesktop && <span className="navbar-toggler font_white loginMobIcon" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <i className="fas fa-sign-in-alt fas fa-sign-out-alt font_18 font_gray"></i> Login
          </span>}
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <div className="navbar_mobile_view ml-md-auto">
                <form className="form-inline my-2 my-lg-0">
                  <div className="nav_input_cont">
                    <input id="mNavEmail" ref="email" className="form-control mr-sm-2 navigation_input navigation_input_noicon" type="email" value={this.state.email} placeholder="Email Address" aria-label="Search" style={{ width: '250px', 'color': '#fff' }} onChange={(e) => this.setState({ email: e.target.value })} onKeyPress={this.enterPressed.bind(this)} />
                    {/* {this.state.errMail && <span style={styles}>{this.state.errMail}</span>} */}
                    <input id="mNavPassword" ref="password" className="form-control mr-sm-2 navigation_input navigation_input_noicon" type="password" value={this.state.password} placeholder="Password" aria-label="Search" style={{ width: '250px', 'color': '#fff' }} onChange={(e) => this.setState({ password: e.target.value })} onKeyPress={this.enterPressed.bind(this)} />
                    {/* {this.state.errPassword && <span style={styles}>{this.state.errPassword}</span>} */}

                    <button id="mSignInButton" type="button" disabled={this.props.auth.fetching} onClick={this.loginSubmit.bind(this)} className="btn base-button-login p-l15" data-toggle="collapse" data-target="#navbarSupportedContent">
                      Sign In
                  </button>

                    <span data-toggle="collapse" data-target="#navbarSupportedContent">
                      <a href='/forgot-password' className="forget-pw">Forgot Password?</a>
                      {/* <Link className="forget-pw" to="/forgot-password">Forgot Password?</Link> */}
                    </span>

                  </div>
                </form>
              </div>
            </div>

            <div className="collapse navbar-collapse">
              <div className="navbar_dekstop_view ml-md-auto">
                <form className="form-inline my-2 my-lg-0">
                  <div className="nav_input_cont">
                    <input id="NavEmail" ref="email" className="form-control mr-sm-2 navigation_input navigation_input_noicon" type="email" value={this.state.email} placeholder="Email Address" aria-label="Search" style={{ width: '250px', 'color': '#fff' }} onChange={(e) => this.setState({ email: e.target.value })} onKeyPress={this.enterPressed.bind(this)} />
                    {/* {this.state.errMail && <span style={styles}>{this.state.errMail}</span>} */}
                    <input id="NavPassword" ref="password" className="form-control mr-sm-2 navigation_input navigation_input_noicon" type="password" value={this.state.password} placeholder="Password" aria-label="Search" style={{ width: '250px', 'color': '#fff' }} onChange={(e) => this.setState({ password: e.target.value })} onKeyPress={this.enterPressed.bind(this)} />
                    {/* {this.state.errPassword && <span style={styles}>{this.state.errPassword}</span>} */}


                    {this.props.isDesktop && <button id="SignInButton" type="button" disabled={this.props.auth.fetching} onClick={this.loginSubmit.bind(this)} className="btn base-button-login p-l15" data-toggle="collapse" data-target="#navbarSupportedContent">
                    Sign In
                  </button>}

                    <span data-toggle="collapse">
                      <a href='/forgot-password' className="forget-pw">Forgot Password?</a>
                      {/* <Link className="forget-pw" to="/forgot-password">Forgot Password?</Link> */}
                    </span>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </nav>
      </div>
    );
  }
}



export default NonAuth;
