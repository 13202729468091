import React from "react";
import './index.css';

const Time = ({ time }) => {
  return <p className="postTime font_light font_11 p-r20">{time}</p>;
};
Time.defaultProps = {
  time: null
}
export { Time };
