import React from "react";

const Padding = ({
  children,
  padding,
  horizontal,
  vertical,
  containerSize,
  customClass
}) => {
  let marginType = null;

  if (horizontal) {
    if (containerSize) {
      marginType = { padding: `0  ${padding + "%"}` };
    } else {
      marginType = { padding: `0px  ${padding + "px"}` };
    }
  } else if (vertical) {
    if (containerSize) {
      marginType = { padding: `${padding + "%"}   0% ` };
    } else {
      marginType = { padding: `${padding + "px"}   0px ` };
    }
  }

  return <div className={customClass} style={marginType}>{children}</div>;
};
export { Padding };
