import React from 'react';
import './../index.css';

const RadioInput = ({value, label, name, id, onChange, checked, disabled=false}) => {
    return <div className="radio-input">
        <input type="radio" id={id} name={name} value={value} onChange={onChange} checked={checked} disabled={disabled}/>
        <label htmlFor={id}>{label}</label>
    </div>
};

export { RadioInput };
