import React, { Component } from "react"
class ModalHeader extends Component {

    render(){
        return(
            <div className="modal-default-header d-flex align-items-center justify-content-between">
            {this.props.children}           
          </div>
        )
    }

}
export {ModalHeader}