import { SIGNED_COOKIES_CF_URL } from '../config';

export const BUCKET = {
    COMMON: 1,
    DATA_ROOM: 2,
    PREFERRED_PARTNERS: 3,
    NEWSFEED: 4,
    MARKET_PLACE: 5,
    EDUCATION: 6,
    SURVEY: 7,
    VIDEO: 8,
    GP_IMAGE: 9,
    GP_VIDEO: 10,
};

const BUCKET_PATH = {
    COMMON: '/common/',
    DATA_ROOM: '/dataroom/',
    PREFERRED_PARTNERS: '/preferredpartners/',
    NEWSFEED: '/newsfeed/',
    MARKET_PLACE: '/marketplace/',
    EDUCATION: '/education/',
    SURVEY: '/survey/',
    VIDEO: '/video/',
    GP_IMAGE: '/images/',
    GP_VIDEO: '/videos/',
};

const VIDEO_THUBNAILS_DIR = 'videothumbnails/';
const THUBNAILS_DIR = 'thumbnails/';
const VIDEO_INPUT_DIR = 'input/';
const VIDEO_OUTPUT_HLS_DIR = 'output/hls/';

export const getCFObjURL = (bucket) => {
    switch (bucket) {
        case BUCKET.COMMON:
            return prepareCFObjURLForCommonBucket;
        case BUCKET.DATA_ROOM:
            return prepareCFObjURLForDataroomBucket;
        case BUCKET.PREFERRED_PARTNERS:
            return prepareCFObjURLForPreferredPartnersBucket;
        case BUCKET.NEWSFEED:
            return prepareCFObjURLForNewsfeedBucket;
        case BUCKET.MARKET_PLACE:
            return prepareCFObjURLForMarketPlaceBucket;
        case BUCKET.EDUCATION:
            return prepareCFObjURLForEducationBucket;
        case BUCKET.VIDEO:
            return prepareCFObjURLForVideoBucket;
        case BUCKET.GP_IMAGE:
            return prepareCFObjURLForGPIMGBucket;
        case BUCKET.GP_VIDEO:
            return prepareCFObjURLForGPVIDEOBucket;
        default:
            return prepareCFObjURLForCommonBucket;
    }
};

const prepareCFObjURLForCommonBucket = (fileName, isThumbnail = false) => {
    const baseURL = SIGNED_COOKIES_CF_URL + BUCKET_PATH.COMMON;
    if (isThumbnail) {
        return baseURL + THUBNAILS_DIR + fileName;
    }
    return baseURL + fileName;
};

const prepareCFObjURLForDataroomBucket = (fileName, companyId) => {
    const baseURL = SIGNED_COOKIES_CF_URL + BUCKET_PATH.DATA_ROOM;
    return baseURL + companyId + '/' + fileName;
};

const prepareCFObjURLForPreferredPartnersBucket = (fileName) => {
    const baseURL = SIGNED_COOKIES_CF_URL + BUCKET_PATH.PREFERRED_PARTNERS;
    return baseURL + fileName;
};

const prepareCFObjURLForNewsfeedBucket = (fileName, isThumbnail = false) => {
    const baseURL = SIGNED_COOKIES_CF_URL + BUCKET_PATH.NEWSFEED;
    if (isThumbnail) {
        return baseURL + THUBNAILS_DIR + fileName;
    }
    return baseURL + fileName;
};

const prepareCFObjURLForMarketPlaceBucket = (fileName, companyId) => {
    const baseURL = SIGNED_COOKIES_CF_URL + BUCKET_PATH.MARKET_PLACE;
    return baseURL + companyId + '/' + fileName;
};

const prepareCFObjURLForEducationBucket = (fileName) => {
    const baseURL = SIGNED_COOKIES_CF_URL + BUCKET_PATH.EDUCATION + THUBNAILS_DIR;
    return baseURL + fileName;
};

const prepareCFObjURLForVideoBucket = (fileName, isOriginal = false) => {
    const baseURL = SIGNED_COOKIES_CF_URL + BUCKET_PATH.VIDEO;
    if (isOriginal) {
        return baseURL + VIDEO_INPUT_DIR + fileName;
    }
    return baseURL + VIDEO_OUTPUT_HLS_DIR + fileName;
};

const prepareCFObjURLForGPIMGBucket = (fileName, isThumbnail = false) => {
    const baseURL = SIGNED_COOKIES_CF_URL + BUCKET_PATH.GP_IMAGE;
    if (isThumbnail) {
        console.log(baseURL + VIDEO_THUBNAILS_DIR + fileName)
        return baseURL + VIDEO_THUBNAILS_DIR + fileName;
    }
    return baseURL + fileName;
};

const prepareCFObjURLForGPVIDEOBucket = (fileName) => {
    const baseURL = SIGNED_COOKIES_CF_URL + BUCKET_PATH.GP_VIDEO;
    return baseURL + fileName;
};