import React , {Component} from 'react';

class AnimatedTextAreaControlled extends Component{

  constructor(props){
    super(props);
    this.state={
      id:this.props.refid || null,
      value:this.props.defaultval || '',
      type: this.props.type || "text",
      minHeight : 100,
      placeholder : this.props.placeholder || '',
      defaultval : '',
      edit:false,
      cashval:'',
      maxLength: this.props.maxLength || null
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextprops){
    if(nextprops.defaultval === "" && !this.state.edit){
      this.setState({
            value:nextprops.defaultval
        })
    }
    if(!this.state.edit && nextprops.defaultval !== this.state.value){
      this.setState({
          value:nextprops.defaultval
      })
    }
    if(this.state.id !== this.props.refid){
      this.setState({
          value:nextprops.defaultval,
          id:nextprops.refid
      })
    }

  }

  handleChange(event) {
    this.props.submit(event);
    this.setState({
        edit:true,
        value: this.state.cashval !== '' && this.state.cashval.length < 2?/*this.state.cashval+*/event.target.value : event.target.value,
        cashval:!this.state.edit?event.target.value:''
    });
  }

  render(){
    return(
      <React.Fragment>
        <textarea maxLength = {this.state.maxLength} type={this.state.type} placeholder={this.state.placeholder} className="matirial_custom_input" style={{minHeight:this.props.height+'px'}} onChange={this.handleChange} value={this.state.value} required/>
        {(this.props.showLimit && this.state.value.length > (this.state.maxLength - this.props.remainingChar)) && <div className="text-right p-r15 p-b10 character_count fcont_light font_12">{this.state.value.length}/{this.state.maxLength}</div>}
      </React.Fragment>
    )
  }
}
export{ AnimatedTextAreaControlled};
