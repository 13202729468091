import React, { PureComponent } from "react";
import './index.css';
import { Link } from 'react-router-dom';

class NotFound extends PureComponent{

    constructor(props){
        super(props);
        this.state = {
            initPath: this.props.location.pathname
        };
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        if(prevState.initPath !== nextProps.location.pathname){
            nextProps.childProps.pageNotFound(false);
        }
        return null
    };

    render(){
        return(
            <div className="notfound-wrapper">
                <div className="notfound-text">
                    <div className="notfound-header">Page not found.</div>
                    <div className="notfound-subtext">We can't find the page you are looking for <Link to="/" className="back-link">Go back to home</Link></div>
                </div>
            </div>
        )
    }
}

export default NotFound;