import React, { useState, useEffect } from 'react';
import ToolTipPopup from './../../../containers/admin/toolTipManager/tooltipPopup/index';
import useComponentVisible from '../../../libs/hooks/useComponentVisible';
import './index.css';
import { trackEvent } from '../../../actions/eventTracking';
import { EVENT_ACTION } from '../../../types/eventTracking';

const ToolTip = ({
  tooltip,
  color,
  toggleZindex,
  staticTT = false,
  staticHead = '',
  user = null,
  dispatch = null,
  page = null,
  section = null,
  element_id = null,
  toolTipStyle = null,
  toolTipPopupStyle,
  useBracketsHeadline = false
}) => {
  const [showToolTip, setShowToolTip] = useState(false);
  const [openToolTip, setOpenToolTip] = useState(false);
  const [ref, isComponentVisible, setIsComponentVisible] =
    useComponentVisible(true);

  const handleOtherTooltipOpen = (e) => {
    if (e.detail && e.detail.isTooltipSeen) {
      setOpenToolTip(false);
    }
  };

  useEffect(() => {
    document.addEventListener('tooltip_action', handleOtherTooltipOpen);
    return function cleanup() {
      document.removeEventListener('tooltip_action', handleOtherTooltipOpen);
    };
  }, []);

  useEffect(() => {
    if (!(isComponentVisible && openToolTip)) {
      setOpenToolTip(false);
    }
    if (!isComponentVisible && !openToolTip && !showToolTip) {
      if (toggleZindex) {
        toggleZindex(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComponentVisible, openToolTip]);

  /* data project - event trcking */
  const _setEventTrackingPayload = () => {
    let payload = {};
    if (user && page) {
      payload = {
        user_id: user._id ? user._id : user,
        page: page,
        section: section,
        element_type: 'TOOLTIP',
        element_id: element_id
          ? element_id
          : staticHead
            ? staticHead.toUpperCase()
            : null,
        action_type: EVENT_ACTION.VIEW,
      };
    }
    return payload;
  };
  /* END : data project - event trcking */

  const handleIconClick = () => {
    setIsComponentVisible(true);
    setOpenToolTip(true);
    if (toggleZindex) {
      toggleZindex(false, true);
    }
  };

  return (
    <React.Fragment>
      {!staticTT && (
        <div
          className="tooltip-icon-wrapper"
          onClick={handleIconClick}
          onMouseOver={() => {
            let event = new CustomEvent('tooltip_action', {
              detail: { isTooltipSeen: true },
            });
            document.dispatchEvent(event);
            setShowToolTip(true);
            if (toggleZindex) {
              toggleZindex(false, true);
            }
            if (user && page) {
              /* data project - event trcking */
              let payload = _setEventTrackingPayload();
              if (dispatch) {
                dispatch(trackEvent(payload));
              } else {
                console.error('method dispatch not found');
              }
              /* END : data project - event trcking */
            }
          }}
          onMouseOut={() => {
            setShowToolTip(false);
            if (toggleZindex && !openToolTip) {
              toggleZindex(true, true);
            }
          }}
        >
          {tooltip && tooltip.ICON === 1 ? (
            <i style={{ color: color }} className="fas fa-info-circle" />
          ) : tooltip && tooltip.ICON === 2 ? (
            <i style={{ color: color }} className="fas fa-eye" />
          ) : (tooltip &&
            <span className={toolTipStyle}>
              {useBracketsHeadline ? `(${tooltip.HEAD_LINE && tooltip.HEAD_LINE})` : tooltip.HEAD_LINE && tooltip.HEAD_LINE}
            </span>
          )}
        </div>
      )}
      <div
      // className="tooltip-icon-wrapper"
      >
        <span
          onClick={handleIconClick}
          onMouseOver={() => {
            setShowToolTip(true);
            if (toggleZindex) {
              toggleZindex(false, true);
            }
            if (user && page) {
              /* data project - event trcking */
              let payload = _setEventTrackingPayload();
              if (dispatch) {
                dispatch(trackEvent(payload));
              } else {
                console.error('method dispatch not found');
              }
              /* END : data project - event trcking */
            }
          }}
          onMouseOut={() => {
            setShowToolTip(false);
            if (toggleZindex && !openToolTip) {
              toggleZindex(true, true);
            }
          }}
        >
          {staticHead}
        </span>
      </div>
      {(showToolTip || openToolTip) && (
        <div className="tooltip-container" ref={ref}>
          <ToolTipPopup
            openToolTip={openToolTip}
            toolTipPopupStyle={toolTipPopupStyle}
            tooltip={tooltip}
            IS_PREVIEW={false}
            close={() => {
              setOpenToolTip(false);
              if (toggleZindex) {
                toggleZindex(true);
              }
            }}
          />
        </div>
      )}
    </React.Fragment>
  );
};

ToolTip.defaultProps = {
  color: '#9ccc65',
};

export default ToolTip;
