module.exports = {
    CLEAR_ACTION: "CLEAR_ACTION",

    FETCHING_LANDING_PAGES: "FETCHING_LANDING_PAGES",
    FETCH_LANDING_PAGES_SUCCESS: "FETCH_LANDING_PAGES_SUCCESS",
    FETCH_LANDING_PAGES_FAIL: "FETCH_LANDING_PAGES_FAIL",

    GETTING_LANDING_PAGE_COUNT: "GETTING_LANDING_PAGE_COUNT",
    LANDING_PAGE_COUNT_SUCCESS: "LANDING_PAGE_COUNT_SUCCESS",
    LANDING_PAGE_COUNT_FAIL: "LANDING_PAGE_COUNT_FAIL",

    ADDING_LANDING_PAGE: "ADDING_LANDING_PAGE",
    ADD_LANDING_PAGE_SUCCESS: "ADD_LANDING_PAGE_SUCCESS",
    ADD_LANDING_PAGE_FAIL: "ADD_LANDING_PAGE_FAIL",

    UPDATING_LANDING_PAGE: "UPDATING_LANDING_PAGE",
    UPDATE_LANDING_PAGE_SUCCESS: "UPDATE_LANDING_PAGE_SUCCESS",
    UPDATE_LANDING_PAGE_FAIL: "UPDATE_LANDING_PAGE_FAIL",

    FETCHING_CUSTOM_LANDING_PAGE: "FETCHING_CUSTOME_LANDING_PAGE",
    FETCH_CUSTOM_LANDING_PAGE_SUCCESS: "FETCH_CUSTOM_LANDING_PAGE_SUCCESS",
    FETCH_CUSTOM_LANDING_PAGE_FAIL: "FETCH_CUSTOM_LANDING_PAGE_FAIL"
}