module.exports = {

    //_SUCCESS
    //_FAIL
    //"EVENT_"

    CLEAR_ACTION: "EVENT_CLEAR_ACTION",

    FETCH_TAGS: "EVENT_FETCH_TAGS",
    FETCH_TAGS_SUCCESS: "EVENT_FETCH_TAGS_SUCCESS",
    FETCH_TAGS_FAIL: "EVENT_FETCH_TAGS_FAIL",

    GET_FEATURED_EVENTS_COUNT: "EVENT_GET_FEATURED_EVENTS_COUNT",
    GET_FEATURED_EVENTS_COUNT_SUCCESS: "EVENT_GET_FEATURED_EVENTS_COUNT_SUCCESS",
    GET_FEATURED_EVENTS_COUNT_FAIL: "EVENT_GET_FEATURED_EVENTS_COUNT_FAIL",

    CREATE_EVENT: "EVENT_CREATE_EVENT",
    CREATE_EVENT_SUCCESS: "EVENT_CREATE_EVENT_SUCCESS",
    CREATE_EVENT_FAIL: "EVENT_CREATE_EVENT_FAIL",

    PUBLISH_LOCAL_EVENT: "EVENT_PUBLISH_LOCAL_EVENT",
    PUBLISH_LOCAL_EVENT_SUCCESS: "EVENT_PUBLISH_LOCAL_EVENT_SUCCESS",
    PUBLISH_LOCAL_EVENT_FAIL: "EVENT_PUBLISH_LOCAL_EVENT_FAIL",

    PUBLISH_GLOBAL_EVENT: "EVENT_PUBLISH_GLOBAL_EVENT",
    PUBLISH_GLOBAL_EVENT_SUCCESS: "EVENT_PUBLISH_GLOBAL_EVENT_SUCCESS",
    PUBLISH_GLOBAL_EVENT_FAIL: "EVENT_PUBLISH_GLOBAL_EVENT_FAIL",

    FETCH_SINGLE_EVENT: "EVENT_FETCH_SINGLE_EVENT",
    FETCH_SINGLE_EVENT_SUCCESS: "EVENT_FETCH_SINGLE_EVENT_SUCCESS",
    FETCH_SINGLE_EVENT_FAIL: "EVENT_FETCH_SINGLE_EVENT_FAIL",

    EDIT_EVENT: "EVENT_EDIT_EVENT",
    EDIT_EVENT_SUCCESS: "EVENT_EDIT_EVENT_SUCCESS",
    EDIT_EVENT_FAIL: "EVENT_EDIT_EVENT_FAIL",

    GET_USER_EVENTS_COUNT: "EVENT_GET_USER_EVENTS_COUNT",
    GET_USER_EVENTS_COUNT_SUCCESS: "EVENT_GET_USER_EVENTS_COUNT_SUCCESS",
    GET_USER_EVENTS_COUNT_FAIL: "EVENT_GET_USER_EVENTS_COUNT_FAIL",

    GET_USER_EVENTS: "EVENT_GET_USER_EVENTS",
    GET_USER_EVENTS_SUCCESS: "EVENT_GET_USER_EVENTS_SUCCESS",
    GET_USER_EVENTS_FAIL: "EVENT_GET_USER_EVENTS_FAIL",

    GET_OTHER_EVENTS_COUNT: "EVENT_GET_OTHER_EVENTS_COUNT",
    GET_OTHER_EVENTS_COUNT_SUCCESS: "EVENT_GET_OTHER_EVENTS_COUNT_SUCCESS",
    GET_OTHER_EVENTS_COUNT_FAIL: "EVENT_GET_OTHER_EVENTS_COUNT_FAIL",

    GET_OTHER_EVENTS: "EVENT_GET_OTHER_EVENTS",
    GET_OTHER_EVENTS_SUCCESS: "EVENT_GET_OTHER_EVENTS_SUCCESS",
    GET_OTHER_EVENTS_FAIL: "EVENT_GET_OTHER_EVENTS_FAIL",

    FETCH_EVENT_SUBSCRIPTIONS: "EVENT_FETCH_EVENT_SUBSCRIPTIONS",
    FETCH_EVENT_SUBSCRIPTIONS_SUCCESS: "EVENT_FETCH_EVENT_SUBSCRIPTIONS_SUCCESS",
    FETCH_EVENT_SUBSCRIPTIONS_FAIL: "EVENT_FETCH_EVENT_SUBSCRIPTIONS_FAIL",

    UNPUBLISH_EVENT: "EVENT_UNPUBLISH_EVENT",
    UNPUBLISH_EVENT_SUCCESS: "EVENT_UNPUBLISH_EVENT_SUCCESS",
    UNPUBLISH_EVENT_FAIL: "EVENT_UNPUBLISH_EVENT_FAIL",

    ADD_EVENT_TAG: "ADD_EVENT_TAG",
    ADD_EVENT_TAG_SUCCESS: "ADD_EVENT_TAG_SUCCESS",
    ADD_EVENT_TAG_FAIL: "ADD_EVENT_TAG_FAIL",

    UPDATE_EVENT_TAG: "UPDATE_EVENT_TAG",
    UPDATE_EVENT_TAG_SUCCESS: "UPDATE_EVENT_TAG_SUCCESS",
    UPDATE_EVENT_TAG_FAIL: "UPDATE_EVENT_TAG_FAIL",

    DELETE_EVENT_TAG: "DELETE_EVENT_TAG",
    DELETE_EVENT_TAG_SUCCESS: "DELETE_EVENT_TAG_SUCCESS",
    DELETE_EVENT_TAG_FAIL: "DELETE_EVENT_TAG_FAIL",

};