import React, { Component } from "react";
import autosize from "autosize";
import { AvatarProfile } from "./../../common";
import avatar from "./../../../assets/sample/avt.png";
import './styles.css';
import { TEXT_AREA } from '../../../types/common'
import  {imageExists} from './../../../libs/image';
import defaultAvatar from "./../../../assets/img/default-male.jpg";
import MentionTagInput from "../../input/mentionTagInput";


class AddComment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      profilePicture: localStorage.getItem('profileThumbnail') ? localStorage.getItem('profileThumbnail') : defaultAvatar,
      comment: ''
    };
  }

  isUrl(s) {
    var regexp = /(ftp|http|https):\/\/(\w:{0,1}\w*@)?(\S)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/
    return regexp.test(s);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.avatar !== this.state.profilePicture && this.isUrl(nextProps.avatar)) {
      this.setState({profilePicture: nextProps.avatar});
    }
  }

  componentDidMount() {
    const isProfilePic = true;
    autosize(this.textarea);
    imageExists(localStorage.getItem('profilePicture'), (exists) => {
      if (exists) {
        this.setState({profilePicture: localStorage.getItem('profilePicture') })
      } else {
        this.setState({profilePicture: defaultAvatar})
      }
    }, isProfilePic)
  }

  handleChange = (text, richText) => {
    this.setState({ comment: text, richText: richText })
  }

  _onKeyPress = () => {
    const { comment, richText } = this.state;
      if (comment !== '') {
        this.props.addComment(this.props.postId, comment, 'feed', 10, richText);
        this.setState({ comment: '' })
      }
  };

  render() {
    return (
      <div className="d-flex flex-row">
        <div>
          <AvatarProfile avatar={this.state.profilePicture} size={39} key={this.props.postId} />
        </div>
        <div className=" m-l10 comment_input" style={{ width: "100%", position: "relative" }} id={'comment_box_' + this.props.ID}>
          <MentionTagInput
            handleText={this.handleChange}
            placeholder={this.props.placeholder}
            ref={c => (this.textarea = c)}
            onKeyPress={this._onKeyPress.bind(this)}
            className="post_input commentPost"
            maxLength={TEXT_AREA.COMMENT_LENGTH}
            toggleMobileNav={!this.props.isDesktop ? this.props.toggleMobileNav : null}
          />
          {/* <textarea
              ref={c => (this.textarea = c)}
              rows={this.props.rows}
              className="post_input commentPost"
              placeholder={this.props.placeholder}
              type={this.props.type}
              value = {this.state.comment}
              onChange = {(e) => {this.setState({comment: e.target.value})}}
              onKeyPress = {this._onKeyPress.bind(this)}
              maxLength = {TEXT_AREA.COMMENT_LENGTH}
            >
            {this.props.chlidren}
          </textarea> */}
          {this.state.comment.length > (TEXT_AREA.COMMENT_LENGTH - TEXT_AREA.COMMENT_REMAINING_CHARACTERS) && <div className="character_count font_light font_12">{this.state.comment.length}/{TEXT_AREA.COMMENT_LENGTH}</div>}
        </div>
      </div>
    );
  }

}

AddComment.defaultProps = {
  placeholder: "Comment on this post...",
  rows: 3,
  children: null,
  avatar: avatar,
  type : 'text'
};

export { AddComment };
