import React, { useRef, useEffect } from "react";
import "./index.css";
import classnames from "classnames";
import { isMobile } from 'react-device-detect';

const CardDefault = ({ postRendered=null, postMouseEnter=null, postMouseLeave=null, children, transparent, customClass, ID, translate }) => {

  const wrapperRef = useRef(null);

  let cx = classnames("card_default_bottom_space" ,{ "card_default": !transparent }, customClass);
  let extra = ID !== null ? { id: ID } : {};


/* data project - event trcking */
  useEffect(() => {
    observer.observe(wrapperRef.current);

    return () => { observer.disconnect() }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting && postRendered) {
        postRendered();
      }

      if(isMobile) {
        if(entry.intersectionRatio > 0.6 && postMouseEnter) {
          postMouseEnter();
        } 
      }
    },
    { threshold: [0.25, 0.6]  }
  );

/* END : data project - event trcking */


  return (
      <div ref={wrapperRef}  onMouseEnter={postMouseEnter ? postMouseEnter : null} onMouseLeave={postMouseLeave ? postMouseLeave : null} className={cx} {...extra} style={{ transform : translate > 0 ? `translateY(-${translate}px)` :  `unset`}} >
        {children}
      </div>
  );
};
CardDefault.defaultProps = {
  transparent: null
};
export { CardDefault };
