module.exports = {
    CLEAR_ACTION: 'CLEAR_ACTION',

    FETCHING_MY_CONNECTIONS : "FETCHING_MY_CONNECTIONS",
    FETCHING_MY_CONNECTIONS_SUCCESS : "FETCHING_MY_CONNECTIONS_SUCCESS",
    FETCHING_MY_CONNECTIONS_FAIL : "FETCHING_MY_CONNECTIONS_FAIL",

    DISCONNECTING_MY_CONNECTION : "DISCONNECTING_MY_CONNECTION",
    DISCONNECTING_MY_CONNECTION_SUCCESS : "DISCONNECTING_MY_CONNECTION_SUCCESS",
    DISCONNECTING_MY_CONNECTION_FAIL : "DISCONNECTING_MY_CONNECTION_FAIL",

    FETCHING_MORE_CONNECTIONS : "FETCHING_MORE_CONNECTIONS",

    FETCHING_RECOMMONDED_CONNECTIONS: 'FETCHING_RECOMMONDED_CONNECTIONS',
    FETCHING_RECOMMONDED_CONNECTIONS_SUCCESS: 'FETCHING_RECOMMONDED_CONNECTIONS_SUCCESS',
    FETCHING_RECOMMONDED_CONNECTIONS_FAIL: 'FETCHING_RECOMMONDED_CONNECTIONS_FAIL',

    REPLACE_RECOMMONDED_CONNECTIONS: 'REPLACE_RECOMMONDED_CONNECTIONS',
    REPLACE_RECOMMONDED_CONNECTIONS_FAIL: 'REPLACE_RECOMMONDED_CONNECTIONS_FAIL',
    SET_RECOMMENDED_CONNECTIONS_IMAGES: 'SET_RECOMMENDED_CONNECTIONS_IMAGES',

    FETCHING_RECOMMONDED_CONNECTIONS_COUNT: 'FETCHING_RECOMMONDED_CONNECTIONS_COUNT',
    FETCHING_RECOMMONDED_CONNECTIONS_COUNT_SUCCESS: 'FETCHING_RECOMMONDED_CONNECTIONS_COUNT_SUCCESS',
    FETCHING_RECOMMONDED_CONNECTIONS_COUNT_FAIL: 'FETCHING_RECOMMONDED_CONNECTIONS_COUNT_FAIL',

    FETCHING_MORE_RECOMMONDED_CONNECTIONS: 'FETCHING_MORE_RECOMMONDED_CONNECTIONS',
    FETCHING_MORE_SEARCH_CONNECTIONS: 'FETCHING_MORE_SEARCH_CONNECTIONS',

    FETCHING_PENDING_CONNECTIONS: 'FETCHING_PENDING_CONNECTIONS',
    FETCHING_PENDING_CONNECTIONS_SUCCESS: 'FETCHING_PENDING_CONNECTIONS_SUCCESS',
    FETCHING_PENDING_CONNECTIONS_FAIL: 'FETCHING_PENDING_CONNECTIONS_FAIL',
    FETCHING_PENDING_CONNECTIONS_EXCEPTION: 'FETCHING_PENDING_CONNECTIONS_EXCEPTION',

    FETCHING_PENDING_CONNECTIONS_COUNT: 'FETCHING_PENDING_CONNECTIONS_COUNT',
    FETCHING_PENDING_CONNECTIONS_COUNT_SUCCESS: 'FETCHING_PENDING_CONNECTIONS_COUNT_SUCCESS',
    FETCHING_PENDING_CONNECTIONS_COUNT_FAIL: 'FETCHING_PENDING_CONNECTIONS_COUNT_FAIL',
    FETCHING_PENDING_CONNECTIONS_COUNT_EXCEPTION: 'FETCHING_PENDING_CONNECTIONS_COUNT_EXCEPTION',

    SEND_CONNECTION_REQUEST_SUCCESS: 'SEND_CONNECTION_REQUEST_SUCCESS',

    ACCEPTING_PENDING_CONNECTION: 'ACCEPTING_PENDING_CONNECTION',
    ACCEPT_PENDING_CONNECTION_SUCCESS: 'ACCEPT_PENDING_CONNECTION_SUCCESS',
    ACCEPT_PENDING_CONNECTION_FAIL: 'ACCEPT_PENDING_CONNECTION_FAIL',
    ACCEPT_PENDING_CONNECTION_EXCEPTION: 'ACCEPT_PENDING_CONNECTION_EXCEPTION',

    REJECTING_PENDING_CONNECTION: 'REJECTING_PENDING_CONNECTION',
    REJECT_PENDING_CONNECTION_SUCCESS: 'REJECT_PENDING_CONNECTION_SUCCESS',
    REJECT_PENDING_CONNECTION_FAIL: 'REJECT_PENDING_CONNECTION_FAIL',
    REJECT_PENDING_CONNECTION_EXCEPTION: 'REJECT_PENDING_CONNECTION_EXCEPTION',

    TOGGLE_SEARCH_MODE:"TOGGLE_SEARCH_MODE",
    CLEAR_RECOMMONDED_CONNECTIONS:"CLEAR_RECOMMONDED_CONNECTIONS",
    CLEAR_MY_CONNECTIONS: "CLEAR_MY_CONNECTIONS",
    CLEAR_PENDING_CONNECTIONS: "CLEAR_PENDING_CONNECTIONS",

    SET_TO_FIRST:'SET_TO_FIRST',

    GET_CONNECTIONS_COUNT:'GET_CONNECTIONS_COUNT',
    GET_CONNECTIONS_COUNT_SUCCESS:'GET_CONNECTIONS_COUNT_SUCCESS',
    GET_CONNECTIONS_COUNT_FAIL:'GET_CONNECTIONS_COUNT_FAIL',

    FETCHING_MY_CONNECTIONS_COUNT : "FETCHING_MY_CONNECTIONS_COUNT",
    FETCHING_MY_CONNECTIONS_COUNT_SUCCESS : "FETCHING_MY_CONNECTIONS_COUNT_SUCCESS",
    FETCHING_MY_CONNECTIONS_COUNT_FAIL : "FETCHING_MY_CONNECTIONS_COUNT_FAIL",

    GET_IMAGE_URL: "GET_CONNECTIONS_IMAGE_URL",
    REJECT_MY_CONNECTION: "REJECT_MY_CONNECTION",

    FETCH_RECOMMENDED_NEXT_BUTTON: 'FETCH_RECOMMENDED_NEXT_BUTTON',
    FETCH_RECOMMENDED_NEXT_BUTTON_SUCCESS: 'FETCH_RECOMMENDED_NEXT_BUTTON_SUCCESS',
    FETCH_RECOMMENDED_NEXT_BUTTON_SUCCESS_ON_PREVIOUS: "FETCH_RECOMMENDED_NEXT_BUTTON_SUCCESS_ON_PREVIOUS",
    FETCH_RECOMMENDED_NEXT_BUTTON_FAIL: 'FETCH_RECOMMENDED_NEXT_BUTTON_FAIL',

    SET_CONNECTION_MESSAGES_SEEN: 'SET_CONNECTION_MESSAGES_SEEN',

    DECREMENT_PENDING_CONNECTION: 'DECREMENT_PENDING_CONNECTION',

    NEW_CONNECTION_REQUEST_NOTIFICATION: 'NEW_CONNECTION_REQUEST_NOTIFICATION',
    PUSH_NEW_CONNECTION_REQUEST: 'PUSH_NEW_CONNECTION_REQUEST',
    SET_ACTIVE_FILTER_SECTION: 'SET_ACTIVE_FILTER_SECTION',
    CANCEL_CONNECTION_REQUEST_NOTIFICATION: 'CANCEL_CONNECTION_REQUEST_NOTIFICATION',
    POP_EXISTING_CONNECTION_REQUEST: 'POP_EXISTING_CONNECTION_REQUEST',

    REMOVE_DELETED_CONNECTION_MESSAGE: 'REMOVE_DELETED_CONNECTION_MESSAGE',

    CONNECTION_REQ_ACCEPTED_BY_USER: 'CONNECTION_REQ_ACCEPTED_BY_USER',
    CONNECTION_REQ_REJECTED_BY_USER: 'CONNECTION_REQ_REJECTED_BY_USER',

    SET_REQUESTED_USER_NOTIFICATION: 'SET_REQUESTED_USER_NOTIFICATION',
    CLEAR_REQUESTED_USER_NOTIFICATION: 'CLEAR_REQUESTED_USER_NOTIFICATION',

    CLEAR_PREVIOUS_REQUEST: 'CLEAR_PREVIOUS_REQUEST'
};