import React from "react";

const TabItem = ({ children, id, active, className}) => {
        return (
            <div id={id} className={`tab-pane fade in ${active ? 'active show' : ''} ${className}`}>
                {children}
            </div>
        );
}

export { TabItem };
