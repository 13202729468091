const NEWSFEED_AD_TYPES = {
  TEXT_LINK: 1,
  NEWSFEED_BANNER: 2,
};

const SUBSCRIPTION_MODULES = {
  EVENTS: 2,
  JOB_BOARD: 3,
  MARKETPLACE: 4,
  GREENPAGES: 5,
  FUNDRAISING: 6,

  MARKETPLACE_BASIC: 7,
  MARKETPLACE_PROFESSIONAL: 8,
  MARKETPLACE_PRO_PLUS: 9,
  MARKETPLACE_PLATINUM: 10
};

module.exports = {
  NEWSFEED_AD_TYPES,
  SOCKET: {
    NEWS_FEED_UPDATED: 'NEWS_FEED_UPDATED',
    GROUP_FEED_UPDATED: 'GROUP_FEED_UPDATED',
    LOADMORE: 'LOADMORE',
    NEWSFEED_UPDATED: 'Newsfeed has been updated',
    USER_DELETED: 'USER_DELETED',
    FORCE_RELOAD: 'FORCE_RELOAD',
    VIDEO_PUBLISHED: 'VIDEO_PUBLISHED',
  },
  THUMBNAIL: {
    MEDIUM: 'mediam-',
    MEDIUM_NEWSFEED: 'medium-',
    EX_MEDIUM: 'ex-mediam-',
    SMALL: 'small-',
    RETTRIEVE_TIMEOUT: 4000,
    UPLOAD_COMPLETED: 100,
  },
  MEMBER_TYPE: {
    ALL: 6,
    NETWORKER: 1,
    COMPANY: 2,
    // INVESTOR: 3,
    // ADMIN: 5,

    INVESTOR: 1,
    PROFESSIONAL: 2,
    ADMIN: 3,
    COMPANY_PROFILES: 4,
    FUND_RAISING_COMPANY_PROFILES: 5,
  },
  SEARCH_MEMBER_TYPE: {
    INVESTOR: 1,
    PROFESSIONAL: 2,
    COMPANY_PROFILES: 4,
    FUND_RAISING: 5,
    ALL: 6,
  },
  SEARCH_CUSTOM_FIELDS: {
    INVESTOR: {
      investorType: '',
      companyType: '',
      fundingStage: '',
      productStage: '',
      investmentType: '',
      companySize: '',
      plantTouching: '',
      minAmount: '',
      maxAmount: '',
      companyName: '',
      jobTitle: '',
      location: '',
    },
    PROFESSIONAL: {
      location: '',
      jobTitle: '',
      companyName: '',
    },
    COMPANY_PROFILES: {
      companyStage: '',
      companySize: '',
      minAmount: '',
      maxAmount: '',
      investmentType: '',
      companyType: '',
      location: '',
    },
    FUND_RAISING: {
      companyName: '',
      estValuationMax: '',
      estValuationMin: '',
      fundingStage: '',
      licencing: '',
      location: '',
      maxAmount: '',
      minAmount: '',
      minInvestmentMax: '',
      minInvestmentMin: '',
      plantTouching: '',
      productStage: '',
      investmentType: '',
    },
  },
  REGISTRATION_STEP: {
    COMPANY_STEP_02: 2,
    COMPANY_STEP_03: 3,
    INVESTOR_STEP_02: 2,
    INVESTOR_STEP_03: 3,
    INVESTOR_STEP_04: 4,
    NETWORKER_STEP_02: 2,
    NETWORKER_STEP_03: 3,
    PROFESSIONAL_STEP_02: 2,
    PROFESSIONAL_STEP_03: 3,
  },
  ALERT: {
    INFO: 1,
    ERROR: 4,
    SUCCESS: 2,
    WARNING: 3,
    UNAVAILABLE: 5,
    SHOW: 200,
    HIDE: 400,
    FORBIDDEN: 403,
    UNAUTORIZED: 401,
  },
  ALERT_TTL: {
    STAY_FIVE_SEC: 10000,
  },
  ALERT_BUTTON: {
    SUCCESS: 2,
    WARNING: 3,
    ERROR: 4,
  },
  POP_ALERT: {
    INFO: 10,
    ERROR: 40,
    SUCCESS: 20,
    WARNING: 30,
    SHOW: 500,
    HIDE: 600,
    EVENT: 700,
    OK: 2000,
    CLOSE: 4000,
    SUCCESS_V2: 21,
  },
  ERROR_MESSAGES: {
    FORBIDDEN:
      'It seems the service that you requested is not responding at the moment. Please try again in a while!',
    UNAUTORIZED: 'Your authorization failed.',
    EXCEPTION_MESSAGE:
      'The service is unavailable due to an unknown error.  Please try again in a while',
  },
  SUBMIT_ACTION: {
    NEXT: 1,
    PREVIOUS: 2,
  },
  PROFILE_TYPES: {
    COMPANY: 'company',
    INDIVIDUAL: 'individual',
    INDIVIDUAL_PERSONAL_PREF: 'individual-personal-preferences',
  },
  SEARCH_LIMIT: 9,
  FUND_RAISING_SEARCH_LIMIT: 15,
  FILTER_SEARCH_LIMIT: 9,
  CONNECTIONS_LIMIT: 9,
  RECOMMONED_CONNECTIONS_LIMIT: 0,
  PENDING_CONNECTIONS_LIMIT: 9,
  CONNECTIONS_SECTION: {
    MY_CONNECTIONS: 1,
    PENDING: 2,
    SEARCH: 3,
    SUGGESTED: 4,
  },
  NEWS_FEED_LIMIT: 8,
  LIKED_USERS_LIMIT: 8,
  MESSAGE_LIMIT: 16,
  FETCH_COMMENT_LIMIT: 10,
  MESSAGE_INBOX: {
    COMPANY: '3',
  },
  MESSAGE_SECTION_TYPES: {
    All: {
      id: '0',
      label: 'All Messages',
      icon: 'fas fa-globe',
      section: 'ALL_MESSAGES',
    },
    PERSONAL: {
      id: '1',
      label: 'Personal Messages',
      icon: 'fas fa-user-alt',
      section: 'PERSONAL_MESSAGES',
    },
    CONNECTIONS: {
      id: '2',
      label: 'Connect Requests',
      icon: 'fas fa-user-friends',
      section: 'CONNECT_REQUESTS',
    },
    PRODUCTS: {
      id: '3',
      label: 'Marketplace Requests',
      icon: 'fas fa-store',
      section: 'MARKETPLACE_REQUESTS',
    },
    GREENPAGES: {
      id: '4',
      label: 'GreenPage Requests',
      icon: 'fas fa-store',
      section: 'GREENPAGES_REQUESTS',
    },
  },
  MESSAGE_TYPE: {
    0: '',
    1: 'ALL',
    2: 'PM',
    3: 'MP IR',
  },
  MESSAGE_STATUS: {
    ACTIVE: 1,
    ARCHIVED: 2,
    BLOCKED: 3
  },
  DOMAINS: {
    S3: 'amazonaws.com/',
    YOUTUBE: 'https://www.youtube.com/',
    FACEBOOK: 'facebook.com/',
    INSTAGRAM: 'instagram.com/',
    LINKEDIN: 'linkedin.com/',
    TWITTER: 'twitter.com/',
  },
  COMMUNICATION_PREFERENCES: {
    CONNECTION_REQUESTS: 1,
    MATCHED_INVESTORS: 2,
    WEEKLY_NEW_MATCHED_INVESTORS: 3,
    WEEKLY_NEWSFEED_UPDATES: 4,
    LEAFWIRE_PARTNER_COMMUNICATIONS: 5,
    UPDATES_ON_COMPANIES_I_FOLLOW: 6,
    INVESTMENT_OPPORTUNITIES: 7,
    WEEKLY_NEW_FUNDING_REQUESTS: 8,
    UPDATES_ON_COMPANIES_I_INVESTED_IN: 9,
    OPT_OUT_OF_ALL_COMMUNICATIONS: 10,
    OPT_IN_FOR_ALL_COMMUNICATIONS: 1000,
    MESSAGE_NOTIFICATION: 12,
    POST_COMMENT_NOTIFICATION: 13,
  },
  REDIRECT_BLOCK: {
    INCLUDES: ['/invite-user', '/set-password', '/event/', '/welcome'],
    EXCLUDES: ['/', '/auth/yahoo', '/mjbiz', '/landing'],
  },
  ALLOWED_TYPES: {
    IMAGES: ['.png', '.jpg', '.jpeg', '.tiff', '.bmp'],
    DOCS: ['.doc', '.xls', '.ppt', '.docx', '.pptx', '.xlsx', '.txt'],
  },
  SHOW_FILE_TYPES: {
    IMAGE: ['.png', '.jpg', '.jpeg', '.tiff', '.bmp'],
    DOC: ['.doc', '.docx', '.txt'],
    EXCEL: ['.xls', '.xlsx'],
    CSV: ['.csv'],
    PPT: ['.ppt', '.pptx'],
    PDF: ['.pdf'],
  },
  DATAROOM_DOC_LIMIT: 5,
  DATAROOM_PENDING_REQUEST_LIMIT: 5,
  DATAROOM_SHAREDUSERS_LIMIT: 5,
  DATAROOM_STATISTICS_LIMIT: 5,
  FUNDRAISING_DETAILS_PENDING_REQUEST_LIMIT: 5,
  FUNDRAISING_DETAILS_SHAREDUSERS_LIMIT: 5,
  FILE_TYPES: {
    IMAGES: [
      'image/gif',
      'image/png',
      'image/jpeg',
      'image/jpg',
      'image/bmp',
      'image/webp',
    ],
    DOCUMENTS: ['application/pdf', 'application/docx', 'application/doc'],
    DATA_ROOM: [
      'image/gif',
      '.gif',
      'image/png',
      '.png',
      'image/jpeg',
      '.jpeg',
      'image/jpg',
      '.jpg',
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      '.xlsx',
      'text/csv',
      '.csv',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      '.doc',
      '.docx',
      'application/vnd.oasis.opendocument.spreadsheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      '.pptx',
      'application/vnd.ms-excel',
      'text/plain',
      '.txt',
    ],
    GREEN_PAGES_ADMIN: [
      'text/csv',
      '.csv',
      'application/vnd.ms-excel',
    ],
    DATA_ROOM_FILTER: [
      {
        value: 'gif',
        label: 'Image/gif',
      },
      {
        value: 'png',
        label: 'Image/png',
      },
      {
        value: 'jpeg',
        label: 'Image/jpeg',
      },
      {
        value: 'jpg',
        label: 'Image/jpg',
      },
      {
        value: 'pdf',
        label: 'PDF (Portable Document Format File)',
      },
      {
        value: 'xlsx',
        label: 'Microsoft Excel Open XML Spreadsheet(.xlsx)',
      },
      {
        value: 'doc',
        label: 'Microsoft Word Document(.doc)',
      },
      {
        value: 'docx',
        label: 'Microsoft Word Open XML Document(.docx)',
      },
      {
        value: 'pptx',
        label: 'Microsoft PowerPoint Document(.pptx)',
      },
      {
        value: 'txt',
        label: 'Text document',
      },
      {
        value: 'csv',
        label: 'CSV (Comma Separated Values File)',
      },
    ],
  },
  SUB_MENU_TYPES: {
    SUMMARY: 1,
    FUNDRASING: 2,
    PITCH_DECK: 3,
    DATA_ROOM: 4,
    FINANCIALS_ACCESS: 5,
  },
  ADMIN_SUB_MENU_TYPES: {
    SUMMARY: 'Summary',
    FUNDRAISING: 'Data Room',
    EVENTS: 'Events',
    MARKETPLACE: 'Marketplace',
    POST_A_JOB: 'Post a Job',
    LEAD_GENERATION: 'Lead Generation',
    BOARD_ROOM: 'Board Room',
    ADVERTISE: 'Advertise',
  },
  CHANGE_SUB_MENU: 'CHANGE_SUB_MENU',
  ACTION_TYPES: {
    DEFAULT: 0,
    ACCEPT: 1,
    IGNORE: 2,
  },
  DATAROOM_ACCESS_TYPE: {
    ACCEPTED: 1,
    REJECTED: 2,
    PENDING: 3,
    REVOKED: 4,
    REGRANTED: 5,
  },
  PREFERENCES: {
    INVESTOR: [8194, 8413, 8414, 9034, 8416, 9043, 8417],
    PROFESSIONAL: [8194, 9033, 8413, 8414, 8415, 9034],
  },
  PAYMENT_GATEWAYS: {
    BLUE_SNAP: 1,
    STRIPE: 2,
  },
  SUBSCRIPTION_MODULES: {
    EVENTS: SUBSCRIPTION_MODULES.EVENTS,
    JOB_BOARD: SUBSCRIPTION_MODULES.JOB_BOARD,
    MARKETPLACE: SUBSCRIPTION_MODULES.MARKETPLACE,
    FUNDRAISING: SUBSCRIPTION_MODULES.FUNDRAISING,

    MARKETPLACE_BASIC: SUBSCRIPTION_MODULES.MARKETPLACE_BASIC,
    MARKETPLACE_PROFESSIONAL: SUBSCRIPTION_MODULES.MARKETPLACE_PROFESSIONAL,
    MARKETPLACE_PRO_PLUS: SUBSCRIPTION_MODULES.MARKETPLACE_PRO_PLUS,
    MARKETPLACE_PLATINUM: SUBSCRIPTION_MODULES.MARKETPLACE_PLATINUM,

    GREENPAGES: SUBSCRIPTION_MODULES.GREENPAGES
  },
  BLUE_SNAP_SUBSCRIPTION_MODULES: {
    /*2444303: {
            ID: 2444303,
            SHORT_NAME: 'Event',
            NAME: 'Event Subscription',
            ICON: 'far fa-calendar-alt tool__button__icon '
        },*/
    2444305: {
      // This is the planId in production
      ID: 2444305, // 3535918 Prod Weekly
      SHORT_NAME: 'Data Room',
      NAME: 'Data Room Subscription',
      ICON: 'fa fa-database tool__button__icon ',
    },
    2464903: {
      // This is the planId in sandbox
      ID: 2464903, // Weekly
      SHORT_NAME: 'Marketplace',
      NAME: 'Marketplace Subscription',
      ICON: 'fas fa-shopping-cart  tool__button__icon',
    },
    2510385: {
      //marketplace plan with trial monthly sandbox
      ID: 2510385, // Weekly
      SHORT_NAME: 'Marketplace',
      NAME: 'Marketplace Subscription',
      ICON: 'fas fa-shopping-cart  tool__button__icon',
    },
    2577825: {
      //marketplace plan with annual sandbox
      ID: 2577825, // Annual
      SHORT_NAME: 'Marketplace',
      NAME: 'Marketplace Subscription',
      ICON: 'fas fa-shopping-cart  tool__button__icon',
    },
    2577827: {
      //marketplace plan with trial annual sandbox
      ID: 2577827, // Annual
      SHORT_NAME: 'Marketplace',
      NAME: 'Marketplace Subscription',
      ICON: 'fas fa-shopping-cart  tool__button__icon',
    },
    2530355: {
      // Job Board Basic Subscription
      ID: 2530355, // Weekly
      SHORT_NAME: 'Job Board',
      NAME: 'Job Board Subscription',
      ICON: 'fas fa-briefcase  tool__button__icon',
    },
    2530353: {
      // Job Board Basic Trial Subscription
      ID: 2530353, // Weekly
      SHORT_NAME: 'Job Board',
      NAME: 'Job Board Subscription',
      ICON: 'fas fa-briefcase  tool__button__icon',
    },
    2530357: {
      // Job Board Intermediate Subscription
      ID: 2530357, // Weekly
      SHORT_NAME: 'Job Board',
      NAME: 'Job Board Subscription',
      ICON: 'fas fa-briefcase  tool__button__icon',
    },
    2530359: {
      // Job Board Intermediate Trial Subscription
      ID: 2530359, // Weekly
      SHORT_NAME: 'Job Board',
      NAME: 'Job Board Subscription',
      ICON: 'fas fa-briefcase  tool__button__icon',
    },
    2530363: {
      // Job Board Premium Subscription
      ID: 2530363, // Weekly
      SHORT_NAME: 'Job Board',
      NAME: 'Job Board Subscription',
      ICON: 'fas fa-briefcase  tool__button__icon',
    },
    2530361: {
      // Job Board Premium Trial Subscription
      ID: 2530361, // Weekly
      SHORT_NAME: 'Job Board',
      NAME: 'Job Board Subscription',
      ICON: 'fas fa-briefcase  tool__button__icon',
    },
    2530367: {
      // Job Board Enterprise Subscription
      ID: 2530367, // Weekly
      SHORT_NAME: 'Job Board',
      NAME: 'Job Board Subscription',
      ICON: 'fas fa-briefcase  tool__button__icon',
    },
    2530365: {
      // Job Board Enterprise Trial Subscription
      ID: 2530365, // Weekly
      SHORT_NAME: 'Job Board',
      NAME: 'Job Board Subscription',
      ICON: 'fas fa-briefcase  tool__button__icon',
    },
    2530371: {
      // Job Board Ultimate Subscription
      ID: 2530371, // Weekly
      SHORT_NAME: 'Job Board',
      NAME: 'Job Board Subscription',
      ICON: 'fas fa-briefcase  tool__button__icon',
    },
    2530369: {
      // Job Board Ultimate Trial Subscription
      ID: 2530369, // Weekly
      SHORT_NAME: 'Job Board',
      NAME: 'Job Board Subscription',
      ICON: 'fas fa-briefcase  tool__button__icon',
    }
  },
  BLUE_SNAP_PLAN_IDS: {
    /*EVENTS: {
            PLAN: 2444303, // 2429287 -: Daily,
            NAME: 'Events Manager'
        },*/
    DATA_ROOM: {
      PLAN: process.env.REACT_APP_BLUE_SNAP_PLAN_IDS_DATA_ROOM_PLAN.split(
        ' '
      ).map((id) => {
        return parseInt(id);
      }), // 3535918 - Prod Weekly // 2444305 -: Weekly, // 2429259 -: Daily
      NAME: process.env.REACT_APP_BLUE_SNAP_PLAN_IDS_DATA_ROOM_NAME,
    },
    MARKETPLACE: {
      PLANS: process.env.REACT_APP_BLUE_SNAP_PLAN_IDS_MARKETPLACE_PLAN.split(
        ' '
      ).map((id) => {
        return parseInt(id);
      }), // Weekly [Sandbox account]
      TRIAL_PLANS:
        process.env.REACT_APP_BLUE_SNAP_PLAN_IDS_MARKETPLACE_TRIAL_PLANS.split(
          ' '
        ).map((id) => {
          return parseInt(id);
        }),
      NAME: process.env.REACT_APP_BLUE_SNAP_PLAN_IDS_MARKETPLACE_NAME,
    },
    JOB_BOARD: {
      PLANS: process.env.REACT_APP_BLUE_SNAP_PLAN_IDS_JOB_BOARD_PLAN.split(
        ' '
      ).map((id) => {
        return parseInt(id);
      }),
      NAME: process.env.REACT_APP_BLUE_SNAP_PLAN_IDS_JOB_BOARD_NAME,
    },
    /*JOB_BOARD_BASIC: {
            PLAN: 2530355,
            NAME: 'Job Board Listing'
        },
        JOB_BOARD_BASIC_TRIAL: {
            PLAN: 2530353,
            NAME: 'Job Board Listing'
        },
        JOB_BOARD_INTERMEDIATE: {
            PLAN: 2530357,
            NAME: 'Job Board Listing'
        },
        JOB_BOARD_INTERMEDIATE_TRIAL: {
            PLAN: 2530359,
            NAME: 'Job Board Listing'
        },
        JOB_BOARD_PREMIUM: {
            PLAN: 2530363,
            NAME: 'Job Board Listing'
        },
        JOB_BOARD_PREMIUM_TRIAL: {
            PLAN: 2530361,
            NAME: 'Job Board Listing'
        },
        JOB_BOARD_ENTERPRISE: {
            PLAN: 2530367,
            NAME: 'Job Board Listing'
        },
        JOB_BOARD_ENTERPRISE_TRIAL: {
            PLAN: 2530365,
            NAME: 'Job Board Listing'
        },
        JOB_BOARD_ULTIMATE: {
            PLAN: 2530371,
            NAME: 'Job Board Listing'
        },
        JOB_BOARD_ULTIMATE_TRIAL: {
            PLAN: 2530369,
            NAME: 'Job Board Listing'
        }*/
  },
  JOB_BOARD_PLAN_TYPES: [
    {
      PLAN: 2530355,
      NAME: 'Basic',
      NO_OF_JOBS: '1',
      AMOUNT: '79.99',
      COLOR: '#35c8e2',
      NUMBERTEXT: 'Job listing per month',
      IS_TRIAL: false,
    },
    {
      PLAN: 2530353,
      NAME: 'Job Board Basic Trial Listing',
      NO_OF_JOBS: '1',
      IS_TRIAL: true,
    },
    {
      PLAN: 2530357,
      NAME: 'Intermediate',
      NO_OF_JOBS: '5',
      AMOUNT: '149.99',
      COLOR: '#f8ca4d',
      NUMBERTEXT: 'Job listings per month',
      IS_TRIAL: false,
    },
    {
      PLAN: 2530359,
      NAME: 'Job Board Intermediate Trial Listing',
      NO_OF_JOBS: '2 to 5',
      IS_TRIAL: true,
    },
    {
      PLAN: 2530363,
      NAME: 'Premium',
      NO_OF_JOBS: '25',
      AMOUNT: '299.99',
      COLOR: '#a2ce6e',
      NUMBERTEXT: 'Job listings per month',
      IS_TRIAL: false,
    },
    {
      PLAN: 2530361,
      NAME: 'Job Board Premium Trial Listing',
      NO_OF_JOBS: '6 to 25',
      IS_TRIAL: true,
    },
    {
      PLAN: 2530367,
      NAME: 'Enterprise',
      NO_OF_JOBS: '100',
      AMOUNT: '499.99',
      COLOR: '#ff5947',
      NUMBERTEXT: 'Job listings per month',
      IS_TRIAL: false,
    },
    {
      PLAN: 2530365,
      NAME: 'Job Board Enterprise Trial Listing',
      NO_OF_JOBS: '26 to 100',
      IS_TRIAL: true,
    },
    {
      PLAN: 2530371,
      NAME: 'Ultimate',
      NO_OF_JOBS: 'More than 100',
      AMOUNT: '999.99',
      COLOR: '#d304af',
      NUMBERTEXT: 'Job listings per month',
      IS_TRIAL: false,
    },
    {
      PLAN: 2530369,
      NAME: 'Job Board Ultimate Trial Listing',
      NO_OF_JOBS: '100+',
      IS_TRIAL: true,
    },
  ],
  BLUE_SNAP_SUBSCRIPTION_STATUS: {
    ACTIVE: 'ACTIVE',
    CANCELED: 'CANCELED',
    SUSPENDED: 'SUSPENDED',
    DELETED: 'DELETED',
  },
  PAID_MODULE_STATUS: {
    ACTIVE: 1,
    PENDING_CANCEL: 2,
    CANCELED: 3,
  },
  SEARCH_TYPE: {
    TOP_SEARCH: 1,
    ADVANCE_SEARCH: 2,
  },
  TEXT_AREA: {
    COMMENT_LENGTH: 500,
    COMMENT_REMAINING_CHARACTERS: 5,
    PRODUCT_SHOWCASE_LENGTH: 250,
    PRODUCT_SHOWCASE_REMAINING_CHARACTERS: 5,
    READ_MORE_LENGTH: 50,
    MARKETPLACE_NAME: 75,
    MARKETPLACE_NAME_BASIC: 25,
    MARKETPLACE_DESCRIPTION_BASIC: 105,
    MARKETPLACE_DESCRIPTION: 2000,
    MARKETPLACE_AD_COPY: 70,
    MARKETPLACE_LINK: 500,
    PROMO_CODE: 12,
    PROMO_DISC: 25,
  },
  CONNECTION_STATUS: {
    PENDING: 1,
    CONNECTED: 2,
    BLOCKED: 3,
    DELETED: 4,
  },
  CONNECT_VIA: {
    NEWSFEED: 1,
    LIKE_MODAL: 2,
  },
  PAYMENT_HISTORY: {
    LIMIT: 5,
  },
  IMAGE_SIZE: {
    MAX_SIZE: 2000,
    MAX_WIDTH_SIZE: 7680,
    MAX_HEIGHT_SIZE: 4320,
    NEWSFEED_AVATAR_MIN_SIZE: 450,
    NEWSFEED_POST_IMAGE_SIZE: 650,
    MARKETPLACE_COVER_PIC: 300,
    MARKETPLACE_SOCIAL_MEDIA_PIC: {
      height: 628,
      width: 1200,
    },
  },
  STATUS: {
    EMPTY: 0,
    NOT_DELETED: 1,
    DELETED: 2,
    TEMPORY_DELETED: 3,
  },
  PRODUCT_TYPES: {
    PUBLISHED: 1,
    SAVED: 2,
    DELETED: 3,
  },
  PRODUCT_SHOW_LIMIT: 12,
  PRODUCT_PUBLIC_SHOW_LIMIT: 15,
  PRODUCT_COUNTRY_ORDER_LIMIT: 10,
  MARKETPLACE: {
    FIELDS: {
      CATAGORY: 1,
      NAME: 2,
      COVER: 3,
      PRODUCT_DESC: 4,
      VIDEO_URL: 5,
      PRICE: 6,
      PRICE_DESC: 7,
      TAGS: 8,
    },
    PRICES: {
      ONE_CATEGORY: 49.5,
      ADDITIONAL_ONE_CATEGORY: 24.5,
    },
    PRICE: {
      FIRST: 49.5,
      ADDITIONAL: 24.5,
    },
    LOCATION: {
      WIDGET: 1,
      PUBLIC_VIEW: 0,
    },
  },
  SUBSCRIPTION_STATUS: {
    ACTIVE: 1,
    PENDING_CANCEL: 2,
    CANCELED: 3,
  },
  DELETED_PRODUCT_LIMIT: 5,
  EXPERT_CONTRIBUTORS_LIMIT: 10,
  ADMIN_POST_SEARCH_LIMIT: 10,
  ADMIN_POST_SEARCH_TYPE: {
    POST: 1,
    COMMENT: 2,
    BOTH: 3,
  },
  ADMIN_COMPANY_SEARCH_LIMIT: 10,
  INDUSTRY_EVENTS_LIMIT: 15,
  COUPON_DETAILS_LIMIT: 10,
  REWARD_REDEMPTION_LIMIT: 25,

  COUPON_APPLICABLE_AREA: [
    {
      value: 0,
      label: 'All Services',
    },
    {
      value: SUBSCRIPTION_MODULES.MARKETPLACE,
      label: 'All Marketplace Packages',
    },
    {
      value: SUBSCRIPTION_MODULES.MARKETPLACE_BASIC,
      label: 'Marketplace: Only Basic Package',
    },
    {
      value: SUBSCRIPTION_MODULES.MARKETPLACE_PROFESSIONAL,
      label: 'Marketplace: Only Professional Package',
    },
    {
      value: SUBSCRIPTION_MODULES.MARKETPLACE_PRO_PLUS,
      label: 'Marketplace: Only Pro-Plus Package',
    },
    {
      value: SUBSCRIPTION_MODULES.MARKETPLACE_PLATINUM,
      label: 'Marketplace: Only Platinum Package',
    },
    {
      value: SUBSCRIPTION_MODULES.JOB_BOARD,
      label: 'All Job Board Packages',
    },
  ],
  PUBLISHED_PRODUCTS_LIMIT: 10,
  MARKETPLACE_STATISTICS_LIMIT: 10,
  MARKETING_CAMPAIGN_STATISTICS_LIMIT: 10,
  MARKETING_CAMPAIGN_SUMMARY_LIMIT: 10,
  NEWS_FEED_TEXT_LINK_SUMMARY_LIMIT: 10,
  LEAFWIRE_ADMIN_EMAILS: process.env.REACT_APP_LEAFWIRE_ADMIN_EMAILS
    ? process.env.REACT_APP_LEAFWIRE_ADMIN_EMAILS.split(' ')
    : [],
  MARKETPLACE_SINGLE_VIEW_URL: {
    ADMIN_VIEW: 'adminView',
    PUBLIC_VIEW: 'publicView',
    CREATE_PREVIEW: 'createPreview',
    EDIT_PREVIEW: 'editPreview',
  },
  MARKETPLACE_PRODUCT_STATUS: {
    PUBLISHED: 1,
    SAVED: 2,
  },
  MARKETPLACE_FORM_TYPES: {
    CREATE: '1',
    EDIT: '2',
  },
  MARKETPLACE_PREVIEW_TYPES: {
    MARKETPLACE: 0,
    NEWSFEED: 1,
    WIDGET: 2,
    FULLPAGE: 3,
  },
  ALERT_BANNER_MESSAGE: {
    UPDATE_STATE:
      'Your state is missing from your profile. This might impact your search results. Please update the state by editing your profile',
    UPDATE_STATE_COMPANY:
      'Your company state is missing from your company profile. This might impact your search results. Please update the state by editing your company profile',
    PROFILE_INCOMPLETE:
      'You won’t come up in other members’ searches for new connections until you ',
    ADMIN_PUBLISH_EVENT_NOT_ALLOWED:
      'No active subscription attached to this event',
  },
  IMAGE_TYPE: {
    THUMBNAIL: 1,
    ORIGINAL: 0,
  },
  WELCOME_MESSAGE:
    "You are now part of the world's largest \n cannabis business community.",
  WELCOME_MESSAGE_SUB:
    'To help us continue improving Leafwire, which choice(s) \n best describe your role in the industry? (Please check all that apply)',
  // Please update in types/data if there's any change
  INDUSTRY_ROLE: [
    { NAME: 'Sales', ID: 11 },
    { NAME: 'Finance / Accounting', ID: 12 },
    { NAME: 'Marketing', ID: 13 },
    { NAME: 'Retail/Dispensary', ID: 3 },
    { NAME: 'Grower/Cultivation', ID: 1 },
    { NAME: 'Product Development', ID: 14 },
    { NAME: 'Operations', ID: 15 },
    { NAME: 'Science/Research', ID: 6 },
    { NAME: 'Legal', ID: 17 },
    { NAME: 'Technology', ID: 7 },
    { NAME: 'Manufacturing', ID: 16 },
    { NAME: 'Media', ID: 5 },
    { NAME: 'HR / Recruiting', ID: 18 },
    { NAME: 'Other', ID: 10 },
    /*{ NAME: 'Ancillary Service Provider', ID: 2 },
        { NAME: 'Policy Advocate', ID: 4 },
        { NAME: 'Caregiver', ID: 8 },
        { NAME: 'Investor', ID: 9 },*/
  ],
  EXPERIENCE_YEARS: [
    { value: 0, label: 'None' },
    { value: 1, label: '1 Year' },
    { value: 2, label: '2 Years' },
    { value: 3, label: '3 Years' },
    { value: 4, label: '4 Years' },
    { value: 5, label: '5+ Years' },
  ],
  EXPERIENCE_YEARS_CANDIDATE_POPUP: [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 6, label: '5+' },
  ],
  JOB_LEVEL: [
    { value: 0, label: 'C-Level/Owner' },
    { value: 1, label: 'VP/Director' },
    { value: 2, label: 'Supervisor/Manager' },
    { value: 3, label: 'Staff' },
    { value: 4, label: 'Other' },
  ],
  PREFERRED_JOB_FUNCTION: [
    { value: 0, label: 'Sales' },
    { value: 1, label: 'Marketing' },
    { value: 2, label: 'Cultivation' },
    { value: 3, label: 'Operations / Logistics' },
    { value: 4, label: 'Manufacturing / Distribution' },
    { value: 5, label: 'HR / Talent / Recruiting' },
    { value: 6, label: 'Finance / Accounting' },
    { value: 7, label: 'Retail' },
    { value: 8, label: 'Tech / Engineering' },
    { value: 9, label: 'Science / Lab' },
    { value: 10, label: 'Product Development' },
    { value: 11, label: 'Other' },
  ],
  WILLING_TO_RELOCATE: [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ],
  POST_TYPES: {
    POST: 1, // only newfeed post or both newsfeed and group post
    GROUP_POST: 2, // only shared within groups
  },
  POST_STATUS: {
    ACTIVE: 1,
    ARCHIVED: 2,
  },
  NAVIGATION_PAGE: {
    HOME: 0,
    MARKETPLACE_PUBLIC: 1,
    MARKETPLACE_CREATE: 2,
    FUNDRAISING_COMPANIES: 3,
    FILTER_CONNECTIONS: 4,
    MESSAGES: 5,
    EVENTS: 6,
    GROUPS: 7,
    TRAINING: 8,
    PERSONAL_PROFILE: 9,
    DEFAULT: 100,
    PRESS:10,
    GREEN_PAGES: 11
  },
  VIEW_COUNT_TYPE: {
    VIEW_BY_CLICK: 1,
    VIEW_BY_CONTACT: 2,
    VIEW_BY_LOAD: 3,
    VIEW_BY_EMAIL_CLICK: 4,
  },
  VIEW_ELEMENT_TYPE: {
    TEXT_LINK_NEWSFEED: 1,
    TRENDING_TOPIC: 3,
    INDUSTRY_EVENT: 4,
    YES: 5,
    NO: 6,
    POST: 7,
  },
  VIEW_COUNT_LOCATION: {
    DEFAULT: 0,
    NEWSFEED: 1,
    WIDGET: 2,
    PUBLIC_VIEW: 3,
    SINGLE_VIEW: 4,
    EVENT_PAGE: 5,
    EMAIL: 6,
    PRESS_SECTION: 7,
    MARKET_PLACE_HOMEPAGE: 8,
  },
  VIEW_COUNT_SPECIFIC_PAGE: {
    EVENTS: {
      PAGE: {
        GLOBAL: 1,
        LOCAL: 2,
        ALL: 3,
      },
      CATEGORY: {
        FEATURED: 1,
        NON_FEATURED: 2,
      },
    },
  },
  STATISTIC_SEARCH_TYPE: {
    PRODUCT: 1,
    COMPANY: 2,
  },
  COMPANY_LIST_POPUP: {
    ADD_LISTING: 1,
    EDIT_LISTING: 2,
    POST_A_JOB: 3,
  },
  COMPANY_LIST_POPUP_TEXT: {
    ADD: {
      HEADER: 'Select a company to create a new listing',
      BUTTON: 'Create New Listing',
      BUTTON_LINK: '/marketplace/product-create/',
    },
    EDIT: {
      HEADER: 'Select a company to edit an existing listing',
      BUTTON: 'Edit existing listing',
      BUTTON_LINK: '/marketplace/company/',
    },
    JOB: {
      HEADER: 'Select a company to post a job',
      BUTTON: 'Post a Job',
      BUTTON_LINK: '/profile-edit/company/',
      PARAMS: '?post-a-job',
    },
  },
  CREATE_COMPANY_POPUP: {
    TO_CREATE_PRODUCT: 1,
    TO_POST_A_JOB: 2,
  },
  COUPON_FETCHING: {
    NOT_FETCHING: 0,
    FETCHING: 1,
    SUCCESS: 2,
    FAILED: 3,
  },
  COUPON_TYPE: {
    DISCOUNT: 1,
    TRAIL: 2,
  },
  COUPON_FREQUENCY_TYPE: {
    ONE_TIME: 1,
    REUSABLE: 2,
  },
  PANEL_TYPES: {
    CONVERSATION_THREADS: 1,
    CONNECTIONS: 2,
  },
  PREFERRED_PARTNERS_LIMIT: 10,
  REPORT_LOCATIONS: {
    POST: 1,
    COMMENT: 2,
    USER_PROFILE: 3,
    COMPANY_PROFILE: 4,
    MARKETPLACE_LISTING: 5,
    CONVERSATIONS: 6,
    NAMES: {
      POST: 'Post',
      COMMENT: 'Comment',
      USER_PROFILE: 'User',
      COMPANY_PROFILE: 'Company',
      MARKETPLACE_LISTING: 'Product',
      CONVERSATIONS: 'Conversation',
    },
  },
  ADMIN_SECTION: {
    REPORTING: 4,
    MARKETPLACE_STATISTICS: 5,
    EXPERT_CONTRIBUTORS: 7,
    COUPON_GENERATOR: 8,
    MARKETING_CAMPAIGN: 10,
    POSTS_AND_COMMENTS: 11,
    USERS: 12,
    MARKETPLACE_PRIORITIZATION: 13,
    COMPANY_ADMIN: 14,
    PREFERRED_PARTNERS: 15,
    LEAFWIRE_LINKS: 17,
    NEWSFEED_TEXT_LINKS: 18,
    NEWSFEED_TEXT_LINKS_REPORT: 19,
    MENTION_TAGS: 20,
    REWARD_REDEMPTION: 21,
    EMAIL_DIET: 22,
    TRENDING_TOPICS: 23,
    TRENDING_TOPICS_STATISTICS: 24,
    INDUSTRY_EVENTS: 25,
    INDUSTRY_EVENTS_STATISTICS: 26,
    PROMO_AREA: 27,
    BOARD_MEETING_REPORTS: 28,
    MARKETPLACE_SETTINGS: 29,
    PACKAGE_DESCRIPTION_EDIOTR: 30,
    TOOL_TIP_MANAGER: 31,
    USER_REVIEW: 32,
    GROUP_STATS: 33,
    GROUP_SETTINGS: 34,
    GROUP_MANAGER: 35,
    JOB_MANAGER: 36,
    PRESS: 38,
    PRESS_DATA: 39,
    PRESS_RELEASES: 40,
    PRESS_NEWS: 41,
    PRESS_AIR: 42,
    JOB_POSITIONS: 43,
    FAQ: 44,
    EVENT_TAGS: 45,
    CUSTOM_LANDING_PAGES: 46,
    TRAINING: 47,
    DASHBOARD: 48,
    POST_SHARE_STATS: 49,
    CANDIDACY_SURVEY_REPORT: 50,
    AUTO_CONNECTION_MANAGER_NEW_USERS: 51,
    AUTO_CONNECTION_MANAGER_EXISTING_USERS: 52,
    TESTIMONIALS: 53,
    NEWSFEED_BANNER_AD: 54,
    NEWSFEED_BANNER_STATS: 55,
    EMAIL_REPORTING: 56,
    JOB_TITLE_MANAGER: 57,
    AUTO_CONNECTION_REQUEST: 58,
    WARNING_DISCLAIMER: 59,
    TRACKING_REPORTS: 60,
    PRO_STATUS: 61,
    VANITY_WORD_LIST: 62,
    PRESS_QA: 63,
    PERSONAL_DASHBOARD_EVENT_DISCOUNT: 64,
    GREEN_PAGES_BULK_UPLOAD: 65,
    GREEN_PAGES_SINGLE_VIEW: 66,
    GREEN_PAGES_MANAGE_SUBCATEGORIES: 67,
    GREEN_PAGES_REPORTING: 68,
    GREEN_PAGES_EDITOR: 69,
    GREEN_PAGES_ADD_ADVERTISER: 70,
    GREEN_PAGES_EXPORT_CSV: 71,
    MANAGE_CO_REGISTRATIONS: 72,
    CHOOSE_GP_LISTINGS: 73,
  },
  MARKETPLACE_QUICK_SEARCH_ALLOWED_CATEGORIES: ['Real Estate', 'Dispensaries'],
  LEAFWIRE_LINK: {
    SKIP: 0,
    LIMIT: 10,
  },
  TIME_DURATION: {
    TEXT_LINK: 120 * 1000,
    NEWS_FEED_BANNER_ADVETISEMENT: 20 * 1000
  },
  DEFAULT_UTM_VARIABLES: {
    NEWSFEED_TEXT_LINK: {
      UTM_SOURCE: 'Homepage',
      UTM_MEDIUM: 'TextLink',
      UTM_CAMPAIGN: 'default',
    },
  },
  NEWS_FEED_TEXT_LINK_STATUS: {
    ACTIVE: 1,
    ARCHIVED: 2,
    EXPIRED: 3,
  },
  JOB_BOARD_DATA: {
    DOMAIN: process.env.REACT_APP_JOB_BOARD_DATA_DOMAIN,
    BOARD_KEY: process.env.REACT_APP_JOB_BOARD_DATA_BOARD_KEY,
  },
  JB_STEPS: {
    ERROR: 0,
    LOADING: 1,
    NOT_REGISTERED: 2,
    NOT_SUBSCRIBED: 3,
    SUBSCRIBED: 4,
    SUBSCRIPTION_CANCELLED: 5,
  },
  TRENDING_TOPIC_STATUS: {
    ACTIVE: 1,
    ARCHIVED: 2,
  },
  EMAIL_TYPE: {
    DAY_BY_DAY: 1,
    WELCOME_DIET: 2,
  },
  EMAIL_TEMPLATE: {
    ACTIVE: 1,
    INACTIVE: 2,
  },
  HIDE_SEARCH_BAR: ['/marketplace', '/message', '/filter-connections'],
  INDUSTRY_EVENT_STATUS: {
    ACTIVE: 1,
    ARCHIVED: 2,
  },
  WELCOME_POPUP_COMPULSORY_FROM: '2019-12-09T08:51:53.910Z',
  NOTIFICATION_TYPES: {
    PERSONAL_MSG: 1,
    CONNECTIONS_MSG: 2,
    PRODUCTS_MSG: 3,
    CONNECTION_REQUESTS: 4,
    CANCEL_CONNECTION_REQUEST: 5,
    REACT_CONNECTION_REQUEST: 6,
    EVENT_UPDATE: 7,
    UPDATE_MY_CONVERSATION_LIST: 8,
    NEWSFEED_VIDEO_UPDATE: 9,
    PRO_INVITATION: 10,
    CONNECTION_LIMIT_EXTENDED: 11,
    GREENPAGES:12
  },
  NOTIFICATION: {
    PUSH: 'PUSH',
    PULL: 'PULL',
    HIDE: 'HIDE',
    FILTER: 'FILTER',
  },
  BOARD_MEETING_REPORT_TYPES: {
    MEMBERS: 1,
    POSTS: 2,
    COMMENTS: 3,
    CONNECTION_REQUESTS: 4,
    CONNECTION_ACCEPTANCES: 5,
    MARKETPLACE_COMPANIES: 6,
    COMPANIES: 7,
    POST_COUNT_BY_USER_REGISTERED_DATE: 8,
    EMPLOYEMENT_HISTORY: 9,
  },
  TRACKING_REPORT_TYPES: {
    USER_TRACKER: 1,
    NEWS_FEED_BANNER_AD_TRACKER: 2,
    POST_SHARE_TRACKER: 3,
    POST_LIKE_COMMENT_TRACKER: 4,
    OPEN_NEWS_FEED_TRACKER: 5,
    NEWS_FEED_VIEW_TRACKER: 6,
    TEXT_LINK_VIEW_TRACKER: 7,
    TRENDING_TOPICS_TRACKER: 8,
    USER_PROFILE_VIEW_TRACKER: 9,
    USER_LOGIN_TRACKER: 10,
    AUTO_CONNECTION_TRACKER: 11,
    MARKETPLACE_WEBSITE_TRACKER: 12,
    USERS_PRO_STATUS_ADMIN_CHANGES_TRACKER: 13,
    PRESS_TRACKER: 14,
    CO_REG_SUBMISSION_TRACKER: 15,
    COMPANY_DATA: 16,
    USER_DATA: 17,
    PROFESSIONAL_EXPERIENCE_DATA: 18
  },
  CONNECTION_REJECT_TYPES: {
    CANCEL_REQUEST: 1,
    REJECT_REQUEST: 2,
    DISCONNECT: 3,
  },
  RESOLVE_TYPES: {
    UNBLOCK: 1,
    BLOCK: 2,
  },
  USER_REVIEW_LIMIT: 10,
  BILLING_FREQUENCY: {
    MONTHLY: 1,
    ANNUAL: 2,
  },
  MARKETPLACE_PACKAGE_TYPES: {
    BASIC: 1,
    PROFESSIONAL: 2,
    PRO_PLUS: 3,
    PLATINUM: 4,
  },
  MARKETPLACE_PACKAGE_DETAILS: {
    1: {
      PACKAGE_NAME: 'BASIC',
      MONTHLY: 4.95,
      ANNUAL: 49.5,
      CATEGORY_LIMIT: 1,
      FEATURED_CATEGORY_LIMIT: 0,
      COLOR: '#9ccc65',
    },
    2: {
      PACKAGE_NAME: 'PROFESSIONAL',
      MONTHLY: 49.5,
      ANNUAL: 495,
      CATEGORY_LIMIT: 1,
      FEATURED_CATEGORY_LIMIT: 0,
      COLOR: '#a7499c',
    },
    3: {
      PACKAGE_NAME: 'PRO-PLUS',
      MONTHLY: 199,
      ANNUAL: 1990,
      CATEGORY_LIMIT: 2,
      FEATURED_CATEGORY_LIMIT: 2,
      COLOR: '#f3b42e',
    },
    4: {
      PACKAGE_NAME: 'PLATINUM',
      MONTHLY: 999,
      ANNUAL: 9990,
      CATEGORY_LIMIT: 5,
      FEATURED_CATEGORY_LIMIT: 2,
      COLOR: '#fd5948',
    },
  },
  ADDITIONAL_CATEGORIES: {
    CATEGORY_M: 25,
    CATEGORY_A: 250,
    FEATURED_CATEGORY_M: 50,
    FEATURED_CATEGORY_A: 500,
  },
  SYSTEM_USER_BLOCKED_STATUS: {
    NOT_BLOCKED: 0,
    BLOCKED: 1,
    RESOLVED: 2,
  },
  MARKETPLACE_INFO_POPUP: {
    IMG1: 1,
    IMG2: 2,
    IMG3: 3,
    IMG4: 4,
    OFFER: 5,
  },
  VIEW_MODULES: {
    // please note subscription module's ids are also used here by accessing from SUBSCRIPTION_MODULES
    // eg:- MARKETPLACE: 4, EVENTS: 2
    GROUPS: 7,
    TRAINING: 8,
  },
  GROUP_ACTION_TYPE: {
    JOIN: 1,
    LEAVE: 2,
  },
  RECENT_POSTS_PAGINATION: {
    SKIP: 0,
    LIMIT: 5,
  },
  HIDE_FOOTER: ['/', '/groups'],
  PRESS_ADMIN: {
    SKIP: 0,
    LIMIT: 10,
  },
  PRESS_USER: {
    SKIP: 0,
    LIMIT: 10,
  },
  PRESS_TYPES: {
    PRESS_RELEASES: 1,
    PRESS_NEWS: 2,
    PRESS_AIR: 3,
    PRESS_COMPANY: 4,
    ALL: 5,
    PRESS_QA: 6,
  },
  RELEASE_TYPES: {
    LEAFWIRE: 1,
    INDUSTRY: 2,
  },
  PRESS_FORM_TYPE: {
    CREATE: 1,
    EDIT: 2,
  },
  JOB_SURVEY: {
    STEP_01: 1,
    STEP_02: 2,
    STEP_03: 3,
    COMPLETED: 4,
    LEVEL_RECRUITER: ['Recruiter'],
    QUESTION_01:
      'Is your company looking to hire someone in the next 3 months?',
    QUESTION_02:
      'What level position(s) do you plan on hiring for? (please check all that apply)',
    RECRUITER_SURVEY_COMPLETE_MESSAGE:
      'Thank you. Leafwire’s exclusive recruiting partner, FlowerHire, will contact you to see if they can help you fill your position(s).',
    JOB_BOARD_SURVEY_COMPLETE_MESSAGE:
      'Thank you for completing Leafwire’s job survey. Have you ever used Leafwire’s Job Board? If you’d like to, you can get your first job posted for free with a 30-day free trial!',
  },
  SYSTEM_USER_STATUS: {
    ACTIVE: 1,
    DELETED: 2,
  },

  EVENT_TAB: {
    LOCAL_EVENTS: 1,
    GLOBAL_EVENTS: 2,
    ALL_EVENTS: 3,
    MANAGE_EVENTS: 4,
    CREATE_EVENTS: 5,
    WEBINARS: 6,
    EVENT_DISCOUNT: 7,
  },

  EVENT_SAVE_TYPE: {
    SAVE: 1,
    PUBLISH: 2,
  },

  EVENT_PACKAGE_TYPES: {
    BASIC: 1,
    FEATURED: 2,
  },

  EVENT_PACKAGE_DETAILS: {
    1: {
      PACKAGE_NAME: 'BASIC',
      MONTHLY: 4.99,
    },
    2: {
      PACKAGE_NAME: 'FEATURED',
      MONTHLY: 99,
    },
  },

  WEBINAR_PACKAGE_DETAILS: {
    1: {
      PACKAGE_NAME: 'BASIC',
      MONTHLY: 79.95,
    },
    2: {
      PACKAGE_NAME: 'FEATURED',
      MONTHLY: 199.95,
    },
  },

  EVENT_TYPE: {
    LOCAL: 1,
    GLOBAL: 2,
    WEBINAR: 3,
    ALL: 4,
    VIRTUAL: 5,
    IN_PERSON: 6,
    BOTH_VIRTUAL_AND_IN_PERSON: 7,
    PRO_DISCOUNT: 8,
  },

  EVENT_FORM_TYPE: {
    CREATE: 1,
    EDIT: 2,
  },

  EVENT_STATUS: {
    PUBLISHED: 1,
    DRAFT: 2,
    CANCELED: 3,
  },

  USER_EVENTS_LIMIT: 15,
  JOB_TITLES_LIMIT: 10,
  EVENT_LIST_LIMIT: 8,
  CLEAR_EVENT_LOCATION: null,
  FEATURED_EVENT_LIMIT: 15,
  EVENT_NOTIFICATION_CONNECTED_STATUS: {
    NOT_CONNECTED: 0,
    REQUESTED: 1,
    ACCEPT_PENDING: 2,
    CONNECTED: 3,
  },
  CUSTOM_LANDING_PAGE_STATUS: {
    ACTIVE: 1,
    INACTIVE: 2,
  },
  LINKED_PAGES_FOR_CUSTOM_LANGING_PAGES: {
    NEWSFEED: 1,
    GROUP_FEED: 2,
    EVENTS: 3,
    JOBS: 4,
    MARKETPLACE: 5,
    GREENPAGES: 6,
  },
  TRAINING_PAGINATION: {
    SKIP: 0,
    LIMIT: 8,
  },
  USER_PROFILE_VIEW_LOCATIONS: {
    NEWSFEED: 1,
    GROUP_FEED: 2,
    WIDGET: 3,
    MY_CONNECTIONS: 4,
    PENDING_CONNECTIONS: 5,
    RECOMMENDED_CONNECTIONS: 6,
    FIND_NEW_CONNECTIONS: 7,
    TOP_SEARCH: 8,
    ADVANCE_SEARCH: 9,
    MESSAGING_PANEL: 10,
    MESSAGING_POPUP: 11,
    ALL_EVENTS: 12,
    GLOBAL_EVENTS: 13,
    WEBINAR_EVENTS: 14,
    SOCKET: 15,
  },
  PUBLIC_PENDING_ACTIONS: {
    //19
    // connections widget
    CW_ADD_CONNECTION: 'CW_ADD_CONNECTION',
    CW_SEE_MORE: 'CW_SEE_MORE',
    CW_SEE_PROFILE: 'CW_SEE_PROFILE',

    //profile
    PW_EDIT: 'PW_EDIT',
    PW_MY_POSTS: 'PW_MY_POSTS',

    //groups
    GW_JOIN: 'GW_JOIN',

    //trending
    TW_LOAD_TOPIC: 'TW_LOAD_TOPIC',

    //marketplace
    MW_SEE_MORE: 'MW_SEE_MORE',
    MW_CONTACT: 'MW_CONTACT',
    MW_SEE_DESC: 'MW_SEE_DESC',

    //industry events
    IW_SEE_MORE: 'IW_SEE_MORE',
    IW_LOAD_TOPIC: 'IW_LOAD_TOPIC',

    // post
    P_LIKE: 'P_LIKE',
    P_COMMENTS: 'P_COMMENTS',
    P_VIEW_LIKES: 'P_VIEW_LIKES',
    P_VIEW_USER: 'P_VIEW_USER',
    P_LIKE_COMMENT: 'P_LIKE_COMMENT',
    P_VIEW_COMMENT_LIKES: 'P_VIEW_COMMENT_LIKES',

    P_SHARE_MSG: 'P_SHARE_MSG',

    P_VIEW_GROUP_POST: 'P_VIEW_GROUP_POST',
    P_VIEW_POST: 'P_VIEW_POST',

    //marketplace Ad
    MA_VISIT_MARKETPLACE: 'MA_VISIT_MARKETPLACE',
    MA_VISIT_COMPANY: 'MA_VISIT_COMPANY',

    // jobs
    JW_VIEW_JOB: 'JW_VIEW_JOB',

    // top nav
    TN_REDIRECT: 'TN_REDIRECT',

    APPLY_JOB: 'APPLY_JOB',
    // TN_JOBS: 'TN_JOBS', // removed - top nav job icon now redirects to http://www.w3schools.com is user not logged in
  },
  PUBLIC_AUTH_TYPE: {
    LOGIN: 0,
    REGISTER: 1,
  },
  SHARED_PLATFORMS: {
    OTHER: 0,
    FACEBOOK: 1,
    TWITTER: 2,
    LINKEDIN: 3,
    EMAIL: 4,
    MESSAGING_PANEL: 5,
    UNKNOWN: 6,
  },
  SHARE_ACTION: {
    SHARE_FROM_LEAFWIRE: 1,
    REDIRECT_TO_LEAFWIRE: 2,
    NEWS_FEED_VIEW_TRACK: 3,
  },
  CANDIDATE_POPUP_SOURCE: {
    POPUP: 0,
    INTERNAL_EMAIL: 1,
    PARTNER_EMAIL: 2,
    WIDGET: 3,
    JOB_BOARD: 4,
    ALL: 10,
  },
  CANDIDATE_POPUP_STEP: {
    CLOSED: 0,
    PAGE_1: 1,
    PAGE_2: 2,
    PAGE_3: 3,
    PAGE_4: 4,
    FINAL: 5,
  },
  AUTO_CONNECTION_SENDER_TYE: {
    NEW_USERS: 1,
    EXISTING_USERS: 2,
  },
  AUTO_CONNECTION_DAY_NUMBERS: [
    {
      label: 'Day 00',
      value: 0,
    },
    {
      label: 'Day 01',
      value: 1,
    },
    {
      label: 'Day 02',
      value: 2,
    },
    {
      label: 'Day 03',
      value: 3,
    },
    {
      label: 'Day 04',
      value: 4,
    },
    {
      label: 'Day 05',
      value: 5,
    },
    {
      label: 'Day 06',
      value: 6,
    },
    {
      label: 'Day 07',
      value: 7,
    },
    {
      label: 'Day 08',
      value: 8,
    },
    {
      label: 'Day 09',
      value: 9,
    },
    {
      label: 'Day 10',
      value: 10,
    },
    {
      label: 'Day 11',
      value: 11,
    },
    {
      label: 'Day 12',
      value: 12,
    },
    {
      label: 'Day 13',
      value: 13,
    },
    {
      label: 'Day 14',
      value: 14,
    },
  ],
  AUTO_CONNECTION_LIMIT: 20,
  ADMIN_TESTIMONIALS_COUNT: 15,
  ADMIN_TESTIMONIAL_FORM: {
    CREATE: 1,
    UPDATE: 2,
  },
  ADMIN_TESTIMONIAL_STATUS: {
    ACTIVE: 1,
    DISABLED: 2,
  },
  VIDEO_TYPE: {
    GV_ONE: 1,
    YOU_TUBE: 2,
    S3: 3,
  },
  TESTIMONIAL_URLS: {
    GV_ONE: 'https://experiencegathervoices.gv-one.com/video.html',
    YOU_TUBE: 'https://www.youtube.com/watch?v=',
    S3: 'amazonaws.com',
  },
  ADMIN_EMAIL_LIMIT: 10,
  COMPANY_USER_ROLES: {
    ADMIN: 1,
    OTHER: 2,
  },
  COMPANY_USER_TYPES: {
    KEY_EMPLOYEE: 1,
    REGULAR: 2,
    ADVISOR: 3,
    KEY_EMPLOYEE_ADVISOR: 4,
    SELF_ASSIGNED_ADMIN: 5,
    OUT_SIDE_USER: 6,
  },
  COMPANY_USER_STATUS: {
    PENDING_ACCEPTANCE: 1,
    ACCEPTED: 2,
    REJECTED: 3,
  },
  CHAT_MESSAGE_LIMIT: 10,
  USER_EMOTION_TYPES: {
    NONE: 0,
    LIKE: 1,
    HEART: 2,
    HAHA: 3,
    SAD: 4,
    ANGRY: 5,
    INSIGHTFUL: 6,
    WOW: 7
  },
  AUTOCONNECTION_REPORT_LIMIT: 10,
  CHAT_MAX_INACTIVE_TIME: 2, //in minutes
  SPAM_REPORT: {
    SPAM_MESSAGE: 1,
    SPAM_USER: 2,
  },
  NEWS_FEED_BANNER_AD_STATUS: {
    ACTIVE: 1,
    EXPIRED: 2,
    ARCHIVED: 3,
  },
  VIDEO_UPLOAD_STATUS: {
    UPLOAD: 1,
    PROCESS: 2,
    SUCCESS: 3,
  },
  VIDEO_PROCESS_STATUS: {
    PENDING: 1,
    COMPLETED: 2,
  },
  ALLOWED_IMG_TYPES: [
    'image/gif',
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/bmp',
    'image/webp',
  ],
  ALLOWED_VIDEO_TYPES: [
    'video/mp4',
    'video/quicktime',
    'video/x-ms-wmv',
    'video/x-flv',
    'video/x-msvideo',
    'video/mpeg',
    'video/avi',
  ],
  WINDOWS_ALLOWED_VIDEO_TYPES: ['flv', 'FLV'],
  WARNING_DISCLAIMER_TERMS_STATUS: {
    ACTIVE: 1,
    INACTIVE: 2,
    ARCHIVED: 3,
  },
  TRACKING_DATA_AVAILABLE_SINCE: '2018-01-01',
  PAGES: {
    0: 'HOME',
    'about-us': 'ABOUT_US',
    'frequently-asked-questions': 'FAQ',
    'privacy-policy': 'PRIVACY_POLICY',
    'terms-of-service': 'TERMS_OF_SERVICE',
    '?reason=contact': 'CUSTOMER_CARE',
    '?reason=advertising': 'ADVERTISING',
    press: 'PRESS',
    'leafwire-platinum-partners': 'LEAFWIRE_PLATINUM_PARTNERS',
    'profile-edit': 'PROFILE_EDIT',
    events: 'EVENT',
    'filter-connections': 'CONNECT',
    marketplace: 'MARKET_PLACE',
    'advanced-search': 'SEARCH',
    'create-company': 'COMPANY_CREATE',
    individual: 'USER_PROFILE',
    pro: 'USER_PROFILE',
    'individual-personal-preferences': 'USER_PROFILE',
    company: 'COMPANY_PROFILE',
    'change-email-password': 'EMAIL_CHANGE',
  },
  PRO_INVITES_TRACKING: {
    OPEN_FROM_NEWS_FEED: 1,
    OPEN_FROM_USER_PROFILE: 2,
  },
  DEFAULT_ALLOCATED_PRO_INVITES_COUNT: 5,
  DEFAULT_FETCH_PRO_USERS_LIMIT: 10,
  DEFAULT_PRO_SEARCH_TRIGGER_CHAR_COUNT: 3,
  VIEW_CONNECTIONS_LIMIT: 15,
  MSG_RESTRICTED_OG_IMGS: ['https://leafwire.com/ogImage/OG-image.jpg'],
  GUEST: 'guest',
  IS_PRO_MSG_SEARCH_PARAM: 'isProMsg',

  BLOCKUSER_ACCEPT_ERROR_MSG : 'Sorry, you cannot accept this request right now',
  BLOCKUSER_REJECT_ERROR_MSG : 'Sorry, you cannot reject this request right now',
  BLOCKUSER_CONNECT_ERROR_MSG : 'Sorry, you cannot connect with this person right now',
  BLOCKUSER_DISCONNECT_ERROR_MSG : 'Sorry, you cannot disconnect with this person right now',
  BLOCKUSER_MSG_ERROR_MSG : 'Sorry, you cannot send this message to this user right now',
  BLOCKUSER_CANCEL_ERROR_MSG : 'Sorry, you cannot cancel this request right now',
  BLOCKUSER_PRO_INVITE_ERROR_MSG : 'Sorry, you cannot send a pro invite to this user right now',
  BLOCKUSER_POPUP_CLASSNAME : 'block-user-popups',
  BLOCKUSER_SUCCESS : 'User has been successfully blocked',
  BLOCKUSER_FAIL : 'Failed to block this user',
  BLOCKUSER_ALREADY : 'User already blocked',

  BLOCKPOST_SUCCESS : 'This post has been successfully hidden',
  BLOCKPOST_FAIL : 'Failed to hide this post',
  
  PRESS_VIEWCOUNTS_SOURCE:{
    TOP_NAVBAR: 1,
    PRESS_PAGE: 2,
    FOOTER: 3,
    SIDEBAR_FOOTER: 4
  },
  PRESS_VIEWCOUNTS_DEVICE:{
    DESKTOP: 1,
    MOBILE: 2, 
  },
  GREENPAGES: {
    REQUEST_INFO_HOME: 'homepageRequest',
    REQUEST_INFO_FULLPAGE: 'requestMore',
    REQUEST_INFO_CLAIM_LISTING: 'claimListing',
    
    DEFAULT_REQUEST_INFO_MESSAGE_HOME: 'I would like more information about being featured on Green Pages.',
    DEFAULT_REQUEST_INFO_MESSAGE_FULLPAGE: 'Can you please send me some more information about your company?',
    DEFAULT_REQUEST_INFO_MESSAGE_CLAIM_LISTING: 'I would like more information about claiming this listing.',
    
    REQUEST_INFO_FORM_TITLE_HOME: 'Green Pages Placement Request',
    REQUEST_INFO_FORM_FULLPAGE: 'Information Request',
    REQUEST_INFO_FORM_TITLE_CLAIM_LISTING: 'Request More Information',
    
    LISTING_PER_LOAD: 10,
    SKIP_FOR_LISTINGS: 0,
    ALLOWED_VIDEO_TYPES:['video/mp4'],
    GREEN_PAGES_ADMIN_LISTINGS_LIMIT: 20,
  },
  ADMIN_GREENPAGES_FILTERS: [
    {
      value: 'listingName',
      label: 'Listing Name',
    },
    {
      value: 'listingID',
      label: 'Listing ID',
    },
    {
      value: 'companyName',
      label: 'Company Name',
    },
    {
      value: 'ownerName',
      label: 'Owner Name',
    },
    {
      value: 'ownerID',
      label: 'Owner ID',
    },
  ],
  ADMIN_GREENPAGES_PAYING_STATUS:[
    {
      value: 'Free',
      label: 'Free',
    },
    {
      value: 'Paid',
      label: 'Paid',
    },
  ],
  ADMIN_GREENPAGES_PAID_FLAG:[
    {
      value: 'Yes',
      label: 'Yes',
    },
    {
      value: 'No',
      label: 'No',
    },
  ],
  ADMIN_GREENPAGES_LISTING_STATUS:[
    {
      value: 'true',
      label: 'Yes',
    },
    {
      value: 'false',
      label: 'No',
    },
  ],
  ADMIN_GREENPAGES_FORM_TYPE: {
    CREATE: '1',
    EDIT: '2',
  },
  ADMIN_GREENPAGES_DEFAULT_VALUES: {
    blogId: '',
    companyID: '',
    companyName: '',
    companyProfileID: '',
    listingID: '',
    listingName: '',
    userId: '',
    ownerName: '',
    ownerJobTitle: '',
    active: 'no',
    paidFlag: 'No',
    payingStatus: 'Free',
    websiteURL: '',
    contactEmail: '',
    contactPhone: '',
    streetAddressLine1: '',
    streetAddressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    linkedIn: '',
    facebook: '',
    twitter: '',
    instagram: '',
    heroImage: '',
    logoImage: '',
    logoImage2: '',
    logoImage3: '',
    video: '',
    videoStatus: 2,
    videoThumbnailImage: '',
    bottomImage1: '',
    bottomImage2: '',
    bottomImage3: '',
    listingBodyCopy: '',
    fullPageBodyCopy: '',
    mainCategory: [],
    subcategoryTag1: '',
    subcategoryTag2: '',
    subcategoryTag3: '',
    customizedWebsiteURL: '',
    featured: 'No'
  },
  PAYMENT_FLOW_STATUS: {
    VALIDATING: 0,
    VALIDATED: 1,
    INVALID: 2,
  },
  ADMIN_GREENPAGE_BILLING_PERIOD: {
    SIX_MONTH: 6,
    YEAR: 12
  },
  ADMIN_GREENPAGE_BILLING_PERIOD_TYPE: {
    SIX_MONTH: 1,
    YEAR: 2
  },
  GREENPAGES_LISTING_SORT: {
    DEFAULT : 'default',
    AZ : 'az',
    ZA: 'za'
  },
  GREEN_PAGE_TRACKING_TYPE: {
    REQUEST_MORE_INFO : 1,
    CLAIM_THIS_LISTING: 2
  }
};
