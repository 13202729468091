module.exports = {
    PAGE_NAMES: {
        NEWS_FEED: 1,
        MARKET_PLACE: 2,
        COMPANY_PAGE: 3,
        POP_UPS: 4,
        EVENT_FORM: 5,
        CONNECTION_POPUP: 6,
        PRO_MESSAGES: 7,
        GREEN_PAGES: 8
    },
    PAGES: {
        NEWS_FEED: 0,
        MARKET_PLACE: 1,
        COMPANY_PAGE: 2,
        POP_UPS: 3,
        EVENT_FORM: 4,
        CONNECTION_POPUP: 5,
        PRO_MESSAGES: 6,
        GREEN_PAGES: 7
    },
    NF_SECTION: {
        SEARCH: 0,
        LINK: 1,
        POST: 2,
        PROFILE: 3,
        MP_WIDGET: 4,
        TRENDING_TOPICS: 5,
        INDUSTRY_EVENTS: 6,
        NETWORK: 7,
        GROUPS_WIDGET: 8,
        JOBS_WIDGET: 9,
    },
    MP_SECTION: {
        PRODUCT_NAME: 0,
        PRODUCT_DESCRIPTION: 1,
        WEB_LINK: 2,
        CATEGORIES: 3,
        FEATURED_LISTING: 4,
        GRAPHICS_IMAGES: 5,
        SPECIAL_OFFER: 6,
    },
    CP_SECTION: {
        COMPANY_NAME: 0,
        COMPANY_NAME_EDIT: 1
    },
    PP_SECTION: {
        NF_EDIT_CP_NAME: 0,
        NF_EDIT_JB_TITLE: 1,
    },
    EF_SECTION: {
        PACKAGE: 1,
        NAME: 2,
        TAGS: 3,
        URL: 4,
        OFFER: 5,
        MUTE_NOTIFICATION: 6
    },
    CMP_SECTION: {
        NOT_CONNECTED_USER: 0
    },
    PM_SECTION: {
        CONNECT: 0,
        PROFILE: 1,
        MESSAGES_PRO:2,
        MESSAGES_NON_PRO:3,
        CONNECT_NON_PRO:4
    },
    GP_SECTION: {
        FEATURED_LISTING: 0
    }
}