import React, { Component } from "react";
import {
    ProfileCover,
    Container,
    Row,
    Col,
    Space,
    Margin, CollapsibleCard
} from "./../../components/common";
import { InputCover } from "./../../components/input";
import { connect } from 'react-redux';
import { changeEmail, changePassword } from './../../actions/auth';
import md5 from 'md5';
import { ROUTES } from './../../config/server';
import { isMail, isValidPassword } from "../../libs/validation";
import * as AuthTypes from './../../types/authentication';
import './index.css';
import Breadcrumb from "./../../components/breadcrumb";

import { trackEvent } from './../../actions/eventTracking';
import { EVENT_ACTION } from './../../types/eventTracking';

import { post } from "../../libs/apiConnector";
import ToolTip from "../../components/common/tooltip";

class ChangeEmailPassword extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            password1: '',
            newEmail: '',
            isMailChecking: false,
            isExistingEmail: false,
            isValidEmail: false,
            isTriedToUpdateMail: false,
            password2: '',
            newPassword: '',
            testNewPassword: '',
            isTriedToUpdatePassword: false,
            isChangingEmail: false,
            isClicked: false
        });
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        if (localStorage.getItem('userId')) {
            /* data project - event trcking */
            const eventPayload = {
                user_id: localStorage.getItem('userId'),
                page: 'EMAIL_CHANGE',
                section: null,
                element_type: null,
                element_id: null,
                action_type: EVENT_ACTION.VIEW
            }
            this.props.dispatch(trackEvent(eventPayload));
            /* END : data project - event trcking */
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.changePassword.status === 200) {
            this.setState({
                password2: '',
                isTriedToUpdatePassword: false,
                newPassword: '',
                testNewPassword: ''
            })
        } else if (nextProps.changePassword.status === 218) {
            this.setState({
                password2: '',
                isTriedToUpdatePassword: false
            })
        }
        if (nextProps.action === AuthTypes.CHANGE_EMAIL_SUCCESS) {
            this.setState({
                isChangingEmail: false,
                password1: '',
                newEmail: '',
                isMailChecking: false,
                isExistingEmail: false,
                isValidEmail: false,
                isTriedToUpdateMail: false,
                isClicked: false
            })
        }
        if (nextProps.action === AuthTypes.CHANGE_EMAIL_FAIL) {
            this.setState({
                isChangingEmail: false
            })
        }
    }

    _updatePassword = () => {
        let check = true;

        if (this.state.password2.length < 6) {
            check = false
        }
        if (!isValidPassword(this.state.newPassword)) {
            check = false;
        }
        /*if(this.state.newPassword.length < 6){
            check = false;
        }*/
        if (this.state.testNewPassword !== this.state.newPassword) {
            check = false;
        }
        if ((!localStorage.getItem('emulateSessionId')) && this.state.password2 === this.state.newPassword) {
            check = false
        }
        this.setState({
            isTriedToUpdatePassword: true
        });
        if (check) {
            this.props.dispatch(changePassword(md5(this.state.password2), md5(this.state.newPassword)))
        }
    };

    _updateEmail = () => {
        this.setState({ isClicked: true });
        let check = true;
        if (this.state.isMailChecking) {
            check = false
        }
        if (this.state.isExistingEmail) {
            check = false
        }
        if (!this.state.isValidEmail) {
            check = false
        }
        if (check && this.state.password1.length >= 6) {
            this.setState({
                isChangingEmail: true
            });
            this.props.dispatch(changeEmail(this.state.newEmail, md5(this.state.password1)))
        }
    };

    _onChangeEmail = (e) => {
        if (isMail(e.target.value)) {
            this.setState({
                isValidEmail: true,
                isMailChecking: true,
                newEmail: e.target.value
            });
            this._checkEmail(e.target.value)
        } else {
            this.setState({
                isValidEmail: false,
                isMailChecking: false,
                newEmail: e.target.value
            })
        }
    };

    _checkEmail = (email) => {
        post(`${ROUTES.CHECK_EMAIL_EXISTS}`, { email: email })
            .then(response => {
                if (response.data.code === 200) {
                    this.setState({
                        isExistingEmail: true,
                        isMailChecking: false
                    })
                } else if (response.data.code === 506) {
                    this.setState({
                        isExistingEmail: false,
                        isMailChecking: false
                    })
                }
            })
            .catch(err => {
                // console.log(err.message)
            })
    };

    render() {
        return (
            <React.Fragment>
                <ProfileCover height={110} />
                <Breadcrumb
                    locations={
                        [
                            { name: 'Profile', link: `/user-profile-edit/${localStorage.getItem('userId')}` },
                            { name: 'Settings', link: '' },
                        ]
                    }
                />
                <Container>
                    <Space customClass={"mobile-view"} space={40}>
                        <Row>
                            <Col className={"col-lg-12 col-md-12"}>
                                <CollapsibleCard title="Change Email and Password" open={true} isCollapsibleFalse={true}>
                                    <div className="changeEmail">
                                        <h3 className="font_18 font_medium font_d_blue  m-b20 m-t10">Change Email Address</h3>
                                        <Row>
                                            <Col className="col-md-6">
                                                <InputCover inputName="TYPE YOUR NEW EMAIL" status="defalt" animate={true}>
                                                    <input
                                                        type="email"
                                                        className="matirial_custom_input"
                                                        required
                                                        value={this.state.newEmail}
                                                        onChange={(e) => { this._onChangeEmail(e) }}
                                                        id="ChangeLoginNewEmail"
                                                        autocomplete="new-password"
                                                        disabled={localStorage.getItem('emulateSessionId')}
                                                    />
                                                </InputCover>
                                                <span hidden={!this.state.isMailChecking} id="error-msg">Please Wait... </span>
                                                <span hidden={this.state.newEmail === '' || this.state.isValidEmail} id="error-msg">Please enter a valid email address </span>
                                                <span hidden={!this.state.isExistingEmail || this.state.newEmail === ''} id="error-msg">Email already exists! </span>
                                                {this.state.isClicked && !this.state.isValidEmail && <span id="error-msg">Please enter a new Email</span>}

                                            </Col>
                                            <Col className="col-md-6">
                                                <InputCover inputName="TYPE YOUR PASSWORD" status="defalt" animate={true}>
                                                    <input
                                                        type="password"
                                                        className="matirial_custom_input"
                                                        required
                                                        value={this.state.password1}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                password1: e.target.value
                                                            })
                                                        }}
                                                        id="ChangeLoginPassword"
                                                        autocomplete="new-password"
                                                        disabled={localStorage.getItem('emulateSessionId')}
                                                    />
                                                </InputCover>
                                                {this.state.isClicked && this.state.password1 === '' && <span id="error-msg">Please enter your password</span>}
                                                {this.state.password1 !== '' && this.state.password1.length < 6 && <span id="error-msg" >Password must contain at least 6 characters. </span>}
                                            </Col>
                                        </Row>
                                        <button
                                            id="ChangeLoginEmailSave"
                                            className=" base_button_transperant_purple btn"
                                            disabled={this.state.isMailChecking || this.state.isChangingEmail || localStorage.getItem('emulateSessionId')}
                                            onClick={() => { this._updateEmail() }}>
                                            <i className={this.state.isChangingEmail ? "fa fa-spinner fa-spin" : "scc"} />
                                            SAVE CHANGES
                                        </button>
                                    </div>
                                    <Margin margin={50} vertical>
                                        {/*<h6 className="orHeader text-center"><span>Or</span></h6>*/}
                                        <hr />
                                    </Margin>
                                    <div className="changePassword">
                                        <h3 className="font_18 font_medium font_d_blue  m-b20 m-t0">Change Password</h3>
                                        <InputCover
                                            inputName="TYPE YOUR CURRENT PASSWORD"
                                            status='defalt'
                                            animate={true}>
                                            <input
                                                id="ChangeLoginPsCurrentPassword"
                                                type="password"
                                                className="matirial_custom_input"
                                                required
                                                value={this.state.password2}
                                                onChange={(e => {
                                                    this.setState({
                                                        password2: e.target.value
                                                    })
                                                })}
                                            />
                                        </InputCover>
                                        {this.state.isTriedToUpdatePassword && this.state.password2.length < 6 && <span id="error-msg">Password must contain at least 6 characters.</span>}
                                        <Row>
                                            <Col className="col-md-6">
                                                <div className="input-relative">
                                                    <InputCover
                                                        inputName="TYPE YOUR NEW PASSWORD"
                                                        status={
                                                            this.state.newPassword === '' ? "defalt" :
                                                                (this.state.newPassword !== this.state.testNewPassword || (!isValidPassword(this.state.newPassword)) ? "error" : "sucess")
                                                        }
                                                        animate={true}>
                                                        <input
                                                            id="ChangeLoginPsNewPassword"
                                                            type="password"
                                                            className="matirial_custom_input"
                                                            required
                                                            value={this.state.newPassword}
                                                            onChange={e => {
                                                                this.setState({
                                                                    newPassword: e.target.value
                                                                })
                                                            }}
                                                        />
                                                    </InputCover>
                                                    <ToolTip
                                                        tooltip={
                                                            {
                                                                HEAD_LINE: 'Password Criteria',
                                                                ICON: 1,
                                                                TEXT_BODY: `
                                                                1. Must contain at least 6 characters in length

                                                                2. Must contain at least one Uppercase character
                                                                
                                                                3. Must contain at least one lowercase character 
                                                                
                                                                4. Must contain at least one number 
                                                                
                                                                5. Must contain at least one special character
                                                            `,
                                                                VISIBLE: true
                                                            }
                                                        }
                                                    />
                                                </div>
                                                {
                                                    (
                                                        (this.state.newPassword !== '' || this.state.isTriedToUpdatePassword) &&
                                                        (!isValidPassword(this.state.newPassword))
                                                    ) &&
                                                    <span id="error-msg">New password must be a strong password.</span>
                                                }
                                                {((!localStorage.getItem('emulateSessionId')) && this.state.newPassword === this.state.password2) && this.state.password2 !== '' && <span id="error-msg">New password can't be your current password.</span>}
                                            </Col>
                                            <Col className="col-md-6">
                                                <InputCover
                                                    inputName="RETYPE YOUR NEW PASSWORD"
                                                    status={
                                                        this.state.testNewPassword === '' ? "defalt" :
                                                            (this.state.newPassword !== this.state.testNewPassword || (!isValidPassword(this.state.testNewPassword)) ? "error" : "sucess")
                                                    }
                                                    animate={true}>
                                                    <input
                                                        id="ChangeLoginPsReNewPassword"
                                                        type="password"
                                                        className="matirial_custom_input"
                                                        required
                                                        value={this.state.testNewPassword}
                                                        onChange={e => {
                                                            this.setState({
                                                                testNewPassword: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </InputCover>
                                                {this.state.newPassword !== this.state.testNewPassword && (this.state.isTriedToUpdatePassword || this.state.testNewPassword !== '') && <span id="error-msg" >{this.state.testNewPassword === '' ? 'Please retype your new password' : 'Password does not match!'}</span>}
                                            </Col>
                                        </Row>
                                        <button id="ChangeLoginPsSave" className=" base_button_transperant_purple btn m-b30" onClick={() => { this._updatePassword() }}>
                                            SAVE CHANGES
                                        </button>
                                    </div>
                                </CollapsibleCard>
                            </Col>
                        </Row>
                    </Space>
                </Container>
            </React.Fragment>
        );
    }
}

export default connect(
    state => {
        return {
            changePassword: state.auth.changePassword,
            action: state.auth.action
        }
    }
)(ChangeEmailPassword);