module.exports = {
    OPEN_INVITE_MODAL: 'OPEN_INVITE_MODAL',
    CLOSE_INVITE_MODAL: 'CLOSE_INVITE_MODAL',

    PRO_USER_INVITATION_STATUS: {
        SENT: 1,
        ACCEPTED: 2,
        DECLINED: 3
    },
    PRO_INVITATION_RECEIVER_ACTION_METHODS: {
        EMAIL_INTERNAL: 1,
        EMAIL_EXTERNAL: 2,
        IN_APP_NOTIFICATION: 3,
        INSTANT_MESSAGE: 4,
        NEWSFEED_PENDING_WIDGET: 5,
        USER_PROFILE_PENDING_WIDGET:6
    },

    OPEN_QUOTA_EXCEEDED_MODAL: 'OPEN_QUOTA_EXCEEDED_MODAL',
    CLOSE_QUOTA_EXCEEDED_MODAL: 'CLOSE_QUOTA_EXCEEDED_MODAL',

    START_FETCHING: 'START_FETCHING',
    STOP_FETCHING: 'STOP_FETCHING',

    START_SENDING: 'START_SENDING',
    STOP_SENDING: 'STOP_SENDING',

    FETCHING_USER_PRO_DATA: 'FETCHING_USER_PRO_DATA',
    UPDATE_USER_PRO_DATA: 'UPDATE_USER_PRO_DATA',
    FETCHING_USER_PRO_DATA_FAIL: 'FETCHING_USER_PRO_DATA_FAIL',
    UPDATE_SENT_INVITATIONS: 'UPDATE_SENT_INVITATIONS',

    FETCH_RECEIVED_INVITATIONS: 'FETCH_RECEIVED_INVITATIONS',
    FETCH_RECEIVED_INVITATIONS_SUCCESS: 'FETCH_RECEIVED_INVITATIONS_SUCCESS',
    FETCH_RECEIVED_INVITATIONS_FAIL: 'FETCH_RECEIVED_INVITATIONS_FAIL',

    UPDATE_SENT_PRO_INVITES_COUNT: 'UPDATE_SENT_PRO_INVITES_COUNT',
    UPDATE_PRO_INVITES_TRACKING_DATA: 'UPDATE_PRO_INVITES_TRACKING_DATA',

    GET_PROFILE_EVENT_DISCOUNT: "GET_PROFILE_EVENT_DISCOUNT",
    GET_PROFILE_EVENT_DISCOUNT_SUCCESS: "GET_PROFILE_EVENT_DISCOUNT_SUCCESS",
    GET_PROFILE_EVENT_DISCOUNT_FAIL: "GET_PROFILE_EVENT_DISCOUNT_FAIL",
};