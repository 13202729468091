

import React, { Component } from "react";

class CookiePolicy extends Component {

    render() {

        return (
            <div className="static_wrp d-flex justify-content-between align-items-center cookie-message">
                <div className="stacticWap_center pl-3 p-t8 p-b5">
                    <h4 className="font_medium">This website uses cookies</h4>
                    <p className="font_12">We use cookies to personalize content and ads, allow you to post to our customer support page, to provide social media features and to analyze our traffic.
                      We also share information about your use of our site with our social media,
                      advertising (as per Google requirements) and analytics partners who may combine it with other information
                      that you've provided to them or that they've collected from your use of their services. Our <a href="/page/privacy-policy" target="_blank" style={{color:'white'}}>Privacy Policy</a>
                    </p>
                </div>
                <div className="stacticWap_right">
                    <button className="btn font_14" onClick={() => this.props.confirmCookiePolicy()}>Accept</button>
                </div>
            </div>
        );
    }
}

export { CookiePolicy };
