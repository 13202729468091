import axios from 'axios';
import {ROUTES} from '../config';
import DataRoomTypes from '../types/dataRoom';
import {ALERT, POP_ALERT} from '../types/common';
import {showAlert, showPopupAlert} from './alert';
import {clearSelectedInvestor, getAutorizedInvestors} from './investor';
import {get, post, del, put} from "../libs/apiConnector";

export function subscribedToFundraising() {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.SUBSCRIBED_TO_FUNDRAISING});
    }
}

export function fundraisingSubscriptionExpired() {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.FUNDRAISING_SUBSCRIPTION_EXPIRED});
    }
}

export function uploadDocument(document) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.UPLOADING_DOCUMENTS});
        post(`${ROUTES.UPLOAD_DOCUMENT}`, document)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: DataRoomTypes.UPLOADING_DOCUMENTS_SUCCESS, payload: response.data});
                    dispatch(getDataRoomDocsSearch(document.companyId));
                } else {
                    dispatch({type: DataRoomTypes.UPLOADING_DOCUMENTS_FAIL, payload: response.data});
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                    dispatch({type: DataRoomTypes.CLEAR_ACTION})
                }
            })
            .catch((exception) => {
                dispatch({type: DataRoomTypes.UPLOADING_DOCUMENTS_FAIL, payload: exception.data});
                showPopupAlert(dispatch, POP_ALERT.WARNING, exception.message);
                dispatch({type: DataRoomTypes.CLEAR_ACTION})
            })
    }
}

export function uploadDocumentDone(response) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.UPLOADING_DOCUMENTS_DONE});
        // showAlert(dispatch, ALERT.SUCCESS, response);
        dispatch({type: DataRoomTypes.CLEAR_ACTION})
    }
}

export function documentUploadModel(status) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.DOCUMENT_UPLOAD_MODAL, payload: status});
        dispatch({type: DataRoomTypes.CLEAR_ACTION})
    }
}

export function addNewVersionModel(status, document) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.ADD_NEW_VERSION_MODAL, payload: {status, document}});
        dispatch({type: DataRoomTypes.CLEAR_ACTION});
    }
}

export function viewDocumentHistoryModel(status, docId, companyId) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.VIEW_DOCUMENT_HISTORY, payload: {status}});
        dispatch({type: DataRoomTypes.CLEAR_ACTION});

        if (status) {
            dispatch(fetchDocumentHistory(companyId, docId));
        } else {
            dispatch(clearDocumentHistory());
        }
    }
}

export function clearDocumentHistory() {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.CLEAR_DOCUMENT_HISTORY});
    }
}

export function documentEditModel(status, docID) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.DOCUMENT_EDIT_MODAL, payload: status, docId: docID});
        dispatch({type: DataRoomTypes.CLEAR_ACTION})
    }
}

export function shareWithModel(status) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.SHAREWITH_MODAL, payload: status});
        dispatch({type: DataRoomTypes.CLEAR_ACTION})
    }
}

export function fetchDocumentHistory(companyId, docId) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.FETCHING_DATAROOM_DOCUMENT_HISTORY});
        get(`${ROUTES.FETCH_DATA_ROOM_DOC_HISTORY}/${companyId}/${docId}`)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({
                        type: DataRoomTypes.FETCHING_DATAROOM_DOCUMENT_HISTORY_SUCCESS,
                        payload: response.data.data
                    });
                } else {
                    dispatch({type: DataRoomTypes.FETCHING_DATAROOM_DOCUMENT_HISTORY_FAIL});
                }
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                dispatch({type: DataRoomTypes.FETCHING_DATAROOM_DOCUMENT_HISTORY_FAIL});
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
    }
}

export function deleteDocument(documentId, companyId) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.DOCUMENT_DELETE});
        del(`${ROUTES.DELETE_DOCUMENT}/${documentId}`)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: DataRoomTypes.DOCUMENT_DELETE_SUCCESS, payload: response.data});
                    dispatch(getDataRoomDocsSearch(companyId))
                } else {
                    dispatch({type: DataRoomTypes.DOCUMENT_DELETE_FAIL, payload: response.data});
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                    dispatch({type: DataRoomTypes.CLEAR_ACTION})
                }
            })
            .catch((exception) => {
                dispatch({type: DataRoomTypes.DOCUMENT_DELETE_FAIL, payload: exception.data});
                showPopupAlert(dispatch, POP_ALERT.WARNING, exception.message);
                dispatch({type: DataRoomTypes.CLEAR_ACTION})
            })
    }
}

export function fetchDataRoomDocuments(companyId, skip, limit, cancelToken) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.FETCHING_DATAROOM_DOCUMENTS});
        post(`${ROUTES.FETCH_DATA_ROOM_DOCS}/${companyId}`, {skip, limit}, {cancelToken})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({
                        type: DataRoomTypes.FETCH_DATAROOM_DOCUMENTS_SUCCESS,
                        payload: response.data,
                        reset: skip === 0
                    });
                } else {
                    dispatch({type: DataRoomTypes.FETCH_DATAROOM_DOCUMENTS_FAIL});
                }
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: DataRoomTypes.FETCH_DATAROOM_DOCUMENTS_FAIL});
                    dispatch({type: DataRoomTypes.CLEAR_ACTION});
                }
            })
    }
}

export function clearDataRoomDocs() {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.CLEAR_DATAROOM_DOCS});
    }
}

export function showErrorMsg(msg) {
    return function (dispatch) {
        showPopupAlert(dispatch, POP_ALERT.WARNING, msg);
        // showAlert(dispatch, ALERT.ERROR, msg);
    };
}

export function DataRoomDocumentCount(companyId, cancelToken) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.FETCHING_DATAROOM_DOCUMENT_COUNT});
        get(`${ROUTES.DATA_ROOM_DOC_COUNT}/${companyId}`, {cancelToken})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: DataRoomTypes.FETCH_DATAROOM_DOCUMENT_COUNT_SUCCESS, payload: response.data});
                } else {
                    dispatch({type: DataRoomTypes.FETCH_DATAROOM_DOCUMENT_COUNT_FAIL});
                }
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: DataRoomTypes.FETCH_DATAROOM_DOCUMENT_COUNT_FAIL});
                    dispatch({type: DataRoomTypes.CLEAR_ACTION});
                }
            })
    }
}

export function clearDataRoomDocCount() {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.CLEAR_DATAROOM_DOCUMENT_COUNT});
    }
}

export function editDocument(cid, docId, title, desc) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.EDIT_DATAROOM_DOC});
        put(`${ROUTES.EDIT_DATAROOM_DOC}${cid}`, {documentId: docId, name: title, description: desc})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: DataRoomTypes.EDIT_DATAROOM_DOC_SUCCESS});
                    dispatch(getDataRoomDocsSearch(cid));
                } else {
                    dispatch({type: DataRoomTypes.EDIT_DATAROOM_DOC_FAIL});
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch(err => {
                dispatch({type: DataRoomTypes.EDIT_DATAROOM_DOC_FAIL});
                showPopupAlert(dispatch, POP_ALERT.WARNING, err.message);
            })
    }
}

export function checkIsMndaSigned(cid) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.CHECK_MNDA_SIGNED});
        get(`${ROUTES.CHECK_MNDA_SIGNED}${cid}`)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: DataRoomTypes.CHECK_MNDA_SIGNED_SUCCESS, payload: response.data});
                } else {
                    dispatch({type: DataRoomTypes.MNDA_NOT_SIGNED});
                }
            })
            .catch(err => {
                dispatch({type: DataRoomTypes.CHECK_MNDA_SIGNED_FAIL});
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
    }
}

export function checkInvitation(companyId) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.CHECK_INVITATION_VALID});
        get(`${ROUTES.CHECK_INVITATION_VALID}${companyId}`)
            .then(response => {
                if (response.data.code === 200) {
                    showAlert(dispatch, ALERT.SUCCESS, response.data.message);
                }
                if (response.data.code === 220) {
                    showAlert(dispatch, ALERT.ERROR, response.data.message);
                }
            })
            .catch(err => {
                dispatch({type: DataRoomTypes.CHECK_INVITATION_VALID_FAIL});
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
    }
}

export function retrieveDataRoomPermission(companyId) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.FETCHING_DATAROOM_PERMISSION});
        get(`${ROUTES.FETCH_DATAROOM_PERMISSION}/${companyId}`)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: DataRoomTypes.FETCH_DATAROOM_PERMISSION_SUCCESS, payload: response.data.data});
                } else {
                    dispatch({type: DataRoomTypes.FETCH_DATAROOM_PERMISSION_FAIL});
                }
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                dispatch({type: DataRoomTypes.FETCH_DATAROOM_PERMISSION_FAIL});
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
    }
}

export function requestDataRoomAccess(companyId) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.REQUESTING_DATAROOM_ACCESS});
        post(`${ROUTES.REQUEST_DATAROOM_ACCESS}`, {companyId})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: DataRoomTypes.REQUEST_DATAROOM_ACCESS_SUCCESS});
                    showPopupAlert(dispatch, POP_ALERT.SUCCESS, response.data.message);

                } else {
                    dispatch({type: DataRoomTypes.REQUEST_DATAROOM_ACCESS_FAIL});
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                dispatch({type: DataRoomTypes.REQUEST_DATAROOM_ACCESS_FAIL});
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            })
    }
}

export function fetchPendingDataRoomRequests(companyId, requestType, skip, limit, cancelToken) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.FETCHING_PENDING_DATAROOM_REQUESTS});
        post(`${ROUTES.FETCH_PENDING_DATAROOM_REQUESTS}/${companyId}`, {requestType, skip, limit}, {cancelToken})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: DataRoomTypes.FETCH_PENDING_DATAROOM_REQUESTS_SUCCESS, payload: response.data});
                } else {
                    dispatch({type: DataRoomTypes.FETCH_PENDING_DATAROOM_REQUESTS_FAIL});
                }
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: DataRoomTypes.FETCH_PENDING_DATAROOM_REQUESTS_FAIL});
                    dispatch({type: DataRoomTypes.CLEAR_ACTION});
                }
            })
    }
}

export function clearPendingDataRoomRequests() {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.CLEAR_PENDING_DATAROOM_REQUESTS});
    }
}

export function pendingDataRoomRequestCount(companyId, requestType, cancelToken) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.FETCHING_PENDING_DATAROOM_REQUEST_COUNT});
        post(`${ROUTES.GET_PENDING_DATAROOM_REQUEST_COUNT}/${companyId}`, {requestType}, {cancelToken})
            .then(response => {
                if (response.data.code === 200 || response.data.code === 212) {
                    dispatch({
                        type: DataRoomTypes.FETCH_PENDING_DATAROOM_REQUEST_COUNT_SUCCESS,
                        payload: response.data
                    });
                } else {
                    dispatch({type: DataRoomTypes.FETCH_PENDING_DATAROOM_REQUEST_COUNT_FAIL});
                }
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: DataRoomTypes.FETCH_PENDING_DATAROOM_REQUEST_COUNT_FAIL});
                    dispatch({type: DataRoomTypes.CLEAR_ACTION});
                }
            })
    }
}

export function clearPendingDataRoomRequestCount() {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.CLEAR_PENDING_DATAROOM_REQUEST_COUNT});
    }
}

export function acceptDataRoomRequest(companyId, requesterUserId, companyName) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.ACCEPTING_DATAROOM_REQUEST});
        put(`${ROUTES.ACCEPT_DATAROOM_REQUEST}/${companyId}`, {requesterUserId, companyName})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: DataRoomTypes.ACCEPT_DATAROOM_REQUEST_SUCCESS});
                    dispatch(getAutorizedInvestors(companyId));
                } else {
                    dispatch({type: DataRoomTypes.ACCEPT_DATAROOM_REQUEST_FAIL});
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
            .catch(err => {
                dispatch({type: DataRoomTypes.ACCEPT_DATAROOM_REQUEST_FAIL});
                showPopupAlert(dispatch, POP_ALERT.WARNING, err.message);
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
    }
}

export function rejectDataRoomRequest(companyId, requesterUserId) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.REJECTING_DATAROOM_REQUEST});
        put(`${ROUTES.REJECT_DATAROOM_REQUEST}/${companyId}`, {requesterUserId})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: DataRoomTypes.REJECT_DATAROOM_REQUEST_SUCCESS});
                } else {
                    dispatch({type: DataRoomTypes.REJECT_DATAROOM_REQUEST_FAIL});
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
            .catch(err => {
                dispatch({type: DataRoomTypes.REJECT_DATAROOM_REQUEST_FAIL});
                showPopupAlert(dispatch, POP_ALERT.WARNING, err.message);
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
    }
}

export function fetchSharedUserDataRoomRequests(companyId, requestType = 1, skip, limit, cancelToken) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.FETCHING_SHAREDUSERS_DATAROOM_REQUESTS});
        post(`${ROUTES.FETCH_SHAREDUSERS_DATAROOM_REQUESTS}/${companyId}`, {requestType, skip, limit}, {cancelToken})
            .then(response => {
                if (response.data.code === 200 || response.data.code === 212) {
                    dispatch({
                        type: DataRoomTypes.FETCH_SHAREDUSERS_DATAROOM_REQUESTS_SUCCESS,
                        payload: response.data,
                        reset: skip === 0
                    });
                } else {
                    dispatch({type: DataRoomTypes.FETCH_SHAREDUSERS_DATAROOM_REQUESTS_FAIL});
                }
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: DataRoomTypes.FETCH_SHAREDUSERS_DATAROOM_REQUESTS_FAIL});
                    dispatch({type: DataRoomTypes.CLEAR_ACTION});
                }
            })
    }
}

export function sharedUserDataRoomRequestCount(companyId, requestType = 1, cancelToken) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.FETCHING_SHAREDUSERS_DATAROOM_REQUEST_COUNT});
        post(`${ROUTES.GET_SHAREDUSERS_DATAROOM_REQUEST_COUNT}/${companyId}`, {requestType}, {cancelToken})
            .then(response => {
                if (response.data.code === 200 || response.data.code === 212) {
                    dispatch({
                        type: DataRoomTypes.FETCH_SHAREDUSERS_DATAROOM_REQUEST_COUNT_SUCCESS,
                        payload: response.data
                    });
                } else {
                    dispatch({type: DataRoomTypes.FETCH_SHAREDUSERS_DATAROOM_REQUEST_COUNT_FAIL});
                }
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: DataRoomTypes.FETCH_SHAREDUSERS_DATAROOM_REQUEST_COUNT_FAIL});
                    dispatch({type: DataRoomTypes.CLEAR_ACTION});
                }
            })
    }
}

export function inviteInvestors(companyId, invitees) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.SENDING_DATAROOM_INVITATION});
        post(`${ROUTES.SEND_DATAROOM_INVITATION}/${companyId}`, {invitees})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: DataRoomTypes.SEND_DATAROOM_INVITATION_SUCCESS});
                    if (response.data.data && response.data.data.length > 0) {
                        dispatch(updateReinvitedInvestors(response.data.data))
                    }
                } else {
                    dispatch({type: DataRoomTypes.SEND_DATAROOM_INVITATION_FAIL});
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                dispatch({type: DataRoomTypes.SEND_DATAROOM_INVITATION_FAIL});
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            });
        dispatch(clearSelectedInvestor());
        dispatch(shareWithModel(false));
    }
}

export function clearDataRoomSharedUsers() {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.CLEAR_DATAROOM_SHAREDUSERS});
    }
}

export function clearSharedUserDataRoomRequestCount() {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.CLEAR_SHAREDUSER_DATAROOM_REQUEST_COUNT});
    }
}

export function clearDataroomPermissions() {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.CLEAR_DATAROOM_PERMISSIONS})
    }
}

export function revokeDataRoomAccess(companyId, userId) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.REVOKE_DATAROOM_ACCESS});
        post(`${ROUTES.REVOKE_DATAROOM_ACCESS}${companyId}`, {requesterUserId: userId})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: DataRoomTypes.REVOKE_DATAROOM_ACCESS_SUCCESS, userId: userId});
                    dispatch({type: DataRoomTypes.CLEAR_DATAROOM_USER_ACTION});
                } else {
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch(error => {
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            })
    }
}

export function reGrantDataRoomAccess(companyId, userId) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.RE_GRANT_DATAROOM_ACCESS});
        post(`${ROUTES.REGRANT_DATAROOM_ACCESS}${companyId}`, {requesterUserId: userId})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: DataRoomTypes.RE_GRANT_DATAROOM_ACCESS_SUCCESS, userId: userId});
                    dispatch({type: DataRoomTypes.CLEAR_DATAROOM_USER_ACTION});
                } else {
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch(error => {
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            })
    }
}

export function updateViewCount(companyId, documentId) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.DOCUMENT_VIEW_COUNT_UPDATING});
        post(`${ROUTES.UPDATE_DOCUMENT_VIEW_COUNT}${companyId}`, {documentId: documentId})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: DataRoomTypes.DOCUMENT_VIEW_COUNT_UPDATED});
                } else {
                    dispatch({type: DataRoomTypes.DOCUMENT_VIEW_COUNT_UPDATE_FAIL});
                }
            })
            .catch(error => {
                dispatch({type: DataRoomTypes.DOCUMENT_VIEW_COUNT_UPDATE_FAIL});
            })
    }
}

export function fetchStatistics(companyId, skip = 0, limit = 5, investorId = "", documentId = "", from = "", to = "", cancelToken) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.FETCHING_STATISTICS, reset: skip === 0});
        post(`${ROUTES.FETCH_STATISTICS}/${companyId}`, {investorId, skip, limit, documentId, from, to}, {cancelToken})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({
                        type: DataRoomTypes.FETCHING_STATISTICS_SUCCESS,
                        payload: response.data.data,
                        reset: skip === 0
                    });
                } else {
                    dispatch({type: DataRoomTypes.FETCHING_STATISTICS_FAIL});
                }
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: DataRoomTypes.FETCHING_STATISTICS_FAIL});
                    dispatch({type: DataRoomTypes.CLEAR_ACTION});
                }
            })
    }
}

export function fetchStatisticsCount(companyId, investorId = "", documentId = "", from = "", to = "", cancelToken) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.FETCHING_STATISTICS_COUNT});
        post(`${ROUTES.FETCH_STATISTICS_COUNT}/${companyId}`, {investorId, documentId, from, to}, {cancelToken})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: DataRoomTypes.FETCHING_STATISTICS_COUNT_SUCCESS, payload: response.data.data});
                } else {
                    dispatch({type: DataRoomTypes.FETCHING_STATISTICS_COUNT_FAIL});
                }
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: DataRoomTypes.FETCHING_STATISTICS_COUNT_FAIL});
                    dispatch({type: DataRoomTypes.CLEAR_ACTION});
                }
            })
    }
}

export function getDataRoomDocsSearch(companyId, cancelToken) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.SEARCH_DATAROOM_DOCS});
        post(`${ROUTES.GET_UPLOADED_DOCS}${companyId}`, {}, {cancelToken})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: DataRoomTypes.SEARCH_DATAROOM_DOCS_SUCCESS, payload: response.data.data})
                } else {
                    dispatch({type: DataRoomTypes.SEARCH_DATAROOM_DOCS_FAIL})
                }
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: DataRoomTypes.SEARCH_DATAROOM_DOCS_FAIL})
                }
            })
    }
}

export function fetchPendingFundRaisingRequests(companyId, requestType, skip, limit) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.FETCHING_PENDING_FUND_RAISING_REQUESTS});
        post(`${ROUTES.FETCH_PENDING_FUND_RAISING_REQUESTS}${companyId}`, {requestType, skip, limit})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: DataRoomTypes.FETCH_PENDING_FUND_RAISING_REQUESTS_SUCCESS, payload: response.data});
                } else {
                    dispatch({type: DataRoomTypes.FETCH_PENDING_FUND_RAISING_REQUESTS_FAIL});
                }
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                dispatch({type: DataRoomTypes.FETCH_PENDING_DATAROOM_REQUESTS_FAIL});
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
    }
}

export function clearPendingFundRaisingRequests() {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.CLEAR_PENDING_FUND_RAISING_REQUESTS});
    }
}

export function pendingFundRaisingRequestCount(companyId, requestType) {
    return function (dispatch) {

        dispatch({type: DataRoomTypes.FETCHING_PENDING_FUND_RAISING_REQUEST_COUNT});
        post(`${ROUTES.GET_PENDING_FUND_RAISING_REQUEST_COUNT}${companyId}`, {requestType})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({
                        type: DataRoomTypes.FETCH_PENDING_FUND_RAISING_REQUEST_COUNT_SUCCESS,
                        payload: response.data.data.COUNT
                    });
                } else if (response.data.code === 212) {
                    dispatch({type: DataRoomTypes.FETCH_PENDING_FUND_RAISING_REQUEST_COUNT_SUCCESS, payload: 0});
                } else {
                    dispatch({type: DataRoomTypes.FETCH_PENDING_FUND_RAISING_REQUEST_COUNT_FAIL});
                }
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                dispatch({type: DataRoomTypes.FETCH_PENDING_FUND_RAISING_REQUEST_COUNT_FAIL});
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
    }
}

export function clearPendingFundRaisingRequestCount() {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.CLEAR_PENDING_FUND_RAISING_REQUEST_COUNT});
    }
}

export function acceptFundRaisingRequest(companyId, requesterUserId, companyName) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.ACCEPTING_FUND_RAISING_REQUEST});
        put(`${ROUTES.ACCEPT_FUND_RAISING_REQUEST}${companyId}`, {requesterUserId, companyName})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: DataRoomTypes.ACCEPT_FUND_RAISING_REQUEST_SUCCESS});
                    showAlert(dispatch, ALERT.SUCCESS, response.data.message);
                } else {
                    dispatch({type: DataRoomTypes.ACCEPT_FUND_RAISING_REQUEST_FAIL});
                    showAlert(dispatch, ALERT.ERROR, response.data.message);
                }
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
            .catch(err => {
                dispatch({type: DataRoomTypes.ACCEPT_FUND_RAISING_REQUEST_FAIL});
                // showAlert(dispatch, ALERT.UNAVAILABLE, ERROR_MESSAGES.EXCEPTION_MESSAGE, 5000); removed to handle from webhelper.js
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
    }
}

export function rejectFundRaisingRequest(companyId, requesterUserId) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.REJECTING_FUND_RAISING_REQUEST});
        put(`${ROUTES.REJECT_FUND_RAISING_REQUEST}${companyId}`, {requesterUserId})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: DataRoomTypes.REJECT_FUND_RAISING_REQUEST_SUCCESS});
                    showAlert(dispatch, ALERT.SUCCESS, response.data.message);
                } else {
                    dispatch({type: DataRoomTypes.REJECT_FUND_RAISING_REQUEST_FAIL});
                    showAlert(dispatch, ALERT.ERROR, response.data.message);
                }
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
            .catch(err => {
                dispatch({type: DataRoomTypes.REJECT_FUND_RAISING_REQUEST_FAIL});
                // showAlert(dispatch, ALERT.UNAVAILABLE, ERROR_MESSAGES.EXCEPTION_MESSAGE, 5000); removed to handle from webhelper.js
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
    }
}

export function fetchSharedFundRaisingRequests(companyId, requestType = 1, skip, limit) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.FETCHING_SHAREDUSERS_FUND_RAISING_REQUESTS});
        post(`${ROUTES.FETCH_SHAREDWITH_FUND_RAISING_REQUESTS}${companyId}`, {requestType, skip, limit})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({
                        type: DataRoomTypes.FETCH_SHAREDUSERS_FUND_RAISING_REQUESTS_SUCCESS,
                        payload: response.data,
                        reset: skip === 0
                    });
                } else {
                    dispatch({type: DataRoomTypes.FETCH_SHAREDUSERS_FUND_RAISING_REQUESTS_FAIL});
                }
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                dispatch({type: DataRoomTypes.FETCH_SHAREDUSERS_FUND_RAISING_REQUESTS_FAIL});
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
    }
}

export function sharedFundRaisingRequestCount(companyId, requestType = 1) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.FETCHING_SHAREDUSERS_FUND_RAISING_REQUEST_COUNT});
        post(`${ROUTES.GET_SHAREDWITH_FUND_RAISING_REQUEST_COUNT}${companyId}`, {requestType})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({
                        type: DataRoomTypes.FETCH_SHAREDUSERS_FUND_RAISING_REQUEST_COUNT_SUCCESS,
                        payload: response.data.data.COUNT
                    });
                } else {
                    dispatch({type: DataRoomTypes.FETCH_SHAREDUSERS_FUND_RAISING_REQUEST_COUNT_FAIL});
                }
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                dispatch({type: DataRoomTypes.FETCH_SHAREDUSERS_FUND_RAISING_REQUEST_COUNT_FAIL});
                dispatch({type: DataRoomTypes.CLEAR_ACTION});
            })
    }
}

export function clearFundRaisingSharedRequests() {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.CLEAR_FUND_RAISING_SHAREDUSERS});
    }
}

export function clearSharedFundRaisingRequestCount() {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.CLEAR_SHAREDUSER_FUND_RAISING_REQUEST_COUNT});
    }
}

export function revokeFundRaisingAccess(companyId, userId) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.REVOKE_FUND_RAISING_ACCESS});
        post(`${ROUTES.REVOKE_FUND_RAISING_ACCESS}${companyId}`, {requesterUserId: userId})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: DataRoomTypes.REVOKE_FUND_RAISING_ACCESS_SUCCESS, userId: userId});
                    dispatch({type: DataRoomTypes.CLEAR_FUND_RAISING_USER_ACTION});
                    showAlert(dispatch, ALERT.SUCCESS, response.data.message);
                } else {
                    dispatch({type: DataRoomTypes.REVOKE_FUND_RAISING_ACCESS_FAIL});
                    showAlert(dispatch, ALERT.ERROR, response.data.message);
                }
            })
            .catch(error => {
                // showAlert(dispatch, ALERT.UNAVAILABLE, ERROR_MESSAGES.EXCEPTION_MESSAGE, 5000); removed to handle from webhelper.js
            })
    }
}

export function reGrantFundRaisingAccess(companyId, userId) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.RE_GRANT_FUND_RAISING_ACCESS});
        post(`${ROUTES.REGRANT_FUND_RAISING_ACCESS}${companyId}`, {requesterUserId: userId})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: DataRoomTypes.RE_GRANT_FUND_RAISING_ACCESS_SUCCESS, userId: userId});
                    dispatch({type: DataRoomTypes.CLEAR_FUND_RAISING_USER_ACTION});
                    showAlert(dispatch, ALERT.SUCCESS, response.data.message);
                } else {
                    dispatch({type: DataRoomTypes.RE_GRANT_FUND_RAISING_ACCESS_FAIL});
                    showAlert(dispatch, ALERT.ERROR, response.data.message);
                }
            })
            .catch(error => {
                // showAlert(dispatch, ALERT.UNAVAILABLE, ERROR_MESSAGES.EXCEPTION_MESSAGE, 5000); removed to handle from webhelper.js
            })
    }
}

export function updateReinvitedInvestors(invList) {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.CLEAR_RE_INVITED_DATAROOM_INVITATION, payload: invList})
    }
}

export function clearDataRoomReducer() {
    return function (dispatch) {
        dispatch({type: DataRoomTypes.CLEAR_DATAROOM_REDUCER})
    }
}
