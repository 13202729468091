module.exports = {

    FETCHING_CONTACTS: "FETCHING_CONTACTS",
    FETCHING_CONTACTS_SUCCESS: "FETCHING_CONTACTS_SUCCESS",
    FETCHING_CONTACTS_FAIL: "FETCHING_CONTACTS_FAIL",

    SENDING_INVITATIONS: "SENDING_INVITATIONS",
    SENDING_INVITATIONS_SUCCESS: "SENDING_INVITATIONS_SUCCESS",
    SENDING_INVITATIONS_FAIL: "SENDING_INVITATIONS_FAIL",

    SENDING_CUSTOM_INVITATIONS: "SENDING_CUSTOM_INVITATIONS",
    SENDING_CUSTOM_INVITATIONS_SUCCESS: "SENDING_CUSTOM_INVITATIONS_SUCCESS",
    SENDING_CUSTOM_INVITATIONS_FAIL: "SENDING_CUSTOM_INVITATIONS_FAIL",

    VALIDATING_INVITATION: "VALIDATING_INVITATION",
    VALIDATING_INVITATION_SUCCESS: "VALIDATING_INVITATION_SUCCESS",
    VALIDATING_INVITATION_FAIL: "VALIDATING_INVITATION_FAIL",

    FETCHING_WINDOWS_LIVE_CONTACTS: "FETCHING_WINDOWS_LIVE_CONTACTS",
    FETCHING_WINDOWS_LIVE_CONTACTS_SUCCESS: "FETCHING_WINDOWS_LIVE_CONTACTS_SUCCESS",
    FETCHING_WINDOWS_LIVE_CONTACTS_FAIL: "FETCHING_WINDOWS_LIVE_CONTACTS_FAIL",

    FETCHING_YAHOO_CONTACTS: "FETCHING_YAHOO_CONTACTS",
    FETCHING_YAHOO_CONTACTS_SUCCESS: "FETCHING_YAHOO_CONTACTS_SUCCESS",
    FETCHING_YAHOO_CONTACTS_FAIL: "FETCHING_YAHOO_CONTACTS_FAIL",


};