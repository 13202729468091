import React, { Component } from "react";
import { Col } from "./../col";
import { getPreview} from "../../../libs/textFormat"
import "./index.css";

class EventRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    
    return (
      <Col className="col-lg-6">
        <div className="evetn___row">
          <div className="d-flex flex-row">
            <div className="event__row___datebox d-flex justify-content-center align-items-center flex-column">
              <h1 className="font_regular font_40 font_white">
                {this.props.date}
              </h1>
              <h3 className="font_bold font_28 font_white">
                {this.props.month}
              </h3>
            </div>
            <div className="event__row___middle">
              <h3 className="font_20 font_gray " style={{cursor: 'pointer'}} onClick={this.props.view} title={this.props.title}>{getPreview(this.props.title,40)}</h3>
              <p className="font_16 font_gray_two">
                  {this.props.type && this.props.type === 1 && <i className="fa fa-link" />}
                  {this.props.type && this.props.type === 2 && <i className="fas fa-map-marker-alt" />}
                <span className="m-l5">{this.props.type && this.props.type === 2 ? this.props.place : <span target="_blank" href={this.props.url} > Online event</span> }</span>
              </p>
              <p className="font_16 font_gray_two">
                <i className="far fa-clock" />
                <span className="m-l5">{this.props.time}</span>
              </p>
            </div>
          </div>
          <div
            style={{ opacity: this.props.indicate === "Inactive" ? 0.6 : 1 }}
            className="evetn___row____status__ind d-flex justify-content-center align-items-center"
          >
            {this.props.indicate}
          </div>

          {
            !this.props.isPublicView && 
            <div className="evetn___row____buttons">
              <div
                className="btn-group mr-2"
                role="group"
                aria-label="Second group"
              >
                <button
                  onClick={this.props.delete}
                  type="button"
                  className="btn  btn-outline-secondary evetn___row____button_delete"
                >
                  <i className="font_red fas fa-trash-alt" />
                </button>
                <button
                  onClick={this.props.edit}
                  type="button"
                  className="btn  btn-outline-secondary evetn___row____button_edit"
                >
                  <i className="fas fa-pencil-alt font_gray" />
                </button>
                <button
                  onClick={this.props.registerdUsers}
                  type="button"
                  className="btn  btn-outline-secondary evetn___row____button_edit"
                >
                  <i className="fas fa-users font_gray" />
                </button>
              </div>
            </div>
          }
          
        </div>
      </Col>
    );
  }
}

export { EventRow };
