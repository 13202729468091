import React from "react";
import "./index.css";

const FormCard = ({ children, title, subTitle, customHeader ,hideHeader}) => {
  return (
    <div className="form_card">
      {!hideHeader && <div className="form_card_heading">
      {customHeader === null ? <span> 
        <h3 className="form_card_title text-center font_30 font_d_blue font_medium" style={{whiteSpace: 'pre-wrap'}}>
          {title}
        </h3>
        <p className="form_card_sub_title text-center">{subTitle}</p>
         </span>  : customHeader }
      </div>}
      <div className="form_card_body ">{children}</div>
    </div>
  );
};
FormCard.defaultProps = {
  title: null,
  subTitle: null,
  customHeader : null
};
export { FormCard };
