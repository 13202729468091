import messageType from '../types/message';

export const initialState = {
    fetching: false,
    fetchingMessages: false,
    sendingMessage: false,
    error: null,
    action: 0,
    currentUser: null,
    conversations: [],
    messageList: [],
    participantsImageList: [],
    unSeenCount: 0,
    latestUnseenConversation: [],
    latestUnseenCompanyConversation: [],
    messageCount: 0,
    isDropDownEnabled: false,
    myConnections: [],
    companyId: null,
    searchedConversations: [],
    searchedUsers: [],
    recipientConnectionStatus: null,
    conversationCount: 0,
    fetchingCompanyMessages: false,
    fetchingConversations: false,
    searchUserCount: 0
};

export const messages = (state = initialState, action) => {
    switch (action.type) {
        case messageType.CLEAR_ACTION:
            return {
                ...state,
                action: messageType.CLEAR_ACTION,
                fetching: false
            };
        case messageType.SET_USER:
            return {
                ...state,
                action: messageType.SET_USER,
                currentUser: action.payload
            };
        case messageType.LOAD_CONVERSATIONS:
            return {
                ...state,
                fetching: action.sStatus ? false : true,
                fetchingConversations: true,
                action: messageType.LOAD_CONVERSATIONS,
            };
        case messageType.LOAD_CONVERSATIONS_SUCCESS:
            return {
                ...state,
                fetching: false,
                fetchingConversations: false,
                action: messageType.LOAD_CONVERSATIONS_SUCCESS,
                conversations: action.sStatus ? action.payload : action.skip === 0 ? action.payload : state.conversations.concat(action.payload)
            };
        case messageType.LOAD_CONVERSATIONS_FAIL:
            return {
                ...state,
                fetching: false,
                fetchingConversations: false,
                action: messageType.LOAD_CONVERSATIONS_FAIL
            };
        case messageType.CLEAR_CONVERSATIONS:
            return {
                ...state,
                fetching: false,
                action: messageType.CLEAR_CONVERSATIONS,
                conversations: []
            };

        case messageType.SENDING_MESSAGE:
            return {
                ...state,
                action: messageType.SENDING_MESSAGE,
                sendingMessage: true
            };
        case messageType.SEND_MESSAGE_SUCCESS:
            return {
                ...state,
                action: messageType.SEND_MESSAGE_SUCCESS,
                sendingMessage: false
                // messageList: action.payload.data.concat(state.messageList)
            };
        case messageType.SEND_MESSAGE_FAIL:
            return {
                ...state,
                action: messageType.SEND_MESSAGE_FAIL,
                sendingMessage: false
            };
        case messageType.SEND_MESSAGE_VALIDATION_ERROR:
            return {
                ...state,
                action: messageType.SEND_MESSAGE_VALIDATION_ERROR
            };
        case messageType.SEND_MESSAGE_EXCEPTION:
            return {
                ...state,
                action: messageType.SEND_MESSAGE_EXCEPTION
            };

        case messageType.FETCHING_MESSAGES:
            return {
                ...state,
                fetchingMessages: action.contentLoader ? true : false,
                action: messageType.FETCHING_MESSAGES,
                fetchingCompanyMessages: true
            };
        case messageType.FETCHING_MESSAGES_SUCCESS:
            return {
                ...state,
                fetchingMessages: false,
                fetchingCompanyMessages: false,
                action: messageType.FETCHING_MESSAGES_SUCCESS,
                messageList: action.concatArrays ? action.payload.data.reverse().concat(state.messageList) : action.payload.data.reverse()
            };
        case messageType.FETCHING_MESSAGES_FAIL:
            return {
                ...state,
                fetchingMessages: false,
                fetchingCompanyMessages: false,
                action: messageType.FETCHING_MESSAGES_FAIL
            };
        case messageType.FETCHING_MESSAGES_INVALID_REQUEST:
            return {
                ...state,
                action: messageType.FETCHING_MESSAGES_INVALID_REQUEST
            };
        case messageType.FETCHING_MESSAGES_EXCEPTION:
            return {
                ...state,
                fetchingCompanyMessages: false,
                action: messageType.FETCHING_MESSAGES_EXCEPTION
            };
        case messageType.CLEAR_MESSAGES:
            return {
                ...state,
                action: messageType.CLEAR_MESSAGES,
                messageList: []
            };

        case messageType.FETCHING_MESSAGE_COUNT:
            return {
                ...state,
                action: messageType.FETCHING_MESSAGE_COUNT
            };
        case messageType.FETCH_MESSAGE_COUNT_SUCCESS:
            return {
                ...state,
                action: messageType.FETCH_MESSAGE_COUNT_SUCCESS,
                messageCount: action.payload.COUNT
            };
        case messageType.FETCH_MESSAGE_COUNT_FAIL:
            return {
                ...state,
                action: messageType.FETCH_MESSAGE_COUNT_FAIL
            };
        case messageType.FETCH_MESSAGE_COUNT_EXCEPTION:
            return {
                ...state,
                action: messageType.FETCH_MESSAGE_COUNT_EXCEPTION
            };
        case messageType.CLEAR_MESSAGE_COUNT:
            return {
                ...state,
                action: messageType.CLEAR_MESSAGE_COUNT,
                messageCount: 0
            };

        case messageType.FETCHING_PARTICIPANTS:
            return {
                ...state,
                action: messageType.FETCHING_PARTICIPANTS
            };
        case messageType.FETCH_PARTICIPANTS_SUCCESS:
            return {
                ...state,
                companyId: action.payload.PARTICIPANTS.length === 2 ? action.payload.PARTICIPANTS[0].COMPANY_NAME ? action.payload.PARTICIPANTS[0]._id : action.payload.PARTICIPANTS[1]._id : null,
                action: messageType.FETCH_PARTICIPANTS_SUCCESS,
                participantsImageList: action.payload.PARTICIPANTS
            };
        case messageType.FETCH_PARTICIPANTS_FAIL:
            return {
                ...state,
                action: messageType.FETCH_PARTICIPANTS_FAIL
            };
        case messageType.FETCH_PARTICIPANTS_EXCEPTION:
            return {
                ...state,
                action: messageType.FETCH_PARTICIPANTS_EXCEPTION
            };
        case messageType.GET_PARTICIPANTS_IMAGE_EXCEPTION:
            return {
                ...state,
                action: messageType.GET_PARTICIPANTS_IMAGE_EXCEPTION
            };
        case messageType.CLEAR_PARTICIPANTS:
            return {
                ...state,
                action: messageType.CLEAR_PARTICIPANTS,
                participantsImageList: []
            };
        case messageType.FETCHING_COMPANY_LATEST_UNSEEN_CONVERSATION:
            return {
                ...state,
                action: messageType.FETCHING_COMPANY_LATEST_UNSEEN_CONVERSATION
            };
        case messageType.FETCH_LATEST_COMPANY_UNSEEN_CONVERSATION_SUCCESS:
            return {
                ...state,
                action: messageType.FETCH_LATEST_COMPANY_UNSEEN_CONVERSATION_SUCCESS,
                latestUnseenCompanyConversation: action.payload.data
            };
        case messageType.CLEAR_LATEST_COMPANY_UNSEEN_CONVERSATION:
            return {
                ...state,
                latestUnseenCompanyConversation: []
            };
        case messageType.FETCH_LATEST_COMPANY_UNSEEN_CONVERSATION_FAIL:
            return {
                ...state,
                action: messageType.FETCH_LATEST_COMPANY_UNSEEN_CONVERSATION_FAIL
            };

        case messageType.FETCHING_LATEST_UNSEEN_CONVERSATION:
            return {
                ...state,
                action: messageType.FETCHING_LATEST_UNSEEN_CONVERSATION
            };
        case messageType.FETCH_LATEST_UNSEEN_CONVERSATION_SUCCESS:
            return {
                ...state,
                action: messageType.FETCH_LATEST_UNSEEN_CONVERSATION_SUCCESS,
                latestUnseenConversation: action.payload.data
            };
        case messageType.FETCH_LATEST_UNSEEN_CONVERSATION_FAIL:
            return {
                ...state,
                action: messageType.FETCH_LATEST_UNSEEN_CONVERSATION_FAIL
            };

        case messageType.ENABLE_DROP_DOWN:
            return {
                ...state,
                isDropDownEnabled: action.payload,
                action: messageType.ENABLE_DROP_DOWN,
            };
        case messageType.SEARCHING_MY_CONNECTIONS:
            return {
                ...state,
                action: messageType.SEARCHING_MY_CONNECTIONS
            };
        case messageType.SEARCHING_MY_CONNECTIONS_SUCCESS:
            return {
                ...state,
                action: messageType.SEARCHING_MY_CONNECTIONS_SUCCESS,
                myConnections: action.payload.data
            };
        case messageType.SEARCHING_MY_CONNECTIONS_FAIL:
            return {
                ...state,
                action: messageType.SEARCHING_MY_CONNECTIONS_FAIL
            };
        case messageType.SEARCHING_MY_CONNECTIONS_NOT_FOUND:
            return {
                ...state,
                action: messageType.SEARCHING_MY_CONNECTIONS_NOT_FOUND
            };
        case messageType.SEARCHING_MY_CONNECTIONS_EXCEPTION:
            return {
                ...state,
                action: messageType.SEARCHING_MY_CONNECTIONS_EXCEPTION
            };
        case messageType.CLEAR_MY_CONNECTIONS:
            return {
                ...state,
                action: messageType.CLEAR_MY_CONNECTIONS,
                myConnections: []
            };


        case messageType.SEARCH_CONVERSATIONS:
            return {
                ...state,
                fetching: true,
                action: messageType.SEARCH_CONVERSATIONS,
            };
        case messageType.SEARCH_CONVERSATIONS_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: messageType.SEARCH_CONVERSATIONS_SUCCESS,
                searchedConversations: action.sStatus ? action.payload : state.conversations.concat(action.payload)
            };
        case messageType.SEARCH_CONVERSATIONS_FAIL:
            return {
                ...state,
                fetching: false,
                action: messageType.SEARCH_CONVERSATIONS_FAIL
            };
        case messageType.CLEAR_SEARCH_CONVERSATIONS:
            return {
                ...state,
                fetching: false,
                action: messageType.CLEAR_SEARCH_CONVERSATIONS,
                searchedConversations: []
            };

        case messageType.SEARCH_USERS_FOR_CONVERSATION:
            return {
                ...state,
                fetching: true,
                action: messageType.SEARCH_USERS_FOR_CONVERSATION,
            };
        case messageType.SEARCH_USERS_FOR_CONVERSATION_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: messageType.SEARCH_USERS_FOR_CONVERSATION_SUCCESS,
                searchedUsers: action.isLoadingMore === true ? state.searchedUsers.concat(action.payload) : action.payload
            };
        case messageType.SEARCH_USERS_FOR_CONVERSATION_FAIL:
            return {
                ...state,
                fetching: false,
                action: messageType.SEARCH_USERS_FOR_CONVERSATION_FAIL,
                searchedUsers: []
            };

        case messageType.CLEAR_SEARCH_USERS_FOR_CONVERSATION:
            return {
                ...state,
                fetching: false,
                action: messageType.CLEAR_SEARCH_USERS_FOR_CONVERSATION,
                searchedUsers: []
            };

        case messageType.SEARCHING_USER_COUNT:
            return {
                ...state,
                action: messageType.SEARCHING_USER_COUNT,
            };
        case messageType.SEARCH_USER_COUNT_SUCCESS:
            return {
                ...state,
                action: messageType.SEARCH_USER_COUNT_SUCCESS,
                searchUserCount: action.payload[0].count
            };
        case messageType.SEARCH_USER_COUNT_SUCCESS_FAIL:
            return {
                ...state,
                action: messageType.SEARCH_USER_COUNT_SUCCESS_FAIL,
                searchUserCount: 0
            };
        case messageType.CLEAR_SEARCH_USER_COUNT:
            return {
                ...state,
                action: messageType.CLEAR_SEARCH_USER_COUNT,
                searchUserCount: 0
            };

        case messageType.MESSAGE_SEEN_SUCCESS:
            return {
                ...state,
                action: messageType.MESSAGE_SEEN_SUCCESS,
            };
        case messageType.MESSAGE_SEEN_FAIL:
            return {
                ...state,
                action: messageType.MESSAGE_SEEN_FAIL
            };
        case messageType.MESSAGE_SEEN_EXCEPTION:
            return {
                ...state,
                action: messageType.MESSAGE_SEEN_EXCEPTION
            };

        case messageType.FETCHING_RECIPIENT_CONNECTION_STATUS:
            return {
                ...state,
                action: messageType.FETCHING_RECIPIENT_CONNECTION_STATUS
            };
        case messageType.FETCH_RECIPIENT_CONNECTION_STATUS_SUCCESS:
            return {
                ...state,
                action: messageType.FETCH_RECIPIENT_CONNECTION_STATUS_SUCCESS,
                recipientConnectionStatus: action.payload.CONNECTION_STATUS ? action.payload.CONNECTION_STATUS : null
            };
        case messageType.FETCH_RECIPIENT_CONNECTION_STATUS_FAIL:
            return {
                ...state,
                action: messageType.FETCH_RECIPIENT_CONNECTION_STATUS_FAIL,
                recipientConnectionStatus: null
            };

        case messageType.FETCHING_CONVERSATION_COUNT:
            return {
                ...state,
                action: messageType.FETCHING_CONVERSATION_COUNT,
            };
        case messageType.FETCHING_CONVERSATION_COUNT_SUCCESS:
            return {
                ...state,
                action: messageType.FETCHING_CONVERSATION_COUNT_SUCCESS,
                conversationCount: action.payload.COUNT
            };
        case messageType.FETCHING_CONVERSATION_COUNT_FAIL:
            return {
                ...state,
                action: messageType.FETCHING_CONVERSATION_COUNT_FAIL
            };

        default:
            return state
    }
};