import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
//import avatar from "./../../../assets/sample/avt.png";
import avatar from "./../../../assets/img/default-male.jpg";
import { SIGNED_COOKIES_CF_URL } from '../../../config';

import { isURL, isValidImageURL } from '../../../libs/validation';
import { PUBLIC_PENDING_ACTIONS } from '../../../types/common';
import { Time } from '../time';
import { trackEvent } from '../../../actions/eventTracking';
import { EVENT_ACTION } from '../../../types/eventTracking';
import { MUTUAL } from '../../../types/dataTrackingElementIds';

class PostCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatar: avatar,
    };
  }

  _isMounted = false;

  componentDidMount() {
      this._isMounted = true;
      if (this.props.imgSrc && this.props.imgSrc !== '') {
          if (!isURL(String.toString(this.props.imgSrc), {protocols: ['https']}, SIGNED_COOKIES_CF_URL)) {
              isValidImageURL(this.props.imgSrc, 1).then((avatar) => {
                  this.setState({avatar});
              }).catch((avatar) => {
                  this.setState({avatar});
              });
          }
      }
  }

  componentDidUpdate(prevProps) {
      if ((this.props.imgSrc && this.props.imgSrc !== '') && (this.props.imgSrc !== prevProps.imgSrc)) {
          if (!isURL(String.toString(this.props.imgSrc), {protocols: ['https']}, SIGNED_COOKIES_CF_URL)) {
              isValidImageURL(this.props.imgSrc, 1).then((avatar) => {
                  this.setState({avatar});
              }).catch((avatar) => {
                  this.setState({avatar});
              });
          }
      }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  isUrl(s) {
      var regexp = /(ftp|http|https):\/\/(\w:{0,1}\w*@)?(\S)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/
      return regexp.test(s);
  }

  checkImg = () => {
    if (this.props.imgSrc) {
      if (
        localStorage.getItem('profileThumbnail') &&
        this.props.creatorId === localStorage.getItem('userId')
      ) {
        return localStorage.getItem('profileThumbnail');
      } else {
        if (
          this.props.creatorId !== localStorage.getItem('userId') &&
          this.isUrl(this.props.imgSrc)
        ) {
          return this.props.imgSrc;
        } else {
          return this.state.avatar;
        }
      }
    } else {
      return avatar;
    }
  };

  render() {
    let avatarStyle = {
      // backgroundImage: "url(" + this.props.imgSrc + ")",
      backgroundImage: 'url(' + this.checkImg() + ')',
      width: this.props.size + 'px',
      height: this.props.size + 'px',
    };

    let nameSpace = {
      marginTop: this.props.singlePost ? '0px' : this.props.size - 50 + 'px',
    };

    let imageWidth = {
      width: this.props.size + 'px',
    };

    return (
      <div className="d-flex align-items-center">
        <div style={imageWidth}>
          <Link
            style={{ textDecoration: 'none' }}
            to={
              this.props.isPublicFeed
                ? '/'
                : `/user-profile/${this.props.creatorId}`
            }
            onClick={() => {
              if (this.props.user && this.props.page) {
                /* data project - event trcking */
                const eventPayload = {
                  user_id: this.props.user._id,
                  page: this.props.page,
                  section: this.props.section,
                  element_type: 'LINK',
                  element_id: null,
                  action_type: EVENT_ACTION.CLICK,
                  post_id: this.props.postId,
                  profile_id: this.props.creatorId,
                  is_user_profile: true,
                };
                this.props.dispatch(trackEvent(eventPayload));
                /* END : data project - event trcking */
              }
              if (this.props.isPublicFeed && this.props.openLogin) {
                this.props.openLogin(
                  PUBLIC_PENDING_ACTIONS.P_VIEW_USER,
                  `/user-profile/${this.props.creatorId}`
                );
              }
            }}
          >
            <div className="post_creator" style={avatarStyle} />
          </Link>
        </div>
        <div
          className="m-l15 creator-details"
          style={this.props.size ? nameSpace : null}
        >
          <div className="d-flex align-items-center">
            <Link
              style={{ textDecoration: 'none' }}
              to={
                this.props.isPublicFeed
                  ? '/'
                  : (this.props.creatorId === localStorage.getItem('userId')? `/user-profile-edit/${this.props.creatorId}` : `/user-profile/${this.props.creatorId}`)
              }
              onClick={() => {
                if (this.props.user && this.props.page) {
                  /* data project - event trcking */
                  const eventPayload = {
                    user_id: this.props.user._id,
                    page: this.props.page,
                    section: this.props.section,
                    element_type: 'LINK',
                    element_id: null,
                    action_type: EVENT_ACTION.CLICK,
                    post_id: this.props.postId,
                    profile_id: this.props.creatorId,
                    is_user_profile: true,
                  };
                  this.props.dispatch(trackEvent(eventPayload));
                  /* END : data project - event trcking */
                }
                if (this.props.isPublicFeed && this.props.openLogin) {
                  this.props.openLogin(
                    PUBLIC_PENDING_ACTIONS.P_VIEW_USER,
                    `/user-profile/${this.props.creatorId}`
                  );
                }
              }}
            >
              <h3 className="font_14 font_bold font_dark_gray_two get_zero_space">
                {this.props.creatorName}
              </h3>
            </Link>
            <div className="badges d-flex align-items-centers m-l5">
              {this.props.isProUser && (
                <div className="badge badge-primary m-r5">PRO</div>
              )}
              {this.props.isExpertContributor && (
                <div className="badge badge-primary m-r5 green">Expert</div>
              )}
            </div>
          </div>
          {this.props.creatorDetails &&
            this.props.creatorDetails !== ' undefined' && (
              <div className="headline">{this.props.creatorDetails}</div>
            )}
          <div className="d-flex align-items-center poster-info">
            <div className="time-ago">
              <Time time={this.props.time} />
            </div>
            {this.props.mutualFriendCount > 0 && (
              <span>
                <span className="m-l5 m-r5">•</span>
                <span
                  onClick={() => {
                    if (this.props.user && this.props.page) {
                      /* data project - event trcking */
                      const eventPayload = {
                        user_id: this.props.user._id,
                        page: this.props.page,
                        section: this.props.section,
                        element_type: 'LINK',
                        element_id: MUTUAL.MUTUAL_CON,
                        action_type: EVENT_ACTION.CLICK,
                        post_id: this.props.postId,
                      };
                      this.props.dispatch(trackEvent(eventPayload));
                      /* END : data project - event trcking */
                    }
                    this.props.showMutualPopUp(
                      this.props.mutualFriendCount,
                      this.props.creatorId,
                      this.props.user,
                      this.props.dispatch,
                      this.props.page,
                      this.props.section
                    );
                  }}
                  style={{ cursor: 'pointer' }}
                  className="font_light font_11 m-0"
                >
                  {this.props.mutualFriendCount} Mutual{' '}
                  {this.props.mutualFriendCount > 1
                    ? 'Connections'
                    : 'Connection'}
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PostCreator.defaultProps = {
  imgSrc: avatar,
  creatorName: null,
  creatorDetails: null,
  size: null,
};
export { PostCreator };
