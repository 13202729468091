import React from "react";
import "./index.css"

const SubMenu = ({ children }) => {

  return (
        <nav id="nav-bar" className="navbar navbar-expand-lg navbar-light">
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    {children}
                </ul>
            </div>
        </nav>
  );
};

const SubMenuItem = ({name, isActive, onClick, menuId}) => {

    let style = isActive ?  "nav-item active" : "nav-item";

    return (
        <li className={style}>
            <button className="nav-link" onClick={onClick.bind(this, menuId)}>{name}</button>
        </li>
    )
};

const SubMenuDropDown = ({children, isOpen, onClick}) => {

    return (
        <li className={"nav-item active"} style={{borderRight: 0+'px'}}>
            <button className="nav-link" onClick={() => onClick()}>More <i className="fas fa-caret-down"/></button>
            <div className={`subMenuDropDown ${isOpen ? 'show': ''}`}>
                <ul>
                    {children}
                </ul>
            </div>
        </li>
    )
};

export { SubMenu, SubMenuItem, SubMenuDropDown };
