import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Modal,
  ModalBody,
  ModalTitle,
  ModalHeader,
  ModalClose,
  ModalFormGroup,
} from '../../components/model';

import { Tabs, TabMenu, MenuItem, TabContent } from '../../components/tabs';
import likeReaction from '../../assets/img/reactions/like.png';
import loveReaction from '../../assets/img/reactions/love.png';
import hahaReaction from '../../assets/img/reactions/haha.png';
import sadReaction from '../../assets/img/reactions/sad.png';
import angryReaction from '../../assets/img/reactions/angry.png';
import insightfulReaction from '../../assets/img/reactions/insightful.png';
import wowReaction from '../../assets/img/reactions/wow.png';

import { AvatarProfile } from '../../components/common';
import { LikedUsersForPost } from '../../components/common/contentLoaders/feed';
import {
  THUMBNAIL, CONNECTION_STATUS, NOTIFICATION_TYPES, SYSTEM_USER_STATUS, USER_EMOTION_TYPES, POP_ALERT, BLOCKUSER_CONNECT_ERROR_MSG, BLOCKUSER_POPUP_CLASSNAME,
  BLOCKUSER_ACCEPT_ERROR_MSG, BLOCKUSER_REJECT_ERROR_MSG, BLOCKUSER_MSG_ERROR_MSG
} from "../../types/common"
import { requestConnection } from '../../actions/newsFeed'
import { acceptConnection, rejectConnection } from '../../actions/connections'
import './index.css'
import { trackEvent } from '../../actions/eventTracking';
import { EVENT_ACTION } from '../../types/eventTracking';
import { POST } from '../../types/dataTrackingElementIds';
import { showPopupAlert } from '../../actions/alert';
import { checkBlockStatus } from '../../actions/profile';

const ViewUsersModal = (props) => {
  // Destruct Props
  const {
    title = '',
    isOpen,
    close,
    fetchUsers = null,
    dispatch,
    socket,
    activeUser = null,
    page = null,
    section = null,
    postId = null,
  } = props;

  // State
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reactCounts, setReactCounts] = useState([]);
  const [filter, setFilter] = useState(false);
  const [deletedUsers, setDeletedUsers] = useState([]);

  // Effects
  useEffect(() => {
    if (fetchUsers) {
      (async () => {
        setLoading(true);
        const { success, data } = await fetchUsers();
        setLoading(false);
        if (success) {
          setUsers(data);
          let counts = [];
          data.forEach((d) => {
            if (d.EMOTION_TYPE === USER_EMOTION_TYPES.LIKE) {
              counts[USER_EMOTION_TYPES.LIKE] = {
                count: counts[USER_EMOTION_TYPES.LIKE]
                  ? counts[USER_EMOTION_TYPES.LIKE].count + 1
                  : 1,
                img: likeReaction,
                type: USER_EMOTION_TYPES.LIKE,
              };
            } else if (d.EMOTION_TYPE === USER_EMOTION_TYPES.HEART) {
              counts[USER_EMOTION_TYPES.HEART] = {
                count: counts[USER_EMOTION_TYPES.HEART]
                  ? counts[USER_EMOTION_TYPES.HEART].count + 1
                  : 1,
                img: loveReaction,
                type: USER_EMOTION_TYPES.HEART,
              };
            } else if (d.EMOTION_TYPE === USER_EMOTION_TYPES.INSIGHTFUL) {
              counts[USER_EMOTION_TYPES.INSIGHTFUL] = {
                count: counts[USER_EMOTION_TYPES.INSIGHTFUL]
                  ? counts[USER_EMOTION_TYPES.INSIGHTFUL].count + 1
                  : 1,
                img: insightfulReaction,
                type: USER_EMOTION_TYPES.INSIGHTFUL,
              };
            } else if (d.EMOTION_TYPE === USER_EMOTION_TYPES.HAHA) {
              counts[USER_EMOTION_TYPES.HAHA] = {
                count: counts[USER_EMOTION_TYPES.HAHA]
                  ? counts[USER_EMOTION_TYPES.HAHA].count + 1
                  : 1,
                img: hahaReaction,
                type: USER_EMOTION_TYPES.HAHA,
              };
            } else if (d.EMOTION_TYPE === USER_EMOTION_TYPES.WOW) {
              counts[USER_EMOTION_TYPES.WOW] = {
                count: counts[USER_EMOTION_TYPES.WOW]
                  ? counts[USER_EMOTION_TYPES.WOW].count + 1
                  : 1,
                img: wowReaction,
                type: USER_EMOTION_TYPES.WOW,
              };
            } else if (d.EMOTION_TYPE === USER_EMOTION_TYPES.SAD) {
              counts[USER_EMOTION_TYPES.SAD] = {
                count: counts[USER_EMOTION_TYPES.SAD]
                  ? counts[USER_EMOTION_TYPES.SAD].count + 1
                  : 1,
                img: sadReaction,
                type: USER_EMOTION_TYPES.SAD,
              };
            } else if (d.EMOTION_TYPE === USER_EMOTION_TYPES.ANGRY) {
              counts[USER_EMOTION_TYPES.ANGRY] = {
                count: counts[USER_EMOTION_TYPES.ANGRY]
                  ? counts[USER_EMOTION_TYPES.ANGRY].count + 1
                  : 1,
                img: angryReaction,
                type: USER_EMOTION_TYPES.ANGRY,
              };
            }
          });
          counts.sort((a, b) => parseInt(b.count) - parseInt(a.count));
          setReactCounts(counts);
        } else {
          setUsers([]);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.acceptedUID) {
      const connection = { STATUS: CONNECTION_STATUS.CONNECTED };
      updateUserObj(props.acceptedUID, connection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.acceptedUID]);

  useEffect(() => {
    if (props.rejectedUID) {
      const connection = { STATUS: CONNECTION_STATUS.BLOCKED };
      updateUserObj(props.rejectedUID, connection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rejectedUID]);

  useEffect(() => {
    if (props.requestedByUID) {
      const connection = { STATUS: CONNECTION_STATUS.PENDING };
      updateUserObj(props.requestedByUID, connection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.requestedByUID]);

  useEffect(() => {
    if (props.accepedByUID) {
      const connection = { STATUS: CONNECTION_STATUS.CONNECTED };
      updateUserObj(props.accepedByUID, connection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.accepedByUID]);

  useEffect(() => {
    if (props.rejectedByUID) {
      const connection = { STATUS: CONNECTION_STATUS.BLOCKED };
      updateUserObj(props.rejectedByUID, connection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rejectedByUID]);

  const updateUserObj = (userId, connection) => {
    if (users) {
      let updUsers = [...users];
      let index;
      let user = updUsers.filter((user, i) => {
        if (user.USER_ID === userId) {
          index = i;
          return user;
        }
        return false;
      })[0];

      if (user) {
        if (user.CONNECTION) {
          user.CONNECTION.STATUS = connection.STATUS;
        } else {
          user.CONNECTION = connection;
        }
        updUsers[index] = user;
        setUsers(updUsers);
      }
    }
  };

  /* data project - event trcking */
  const setPayload = () => {
    const eventPayload = {
      user_id: localStorage.getItem('userId'),
      page: page,
      section: section,
      element_type: 'BUTTON',
      element_id: null,
      post_id: postId,
      action_type: EVENT_ACTION.CLICK,
    };
    return eventPayload;
  };
  /* END : data project - event trcking */

  const sendConnectionRequest = async (user) => {
    /* data project - event trcking */
    if (localStorage.getItem('userId') && page) {
      let eventPayload = setPayload();
      eventPayload.element_id = POST.CONNECT;
      eventPayload.profile_id = user.USER_ID;
      eventPayload.is_user_profile = true;
      dispatch(trackEvent(eventPayload));
    }
    /* END : data project - event trcking */

    const { success } = await checkBlockStatus(user.USER_ID);
    if (success) {
      const socketInfo = {
        socket,
        userId: user.USER_ID,
        type: NOTIFICATION_TYPES.CONNECTION_REQUESTS,
      };
      const { success } = await requestConnection(user.USER_ID, '', socketInfo, dispatch);
      if (success) {
        const connection = {
          RECIPIENT: user.USER_ID,
          REQUESTER: localStorage.getItem('userId'),
          STATUS: CONNECTION_STATUS.PENDING,
        };
        updateUserObj(user.USER_ID, connection)
      }
    } else {
      showPopupAlert(dispatch, POP_ALERT.OK, '', BLOCKUSER_CONNECT_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
    }
  };

  const acceptConnectionRequest = async (user) => {
    /* data project - event trcking */
    if (localStorage.getItem('userId') && page) {
      let eventPayload = setPayload();
      eventPayload.element_id = POST.ACCEPT;
      eventPayload.profile_id = user.USER_ID;
      eventPayload.is_user_profile = true;
      dispatch(trackEvent(eventPayload));
    }
    /* END : data project - event trcking */

    const { success } = await checkBlockStatus(user.USER_ID);
    if (success) {
      dispatch(acceptConnection(user.USER_ID, null, (userStatus) => { if (!userStatus) setDeletedUsers([...deletedUsers, user.USER_ID]) }, socket));
    } else {
      showPopupAlert(dispatch, POP_ALERT.OK, '', BLOCKUSER_ACCEPT_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
    }
  };

  const rejectConnectionRequest = async (user) => {
    /* data project - event trcking */
    if (localStorage.getItem('userId') && page) {
      let eventPayload = setPayload();
      eventPayload.element_id = POST.REJECT;
      eventPayload.profile_id = user.USER_ID;
      eventPayload.is_user_profile = true;
      dispatch(trackEvent(eventPayload));
    }
    /* END : data project - event trcking */

    const { success } = await checkBlockStatus(user.USER_ID);
    if (success) {
      dispatch(rejectConnection(user.USER_ID, null, null, socket));
    } else {
      showPopupAlert(dispatch, POP_ALERT.OK, '', BLOCKUSER_REJECT_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
    }
  };

  const getSortedReactionsCounts = () => {

    return (
      <TabMenu>
        {users.length > 0 && <MenuItem active={true} onClick={() => setFilter(false)}>
          All <span className="m-l5 font-weight-bold">{users.length}</span>
        </MenuItem>}
        {
          reactCounts.map(rc => {
            if (rc.count > 0) {
              return (
                <MenuItem active={false} onClick={() => setFilter(rc.type)}>
                  <img src={rc.img} alt="Like" /> {rc.count}
                </MenuItem>
              )
            } else {
              return (<div />)
            }

          })
        }
      </TabMenu>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      className="view-users-modal modal-space-top-10 modal-md"
    >
      <ModalHeader>
        <ModalTitle>
          {' '}
          {loading ? '' : `${title} ${users && users.length}`}
        </ModalTitle>
        <ModalClose
          close={() => {
            /* data project - event trcking */
            if (localStorage.getItem('userId') && page) {
              let eventPayload = setPayload();
              eventPayload.element_type = 'ICON';
              eventPayload.element_id = POST.CLOSE;
              dispatch(trackEvent(eventPayload));
            }
            /* END : data project - event trcking */

            close();
          }}
        />
      </ModalHeader>
      <ModalBody>
        <ModalFormGroup className="p-0 pb-3">
          <Tabs className="reaction-tabs">
            {getSortedReactionsCounts()}
            <TabContent>
              {loading && <LikedUsersForPost />}
              {!loading && users && (
                <div className="ml_item_wrp scrollbar" id="style-1">
                  {users.length > 0 &&
                    users.map((user) => {
                      if (filter && user.EMOTION_TYPE !== filter) {
                        return null;
                      }
                      return (
                        <User
                          activeUser={activeUser}
                          dispatch={dispatch}
                          page={page}
                          section={section}
                          postId={postId}
                          key={user.USER_ID}
                          user={user}
                          requestConnection={() => sendConnectionRequest(user)}
                          acceptConnection={() => acceptConnectionRequest(user)}
                          rejectConnection={() => rejectConnectionRequest(user)}
                          isUserDeleted={deletedUsers.includes(user.USER_ID)}
                          {...props}
                        />
                      );
                    })}
                </div>
              )}
            </TabContent>
          </Tabs>
        </ModalFormGroup>
      </ModalBody>
    </Modal>
  );
};

const User = (props) => {
  const { user, openMsgPopup, requestConnection, acceptConnection, close, isUserDeleted = false, dispatch = null, page = null, section = null, } = props //activeUser=null, postId=null  // rejectConnection :- add this to have realtime reject functionality
  const currentUserId = localStorage.getItem('userId')

  const sendtrackedData = async (type, element_id) => {
    /* data project - event trcking */
    if (localStorage.getItem('userId') && page) {
      const eventPayload = {
        user_id: localStorage.getItem('userId'),
        page: page,
        section: section,
        element_type: type,
        element_id: element_id,
        profile_id: user.USER_ID,
        is_user_profile: true,
        action_type: EVENT_ACTION.CLICK
      }
      await dispatch(trackEvent(eventPayload));
    }
    /* END : data project - event trcking */

    const { success } = await checkBlockStatus(user.USER_ID);
    if (success) {
      if (type.toString() === 'BUTTON') { //only for send message
        close()
        const userObj = { ...user, SYSTEM_USER_ID: user.USER_ID, CONNECTION_STATUS: user.CONNECTION }
        openMsgPopup(userObj)
      }
    } else {
      showPopupAlert(dispatch, POP_ALERT.OK, '', BLOCKUSER_MSG_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
    }
  };

  const getActionButton = () => {
    if (user.USER_ID !== currentUserId) {
      if (user.CONNECTION) {
        switch (user.CONNECTION.STATUS) {
          case CONNECTION_STATUS.PENDING:
            if (user.CONNECTION.REQUESTER === currentUserId) {
              return (
                <label disabled className="pending">
                  Pending Approval
                </label>
              );
            } else {
              return (
                <span>
                  <button
                    className="btn view-action-button"
                    onClick={acceptConnection}
                  >
                    Accept
                  </button>
                  {/* <button className="btn view-action-button" onClick={rejectConnection}>Reject</button> */}
                </span>
              );
            }
          case CONNECTION_STATUS.BLOCKED:
            return (
              <button
                className="btn view-action-button"
                onClick={requestConnection}
              >
                Connect
              </button>
            );
          default:
            return;
        }
      } else {
        return (
          <button
            className="btn view-action-button"
            onClick={requestConnection}
          >
            Connect
          </button>
        );
      }
    } else {
      return null;
    }
  };

  const getMessageButton = () => {
    if (user.USER_ID !== currentUserId) {
      return (
        <button
          className="btn view-action-button"
          onClick={() => sendtrackedData('BUTTON', POST.MSG)}
        >
          Message
        </button>
      );
    }
  };

  return user.STATUS === SYSTEM_USER_STATUS.ACTIVE && !isUserDeleted ? (
    <div className="data-row" key={user._id}>
      <Link
        to={(user.USER_ID === localStorage.getItem('userId')) ? `/user-profile-edit/${user.USER_ID}` : `/user-profile/${user.USER_ID}`}
        onClick={() => sendtrackedData('LINK', null)}
        target="_blank"
      >
        <AvatarProfile
          avatar={user.PHOTO}
          size={42}
          key={user._id}
          avatarSize={THUMBNAIL.SMALL}
        />
        <div className="info-wrapper">
          <div className="d-flex align-items-center">
            <p className="user-name">{`${user.FIRST_NAME} ${user.LAST_NAME}`}</p>
            <div className="badges d-flex align-items-center m-l5">
              {user.IS_PRO_MEMBER && (
                <div className="badge badge-primary m-r5">PRO</div>
              )}
              {user.IS_EXPERT_CONTRIBUTOR && (
                <div className="badge badge-primary m-r5 green">EXPERT</div>
              )}
            </div>
          </div>
          {user.HEADLINE && user.HEADLINE !== null && (
            <p className="headline">{user.HEADLINE}</p>
          )}
          {(!user.HEADLINE || user.HEADLINE == null) && user.JOB_TITLE && (
            <p className="user-job-title">{`${user.JOB_TITLE}, ${user.COMPANY_NAME}`}</p>
          )}
        </div>
      </Link>
      <div className="buttons">
        {getMessageButton()}
        {getActionButton()}
      </div>
    </div>
  ) : (
    <div className="data-row disabled-row" key={user._id}>
      <div className="userRow">
        <AvatarProfile
          avatar={user.PHOTO}
          size={42}
          key={user._id}
          avatarSize={THUMBNAIL.SMALL}
        />
        <div className="info-wrapper">
          <p className="user-name">{`${user.FIRST_NAME} ${user.LAST_NAME}`}</p>
          {user.JOB_TITLE && (
            <p className="user-job-title">{`${user.JOB_TITLE}, ${user.COMPANY_NAME}`}</p>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    acceptedUID: state.connection.connectionAcceptedUserId || null,
    rejectedUID: state.connection.connectionRejectedUserId || null,
    requestedByUID: state.auth.connectionRequestSentBy || null,
    accepedByUID: state.connection.connectionAcceptedByUserId || null,
    rejectedByUID: state.connection.connectionRejectedByUserId || null,
  };
};

export default connect(mapStateToProps)(ViewUsersModal);
