import React from 'react'

const LandingCover = ({children, height}) =>{
 
      let coverSize = {height : height + 'px'}
      
   return(
    <div className="landing_cover" style={coverSize}>
         {children}
    </div>
   )
   
}
LandingCover.defaultProps = {
    height: '0px' ,
  };
export { LandingCover }