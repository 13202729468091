import React, { Component } from "react";
import "./index.css";
//import classNames from "classnames";
import { rawMarkup } from "./../../../../libs/textFormat"

class BottomCollapseCard extends Component {
  state = { showAll: this.props.limit ? false : true };
  showMore = () => this.setState({ showAll: true });
  showLess = () => this.setState({ showAll: false });

  render() {

    const { content, limit } = this.props;
    const { showAll } = this.state;
    if (content.length <= limit) {
      return { content };
    }
    if (showAll) {
      return (
        <div>
          <p dangerouslySetInnerHTML={rawMarkup(content.replace(/(?:\r\n|\r|\n)/g, '<br/>'))} style={{wordBreak:'break-word'}}/>
          {limit > 1 && <button className="read-button read-less-button" onClick={this.showLess}>
            Read less
          </button>}
        </div>
      );
    }

    const toShow = content.substring(0, limit ? limit : undefined) + (limit ? "..." : '');

    return (
      <div className="bottom_collapse_card">
        <p dangerouslySetInnerHTML={rawMarkup(toShow.replace(/(?:\r\n|\r|\n)/g, '<br/>'))} style={{wordBreak:'break-word'}}/>
        <div className="bottom_collapse_card_fadeout" />
        {limit > 1 && <button className="read-button read-more-button" onClick={this.showMore}>
          Read More
        </button>}
      </div>
    );
  }
}

export { BottomCollapseCard };
