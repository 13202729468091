import React from 'react'
import classNames from 'classnames'
import './style.css'
import { Component } from 'react';
import { ALERT } from '../../../../types/common';

class NotifyAlert extends Component {

  constructor(props) {
    super(props);
    this.state = {
      opacity: 0,
      visibility: "visible",
      marginTop: "-70px",
      message: "No message stored to be shown",
      type: null,
      duration: 2000,
      closed: false,
      autoTimeout: null
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show) {
      if(nextProps.text !== this.state.message && this.state.autoTimeout){
        clearTimeout(this.state.autoTimeout);
      }
      let autoTimeout = setTimeout(() => {
        this.setState({ opacity: 0, marginTop: "-70px", visibility: "hidden", closed: true });
        if(nextProps.doNotHide){
          this.props.dispatch({ type: ALERT.HIDE })
        }
      }, nextProps.duration);
      this.setState({ opacity: 1, type: nextProps.type, message: nextProps.text, marginTop: "0px", visibility: "visible", closed: false, autoTimeout });
    }
  }

  hideAlert = () => {
    if(this.state.autoTimeout){
      clearTimeout(this.state.autoTimeout);
    }
    this.setState({
      opacity: 0,
      marginTop: "-70px",
      visibility: "hidden",
      closed: true,
      autoTimeout: null
    });
    this.props.dispatch({ type: ALERT.HIDE })
  }

  showAlert = (type, message) => {
    this.setState({ opacity: 1, type: type, message: message, marginTop: "0px", visibility: "visible" });
    setTimeout(() => {
      this.setState({ opacity: 0, marginTop: "-70px", visibility: "hidden" });
    }, this.state.duration);
  }

  render() {
    const styles = { transition: 'margin 0.2s ease-out', 'zIndex': '1' };
    const alertClasses = classNames('alert-top justify-content-between d-flex align-items-center',
      { 'alert--message-info': this.state.type === ALERT.INFO ? true : false },
      { 'alert--message-sucess': this.state.type === ALERT.SUCCESS ? true : false },
      { 'alert--message-warning': this.state.type === ALERT.WARNING ? true : false },
      { 'alert--message-danger': this.state.type === ALERT.ERROR ? true : false });

    return (
        <div
            className="alert_body"
            style={(this.props.show && !this.state.closed && this.state.type !== ALERT.UNAVAILABLE) ? { height: '70px' } : { height: '0px' }}
        >
          {this.state.type === ALERT.UNAVAILABLE && <div
              className="popup-notify-alert d-flex justify-content-center align-items-center"
              style={{ ...styles, marginTop: this.state.marginTop, opacity: this.state.opacity, visibility: this.state.visibility }}
          >
            <i className="fa fa-exclamation-triangle m-r10 font_13"/>
            <div className="notify-message">
              The service is unavailable due to an unknown error.  Please try again in a while
            </div>
            <i className="fa fa-times m-l15" onClick={this.hideAlert}/>
          </div>}
          {this.state.type !== ALERT.UNAVAILABLE && <div className={alertClasses} role="alert" style={{ ...styles, marginTop: this.state.marginTop, opacity: this.state.opacity, visibility: this.state.visibility }}>
            <div />
            <p className="alert-message">{typeof this.state.message === 'string' ? this.state.message : 'Unexpected Error Occurred'}</p>
            <button type="button" className="btn-close" onClick={this.hideAlert}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>}
        </div>
    );
  }
}


export { NotifyAlert };