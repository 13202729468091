import validator from 'validator';
import React from 'react';
import moment from 'moment';
import { DOMAINS, ALERT_BANNER_MESSAGE, TESTIMONIAL_URLS } from './../types/common';
import { BROWSER_SUPPORT, SIGNED_COOKIES_CF_URL } from '../config';
import { imageExists } from './image';
import avatar from "./../assets/img/default-male.jpg";
import { concatDateTime } from './textFormat';
import { BUCKET, getCFObjURL } from './cfHelper';

const styles = {
    'color': '#F44336',
    'opacity': '0.6',
    'top': '-18px',
    'marginLeft': '16px'
};
const nextStyles = {
    'color': 'rgb(244, 67, 54)',
    'opacity': '0.6',
    'top': '76%',
    'position': 'absolute',
    'marginLeft': '1%'
};

export const required = (value, props) => {
    if (!value.toString().trim().length) {
        return <span style={styles}>Please enter {(props.placeholder && props.placeholder.split("*")) ? props.placeholder.split("*")[0] : props.placeholder}</span>;
    }
};

export const isChecked = (value, props) => {
    if (!value || (props.isCheckable && !props.checked)) {
        return <span style={nextStyles}>Please agree to the terms and conditions </span>;
    }
};

export const email = (value) => {
    if (!validator.isEmail(value)) {
        return <span style={styles}>{value} is not a valid email.</span>
    }
};

export const noSpOnEmail = (email) => {
    let username = email.substr(0, email.indexOf("@"));
    if (username !== -1) {
        if (isValidInput(username)) {
            return <span style={styles}>Special characters are not allowed.</span>
        }
    }
};

export const noSpecialChars = (value) => {
    if (isValidInput(value)) {
        return <span style={styles}>Special characters are not allowed.</span>
    }
};

export const noOnlySpecialChars = (value) => {
    let desired = value.replace(/[^\w\s]/gi, '');
    if (desired === '') {
        return <span id="error-msg">Only Special characters are not allowed.</span>
    }
};

export const noSpecialCharsSpaceSymbols = (value) => {
    // eslint-disable-next-line
    let format = /^[0-9a-zA-Z\_]+$/;

    if (!format.test(value)) {
        return <span id="error-msg">Please do not use spaces, symbols or special characters.</span>;
    } else {
        return false;
    }
};

export const notNumber = (value) => {
    if (isNumber(value)) {
        return <span style={styles}>{value} is not a valid input.</span>
    }
};

export const isPassword = (value) => {
    if (value.toString().length < 6) {
        return <span style={styles}>password should contain at least 6 characters</span>
    }
};

export function isrequired(string) {
    if (!string.toString().trim().length) {
        return false;
    } else {
        return true;
    }
}

export function isInRange(string, max, min) {
    if (min > (string.toString().trim().length) || max < (string.toString().trim().length)) {
        return false;
    } else {
        return true;
    }
}

export function isValidDate(date) {
    return moment(date).isValid();
}

export function isValidInput(string) {
    let format = /[!@#$%^&*()_+\-=\]{};':"\\|,<>?]+/;

    if (format.test(string)) {
        return true;
    } else {
        return false;
    }
}

export function isValidDuration(from, to) {

    if (isValidDate(from) && isValidDate(to)) {
        let diff = moment.duration(to.diff(from)).as('milliseconds');
        return (diff > 0);
    } else {
        return false;
    }
}

export function isNumber(string) {
    if (validator.isNumeric(string)) {
        return true
    } else {
        return false
    }
}

export function isMobile(number) {
    return validator.isMobilePhone(number, 'any') && 10 < number.length && number.length < 13
}

export function isMobileUS(number) {
    return validator.isMobilePhone(number, 'any') && 9 < number.length && number.length < 11
}

export function isURL(url, options, domain = '') {
    if (url && domain !== '' && !url.code) {
        if ((domain === DOMAINS.TWITTER || DOMAINS.FACEBOOK || DOMAINS.LINKEDIN || DOMAINS.INSTAGRAM || SIGNED_COOKIES_CF_URL) && url.includes(domain) && url.includes('http://')) {
            return true
        } else if (url.includes(domain)) {
            return validator.isURL(url, options);
        } else {
            return false
        }
    } else {
        if (url) {
            return validator.isURL(!url.code ? url : '', options);
        } else {
            return false
        }
    }
}

export function isValidUrl(str)
{
    let regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    try{
        return regexp.test(str)
    } catch (ex) {
        return false;
    }

}

export function isNewsFeedPostUrl(url) {
    return url.includes('leafwire.com/post/') || url.includes('leafwire.com/?postId=') || url.includes('leafwire.com/groups/post/') || url.includes('leafwire.com/groups/');
}

export function isTestimonialUrl(url) {
    return url.includes(TESTIMONIAL_URLS.GV_ONE) || url.includes(TESTIMONIAL_URLS.YOU_TUBE) || url.includes(TESTIMONIAL_URLS.S3);
}

export function isFullName(string) {
    if (!validator.isEmpty(string) && (/\s/.test(string)) && !(string.indexOf(' ') + 1 === string.length)) {
        return true;
    } else {
        return false;
    }
}

export function checkUserProfileIncomplete(user) {
    try {

        let message = '';
        if (!user.PHOTO) {
            message = message + 'upload a picture';
        }

        if (!user.COMPANY_ID) {
            message = message !== '' ? message + ', add your company name' : 'add your company name';
        }

        if (!user.CITY && !user.STATE && !user.COUNTRY) {
            message = message !== '' ? message + ' and add your location' : 'add your location';
        }

        if (message !== '' && message) {
            return ALERT_BANNER_MESSAGE.PROFILE_INCOMPLETE + message + ".";
        }

        return false;

    } catch (ex) {
        console.log(ex);
        return false
    }
}

export const isMail = (value) => {
    return validator.isEmail(value)
};

export const isValidPassword = (value) => {
    return new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})").test(value.toString())
};

export function noSpCOnEmail(email) {
    let username = email.substr(0, email.indexOf("@"));
    return (username !== -1 && !isValidInput(username))
}

export function isValidPhoneNumber(phone) {
    return validator.isNumeric(phone + '') && phone.length === 10
}

export function noOnlySpecialChar(value = '') {
    let desired = value.replace(/[^\w\s]/gi, '');
    return desired !== '';
}

export function isOnlySpecialCharsAndNumbers(value) {
    let withoutSP = value.replace(/[^\w\s]/gi, '');
    let withoutNum = withoutSP.replace(/[0-9]/g, '');
    return withoutNum === ''
}

export function isOnlySpecialCharsAndNumbersCaps(value) {
    let withoutSP = value.replace(/[^\w\s]/gi, '');
    let withoutNum = withoutSP.replace(/[0-9]/g, '');
    if (withoutNum === '') {
        return true
    } else if (withoutNum === withoutNum.toUpperCase() && value !== value.replace(/[0-9]/g, '')) {
        return true
    } else {
        return false
    }
}

export const isVerified = (value) => {
    return <span hidden={!value} style={styles}>Please verify that you are not a robot</span>;
};

export const isValidImageURL = (url, type = 1, isProfilePic = false) => {
    const isThumbnail = type === 1;
    return new Promise((resolve, reject) => {
        const img = getCFObjURL(BUCKET.COMMON)(url, isThumbnail);
        if (img) {
            imageExists(img, (exists) => {
                if (exists) {
                    resolve(img)
                } else {
                    reject(avatar)
                }
            }, isProfilePic);
        } else {
            reject(avatar)
        }
    });
}

const browserWarning = (version, download) => {
    return 'Your current browser version (' + version + ') may not support some of the features in this application. please update to the <a href=' + download + ' target="_blank" style="text-decoration: none !important">latest version</a>';
}

export async function isBrowserNotCompatible({ browser, version }) {

    let { CHROME, FIREFOX, SAFARI, EDGE, OPERA, INTERNET_EXPLORA } = BROWSER_SUPPORT;
    if (browser === CHROME.NAME && version < CHROME.VERSION) {
        return {
            status: false,
            message: browserWarning(version, 'https://www.google.com/chrome'),
        }
    }

    if (browser === FIREFOX.NAME && version < FIREFOX.VERSION) {
        return {
            status: false,
            message: browserWarning(version, 'https://support.mozilla.org/en-US/kb/update-firefox-latest-version'),
        }
    }

    if (browser === SAFARI.NAME && version < SAFARI.VERSION) {
        return {
            status: false,
            message: browserWarning(version, 'https://support.apple.com/en-us/HT204416'),
        }
    }

    if (browser === EDGE.NAME && version < EDGE.VERSION) {
        return {
            status: false,
            message: browserWarning(version, 'https://www.microsoft.com/en-us/windows/microsoft-edge')
        }
    }

    if (browser === OPERA.NAME && version < OPERA.VERSION) {
        return {
            status: false,
            message: browserWarning(version, 'https://www.opera.com/download'),
        }
    }

    if (browser === INTERNET_EXPLORA.NAME && version < INTERNET_EXPLORA.VERSION) {
        return {
            status: false,
            message: browserWarning(version, 'https://www.microsoft.com/en-us/download/internet-explorer.aspx')
        }
    }

    return {
        status: true,
        message: null
    }
}

export const requiredInput = (value, property) => {
    if (!value || !value.toString().trim().length) {
        return <span id="error-msg">Please enter a valid {property}</span>;
    }
    return false;
};

export const requiredDropdown = (value, property) => {
    if (!value || !value.toString().trim().length) {
        return <span id="error-msg">Please select a valid {property}</span>;
    }
    return false;
};

export const s_is_valid_ex = (value, property) => {
    if (!value.toString().trim().length) {
        return <span id="error-msg">Please select a valid {property}</span>;
    }
    return false;
};

export const s_is_valid_date = (value) => {
    if(!isValidDate(value)){
        return <span id="error-msg">Please enter valid date</span>;
    }
    return false;
};

export const s_is_valid_roles = (roles) => {
    if(!(roles && roles.length > 0)){
        return <span id="error-msg">Please select industry roles</span>;
    }
    return false;
};

export const exceededLimit = () => {
    return <span id="error-msg">Featured event package has been exceeded</span>;
};

export const requiredSelection = () => {
    return <span id="error-msg">Please choose whether or not you have a special offer for Leafwire Members.</span>;
};

export const requiredInvestorTypeSelection = () => {
    return <span id="error-msg">Please select investor type</span>;
};

export const requiredInvestorOptionSelection = () => {
    return <span id="error-msg">Please select at least one investor option</span>;
};

export const exceedMax = (value, property, max) => {
    if (value.toString().trim().length > max) {
        return <span id="error-msg">Please enter a {property} less than {max} characters</span>;
    }
    return false;
};

export const inputInRange = (value, property, min ,max) => {
    if (value.toString().trim().length > max || value.toString().trim().length < min) {
        return <span id="error-msg">Please enter a {property} between {min} to {max} characters</span>;
    }
    return false;
};

export const inputNotOnlySpecialCharsAndNumbers = (value) => {
    if (isOnlySpecialCharsAndNumbers(value)) {
        return <span id="error-msg">Only Special characters and Numbers are not allowed.</span>;
    }
    return false;
};

export const isPhoneNumber = (value, property) => {

    if (!value.toString().trim().length) {
        return <span id="error-msg">Please enter a valid {property}</span>;
    } else if (!validator.isNumeric(value.toString().trim())) {
        return <span id="error-msg">{property} should be a numeric value</span>;
    } else if (value.toString().trim().length < 10 || value.toString().trim().length > 25) {
        return <span id="error-msg">Please enter a valid {property}</span>;
    }
    return false;
};

export const isZipCode = (value, property) => {
    if (!value.toString().trim().length || value.toString().trim().length > 10) {
        return <span id="error-msg">Please enter a valid {property}</span>;
    }
    return false;
};

export const isValidCity = (selectedCity, enteredCity, state, country, property) => {
    if(state.length === 0 && country.length === 0 && enteredCity.length > 0  && selectedCity.length === 0){
        return <span id="error-msg">Please select a valid {property}</span>;
    }
    return false;
};

export const isValidPriority = (value = '') => {
    if (!value) {
        return false;
    } else {
        if (isNumber(value) && value > 0) {
            return false;
        } else {
            return <span id="error-msg">Invalid Priority Number</span>;
        }
    }
};

export const isValidSocialUrl = (value, property, options, domain = '') => {
    if (!value || value === '') {
        return false;
    } else if (value && domain !== '' && !value.code) {
        if ((domain === DOMAINS.TWITTER || DOMAINS.FACEBOOK || DOMAINS.LINKEDIN || DOMAINS.INSTAGRAM || SIGNED_COOKIES_CF_URL) && value.includes(domain) && value.includes('http://')) {
            return false
        } else if (value.includes(domain)) {
            if (validator.isURL(value, options)) {
                return false;
            } else {
                return <span id="error-msg">Please provide a valid {property} URL</span>;
            }
        } else {
            return <span id="error-msg">Please provide a valid {property} URL</span>;
        }
    } else {
        if (value) {
            if (validator.isURL(!value.code ? value : '', options)) {
                return false;
            } else {
                return <span id="error-msg">Please provide a valid {property} URL</span>;
            }
        } else {
            return <span id="error-msg">Please provide a valid {property} URL</span>;
        }
    }
}

export const requiredCheckbox = (value, property) => {
    if (!value || (Array.isArray(value) && value.length === 0)) {
        return <span id="error-msg">Please select at least one {property}</span>;
    }
    return false;
};

export const validInput = (value, property) => {
    if (isOnlySpecialCharsAndNumbers(value)) {
        return <span id="error-msg">Invalid {property}</span>;
    }
};

export const arrayDifference = (a, b) => {
    const s = new Set(b);
    return a ? a.filter(x => !s.has(x)) : [];
};

export const s_name = (value, lable) => {
    if (value === '') {
        return <span id="error-msg">{`Please enter ${lable}`}</span>;
    } else if (isNumber(value) || isOnlySpecialCharsAndNumbers(value)) {
        return <span id="error-msg">{`${value} is not a valid input.`}</span>;
    } else {
        return false;
    }
};

export const s_email = (value) => {
    if (value === '') {
        return <span id="error-msg">Please enter Email Address</span>;
    } else if (!isMail(value)) {
        return <span id="error-msg">{`${value} is not a valid email.`}</span>;
    } else {
        return false
    }
};

export const s_password = (value) => {
    if (value === '') {
        return <span id="error-msg">Please enter Password</span>;
    } else if (!isValidPassword(value)) {
        return <span id="error-msg">Password must be a strong password</span>;
    } else {
        return false
    }
};

export const s_industryInterests = (value) => {
    if (value === '0') {
        return <span id="error-msg">Please select what part of the industry interests you</span>;
    } else {
        return false
    }
};

export const s_requiredSelection = (value) => {
    if (value === '') {
        return <span id="error-msg">{`Please select an option from the dropdown menu.`}</span>;
    } else {
        return false
    }
};

export const s_isMobileUS = (number) => {
    if(validator.isMobilePhone(number, 'any') && 9 < number.length && number.length < 20){
        return false
    } else if(validator.isMobilePhone(number, 'any') && 10 > number.length){
        return <span id="error-msg">Please enter a valid phone number.</span>;
    }  else if(validator.isMobilePhone(number, 'any') && 20 < number.length){
        return <span id="error-msg">Please enter a valid phone number.</span>;
    } else {
        return <span id="error-msg">Please enter a valid phone number.</span>;
    }
};

export const s_isValidLinkedInUrl = (url) => {
    if(isURL(url, { protocols: ['https'] }, DOMAINS.LINKEDIN)){
        return false
    } else {
        return <span id="error-msg">Please provide a valid linkedIn URL</span>;
    }
}

export const s_policyRef = (value) => {
    if (!value) {
        return <span id="error-msg">Please agree to the terms and conditions</span>;
    } else {
        return false
    }
};

export const s_ageRef = (value) => {
    if (!value) {
        return <span id="error-msg">Please Confirm your age</span>;
    } else {
        return false
    }
};

export const validateJobPositionsAndLevel = (postions, level) => {
    try {
        let isAllowed = true;
        if (postions.length > 0 && isrequired(level)) {
            postions.some((item, i) => {
                if (!isrequired(item.NAME)) {
                    isAllowed = false;
                    return true;
                } else {
                    isAllowed = true;
                    return false;
                }
            });
        } else {
            isAllowed = false;
        }
        return isAllowed;
    } catch (ex) {
        return false;
    }
};

export const validDateRange = (property, startDate, endDate, customError='') => {

    let mStartTime = moment(startDate).utcOffset(0);

    let mEndTime = moment(endDate).utcOffset(0);

    let duration = moment.duration(mStartTime.diff(mEndTime));
    let seconds = duration.asSeconds();
    if(seconds > 0){
        return <span id="error-msg">{customError ? customError : `Please enter a valid ${property}`}</span>;
    }
    return false;
};

export const validTimeRange = (property, startTime, endTime, customError='') => {

    let mStartTime = new Date().toISOString().split('T').shift();
    mStartTime = moment(mStartTime + ' ' + startTime).utcOffset(0);

    let mEndTime = new Date().toISOString().split('T').shift();
    mEndTime = moment(mEndTime + ' ' + endTime).utcOffset(0);

    let duration = moment.duration(mStartTime.diff(mEndTime));
    let seconds = duration.asSeconds();
    if(seconds >= 0){
        return <span id="error-msg">{customError ? customError : `Please enter a valid ${property}`}</span>;
    }
    return false;
};

export const validStartTime = (property, startDate ,startTime, offset) => {
    offset = offset.replace(":", ".");
    let hours = parseInt(parseFloat(offset));
    let min  = parseFloat(offset);
    min = ((min-hours).toFixed(2)*100);
    let start = moment(concatDateTime(startDate, startTime)).utc(0);
    let current = moment().utc(0).add(hours, 'hours').add(min,'minutes');
    let duration = moment.duration(start.diff(current));
    if(0 > duration.asSeconds()){
        return <span id="error-msg">{property} cannot be in past</span>;
    }
    return false;
};

export const endDateValidation = (endDate) => {
    if(moment(endDate).format("YYYY-MM-DD")<= moment(new Date()).format("YYYY-MM-DD")){
    return <span id="error-msg">End date should be grater than today</span>;
    }else{
        return false;
    }
};

export const bannerAdDateValidation = (startDate, endDate) => {
    if(moment(startDate).format("YYYY-MM-DD")=== moment(endDate).format("YYYY-MM-DD")){
    return <span id="error-msg">Start date and end date cannot be same day</span>;
    }else{
        return false;
    }
};

export const advertiser_email = (value) => {
    if (value === '') {
        return <span id="error-msg">Please enter Email Address</span>;
    } else if (!isMail(value)) {
        return <span id="error-msg">{`${value} is not a valid email.`}</span>;
    } else {
        return false
    }
};

export const isValidNumber = (value = '') => {
    if (!value) {
        return false;
    } else {
        if (isNumber(value)) {
            return false;
        } else {
            return <span id="error-msg">Invalid input</span>;
        }
    }
};
