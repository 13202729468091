module.exports = {
    NETWORKER: {
        TYPE: 3,
        MESSAGE: "Welcome to Leafwire! Research shows, complete profile makes it 600% more likely other professionals will reach out to you with job offers, business deals and connection requests. Get sharted now!"
    },
    COMPANY: {
        TYPE: 2,
        MESSAGE: "We are glad you are here. Make sure to complete your individual and company profile and start connecting with the network. The more complete your profile is, the more likely people will connect with you."
    },
    INVESTOR: {
        TYPE: 1,
        MESSAGE: "Welcome to Leafwire. Your investor profile is used to focus the types of companies that we show you. The more detailed the profile is, the more relevant opportunities you will see. Take the time to fill out your investor profile so that we can help you find the right companies for your portfolio. And Thank you for joining the Leafwire community."
    },
    RESET_PASSWORD: {
        TOKEN_VALIDATING: {
            status: 1,
            message: 'We are validating you! please wait.'
        },
        TOKEN_VALIDATION_SUCCESS: {
            status: 2,
            message: 'Password reset token has been successfully sent to your email.'
        },
        TOKEN_VALIDATION_ERROR: {
            status: 3,
            message: 'An error occured while validating token, please try again later.'
        },
        SUCCESS: {
            status: 1,
            message: 'Your password has been successfully updated.'
        }
    },
    RESET_PASSWORD_TOKEN: {
        EMAIL_VALIDATING: {
            status: 1,
            message: 'We are validating you! please wait'
        },
        EMAIL_VALIDATION_SUCCESS: {
            status: 2,
            message: 'We have sent a notification to the email address you entered with instructions on how to reset your password.'
        },
        EMAIL_VALIDATION_FAIL: {
            status: 3,
            message: "We were unable to find a Leafwire account with this email address. Please enter an alternate email address, if you'd like us to search for another email address."
        },
        EMAIL_VALIDATION_FAIL_ERROR: {
            status: 3,
            message: "Whoops! That's embarrassing. It looks like we're having a technical problem. Please give it another try in a few minutes."
        }
    }
};