import React from "react";
import "./index.css";

export const ToggleButton = ({ isChecked, onChange, isChecking, newId, title = "" }) => {
    return (
        <label className="switch" htmlFor={newId ? newId : "widgetCheckBox"} { ...(title && { 'data-title' : `${title}` } ) }>
            <input 
                type="checkbox" 
                id={newId ? newId : "widgetCheckBox"}
                checked={isChecked}
                onChange={onChange}
                disabled={isChecking}
            />
            <div className="slider round"></div>
        </label>
    );
}