module.exports = {

    FETCHING_COMMUNICATION_PREFERENCES: "FETCHING_COMMUNICATION_PREFERENCES",
    FETCHING_COMMUNICATION_PREFERENCES_SUCCESS: "FETCHING_COMMUNICATION_PREFERENCES_SUCCESS",
    FETCHING_COMMUNICATION_PREFERENCES_FAIL: "FETCHING_COMMUNICATION_PREFERENCES_FAIL",

    SETTING_COMMUNICATION_PREFERENCES: "SETTING_COMMUNICATION_PREFERENCES",
    SETTING_COMMUNICATION_PREFERENCES_SUCCESS: "SETTING_COMMUNICATION_PREFERENCES_SUCCESS",
    SETTING_COMMUNICATION_PREFERENCES_FAIL: "SETTING_COMMUNICATION_PREFERENCES_FAIL",

    CLEAR_ACTION: "CLEAR_ACTION"
    
}