import NewsTypes from '../types/newsFeed';
import { CONNECT_VIA, VIDEO_UPLOAD_STATUS } from "../types/common";
import { commentHandleLikeUnlike } from '../libs/commonFunctions';

export const initialState = {
    fetching: false,
    action: 0,
    profileDetails: {},
    newsFeedData: [],
    userFeedFetchFailed: false,
    singlePost: [],
    scrapedData: {},
    likedItems: [],
    unlikedItems: [],
    recentlyReacted: [],
    newsFeedCount: 0,
    suggestions: [],
    deleteActionFailedPost: null,
    upcomingEvents: [],
    fetchingFundraisingCompanies: false,
    fundraisingCompanies: [],
    fundraisingCompanyCount: 0,
    fetchingConnectionSuggestions: false,
    isPostLikedUsersFetching: false,
    postLikedUsers: [],
    postLikedUserCount: 0,
    performingIndex: null,
    commenterDetails: null,
    fetchingCommenterDetails: false,
    textLinks: [],
    fetchingTextLinks: false,
    textLinksCount: 0,
    tagSuggestion: [],
    trendingPost: {},
    trendingTopicWidgetTitle: '',
    industryEventWidgetTitle: '',
    addedPost: null,
    deletedPost: null,
    trendingGroupPost: {},
    likesForComments: [],
    newsfeedSinglePost: null,
    uploadingVideos: {},
    completedVideo: null,
    pendingPublish: [],
    lastFetchedPostIds: [],
    openNewsfeedAdCount: 0,
    newsfeedSkip: 0
};

export const newsFeed = (state = initialState, action) => {
    switch (action.type) {
        case NewsTypes.FETCHING_PROFILE_DATA:
            return {
                ...state,
                isProfileDataFetching: true,
                action: NewsTypes.FETCHING_PROFILE_DATA
            };
        case NewsTypes.FETCH_PROFILE_DATA_SUCCESS:
            return {
                ...state,
                isProfileDataFetching: false,
                action: NewsTypes.FETCH_PROFILE_DATA_SUCCESS,
                profileDetails: action.payload.data
            };
        case NewsTypes.FETCH_PROFILE_DATA_FAIL:
            return {
                ...state,
                isProfileDataFetching: false,
                action: NewsTypes.FETCH_PROFILE_DATA_FAIL
            };

        case NewsTypes.ADD_POST_SUCCESS:
            return {
                ...state,
                addedPost: action.payload ? action.payload : null,
                newsfeedSinglePost: null,
                trendingGroupPost: null,
                action: NewsTypes.ADD_POST_SUCCESS
            };
        case NewsTypes.ADD_POST_FAIL:
            return {
                ...state,
                action: NewsTypes.ADD_POST_FAIL
            };
        case NewsTypes.ADD_POST_EXCEPTION:
            return {
                ...state,
                action: NewsTypes.ADD_POST_EXCEPTION
            };

        case NewsTypes.EDIT_POST_SUCCESS: {
            let posts = state.newsFeedData.map(post => {
                if (post._id === action.postId) {
                    post.POST_DESCRIPTION = action.postDescription;
                    post.RICH_TEXT = action.richText;
                    post.POST_MEDIA = action.isPostVideoIncluded ? [] : action.postMediaType;
                    post.POST_VIDEO = action.postVideoFile;
                }
                return post;
            });
            let singlePostUpdated = state.singlePost.map(post => {
                if (post._id === action.postId) {
                    post.POST_DESCRIPTION = action.postDescription;
                    post.RICH_TEXT = action.richText;
                    post.POST_MEDIA = action.isPostVideoIncluded ? [] : action.postMediaType;
                    post.POST_VIDEO = action.postVideoFile;
                }
                return post;
            });
            let newsfeedSinglePostUpdated = (state.newsfeedSinglePost && state.newsfeedSinglePost._id === action.postId) ? {
                ...state.newsfeedSinglePost,
                POST_DESCRIPTION: action.postDescription,
                RICH_TEXT: action.richText,
                POST_MEDIA: action.isPostVideoIncluded ? [] : action.postMediaType,
                POST_VIDEO: action.postVideoFile
            }: null
            let trendingPostUpdated = (state.trendingPost && state.trendingPost._id === action.postId) ? {
                ...state.trendingPost,
                POST_DESCRIPTION: action.postDescription,
                RICH_TEXT: action.richText,
                POST_MEDIA: action.isPostVideoIncluded ? [] : action.postMediaType,
                POST_VIDEO: action.postVideoFile
            }: null
            return {
                ...state,
                fetching: false,
                newsFeedData: posts,
                singlePost: singlePostUpdated,
                newsfeedSinglePost: newsfeedSinglePostUpdated,
                trendingPost: trendingPostUpdated,
                action: NewsTypes.COMMENT_POST_SUCCESS
            };
        }

        case NewsTypes.FETCHING_NEWS_FEEDS:
            return {
                ...state,
                action: NewsTypes.FETCHING_NEWS_FEEDS
            };
        case NewsTypes.FETCH_NEWS_FEED_SUCCESS:
            return {
                ...state,
                action: NewsTypes.FETCH_NEWS_FEED_SUCCESS,
                lastFetchedPostIds: action.payload.data.map(post => { return post._id}),
                newsFeedData: action.isInit ? action.payload.data : state.newsFeedData.concat(action.payload.data.filter(post => { return !state.lastFetchedPostIds.includes(post._id)})),
                openNewsfeedAdCount: action.isAdvertisementIncluded ? ++state.openNewsfeedAdCount : state.openNewsfeedAdCount,
                newsfeedSkip: action.skip
            };
        case NewsTypes.FETCH_NEWS_FEED_FAIL:
            return {
                ...state,
                action: NewsTypes.FETCH_PROFILE_DATA_FAIL
            };
        case NewsTypes.FETCH_NEWS_FEED_EXCEPTION:
            return {
                ...state,
                action: NewsTypes.FETCH_NEWS_FEED_EXCEPTION
            };
        case NewsTypes.FETCH_NEWS_FEED_INIT_ERROR:
            return {
                ...state,
                action: NewsTypes.FETCH_NEWS_FEED_INIT_ERROR
            };
        case NewsTypes.CLEAR_NEWS_FEEDS:
            return {
                ...state,
                action: NewsTypes.CLEAR_NEWS_FEEDS,
                newsFeedData: []
            };

        case NewsTypes.LIKE_POST_SUCCESS: {
            let recentlyReacted = state.recentlyReacted;
            recentlyReacted[action.payload] = action.reaction;
            return {
                ...state,
                action: NewsTypes.LIKE_POST_SUCCESS,
                likedItems: state.likedItems.concat(action.payload),
                unlikedItems: state.unlikedItems.filter(item => item !== action.payload),
                recentlyReacted: recentlyReacted
            }
        }
        case NewsTypes.UNLIKE_POST_SUCCESS: {
            let recentlyReacted = state.recentlyReacted;
            delete recentlyReacted[action.payload];
            return {
                ...state,
                action: NewsTypes.UNLIKE_POST_SUCCESS,
                likedItems: state.likedItems.filter(item => item !== action.payload),
                unlikedItems: state.unlikedItems.concat(action.payload),
                recentlyReacted: recentlyReacted
            }
        }
        case NewsTypes.FETCHING_NEWS_FEED_COUNT:
            return {
                ...state,
                action: NewsTypes.FETCHING_NEWS_FEED_COUNT
            };
        case NewsTypes.FETCH_NEWS_FEED_COUNT_SUCCESS:
            return {
                ...state,
                action: NewsTypes.FETCH_NEWS_FEED_COUNT_SUCCESS,
                newsFeedCount: action.payload.data[0].POST_COUNT
            };
        case NewsTypes.FETCH_NEWS_FEED_COUNT_FAIL:
            return {
                ...state,
                action: NewsTypes.FETCH_NEWS_FEED_COUNT_FAIL
            };
        case NewsTypes.FETCH_NEWS_FEED_COUNT_EXCEPTION:
            return {
                ...state,
                action: NewsTypes.FETCH_NEWS_FEED_COUNT_EXCEPTION
            };
        case NewsTypes.CLEAR_NEWS_FEED_COUNT:
            return {
                ...state,
                action: NewsTypes.CLEAR_NEWS_FEED_COUNT,
                newsFeedCount: 0
            };

        case NewsTypes.POST_ARCHIVE_SUCCESS:
            let filteredSinglePost = state.newsfeedSinglePost && state.newsfeedSinglePost._id && state.newsfeedSinglePost._id !== action.payload ? state.newsfeedSinglePost : null
            return {
                ...state,
                // newsFeedData: [],
                deletedPost: action.payload ?? null,
                newsfeedSinglePost: filteredSinglePost,
                action: NewsTypes.POST_ARCHIVE_SUCCESS
            };
        case NewsTypes.POST_REMOVE:
            const updatedNewsfeed = state.newsFeedData.filter(p => p._id !== action.payload)
            return {
                ...state,
                newsFeedData: updatedNewsfeed,
                action: action.callAction ?? NewsTypes.POST_REMOVE
            }
        case NewsTypes.POST_ARCHIVE_FAIL:
            return {
                ...state,
                action: NewsTypes.POST_ARCHIVE_FAIL
            };

        case NewsTypes.FETCHING_POST:
            return {
                ...state,
                action: NewsTypes.FETCHING_POST
            };
        case NewsTypes.FETCH_POST_SUCCESS:
            return {
                ...state,
                action: NewsTypes.FETCH_POST_SUCCESS,
                singlePost: action.payload.data
            };
        case NewsTypes.FETCH_POST_FAIL:
            return {
                ...state,
                action: NewsTypes.FETCH_POST_FAIL,
                singlePost: []
            };
        case NewsTypes.FETCH_POST_EXCEPTION:
            return {
                ...state,
                action: NewsTypes.FETCH_POST_EXCEPTION,
                singlePost: []
            };
        case NewsTypes.CLEAR_SINGLE_POST:
            return {
                ...state,
                action: NewsTypes.CLEAR_SINGLE_POST,
                singlePost: []
            };
        case NewsTypes.FETCHING_SUGGESTIONS:
            return {
                ...state,
                action: NewsTypes.FETCHING_SUGGESTIONS,
                fetchingConnectionSuggestions: true
            };
        case NewsTypes.FETCHING_SUGGESTIONS_SUCCESS:
            return {
                ...state,
                action: NewsTypes.FETCHING_SUGGESTIONS_SUCCESS,
                fetchingConnectionSuggestions: false,
                suggestions: action.payload,
            };
        case NewsTypes.FETCHING_SUGGESTIONS_FAIL:
            return {
                ...state,
                fetchingConnectionSuggestions: false,
                action: NewsTypes.FETCHING_SUGGESTIONS_FAIL,
            };
        case NewsTypes.CLEAR_SUGGESTIONS:
            return {
                ...state,
                fetchingConnectionSuggestions: false,
                action: NewsTypes.CLEAR_SUGGESTIONS,
                suggestions: []
            };

        case NewsTypes.ADDING_SUGGESTIONS:
            return {
                ...state,
                action: NewsTypes.ADDING_SUGGESTIONS,
            };
        case NewsTypes.ADD_SUGGESTION_SUCCESS:
            return {
                ...state,
                action: NewsTypes.ADD_SUGGESTION_SUCCESS,
                // suggestions: []
            };
        case NewsTypes.ADD_SUGGESTION_FAIL:
            return {
                ...state,
                action: NewsTypes.ADD_SUGGESTION_FAIL,
            };

        case NewsTypes.COMMENT_POST: {
            return {
                ...state,
                fetching: true,
                action: NewsTypes.COMMENT_POST
            };
        }
        case NewsTypes.COMMENT_POST_SUCCESS: {
            let posts = state.newsFeedData.map(post => {
                if (post._id === action.postId) {
                    post.POST_COMMENT_COUNT = action.isEdit ? post.POST_COMMENT_COUNT : post.POST_COMMENT_COUNT + 1;
                }
                return post;
            });
            let singlePostUpdated = state.singlePost.map(post => {
                if (post._id === action.postId) {
                    post.COMMENT_COUNT = action.isEdit ? post.POST_COMMENT_COUNT : post.COMMENT_COUNT + 1;
                }
                return post;
            });
            return {
                ...state,
                fetching: false,
                newsFeedData: posts,
                singlePost: singlePostUpdated,
                action: NewsTypes.COMMENT_POST_SUCCESS
            };
        }
        case NewsTypes.COMMENT_POST_FAIL: {
            return {
                ...state,
                fetching: false,
                action: NewsTypes.COMMENT_POST_FAIL
            };
        }

        case NewsTypes.DELETE_COMMENT_POST: {
            return {
                ...state,
                fetching: true,
                action: NewsTypes.DELETE_COMMENT_POST
            };
        }
        case NewsTypes.DELETE_COMMENT_POST_SUCCESS: {
            let posts = state.newsFeedData.map(post => {
                if (post._id === action.postId) {
                    post.POST_COMMENT_COUNT = post.POST_COMMENT_COUNT - 1;
                }
                return post;
            });
            return {
                ...state,
                fetching: false,
                newsFeedData: posts,
                action: NewsTypes.DELETE_COMMENT_POST_SUCCESS
            };
        }
        case NewsTypes.DELETE_COMMENT_POST_FAIL: {
            return {
                ...state,
                fetching: false,
                action: NewsTypes.DELETE_COMMENT_POST_FAIL,
                deleteActionFailedPost: action.payload
            };
        }

        case NewsTypes.GET_COMMENTS:
            return {
                ...state,
                action: NewsTypes.GET_COMMENTS
            };
        case NewsTypes.GET_COMMENTS_SUCCESS:
            let reversedArray = action.payload.reverse();
            let singlepost = state.singlePost;
            let feed = state.newsFeedData;
            let trendingPost = state.trendingPost;
            let trendingGroupPost = state.trendingGroupPost;
            
            let tmpNewsFeedPost = state.newsfeedSinglePost ? {...state.newsfeedSinglePost} : null ;

            if (state.singlePost.length > 0 && action.id === state.singlePost[0]._id) {
                singlepost[0].COMMENTS = reversedArray
            }

            feed.forEach((item, i) => {
                if (item._id === action.id) {
                    feed[i].POST_COMMENT = action.singlePost ? action.payload : reversedArray
                    feed[i].POST_COMMENT_COUNT = action.commentCount
                }
            });

            if (trendingPost && (trendingPost._id === action.id)) {
                trendingPost.POST_COMMENT_COUNT = action.commentCount;
                trendingPost.POST_COMMENT = reversedArray;
            }

            if (trendingGroupPost && (trendingGroupPost._id === action.id)) {
                trendingGroupPost.POST_COMMENT_COUNT = action.commentCount;
                trendingGroupPost.POST_COMMENT = reversedArray;
            }

            if (tmpNewsFeedPost && (tmpNewsFeedPost._id === action.id)) {
                tmpNewsFeedPost.POST_COMMENT_COUNT = action.commentCount
                tmpNewsFeedPost.POST_COMMENT = reversedArray;
            }

            return {
                ...state,
                action: NewsTypes.GET_COMMENTS_SUCCESS,
                singlePost: singlepost,
                newsFeedData: feed,
                trendingPost: trendingPost,
                trendingGroupPost: trendingGroupPost,
                newsfeedSinglePost: tmpNewsFeedPost,
            };
        case NewsTypes.GET_COMMENTS_FAIL:
            return {
                ...state,
                action: NewsTypes.GET_COMMENTS_FAIL
            };
        case NewsTypes.CLEAR_ACTION:
            return {
                ...state,
                action: 0,
                addedPost: null,
                deletedPost: null,
                deleteActionFailedPost: action.payload
            };

        case NewsTypes.FETCHING_UP_COMING_EVENTS:
            return {
                ...state,
                action: NewsTypes.FETCHING_UP_COMING_EVENTS,
            };
        case NewsTypes.FETCHING_UP_COMING_EVENTS_SUCCESS:
            return {
                ...state,
                action: NewsTypes.FETCHING_UP_COMING_EVENTS_SUCCESS,
                upcomingEvents: action.payload,
            };
        case NewsTypes.FETCHING_UP_COMING_EVENTS_FAIL:
            return {
                ...state,
                action: NewsTypes.FETCHING_UP_COMING_EVENTS_FAIL,
            };
        case NewsTypes.CLEAR_UP_COMING_EVENTS:
            return {
                ...state,
                action: NewsTypes.CLEAR_UP_COMING_EVENTS,
                upcomingEvents: []
            };

        case NewsTypes.FETCHING_FUNDRAISING_COMPANIES:
            return {
                ...state,
                action: NewsTypes.FETCHING_FUNDRAISING_COMPANIES,
                fetchingFundraisingCompanies: true
            };
        case NewsTypes.FETCH_FUNDRAISING_COMPANIES_SUCCESS:
            return {
                ...state,
                action: NewsTypes.FETCH_FUNDRAISING_COMPANIES_SUCCESS,
                fundraisingCompanies: action.payload,
                fetchingFundraisingCompanies: false
            };
        case NewsTypes.FETCH_FUNDRAISING_COMPANIES_FAIL:
            return {
                ...state,
                action: NewsTypes.FETCH_FUNDRAISING_COMPANIES_FAIL,
                fetchingFundraisingCompanies: false
            };
        case NewsTypes.CLEAR_FUNDRAISING_COMPANIES:
            return {
                ...state,
                action: NewsTypes.CLEAR_FUNDRAISING_COMPANIES,
                fundraisingCompanies: []
            };

        case NewsTypes.FETCHING_FUNDRAISING_COMPANY_COUNT:
            return {
                ...state,
                action: NewsTypes.FETCHING_FUNDRAISING_COMPANY_COUNT
            };
        case NewsTypes.FETCH_FUNDRAISING_COMPANY_COUNT_SUCCESS:
            return {
                ...state,
                action: NewsTypes.FETCH_FUNDRAISING_COMPANY_COUNT_SUCCESS,
                fundraisingCompanyCount: action.payload
            };
        case NewsTypes.FETCH_FUNDRAISING_COMPANY_COUNT_FAIL:
            return {
                ...state,
                action: NewsTypes.FETCH_FUNDRAISING_COMPANY_COUNT_FAIL
            };
        case NewsTypes.CLEAR_FUNDRAISING_COMPANY_COUNT:
            return {
                ...state,
                action: NewsTypes.CLEAR_FUNDRAISING_COMPANY_COUNT,
                fundraisingCompanyCount: 0
            };

        case NewsTypes.FETCHING_POST_LIKED_USERS:
            return {
                ...state,
                isPostLikedUsersFetching: true,
                action: NewsTypes.FETCHING_POST_LIKED_USERS
            };
        case NewsTypes.FETCH_POST_LIKED_USERS_SUCCESS:
            return {
                ...state,
                isPostLikedUsersFetching: false,
                action: NewsTypes.FETCH_POST_LIKED_USERS_SUCCESS,
                postLikedUsers: state.postLikedUsers.concat(action.payload.data[0].POST_LIKED_USER),
                postLikedUserCount: action.payload.data[0].LIKE_COUNT
            };
        case NewsTypes.FETCH_POST_LIKED_USERS_FAIL:
            return {
                ...state,
                isPostLikedUsersFetching: false,
                action: NewsTypes.FETCH_POST_LIKED_USERS_FAIL
            };
        case NewsTypes.CLEAR_POST_LIKED_USERS:
            return {
                ...state,
                action: NewsTypes.CLEAR_POST_LIKED_USERS,
                postLikedUsers: [],
                postLikedUserCount: 0
            };

        case NewsTypes.ACCEPTING_CONNECTION:
            return {
                ...state,
                action: NewsTypes.ACCEPTING_CONNECTION
            };
        case NewsTypes.CONNECTION_ACCEPT_SUCCESS:
            if (action.location === CONNECT_VIA.NEWSFEED) {
                return {
                    ...state,
                    action: NewsTypes.CONNECTION_ACCEPT_SUCCESS
                }
            } else if (action.location === CONNECT_VIA.LIKE_MODAL) {
                let currentPostLikedUsers = state.postLikedUsers;
                currentPostLikedUsers[action.payload].CONNECTION_STATUS.STATUS = 2
                return {
                    ...state,
                    action: NewsTypes.CONNECTION_ACCEPT_SUCCESS,
                    postLikedUsers: currentPostLikedUsers,
                    performingIndex: action.payload
                };
            } else {
                return {
                    ...state
                };
            }
        case NewsTypes.CONNECTION_ACCEPT_FAIL:
            return {
                ...state,
                action: NewsTypes.CONNECTION_ACCEPT_FAIL,
                performingIndex: action.payload
            };

        case NewsTypes.REJECTING_CONNECTION:
            return {
                ...state,
                action: NewsTypes.REJECTING_CONNECTION
            };
        case NewsTypes.CONNECTION_REJECT_SUCCESS:
            if (action.location === CONNECT_VIA.NEWSFEED) {
                return {
                    ...state,
                    action: NewsTypes.CONNECTION_REJECT_SUCCESS
                }
            } else if (action.location === CONNECT_VIA.LIKE_MODAL) {
                let currentPostLikedUsers = state.postLikedUsers;
                currentPostLikedUsers[action.payload].CONNECTION_STATUS.STATUS = 3
                return {
                    ...state,
                    action: NewsTypes.CONNECTION_REJECT_SUCCESS,
                    postLikedUsers: currentPostLikedUsers,
                    performingIndex: action.payload
                };
            } else {
                return {
                    ...state
                };
            }
        case NewsTypes.CONNECTION_REJECT_FAIL:
            return {
                ...state,
                action: NewsTypes.CONNECTION_REJECT_FAIL,
                performingIndex: action.payload
            };

        case NewsTypes.ADDING_CONNECTION:
            return {
                ...state,
                action: NewsTypes.ADDING_CONNECTION
            };
        case NewsTypes.CONNECTION_ADD_SUCCESS:
            if (action.location === CONNECT_VIA.NEWSFEED) {
                return {
                    ...state,
                    action: NewsTypes.CONNECTION_ADD_SUCCESS
                }
            } else if (action.location === CONNECT_VIA.LIKE_MODAL) {
                let currentPostLikedUsers = state.postLikedUsers;
                currentPostLikedUsers[action.index].CONNECTION_REQUEST = 1;
                currentPostLikedUsers[action.index].CONNECTION_STATUS = action.payload;
                return {
                    ...state,
                    action: NewsTypes.CONNECTION_ADD_SUCCESS,
                    postLikedUsers: currentPostLikedUsers,
                    performingIndex: action.index
                }
            } else {
                return {
                    ...state
                };
            }
        case NewsTypes.CONNECTION_ADD_FAIL:
            return {
                ...state,
                action: NewsTypes.CONNECTION_ADD_FAIL,
                performingIndex: action.index
            }

        case NewsTypes.FETCHING_COMMENTER_DETAILS:
            return {
                ...state,
                action: NewsTypes.FETCHING_COMMENTER_DETAILS,
                fetchingCommenterDetails: true,
            };
        case NewsTypes.FETCH_COMMENTER_DETAILS_SUCCESS:
            return {
                ...state,
                fetchingCommenterDetails: false,
                action: NewsTypes.FETCH_COMMENTER_DETAILS_SUCCESS,
                commenterDetails: action.payload.data
            };
        case NewsTypes.FETCH_COMMENTER_DETAILS_FAIL:
            return {
                ...state,
                fetchingCommenterDetails: false,
                action: NewsTypes.FETCH_COMMENTER_DETAILS_FAIL
            };
        case NewsTypes.FETCH_MORE_COMMENTS: {
            return {
                ...state,
                action: NewsTypes.FETCH_MORE_COMMENTS
            };
        }
        case NewsTypes.FETCH_MORE_COMMENTS_SUCCESS: {
            return {
                ...state,
                newsFeedData: state.newsFeedData.map((post) => {
                    if (post._id === action.id) action.reset ? post.POST_COMMENT = [...action.payload] : post.POST_COMMENT.unshift(...action.payload)
                    return post;
                }),
                trendingPost: (state.trendingPost && state.trendingPost._id === action.id) ? action.reset ? { ...state.trendingPost, ...{ POST_COMMENT: [...action.payload] } } : { ...state.trendingPost, ...{ POST_COMMENT: [...action.payload, ...state.trendingPost.POST_COMMENT] } } : state.trendingPost,
                trendingGroupPost: (state.trendingGroupPost && state.trendingGroupPost._id === action.id) ? action.reset ? { ...state.trendingGroupPost, ...{ POST_COMMENT: [...action.payload] } } : { ...state.trendingGroupPost, ...{ POST_COMMENT: [...action.payload, ...state.trendingGroupPost.POST_COMMENT] } } : state.trendingGroupPost,
                newsfeedSinglePost : (state.newsfeedSinglePost && state.newsfeedSinglePost._id === action.id) ? action.reset ? { ...state.newsfeedSinglePost, ...{ POST_COMMENT: [...action.payload] } } : { ...state.newsfeedSinglePost, ...{ POST_COMMENT: [...action.payload, ...state.newsfeedSinglePost.POST_COMMENT] } } : state.newsfeedSinglePost,
            }
        }
        case NewsTypes.FETCH_MORE_COMMENTS_FAIL: {
            return {
                ...state,
                action: NewsTypes.FETCH_MORE_COMMENTS_FAIL
            };
        }
        // case NewsTypes.GET_IMAGE_URL:
        //     const newData = [...state.newsFeedData];
        //     newData[action.index] = action.payload;
        //     return {
        //         ...state,
        //         newsFeedData: newData
        //     };

        case NewsTypes.FETCH_NEWSFEED_SINGLE_POST_ALL_COMMENTS:
            return {
                ...state,
                newsfeedSinglePost : { ...state.newsfeedSinglePost, ...{ POST_COMMENT: [...action.payload] } }
            };
        case NewsTypes.UPDATE_COMMENTS_COUNT:
            const newUpdatedCountData = state.newsFeedData.map(data => (data._id === action.payload.postId) ? { ...data, POST_COMMENT_COUNT: action.payload.newCount } : data)
            return {
                ...state,
                newsFeedData: newUpdatedCountData
            }
        case NewsTypes.FETCH_TEXT_LINK:
            return {
                ...state,
                action: NewsTypes.FETCH_TEXT_LINK,
                fetchingTextLinks: true,
            }
        case NewsTypes.FETCH_TEXT_LINK_SUCCESS:
            return {
                ...state,
                action: NewsTypes.FETCH_TEXT_LINK_SUCCESS,
                fetchingTextLinks: false,
                textLinks: action.init ? action.payload : [...state.textLinks, ...action.payload]
            }
        case NewsTypes.FETCH_TEXT_LINK_FAIL:
            return {
                ...state,
                action: NewsTypes.FETCH_TEXT_LINK_FAIL,
                fetchingTextLinks: false,
            }
        case NewsTypes.FETCH_TEXT_LINK_COUNT:
            return {
                ...state,
                action: NewsTypes.FETCH_TEXT_LINK_COUNT,
                textLinksCount: 0
            }
        case NewsTypes.FETCH_TEXT_LINK_COUNT_SUCCESS:
            return {
                ...state,
                action: NewsTypes.FETCH_TEXT_LINK_COUNT_SUCCESS,
                textLinksCount: action.payload
            }
        case NewsTypes.FETCH_TEXT_LINK_COUNT_FAIL:
            return {
                ...state,
                action: NewsTypes.FETCH_TEXT_LINK_COUNT_FAIL,
            }
        case NewsTypes.CLEAR_TEXT_LINK_AND_COUNT:
            return {
                ...state,
                textLinksCount: 0,
                textLinks: []
            }
        case NewsTypes.FETCH_TAG_SUGGESTION:
            return {
                ...state,
                action: NewsTypes.FETCH_TAG_SUGGESTION
            }

        case NewsTypes.FETCH_TAG_SUGGESTION_SUCCESS:
            return {
                ...state,
                action: NewsTypes.FETCH_TAG_SUGGESTION_SUCCESS,
                tagSuggestion: action.payload
            }
        case NewsTypes.FETCH_TAG_SUGGESTION_FAIL:
            return {
                ...state,
                action: NewsTypes.FETCH_TAG_SUGGESTION_FAIL
            }

        case NewsTypes.FETCHING_TRENDING_POST:
            return {
                ...state,
                action: NewsTypes.FETCHING_TRENDING_POST,
            };
        case NewsTypes.FETCH_TRENDING_POST_SUCCESS:
            return {
                ...state,
                action: NewsTypes.FETCH_TRENDING_POST_SUCCESS,
                trendingPost: action.payload,
                newsfeedSinglePost: null
            };
        case NewsTypes.FETCH_TRENDING_POST_FAIL:
            return {
                ...state,
                action: NewsTypes.FETCH_TRENDING_POST_FAIL
            };
        case NewsTypes.CLEAR_TRENDING_POST:
            return {
                ...state,
                action: NewsTypes.CLEAR_TRENDING_POST,
                trendingPost: {}
            };
        case NewsTypes.SET_TRENDING_TOPIC_WIDGET_TITLE:
            return {
                ...state,
                action: NewsTypes.SET_TRENDING_TOPIC_WIDGET_TITLE,
                trendingTopicWidgetTitle: action.payload.title
            };
        case NewsTypes.SET_INDUSTRY_WIDGET_TITLE:
            return {
                ...state,
                action: NewsTypes.SET_INDUSTRY_WIDGET_TITLE,
                industryEventWidgetTitle: action.payload.title
            };

        case NewsTypes.FETCHING_TRENDING_GROUP_POST:
            return {
                ...state,
                action: NewsTypes.FETCHING_TRENDING_GROUP_POST
            };
        case NewsTypes.FETCH_TRENDING_GROUP_POST_SUCCESS:
            return {
                ...state,
                trendingGroupPost: action.payload.data,
                action: NewsTypes.FETCH_TRENDING_GROUP_POST_SUCCESS,
            };
        case NewsTypes.FETCH_TRENDING_GROUP_POST_FAIL:
            return {
                ...state,
                action: NewsTypes.FETCH_TRENDING_GROUP_POST_FAIL,
            };
        case NewsTypes.CLEAR_TRENDING_GROUP_POST:
            return {
                ...state,
                action: NewsTypes.CLEAR_TRENDING_GROUP_POST,
                trendingGroupPost: {}
            };
        case NewsTypes.LIKE_COMMENT: {
            return {
                ...state,
                likeAction: NewsTypes.LIKE_COMMENT,
            }
        }
        case NewsTypes.LIKE_COMMENT_SUCCESS:

            let newsFeedDataLike = [...state.newsFeedData]
            let singlePostLike = [...state.singlePost]
            let trendingPostLike = {...state.trendingPost}
            let trendingGroupPostLike = { ...state.trendingGroupPost }
            let newsfeedSinglePostLike = state.newsfeedSinglePost ? { ...state.newsfeedSinglePost } : null
            let recentlyReacted = state.recentlyReacted;
            recentlyReacted[action.payload.commentId] = action.payload.reaction;
            let postInNewsFeed = false;

            if (newsFeedDataLike && newsFeedDataLike.length > 0) {
                const likeResult = commentHandleLikeUnlike(newsFeedDataLike, action.payload.postId, action.payload.commentId)
                if (likeResult) {
                    const { comment, postIndex, commentIndex } = likeResult
                    comment.POST_COMMENT_LIKE.push({ USER_ID: action.payload.userId, EMOTION_TYPE: action.payload.reaction })
                    newsFeedDataLike[postIndex].POST_COMMENT[commentIndex] = comment
                    postInNewsFeed = true;
                }
            }

            if (!postInNewsFeed && singlePostLike && singlePostLike.length > 0) {
                const likeResult = commentHandleLikeUnlike(singlePostLike, action.payload.postId, action.payload.commentId, true)
                if (likeResult) {
                    const { comment, postIndex, commentIndex } = likeResult
                    comment.POST_COMMENT_LIKE.push({ USER_ID: action.payload.userId, EMOTION_TYPE: action.payload.reaction })
                    singlePostLike[postIndex].COMMENTS[commentIndex] = comment  
                }
            }

            if (trendingPostLike && Object.entries(trendingPostLike).length > 0) {
                const likeResult = commentHandleLikeUnlike(trendingPostLike, action.payload.postId, action.payload.commentId)
                if (likeResult) {
                    const { comment, commentIndex } = likeResult
                    comment.POST_COMMENT_LIKE.push({ USER_ID: action.payload.userId, EMOTION_TYPE: action.payload.reaction })
                    trendingPostLike.POST_COMMENT[commentIndex] = comment  
                }
            }

            if (state.trendingGroupPost && state.trendingGroupPost._id && state.trendingGroupPost._id === action.payload.postId && trendingGroupPostLike && Object.entries(trendingGroupPostLike).length > 0) {
                const likeResult = commentHandleLikeUnlike(trendingGroupPostLike, action.payload.postId, action.payload.commentId)
                if (likeResult) {
                    const { comment, commentIndex } = likeResult
                    comment.POST_COMMENT_LIKE.push({ USER_ID: action.payload.userId, EMOTION_TYPE: action.payload.reaction })
                    trendingGroupPostLike.POST_COMMENT[commentIndex] = comment  
                }
            }

            if (state.newsfeedSinglePost && state.newsfeedSinglePost._id && state.newsfeedSinglePost._id === action.payload.postId && newsfeedSinglePostLike && Object.entries(newsfeedSinglePostLike).length > 0) {
                const likeResult = commentHandleLikeUnlike(newsfeedSinglePostLike, action.payload.postId, action.payload.commentId)
                if (likeResult) {
                    const { comment, commentIndex } = likeResult
                    comment.POST_COMMENT_LIKE.push({ USER_ID: action.payload.userId, EMOTION_TYPE: action.payload.reaction })
                    newsfeedSinglePostLike.POST_COMMENT[commentIndex] = comment  
                }
            }

            return {
                ...state,
                likeAction: NewsTypes.LIKE_COMMENT_SUCCESS,
                newsFeedData: newsFeedDataLike,
                singlePost: singlePostLike,
                trendingPost: trendingPostLike,
                trendingGroupPost: trendingGroupPostLike,
                newsfeedSinglePost: newsfeedSinglePostLike,
                likedItems: state.likedItems.concat(action.payload.commentId),
                unlikedItems: state.unlikedItems.filter(item => item !== action.payload.commentId),
                recentlyReacted: recentlyReacted
            };
        case NewsTypes.UNLIKE_COMMENT: {
            return {
                ...state,
                likeAction: NewsTypes.UNLIKE_COMMENT,
            }
        }
        case NewsTypes.UNLIKE_COMMENT_SUCCESS:
            let newsFeedDataUnlike = [...state.newsFeedData];
            let singlePostUnLike = [...state.singlePost];
            let trendingPostUnLike = {...state.trendingPost};
            let trendingGroupPostUnLike = {...state.trendingGroupPost};
            let newsfeedSinglePostUnLike = state.newsfeedSinglePost ? {...state.newsfeedSinglePost}: null;
            let recentlyUnReacted = state.recentlyReacted;
            delete recentlyUnReacted[action.payload.commentId];

            if (newsFeedDataUnlike && newsFeedDataUnlike.length > 0) {
                const unLikeResult = commentHandleLikeUnlike(newsFeedDataUnlike, action.payload.postId, action.payload.commentId)
                if (unLikeResult) {
                    const { comment, postIndex, commentIndex } = unLikeResult
                    newsFeedDataUnlike[postIndex].POST_COMMENT[commentIndex].POST_COMMENT_LIKE = comment.POST_COMMENT_LIKE.filter(like => like.USER_ID !== action.payload.userId)
                }
            }
            
            if (singlePostUnLike && singlePostUnLike.length > 0) {
                const unLikeResult = commentHandleLikeUnlike(singlePostUnLike, action.payload.postId, action.payload.commentId, true)
                if (unLikeResult) {
                    const { comment, postIndex, commentIndex } = unLikeResult
                    singlePostUnLike[postIndex].COMMENTS[commentIndex].POST_COMMENT_LIKE = comment.POST_COMMENT_LIKE.filter(like => like.USER_ID !== action.payload.userId)
                }
            }

            if (trendingPostUnLike && Object.entries(trendingPostUnLike).length > 0) {
                const unLikeResult = commentHandleLikeUnlike(trendingPostUnLike, action.payload.postId, action.payload.commentId)
                if (unLikeResult) {
                    const { comment, commentIndex } = unLikeResult
                    trendingPostUnLike.POST_COMMENT[commentIndex].POST_COMMENT_LIKE = comment.POST_COMMENT_LIKE.filter(like => like.USER_ID !== action.payload.userId)
                }
            }

            if (trendingGroupPostUnLike && Object.entries(trendingGroupPostUnLike).length > 0) {
                const unLikeResult = commentHandleLikeUnlike(trendingGroupPostUnLike, action.payload.postId, action.payload.commentId)
                if (unLikeResult) {
                    const { comment, commentIndex } = unLikeResult
                    trendingGroupPostUnLike.POST_COMMENT[commentIndex].POST_COMMENT_LIKE = comment.POST_COMMENT_LIKE.filter(like => like.USER_ID !== action.payload.userId)
                }
            }
            if (state.newsfeedSinglePost && state.newsfeedSinglePost._id && state.newsfeedSinglePost._id === action.payload.postId && Object.entries(newsfeedSinglePostUnLike).length > 0) {
                const unLikeResult = commentHandleLikeUnlike(newsfeedSinglePostUnLike, action.payload.postId, action.payload.commentId)
                if (unLikeResult) {
                    const { comment, commentIndex } = unLikeResult
                    newsfeedSinglePostUnLike.POST_COMMENT[commentIndex].POST_COMMENT_LIKE = comment.POST_COMMENT_LIKE.filter(like => like.USER_ID !== action.payload.userId)
                }
            }
            return {
                ...state,
                likeAction: NewsTypes.UNLIKE_COMMENT_SUCCESS,
                newsFeedData: newsFeedDataUnlike,
                singlePost: singlePostUnLike,
                trendingPost: trendingPostUnLike,
                trendingGroupPost: trendingGroupPostUnLike,
                newsfeedSinglePost: newsfeedSinglePostUnLike,
                likedItems: state.likedItems.filter(item => item !== action.payload.commentId),
                unlikedItems: state.unlikedItems.concat(action.payload.commentId),
                recentlyReacted: recentlyUnReacted
            };
        case NewsTypes.GET_LIKES_FOR_COMMENTS:
            return {
                ...state,
                postLikedUsers: []
            }
        
        case NewsTypes.GET_LIKES_FOR_COMMENTS_SUCCESS:
            return {
                ...state,
                postLikedUsers: action.payload
            }
        
        case NewsTypes.GET_LIKES_FOR_COMMENTS_FAIL:
            return {
                ...state,
                postLikedUsers: []
            }
        case NewsTypes.FETCH_NEWSFEED_SINGLE_POST:
            return {
                ...state,
                newsfeedSinglePost: null,
                fetchingNFSinglePost: true,
            }
        case NewsTypes.FETCH_NEWSFEED_SINGLE_POST_SUCCESS:
            return {
                ...state,
                fetchingNFSinglePost: false,
                newsfeedSinglePost: action.payload,
                trendingPost: null
            }
        case NewsTypes.FETCH_NEWSFEED_SINGLE_POST_FAIL:
            return {
                ...state,
                fetchingNFSinglePost: false,
            }
        case NewsTypes.CLEAR_NEWSFEED_SINGLE_POST: 
            return {
                ...state,
                newsfeedSinglePost: null,
            }
        case NewsTypes.FETCH_USER_FEED:
            return {
                ...state,
                action: NewsTypes.FETCH_USER_FEED,
            }
        case NewsTypes.FETCH_USER_FEED_SUCCESS:
            return {
                ...state,
                action: NewsTypes.FETCH_USER_FEED_SUCCESS,
                /* Chaged due to the LEAF-6136 issue
                UPDATE due to LEAF-6560
                */
                newsFeedData: action.isInit ? action.payload : [...state.newsFeedData, ...action.payload], /*  action.payload */
                userFeedFetchFailed: false
            }
        case NewsTypes.FETCH_USER_FEED_FAIL:
            return {
                ...state,
                action: NewsTypes.FETCH_USER_FEED_FAIL,
                newsFeedData: action.isInit ? [] : state.newsFeedData,
                userFeedFetchFailed: !action.isInit
            }

        case NewsTypes.FETCH_OTHER_USER_FEED:
            return {
                ...state,
                action: NewsTypes.FETCH_USER_FEED,
            }
        case NewsTypes.FETCH_OTHER_USER_FEED_SUCCESS:
            return {
                ...state,
                action: NewsTypes.FETCH_USER_FEED_SUCCESS,
                /* Chaged due to the LEAF-6136 issue
                UPDATE due to LEAF-6560
                */
                newsFeedData: action.isInit ? action.payload : [...state.newsFeedData, ...action.payload], /*  action.payload */
                userFeedFetchFailed: false
            }
        case NewsTypes.FETCH_OTHER_USER_FEED_FAIL:
            return {
                ...state,
                action: NewsTypes.FETCH_USER_FEED_FAIL,
                newsFeedData: action.isInit ? [] : state.newsFeedData,
                userFeedFetchFailed: !action.isInit
            }
        case NewsTypes.FETCH_USER_FEED_COUNT:
            return {
                ...state,
                action: NewsTypes.FETCH_USER_FEED_COUNT,
                newsFeedCount: 0
            }
        case NewsTypes.FETCH_USER_FEED_COUNT_SUCCESS:
            return {
                ...state,
                action: NewsTypes.FETCH_USER_FEED_COUNT_SUCCESS,
                newsFeedCount: action.payload
            }
        case NewsTypes.FETCH_OTHER_USER_FEED_COUNT:
            return {
                ...state,
                action: NewsTypes.FETCH_OTHER_USER_FEED_COUNT,
                newsFeedCount: 0
            }
        case NewsTypes.FETCH_OTHER_USER_FEED_COUNT_SUCCESS:
            return {
                ...state,
                action: NewsTypes.FETCH_OTHER_USER_FEED_COUNT_SUCCESS,
                newsFeedCount: action.payload[0].postCount
            }
        case NewsTypes.FORCE_REFRESH:
            return {
                ...state,
                action: NewsTypes.FORCE_REFRESH
            };
        case NewsTypes.CLEAR_NEWSFEED_SINGLE_POST_BY_USER:
            return {
                ...state,
                action: NewsTypes.CLEAR_NEWSFEED_SINGLE_POST_BY_USER
            }
        case NewsTypes.CLEAR_NEWSFEED_REACTED_DATA:
            return {
                ...state,
                recentlyReacted: [],
                unlikedItems: []
            }
        case NewsTypes.UPLOADING_NEWSFEED_VIDEO:
            let formatedUploadingVideos = state.uploadingVideos;
            formatedUploadingVideos[action.videoKey] = {
                status: VIDEO_UPLOAD_STATUS.UPLOAD,
                progress: action.progress
            }
            return {
                ...state,
                uploadingVideos: formatedUploadingVideos,
                action: NewsTypes.UPLOADING_NEWSFEED_VIDEO
            }
        case NewsTypes.PROCESSING_NEWSFEED_VIDEO:
            let formatedProcessingVideos = state.uploadingVideos;
            formatedProcessingVideos[action.videoKey] = {
                status: VIDEO_UPLOAD_STATUS.PROCESS,
                progress: 0
            }
            return {
                ...state,
                uploadingVideos: formatedProcessingVideos,
                action: NewsTypes.PROCESSING_NEWSFEED_VIDEO
            }
        case NewsTypes.PROCESS_COMPLETE_NEWSFEED_VIDEO: {
            return {
                ...state,
                completedVideo: action.postId,
                action: NewsTypes.PROCESS_COMPLETE_NEWSFEED_VIDEO
            }
        }
        case NewsTypes.CLEAR_PROCESS_COMPLETE_NEWSFEED_VIDEO: {
            return {
                ...state,
                completedVideo: null,
                action: 0
            }
        }
        case NewsTypes.SET_PENDING_POST: {
            return {
                ...state,
                pendingPublish: [{...action.pendingPost}, ...state.pendingPublish]
            }
        }
        case NewsTypes.CLEAR_PENDING_POST: {
            const formatedPendingPublish = state.pendingPublish.filter(p => {return !action.publishedPostes.includes( p._id)})
            return {
                ...state,
                pendingPublish: formatedPendingPublish
            }
        }
        case NewsTypes.EMPTY_PENDING_POST: {
            return {
                ...state,
                pendingPublish: []
            }
        }
        case NewsTypes.SET_RECENT_POST: {
            return {
                ...state,
                newsFeedData: [action.recentPost, ...state.newsFeedData],
                action: NewsTypes.SET_RECENT_POST
            }
        }
        case NewsTypes.BLOCK_POST_SUCCESS: {
            const newNewsFeedData = state.newsFeedData.filter(data => (data._id !== action.payload.postId));
            let newsfeedSinglePostBlock = state.newsfeedSinglePost;
            if(state.newsfeedSinglePost && state.newsfeedSinglePost._id === action.payload.postId) {
                newsfeedSinglePostBlock = null;
            }
            return {
                ...state,
                newsFeedData: newNewsFeedData,
                action: NewsTypes.BLOCK_POST_SUCCESS,
                newsfeedSinglePost: newsfeedSinglePostBlock
            }
        }
        case NewsTypes.BLOCK_USER_SUCCESS: {
            const newNewsFeedData = state.newsFeedData.filter(data => (data.POST_CREATOR !== action.payload.blockedUserId));
            let newsfeedSinglePostBlock = state.newsfeedSinglePost;
            if(state.newsfeedSinglePost && state.newsfeedSinglePost.POST_CREATOR === action.payload.blockedUserId) {
                newsfeedSinglePostBlock = null;
            }
            return {
                ...state,
                newsFeedData: newNewsFeedData,
                action: NewsTypes.BLOCK_USER_SUCCESS,
                newsfeedSinglePost: newsfeedSinglePostBlock
            }
        }
        default:
            return state
    }
}