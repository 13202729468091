module.exports = {
    CHAT_HEADERS: {
        LIST: 1,
        WINDOW: 2,
        NEW_MESSAGE: 3,
    },
    CHAT_UTILITY: {
        EDIT: 1,
        MIN_MAX: 2,
        CLOSE: 3,
        OPTIONS: 4
    },
    STATUS: {
        FETCHING: '_FETCHING',
        SUCCESS: '_SUCCESS',
        FAIL: '_FAIL',
        DELETING: '_DELETING'
    },

    // actions
    OPEN_NEW_CHAT: "NEW_CHAT",
    CLOSE_NEW_CHAT: "NEW_CHAT",
    OPEN_EXISTING_CHAT: "OPEN_EXISTING_CHAT",
    CLOSE_EXISTING_CHAT: "CLOSE_EXISTING_CHAT",
    GET_CHAT_CONVERSATIONS: 'GET_CHAT_CONVERSATIONS',
    GET_CHAT_CONVERSATIONS_COUNT: 'GET_CHAT_CONVERSATIONS_COUNT',
    GET_CHAT_MESSAGES: 'GET_CHAT_MESSAGES',
    GET_CHAT_MESSAGES_COUNT: 'GET_CHAT_MESSAGES_COUNT',
    SEND_CHAT_MESSAGE: 'SEND_CHAT_MESSAGE',
    UPDATE_CHAT_THREAD: 'UPDATE_CHAT_THREAD',
    UPDATE_CHAT_THREAD_TYPING_STATUS: 'UPDATE_CHAT_THREAD_TYPING_STATUS',
    FILTER_DELETED_CHAT: 'FILTER_DELETED_CHAT',
    UPDATE_CHAT_ONLINE_STATUS: 'UPDATE_CHAT_ONLINE_STATUS',
    NOTIFICATION_CHAT_POP: 'NOTIFICATION_CHAT_POP',
    MINIMIZE_CONVERSATIONS: 'MINIMIZE_CONVERSATIONS',
    CLEAN_CHATPOP_STATES: 'CLEAN_CHATPOP_STATES',
    RECONNECT_TO_CONVERSATIONS: 'RECONNECT_TO_CONVERSATIONS',
    CLEAN_SINGLE_CHATPOP_STATES: 'CLEAN_SINGLE_CHATPOP_STATES',
    SOCKET_NEW_CHAT_MESSAGE: 'SOCKET_NEW_CHAT_MESSAGE',
    SOCKET_SEEN_CHAT_MESSAGE: 'SOCKET_SEEN_CHAT_MESSAGE',
    NEW_CHAT_NOTIFICATION: 'NEW_CHAT_NOTIFICATION',
    SET_CHAT_ACTIVE_STATUS: 'SET_CHAT_ACTIVE_STATUS',
    REPLACE_INACTIVE_CHAT: 'REPLACE_INACTIVE_CHAT',
    CLEAR_REPLACE_INACTIVE_CHAT: 'CLEAR_REPLACE_INACTIVE_CHAT',
    INIT_UNSEEN_CHATS: 'INIT_UNSEEN_CHATS',
    CLEAR_INIT_UNSEEN_CHATS: 'CLEAR_INIT_UNSEEN_CHATS',
    SEEN_CHAT_MESSAGE: 'SEEN_CHAT_MESSAGE',
    REFETCH_MY_CONVERSATIONS: 'REFETCH_MY_CONVERSATIONS',
    REPORT_SPAM: 'REPORT_SPAM',
    RESET_PRO_MESSAGE_LIMIT: "RESET_PRO_MESSAGE_LIMIT",

    CLEAR_CHAT_ACTION: 'CLEAR_CHAT_ACTION'
};