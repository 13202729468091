import React, { Component } from "react";
import Measure from "react-measure";
import "./styles.css";
import avt from "./../../../../assets/sample/avt.png";

class SummaryBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dimensions: {
        width: 0
      }
    };
  }
  render() {
    return (
      <div className="d-flex flex-row m-b5">
        {this.getProfileImage(this.props.imgSrc, this.props.isOnline , this.props.icon)}
        <div style={{ width: "100%" }}>
          {this.getDetails(
            this.props.name,
            this.props.message,
            this.state.dimensions.width
          )}
        </div>
      </div>
    );
  }

  getDetails = (name, message, width) => {
    let styles = {
      whiteSpace: "nowrap",
      width: width - 20 + "px",
      overflow: "hidden",
      textOverflow: "ellipsis"
    };
    return (
      <Measure
        bounds
        onResize={contentRect => {
          this.setState({ dimensions: contentRect.bounds });
        }}
      >
        {({ measureRef }) => (
          <div ref={measureRef} className="m-l15">
            <h4 className="summary_box_name font_15 font_medium font_dark_gray_two">
              {name}
            </h4>
            <p style={styles}>
              {message}
            </p>
          </div>
        )}
      </Measure>
    );
  };

  getProfileImage = (image, isOnline ,icon) => {
    if(icon){
          return icon
    }else{
      if (image) {
        let profileStyle = {
          backgroundImage: "url(" + image + ")",
          width: "41px",
          height: "41px"
        };
        return (
          <div style={{ width: "41px" }}>
            <div
              className="summary_box_profile_img justify-content-center align-items-center d-flex "
              style={profileStyle}
            >
              {isOnline ? (
                <div className="summary_box_online" />
              ) : null}
            </div>
          </div>
        );
      } else {
        let profileStyle = {
          backgroundImage: "url(" + avt + ")"
        };
        return(
            <div style={{ width: "41px" }}>
              <div
                  className="summary_box_profile_img justify-content-center align-items-center d-flex "
                  style={profileStyle}
              >
                  {isOnline ? <div className="summary_box_online" /> : null}
              </div>
            </div>
        );
      }
    }
 
  };
}

SummaryBox.defaultProps = {
  imgSrc: null,
  online: false,
  name: null,
  message: null,
  icon : null
};

export { SummaryBox };
