module.exports = {

    SUBSCRIBED_TO_FUNDRAISING: "SUBSCRIBED_TO_FUNDRAISING",
    FUNDRAISING_SUBSCRIPTION_EXPIRED: "FUNDRAISING_SUBSCRIPTION_EXPIRED",

    UPLOADING_DOCUMENTS: "UPLOADING_DOCUMENTS",
    UPLOADING_DOCUMENTS_SUCCESS: "UPLOADING_DOCUMENTS_SUCCESS",
    UPLOADING_DOCUMENTS_FAIL: "UPLOADING_DOCUMENTS_FAIL",
    UPLOADING_DOCUMENTS_DONE: "UPLOADING_DOCUMENTS_DONE",
    DOCUMENT_DELETE_SUCCESS: "DOCUMENT_DELETE_SUCCESS",
    DOCUMENT_DELETE_FAIL: "DOCUMENT_DELETE_FAIL",
    DOCUMENT_DELETE: "DOCUMENT_DELETE",

    DOCUMENT_UPLOAD_MODAL: "DOCUMENT_UPLOAD_MODAL",
    ADD_NEW_VERSION_MODAL: "ADD_NEW_VERSION_MODAL",
    DOCUMENT_EDIT_MODAL: "DOCUMENT_EDIT_MODAL",
    SHAREWITH_MODAL: "SHAREWITH_MODAL",

    VIEW_DOCUMENT_HISTORY: "VIEW_DOCUMENT_HISTORY",
    CLEAR_DOCUMENT_HISTORY: "CLEAR_DOCUMENT_HISTORY",

    FETCHING_DATAROOM_DOCUMENTS: "FETCHING_DATAROOM_DOCUMENTS",
    FETCH_DATAROOM_DOCUMENTS_SUCCESS: "FETCH_DATAROOM_DOCUMENTS_SUCCESS",
    FETCH_DATAROOM_DOCUMENTS_FAIL: "FETCH_DATAROOM_DOCUMENTS_FAIL",
    CLEAR_DATAROOM_DOCUMENTS: "CLEAR_DATAROOM_DOCUMENTS",
    CLEAR_DATAROOM_DOCS: "CLEAR_DATAROOM_DOCS",

    FETCHING_DATAROOM_DOCUMENT_HISTORY: "FETCHING_DATAROOM_DOCUMENT_HISTORY",
    FETCHING_DATAROOM_DOCUMENT_HISTORY_SUCCESS: "FETCHING_DATAROOM_DOCUMENT_HISTORY_SUCCESS",
    FETCHING_DATAROOM_DOCUMENT_HISTORY_FAIL: "FETCHING_DATAROOM_DOCUMENT_HISTORY_FAIL", 

    FETCHING_DATAROOM_DOCUMENT_COUNT: "FETCHING_DATAROOM_DOCUMENT_COUNT",
    FETCH_DATAROOM_DOCUMENT_COUNT_SUCCESS: "FETCH_DATAROOM_DOCUMENT_COUNT_SUCCESS",
    FETCH_DATAROOM_DOCUMENT_COUNT_FAIL: "FETCH_DATAROOM_DOCUMENT_COUNT_FAIL",
    CLEAR_DATAROOM_DOCUMENT_COUNT: "CLEAR_DATAROOM_DOCUMENT_COUNT",

    EDIT_DATAROOM_DOC: "EDIT_DATAROOM_DOC",
    EDIT_DATAROOM_DOC_SUCCESS: "EDIT_DATAROOM_DOC_SUCCESS",
    EDIT_DATAROOM_DOC_FAIL: "EDIT_DATAROOM_DOC_FAIL",

    CHECK_INVITATION_VALID: "CHECK_INVITATION_VALID",
    CHECK_INVITATION_VALID_SUCCESS: "CHECK_INVITATION_VALID_SUCCESS",
    CHECK_INVITATION_VALID_FAIL: "CHECK_INVITATION_VALID_FAIL",


    FETCHING_DATAROOM_PERMISSION: "FETCHING_DATAROOM_PERMISSION",
    FETCH_DATAROOM_PERMISSION_SUCCESS: "FETCH_DATAROOM_PERMISSION_SUCCESS",
    FETCH_DATAROOM_PERMISSION_FAIL: "FETCH_DATAROOM_PERMISSION_FAIL",

    REQUESTING_DATAROOM_ACCESS: "REQUESTING_DATAROOM_ACCESS",
    REQUEST_DATAROOM_ACCESS_SUCCESS: "REQUEST_DATAROOM_ACCESS_SUCCESS",
    REQUEST_DATAROOM_ACCESS_FAIL: "REQUEST_DATAROOM_ACCESS_FAIL",

    FETCHING_PENDING_DATAROOM_REQUESTS: "FETCHING_PENDING_DATAROOM_REQUESTS",
    FETCH_PENDING_DATAROOM_REQUESTS_SUCCESS: "FETCH_PENDING_DATAROOM_REQUESTS_SUCCESS",
    FETCH_PENDING_DATAROOM_REQUESTS_FAIL: "FETCH_PENDING_DATAROOM_REQUESTS_FAIL",
    CLEAR_PENDING_DATAROOM_REQUESTS: "CLEAR_PENDING_DATAROOM_REQUESTS",

    FETCHING_PENDING_DATAROOM_REQUEST_COUNT: "FETCHING_PENDING_DATAROOM_REQUEST_COUNT",
    FETCH_PENDING_DATAROOM_REQUEST_COUNT_SUCCESS: "FETCH_PENDING_DATAROOM_REQUEST_COUNT_SUCCESS",
    FETCH_PENDING_DATAROOM_REQUEST_COUNT_FAIL: "FETCH_PENDING_DATAROOM_REQUEST_COUNT_FAIL",
    CLEAR_PENDING_DATAROOM_REQUEST_COUNT: "CLEAR_PENDING_DATAROOM_REQUEST_COUNT",

    ACCEPTING_DATAROOM_REQUEST: "ACCEPTING_DATAROOM_REQUEST",
    ACCEPT_DATAROOM_REQUEST_SUCCESS: "ACCEPT_DATAROOM_REQUEST_SUCCESS",
    ACCEPT_DATAROOM_REQUEST_FAIL: "ACCEPT_DATAROOM_REQUEST_FAIL",

    REJECTING_DATAROOM_REQUEST: "REJECTING_DATAROOM_REQUEST",
    REJECT_DATAROOM_REQUEST_SUCCESS: "REJECT_DATAROOM_REQUEST_SUCCESS",
    REJECT_DATAROOM_REQUEST_FAIL: "REJECT_DATAROOM_REQUEST_FAIL",

    FETCHING_SHAREDUSERS_DATAROOM_REQUESTS: "FETCHING_SHAREDUSERS_DATAROOM_REQUESTS",
    FETCH_SHAREDUSERS_DATAROOM_REQUESTS_SUCCESS: "FETCH_SHAREDUSERS_DATAROOM_REQUESTS_SUCCESS",
    FETCH_SHAREDUSERS_DATAROOM_REQUESTS_FAIL: "FETCH_SHAREDUSERS_DATAROOM_REQUESTS_FAIL",
    CLEAR_DATAROOM_SHAREDUSERS: "CLEAR_DATAROOM_SHAREDUSERS",

    FETCHING_SHAREDUSERS_DATAROOM_REQUEST_COUNT: "FETCHING_SHAREDUSERS_DATAROOM_REQUEST_COUNT",
    FETCH_SHAREDUSERS_DATAROOM_REQUEST_COUNT_SUCCESS: "FETCH_SHAREDUSERS_DATAROOM_REQUEST_COUNT_SUCCESS",
    FETCH_SHAREDUSERS_DATAROOM_REQUEST_COUNT_FAIL: "FETCH_SHAREDUSERS_DATAROOM_REQUEST_COUNT_FAIL",
    CLEAR_SHAREDUSER_DATAROOM_REQUEST_COUNT: "CLEAR_SHAREDUSER_DATAROOM_REQUEST_COUNT",

    REVOKE_DATAROOM_ACCESS: "REVOKE_DATAROOM_ACCESS",
    REVOKE_DATAROOM_ACCESS_SUCCESS: "REVOKE_DATAROOM_ACCESS_SUCCESS",
    REVOKE_DATAROOM_ACCESS_FAIL: "REVOKE_DATAROOM_ACCESS_FAIL",

    RE_GRANT_DATAROOM_ACCESS: "RE_GRANT_DATAROOM_ACCESS",
    RE_GRANT_DATAROOM_ACCESS_SUCCESS: "RE_GRANT_DATAROOM_ACCESS_SUCCESS",
    RE_GRANT_DATAROOM_ACCESS_FAIL: "RE_GRANT_DATAROOM_ACCESS_FAIL",

    CLEAR_DATAROOM_USER_ACTION: "CLEAR_DATAROOM_USER_ACTION",

    SENDING_DATAROOM_INVITATION: "SENDING_DATAROOM_INVITATION",
    SEND_DATAROOM_INVITATION_SUCCESS: "SEND_DATAROOM_INVITATION_SUCCESS",
    SEND_DATAROOM_INVITATION_FAIL: "SEND_DATAROOM_INVITATION_FAIL",
    CLEAR_SEND_DATAROOM_INVITATION: "CLEAR_SEND_DATAROOM_INVITATION",
    CLEAR_RE_INVITED_DATAROOM_INVITATION: "CLEAR_RE_INVITED_DATAROOM_INVITATION",

    DOCUMENT_VIEW_COUNT_UPDATING: "DOCUMENT_VIEW_COUNT_UPDATING",
    DOCUMENT_VIEW_COUNT_UPDATED: "DOCUMENT_VIEW_COUNT_UPDATED",
    DOCUMENT_VIEW_COUNT_UPDATE_FAIL: "DOCUMENT_VIEW_COUNT_UPDATE_FAIL",

    FETCHING_STATISTICS: "FETCHING_STATISTICS",
    FETCHING_STATISTICS_SUCCESS: "FETCHING_STATISTICS_SUCCESS",
    FETCHING_STATISTICS_FAIL: "FETCHING_STATISTICS_FAIL",

    FETCHING_STATISTICS_COUNT: "FETCHING_STATISTICS_COUNT",
    FETCHING_STATISTICS_COUNT_SUCCESS: "FETCHING_STATISTICS_COUNT_SUCCESS",
    FETCHING_STATISTICS_COUNT_FAIL: "FETCHING_STATISTICS_COUNT_FAIL",

    SEARCH_DATAROOM_DOCS:"SEARCH_DATAROOM_DOCS",
    SEARCH_DATAROOM_DOCS_SUCCESS:"SEARCH_DATAROOM_DOCS_SUCCESS",
    SEARCH_DATAROOM_DOCS_FAIL:"SEARCH_DATAROOM_DOCS_FAIL",

    STATISTICS_INC: "STATISTICS_INC",
    STATISTICS_DEC: "STATISTICS_DEC",

    CLEAR_ACTION: "CLEAR_ACTION",
    CLEAR_DATAROOM_PERMISSIONS: "CLEAR_DATAROOM_PERMISSIONS",

    CLEAR_FUND_RAISING_USER_ACTION: "CLEAR_DATAROOM_USER_ACTION",

    CLEAR_DATAROOM_REDUCER: "CLEAR_DATAROOM_REDUCER"

}