import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Route, Redirect } from "react-router-dom";
import { Loading } from '../../components/common';
import { ROUTES } from "../../config";
import {routeLogin} from './../../actions/route';
import {CLEAR_ROUTE_ACCESS} from './../../types/authentication';
import {withRouter} from 'react-router-dom';
import qs from 'query-string'
import {get} from "../../libs/apiConnector";


function GroupRoute(props) {

    const [group, setGroup] = useState({
        fetching: true,
        data: null,
        valid: false
    });

    const {
        computedMatch,
        login,
        route,
        component: Component,
        ...rest
    } = props;

    const setGroupParams = () => {
        
        localStorage.setItem('groupToJoin', computedMatch.params.groupId);
        if(computedMatch.params.postId){
            localStorage.setItem('groupPost', computedMatch.params.postId);
            // tag/report redirect
            if (computedMatch && computedMatch.params && computedMatch.params.groupId && computedMatch.params.postId) {
                const { commentId = null, tagCommentId = null, tagId = null } = qs.parse(props.location.search);
                const singleGroupPost = {};
                singleGroupPost.singleView = true;
                if (tagCommentId) {
                    singleGroupPost.tagCommentId = tagCommentId
                } else if(commentId) {
                    singleGroupPost.commentId = commentId
                }
                if (tagId) {
                    singleGroupPost.tagId = tagId
                }

                localStorage.setItem('isSinglePost', JSON.stringify(singleGroupPost))
            }
        }else {
            localStorage.removeItem('groupPost');
            localStorage.removeItem('isSinglePost');
        }
    };

    useEffect(() => {
        async function fetchData() {
            try {
                setGroup(prev => ({ ...prev, fetching: true }));
                const { data, status } = await get(ROUTES.FETCH_GROUP_DETAILS + '/' + computedMatch.params.groupId);
                if(status === 200){
                    setGroup(prev => ({
                        ...prev,
                        fetching: false,
                        data: data.data,
                        valid: true
                    }));
                } else {
                    setGroupParams();
                    setGroup(prev => ({
                        ...prev,
                        fetching: false,
                        valid: false
                    }));
                }
            } catch (ex) {
                setGroupParams();
                setGroup(prev => ({
                    ...prev,
                    fetching: false,
                    valid: false
                }));
            }
        };
        login(localStorage.getItem('token'));
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [computedMatch.params.groupId, login]);

    useEffect(() => {
        if (route.action === CLEAR_ROUTE_ACCESS) {
            let utm = props.location.search;
            if (utm) {
                props.history.push(`/mjbiz${utm}`)
            } else {
                props.history.push('/mjbiz')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [route.action]);


    if(group.fetching || route.loading){
        return <Loading/>
    } else {
        return (
            <Route {...rest} render={prop => (
                <div>
                    {!group.valid && route.isAuthenticated && <Redirect to={`/`} />}
                    <Component {...props} groupInfo={group.data}/>
                </div>
            )}
            />
        )
    }

}

const mapStateToProps = function (state) {
    return {
        route: state.route
    }
}

export default withRouter(connect(mapStateToProps, {
    login: routeLogin
})(GroupRoute));
