import axios from 'axios';
import {get, post} from '../libs/apiConnector';
import ProfileTypes from '../types/profile';
import {
    REPLACE_RECOMMONDED_CONNECTIONS,
    SET_RECOMMENDED_CONNECTIONS_IMAGES,
    CLEAR_ACTION,
    REPLACE_RECOMMONDED_CONNECTIONS_FAIL,
    REJECT_MY_CONNECTION,
    DECREMENT_PENDING_CONNECTION
} from '../types/connections';
import {SET_IMAGES} from '../types/images';
import {ALERT, POP_ALERT, BLOCKUSER_SUCCESS, BLOCKUSER_FAIL, BLOCKUSER_ALREADY} from '../types/common';
import {showAlert, showPopupAlert} from './alert';

import {ROUTES, SIGNED_COOKIES_CF_URL} from '../config';
//import {requestToFetchMore} from "./search";
import {isURL} from '../libs/validation';
import {
    CHANGE_SUB_MENU,
    FILTER_SEARCH_LIMIT,
    NOTIFICATION_TYPES,
    CONNECTION_REJECT_TYPES,
    USER_PROFILE_VIEW_LOCATIONS,
    BLOCKUSER_ACCEPT_ERROR_MSG,
    BLOCKUSER_REJECT_ERROR_MSG,
    BLOCKUSER_CONNECT_ERROR_MSG,
    BLOCKUSER_POPUP_CLASSNAME
} from '../types/common';
import {getPendingConnectionCount, getConnectionsCount, fetchConnections} from './connections';
import {imageExists} from "../libs/image";
import {notifyReceiver} from '../libs/socket';
import {reloadUserNotificationCounters} from './auth';
import {refeshChat, getChatConversations} from './chat';
import {getLeftPanelCoversationCounts} from './message-v2'
import {ADD_LAST_ACCEPTED_USER_MESSAGE} from '../types/message-v2';
import {BLOCK_USER_SUCCESS} from '../types/newsFeed';

import * as ApiConnector from '../libs/apiConnector';
import {notifyConnectionRequestFail} from "../libs/commonFunctions";
import { getCFObjURL, BUCKET } from '../libs/cfHelper';

export function getCompanyPublicProfile(companyId, cancelToken) {
    return function (dispatch) {
        dispatch({type: ProfileTypes.FETCH_PROFILE_BEGIN});
        get(`${ROUTES.PUBLIC_PROFILE}${companyId}`, {cancelToken})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: ProfileTypes.FETCH_PROFILE_SUCCESS, payload: response.data.data[0]});
                    dispatch({type: ProfileTypes.CLEAR_ACTION})
                } else if (response.data.code === 203) {
                    dispatch({type: ProfileTypes.FETCH_PROFILE_FAILURE, payload: response.data});
                    dispatch({type: ProfileTypes.CLEAR_ACTION})
                    showAlert(dispatch, ALERT.ERROR, response.data.message);
                } else {
                    dispatch({type: ProfileTypes.FETCH_PROFILE_NOT_FOUND, payload: response.data});
                    dispatch({type: ProfileTypes.CLEAR_ACTION})
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: ProfileTypes.FETCH_PROFILE_FAILURE, payload: error.message});
                    dispatch({type: ProfileTypes.CLEAR_ACTION})
                }
            })
    }
}

export function getCompanyPeople(companyId) {
    return function (dispatch) {
        dispatch({type: ProfileTypes.FETCH_COMPANY_PEOPLE_BEGIN});
        get(`${ROUTES.COMPANY_PEOPLE}${companyId}`)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: ProfileTypes.FETCH_COMPANY_PEOPLE_SUCCESS, payload: response.data.data});
                    dispatch({type: ProfileTypes.CLEAR_ACTION})
                } else if (response.data.code === 203) {
                    dispatch({type: ProfileTypes.FETCH_COMPANY_PEOPLE_FAILURE, payload: response.data});
                    dispatch({type: ProfileTypes.CLEAR_ACTION})
                    showAlert(dispatch, ALERT.ERROR, response.data.message);
                } else {
                    dispatch({type: ProfileTypes.FETCH_COMPANY_PEOPLE_NOT_FOUND, payload: response.data});
                    dispatch({type: ProfileTypes.CLEAR_ACTION})
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: ProfileTypes.FETCH_COMPANY_PEOPLE_FAILURE, payload: error.message});
                    dispatch({type: ProfileTypes.CLEAR_ACTION})
                }
            })
    }
}

export function checkUserIsCompanyAdmin(data) {
    return function (dispatch) {
        dispatch({type: ProfileTypes.IS_USER_COMPANY_ADMIN_CHECK_BEGIN});
        post(`${ROUTES.IS_USER_COMPANY_ADMIN}`, data)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: ProfileTypes.IS_USER_COMPANY_ADMIN_CHECK_SUCCESS, payload: response.data.data.isAdmin});
                    dispatch({type: ProfileTypes.CLEAR_ACTION})
                } else if (response.data.code === 203) {
                    dispatch({type: ProfileTypes.IS_USER_COMPANY_ADMIN_CHECK_FAILURE, payload: response.data.data});
                    dispatch({type: ProfileTypes.CLEAR_ACTION})
                } else {
                    dispatch({type: ProfileTypes.IS_USER_COMPANY_ADMIN_CHECK_NOT_FOUND, payload: response.data.data});
                    dispatch({type: ProfileTypes.CLEAR_ACTION})
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: ProfileTypes.FETCH_COMPANY_PEOPLE_COUNT_FAILURE, payload: error.message});
                    dispatch({type: ProfileTypes.CLEAR_ACTION})
                }
            })
    }
}

export function removePersonFromCompanyPeople(data) {
    return function (dispatch) {
        dispatch({type: ProfileTypes.REMOVE_COMPANY_PEOPLE_BEGIN});
        post(`${ROUTES.REOMVE_PERSON_FROM_COMPANY_PEOPLE}`, data)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: ProfileTypes.REMOVE_COMPANY_PEOPLE_SUCCESS, payload: response.data.data});
                    dispatch({type: ProfileTypes.CLEAR_ACTION})
                } else if (response.data.code === 203) {
                    dispatch({type: ProfileTypes.REMOVE_COMPANY_PEOPLE_FAILURE, payload: response.data.data});
                    dispatch({type: ProfileTypes.CLEAR_ACTION})
                    showAlert(dispatch, ALERT.ERROR, response.data.message);
                } else {
                    dispatch({type: ProfileTypes.REMOVE_COMPANY_PEOPLE_FAILURE, payload: response.data.data});
                    dispatch({type: ProfileTypes.CLEAR_ACTION})
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: ProfileTypes.REMOVE_COMPANY_PEOPLE_FAILURE, payload: error.message});
                    dispatch({type: ProfileTypes.CLEAR_ACTION})
                }
            })
    }
}


export function clearProfile() {
    return function (dispatch) {
        dispatch({type: ProfileTypes.CLEAR_PROFILE})
    }
}

export function clearCompanyPeople() {
    return function (dispatch) {
        dispatch({type: ProfileTypes.CLEAR_COMPANY_PROFILE})
    }
}

export function getKeyEmpFundrasingDetails(userId, cancelToken) {
    return function (dispatch) {
        dispatch({type: ProfileTypes.FETCH_KEY_EMP_FUNDRAISING});
        get(`${ROUTES.GET_KEY_EMP_FUNDRAISING}${userId}`, {cancelToken})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: ProfileTypes.FETCH_KEY_EMP_FUNDRAISING_SUCCESS, payload: response.data.data[0]});
                    dispatch({type: ProfileTypes.CLEAR_ACTION})
                } else {
                    dispatch({type: ProfileTypes.FETCH_KEY_EMP_FUNDRAISING_FAILURE});
                    dispatch({type: ProfileTypes.CLEAR_ACTION})
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: ProfileTypes.FETCH_KEY_EMP_FUNDRAISING_FAILURE, payload: error.message});
                    dispatch({type: ProfileTypes.CLEAR_ACTION})
                }
            })
    }
}

export function setProfileViewLocation(location) {
    return function (dispatch) {
        dispatch({type: ProfileTypes.SET_PROFILE_VIEW_LOCATION, payload: location});
    }
}

export function getIndividualPublicProfile(userId, cancelToken, gynViewId = null) {
    return function (dispatch, getState) {
        dispatch({type: ProfileTypes.FETCH_PUBLIC_PROFILE_BEGIN});
        let url = '';
        if (gynViewId) {
            url = `${ROUTES.PUBLIC_PROFILE_INDIVIDUAL}${userId}/${USER_PROFILE_VIEW_LOCATIONS.WIDGET}/${gynViewId}`;
        } else {
            url = `${ROUTES.PUBLIC_PROFILE_INDIVIDUAL}${userId}/${getState().profile.profileViewLocation}`;
        }
        get(url, {cancelToken})
            .then(response => {
                if (response) {
                    if (response.data.code === 200) {
                        dispatch({type: ProfileTypes.FETCH_PUBLIC_PROFILE_SUCCESS, payload: response.data.data});
                        dispatch({type: ProfileTypes.CLEAR_ACTION})
                    } else {
                        dispatch({type: ProfileTypes.FETCH_PUBLIC_PROFILE_FAILURE, payload: response.data});
                        dispatch({type: ProfileTypes.CLEAR_ACTION})
                    }
                } else {
                    //when refresh token failed
                    dispatch({type: ProfileTypes.CLEAR_ACTION})
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: ProfileTypes.FETCH_PUBLIC_PROFILE_FAILURE, payload: error.message});
                    dispatch({type: ProfileTypes.CLEAR_ACTION})
                }
            })
    }
}

export const getIndividualUser = async (userId) => {
    try {
        const res = await get(`${ROUTES.PUBLIC_PROFILE_INDIVIDUAL}${userId}/${USER_PROFILE_VIEW_LOCATIONS.SOCKET}`);
        if (res && res.data && res.data.code === 200 && res.data.data) {
            return {success: true, data: res.data.data}
        } else {
            return {success: false, data: {}}
        }
    } catch (ex) {
        return {success: false, data: {}}
    }
};

export function getPublicProfileImage(imageName, cancelToken) {
    return function (dispatch) {
        dispatch({type: ProfileTypes.GETTING_PUBLIC_PROFILE_IMAGE});
        const url = getCFObjURL(BUCKET.COMMON)(imageName, true);
        if (url) {
            dispatch({
                type: ProfileTypes.GETTING_PUBLIC_PROFILE_IMAGE_SUCCESS,
                payload: url,
            });
        } else {
            dispatch({ type: ProfileTypes.GETTING_PUBLIC_PROFILE_IMAGE_FAIL });
        }
    }
}

export function getPublicCompanyImage(imageName, cancelToken) {
    return function (dispatch) {
        dispatch({type: ProfileTypes.GETTING_PUBLIC_COMPANY_IMAGE});
        if (imageName === '' || imageName === undefined) {
            dispatch({type: ProfileTypes.GETTING_PUBLIC_COMPANY_IMAGE_FAIL});
        } else {
            const url = getCFObjURL(BUCKET.COMMON)(imageName, true);
            if (url) {
                if (isURL(url, {protocols: ['https']}, SIGNED_COOKIES_CF_URL)) {
                    imageExists(url, (exists) => {
                        if (exists) {
                            dispatch({
                                type: ProfileTypes.GETTING_PUBLIC_COMPANY_IMAGE_SUCCESS,
                                payload: url
                            });
                        } else {
                            setTimeout(() => {
                                imageExists(url, (exists) => {
                                    if (exists) {
                                        dispatch({
                                            type: ProfileTypes.GETTING_PUBLIC_COMPANY_IMAGE_SUCCESS,
                                            payload: url
                                        });
                                    } else {
                                        setTimeout(() => {
                                            imageExists(url, (exists) => {
                                                if (exists) {
                                                    dispatch({
                                                        type: ProfileTypes.GETTING_PUBLIC_COMPANY_IMAGE_SUCCESS,
                                                        payload: url
                                                    });
                                                }
                                            });
                                        }, 5000);
                                    }
                                });
                            }, 1000);
                        }
                    });
                } else {
                    dispatch({type: ProfileTypes.GETTING_PUBLIC_COMPANY_IMAGE_FAIL});
                }
            } else {
                dispatch({type: ProfileTypes.GETTING_PUBLIC_COMPANY_IMAGE_FAIL});
            }
        }
    }
}

export function removePublicCompanyImage() {
    return function (dispatch) {
        dispatch({type: ProfileTypes.REMOVE_PUBLIC_COMPANY_IMAGE_SUCCESS})
    }
}

export function getEmployeePublicProfileImagesList(list, key, action, cancelToken) {
    /*
    * @params:list - data list
    * @params:key - field contains image name
    * @params:action - action to dispatch after getting urls
    *
    * this function accepts a data list, replaces image names with image links and dispatches a given action with modified list as payload
    */
    return function (dispatch) {
        let count = 0, completed = 0;
        list.forEach((item, i) => {
            if (item.USER_DETAILS[key] && !isURL(item.USER_DETAILS[key], {protocols: ['https']}, SIGNED_COOKIES_CF_URL)) {
                count++;
                const url = getCFObjURL(BUCKET.COMMON)(item.USER_DETAILS[key], true);
                if (url) {
                    if (isURL(url, {protocols: ['https']}, SIGNED_COOKIES_CF_URL) && item.USER_DETAILS[key]) {
                        list[i].USER_DETAILS[key] = url;
                    }
                }
                completed++;
                if (completed === count) {
                    dispatch({type: action, payload: list});
                }
            } else {
                count++;
                completed++;
                if (completed === count) {
                    dispatch({type: action, payload: list});
                }
            }
        })
    }
}

export function getPublicProfileImagesList(list, key, action, size = 'small-', cancelToken) {
    /*
    * @params:list - data list
    * @params:key - field contains image name
    * @params:action - action to dispatch after getting urls
    *
    * this function accepts a data list, replaces image names with image links and dispatches a given action with modified list as payload
    */

    return function (dispatch) {
        let count = 0, completed = 0;
        list.forEach((item, i) => {
            if (item[key]) {
                list[i].IMAGE_NAME = list[i][key];
                count++;

                const url = getCFObjURL(BUCKET.COMMON)((size + item[key]), true);
                if (url) {
                    if (url && isURL(url, {protocols: ['https']}, SIGNED_COOKIES_CF_URL) && item[key]) {
                        imageExists(url, (exists) => {
                            if (exists) {
                                list[i][key] = url;
                            } else {
                                setTimeout(() => {
                                    imageExists(url, (exists) => {
                                        if (exists) {
                                            list[i][key] = url;
                                        }
                                    })
                                }, 5000)
                            }
                        })
                    }
                }
            } else {
                count++;
                completed++;
                if (completed === count) {
                    dispatch({type: action, payload: list});
                }
            }
        })
    }
}

export function fetchAndUpdateImages(data) {
    return function (dispatch, getState) {
        if (data && data.length > 0) {
            let count = data.length;
            let images = {};
            let cacheImages = {};
            data.forEach(item => {
                if (!item.PROFILE_PHOTO || item.PROFILE_PHOTO === '') {
                    images[item._id] = '';
                    if (Object.keys(images).length === count) {
                        dispatch({type: SET_RECOMMENDED_CONNECTIONS_IMAGES, payload: images});
                    }
                } else {
                    const {images_L} = (getState().images);
                    if (images_L[item.PROFILE_PHOTO]) {
                        images[item._id] = images_L[item.PROFILE_PHOTO];
                        if (Object.keys(images).length === count) {
                            dispatch({type: SET_RECOMMENDED_CONNECTIONS_IMAGES, payload: images});
                        }
                    } else {
                        const img = getCFObjURL(BUCKET.COMMON)(item.PROFILE_PHOTO, true);
                        if (img) {
                            images[item._id] = img;
                            cacheImages[item.PROFILE_PHOTO] = img;
                            if (Object.keys(images).length === count) {
                                dispatch({type: SET_RECOMMENDED_CONNECTIONS_IMAGES, payload: images});
                                dispatch({type: SET_IMAGES, payload: cacheImages})
                            }
                        } else {
                            images[item._id] = '';
                            if (Object.keys(images).length === count) {
                                dispatch({type: SET_RECOMMENDED_CONNECTIONS_IMAGES, payload: images});
                                dispatch({type: SET_IMAGES, payload: cacheImages})
                            }
                        }
                    }
                }
            })
        }
    }
}

export function addConnection(uid, msg = '', skip = 0, isFromRecommended = null, socketInfo = null) {
    return function (dispatch, getState) {
        let cachedData = {};
        if (isFromRecommended) {
            cachedData = {
                cacheIndex: isFromRecommended.cacheIndex,
                pageNo: isFromRecommended.pageNo,
                includeResults: true
            }
        } else {
            cachedData = {cacheIndex: null, pageNo: null}
        }
        let data = {"userId": uid, "text": msg, "skipConnections": skip, ...cachedData};
        post(`${ROUTES.ADD_CONNECTION}`, data)
            .then(async response => {
                if (response.data.code === 200) {
                    let payload = {id: uid, status: true};
                    dispatch({type: ProfileTypes.ADD_CONNECTION_SUCCESS, payload: payload, skip: skip, requestId: uid});

                    // emit socket
                    if (socketInfo && socketInfo.socket && socketInfo.userId && socketInfo.type && response.data.data.length) {
                        const conversationId = (msg !== '' && response.data.data[0].CONVERSATION) ? response.data.data[0].CONVERSATION._id : null;
                        let conversationData = (msg !== '' && response.data.data[0].CONVERSATION) ? response.data.data[0].CONVERSATION : null;
                        let connectionData = response.data.data[0];
                        delete connectionData.CONVERSATION

                        //update conversation if connection request has message
                        if (msg !== '') {
                            dispatch(getChatConversations({
                                username: '',
                                module: 0,
                                skip: 0,
                                limit: 16
                            }));
                        }

                        // export const notifyReceiver = (socket, userId, type, data, conversationId, msgData)
                        if (socketInfo.type === NOTIFICATION_TYPES.CONNECTION_REQUESTS) {
                            notifyReceiver(socketInfo.socket, socketInfo.userId, socketInfo.type, connectionData, conversationId, null);
                        } else {
                            notifyReceiver(socketInfo.socket, socketInfo.userId, NOTIFICATION_TYPES.CONNECTION_REQUESTS, connectionData, conversationId, null);

                            const user = getState().auth.user;
                            conversationData.RECIPENT = [
                                {
                                    SYSTEM_USER_ID: user._id,
                                    LOWER_NAME: (user.FIRST_NAME + ' ' + user.LAST_NAME).toLowerCase(),
                                    NAME: user.FIRST_NAME + ' ' + user.LAST_NAME,
                                    FIRST_NAME: user.FIRST_NAME,
                                    LAST_NAME: user.LAST_NAME,
                                    MEMBER_TYPE: user.MEMBER_TYPE,
                                    PHOTO: user.PHOTO,
                                    STATUS: 1
                                }
                            ];

                            notifyReceiver(socketInfo.socket, socketInfo.userId, NOTIFICATION_TYPES.CONNECTIONS_MSG, conversationData, conversationId, null);
                        }

                    } else {
                        console.error('socket connection failed, invalid data', {
                            userId: socketInfo.userId,
                            type: socketInfo.type
                        })
                    }
                    // get connections
                    if (response.data.data && response.data.data[0] && response.data.data[0].updatedResults && response.data.data[0].updatedResults.length > 0 && isFromRecommended) {
                        dispatch({
                            type: REPLACE_RECOMMONDED_CONNECTIONS,
                            payload: response.data.data[0].updatedResults,
                            skip
                        });
                        dispatch({type: CLEAR_ACTION});
                        dispatch(fetchAndUpdateImages(response.data.data[0].updatedResults));
                    } else if (isFromRecommended && isFromRecommended.pageNo) { //if failed to get updated connections
                        let filter = {
                            skip: isFromRecommended.pageNo <= 2 ? 0 : (isFromRecommended.pageNo - 2) * FILTER_SEARCH_LIMIT,
                            limit: isFromRecommended.pageNo === 1 ? 0 : FILTER_SEARCH_LIMIT
                        };
                        post(`${ROUTES.GET_SUGGESTIONS}`, filter)
                            .then(res => {
                                if (res.data.code === 200) {
                                    dispatch({type: REPLACE_RECOMMONDED_CONNECTIONS, payload: res.data.data, skip});
                                    dispatch({type: CLEAR_ACTION});
                                    dispatch(fetchAndUpdateImages(res.data.data));
                                } else {
                                    dispatch({type: REPLACE_RECOMMONDED_CONNECTIONS_FAIL});
                                    dispatch({type: CLEAR_ACTION});
                                }
                            })
                            .catch(err => {
                                dispatch({type: REPLACE_RECOMMONDED_CONNECTIONS_FAIL});
                                dispatch({type: CLEAR_ACTION});
                            })
                    } else {
                        dispatch({type: REPLACE_RECOMMONDED_CONNECTIONS_FAIL});
                        dispatch({type: CLEAR_ACTION});
                    }

                } else {
                    if(response.data && response.data.code === 221){
                        let event = new CustomEvent("maxDailyConnectionLimit", {});
                        document.dispatchEvent(event);
                    } else if(response.data && response.data.code === 217){
                        showPopupAlert(dispatch, POP_ALERT.OK, '', BLOCKUSER_CONNECT_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
                    } else {
                        showPopupAlert(dispatch, POP_ALERT.ERROR, response.data.message);
                    }
                    let payload = {id: uid, status: false};
                    await dispatch({type: ProfileTypes.ADD_CONNECTION_FAIL, payload: payload})

                    notifyConnectionRequestFail(uid)
                    await dispatch({type: ProfileTypes.CLEAR_ACTION})
                }
            })
            .catch((error) => {
                let payload = {id: uid, status: false};
                dispatch({type: ProfileTypes.ADD_CONNECTION_FAIL, payload: payload});
                notifyConnectionRequestFail(uid)
                if (isFromRecommended) {
                    dispatch({type: REPLACE_RECOMMONDED_CONNECTIONS_FAIL});
                    dispatch({type: CLEAR_ACTION});
                }
            })
    }
}

export function clearConnectionAction() {
    return function (dispatch) {
        dispatch({type: ProfileTypes.CLEAR_CONNECTION_ACTION})
    }
}


export const notifyUserAccountDeletion = async (userId) => {
    let user = await getIndividualUser(userId)
    if (!user.success) {
        let event = new CustomEvent("connectUserDeleted", {detail: {userId}});
        setTimeout(() => {
            document.dispatchEvent(event);
        }, 500)
        return false
    } else {
        return true
    }
}

export function acceptConnection(ruid, socket = null, errorCallback = null, connectionCount=null) {
    return function (dispatch) {
        
        post(`${ROUTES.ACCEPT_CONNECTION}`, {"requesterUserId": ruid}).then(async response => {
            if (response.data.code === 200) {
                let payload = {id: ruid, status: true};
                dispatch({type: ProfileTypes.ACCEPT_CONNECTION_SUCCESS, payload: payload});
                dispatch({type: DECREMENT_PENDING_CONNECTION, userId: ruid});
                dispatch(getPendingConnectionCount());
                dispatch(getConnectionsCount());

                if(connectionCount !== null){
                        dispatch({type: ProfileTypes.UPDATE_CONNECTION_COUNT_IN_USER_PROFILE, count: connectionCount, change:1})
                }

                // chat reset
                refeshChat(dispatch, ruid);
                setTimeout(() => {
                    dispatch(getLeftPanelCoversationCounts())
                }, 4000)
                if (response.data && response.data.data && response.data.data.conversationId) {
                    dispatch({type: ADD_LAST_ACCEPTED_USER_MESSAGE, conversationId: response.data.data.conversationId})
                }

                // Notify Reaction to requester
                if (socket) {
                    let socketData = {
                        type: 'ACCEPT',
                        requester: ruid,
                        recipient: localStorage.getItem('userId'),
                        newConversationId: (response.data && response.data.data && response.data.data.conversationId) ? response.data.data.conversationId : null
                    };
                    notifyReceiver(socket, ruid, NOTIFICATION_TYPES.REACT_CONNECTION_REQUEST, socketData)
                } else {
                    console.error('socket error: invalid data')
                }
            } else if (response.data.code === 217) {
                let payload = {id: ruid, status: false};
                dispatch({type: ProfileTypes.ACCEPT_CONNECTION_FAIL, payload: payload})
                showPopupAlert(dispatch, POP_ALERT.OK, '', BLOCKUSER_ACCEPT_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
                if (errorCallback) {
                    errorCallback(false, true)
                }
            } else {
                let payload = {id: ruid, status: false};
                dispatch({type: ProfileTypes.ACCEPT_CONNECTION_FAIL, payload: payload})
                let userStatus = await notifyUserAccountDeletion(ruid)
                if (errorCallback) {
                    errorCallback(userStatus, false)
                }
            }
        })
            .catch((error) => {
                let payload = {id: ruid, status: false};
                dispatch({type: ProfileTypes.ACCEPT_CONNECTION_FAIL, payload: payload})
            })
    }
}

export function rejectConnection(rjuid, type, conCount, conLength, socketInfo = null, rejectType = CONNECTION_REJECT_TYPES.REJECT_REQUEST, connectionCount=null) {
    return function (dispatch) {
        post(`${ROUTES.REJECT_CONNECTION}`, {"rejectUserId": rjuid, "requestType": type, rejectType})
            .then(response => {
                if (response.data.code === 200) {
                    let payload = {id: rjuid, status: true};
                    dispatch({type: ProfileTypes.REJECT_CONNECTION_SUCCESS, payload: payload})
                    dispatch({type: DECREMENT_PENDING_CONNECTION, userId: rjuid});
                    dispatch({type: REJECT_MY_CONNECTION, payload: rjuid})
                    dispatch(getPendingConnectionCount());
                    dispatch(getConnectionsCount());
                    dispatch(reloadUserNotificationCounters());

                    if (conCount > conLength) {
                        const filter = {
                            skip: conLength,
                            limit: 1,
                            memberType: 6
                        }
                        dispatch(fetchConnections(filter));
                    }
                    if (socketInfo && socketInfo.socket && socketInfo.recipientUserId && socketInfo.type) {
                        notifyReceiver(socketInfo.socket, socketInfo.recipientUserId, socketInfo.type, {requesterUserId: localStorage.getItem('userId')}, null, null);

                        // Notify Reaction to requester
                        let socketData = {
                            type: 'REJECT',
                            requester: socketInfo.recipientUserId,
                            recipient: localStorage.getItem('userId'),
                            newConversationId: null,
                            deletedConversation: (response.data.data && response.data.data.deletedConversationId) ? response.data.data.deletedConversationId : null
                        };
                        notifyReceiver(socketInfo.socket, socketInfo.recipientUserId, NOTIFICATION_TYPES.REACT_CONNECTION_REQUEST, socketData)
                    }

                    if (rejectType === CONNECTION_REJECT_TYPES.DISCONNECT && connectionCount !== null && connectionCount !==0) {
                        dispatch({ type: ProfileTypes.UPDATE_CONNECTION_COUNT_IN_USER_PROFILE, count: connectionCount, change: -1 })
                    }

                }  else if (response.data.code === 217) {
                    showPopupAlert(dispatch, POP_ALERT.OK, '', BLOCKUSER_REJECT_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
                } else {
                    let payload = {id: rjuid, status: false};
                    dispatch({type: ProfileTypes.REJECT_CONNECTION_SUCCESS, payload: payload})
                }
            })
            .catch((error) => {
                let payload = {id: rjuid, status: false};
                dispatch({type: ProfileTypes.REJECT_CONNECTION_SUCCESS, payload: payload})
            })
    }
}

export function getRecentPosts(uid, skip = 0, limit = 5, cancelToken) {
    return function (dispatch) {
        dispatch({type: ProfileTypes.FETCH_LAST_POSTS});
        get(`${ROUTES.USER_RECENT_POST}${uid}/${skip}/${limit}`, {cancelToken})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: ProfileTypes.FETCH_LAST_POSTS_SUCCESS, payload: response.data.data});
                } else {
                    dispatch({type: ProfileTypes.FETCH_LAST_POSTS_FAIL});
                }
            })
            .catch((err) => {
                if (axios.isCancel(err)) {
                    return;
                } else {
                    dispatch({type: ProfileTypes.FETCH_LAST_POSTS_FAIL});
                }
            })
    }
}

export async function getRecentPostsCount(uid) {
    try {
        const response = await get(`${ROUTES.USER_RECENT_POST_COUNT}${uid}`)
        if (response && response.data.code === 200) {
            return {success: true, data: response.data.data}
        } else {
            return {success: false, data: 0}
        }
    } catch (exception) {
        return {success: false, data: 0}
    }
}

export function clearRecentPosts() {
    return function (dispatch) {
        dispatch({type: ProfileTypes.CLEAR_LAST_POSTS})
    }
}

export function selectSubMenu(selectedMenu) {
    return function (dispatch) {
        dispatch({type: CHANGE_SUB_MENU, payload: selectedMenu});
    }
}

export function removeRequestedConnections() {
    return function (dispatch) {
        dispatch({type: ProfileTypes.CLEAR_REQUESTED_CONNECTIONS});
    }
}

export const clearFundRaisingInformation = () => dispatch => dispatch({type: ProfileTypes.CLEAR_FUNDRAISING_DETAILS})

export const getUserId = async (details) => {

    try {
        const {data} = await ApiConnector.post(ROUTES.VANITY_USERID, details);
        return {
            data: data.data ? data.data : {},
            message: data.message,
            success: data.code === 200
        };
    } catch (e) {
        return {
            success: false
        };
    }
};

export function createVanityUrl(details) {
    return async function (dispatch) {
        dispatch({type: ProfileTypes.CREATE_VANITY_URL});
        try {
            const res = await ApiConnector.post(ROUTES.VANITY_CREATE_URL, details);
            if (res && res.data && res.data.code === 200) {
                dispatch({type: ProfileTypes.CREATE_VANITY_URL_SUCCESS, payload: details});
            } else {
                console.error('Create url Failed');
                showAlert(dispatch, ALERT.ERROR, ProfileTypes.VANITY_URL_EXIST);
            }
        } catch (ex) {
            console.error(ex);
        }
    }
}

export function blockUser(blockedUserId, location, currentUrl) {
    return async function (dispatch) {
        try {
            const res = await ApiConnector.get(`${ROUTES.BLOCKUSER}/${blockedUserId}`);
            if (res && res.data && res.data.code === 200) {
                if(location === 'POST' && !currentUrl.includes('/feed/')) {
                    dispatch({ type: BLOCK_USER_SUCCESS, payload: {blockedUserId} });
                }
                showPopupAlert(dispatch, POP_ALERT.SUCCESS, BLOCKUSER_SUCCESS);
            } else {
                if (res && res.data && res.data.code === 217) {
                    showPopupAlert(dispatch, POP_ALERT.ERROR, BLOCKUSER_ALREADY);
                } else {
                    showPopupAlert(dispatch, POP_ALERT.ERROR, BLOCKUSER_FAIL);
                }
            }
        } catch (ex) {
            console.error(ex);
            showPopupAlert(dispatch, POP_ALERT.ERROR, BLOCKUSER_FAIL);
        }
    }
}

export const checkBlockStatus = async (userID) => {
    try {
        if(userID !== '0') {
            const {data} = await ApiConnector.get(`${ROUTES.BLOCKUSERSTATUS}/${userID}`);
            return {success: data.code === 200};
        } else {
            return {success: false};
        }
    } catch (e) {
        return {success: false};
    }
}
