import ContentLoader from "react-content-loader";
import React from "react";

const News = props => (
    <ContentLoader
        rtl
        height={300}
        width={400}
        speed={2}
        {...props}
    >
        <circle cx="30" cy="30" r="30" />
        <rect x="70" y="15" rx="4" ry="4" width="200" height="6.4" />
        <rect x="370" y="15" rx="4" ry="4" width="30" height="6.4" />
        <rect x="70" y="30" rx="3" ry="3" width="120" height="6.4" />
        <rect x="360" y="30" rx="4" ry="4" width="40" height="6.4" />
        {/* <rect x="0" y="80" rx="3" ry="3" width="350" height="10" />  */}
        <rect x="0" y="70" rx="3" ry="3" width="350" height="6.4" />
        <rect x="0" y="85" rx="3" ry="3" width="360" height="6.4" />
        <rect x="0" y="100" rx="3" ry="3" width="380" height="6.4" />
        <rect x="0" y="115" rx="3" ry="3" width="380" height="6.4" />
        <rect x="0" y="130" rx="3" ry="3" width="400" height="200" />
    </ContentLoader>
);

const GroupBanner = props => (
    <ContentLoader
        rtl
        height={50}
        width={650}
        speed={2}
        {...props}
    >

        <div className="feed-blinder"></div>
        <rect x="0" y="0" rx="3" ry="3" width="650" height="50" />
    </ContentLoader>
);

const SinglePost = props => (
    <ContentLoader
        rtl
        height={450}
        width={400}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
    >
        <rect x="10" y="18" rx="0" ry="0" width="105" height="89" />
        <rect x="150" y="61" rx="0" ry="0" width="68" height="8" />
        <rect x="229" y="62" rx="0" ry="0" width="99" height="7" />
        <rect x="12" y="127" rx="0" ry="0" width="375" height="7" />
        <rect x="12" y="141" rx="0" ry="0" width="375" height="7" />
        <rect x="12" y="155" rx="0" ry="0" width="375" height="7" />
        <rect x="13" y="169" rx="0" ry="0" width="375" height="7" />
        <rect x="13" y="185" rx="0" ry="0" width="281" height="5" />
        <rect x="14" y="207" rx="0" ry="0" width="377" height="57" />
        <rect x="69" y="282" rx="0" ry="0" width="72" height="16" />
        <rect x="262" y="281" rx="0" ry="0" width="72" height="16" />
        <rect x="17" y="310" rx="0" ry="0" width="80" height="7" />
        <rect x="20" y="335" rx="0" ry="0" width="42" height="26" />
        <rect x="84" y="340" rx="0" ry="0" width="63" height="4" />
        <rect x="89" y="349" rx="0" ry="0" width="57" height="3" />
        <rect x="90" y="366" rx="0" ry="0" width="156" height="7" />
        <rect x="306" y="345" rx="0" ry="0" width="84" height="6" />
    </ContentLoader>
);

const InboxConversation = props => (
    <ContentLoader
        rtl
        height={500}
        width={400}
        speed={2}
        {...props}
    >
        <rect x="20" y="10" rx="5" ry="5" width="40" height="40" />
        <rect x="70" y="15" rx="4" ry="4" width="200" height="8" />
        <rect x="70" y="30" rx="3" ry="3" width="120" height="6.4" />
        <rect x="0" y="60" rx="4" ry="4" width="400" height="1" />
        <rect x="20" y="70" rx="5" ry="5" width="40" height="40" />
        <rect x="70" y="75" rx="4" ry="4" width="200" height="8" />
        <rect x="70" y="90" rx="3" ry="3" width="120" height="6.4" />
        <rect x="0" y="120" rx="4" ry="4" width="400" height="1" />
        <rect x="20" y="130" rx="5" ry="5" width="40" height="40" />
        <rect x="70" y="135" rx="4" ry="4" width="200" height="8" />
        <rect x="70" y="150" rx="3" ry="3" width="120" height="6.4" />
        <rect x="0" y="180" rx="4" ry="4" width="400" height="1" />

        <rect x="20" y="190" rx="5" ry="5" width="40" height="40" />
        <rect x="70" y="195" rx="4" ry="4" width="200" height="8" />
        <rect x="70" y="210" rx="3" ry="3" width="120" height="6.4" />
        <rect x="0" y="240" rx="4" ry="4" width="400" height="1" />

        <rect x="20" y="250" rx="5" ry="5" width="40" height="40" />
        <rect x="70" y="255" rx="4" ry="4" width="200" height="8" />
        <rect x="70" y="270" rx="3" ry="3" width="120" height="6.4" />
        <rect x="0" y="300" rx="4" ry="4" width="400" height="1" />

        <rect x="20" y="310" rx="5" ry="5" width="40" height="40" />
        <rect x="70" y="315" rx="4" ry="4" width="200" height="8" />
        <rect x="70" y="330" rx="3" ry="3" width="120" height="6.4" />
        <rect x="0" y="360" rx="4" ry="4" width="400" height="1" />

        <rect x="20" y="370" rx="5" ry="5" width="40" height="40" />
        <rect x="70" y="375" rx="4" ry="4" width="200" height="8" />
        <rect x="70" y="390" rx="3" ry="3" width="120" height="6.4" />
        <rect x="0" y="430" rx="4" ry="4" width="400" height="1" />
    </ContentLoader>
);

const ChatConversation = props => (
    <ContentLoader
        rtl
        height={500}
        width={400}
        speed={2}
        {...props}
    >
        <rect x="20" y="10" rx="25" ry="25" width="45" height="45" />
        <rect x="75" y="15" rx="4" ry="4" width="200" height="8" />
        <rect x="75" y="30" rx="3" ry="3" width="120" height="6.4" />
        <rect x="0" y="60" rx="4" ry="4" width="400" height="1" />
        <rect x="20" y="70" rx="25" ry="25" width="45" height="45" />
        <rect x="75" y="75" rx="4" ry="4" width="200" height="8" />
        <rect x="75" y="90" rx="3" ry="3" width="120" height="6.4" />
        <rect x="0" y="120" rx="4" ry="4" width="400" height="1" />
        <rect x="20" y="130" rx="25" ry="25" width="45" height="45" />
        <rect x="75" y="135" rx="4" ry="4" width="200" height="8" />
        <rect x="75" y="150" rx="3" ry="3" width="120" height="6.4" />
        <rect x="0" y="180" rx="4" ry="4" width="400" height="1" />

        <rect x="20" y="190" rx="25" ry="25" width="45" height="45" />
        <rect x="75" y="195" rx="4" ry="4" width="200" height="8" />
        <rect x="75" y="210" rx="3" ry="3" width="120" height="6.4" />
        <rect x="0" y="240" rx="4" ry="4" width="400" height="1" />

        <rect x="20" y="250" rx="25" ry="25" width="45" height="45" />
        <rect x="75" y="255" rx="4" ry="4" width="200" height="8" />
        <rect x="75" y="270" rx="3" ry="3" width="120" height="6.4" />
        <rect x="0" y="300" rx="4" ry="4" width="400" height="1" />

        <rect x="20" y="310" rx="25" ry="25" width="45" height="45" />
        <rect x="75" y="315" rx="4" ry="4" width="200" height="8" />
        <rect x="75" y="330" rx="3" ry="3" width="120" height="6.4" />
        <rect x="0" y="360" rx="4" ry="4" width="400" height="1" />

        <rect x="20" y="370" rx="25" ry="25" width="45" height="45" />
        <rect x="75" y="375" rx="4" ry="4" width="200" height="8" />
        <rect x="75" y="390" rx="3" ry="3" width="120" height="6.4" />
        <rect x="0" y="430" rx="4" ry="4" width="400" height="1" />

        <rect x="20" y="440" rx="25" ry="25" width="45" height="45" />
        <rect x="75" y="445" rx="4" ry="4" width="200" height="8" />
        <rect x="75" y="460" rx="3" ry="3" width="120" height="6.4" />
        <rect x="0" y="510" rx="4" ry="4" width="400" height="1" />

    </ContentLoader>
);

const InboxMessage = props => (
    <ContentLoader
        rtl
        height={130}
        width={400}
        speed={2}
        {...props}
    >
        <rect x="10" y="10" rx="5" ry="5" width="20" height="20" />
        <rect x="34" y="14" rx="4" ry="4" width="200" height="5" />
        <rect x="34" y="22" rx="3" ry="3" width="120" height="5" />

        <rect x="10" y="40" rx="5" ry="5" width="20" height="20" />
        <rect x="34" y="45" rx="4" ry="4" width="140" height="5" />
        <rect x="34" y="53" rx="3" ry="3" width="160" height="5" />

        <rect x="10" y="71" rx="5" ry="5" width="20" height="20" />
        <rect x="34" y="75" rx="4" ry="4" width="200" height="5" />
        <rect x="34" y="83" rx="3" ry="3" width="120" height="5" />

        <rect x="10" y="101" rx="5" ry="5" width="20" height="20" />
        <rect x="34" y="106" rx="4" ry="4" width="140" height="5" />
        <rect x="34" y="115" rx="3" ry="3" width="160" height="5" />

    </ContentLoader>
);

const ConnectionRecommonds = props => (
    <ContentLoader
        rtl
        height={360}
        width={400}
        speed={2}
        {...props}
    >
        <rect x="40" y="5" rx="5" ry="5" width="250" height="20" />
        <rect x="0" y="45" r="8" width="60" height="60" />
        <rect x="70" y="45" r="8" width="220" height="20" />
        <rect x="70" y="75" r="8" width="180" height="15" />
        {/* <circle cx="350" cy="68" r="25" /> */}
        <rect x="0" y="125" r="8" width="60" height="60" />
        <rect x="70" y="125" r="8" width="220" height="20" />
        <rect x="70" y="155" r="8" width="180" height="15" />
        {/* <circle cx="350" cy="145" r="25" /> */}
        <rect x="0" y="205" r="8" width="60" height="60" />
        <rect x="70" y="205" r="8" width="220" height="20" />
        <rect x="70" y="235" r="8" width="180" height="15" />
        {/* <circle cx="350" cy="225" r="25" /> */}
        <rect x="40" y="310" r="8" width="300" height="40" />

    </ContentLoader>
)

const GroupsMobile = props => (
    <ContentLoader
        rtl
        height={230}
        width={400}
        speed={2}
        {...props}
    >
        <rect x="100" y="10" r="8" width="200" height="20" />

        <rect x="10" y="50" r="8" width="75" height="20" />
        <rect x="98" y="50" r="8" width="200" height="20" />

        <rect x="10" y="80" r="8" width="75" height="20" />
        <rect x="98" y="80" r="8" width="250" height="20" />

        <rect x="10" y="110" r="8" width="75" height="20" />
        <rect x="98" y="110" r="8" width="210" height="20" />

        <rect x="10" y="140" r="8" width="75" height="20" />
        <rect x="98" y="140" r="8" width="260" height="20" />

        <rect x="10" y="170" r="8" width="75" height="20" />
        <rect x="98" y="170" r="8" width="210" height="20" />

        <rect x="10" y="200" r="8" width="75" height="20" />
        <rect x="98" y="200" r="8" width="150" height="20" />

    </ContentLoader>
)

const LikedUsersForPost = props => (
    <ContentLoader
        rtl
        height={360}
        width={400}
        speed={2}
        {...props}
    >
        <rect x="53" y="18" rx="5" ry="5" width="220" height="10" />
        <rect x="6.84" y="12.67" rx="0" ry="0" width="36" height="36" />
        <rect x="54" y="34" rx="5" ry="5" width="169.4" height="7.7" />
        <rect x="326.84" y="19.67" rx="0" ry="0" width="48.98" height="23.36" />
        <rect x="6.84" y="56.67" rx="0" ry="0" width="36" height="36" />
        <rect x="6.84" y="100.67" rx="0" ry="0" width="36" height="36" />
        <rect x="51" y="58" rx="5" ry="5" width="220" height="10" />
        <rect x="51" y="102" rx="5" ry="5" width="220" height="10" />
        <rect x="50" y="74" rx="5" ry="5" width="169.4" height="7.7" />
        <rect x="50" y="115" rx="5" ry="5" width="169.4" height="7.7" />
        <rect x="325.84" y="56.67" rx="0" ry="0" width="48.98" height="23.36" />
        <rect x="325.84" y="99.67" rx="0" ry="0" width="48.98" height="23.36" />

    </ContentLoader>
)

const FetchingPopover = props => (
    <ContentLoader
        height={155}
        width={400}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
    >
        <rect x="138.01" y="101.94" rx="4" ry="4" width="103" height="13.26" />
        <circle cx="29.84" cy="46.67" r="1" />
        <rect x="32.47" y="72.98" rx="0" ry="0" width="91.67" height="75.48" />
        <rect x="135.78" y="124.09" rx="0" ry="0" width="112.27" height="26.52" />
        <rect x="262.84" y="123.07" rx="0" ry="0" width="107.12" height="27.54" />
        <rect x="12.84" y="12.67" rx="0" ry="0" width="373.89" height="78.54" />
    </ContentLoader>
)

const ProfileInfoCard = props => (
    <ContentLoader
        rtl
        height={400}
        width={259}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
    >
        <rect x="10" y="6" rx="0" ry="0" width="259" height="197" />
        <rect x="15" y="217" rx="0" ry="0" width="139" height="9" />
        <rect x="197" y="208" rx="0" ry="0" width="72" height="34" />
        <rect x="17" y="242" rx="0" ry="0" width="68" height="5" />
        <rect x="102" y="242" rx="0" ry="0" width="82" height="4" />
        <rect x="13" y="259" rx="0" ry="0" width="72" height="6" />
        <rect x="102" y="259" rx="0" ry="0" width="60" height="5" />
        <rect x="178" y="258" rx="0" ry="0" width="72" height="6" />
        <rect x="12" y="277" rx="0" ry="0" width="265" height="4" />
        <rect x="11" y="296" rx="0" ry="0" width="105" height="25" />
        <rect x="30" y="404" rx="0" ry="0" width="105" height="25" />
        <rect x="33" y="439" rx="0" ry="0" width="83" height="12" />
        <rect x="12" y="330" rx="0" ry="0" width="88" height="9" />
        <rect x="11" y="345" rx="0" ry="0" width="105" height="25" />
        <rect x="12" y="378" rx="0" ry="0" width="85" height="6" />
    </ContentLoader>
)

const ConnectionRecommond = props => (
    <ContentLoader
        rtl
        height={95}
        width={400}
        speed={2}
        {...props}
    >
        <rect x="0" y="45" r="8" width="60" height="60" />
        <rect x="70" y="45" r="8" width="220" height="20" />
        <rect x="70" y="75" r="8" width="180" height="15" />
        <circle cx="350" cy="68" r="25" />

    </ContentLoader>
)

const LoadingMutualGroupMembers = props => (
    <ContentLoader
        rtl
        speed={2}
        width={500}
        height={20}
        {...props}
    >
        <rect x="1" y="1" rx="0" ry="0" width="490" height="20" />
    </ContentLoader>
)

const JobsWidgetLoader = props => (
    <ContentLoader
        rtl
        height={330}
        width={400}
        speed={2}
        {...props}
    >
        <rect x="9" y="53" rx="0" ry="0" width="208" height="11" />
        <rect x="8" y="76" rx="0" ry="0" width="283" height="12" />
        <rect x="8" y="100" rx="0" ry="0" width="597" height="12" />
        <rect x="8" y="9" rx="0" ry="0" width="46" height="25" />
        <rect x="89" y="12" rx="0" ry="0" width="238" height="21" />
        <rect x="10" y="145" rx="0" ry="0" width="208" height="11" />
        <rect x="8" y="168" rx="0" ry="0" width="283" height="12" />
        <rect x="8" y="192" rx="0" ry="0" width="597" height="12" />
        <rect x="8" y="234" rx="0" ry="0" width="208" height="11" />
        <rect x="8" y="257" rx="0" ry="0" width="283" height="12" />
        <rect x="8" y="281" rx="0" ry="0" width="597" height="12" />

    </ContentLoader>
)

const ProWidgetLoader = (props) => (
    <ContentLoader
        rtl
        height={160}
        width={400}
        speed={2}
        {...props}
    >
        <rect x="0" y="3" rx="0" ry="0" width="65" height="28" />
        <rect x="41" y="49" rx="0" ry="0" width="293" height="18" />
        <rect x="42" y="78" rx="0" ry="0" width="293" height="18" />
        <rect x="43" y="106" rx="0" ry="0" width="293" height="18" />
    </ContentLoader>
);

const ProWidgetLoaderMobile = (props) => (
    <ContentLoader
        rtl
        height={30}
        width={375}
        speed={2}
        {...props}
    >
        <rect x="0" y="0" rx="0" ry="0" width="375" height="30" />
    </ContentLoader>
);

const ProSentInvitationsLoader = (props) => (
    <ContentLoader 
    speed={2}
    width={640}
    height={150}
    rtl
    {...props}
  >
    <rect x="9" y="59" rx="0" ry="0" width="37" height="34" /> 
    <rect x="562" y="56" rx="0" ry="0" width="37" height="34" /> 
    <rect x="80" y="12" rx="0" ry="0" width="93" height="88" /> 
    <rect x="79" y="110" rx="0" ry="0" width="95" height="25" /> 
    <rect x="199" y="11" rx="0" ry="0" width="93" height="88" /> 
    <rect x="198" y="109" rx="0" ry="0" width="95" height="25" /> 
    <rect x="318" y="13" rx="0" ry="0" width="93" height="88" /> 
    <rect x="317" y="111" rx="0" ry="0" width="95" height="25" /> 
    <rect x="435" y="13" rx="0" ry="0" width="93" height="88" /> 
    <rect x="434" y="111" rx="0" ry="0" width="95" height="25" />
  </ContentLoader>
);

export {
    News,
    ConnectionRecommonds,
    LikedUsersForPost,
    FetchingPopover,
    InboxConversation,
    InboxMessage,
    ProfileInfoCard,
    ConnectionRecommond,
    SinglePost,
    GroupBanner,
    GroupsMobile,
    LoadingMutualGroupMembers,
    JobsWidgetLoader,
    ChatConversation,
    ProWidgetLoader,
    ProWidgetLoaderMobile,
    ProSentInvitationsLoader,
}