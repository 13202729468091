import React from "react";
import "./../index.css";

const MessageSearch = ({ children }) => {

    return (
        <div className="msg_left_search messageSearch_input">
            <div className="material_group">
                {children}
            </div>
        </div>
    )
}

export { MessageSearch };
