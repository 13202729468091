import React from "react";

const TabMenu = ({ children }) => {
        return (
            <ul className="nav nav-tabs">
                {children}
            </ul>
        );
    }

export { TabMenu };