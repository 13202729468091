import React from "react";
import classname from "classnames";
import "./../index.css";


const InputCover = ({ customClass = "", inputName, children, status, animate, type, icon, size, removeIcon, tags, onclick, faIcon, required, noTransform }) => {
  let bg = classname(`material_group ${customClass}`, {
    material_group_default: status === "default",
    material_group_error: status === "error",
    material_group_sucess: status === "sucess",
    material_group_white: type === "select",
    material_group_small: size === "small",
  });

  let lb = classname(
    "material_label",
    { material_label_small: size === "small" },
    animate === true ? "without_placeholder" : "with_placeholder"
  );

  let iconname = null;

  if (icon) {
    iconname = <span className="matirial_icon">{icon}</span>
  } else {
    iconname = null
  }

  const removeBtnStyles = {
    'position': 'absolute',
    'right': '35px',
    'top': '20px'
  }

  let removeIconName = null;

  if (removeIcon) {
    removeIconName = <span style={removeBtnStyles}>{removeIcon}</span>
  } else {
    removeIconName = null
  }

  let style = tags ? { "fontSize": "12px" } : {}

  return (
    <div className={bg} onClick={onclick}>
      {children}
      <p style={style} className={lb}>
      <span className="input-placeholder" style={ noTransform ? {textTransform: 'none'} : {} }>
          {faIcon && <i style={{ marginRight: '7px' }} className={faIcon}/>}
          {inputName}
          {required && <span className="required"> *</span>}
      </span>{iconname}{removeIconName}
      </p>
    </div>
  );
};

InputCover.defaultProps = {
  animate: true,
  type: "material_group_default",
  size: null
};

export { InputCover };
