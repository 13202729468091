import PreferencesTypes from '../types/accountPreferences';

export const initialState = {
    fetching: false,
    fetchingCommunicationPreferences: false,
    action: 0,
    accountPreferences: []
};

export const accountPreferences = (state = initialState, action) => {
    switch (action.type) {
        case PreferencesTypes.FETCHING_COMMUNICATION_PREFERENCES:
            return {
                ...state,
                fetchingCommunicationPreferences: true,
                action: PreferencesTypes.FETCHING_COMMUNICATION_PREFERENCES,
            };
        case PreferencesTypes.FETCHING_COMMUNICATION_PREFERENCES_SUCCESS:
            return {
                ...state,
                fetchingCommunicationPreferences: false,
                action: PreferencesTypes.FETCHING_COMMUNICATION_PREFERENCES_SUCCESS,
                accountPreferences: action.payload
            };
        case PreferencesTypes.FETCHING_COMMUNICATION_PREFERENCES_FAIL:
            return {
                ...state,
                fetchingCommunicationPreferences: false,
                action: PreferencesTypes.FETCHING_COMMUNICATION_PREFERENCES_FAIL
            };
        case PreferencesTypes.SETTING_COMMUNICATION_PREFERENCES:
            return {
                ...state,
                fetching: true,
                action: PreferencesTypes.SETTING_COMMUNICATION_PREFERENCES,
            };
        case PreferencesTypes.SETTING_COMMUNICATION_PREFERENCES_SUCCESS:
            return {
                ...state,
                fetching: true,
                action: PreferencesTypes.SETTING_COMMUNICATION_PREFERENCES_SUCCESS,
            };
        case PreferencesTypes.SETTING_COMMUNICATION_PREFERENCES_FAIL:
            return {
                ...state,
                fetching: true,
                action: PreferencesTypes.SETTING_COMMUNICATION_PREFERENCES_FAIL,
            };

        case PreferencesTypes.CLEAR_ACTION:
            return {
                ...state,
                action: 0
            };
        default:
            return state
    }
}