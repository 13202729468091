import marketPlaceTypes from '../types/marketPlace';
import {BILLING_FREQUENCY, MARKETPLACE_PACKAGE_TYPES } from './../types/common'

export const initialState = {
    fetching: false,
    fetchingMyProducts: false,
    error: {},
    action: 0,
    message: null,
    productTags: [],
    creatingProduct: false,
    uploadingProductShowcase: false,
    productCategories: [],
    overallProductCreateProcess: false,
    productDetails: {
        _id: null,
        SUB_CATEGORIES: [],
        PRODUCT_NAME: '',
        PRODUCT_COVER_IMAGE: null,
        PRODUCT_IMAGE_ONE: null,
        PRODUCT_IMAGE_TWO: null,
        PRODUCT_DESCRIPTION: '',
        STATUS: 2,
        RATINGS: [],
        COMPANY_ID: null,
        COMPANY_NAME: '',
        COMPANY_LOGO: '',
        CITY: '',
        STATE: '',
        COUNTRY: '',
        CREATOR: '',
        SUBSCRIPTION_ID: null,
        SUBSCRIPTION_STATUS: null
    },
    myProductsFilter: {},
    myProducts: [],
    myProductsCount: 0,
    isReachedEnd: false,
    widgetList: [],
    editingProduct: {},
    editingProductMedia: {},
    productNames: [],
    companies: [],
    AllProducts: [],
    AllProductsCount: 0,
    editingChangedTags: { added: [], removed: [] },
    publicProductLocations: {
        countries: [],
        states: [],
        cities: []
    },
    // marketplace new
    fetchingProductCategories: false,
    allProductCatagories: [],
    product: {
        name: '',
        description: '',
        subCategories: [],
        productImageOne: '',
        productImageTwo: '',
        productCover: '',
        companyId: '',
        creatorId: '',
        status: '',
        subscriptionId: '',
        subscriptionStatus: '',
        createdAt: '',
        updatedAt: '',
        productOneUpload: { progress: 0 },
        productTwoUpload: { progress: 0 },
        productThreeUpload: { progress: 0 }
    },
    isProductFetching: false,
    lastCreatedProduct: null,
    lastCreatedProductDetails: {},
    marketplaceReportingDashboardDetails: [],
    productPaymentDetails: {},
    fetchingProductPaymentDetails: false,
    fetchingProductPackageDetails: false,
    productPackages: []
};

export const marketPlace = (state = initialState, action) => {
    switch (action.type) {
        case marketPlaceTypes.FETCHING_TAGS:
            return {
                ...state,
                // fetching: true,
                action: action.type
            };
        case marketPlaceTypes.FETCHING_TAGS_SUCCESS:
            return {
                ...state,
                // fetching: false,
                action: action.type,
                productTags: action.payload
            };
        case marketPlaceTypes.FETCHING_TAGS_ERROR:
            return {
                ...state,
                // fetching: false,
                action: action.type
            };

        case marketPlaceTypes.EDITING_PRODUCT:
            return {
                ...state,
                action: action.type
            };
        case marketPlaceTypes.EDIT_PRODUCT_SUCCESS:
            return {
                ...state,
                action: action.type
            };
        case marketPlaceTypes.EDIT_PRODUCT_ERROR:
            return {
                ...state,
                action: action.type
            };

        case marketPlaceTypes.UPLOADING_PRODUCT_SHOWCASE:
            return {
                ...state,
                uploadingProductShowcase: true,
                action: action.type
            };
        case marketPlaceTypes.UPLOADING_PRODUCT_SHOWCASE_SUCCESS:
            return {
                ...state,
                uploadingProductShowcase: false,
                action: action.type
            };
        case marketPlaceTypes.UPLOADING_PRODUCT_SHOWCASE_ERROR:
            return {
                ...state,
                uploadingProductShowcase: false,
                action: action.type
            };
        case marketPlaceTypes.CREATING_PRODUCT_PROCESS:
            return {
                ...state,
                overallProductCreateProcess: action.payload
            };
        case marketPlaceTypes.CLEAR_MARKETPLACE_REDUCER:
            return {
                ...initialState
            };

        case marketPlaceTypes.FETCHING_MY_PRODUCTS:
            return {
                ...state,
                fetchingMyProducts: true,
                myProducts: action.isInit ? [] : state.myProducts,
                action: marketPlaceTypes.FETCHING_MY_PRODUCTS
            };
        case marketPlaceTypes.FETCHING_MY_PRODUCTS_SUCCESS:
            return {
                ...state,
                fetchingMyProducts: false,
                myProducts: action.isInit ? action.payload : state.myProducts.concat(action.payload),
                action: marketPlaceTypes.FETCHING_MY_PRODUCTS_SUCCESS,
                isReachedEnd: action.payload.length === 0
            };
        case marketPlaceTypes.FETCHING_MY_PRODUCTS_FAIL:
            return {
                ...state,
                fetchingMyProducts: false,
                action: marketPlaceTypes.FETCHING_MY_PRODUCTS_FAIL
            };

        case marketPlaceTypes.FETCHING_MY_PRODUCTS_COUNT:
            return {
                ...state,
                // fetching: true,
                action: marketPlaceTypes.FETCHING_MY_PRODUCTS_COUNT
            };
        case marketPlaceTypes.FETCHING_MY_PRODUCTS_COUNT_SUCCESS:
            return {
                ...state,
                // fetching: false,
                myProductsCount: action.payload,
                action: marketPlaceTypes.FETCHING_MY_PRODUCTS_COUNT_SUCCESS
            };
        case marketPlaceTypes.FETCHING_MY_PRODUCTS_COUNT_FAIL:
            return {
                ...state,
                // fetching: false,
                myProductsCount: 0,
                action: marketPlaceTypes.FETCHING_MY_PRODUCTS_COUNT_FAIL
            };
        case marketPlaceTypes.CREATING_PRODUCT_SUBSCRIPTION:
            return {
                ...state,
                action: marketPlaceTypes.CREATING_PRODUCT_SUBSCRIPTION
            };
        case marketPlaceTypes.CREATE_PRODUCT_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                action: marketPlaceTypes.CREATE_PRODUCT_SUBSCRIPTION_SUCCESS
            };
        case marketPlaceTypes.CREATE_PRODUCT_SUBSCRIPTION_FAIL:
            return {
                ...state,
                action: marketPlaceTypes.CREATE_PRODUCT_SUBSCRIPTION_FAIL
            };

        case marketPlaceTypes.CREATING_CUSTOMER:
            return {
                ...state,
                action: marketPlaceTypes.CREATING_CUSTOMER
            };
        case marketPlaceTypes.CREATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                action: marketPlaceTypes.CREATE_CUSTOMER_SUCCESS
            };
        case marketPlaceTypes.CREATE_CUSTOMER_FAIL:
            return {
                ...state,
                action: marketPlaceTypes.CREATE_CUSTOMER_FAIL
            };

        case marketPlaceTypes.DELETING_PRODUCT:
            return {
                ...state,
                action: marketPlaceTypes.DELETING_PRODUCT
            };
        case marketPlaceTypes.UNPUBLISH_PRODUCT_SUCCESS:
            return {
                ...state,
                action: marketPlaceTypes.UNPUBLISH_PRODUCT_SUCCESS
            }
        case marketPlaceTypes.UNPUBLISH_PRODUCT_FAIL:
            return {
                ...state,
                action: marketPlaceTypes.UNPUBLISH_PRODUCT_FAIL
            }
        case marketPlaceTypes.LIST_WIDGET_PRODUCTS:
            return {
                ...state,
                action: marketPlaceTypes.LIST_WIDGET_PRODUCTS
            };
        case marketPlaceTypes.LIST_WIDGET_PRODUCTS_SUCCESS:
            return {
                ...state,
                widgetList: action.payload.data,
                action: marketPlaceTypes.LIST_WIDGET_PRODUCTS_SUCCESS

            };
        case marketPlaceTypes.LIST_WIDGET_PRODUCTS_FAIL:
            return {
                ...state,
                action: marketPlaceTypes.LIST_WIDGET_PRODUCTS_FAIL
            };
        case marketPlaceTypes.CLEAR_WIDGET_PRODUCTS:
            return {
                ...state,
                widgetList: [],
                action: marketPlaceTypes.CLEAR_WIDGET_PRODUCTS

            };
        case marketPlaceTypes.CLEAR_ACTION:
            return {
                ...state,
                action: 0,
                // fetching: false,
                error: {}
            };
        case marketPlaceTypes.VIEW_SINGLE_PRODUCT:
            return {
                ...state,
                productDetails: {
                    _id: null,
                    SUB_CATEGORIES: [],
                    PRODUCT_NAME: '',
                    PRODUCT_COVER_IMAGE: null,
                    PRODUCT_IMAGE_ONE: null,
                    PRODUCT_IMAGE_TWO: null,
                    PRODUCT_DESCRIPTION: '',
                    STATUS: 2,
                    RATINGS: [],
                    COMPANY_ID: null,
                    COMPANY_NAME: '',
                    COMPANY_LOGO: '',
                    CITY: '',
                    STATE: '',
                    COUNTRY: '',
                    CREATOR: '',
                    SUBSCRIPTION_ID: null,
                    SUBSCRIPTION_STATUS: null
                },
                action: marketPlaceTypes.VIEW_SINGLE_PRODUCT
            };
        case marketPlaceTypes.VIEW_SINGLE_PRODUCT_SUCCESS:
            return {
                ...state,
                productDetails: action.payload.data,
                action: marketPlaceTypes.VIEW_SINGLE_PRODUCT_SUCCESS,
            };
        case marketPlaceTypes.VIEW_SINGLE_PRODUCT_FAIL:
            return {
                ...state,
                action: marketPlaceTypes.VIEW_SINGLE_PRODUCT_FAIL
            };
        case marketPlaceTypes.SINGLE_PRODUCT_COMPANY_IMAGE:
            return {
                ...state,
                productDetails: {
                    ...state.productDetails,
                    COMPANY_LOGO: action.payload
                },
                action: marketPlaceTypes.SINGLE_PRODUCT_COMPANY_IMAGE
            };
        case marketPlaceTypes.SINGLE_PRODUCT_COVER_IMAGE:
            return {
                ...state,
                productDetails: {
                    ...state.productDetails,
                    PRODUCT_COVER_IMAGE: action.payload
                },
                action: marketPlaceTypes.SINGLE_PRODUCT_COVER_IMAGE
            };
        case marketPlaceTypes.SINGLE_PRODUCT_IMAGE1:
            return {
                ...state,
                productDetails: {
                    ...state.productDetails,
                    PRODUCT_IMAGE_ONE: action.payload
                },
                action: marketPlaceTypes.SINGLE_PRODUCT_IMAGE1
            };
        case marketPlaceTypes.SINGLE_PRODUCT_IMAGE2:
            return {
                ...state,
                productDetails: {
                    ...state.productDetails,
                    PRODUCT_IMAGE_TWO: action.payload
                },
                action: marketPlaceTypes.SINGLE_PRODUCT_IMAGE2
            };
        
        case marketPlaceTypes.FETCHING_PRODUCT_NAME_SUGGESTIONS:
            return {
                ...state,
                action: marketPlaceTypes.FETCHING_PRODUCT_NAME_SUGGESTIONS
            };
        case marketPlaceTypes.FETCHING_PRODUCT_NAME_SUGGESTIONS_SUCCESS:
            return {
                ...state,
                productNames: action.payload,
                action: marketPlaceTypes.FETCHING_PRODUCT_NAME_SUGGESTIONS_SUCCESS
            };
        case marketPlaceTypes.FETCHING_PRODUCT_NAME_SUGGESTIONS_FAIL:
            return {
                ...state,
                productNames: [],
                action: marketPlaceTypes.FETCHING_PRODUCT_NAME_SUGGESTIONS_FAIL
            };
        case marketPlaceTypes.LISTING_COMPANIES:
            return {
                ...state,
                action: marketPlaceTypes.LISTING_COMPANIES
            };
        case marketPlaceTypes.LISTING_COMPANIES_SUCCESS:
            return {
                ...state,
                action: marketPlaceTypes.LISTING_COMPANIES_SUCCESS,
                companies: action.payload
            };
        case marketPlaceTypes.LISTING_COMPANIES_FAIL:
            return {
                ...state,
                action: marketPlaceTypes.LISTING_COMPANIES_FAIL,
                companies: []
            };

        case marketPlaceTypes.FETCHING_PUBLIC_PRODUCTS:
            return {
                ...state,
                fetching: true,
                AllProducts: action.init ? [] : state.AllProducts,
                action: marketPlaceTypes.FETCHING_PUBLIC_PRODUCTS
            };
        case marketPlaceTypes.FETCHING_PUBLIC_PRODUCTS_SUCCESS:
            return {
                ...state,
                fetching: false,
                AllProducts: action.init ? action.payload : state.AllProducts.concat(action.payload),
                action: marketPlaceTypes.FETCHING_PUBLIC_PRODUCTS_SUCCESS
            };
        case marketPlaceTypes.FETCHING_PUBLIC_PRODUCTS_FAIL:
            return {
                ...state,
                fetching: false,
                action: marketPlaceTypes.FETCHING_PUBLIC_PRODUCTS_FAIL
            };

        case marketPlaceTypes.FETCHING_PUBLIC_PRODUCTS_COUNT:
            return {
                ...state,
                action: marketPlaceTypes.FETCHING_PUBLIC_PRODUCTS_COUNT
            };
        case marketPlaceTypes.FETCHING_PUBLIC_PRODUCTS_COUNT_SUCCESS:
            return {
                ...state,
                AllProductsCount: action.payload,
                action: marketPlaceTypes.FETCHING_PUBLIC_PRODUCTS_COUNT_SUCCESS
            };
        case marketPlaceTypes.FETCHING_PUBLIC_PRODUCTS_COUNT_FAIL:
            return {
                ...state,
                AllProductsCount: 0,
                action: marketPlaceTypes.FETCHING_PUBLIC_PRODUCTS_COUNT_FAIL
            };

        case marketPlaceTypes.FETCHING_LEAF_ADMIN_DELETED_PRODUCT_NAME_SUGGESTIONS:
            return {
                ...state,
                action: marketPlaceTypes.FETCHING_LEAF_ADMIN_DELETED_PRODUCT_NAME_SUGGESTIONS
            };
        case marketPlaceTypes.FETCHING_LEAF_ADMIN_DELETED_PRODUCT_NAME_SUGGESTIONS_SUCCESS:
            return {
                ...state,
                productNames: action.payload,
                action: marketPlaceTypes.FETCHING_LEAF_ADMIN_DELETED_PRODUCT_NAME_SUGGESTIONS_SUCCESS
            };
        case marketPlaceTypes.FETCHING_LEAF_ADMIN_DELETED_PRODUCT_NAME_SUGGESTIONS_FAIL:
            return {
                ...state,
                productNames: [],
                action: marketPlaceTypes.FETCHING_LEAF_ADMIN_DELETED_PRODUCT_NAME_SUGGESTIONS_FAIL
            };
        case marketPlaceTypes.DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                action: marketPlaceTypes.DELETE_PRODUCT_SUCCESS
            };
        case marketPlaceTypes.DELETE_PRODUCT_FAIL:
            return {
                ...state,
                action: marketPlaceTypes.DELETE_PRODUCT_FAIL
            };
        case marketPlaceTypes.UPDATE_TAGS:
            let added = state.editingChangedTags.added.concat(action.payload.added);
            let removed = state.editingChangedTags.removed.concat(action.payload.removed);
            added = added.filter(add => !(action.payload.removed.includes(add)));
            removed = removed.filter(remove => !(action.payload.added.includes(remove)));
            return {
                ...state,
                editingChangedTags: {
                    added: added,
                    removed: removed
                }
            };
        case marketPlaceTypes.CLEAR_UPDATED_TAGS:
            return {
                ...state,
                editingChangedTags: { added: [], removed: [] },
                editingProduct: {}
            };

        case marketPlaceTypes.PREVIEW_PRODUCT:
            return {
                ...state,
                productDetails: action.payload,
                action: marketPlaceTypes.PREVIEW_PRODUCT
            };

        case marketPlaceTypes.CHANGE_CATEGORIES:
            return {
                ...state,
                productDetails: action.payload
            };

        case marketPlaceTypes.FETCH_PRODUCT_LOC_COUNTRY_SUCCESS:
            return {
                ...state,
                publicProductLocations: {
                    ...state.publicProductLocations,
                    countries: action.payload.data
                },
                action: marketPlaceTypes.FETCH_PRODUCT_LOC_COUNTRY_SUCCESS
            };

        case marketPlaceTypes.FETCH_PRODUCT_LOC_STATE_SUCCESS:
            return {
                ...state,
                publicProductLocations: {
                    ...state.publicProductLocations,
                    states: action.payload.data
                },
                action: marketPlaceTypes.FETCH_PRODUCT_LOC_STATE_SUCCESS
            };

        case marketPlaceTypes.FETCH_PRODUCT_LOC_CITY_SUCCESS:
            return {
                ...state,
                publicProductLocations: {
                    ...state.publicProductLocations,
                    cities: action.payload.data
                },
                action: marketPlaceTypes.FETCH_PRODUCT_LOC_CITY_SUCCESS
            };

        case marketPlaceTypes.CLEAR_PREVIEW:
            return {
                ...state,
                productDetails: action.payload,
                action: marketPlaceTypes.CLEAR_PREVIEW
            };
        case 'CHANGE_PRODUCT':
            return {
                ...state,
                product: {
                    ...(state.product || {}),
                    [action.field]: action.value
                }
            };
        case marketPlaceTypes.FETCHING_MARKETPLACE_PRODUCT_CATEGORIES:
            return {
                ...state,
                fetchingProductCategories: true,
                action: marketPlaceTypes.FETCHING_MARKETPLACE_PRODUCT_CATEGORIES
            };
        case marketPlaceTypes.FETCH_MARKETPLACE_PRODUCT_CATEGORIES_SUCCESS:
            return {
                ...state,
                fetchingProductCategories: false,
                action: marketPlaceTypes.FETCH_MARKETPLACE_PRODUCT_CATEGORIES_SUCCESS,
                allProductCatagories: action.payload.data
            };
        case marketPlaceTypes.FETCH_MARKETPLACE_PRODUCT_CATEGORIES_FAIL:
            return {
                ...state,
                fetchingProductCategories: false,
                action: marketPlaceTypes.FETCH_MARKETPLACE_PRODUCT_CATEGORIES_FAIL
            };

        case marketPlaceTypes.FETCH_MY_SINGLE_PRODUCT:
            return {
                ...state,
                action: marketPlaceTypes.FETCH_MY_SINGLE_PRODUCT,
                isProductFetching: true
            };
        case marketPlaceTypes.FETCH_MY_SINGLE_PRODUCT_SUCCESS:
            return {
                ...state,
                action: marketPlaceTypes.FETCH_MY_SINGLE_PRODUCT_SUCCESS,
                product: {
                    name: action.payload.PRODUCT_NAME,
                    descriptionText: action.payload.PRODUCT_DESCRIPTION,
                    richTextDescription: action.payload.PRODUCT_RICH_DESCRIPTION,
                    categories: action.payload.SUB_CATEGORIES,
                    coverImage: action.payload.PRODUCT_COVER_IMAGE,
                    productImageOne: action.payload.PRODUCT_IMAGE_ONE,
                    productImageTwo: action.payload.PRODUCT_IMAGE_TWO,
                    alternateLogo: action.payload.ALTERNATE_LOGO,
                    coverImageName: action.payload.PRODUCT_COVER_IMAGE,
                    productImageOneName: action.payload.PRODUCT_IMAGE_ONE,
                    productImageTwoName: action.payload.PRODUCT_IMAGE_TWO,
                    alternateLogoName: action.payload.ALTERNATE_LOGO,
                    companyId: action.payload.COMPANY_ID,
                    creatorId: action.payload.CREATOR,
                    status: action.payload.STATUS,
                    subscriptionId: action.payload.SUBSCRIPTION_ID,
                    subscriptionStatus: action.payload.SUBSCRIPTION_STATUS,
                    companyUrl: action.payload.COMPANY_URL,
                    isOffer: String(action.payload.IS_OFFER),
                    isNeedPromoCode: String(action.payload.IS_NEED_PROMO_CODE),
                    promoDescription: action.payload.PROMO_DESCRIPTION,
                    billingType: action.payload.BILLING_TYPE,
                    billingFrequency: action.payload.BILLING_FREQUENCY || BILLING_FREQUENCY.MONTHLY,
                    // description: action.payload.DESCRIPTION,
                    listing: action.payload.LISTING,
                    featuredCategories: action.payload.FEATURED_CATEGORIES ? action.payload.FEATURED_CATEGORIES : [],
                    packageType: action.payload.PACKAGE_TYPE || MARKETPLACE_PACKAGE_TYPES.PROFESSIONAL,
                    promoCode: action.payload.PROMO_CODE,
                    subscribedPackageDetails: {
                        subCategories: (action.payload.SUBSCRIBED_PACKAGE_DETAILS && action.payload.SUBSCRIBED_PACKAGE_DETAILS.SUB_CATEGORIES) ?action.payload.SUBSCRIBED_PACKAGE_DETAILS.SUB_CATEGORIES : [],
                        featuredIn: (action.payload.SUBSCRIBED_PACKAGE_DETAILS && action.payload.SUBSCRIBED_PACKAGE_DETAILS.SUB_CATEGORIES) ?action.payload.SUBSCRIBED_PACKAGE_DETAILS.FEATURED_IN : [],
                        packageType: (action.payload.SUBSCRIBED_PACKAGE_DETAILS && action.payload.SUBSCRIBED_PACKAGE_DETAILS.SUB_CATEGORIES) ?action.payload.SUBSCRIBED_PACKAGE_DETAILS.PACKAGE_TYPE : null,
                        billingType: (action.payload.SUBSCRIBED_PACKAGE_DETAILS && action.payload.SUBSCRIBED_PACKAGE_DETAILS.SUB_CATEGORIES) ?action.payload.SUBSCRIBED_PACKAGE_DETAILS.BILLING_TYPE : null,
                        billingFrequency: (action.payload.SUBSCRIBED_PACKAGE_DETAILS && action.payload.SUBSCRIBED_PACKAGE_DETAILS.SUB_CATEGORIES) ?action.payload.SUBSCRIBED_PACKAGE_DETAILS.BILLING_FREQUENCY : null,
                        date: (action.payload.SUBSCRIBED_PACKAGE_DETAILS && action.payload.SUBSCRIBED_PACKAGE_DETAILS.SUB_CATEGORIES) ?action.payload.SUBSCRIBED_PACKAGE_DETAILS.DATE : null,
                    },
                    isTrialOver: action.payload.IS_TRIAL_OVER,
                    currentNextChargeAmount: action.payload.NEXT_CHARGE_AMOUNT,
                    gatewayId: action.payload.GATEWAY_ID || null, 
                    planId: action.payload.PLAN_ID,
                    nextChargeDate: action.payload.NEXT_CHARGE_DATE,
                    createdAt: action.payload.createdAt,
                    updatedAt: action.payload.updatedAt,
                },
                isProductFetching: false
            };
        case marketPlaceTypes.CLEAR_MY_SINGLE_PRODUCT:
            return {
                ...state,
                product: {
                    name: '',
                    description: '',
                    subCategories: [],
                    productImageOne: '',
                    productImageTwo: '',
                    productCover: '',
                    companyId: '',
                    creatorId: '',
                    status: '',
                    subscriptionId: '',
                    subscriptionStatus: '',
                    createdAt: '',
                    updatedAt: ''
                },
                productOneUpload: { progress: 0 },
                productTwoUpload: { progress: 0 },
                productThreeUpload: { progress: 0 },
                action: marketPlaceTypes.CLEAR_MY_SINGLE_PRODUCT
            };
        case marketPlaceTypes.FETCH_MY_SINGLE_PRODUCT_FAIL:
            return {
                ...state,
                action: marketPlaceTypes.FETCH_MY_SINGLE_PRODUCT_FAIL
            };
        case marketPlaceTypes.FETCH_MY_SINGLE_PRODUCT_MEDIA_SUCCESS:
            return {
                ...state,
                action: marketPlaceTypes.FETCH_MY_SINGLE_PRODUCT_MEDIA_SUCCESS,
                product: {
                    ...state.product,
                    coverImage: action.payload.cover
                }
            };
        case marketPlaceTypes.FETCH_MY_SINGLE_PRODUCT_IMAGE1_SUCCESS:
            return {
                ...state,
                action: marketPlaceTypes.FETCH_MY_SINGLE_PRODUCT_IMAGE1_SUCCESS,
                product: {
                    ...state.product,
                    productImageOne: action.payload.cover
                }
            };
        case marketPlaceTypes.FETCH_MY_SINGLE_PRODUCT_IMAGE2_SUCCESS:
            return {
                ...state,
                action: marketPlaceTypes.FETCH_MY_SINGLE_PRODUCT_IMAGE2_SUCCESS,
                product: {
                    ...state.product,
                    productImageTwo: action.payload.cover
                }
            };
        case marketPlaceTypes.SINGLE_PRODUCT_COMPANY_IMAGE_ALTERNATE:
            return {
                ...state,
                product: {
                    ...state.product,
                    alternateLogo: action.payload.alternateLogo
                },
            };
        case marketPlaceTypes.PRODUCT_IMAGE_ONE_UPLOAD:
            return {
                ...state,
                productOneUpload: action.payload
            };
        case marketPlaceTypes.PRODUCT_IMAGE_TWO_UPLOAD:
            return {
                ...state,
                productTwoUpload: action.payload
            };
        case marketPlaceTypes.PRODUCT_IMAGE_THREE_UPLOAD:
            return {
                ...state,
                productThreeUpload: action.payload
            };
        case marketPlaceTypes.FETCH_MARKETPLACE_REPORTING_DASHBOARD_DETAILS:
            return {
                ...state,
                action: marketPlaceTypes.FETCH_MARKETPLACE_REPORTING_DASHBOARD_DETAILS
            };
        case marketPlaceTypes.FETCH_MARKETPLACE_REPORTING_DASHBOARD_DETAILS_SUCCESS:
            return {
                ...state,
                action: marketPlaceTypes.FETCH_MARKETPLACE_REPORTING_DASHBOARD_DETAILS_SUCCESS,
                marketplaceReportingDashboardDetails: action.payload
            };
        case marketPlaceTypes.FETCH_MARKETPLACE_REPORTING_DASHBOARD_DETAILS_FAIL:
            return {
                ...state,
                action: marketPlaceTypes.FETCH_MARKETPLACE_REPORTING_DASHBOARD_DETAILS_FAIL,
            };
        case marketPlaceTypes.FETCH_PROMO_TEXT:
            return {
                ...state,
                action: marketPlaceTypes.FETCH_PROMO_TEXT
            };
        case marketPlaceTypes.FETCH_PROMO_TEXT_SUCCESS:
            return {
                ...state,
                action: marketPlaceTypes.FETCH_PROMO_TEXT_SUCCESS,
                marketplacePromoText: action.payload.filter(text => text.MODULE_ID === 4),
                jobBoardPromoText: action.payload.filter(text => text.MODULE_ID === 3)
            };
        case marketPlaceTypes.FETCH_PROMO_TEXT_FAIL:
            return {
                ...state,
                action: marketPlaceTypes.FETCH_PROMO_TEXT_FAIL,
            };

        case marketPlaceTypes.FETCH_PAYMENT_DETAILS:
            return {
                ...state,
                productPaymentDetails: {},
                fetchingProductPaymentDetails: true,
                action: marketPlaceTypes.FETCH_PAYMENT_DETAILS
            };
        case marketPlaceTypes.FETCH_PAYMENT_DETAILS_SUCCESS:
            let details = {};
            if (action.payload.data) {
                action.payload.data.forEach(product => {
                    if (product && product.autoRenew) {
                        details[product.subscriptionId] = {
                            nextChargeDate: product.nextChargeDate,
                            creditCard: (product.paymentSource && product.paymentSource.creditCardInfo && product.paymentSource.creditCardInfo.creditCard) ? product.paymentSource.creditCardInfo.creditCard : null
                        };
                    }
                })
            }
            return {
                ...state,
                fetchingProductPaymentDetails: false,
                productPaymentDetails: details,
                action: marketPlaceTypes.FETCH_PAYMENT_DETAILS_SUCCESS
            };
        case marketPlaceTypes.FETCH_PAYMENT_DETAILS_FAIL:
            return {
                ...state,
                productPaymentDetails: {},
                fetchingProductPaymentDetails: false,
                action: marketPlaceTypes.FETCH_PAYMENT_DETAILS_FAIL
            };

        case marketPlaceTypes.REPLACE_PAYMENT_METHOD:

            let updatedPaymentDetails = state.productPaymentDetails;
            if (updatedPaymentDetails[action.subId]) {
                updatedPaymentDetails[action.subId] = {
                    nextChargeDate: updatedPaymentDetails[action.subId].nextChargeDate,
                    creditCard: action.cardDetails
                }
            }

            return {
                ...state,
                productPaymentDetails: state.productPaymentDetails,
                action: marketPlaceTypes.REPLACE_PAYMENT_METHOD
            };
            
        case marketPlaceTypes.FETCHING_MARKETPLACE_PACKAGE_DESCRIPTION:
            return {
                ...state,
                fetchingProductPackageDetails: true,
                action: marketPlaceTypes.FETCHING_MARKETPLACE_PACKAGE_DESCRIPTION
            };
        case marketPlaceTypes.FETCH_MARKETPLACE_PACKAGE_DESCRIPTION_SUCCESS:
            return {
                ...state,
                fetchingProductPackageDetails: false,
                action: marketPlaceTypes.FETCH_MARKETPLACE_PACKAGE_DESCRIPTION_SUCCESS,
                productPackages: action.payload.data
            };
        case marketPlaceTypes.FETCH_MARKETPLACE_PACKAGE_DESCRIPTION_FAIL:
            return {
                ...state,
                fetchingProductPackageDetails: false,
                action: marketPlaceTypes.FETCH_MARKETPLACE_PACKAGE_DESCRIPTION_FAIL
            };

        default:
            return state
    }
};