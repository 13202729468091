import React, { Component } from 'react'
import './styles.css'

class Dropdown extends Component {

    _onSelect = (e) => {
        this.props.submit(e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text,e.target.value);
    };

    render() {

        let items = this.props.items.map((item, i)=>{
            return <DropdownItem key={i} item={item} selected={item.value === this.props.defaultValue ? true : false}/>
        });

        return (
            <select className="form-control" value={this.props.defaultValue} onChange={this._onSelect} style={{padding: 0}} id={this.props.id ? this.props.id : null}>
                {!this.props.noDefault && <option default={true} readOnly={true} value="0">Select an option</option>}
                {items}
            </select>
        )
    }

}

class DropdownItem extends Component {

    render() {
        return (
            <option value={this.props.item.value} >{this.props.item.label}</option>
        );
    }
}
export { Dropdown }