import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import SweetAlert from 'sweetalert-react';
import {deleteAccount} from "../../actions/auth";
import {ACCOUNT_SETTINGS} from '../../types/data';
import {trackEvent} from '../../actions/eventTracking';
import {EVENT_ACTION} from '../../types/eventTracking';

class AccountSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isPopupOpen: false
        }
    }

    componentWillUnmount() {
        this.setState({isPopupOpen: false});
    }

    handleClick = (i) => {
        if (localStorage.getItem('selectedCategories')) {
            localStorage.removeItem('selectedCategories');
        }
        switch (i) {
            case 0:
                // Change Email and Password
                this.props.history.push('/settings/change-email-password');
                break;
            case 1:
                // Personal Preferences
                this.props.history.push('/profile/individual-personal-preferences');
                break;
            case 2:
                // Delete Account
                this.setState({isPopupOpen: true})
                break;
            default:
                break;
        }
    }

    /* data project - event trcking */
    _setEventTrackingPayload = (link) => {
        if (link && this.props.auth.user) {
            const payload = {
                user_id: this.props.auth.user._id,
                page: this.props.page,
                section: 'ME_SECTION',
                element_type: 'SPAN',
                element_id: link,
                action_type: EVENT_ACTION.CLICK
            }

            this.props.dispatch(trackEvent(payload));
        }
    }

    /* END : data project - event trcking */

    render() {
        return (
            <React.Fragment>
                <SweetAlert
                    show={this.state.isPopupOpen}
                    title="Confirmation"
                    text={"Do you really want to delete your account?"}
                    showCancelButton
                    onConfirm={() => {
                        this.setState({isPopupOpen: false}, () => {
                            this.props.deleteAccount(this.props, true);
                        });
                    }}
                    onCancel={() => this.setState({isPopupOpen: false})}
                />
                <div className="dropdown-divider"/>
                <p className="dropdown-title">Account Settings</p>
                {ACCOUNT_SETTINGS.map((setting, i) => (
                    <span key={i} className="dropdown-item link close-on-click" onClick={() => {
                        this._setEventTrackingPayload(setting.toUpperCase());
                        this.handleClick(i)
                    }}> {setting} </span>
                ))}
            </React.Fragment>
        )
    }
}

export default withRouter(connect(null, {deleteAccount})(AccountSettings));

