import React from 'react'
import classNames from "classnames";

const ModalClose = ({ close, className, light, elemId }) =>{
    let title = classNames("modal-on-close", className);
    let extraProps = {};
    if(elemId){
        extraProps.id = elemId
    }

    return(
        <h4 className={title} onClick={close}><i className={light ? "fas fa-times" : "far fa-times-circle"} {...extraProps}/></h4>
    )

}
export {ModalClose}