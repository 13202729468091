import { applyMiddleware , createStore } from 'redux';
//import {createLogger} from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import reducer from './reducers';

const isDev = String(process.env.NODE_ENV).toLowerCase() === 'development'
const middleware = applyMiddleware(promise(),thunk);

/*, createLogger()*/

// export default createStore(reducer, composeWithDevTools(middleware)); // development

// const { store, persistor } = configureStore();

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['chat'],
  }
   
const persistedReducer = persistReducer(persistConfig, reducer)

export default () => {
    let store = createStore(persistedReducer, isDev ? composeWithDevTools(middleware) : middleware)
    let persistor = persistStore(store)
    return { store, persistor }
}

// export default createStore(persistReducer({
//     key: "root",
//     debug: true,
//     storage,
//     whitelist: ['auth'],
//   }, reducer), middleware); // production