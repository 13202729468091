import React, { Component } from "react";
import defaultAvatorCompany from "./../../../../assets/img/default-corporate-image.jpg";
import defaultAvatorPeople from "./../../../../assets/img/default-male.jpg";
import "./index.css";
import imgExists from 'image-exists';
import { isURL } from './../../../../libs/validation';
import { imageExists } from './../../../../libs/image';
import { SIGNED_COOKIES_CF_URL } from '../../../../config';
import { getCFObjURL, BUCKET } from "../../../../libs/cfHelper";


class AvatarProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      avatar: this.props.isCompany ? defaultAvatorCompany :  defaultAvatorPeople,
      imageName: this.props.avatar
    }
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  componentWillMount() {
    if (!this.props.isProduct && this.props.avatar !== '') {
      if (!isURL(this.props.avatar, { protocols: ['https'] }, SIGNED_COOKIES_CF_URL) && (this.props.avatar && this.props.avatar.indexOf("/static/media/") === -1)) {
        if(!this.props.noFetch){
          this._getImageUrl(this.props.avatar);
        }
      } else {
        this.setState({ avatar: this.props.avatar });
      }
      this.setState({imageName: this.props.avatar})
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.avatar && (nextProps.avatar !== this.state.imageName)) {
      if (nextProps.avatar && !isURL(nextProps.avatar, { protocols: ['https'] }, SIGNED_COOKIES_CF_URL) && (nextProps.avatar.indexOf("/static/media/") === -1)) {
        if(!this.props.noFetch) {
          this._getImageUrl(nextProps.avatar);
        }
      } else {
        this.setState({ avatar: nextProps.avatar, imageName: nextProps.avatar });
      }
    }

    if(this.props.isProduct){
      this.setState({avatar: nextProps.avatar});
    }

    if (nextProps.avatar === '') {
      this.setState({ avatar: nextProps.isCompany ? defaultAvatorCompany : defaultAvatorPeople, imageName:'' });
    }

    if (this.props.messaging) {
      if (this.state.avatar !== nextProps.avatar) {
        if (nextProps.avatar && nextProps.avatar.indexOf("/static/media/") === -1) {
          if (nextProps.avatar !== this.state.imageName) {
            this._getImageUrl(nextProps.avatar);
            this.setState({ imageName: nextProps.avatar });
          }
        } else {
          this.setState({ avatar: nextProps.avatar, imageName: nextProps.avatar });
        }
      }
    }
  }

  _getImageUrl(imageName) {
    const url = getCFObjURL(BUCKET.COMMON)((this.props.avatarSize + imageName), true);
    if (url) {
          const isProfilePic = !this.props.isCompany
          imageExists(url, (exists) => {
            if (exists && this._isMounted) {
              imgExists(url, (exists) => {
                if (exists) {
                  this.setState({ avatar: url });
                }
                else {
                  this.setState({ avatar: this.props.isCompany ? defaultAvatorCompany : defaultAvatorPeople });
                }
              });
              
            } else if (this._isMounted) {
              this.setState({ avatar: this.props.isCompany ? defaultAvatorCompany : defaultAvatorPeople });
            }
          }, isProfilePic);
        } else {
          this.setState({ avatar: this.props.isCompany ? defaultAvatorCompany : defaultAvatorPeople });
        }
  }

  getProfile = (avatar, type, size) => {
    let img = avatar;
    if (avatar && isURL(avatar, { protocols: ['https'] }, SIGNED_COOKIES_CF_URL) && (avatar.indexOf("/static/media/") === -1)) {
      imgExists(avatar, (exists) => {
        if (!exists) {
          img = this.props.isCompany ? defaultAvatorCompany : defaultAvatorPeople;
        }
      });
    }
    let style = this.props.customStyle ? this.props.customStyle : "avatar_profile";
    if(type === 'chat_popup'){
      return (
        <img src={img} alt="User" />
      );
    }
    if (img) {
      return (
        <div
          className={style}
          style={{
            backgroundImage: "url(" + img + ")",
            width: size + "px",
            height: size + "px"
          }}
        >
          {this.getProgileType(type)}
        </div>
      );
    } else {
      return (
        <div
          className={style}
          style={{
            backgroundImage: "url(" + this.props.isCompany ? defaultAvatorCompany :  defaultAvatorPeople + ")",
            border: "1px solid #DDD",
            width: size + "px",
            height: size + "px"
          }}
        >
          {this.getProgileType(type)}
        </div>
      );
    }
  };

  getProgileType = type => {
    // if (type && type === 'Investor') {
    //   return (
    //     <div className="avatar_profile_type_investor justify-content-center align-items-center d-flex font_medium">
    //       {type} 123
    //     </div>
    //   );
    // } removed investor tag from LEAF-6552 - mewan
    if (type && type !== 'Investor') {
      return (
        <div className="avatar_profile_type_fundraising justify-content-center align-items-center d-flex font_medium">
          {type}
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    return this.getProfile(this.state.avatar, this.props.type, this.props.size);
  }
}

AvatarProfile.defaulProps = {
  size: null,
  type: "FUNDRAISING",
  avatarSize: 'small-'
};

export { AvatarProfile };