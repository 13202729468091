import React from 'react'
import './index.css'

const ProfileCover = ({children, height, customClass}) =>{
 
      let coverSize = {height : height + 'px'}
      
   return(
    <div className={customClass ? customClass : "profile-cover"} style={coverSize}>
         {children}
    </div>
   )
   
}
ProfileCover.defaultProps = {
    height: '0px' ,
  };
export { ProfileCover }