import React from "react";
import "./index.css"

const Table = ({ children, width = "100%" }) => {

    return (
        <div className="table-responsive">
         <table className="table" width={width}>
            {children}
         </table>
        </div>
    );
};

const TableBody = ({ children }) => {
    return (
        <tbody>
            {children}
        </tbody>
    );
}

const TableRow = ({ children }) => {
    return (
        <tr>
            {children}
        </tr>
    );
}

const TableRowHeader = ({ children }) => {
    return (
        <thead>{children}</thead>
    );
}

const TableHeader = ({ colspan="1", width = "0%", className = "", text, children }) => {
    return (
        <th width={width} colSpan={colspan} className={className}>{text}{children}</th>
    );
}

const TableData = ({ colspan="1", width = "0%", className = "", children, style }) => {
    return (
        <td width={width} colSpan={colspan} className={className}>{children}</td>
    );
}

export { Table, TableRow, TableBody, TableRowHeader, TableHeader, TableData };