import React, { Component, useState } from "react";
import "./styles.css";
import avatar from "./../../../assets/sample/avt.png";
import imgLoader from "./../../../assets/img/placeholder.png";
import { isURL } from './../../../libs/validation';
import { pauseOtherVideos } from './../../../libs/commonFunctions';
import { IMAGE_TYPE, VIDEO_PROCESS_STATUS } from '../../../types/common';
import { SIGNED_COOKIES_CF_URL } from '../../../config';
import ReactHlsPlayer from 'react-hls-player';
import { getCFObjURL, BUCKET } from '../../../libs/cfHelper';

class PostImage extends Component {

  constructor(props){
    super(props);
    this.state = {
      avatar: null,
      imageList: [],
      attachmentTypes: ["application/pdf", "application/docx", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
      imageTypes: ["image/gif", "image/png", "image/jpeg", "image/jpg", "image/bmp", "image/webp"],
      adminImageTypes: ["gif", "png", "jpeg", "jpg", "bmp", "webp"],
      isImageLoading: false
    }
  }

  _isMounted = false;

  componentDidMount(){
    this._isMounted = true;

    if (this.state.imageTypes.indexOf(this.props.imgSrc.TYPE) !== -1 || this.state.adminImageTypes.indexOf(this.props.imgSrc.TYPE)) {
      if(!isURL(String.toString(this.props.imgSrc.FILE_NAME), { protocols: ['https'] }, SIGNED_COOKIES_CF_URL) && this.props.imgSrc.FILE_NAME !== ''){
        this._getImageUrl(this.props.imgSrc.FILE_NAME);
        this.setState({isImageLoading: true});
      }
    }
  }

  componentWillUnmount(){
    this._isMounted = false
  }

  // componentWillMount() {
  //   if (this.state.imageTypes.indexOf(this.props.imgSrc.TYPE) !== -1 || this.state.adminImageTypes.indexOf(this.props.imgSrc.TYPE)) {
  //     if(!isURL(String.toString(this.props.imgSrc.FILE_NAME), { protocols: ['https'] }, SIGNED_COOKIES_CF_URL) && this.props.imgSrc.FILE_NAME !== ''){
  //       this._getImageUrl(this.props.imgSrc.FILE_NAME);
  //       this.setState({isImageLoading: true});
  //     }
  //   } /*else if (this.state.attachmentTypes.indexOf(this.props.imgSrc.TYPE) !== -1) {
  //     if (this.props.imgSrc.TYPE === "application/pdf") {
  //       this.setState({avatar: pdfIcon});
  //     } else if (this.props.imgSrc.TYPE === "application/msword") {
  //       this.setState({avatar: docIcon});
  //     } else if (this.props.imgSrc.TYPE === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || this.props.imgSrc.TYPE === "application/docx") {
  //       this.setState({avatar: docxIcon});
  //     }
  //   }*/
  // }

  _getImageUrl(imageName){
    let imgName = this.props.imageType === IMAGE_TYPE.THUMBNAIL ? 'medium-' + imageName : imageName;

    const isThumb = this.props.imageType === IMAGE_TYPE.THUMBNAIL;
    const url = getCFObjURL(BUCKET.NEWSFEED)(imgName, isThumb);
      if (url && this._isMounted) {
        this.setState({avatar: url, /*isImageLoading: true*/});
      } else {
        this.setState({isImageLoading: false});
      }
  }

  handleImageLoaded() {
    this.setState({ isImageLoading: false });
  }

  handleImageErrored() {
    this.setState({ isImageLoading: false });
  }

  render() {
    
    let style = { borderRadius: this.props.viewmood === true ? "7px 7px 0px 0px" : null };

    return (
      <div className="post-image-wrapper">
        {
          this.state.avatar !== null && 
          <img 
            src={this.state.isImageLoading ? imgLoader : this.state.avatar}
            onLoad={this.handleImageLoaded.bind(this)} 
            onError={this.handleImageErrored.bind(this)}
            onClick={(this.props.openImage && this.props.imgSrc && this.props.imgSrc.FILE_NAME) ? this.props.openImage.bind(this, this.props.imgSrc.FILE_NAME): null}
            style={style} 
            className={this.props.customClass || "post_image"} 
            alt="" 
          />
        }
      </div>
      // <div>
      //   {
      //     this.state.attachmentTypes.indexOf(this.props.imgSrc.TYPE) !== -1 
      //       ? 
      //         <img src={this.state.avatar} style={style} className="post_image" alt="" height="50" width="50" /> 
      //           : <img src={this.state.avatar} style={style} className="post_image" alt="" />
      //   }
      // </div>
    );

  }
}

const PostVideo = ({ FILE_NAME, STATUS, TYPE, _id, isPublicFeed, openLogin }) => {

  return (
    <div
      className="post-video-wrapper"
      onClick={() => { //if video is muted by autoplay, when user click on play this will enable sound
        if (!isPublicFeed) {
          let el = document.getElementById(FILE_NAME);
          if (el && el.tagName === 'VIDEO') {
            el.muted = false
          }
        }
      }}
    >
      {isPublicFeed && <div className="public-media-cover" onClick={() => {if(openLogin)openLogin()}}/>}
      {STATUS === VIDEO_PROCESS_STATUS.PENDING
        ? <VideoPlayer
          src={getCFObjURL(BUCKET.VIDEO)(FILE_NAME, true)}
          isPublicFeed={isPublicFeed}
          openLogin={openLogin}
          ID={FILE_NAME}
        />
        : <CustomPlayer
          src={getCFObjURL(BUCKET.VIDEO)(FILE_NAME)}
          isPublicFeed={isPublicFeed}
          openLogin={openLogin}
          ID={FILE_NAME}
        />}

    </div>
  );
}

const CustomPlayer = React.memo(({ src, isPublicFeed, openLogin, ID }) => {
  const playerRef = React.useRef();

  function pauseVideo() {
    playerRef.current.pause();
  }

  return (
    <ReactHlsPlayer
      id={ID}
      onPlay={(e) => {
        if(isPublicFeed){
          pauseVideo()
          if(openLogin)openLogin()
        } else {
          pauseOtherVideos(ID)
        }
      }}
      playerRef={playerRef}
      src={src}
      autoPlay={false}
      controls={true}
      width="100%"
      height="auto"
      hlsConfig={{
        maxBufferSize: 500
      }}
      muted={false}
    />
  );
})

const VideoPlayer = React.memo(({ src, isPublicFeed, openLogin, ID }) => {

  const videoPlayerRef = React.useRef();
  const [isValidFormat, setValidFormat] = useState(false)
  const [isFetching, setIsFetching] = useState(true)

  if(!isFetching && !isValidFormat){
    return(
      <div className="video-processing-placeholder"><i className="fas fa-spin m-r5 fa-circle-notch"/>Video Processing...</div>
    )
  }

  return (
    <video
      id={ID}
      ref={videoPlayerRef}
      width="100%"
      height="auto"
      controls
      autoPlay={false}
      onPlay={() => {
        if(isPublicFeed){
          videoPlayerRef.current.pause();
          if(openLogin)openLogin()
        } else {
          pauseOtherVideos(ID)
        }
      }}
      onError={(error) => {
        setIsFetching(false)
      }}
      onCanPlay={() => {
        setValidFormat(true)
        setIsFetching(false)
      }}
      hidden={!isValidFormat || isFetching}
      muted={false}
    >
      <source src={src} />
      Your browser does not support the video.
    </video>
  )
})

PostImage.defaultProps = {
  imgSrc: avatar,
  viewmood: false
};

PostVideo.defaultProps = {
  imgSrc: avatar,
  viewmood: false
};

export { PostImage, PostVideo };