import AdminTypes from '../types/admin';

export const initialState = {
    /*event*/
    /*event_creating: false,
    event_isSuccess: false,
    event_isFetching: false,
    event_isFetchingSuccess: false,
    event_isGettingCount: false,
    event_isGettingCountSuccess: false,
    event_isUpdating: false,
    event_isUpdatingSuccess: false,
    event_isDeleting: false,
    event_isDeletingSuccess: false,
    event_count: 0,
    event_list: [],*/

    /*post*/
    /*post_count:0,
    post_list:[],
    post_fetching:false,
    post_creating:false,
    post_deleting:false,
    post_updating:false,*/

    // permission_list:[],
    action: 0,
    deletedProducts: [],
    deletedProductsCount: 0,
    expertContributers: [],
    expertContributersCount: 0,
    marketplaceStatistics: [],
    marketplaceStatisticsCount: 0,
    statisticSearchResults: [],
    couponDetails: [],
    couponDetailsCount: 0,
    publishedProducts: [],
    publishedProductCount: 0,
    isProductsFetching: false,
    alreadyPrioritized: {},
    marketingCompaignStatistics: [],
    marketingCompaignStatisticsCount: 0,
    marketingCompaignSummary: [],
    marketingCompaignSummaryCount: 0,
    newsfeedTextLinkSummary: [],
    newsfeedTextLinkSummaryCount: 0,
    rewardRedemption: [],
    rewardRedemptionCount: 0,
    rewardRedemptionList: [],
    rewardsInvalidIds: [],
};

export const admin = (state = initialState, action) => {
    switch (action.type) {
        // case AdminTypes.CREATEING_EVENT:
        //     return {
        //         ...state,
        //         event_creating: true
        //     };
        // case AdminTypes.EVENT_CREATION_SUCCESS:
        //     return {
        //         ...state,
        //         event_creating: false,
        //         event_isSuccess: true,
        //         post_list: [...state.post_list, action.payload],
        //         action: AdminTypes.EVENT_CREATION_SUCCESS
        //     };
        // case AdminTypes.EVENT_CREATION_FAIL:
        //     return {
        //         ...state,
        //         event_creating: false,
        //         event_isSuccess: false,
        //         action: AdminTypes.EVENT_CREATION_FAIL
        //     };
        // case AdminTypes.UPDATING_EVENT:
        //     return {
        //         ...state,
        //         event_isUpdating: true
        //     };
        // case AdminTypes.EVENT_UPDATE_SUCCESS:
        //     return {
        //         ...state,
        //         event_isUpdating: false,
        //         event_isUpdatingSuccess: true,
        //         action: AdminTypes.EVENT_UPDATE_SUCCESS
        //     };
        // case AdminTypes.EVENT_UPDATE_FAIL:
        //     return {
        //         ...state,
        //         event_isUpdating: false,
        //         event_isUpdatingSuccess: false,
        //         action: AdminTypes.EVENT_UPDATE_FAIL
        //     };
        // case AdminTypes.DELETING_EVENT:
        //     return {
        //         ...state,
        //         event_isDeleting: true
        //     };
        // case AdminTypes.EVENT_DELETE_SUCCESS:
        //     return {
        //         ...state,
        //         event_isDeleting: false,
        //         event_isDeletingSuccess: true,
        //         action: AdminTypes.EVENT_DELETE_SUCCESS
        //     };
        // case AdminTypes.EVENT_DELETE_FAIL:
        //     return {
        //         ...state,
        //         event_isDeleting: false,
        //         event_isDeletingSuccess: false,
        //         action: AdminTypes.EVENT_DELETE_FAIL
        //     };
        // case AdminTypes.EVENT_CLEAR_ACTION:
        //     return {
        //         ...state,
        //         action: 0
        //     };
        // case AdminTypes.EVENT_FETCHING:
        //     return {
        //         ...state,
        //         event_isFetching: true,
        //         event_isFetchingSuccess: false,
        //         action: AdminTypes.EVENT_FETCHING
        //     };
        // case AdminTypes.EVENT_FETCHING_SUCCESS:
        //     return {
        //         ...state,
        //         event_isFetching: false,
        //         event_isFetchingSuccess: true,
        //         post_list: action.payload,
        //         action: AdminTypes.EVENT_FETCHING_SUCCESS
        //     };
        // case AdminTypes.EVENT_FETCHING_FAIL:
        //     return {
        //         ...state,
        //         event_isFetching: false,
        //         event_isFetchingSuccess: false,
        //         action: AdminTypes.EVENT_FETCHING_FAIL
        //     };
        // case AdminTypes.EVENT_GETTING_COUNT:
        //     return {
        //         ...state,
        //         event_isGettingCount: true,
        //         event_isGettingCountSuccess: false,
        //         action: AdminTypes.EVENT_GETTING_COUNT
        //     };
        // case AdminTypes.EVENT_GETTING_COUNT_SUCCESS:
        //     return {
        //         ...state,
        //         event_isGettingCount: false,
        //         event_isGettingCountSuccess: true,
        //         event_count: action.payload,
        //         action: AdminTypes.EVENT_GETTING_COUNT_SUCCESS
        //     };
        // case AdminTypes.EVENT_GETTING_COUNT_FAIL:
        //     return {
        //         ...state,
        //         event_isGettingCount: false,
        //         event_isGettingCountSuccess: false,
        //         action: AdminTypes.EVENT_GETTING_COUNT_FAIL
        //     };
        // case AdminTypes.FETCHING_POST:
        //     return{
        //         ...state,
        //         post_fetching:true,
        //         action: AdminTypes.FETCHING_POST
        //     };
        // case AdminTypes.FETCHING_POST_SUCCESS:
        //     return{
        //         ...state,
        //         post_fetching:false,
        //         post_list:action.payload,
        //         action: AdminTypes.FETCHING_POST_SUCCESS
        //     };
        // case AdminTypes.FETCHING_POST_FAIL:
        //     return{
        //         ...state,
        //         post_fetching:false,
        //         action: AdminTypes.FETCHING_POST_FAIL
        //     };
        // case AdminTypes.CREATING_POST:
        //     return{
        //         ...state,
        //         post_creating:true,
        //         action:AdminTypes.CREATING_POST
        //     };
        // case AdminTypes.CREATING_POST_SUCCESS:
        //     return{
        //         ...state,
        //         post_creating:false,
        //         post_count: ++state.post_count,
        //         action:AdminTypes.CREATING_POST_SUCCESS
        //     };
        // case AdminTypes.CREATING_POST_FAIL:
        //     return{
        //         ...state,
        //         post_creating: false,
        //         action: AdminTypes.CREATING_POST_FAIL
        //     };
        // case AdminTypes.DELETING_POST:
        //     return{
        //         ...state,
        //         post_deleting: true,
        //         action: AdminTypes.DELETING_POST
        //     };
        // case AdminTypes.DELETING_POST_SUCCESS:
        //     return{
        //         ...state,
        //         post_deleting: false,
        //         post_count: --state.post_count,
        //         action: AdminTypes.DELETING_POST_SUCCESS
        //     };
        // case AdminTypes.DELETING_POST_FAIL:
        //     return{
        //         ...state,
        //         post_deleting: false,
        //         action: AdminTypes.DELETING_POST_FAIL
        //     };
        // case AdminTypes.GETTING_POST_COUNT_SUCCESS:
        //     return{
        //         ...state,
        //         post_count: action.payload,
        //         action: AdminTypes.GETTING_POST_COUNT_SUCCESS
        //     };
        // case AdminTypes.UPDATING_POST:
        //     return{
        //         ...state,
        //         post_updating:true,
        //         action: AdminTypes.UPDATING_POST
        //     };
        // case AdminTypes.UPDATING_POST_SUCCESS:
        //     return{
        //         ...state,
        //         post_updating:false,
        //         action: AdminTypes.UPDATING_POST_SUCCESS
        //     };
        // case AdminTypes.UPDATING_POST_FAIL:
        //     return{
        //         ...state,
        //         post_updating:false,
        //         action: AdminTypes.UPDATING_POST_FAIL
        // };

        // case AdminTypes.UPDATING_POSTS_SETTINGS:
        //     return {
        //         ...state,
        //         event_isUpdating: true,
        //         action: AdminTypes.UPDATING_POSTS_SETTINGS
        //     };
        // case AdminTypes.UPDATING_POSTS_SETTINGS_SUCCESS:
        //     return {
        //         ...state,
        //         event_isUpdating: false,
        //         event_isUpdatingSuccess: true,
        //         action: AdminTypes.UPDATING_POSTS_SETTINGS_SUCCESS
        //     };
        // case AdminTypes.UPDATING_POSTS_SETTINGS_FAIL:
        //     return {
        //         ...state,
        //         event_isUpdating: false,
        //         event_isUpdatingSuccess: false,
        //         action: AdminTypes.UPDATING_POSTS_SETTINGS_FAIL
        //     };

        // case AdminTypes.FETCHING_PERMISSIONS:
        //     return{
        //         ...state,
        //         action: AdminTypes.FETCHING_PERMISSIONS
        //     };
        // case AdminTypes.FETCHING_PERMISSIONS_SUCCESS:
        //     return{
        //         ...state,
        //         permission_list: action.payload,
        //         action: AdminTypes.FETCHING_PERMISSIONS_SUCCESS
        //     };
        // case AdminTypes.FETCHING_PERMISSIONS_FAIL:
        //     return{
        //         ...state,
        //         action: AdminTypes.FETCHING_PERMISSIONS_FAIL
        //     };

        case AdminTypes.FETCHING_ADMIN_DELETED_PRODUCTS:
            return {
                ...state,
                action: AdminTypes.FETCHING_ADMIN_DELETED_PRODUCTS
            };
        case AdminTypes.FETCHING_ADMIN_DELETED_PRODUCTS_SUCCESS:
            return {
                ...state,
                action: AdminTypes.FETCHING_ADMIN_DELETED_PRODUCTS_SUCCESS,
                deletedProducts: state.deletedProducts.concat(action.payload)
            };
        case AdminTypes.FETCHING_ADMIN_DELETED_PRODUCTS_FAIL:
            return {
                ...state,
                action: AdminTypes.FETCHING_ADMIN_DELETED_PRODUCTS_FAIL,
                deletedProducts: []
            };

        case AdminTypes.FETCHING_ADMIN_DELETED_PRODUCT_COUNT:
            return {
                ...state,
                action: AdminTypes.FETCHING_ADMIN_DELETED_PRODUCT_COUNT
            };
        case AdminTypes.FETCHING_ADMIN_DELETED_PRODUCT_COUNT_SUCCESS:
            return {
                ...state,
                action: AdminTypes.FETCHING_ADMIN_DELETED_PRODUCT_COUNT_SUCCESS,
                deletedProductsCount: action.payload.COUNT
            };
        case AdminTypes.FETCHING_ADMIN_DELETED_PRODUCT_COUNT_FAIL:
            return {
                ...state,
                action: AdminTypes.FETCHING_ADMIN_DELETED_PRODUCT_COUNT_FAIL,
                deletedProductsCount: 0
            };

        case AdminTypes.CLEAR_DELETED_PRODUCTS:
            return {
                ...state,
                action: AdminTypes.CLEAR_DELETED_PRODUCTS,
                deletedProducts: []
            };
        case AdminTypes.CLEAR_DELETED_PRODUCT_COUNT:
            return {
                ...state,
                action: AdminTypes.CLEAR_DELETED_PRODUCT_COUNT,
                deletedProductsCount: 0
            };
        case AdminTypes.CLEAR_ACTION:
            return {
                ...state,
                action: AdminTypes.CLEAR_ACTION
            };
        case AdminTypes.GET_ALL_EXPERT_CONTRIBUTER:
            return {
                ...state,
                action: AdminTypes.GET_ALL_EXPERT_CONTRIBUTER
            }
        case AdminTypes.GET_ALL_EXPERT_CONTRIBUTER_SUCCESS:
            return {
                ...state,
                action: AdminTypes.GET_ALL_EXPERT_CONTRIBUTER_SUCCESS,
                expertContributers: [...state.expertContributers, ...action.payload]
            }
        case AdminTypes.GET_ALL_EXPERT_CONTRIBUTER_FAIL:
            return {
                ...state,
                action: AdminTypes.GET_ALL_EXPERT_CONTRIBUTER_FAIL,
            }
        case AdminTypes.ADD_EXPERT_CONTRIBUTER:
            return {
                ...state,
                action: AdminTypes.ADD_EXPERT_CONTRIBUTER
            }
        case AdminTypes.ADD_EXPERT_CONTRIBUTER_SUCCESS:
            return {
                ...state,
                action: AdminTypes.ADD_EXPERT_CONTRIBUTER_SUCCESS,
                expertContributers: action.payload ? [action.payload, ...state.expertContributers] : state.expertContributers
            }
        case AdminTypes.REMOVE_EXPERT_CONTRIBUTER_SUCCESS:
            return {
                ...state,
                action: AdminTypes.REMOVE_EXPERT_CONTRIBUTER_SUCCESS,
                expertContributers: []
            }
        case AdminTypes.ADD_EXPERT_CONTRIBUTER_FAIL:
            return {
                ...state,
                action: AdminTypes.ADD_EXPERT_CONTRIBUTER_FAIL,
            }
        case AdminTypes.GET_ALL_EXPERT_CONTRIBUTER_COUNT:
            return {
                ...state,
                action: AdminTypes.GET_ALL_EXPERT_CONTRIBUTER_COUNT
            }
        case AdminTypes.GET_ALL_EXPERT_CONTRIBUTER_COUNT_SUCCESS:
            return {
                ...state,
                action: AdminTypes.GET_ALL_EXPERT_CONTRIBUTER_COUNT_SUCCESS,
                expertContributersCount: action.payload
            }
        case AdminTypes.GET_ALL_EXPERT_CONTRIBUTER_COUNT_FAIL:
            return {
                ...state,
                action: AdminTypes.GET_ALL_EXPERT_CONTRIBUTER_COUNT_FAIL,
            }
        case AdminTypes.CLEAR_ALL_EXPERT_CONTRIBUTORS_AND_COUNT:
            return {
                ...state,
                expertContributers: [],
                expertContributersCount: 0
            }
        case AdminTypes.GET_MARKETPLACE_STATISTICS:
            return {
                ...state,
                action: AdminTypes.GET_MARKETPLACE_STATISTICS
            }
        case AdminTypes.GET_MARKETPLACE_STATISTICS_SUCCESS:
            return {
                ...state,
                action: AdminTypes.GET_MARKETPLACE_STATISTICS_SUCCESS,
                marketplaceStatistics: action.payload
            }
        case AdminTypes.GET_MARKETPLACE_STATISTICS_FAIL:
            return {
                ...state,
                action: AdminTypes.GET_MARKETPLACE_STATISTICS_FAIL,
            }
        case AdminTypes.GET_MARKETPLACE_STATISTICS_COUNT:
            return {
                ...state,
                action: AdminTypes.GET_MARKETPLACE_STATISTICS_COUNT
            }
        case AdminTypes.GET_MARKETPLACE_STATISTICS_COUNT_SUCCESS:
            return {
                ...state,
                action: AdminTypes.GET_MARKETPLACE_STATISTICS_COUNT_SUCCESS,
                marketplaceStatisticsCount: action.payload
            }
        case AdminTypes.GET_MARKETPLACE_STATISTICS_COUNT_FAIL:
            return {
                ...state,
                action: AdminTypes.GET_MARKETPLACE_STATISTICS_COUNT_FAIL,
            }
        case AdminTypes.CLEAR_MARKETPLACE_STATISTICS_AND_COUNT:
            return {
                ...state,
                marketplaceStatistics: [],
                marketplaceStatisticsCount: 0
            }
        case AdminTypes.GET_STATISTICS_SEARCH:
            return {
                ...state,
                action: AdminTypes.GET_STATISTICS_SEARCH
            }

        case AdminTypes.GET_STATISTICS_SEARCH_SUCCESS:
            return {
                ...state,
                action: AdminTypes.GET_STATISTICS_SEARCH_SUCCESS,
                statisticSearchResults: action.payload
            }
        case AdminTypes.GET_STATISTICS_SEARCH_FAIL:
            return {
                ...state,
                action: AdminTypes.GET_STATISTICS_SEARCH_FAIL
            }
        case AdminTypes.CLEAR_GET_STATISTICS_SEARCH:
            return {
                ...state,
                action: AdminTypes.CLEAR_GET_STATISTICS_SEARCH,
                statisticSearchResults: []
            }
        case AdminTypes.GET_ALL_COUPON_DETAILS:
            return {
                ...state,
                action: AdminTypes.GET_ALL_COUPON_DETAILS
            }
        case AdminTypes.GET_ALL_COUPON_DETAILS_SUCCESS:
            return {
                ...state,
                action: AdminTypes.GET_ALL_COUPON_DETAILS_SUCCESS,
                couponDetails: [...state.couponDetails, ...action.payload]
            }
        case AdminTypes.GET_ALL_COUPON_DETAILS_FAIL:
            return {
                ...state,
                action: AdminTypes.GET_ALL_COUPON_DETAILS_FAIL,
            }
        case AdminTypes.ADD_COUPON_DETAILS:
            return {
                ...state,
                action: AdminTypes.ADD_COUPON_DETAILS
            }
        case AdminTypes.ADD_COUPON_DETAILS_SUCCESS:
            return {
                ...state,
                action: AdminTypes.ADD_COUPON_DETAILS_SUCCESS,
                couponDetails: action.payload ? [action.payload, ...state.couponDetails] : state.couponDetails
            }
        case AdminTypes.ADD_COUPON_DETAILS_FAIL:
            return {
                ...state,
                action: AdminTypes.ADD_COUPON_DETAILS_FAIL,
            }
        case AdminTypes.GET_ALL_COUPON_DETAILS_COUNT:
            return {
                ...state,
                action: AdminTypes.GET_ALL_COUPON_DETAILS_COUNT
            }
        case AdminTypes.GET_ALL_COUPON_DETAILS_COUNT_SUCCESS:
            return {
                ...state,
                action: AdminTypes.GET_ALL_COUPON_DETAILS_COUNT_SUCCESS,
                couponDetailsCount: action.payload
            }
        case AdminTypes.GET_ALL_COUPON_DETAILS_COUNT_FAIL:
            return {
                ...state,
                action: AdminTypes.GET_ALL_COUPON_DETAILS_COUNT_FAIL,
            }
        case AdminTypes.CLEAR_ALL_COUPON_DETAILS_AND_COUNT:
            return {
                ...state,
                couponDetails: [],
                couponDetailsCount: 0
            }

        case AdminTypes.FETCHING_PRODUCTS_FOR_PRIORITIZE:
            return {
                ...state,
                action: AdminTypes.FETCHING_PRODUCTS_FOR_PRIORITIZE,
                isProductsFetching: true
            }
        case AdminTypes.FETCH_PRODUCTS_FOR_PRIORITIZE_SUCCESS:
            const filterPrioritized = action.payload.map(prioritizedProduct => {
                if (prioritizedProduct.LIST_PRIORITY > 0) {
                    return {
                        [prioritizedProduct._id]: prioritizedProduct.LIST_PRIORITY
                    }
                }
                return undefined;
            })

            const alreadyPrioritized = Object.assign({}, ...filterPrioritized, state.alreadyPrioritized);
            return {
                ...state,
                action: AdminTypes.FETCH_PRODUCTS_FOR_PRIORITIZE_SUCCESS,
                publishedProducts: [...state.publishedProducts, ...action.payload],
                alreadyPrioritized,
                isProductsFetching: false
            }
        case AdminTypes.FETCH_PRODUCTS_FOR_PRIORITIZE_FAIL:
            return {
                ...state,
                action: AdminTypes.FETCH_PRODUCTS_FOR_PRIORITIZE_FAIL,
                isProductsFetching: false
            }

        case AdminTypes.FETCHING_PUBLISHED_PRODUCTS_COUNT:
            return {
                ...state,
                action: AdminTypes.FETCHING_PUBLISHED_PRODUCTS_COUNT
            }
        case AdminTypes.FETCH_PUBLISHED_PRODUCTS_COUNT_SUCCESS:
            return {
                ...state,
                action: AdminTypes.FETCH_PUBLISHED_PRODUCTS_COUNT_SUCCESS,
                publishedProductCount: action.payload
            }
        case AdminTypes.FETCH_PUBLISHED_PRODUCTS_COUNT_FAIL:
            return {
                ...state,
                action: AdminTypes.FETCH_PUBLISHED_PRODUCTS_COUNT_FAIL,
            }
        case AdminTypes.SET_PRODUCTS_PRIORITY:
            return {
                ...state,
                action: AdminTypes.SET_PRODUCTS_PRIORITY
            }
        case AdminTypes.SET_PRODUCTS_PRIORITY_SUCCESS:
            const obj = (typeof action.payload === 'object') ? { [action.payload._id]: action.payload.LIST_PRIORITY } : {}
            const newPriority = { ...state.alreadyPrioritized, ...obj }
            return {
                ...state,
                action: AdminTypes.SET_PRODUCTS_PRIORITY_SUCCESS,
                alreadyPrioritized: newPriority
            }
        case AdminTypes.SET_PRODUCTS_PRIORITY_FAIL:
            return {
                ...state,
                action: AdminTypes.SET_PRODUCTS_PRIORITY_FAIL,
            }
        case AdminTypes.CLEAR_ALL_PRODUCTS_PRIORITIZE:
            return {
                ...state,
                publishedProducts: [],
                publishedProductCount: 0
            }
        case AdminTypes.GET_MARKETING_CAMPAIGN_STATISTICS:
            return{
                ...state,
                action: AdminTypes.GET_MARKETING_CAMPAIGN_STATISTICS
            }
        case AdminTypes.GET_MARKETING_CAMPAIGN_STATISTICS_SUCCESS:
                return{
                    ...state,
                    action: AdminTypes.GET_MARKETING_CAMPAIGN_STATISTICS_SUCCESS,
                    marketingCompaignStatistics: action.payload
                }
        case AdminTypes.GET_MARKETING_CAMPAIGN_STATISTICS_FAIL:
                return{
                    ...state,
                    action: AdminTypes.GET_MARKETING_CAMPAIGN_STATISTICS_FAIL,
                }
                case AdminTypes.GET_MARKETING_CAMPAIGN_STATISTICS_COUNT:
            return{
                ...state,
                action: AdminTypes.GET_MARKETING_CAMPAIGN_STATISTICS_COUNT
            }
        case AdminTypes.GET_MARKETING_CAMPAIGN_STATISTICS_COUNT_SUCCESS:
                return{
                    ...state,
                    action: AdminTypes.GET_MARKETING_CAMPAIGN_STATISTICS_COUNT_SUCCESS,
                    marketingCompaignStatisticsCount:  action.payload
                }
        case AdminTypes.GET_MARKETING_CAMPAIGN_STATISTICS_COUNT_FAIL:
                return{
                    ...state,
                    action: AdminTypes.GET_MARKETING_CAMPAIGN_STATISTICS_COUNT_FAIL,
                }
        case AdminTypes.CLEAR_MARKETING_CAMPAIGN_STATISTICS_AND_COUNT: 
                return{
                    ...state,
                    marketingCompaignStatistics: [],
                    marketingCompaignStatisticsCount: 0
                }   
        case AdminTypes.GET_MARKETING_CAMPAIGN_SUMMARY:
            return {
                ...state,
                action: AdminTypes.GET_MARKETING_CAMPAIGN_SUMMARY
            }
        case AdminTypes.GET_MARKETING_CAMPAIGN_SUMMARY_SUCCESS:
            return {
                ...state,
                action: AdminTypes.GET_MARKETING_CAMPAIGN_SUMMARY_SUCCESS,
                marketingCompaignSummary: action.payload
            }
        case AdminTypes.GET_MARKETING_CAMPAIGN_SUMMARY_FAIL:
            return {
                ...state,
                action: AdminTypes.GET_MARKETING_CAMPAIGN_SUMMARY_FAIL,
            }
        case AdminTypes.GET_MARKETING_CAMPAIGN_SUMMARY_COUNT:
            return {
                ...state,
                action: AdminTypes.GET_MARKETING_CAMPAIGN_SUMMARY_COUNT
            }
        case AdminTypes.GET_MARKETING_CAMPAIGN_SUMMARY_COUNT_SUCCESS:
            return {
                ...state,
                action: AdminTypes.GET_MARKETING_CAMPAIGN_SUMMARY_COUNT_SUCCESS,
                marketingCompaignSummaryCount: action.payload
            }
        case AdminTypes.GET_MARKETING_CAMPAIGN_SUMMARY_COUNT_FAIL:
            return {
                ...state,
                action: AdminTypes.GET_MARKETING_CAMPAIGN_SUMMARY_COUNT_FAIL,
            }
        case AdminTypes.CLEAR_GET_MARKETING_CAMPAIGN_SUMMARY_AND_COUNT:
            return {
                ...state,
                marketingCompaignSummary: [],
                marketingCompaignSummaryCount: 0
            }
        case AdminTypes.GET_NEWS_FEED_TEXT_LINK_SUMMARY:
            return {
                ...state,
                action: AdminTypes.GET_NEWS_FEED_TEXT_LINK_SUMMARY
            }
        case AdminTypes.GET_NEWS_FEED_TEXT_LINK_SUMMARY_SUCCESS:
            return {
                ...state,
                action: AdminTypes.GET_NEWS_FEED_TEXT_LINK_SUMMARY_SUCCESS,
                newsfeedTextLinkSummary: [...state.newsfeedTextLinkSummary, ...action.payload]
            }
        case AdminTypes.GET_NEWS_FEED_TEXT_LINK_SUMMARY_FAIL:
            return {
                ...state,
                action: AdminTypes.GET_NEWS_FEED_TEXT_LINK_SUMMARY_FAIL,
            }
        case AdminTypes.GET_NEWS_FEED_TEXT_LINK_SUMMARY_COUNT:
            return {
                ...state,
                action: AdminTypes.GET_NEWS_FEED_TEXT_LINK_SUMMARY_COUNT
            }
        case AdminTypes.GET_NEWS_FEED_TEXT_LINK_SUMMARY_COUNT_SUCCESS:
            return {
                ...state,
                action: AdminTypes.GET_NEWS_FEED_TEXT_LINK_SUMMARY_COUNT_SUCCESS,
                newsfeedTextLinkSummaryCount: action.payload
            }
        case AdminTypes.GET_NEWS_FEED_TEXT_LINK_SUMMARY_COUNT_FAIL:
            return {
                ...state,
                action: AdminTypes.GET_NEWS_FEED_TEXT_LINK_SUMMARY_COUNT_FAIL,
            }
        case AdminTypes.CLEAR_GET_NEWS_FEED_TEXT_LINK_SUMMARY_AND_COUNT:
            return {
                ...state,
                newsfeedTextLinkSummary: [],
                newsfeedTextLinkSummaryCount: 0
            }
        case AdminTypes.GET_ALL_REWARD_REDEMPTION:
            return {
                ...state,
                action: AdminTypes.GET_ALL_REWARD_REDEMPTION
            }
        case AdminTypes.GET_ALL_REWARD_REDEMPTION_SUCCESS:
            return {
                ...state,
                action: AdminTypes.GET_ALL_REWARD_REDEMPTION_SUCCESS,
                rewardRedemption: action.payload
            }
        case AdminTypes.GET_ALL_REWARD_REDEMPTION_FAIL:
            return {
                ...state,
                action: AdminTypes.GET_ALL_REWARD_REDEMPTION_FAIL,
            }
        case AdminTypes.UPDATE_REWARD_REDEMPTION:
            return {
                ...state,
                action: AdminTypes.UPDATE_REWARD_REDEMPTION,
            }
        case AdminTypes.UPDATE_REWARD_REDEMPTION_SUCCESS:
            return {
                ...state,
                action: AdminTypes.UPDATE_REWARD_REDEMPTION_SUCCESS,
                rewardRedemption: []
            }
        case AdminTypes.UPDATE_REWARD_REDEMPTION_FAIL:
            return {
                ...state,
                action: AdminTypes.FETCH_LIST_REWARD_REDEMPTION_FAIL,
            }
        case AdminTypes.ADD_REWARD_REDEMPTION:
            return {
                ...state,
                action: AdminTypes.ADD_REWARD_REDEMPTION,
                rewardsInvalidIds: []
            }
        case AdminTypes.ADD_REWARD_REDEMPTION_SUCCESS:
            return {
                ...state,
                action: AdminTypes.ADD_REWARD_REDEMPTION_SUCCESS,
                rewardsInvalidIds: action.payload
            }
        case AdminTypes.ADD_REWARD_REDEMPTION_FAIL:
            return {
                ...state,
                action: AdminTypes.ADD_REWARD_REDEMPTION_FAIL,
                rewardsInvalidIds: action.payload
            }
        case AdminTypes.GET_ALL_REWARD_REDEMPTION_COUNT:
            return {
                ...state,
                action: AdminTypes.GET_ALL_REWARD_REDEMPTION_COUNT
            }
        case AdminTypes.GET_ALL_REWARD_REDEMPTION_COUNT_SUCCESS:
            return {
                ...state,
                action: AdminTypes.GET_ALL_REWARD_REDEMPTION_COUNT_SUCCESS,
                rewardRedemptionCount: action.payload
            }
        case AdminTypes.GET_ALL_REWARD_REDEMPTION_COUNT_FAIL:
            return {
                ...state,
                action: AdminTypes.GET_ALL_REWARD_REDEMPTION_COUNT_FAIL,
            }
        case AdminTypes.CLEAR_ALL_REWARD_REDEMPTION_AND_COUNT:
            return {
                ...state,
                rewardRedemption: [],
                rewardRedemptionCount: 0,
                rewardRedemptionList: [],
                rewardsInvalidIds: []
            }
        case AdminTypes.GET_EMAIL_CONFIG_SUCCESS:
            return {
                ...state,
                action: AdminTypes.GET_EMAIL_CONFIG_SUCCESS,
                config: action.payload
            }
        case AdminTypes.GET_EMAIL_CONFIG_FAIL:
            return {
                ...state,
                action: AdminTypes.GET_EMAIL_CONFIG_FAIL,
            }
        case AdminTypes.UPDATE_EMAIL_DIET_SUCCESS:
            return {
                ...state,
                action: AdminTypes.UPDATE_EMAIL_DIET_SUCCESS,
                config: action.payload
            }
        case AdminTypes.UPDATE_EMAIL_DIET_FAIL:
            return {
                ...state,
                action: AdminTypes.UPDATE_EMAIL_DIET_FAIL,
            }
        case AdminTypes.GET_AUTO_CONNECTION_REQUESTS_STATISTICS:
            return{
                ...state,
                action: AdminTypes.GET_AUTO_CONNECTION_REQUESTS_STATISTICS
            }
        case AdminTypes.GET_AUTO_CONNECTION_REQUESTS_STATISTICS_SUCCESS:
                return{
                    ...state,
                    action: AdminTypes.GET_AUTO_CONNECTION_REQUESTS_STATISTICS_SUCCESS,
                    autoConnectionRequestStatistics: action.payload
                    
                }
        case AdminTypes.GET_AUTO_CONNECTION_REQUESTS_STATISTICS_FAIL:
                return{
                    ...state,
                    action: AdminTypes.GET_AUTO_CONNECTION_REQUESTS_STATISTICS_FAIL,
                }
        case AdminTypes.GET_AUTO_CONNECTION_REQUESTS_STATISTICS_COUNT:
            return{
                ...state,
                action: AdminTypes.GET_AUTO_CONNECTION_REQUESTS_STATISTICS_COUNT
            }
        case AdminTypes.GET_AUTO_CONNECTION_REQUESTS_STATISTICS_COUNT_SUCCESS:
                return{
                    ...state,
                    action: AdminTypes.GET_AUTO_CONNECTION_REQUESTS_STATISTICS_COUNT_SUCCESS,
                    autoConnectionRequestStatisticsCount:  action.payload
                }
        case AdminTypes.GET_AUTO_CONNECTION_REQUESTS_STATISTICS_COUNT_FAIL:
                return{
                    ...state,
                    action: AdminTypes.GET_AUTO_CONNECTION_REQUESTS_STATISTICS_COUNT_FAIL,
                }    
        case AdminTypes.CLEAR_AUTO_CONNECTION_REQUESTS_STATISTICS_AND_COUNT: 
        return{
            ...state,
            autoConnectionRequestStatistics: [],
            autoConnectionRequestStatisticsCount: 0
        }          
        case AdminTypes.UPDATING_EVENT_DISCOUNT:
            return{
                ...state,
                action:AdminTypes.UPDATING_EVENT_DISCOUNT
            };
        case AdminTypes.UPDATING_EVENT_DISCOUNT_SUCCESS:
            return{
                ...state,
                action:AdminTypes.UPDATING_EVENT_DISCOUNT_SUCCESS
            };
        case AdminTypes.UPDATING_EVENT_DISCOUNT_FAIL:
            return{
                ...state,
                action: AdminTypes.UPDATING_EVENT_DISCOUNT_FAIL
        };
        default:
            return {
                ...state
            }
    }
}
