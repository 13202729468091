import React from 'react';
import SuccessPopupV2 from './successPopupV2';
import { POP_ALERT } from '../../types/common';
import './index.css';


function AlertHeader(
    {
        children,
        title,
        text,
        customClass,
        onClickClose,
        onClickOk,
        type
    }
) {
    return (
        <React.Fragment>
            <div className={`popup-overlay ${customClass ? customClass : ''}`}>
                <div className="popup-alert">
                    <div className="popup-wrapper">
                        <button type="button" onClick={onClickClose} className="btn popup-close"><i className="fas fa-times" /></button>
                        <div className="popup-icon-animation text-center m-b15">
                            {children}
                        </div>
                        <h4 className="text-center m-b12">{title}</h4>
                        <div className="popup-text text-center">{text}</div>
                        <div className="popup-buttons m-t15 text-right">
                            {/* <i className={isButtonClicked ? "fa fa-spinner fa-spin" : "scc"}></i> */}
                            <span/>
                            <button type="button" onClick={onClickOk} className="btn popup-btn success">Ok</button>
                            {/* {type !== POP_ALERT.SUCCESS && <button type="button" onClick={onClickClose} className="btn popup-btn">Cancel</button>} */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

function AlertError() {
    return (
        <div className="f-modal-icon f-modal-error animate">
            <span className="f-modal-x-mark">
                <span className="f-modal-line f-modal-left animateXLeft" />
                <span className="f-modal-line f-modal-right animateXRight" />
            </span>
            <div className="f-modal-placeholder" />
            <div className="f-modal-fix" />
        </div>
    );
}

function AlertSuccess() {
    return (
        <div className="f-modal-icon f-modal-success animate">
            <span className="f-modal-line f-modal-tip animateSuccessTip" />
            <span className="f-modal-line f-modal-long animateSuccessLong" />
            <div className="f-modal-placeholder" />
            <div className="f-modal-fix" />
        </div>
    );
}

function AlertWarning() {
    return (
        <div className="f-modal-icon f-modal-warning scaleWarning">
            <span className="f-modal-body pulseWarningIns" />
            <span className="f-modal-dot pulseWarningIns" />
        </div>
    );
}

export default class PopupAlert extends React.PureComponent {

    render() {
        let {
            popAlertType,
            popAlertText,
            popAlertTitle,
            description='',
            customClass='',
            dispatch
        } = this.props;

        return (
            <React.Fragment>
                {![POP_ALERT.HIDE , POP_ALERT.SUCCESS_V2].includes(popAlertType) && 
                <AlertHeader
                    type={popAlertType}
                    title={popAlertTitle}
                    customClass={customClass}
                    text={popAlertText}
                    onClickClose={() => {
                        dispatch({
                            type: POP_ALERT.HIDE
                        })
                    }}
                    onClickOk={() => {
                        dispatch({
                            type: POP_ALERT.HIDE
                        })
                    }}
                >
                    {popAlertType === POP_ALERT.SUCCESS && <AlertSuccess />}
                    {popAlertType === POP_ALERT.ERROR && <AlertError />}
                    {popAlertType === POP_ALERT.WARNING && <AlertWarning />}
                </AlertHeader>
                }

                { POP_ALERT.HIDE !== popAlertType && [POP_ALERT.SUCCESS_V2].includes(popAlertType) && 
                <SuccessPopupV2
                    type={popAlertType}
                    title={popAlertTitle}
                    text={popAlertText}
                    description={description}
                    onClickClose={() => {
                        dispatch({
                            type: POP_ALERT.HIDE
                        })
                    }}
                    onClickOk={() => {
                        dispatch({
                            type: POP_ALERT.HIDE
                        })
                    }}
                />
                }
            </React.Fragment>
        )
    }

}
