import React, { Component } from "react";
import "./index.css";
import avatar from "./../../../../assets/img/default-male.jpg";
import { isURL } from "../../../../libs/validation";
import { imageExists } from "../../../../libs/image";

class NewsFeedAvatar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      profile: avatar
    };
  }

  componentDidMount() {
    if (isURL(this.props.profilePicture)) {
      imageExists(this.props.profilePicture, (exists) => {
          if (exists ) {
            this.setState({ profile: this.props.profilePicture });
          } 
      });
    }
  }

  componentDidUpdate(prevProps, prevSt) {
    if (isURL(this.props.profilePicture) && (prevSt.profile !== this.state.profile || prevSt.profile !== this.props.profilePicture) ) {
      imageExists(this.props.profilePicture, (exists) => {
          if (exists ) {
            this.setState({ profile: this.props.profilePicture });
          } 
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="profile_img_wrp ">
          <div className="profile_container">
            <img alt="profile" className="img-fluid profile-image w-100" src={this.state.profile} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src=avatar;
                    imageExists(this.state.profile, (exists) => {
                        if (exists) {
                            currentTarget.src=this.state.profile;
                        } else {
                            if(localStorage.getItem('imgKey')) {
                               setTimeout(() => {
                                   imageExists(this.state.profile, (exists) => {
                                       if (exists ) {
                                           currentTarget.src=this.state.profile;
                                       } else {
                                           currentTarget.src=avatar;
                                       }
                                   });
                               }, 3000);
                            }
                        }
                    });
                    }} />
            {/* {memberType && <div className={memeberStyles}>{memberType}</div>} */}
          </div>
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }

}

NewsFeedAvatar.defaultProps = {
  profile: null,
  height: null,
};

export { NewsFeedAvatar };
