import React, { Component } from "react";
import "./index.css";
import classname from "classnames";

class RealTimeSearchDropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            query: '',
            searchTerm: '',
            isOktoHide: true
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    search = () => {
        this.setState({ query: this.state.searchTerm, popupVisible: true });
        this.props.onKeyUp(this.state.searchTerm);
    }

    handleClick() {
        if (!this.state.popupVisible) {
            document.addEventListener("click", this.handleOutsideClick, false);
        } else {
            document.removeEventListener("click", this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
        if(!this.props.noClear){
            this.clearQuery();
        }
    }

    handleOutsideClick = (e) => {
        if (this.node && this.node.contains && this.node.contains(e.target)) {
            return;
        }
        if(this.state.isOktoHide){
            this.handleClick();
        }
    };

    clearQuery = () => {this.setState({query: '',searchTerm: ''})};

    _returnSelected = (item, isSelected) => {
        this.handleClick();
        this.props.onClick( item, isSelected);
        if(this.props.noClear){
            this.setState({
                searchTerm: item.NAME
            })
        }
    };

    render() {
        let dropdown = classname(
            "dropdown_body ",
            this.props.size === "small"
                ? "dropdown_body_small"
                : "dropdown_body_regular",
            this.state.query ? "media_active" : "media_hide"
        );

        let inputStyle = classname(
            this.props.size === "small"
                ? " matirial_custom_input matirial_custom_input_small"
                : "matirial_custom_input "+this.props.customStyle
        );

        let { data, fetching, selectedData } = this.props;

        let items = data.map((item, i) => {
            let isSelected = selectedData.indexOf(item._id) >= 0;
                return (
                    !isSelected && <DropdownItem key={item._id} text={item.NAME} onClick={this._returnSelected.bind(this, item, isSelected)} className='dropdown_item' />
                )
        });

        return (
            <React.Fragment>
                <input id={this.props.elemId ? this.props.elemId : null} value={this.state.searchTerm} onChange={(e)=>{this.setState({searchTerm: e.target.value})}} type={'text'} className={inputStyle} onKeyUp={this.search} required onClick={this.handleClick}/>
                {this.state.popupVisible && <div className={dropdown} style={{ zIndex: 9999 }} >
                    {!fetching && items}
                    {fetching && <DropdownItem text={"Searching ..."} className={'dropdown_item'} />}
                </div>}
            </React.Fragment>
        );
    }
}

const DropdownItem = ({ text, className, onClick = () => { } }) => {
    return (
        <div className={className} onClick={onClick}>
            {text}
        </div>
    );
};

RealTimeSearchDropdown.defaultProps = {
    type: "text",
    size: null
};

export { RealTimeSearchDropdown };