import React, { Component } from 'react';

class CustomTimePicker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            time: '10:00'
        }
    }

    onChange = time => this.setState({ time })

    render() {
        return (
            <input type='time' className="matirial_custom_input" />
        );
    }
}

export { CustomTimePicker };