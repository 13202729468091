import contactTypes from '../types/contacts';

export const initialState = {
    fetching: false,
    contacts: [],
    invitedContacts: [],
    error: {},
    action: 0,
    windowsLiveCode: null
};

export const contacts = (state = initialState, action) => {
    switch (action.type) {
        case contactTypes.FETCHING_CONTACTS:
            return {
                ...state,
                fetching: true,
                action: contactTypes.FETCHING_CONTACTS
            };

        case contactTypes.FETCHING_CONTACTS_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: contactTypes.FETCHING_CONTACTS_SUCCESS,
                contacts: action.payload
            };
        case contactTypes.FETCHING_CONTACTS_FAIL:
            return {
                ...state,
                fetching: false,
                action: contactTypes.FETCHING_CONTACTS_FAIL,
                error: action.payload
            };
        case contactTypes.SENDING_INVITATIONS:
            return {
                ...state,
                fetching: true,
                action: contactTypes.SENDING_INVITATIONS,
            };
        case contactTypes.SENDING_INVITATIONS_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: contactTypes.SENDING_INVITATIONS_SUCCESS,
                invitedContacts:action.payload,
                error: action.error
            };
        case contactTypes.SENDING_INVITATIONS_FAIL:
            return {
                ...state,
                fetching: false,
                action: contactTypes.SENDING_INVITATIONS_FAIL,
                error: action.error
            };

        case contactTypes.SENDING_CUSTOM_INVITATIONS:
            return {
                ...state,
                fetching: true,
                action: contactTypes.SENDING_CUSTOM_INVITATIONS,
            };
        case contactTypes.SENDING_CUSTOM_INVITATIONS_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: contactTypes.SENDING_CUSTOM_INVITATIONS_SUCCESS,
                invitedContacts:action.payload,
                error: action.error
            };
        case contactTypes.SENDING_CUSTOM_INVITATIONS_FAIL:
            return {
                ...state,
                fetching: false,
                action: contactTypes.SENDING_CUSTOM_INVITATIONS_FAIL,
                error: action.error
            };

        case contactTypes.WINDOWS_LIVE_CODE:
            return {
                ...state,
                fetching: false,
                action: contactTypes.WINDOWS_LIVE_CODE,
                windowsLiveCode: action.payload
            };
        case contactTypes.VALIDATING_INVITATION:
            return {
                ...state,
                fetching: true,
                action: contactTypes.VALIDATING_INVITATION,
            };
        case contactTypes.VALIDATING_INVITATION_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: contactTypes.VALIDATING_INVITATION_SUCCESS,
                invitedContacts:action.payload,
            };
        case contactTypes.VALIDATING_INVITATION_FAIL:
            return {
                ...state,
                fetching: false,
                action: contactTypes.VALIDATING_INVITATION_FAIL,
                error:action.payload,
            };
        default:
            return state
    }
};