import React, { Component } from "react";
import "./index.css";
import { Break } from "./../../../common";
import classname from "classnames";
import { Collapse } from "react-collapse";

class ViewCard extends Component {
  state = { isOpen: true };

  getIcon = icon => {
    if (icon) {
      return <div className="m-r15 card-icon">{icon}</div>;
    } else {
      return null;
    }
  };

  getPostIcon = postIcon => {
    if (postIcon) {
      return <div className="m-l10 card-post-icon">{postIcon}</div>;
    } else {
      return null;
    }
  };

  getTitle = title => {
    if (title) {
      return (
        <div>
          <h3
            style={{ marginTop: "-2px" }}
            className=" font_dark_gray_two font_20 h3-left"
          >
            {title}
          </h3>
        </div>
      );
    } else {
      return null;
    }
  };

  getLeftTitle = leftTitle => {
    if (leftTitle) {
      return (
        <h3
          style={this.props.leftTitleAction ? { marginTop: "-2px", cursor: 'pointer' } : { marginTop: "-2px" }}
          className=" font_blue font_16 font_regular left_title_action h3-right"
          onClick={() => this.props.leftTitleAction && this.props.leftTitleAction()}
        >
          {leftTitle}
        </h3>
      );
    } else {
      return null;
    }
  };

  getActionIcon = actionIcon => {
    if (actionIcon) {
      return (
        <div>
          {this.state.isOpen === true ? (
            <i className="font_20 fas fa-angle-down font_gray_05" />
          ) : (
            <i className="font_20 fas fa-angle-up font_gray_05" />
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  getCollapse = children => {
    if (children) {
      return (
        <div>
          <Break color={this.state.isOpen === true ? "#979797" : null} />{" "}
          <div>{children}</div>
        </div>
      );
    } else {
      return (
        null
      );
    }
  };

  getFooter = (footer, text, loading) => {
    if ((footer || this.props.pagination) && (this.props.hideLoadMoreBtn !== undefined && !this.props.hideLoadMoreBtn)) {
      return (
        <div className="view_card_footer d-flex flex-column">
          <div className="nextPrevious_btn p-all30 p-t0 clearfix">
            {(this.props.pagination && this.props.previous && this.props.onPrevious) && <button className="btn_hallow_blue font_light float-left" onClick={this.props.onPrevious}><i className="fas fa-arrow-left" /> <span className="pn_btn_text">{this.props.previous}</span></button>}

            {(this.props.pagination && this.props.next && this.props.onNext) && <button className="btn_hallow_blue font_blue font_light float-right" disabled={this.props.loading || this.props.countLoading} onClick={this.props.onNext}><i className={(this.props.loading || this.props.countLoading) ? "fa fa-spinner fa-spin" : "scc"} /> <span className="pn_btn_text">{this.props.next}</span> <i className="fas fa-arrow-right" /></button>}
          </div>

          {this.props.footer && <button className={`buton-more-block ${this.props.loadMorePlacement}`} onClick={this.props.loadMore} disabled={loading}>
            {!loading && <i className="fas font_blue font_15 m-r10 fa-sync" />}
            {loading && <i className="fa-spin fas font_blue font_15 m-r10 fa-sync" />}
            {loading ? 'Loading...' : text}
          </button>}
        </div>
      );
    } else {
      return null;
    }
  };

  open = (state, e = null) => {
    if (state) {
      this.setState(prevState => {
        return { isOpen: !prevState.isOpen };
      });
    } else {
      this.setState({ isOpen: true });
    }
    if (this.props.customAction) {
      this.props.customAction(e)
    }
  };

  render() {
    let heder = classname("justify-content-between d-flex view_card_header ", {
      view_card_header_hover: this.props.collapse
    });
    return (
      <div className={`view_card m-b25 ${this.props.customClass ? this.props.customClass : ""}`}>
        <div
          className={heder}
          onClick={e => this.open(this.props.collapse, e)}
        >
          <div className="d-flex filterResult_name flex-wrap">
            {this.getIcon(this.props.icon)}
            {this.getTitle(this.props.title)}
            {this.getPostIcon(this.props.postIcon)}
          </div>
          {this.getActionIcon(this.props.actionIcon)}
          {this.getLeftTitle(this.props.leftTitle)}
        </div>
        <Collapse isOpened={this.state.isOpen}>
          {this.getCollapse(this.props.children)}
          {this.getFooter(this.props.footer, this.props.footerText, this.props.loading)}
        </Collapse>

      </div>
    );
  }
}

export { ViewCard };
