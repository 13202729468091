import React, { Component } from "react";
import "./index.css";
import sample from "./../../../../assets/img/companyAvatar.jpg";
import { ImageLoading } from "./../../loaders/imageLoader";
import classNames from "classnames";
import { Link } from 'react-router-dom';
import { getPreview } from "../../../../libs/textFormat"
import { imageExists } from '../../../../libs/image';
import defaultAvatar from "./../../../../assets/img/default-male.jpg";
import defaultCompanyAvatar from "./../../../../assets/img/default-corporate-image.jpg";
import productAvatar from "./../../../../assets/sample/briefcase.svg";
import { STATUS } from '../../../../types/common'

class ItemBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatar: this.props.isCompany ? defaultCompanyAvatar :
        this.props.isProduct ? productAvatar : defaultAvatar
    }
  }

  componentDidUpdate(prevProps, prevState){
    const { imgSrc, isCompany, isProduct } = this.props;
    if (prevState.avatar !== imgSrc && /(ftp|http|https):\/\/(\w:{0,1}\w*@)?(\S)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/.test(imgSrc)) {
        imageExists(imgSrc, (exists => { if(exists) this.setState({avatar: imgSrc})}), (!isCompany && !isProduct))
    }
  }

  render() {
    let {onClick, editOption, profile, deleteOption, onEdit, onConntectionSuggestion, onAdd, resendOption, boxShadow, onResend, rejected, subText, subTitle, height, width, title, redirectLink, years, imgSrc, status, editProps } = this.props;

    let avatarStyle = {
      backgroundImage: "url(" + this.state.avatar + ")",
      height: height + "px",
      width: width + "px",
      cursor: 'pointer'
    };
    let loaderBoxStyle = {
      height: height + "px",
      width: width + "px"
    };

    let label = null;
    let display = null;
    let name = null;
    subTitle = null;
    let duration = null;
    let media = classNames("media_item_box", { media_item_box_shdow: boxShadow }, rejected ? "imgDisable" : "");

    if (imgSrc === null) {
      display = (
        <div
          className="loader_box d-flex align-items-center justify-content-center"
          style={loaderBoxStyle}
        >
          <ImageLoading />
        </div>
      );
    } else if (profile === true) {
      display = (
          <Link to={redirectLink ? redirectLink : window.location.pathname}>
            <div className={media} style={avatarStyle}>
              <div className="media_item_box_action profile-professional-experience">
                  {deleteOption === true ? (
                      <button 
                        className="media_item_box_action_button" 
                        onClick={(e)=>{
                          e.preventDefault();
                          onClick();
                        }}>
                        <i className="far fa-trash-alt media_item_box_action_icon" />
                      </button>
                  ) : null}
                  {editOption === true ? (
                      <button 
                        className="media_item_box_action_button font_purple" 
                        onClick={(e)=>{
                          e.preventDefault();
                          onEdit();
                        }} 
                        {...editProps}
                      >
                        <i className="far fa-edit" />
                      </button>
                  ) : null}
                  {onConntectionSuggestion === true ? (
                      <button 
                        className="media_item_box_action_button font_purple" 
                        onClick={(e)=>{
                          e.preventDefault();
                          onAdd();
                        }} 
                      >
                        <i className="fas fa-plus" />
                      </button>
                  ) : null}
              </div>
            </div>
          </Link>
      );
    } else {
      display = <div className="media_item_box" />;
    }

    if (resendOption === true) {
      label = (
        <button className="button_small base_button_transperant_purple btn m-t10 font_medium" onClick={onResend}>
          RESEND REQUEST
            </button>
      );
    } else if (rejected === true) {
      label = (
        <label className="rejectedLabel text-center font_gray m-t10 mb-0">
          REJECTED
            </label>
      )
    }

    if (title) {
      name = (
        redirectLink && status === STATUS.NOT_DELETED ? 
        (<Link style={{ textDecoration: 'none' }} to={redirectLink}>
          <h3 className="text-center font_16 font_d_blue font_regular m-t15 ">
            {getPreview(title, 40)}
          </h3>
        </Link>) : (<h3 className="text-center font_16 font_d_blue font_regular m-t15 word-wrap">{getPreview(title, 40)}</h3>)
      );
    } else {
      name = null;
    }
    if (subText) {
      subTitle = (
        <p className="text-center font_14 font_dark_gray font_light m-t5 mb-0">
          {getPreview(subText, 40)}
        </p>
      );
    } else {
      subTitle = null;
    }
    if (years) {
      duration = (
        <p className="text-center font_14 font_dark_gray font_light m-t3">
          {years}
        </p>
      );
    } else {
      duration = null;
    }
    return (
      <div className="m-t10 m-b10 media_item_box_conten">
        {display}
        {name}
        {subTitle}
        {duration}
        {label}
      </div>
    )
  }

}

ItemBox.defaultProps = {
  backgroundImage: null,
  height: "auto",
  width: "100%",
  title: null,
  profile: true,
  imgSrc: sample,
  boxShadow: false,
  deleteOption: true,
  subText: null,
  years: null,
  editProps: {}
};
export { ItemBox };
