import React, { Component } from "react";
import "./index.css";
import classname from 'classnames'
import source from './source.js'

class CountrySelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      popupVisible: false,
      countries: source,
      selectedCountry: {
        name: 'United States',
        code: this.props.defaultValue || 'US'

      }
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  handleClick() {
    if (!this.state.popupVisible) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      popupVisible: !prevState.popupVisible
    }));
  }

  handleOutsideClick(e) {
    if (this.node && this.node.contains && this.node.contains(e.target)) {
      return;
    }
    this.handleClick();
  }

  setSelectedImage = (item) => {
    let selectedCountry = {
      name: item[0],
      code: item[1]
    };
    this.setState({ selectedCountry: selectedCountry });

    if (this.props.setCountry) { this.props.setCountry(item[1]) }
    this.handleClick();

  };

  search = (e) => {
    let countryList = [];
    let selectedCountry = {
      name: e.target.value,
      code: '',
      popupVisible: true
    };
    this.setState({ selectedCountry: selectedCountry });
    if (e.target.value) {
      source.forEach((item, i) => {
        if (item[0].toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
          countryList.push(item);
        }
      });
      this.setState({ countries: countryList });
    } else {
      this.setState({ countries: source });
    }

  };

  componentWillMount() {
    if (this.props.defaultValue && this.props.defaultValue !== 'US') {
      let countryName = 'United States';
      this.state.countries.forEach((item, i) => {
        if (item[1] === this.props.defaultValue) {
          countryName = item[0];
        }
      });
      this.setState({
        selectedCountry: {
          code: this.state.selectedCountry.code,
          name: countryName,
        }
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultValue) {
      if (nextProps.defaultValue !== this.state.selectedCountry.code) {
        let countryName = null;
        this.state.countries.forEach((item, i) => {
          if (item[1] === nextProps.defaultValue) {
            countryName = item[0];
          }
        });
        this.setState({
          selectedCountry: {
            name: countryName,
            code: nextProps.defaultValue
          }
        })
      }
    }
  }

  render() {
    let dropdown = classname(
      "dropdown_body_country_select ",
      this.state.popupVisible === true ? "media_active" : "media_hide"
    );

    let itemsList = this.state.countries.map((item, index) => {
      return <div onClick={this.setSelectedImage.bind(this, item)} className="dropdown_item_country_select d-flex" key={index}>
        <div>
          <img className="dropdown_item_country_select_img" src={"/flags/" + item[1] + ".png"} alt="" />
        </div>
        <div className="m-l15">{item[0]}</div>
      </div>;
    });
    

    let flagPath = '/flags/' + this.state.selectedCountry.code + '.png';

    let countryImage = {
      backgroundImage: "url(" + flagPath + ")",
      width: '40px',
      height: '20px',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      bottom: '12px',
      left: '15px'
    };

    return (
      <React.Fragment>
        <div style={countryImage} />
        <input
          type={this.props.type}
          className="matirial_custom_input"
          onClick={this.handleClick}
          onChange={this.search}
          required
          style={this.state.selectedCountry.code ? { paddingLeft: '60px' } : null}
          value={this.state.selectedCountry.name}
        />
        <div
          className={dropdown}
          ref={node => {
            this.node = node;
          }}
          style={{ zIndex: 9999 }}
        >
          {itemsList}
        </div>
      </React.Fragment>
    );
  }
}

CountrySelect.defaultProps = {
  type: "text"
};

export { CountrySelect };
