import React, { Component } from "react"
import "./styles.css";


class Button extends Component {

    render() {
        let customClass = this.props.customClass ? this.props.customClass : "button";
        return (
            // <button className={customClass} type="button" onClick={this.props.onClick}>{this.props.text}</button>
            <button
                id={this.props.itemId}
                className={customClass} 
                type="button" 
                onClick={this.props.onClick} 
                style={{cursor: this.props.disabledButton ? "not-allowed": "pointer"}}
                disabled={this.props.disabledButton ? true : (this.props.itemId === this.props.clickedItem ? true : false )}>
                <i className={(this.props.itemId === this.props.clickedItem) && (this.props.btnType === this.props.actionType) ? "fa fa-spinner fa-spin" : "scc"}></i> 
                {(this.props.itemId === this.props.clickedItem) && (this.props.btnType === this.props.actionType) ? '' : this.props.text}
            </button>
        )
    }

}
export { Button };