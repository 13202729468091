import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

import { initlogin } from '../../actions/auth';
import { SIGNIN_SUCCESS, SIGNUP_SUCCESS } from '../../types/authentication';

const GreenPageRoute = ({
  component: C,
  props: cProps,
  dispatch,
  action,
  user,
  ...rest
}) => {
  useEffect(() => {
    const requestCancelToken = axios.CancelToken.source();

    if (!user._id && localStorage.getItem('token')) {
      dispatch(
        initlogin(localStorage.getItem('token')),
        false,
        null,
        requestCancelToken.token
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (action === SIGNIN_SUCCESS || action === SIGNUP_SUCCESS) {
      cProps.userHasAuthenticated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  if (rest.redirect) {
    return <Redirect to={rest.url} />;
  } else {
    return <Route {...rest} render={(props) => <C {...props} {...cProps} />} />;
  }
};

export default connect((state) => {
  return {
    user: state.auth.user,
    action: state.auth.action,
  };
})(GreenPageRoute);
