import React, { Component } from "react";

/*const AnimatedList = () => {
  return (
    <select className="matirial_custom_list matirial_custom_select" required>
      <option selected="Development" />
      <option value="saab">Saab</option>
      <option value="mercedes">Mercedes</option>
      <option value="audi">Audi</option>
    </select>
  );
};*/

class AnimatedList extends Component {

    _onSelect = (e) => {
        this.props.submit(e.nativeEvent.target[e.nativeEvent.target.selectedIndex].value, this.props.inputname);
    };

    render() {

        let items = this.props.items.map((item, i) => {
            return <DropdownItem key={i} item={item} selected={item.value === this.props.defaultValue ? true : false} />
        });

        return (
            <select className="matirial_custom_input matirial_custom_select" value={this.props.defaultValue} onChange={this._onSelect} required>
                <option selected></option>
                {items}
            </select>
        )
    }

}

class DropdownItem extends Component {

    render() {
        return (
            <option value={this.props.item.value} >{this.props.item.label}</option>
        );
    }
}

export { AnimatedList };
