import React from "react";
import "./index.css";

const Tabs = ({ className, children }) => {
        return (
            <div className={`tabs-wrapper ${className}`}>
                {children}
            </div>
        );
    }

export { Tabs };
