import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import asyncComponent from './containers/AsyncComponent';

import AuthenticatedRoute from './containers/routes/AuthenticatedRoute';
import UnauthenticatedRoute from './containers/routes/UnauthenticatedRoute';
import AdminRoute from './containers/routes/AuthenticatedAdminRoute';
import PrivateRoute from './containers/routes/PrivateRoute';
import GroupRoute from './containers/routes/GroupRoute';
import CompanyPrivateRoute from './containers/routes/CompanyPrivateRoute';
import PaidRoute from './containers/routes/PaidRoute';
import PaymentRoute from './containers/routes/PaymentRoute';
import GreenPagesRoute from './containers/routes/GreenPagesRoute';
import LandingPageRoute from './containers/routes/LandingPageRoute';
import { SUBSCRIPTION_MODULES } from './types/common';
import ChangeEmailPassword from './containers/settings/ChangeEmailPassword';
import { Loading } from './components/common';
import ExternalRoute from './containers/routes/ExternalRoute';

const PageNotFound = lazy(() => import('./containers/permission/'));
const Authentication = asyncComponent(() => import('./containers/authentication'));
const Login = lazy(() => import('./containers/login'));
const CustomLanding = lazy(() => import('./containers/authentication/customLanding'));
const MjbizLanding = lazy(() => import('./containers/authentication/mjbizLanding'));
const Registration = lazy(() => import('./containers/registrationStep'));
const AdminReportedContentReview = lazy(() => import('./containers/admin/reportedContent/conversations'));
const Admin = lazy(() => import('./containers/admin/index'));
const Activate = lazy(() => import('./containers/activate/index'));
const Invite = lazy(() => import('./containers/activate/invite'));
// const LandinPage = asyncComponent(() => import("./containers/landingPage"));
const Profile = lazy(() => import('./containers/profile/index'));
const ProfileView = lazy(() => import('./containers/profileView'));
const CreateCompany = lazy(() => import('./containers/profile/company/createCompany'));
const AdvancedSearch = lazy(() => import('./containers/advancedSearch'));
const FilterConnections = lazy(() => import('./containers/filterConnections'));
const FilterConnectionsOld = lazy(() => import('./containers/filterConnections/redirectOldRequests.js'));
const Message = lazy(() => import('./containers/messages-v2'));
// const CompanyInbox = lazy(() => import("./containers/messages/companyInbox"));
const SinglePost = lazy(() => import('./containers/singlePost'));
const ForgotPassword = lazy(() => import('./containers/resetPassword/forgot'));
const ResetPassword = lazy(() => import('./containers/resetPassword/reset'));
const SetEmpPassword = lazy(() => import('./containers/setEmpPassword/reset'));
const Advisor = lazy(() => import('./containers/advisor'));
const Faq = lazy(() => import('./containers/staticContents/about'));
const Contact = lazy(() => import('./containers/staticContents/contact'));
const AuthYahoo = lazy(() => import('./containers/auth/yahoo'));
const AuthWindowsLive = lazy(() => import('./containers/auth/windowsLive'));
const Settings = lazy(() => import('./containers/settings'));
const Video = lazy(() => import('./containers/staticContents/video'));
const DataRoom = lazy(() => import('./containers/dataRoom'));
const DataRoomCheck = lazy(() => import('./containers/dataRoom/checkAgreement'));

// const AddProduct = lazy(() => import('./containers/marketplace/index'));
// const CreateProductView = lazy(() =>
//   import('./containers/marketplace/adminView/createProduct')
// );
// const EditProductView = lazy(() =>
//   import('./containers/marketplace/adminView/editProduct')
// );
// const ViewProduct = lazy(() =>
//   import('./containers/marketplace/publicSingleView')
// );
// const MarketplacePublic = lazy(() =>
//   import('./containers/marketplace/publicView/index')
// );
// const MarketplaceReportingDashboard = lazy(() =>
//   import('./containers/marketplace/reportingDashboard/index')
// );

const payments = lazy(() => import('./containers/payment/index'));
const ManageSubscriptions = lazy(() => import('./containers/manageSubscriptions'));
const PreferredPartners = lazy(() => import('./containers/preferredPartners'));
const Redirect = lazy(() => import('./containers/redirectLink'));
const Press = lazy(() => import('./containers/press'));
const Groups = lazy(() => import('./containers/feed/groups'));
const AllEvents = lazy(() => import('./containers/events'));
const EventDiscounts = lazy(() => import('./containers/events/eventDiscounts'));
const ManageEvents = lazy(() => import('./containers/events/adminView/manageEvents'));
const CreateEvent = lazy(() => import('./containers/events/adminView/createEvent'));
const EditEvent = lazy(() => import('./containers/events/adminView/editEvent'));
const Training = lazy(() => import('./containers/training'));
const ConnectFromQR = lazy(() => import('./containers/connectFromQR'));
const AcceptConnectionFromEmail = lazy(() => import('./containers/acceptConnectionFromEmail'));
const SendConnectionRequestFromEmail = lazy(() => import('./containers/sendConnectionReqFromEmail'));
const ProInvitation = lazy(() => import('./containers/proInvitation'));
const UserProfile = lazy(() => import('./containers/userProfile'));
const UserProfileView = lazy(() => import('./containers/userProfileView'));
const customUrl = lazy(() => import('./containers/customUrl'));

// Green Pages - Sprint 53
const GreenPage = lazy(() => import('./containers/greenPages/index'));
// Green Pages List - Sprint 53
const GreenPageDetails = lazy(() => import('./containers/greenPages/details'));
const RequestPayments = lazy(() => import('./containers/paymentRequests'));

export default ({ childProps }) => (
  <Suspense fallback={<Loading />}>
    <Switch>
      {/* un authorized routes */}
      {/* <LandingRoute exact path="/landing-page" component={LandinPage} props={childProps} /> */}
      {['/', '/welcome', '/trn/:pid', '/myfeed', '/feed/:uid', '/core/:id'].map((path) => (
        <UnauthenticatedRoute
          key={path}
          path={path}
          exact
          component={Authentication}
          props={childProps}
        />
      ))}

      <UnauthenticatedRoute
        path='/login'
        exact
        component={Login}
        props={childProps}
      />

      <LandingPageRoute
        path='/landing/:landingPageUrl'
        exact
        component={CustomLanding}
        props={childProps}
      />

      <UnauthenticatedRoute
        path='/mjbiz'
        exact
        component={MjbizLanding}
        props={childProps}
      />
      {/* <UnauthenticatedRoute path="/" exact component={Authentication} props={childProps} /> */}
      <UnauthenticatedRoute
        path='/forgot-password'
        exact
        component={ForgotPassword}
        props={childProps}
      />
      <UnauthenticatedRoute
        path='/reset-password/:token'
        exact
        component={ResetPassword}
        props={childProps}
      />
      {/* <UnauthenticatedRoute path="/activate/:token" exact component={Activate} props={childProps} /> */}
      <UnauthenticatedRoute
        path='/set-password/:token'
        exact
        component={SetEmpPassword}
        props={childProps}
      />

      {/* administration routes */}
      <AdminRoute exact path='/admin' component={Admin} props={childProps} />
      <AdminRoute
        exact
        path='/admin/review/:type/:param'
        component={AdminReportedContentReview}
        props={childProps}
      />

      {/* payment routes */}
      <PaymentRoute
        exact
        path='/payments/:companyId/:sectionId'
        component={payments}
        props={childProps}
      />
      <PrivateRoute
        exact
        path='/proceed-to-checkout/:module/:token'
        component={RequestPayments}
        props={childProps}
      />

      {/* authorized routes */}
      <UnauthenticatedRoute
        exact
        path='/activate/:token'
        component={Activate}
        props={childProps}
      />
      <AuthenticatedRoute
        exact
        path='/registration'
        component={Registration}
        props={childProps}
      />

      <UnauthenticatedRoute
        exact
        path='/data-room/invitation/:companyId/:id'
        component={DataRoomCheck}
        props={childProps}
      />

      <UnauthenticatedRoute
        exact
        path='/invite-user/:token'
        component={Invite}
        props={childProps}
      />
      <PrivateRoute
        exact
        path='/proInvitation/:type/:via/:invitationId/:userId'
        component={ProInvitation}
        props={childProps}
      />
      <PrivateRoute
        exact
        path='/profile/:type'
        component={Profile}
        props={childProps}
      />
      <PrivateRoute
        exact
        path='/profile-edit/:type/:id'
        component={Profile}
        props={childProps}
      />
      <PrivateRoute
        exact
        path='/pro/:vanityName'
        component={customUrl}
        props={childProps}
      />
      <PrivateRoute
        exact
        path='/user-profile/:id/:gynViewId?'
        component={UserProfileView}
        props={childProps}
      />
      <PrivateRoute
        exact
        path='/user-profile-edit/:id'
        component={UserProfile}
        props={childProps}
      />
      <PrivateRoute
        exact
        path='/create-company'
        component={CreateCompany}
        props={childProps}
      />
      <PrivateRoute
        exact
        path='/profile/:type/:id/:gynViewId?'
        component={ProfileView}
        props={childProps}
      />
      <PrivateRoute
        exact
        path='/advanced-search/:usertype/:text'
        component={AdvancedSearch}
        props={childProps}
      />
      <PrivateRoute
        exact
        path='/filter-connections/:text'
        component={FilterConnections}
        props={childProps}
      />
      <PrivateRoute
        exact
        path='/filter-connections'
        component={FilterConnectionsOld}
        props={childProps}
      />
      <PrivateRoute
        exact
        path='/verify-advisor-request/:type/:companyId/:token'
        component={Advisor}
        props={childProps}
      />
      <CompanyPrivateRoute
        exact
        path='/manage-admins/:companyId'
        component={Settings}
        props={childProps}
      />
      <PrivateRoute
        exact
        path='/settings/change-email-password'
        component={ChangeEmailPassword}
        props={childProps}
      />

      {['/groups/:groupId', '/groups/:groupId/:postId'].map((path) => (
        <GroupRoute
          exact
          key={path}
          path={path}
          component={Groups}
          props={childProps}
        />
      ))}

      <PrivateRoute
        exact
        path='/events/all-events'
        component={AllEvents}
        props={childProps}
      />
      <PrivateRoute
        exact
        path='/events'
        url='/events/all-events'
        redirect={true}
      />
      <PrivateRoute
        exact
        path='/events/event-discounts'
        component={EventDiscounts}
        props={childProps}
      />
      <PrivateRoute
        exact
        path='/events/manage-events'
        component={ManageEvents}
        props={childProps}
      />
      <PrivateRoute
        exact
        path='/events/create'
        component={CreateEvent}
        props={childProps}
      />
      <PrivateRoute
        exact
        path='/events/edit/:eventId'
        component={EditEvent}
        props={childProps}
      />

      <GroupRoute
        exact
        path='/groups/post/:groupId/:postId/:commentId?'
        component={SinglePost}
        props={childProps}
      />

      {/* payment required routes -  data-room */}
      <PaidRoute
        module={SUBSCRIPTION_MODULES.FUNDRAISING}
        exact
        path='/data-room/:companyId'
        component={DataRoom}
        props={childProps}
      />

      <UnauthenticatedRoute
        exact
        path='/auth/yahoo'
        component={AuthYahoo}
        props={childProps}
      />

      {/* Individual Messaging  */}
      <PrivateRoute
        exact
        path='/message/:type/:id'
        component={Message}
        props={childProps}
      />
      {/* Company Messaging  */}
      {/* <CompanyPrivateRoute
      exact
      path="/company-inbox/:companyId/:recipientId/:conversationId"
      component={CompanyInbox}
      props={childProps}
    /> */}

      <PrivateRoute
        exact
        path='/post/:postId/:commentId?'
        component={SinglePost}
        props={childProps}
      />

      <ExternalRoute
        exact
        path='/page/:type'
        component={Faq}
        props={childProps}
      />
      <ExternalRoute
        exact
        path='/contact-us'
        component={Contact}
        props={childProps}
      />

      {/* <PrivateRoute
        path='/marketplace/view-product/:productId'
        component={ViewProduct}
        props={childProps}
      />
      <PrivateRoute
        exact
        path='/marketplace/product-create/:companyId'
        component={CreateProductView}
        props={childProps}
      />
      <PrivateRoute
        path='/marketplace/product-edit/:companyId'
        component={EditProductView}
        props={childProps}
      />
      <PrivateRoute
        path='/marketplace/company/:companyId'
        component={AddProduct}
        props={childProps}
      />
      <PrivateRoute
        path='/marketplace/public'
        component={MarketplacePublic}
        props={childProps}
      />
      <PrivateRoute
        path='/marketplace/reportingDashboard'
        component={MarketplaceReportingDashboard}
        props={childProps}
      /> */}

      <PrivateRoute
        exact
        path='/manage-subscriptions'
        component={ManageSubscriptions}
        props={childProps}
      />
      <PrivateRoute
        exact
        path='/training'
        component={Training}
        props={childProps}
      />

      <PrivateRoute
        exact
        path='/accepting/:requesterId'
        component={AcceptConnectionFromEmail}
        props={childProps}
      />

      <PrivateRoute
        exact
        path='/connecting/:requesterId/:recipientId/:viewId'
        component={SendConnectionRequestFromEmail}
        props={childProps}
      />

      <PrivateRoute
        exact
        path='/connect/:deviceToken'
        component={ConnectFromQR}
        props={childProps}
      />

      {/* Green Pages Home - Sprint 53 */}
      <GreenPagesRoute
        exact
        path='/greenpages/home'
        component={GreenPage}
        props={childProps}
      />
      <GreenPagesRoute
        exact
        path='/greenpages'
        url="/greenpages/home"
        redirect={true}
      />
      <GreenPagesRoute
        exact
        path='/marketplace/public'
        url="/greenpages/home"
        redirect={true}
      />
      {/* Green Pages Details - Sprint 53 */}
      <GreenPagesRoute
        exact
        strict
        path='/greenpages/listings/:uniqueListingName'
        component={GreenPageDetails}
        props={childProps}
      />
      <GreenPagesRoute
        exact
        strict
        path='/greenpages/adminPreview/:uniqueListingName'
        component={GreenPageDetails}
        props={childProps}
      />
      {/* Green Pages List - Sprint 53 */}
      <GreenPagesRoute
        exact
        path='/greenpages/:mainCategory/:subCategory?'
        component={GreenPage}
        props={childProps}
      />

      <UnauthenticatedRoute
        path='/go/:linkId'
        exact
        component={Redirect}
        props={childProps}
      />
      <UnauthenticatedRoute
        exact
        path='/leafwire-platinum-partners'
        component={PreferredPartners}
        props={childProps}
      />
      <UnauthenticatedRoute
        path='/press'
        exact
        component={Press}
        props={childProps}
      />
      <UnauthenticatedRoute
        path='/press/:section'
        component={Press}
        props={childProps}
      />
      <ExternalRoute
        exact
        path='/video-reference'
        component={Video}
        props={childProps}
      />
      {window.location.pathname.includes('/authorize/?code=') && (
        <UnauthenticatedRoute
          exact
          path={window.location.pathname.includes('/authorize/?code=')}
          component={AuthWindowsLive}
          props={childProps}
        />
      )}

      <Route render={(props) => <PageNotFound {...childProps} />} />
    </Switch>
  </Suspense>
);
