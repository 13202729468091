import React, { useEffect, useState, useMemo } from "react";
import likeReaction from "./../../../assets/img/reactions/like.png";
import loveReaction from "./../../../assets/img/reactions/love.png";
import hahaReaction from "./../../../assets/img/reactions/haha.png";
import sadReaction from "./../../../assets/img/reactions/sad.png";
import angryReaction from "./../../../assets/img/reactions/angry.png";
import insightfulReaction from "./../../../assets/img/reactions/insightful.png";
import wowReaction from "./../../../assets/img/reactions/wow.png";
import { USER_EMOTION_TYPES } from './../../../types/common'

const PostReactionCounts = ({ likedUsers, openLikedUsers, postId, ID, recentReaction, recentlyUnliked }) => {

    const getReactionCounts = () => {

        let reactionArr = [];
        reactions.map(r => {

            if (r.TYPE === USER_EMOTION_TYPES.LIKE) {
                reactionArr[USER_EMOTION_TYPES.LIKE] = reactionArr[USER_EMOTION_TYPES.LIKE]
                    ? { ...reactionArr[USER_EMOTION_TYPES.LIKE], count: reactionArr[USER_EMOTION_TYPES.LIKE].count + 1 }
                    : { type: USER_EMOTION_TYPES.LIKE, count: 1, image: likeReaction, className: 'like' }
            } else if (r.TYPE === USER_EMOTION_TYPES.HEART) {
                reactionArr[USER_EMOTION_TYPES.HEART] = reactionArr[USER_EMOTION_TYPES.HEART]
                    ? { ...reactionArr[USER_EMOTION_TYPES.HEART], count: reactionArr[USER_EMOTION_TYPES.HEART].count + 1 }
                    : { type: USER_EMOTION_TYPES.HEART, count: 1, image: loveReaction, className: 'love' }
            } else if (r.TYPE === USER_EMOTION_TYPES.INSIGHTFUL) {
                reactionArr[USER_EMOTION_TYPES.INSIGHTFUL] = reactionArr[USER_EMOTION_TYPES.INSIGHTFUL]
                    ? { ...reactionArr[USER_EMOTION_TYPES.INSIGHTFUL], count: reactionArr[USER_EMOTION_TYPES.INSIGHTFUL].count + 1 }
                    : { type: USER_EMOTION_TYPES.INSIGHTFUL, count: 1, image: insightfulReaction, className: 'insightful' }
            } else if (r.TYPE === USER_EMOTION_TYPES.HAHA) {
                reactionArr[USER_EMOTION_TYPES.HAHA] = reactionArr[USER_EMOTION_TYPES.HAHA]
                    ? { ...reactionArr[USER_EMOTION_TYPES.HAHA], count: reactionArr[USER_EMOTION_TYPES.HAHA].count + 1 }
                    : { type: USER_EMOTION_TYPES.HAHA, count: 1, image: hahaReaction, className: 'haha' }
            } else if (r.TYPE === USER_EMOTION_TYPES.WOW) {
                reactionArr[USER_EMOTION_TYPES.WOW] = reactionArr[USER_EMOTION_TYPES.WOW]
                    ? { ...reactionArr[USER_EMOTION_TYPES.WOW], count: reactionArr[USER_EMOTION_TYPES.WOW].count + 1 }
                    : { type: USER_EMOTION_TYPES.WOW, count: 1, image: wowReaction, className: 'wow' }
            } else if (r.TYPE === USER_EMOTION_TYPES.SAD) {
                reactionArr[USER_EMOTION_TYPES.SAD] = reactionArr[USER_EMOTION_TYPES.SAD]
                    ? { ...reactionArr[USER_EMOTION_TYPES.SAD], count: reactionArr[USER_EMOTION_TYPES.SAD].count + 1 }
                    : { type: USER_EMOTION_TYPES.SAD, count: 1, image: sadReaction, className: 'sad' }
            } else if (r.TYPE === USER_EMOTION_TYPES.ANGRY) {
                reactionArr[USER_EMOTION_TYPES.ANGRY] = reactionArr[USER_EMOTION_TYPES.ANGRY]
                    ? { ...reactionArr[USER_EMOTION_TYPES.ANGRY], count: reactionArr[USER_EMOTION_TYPES.ANGRY].count + 1 }
                    : { type: USER_EMOTION_TYPES.ANGRY, count: 1, image: angryReaction, className: 'angry' }
            }
            return true
        });

        reactionArr.sort((a, b) => parseInt(b.count) - parseInt(a.count));

        return (
            <div className="current-reactions">
                {reactionArr.map((el, i) => {
                    if (i < 3) {
                        return <img key={el.type} src={el.image} className={el.className} alt="user-reaction" />
                    }
                    return null
                })}
            </div>
        );
    };
    const [reactions, setReactions] = useState(likedUsers);
    const [toggleReaction, setToggle] = useState(true);
    const reactionCount = reactions.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const reactionsCounts = useMemo(() => getReactionCounts(), [reactionCount, toggleReaction]);
    useEffect(() => {
        if (recentReaction) {
            let newReactions = reactions.filter(nr => { return nr.USER_ID !== localStorage.getItem('userId') });
            newReactions = [...newReactions, { _id: '0', ELEMENT_ID: postId, MODULE: 1, USER_ID: localStorage.getItem('userId'), TYPE: recentReaction }];
            setReactions(newReactions);
            setToggle(!toggleReaction);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recentReaction]);

    useEffect(() => {
        if (recentlyUnliked) {
            let newReactions = reactions.filter(nr => { return nr.USER_ID !== localStorage.getItem('userId') });
            setReactions(newReactions);
            setToggle(!toggleReaction);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recentlyUnliked]);

    if ((reactions && reactions.length > 0) || recentReaction) {
        return (
            <div
                className="post-reactions cursorPointer m-r13"
                onClick={() => openLikedUsers(postId)}
                id={'post-reactions-' + ID}
            >
                {reactionsCounts}
                {reactions.length}
            </div>
        )
    }
    return (
        <div />
    );
};

const PostCommentReactionCounts = ({ likedUsers, openLikedUsers, postId, ID, recentReaction, recentlyUnliked }) => {

    const getReactionCounts = () => {

        let reactionArr = [];
        reactions.map(r => {
            if (r.EMOTION_TYPE === USER_EMOTION_TYPES.LIKE) {
                reactionArr[USER_EMOTION_TYPES.LIKE] = reactionArr[USER_EMOTION_TYPES.LIKE]
                    ? { ...reactionArr[USER_EMOTION_TYPES.LIKE], count: reactionArr[USER_EMOTION_TYPES.LIKE].count + 1 }
                    : { type: USER_EMOTION_TYPES.LIKE, count: 1, image: likeReaction, className: 'like' }
            } else if (r.EMOTION_TYPE === USER_EMOTION_TYPES.HEART) {
                reactionArr[USER_EMOTION_TYPES.HEART] = reactionArr[USER_EMOTION_TYPES.HEART]
                    ? { ...reactionArr[USER_EMOTION_TYPES.HEART], count: reactionArr[USER_EMOTION_TYPES.HEART].count + 1 }
                    : { type: USER_EMOTION_TYPES.HEART, count: 1, image: loveReaction, className: 'love' }
            } else if (r.EMOTION_TYPE === USER_EMOTION_TYPES.INSIGHTFUL) {
                reactionArr[USER_EMOTION_TYPES.INSIGHTFUL] = reactionArr[USER_EMOTION_TYPES.INSIGHTFUL]
                    ? { ...reactionArr[USER_EMOTION_TYPES.INSIGHTFUL], count: reactionArr[USER_EMOTION_TYPES.INSIGHTFUL].count + 1 }
                    : { type: USER_EMOTION_TYPES.INSIGHTFUL, count: 1, image: insightfulReaction, className: 'insightful' }
            } else if (r.EMOTION_TYPE === USER_EMOTION_TYPES.HAHA) {
                reactionArr[USER_EMOTION_TYPES.HAHA] = reactionArr[USER_EMOTION_TYPES.HAHA]
                    ? { ...reactionArr[USER_EMOTION_TYPES.HAHA], count: reactionArr[USER_EMOTION_TYPES.HAHA].count + 1 }
                    : { type: USER_EMOTION_TYPES.HAHA, count: 1, image: hahaReaction, className: 'haha' }
            } else if (r.EMOTION_TYPE === USER_EMOTION_TYPES.WOW) {
                reactionArr[USER_EMOTION_TYPES.WOW] = reactionArr[USER_EMOTION_TYPES.WOW]
                    ? { ...reactionArr[USER_EMOTION_TYPES.WOW], count: reactionArr[USER_EMOTION_TYPES.WOW].count + 1 }
                    : { type: USER_EMOTION_TYPES.WOW, count: 1, image: wowReaction, className: 'wow' }
            } else if (r.EMOTION_TYPE === USER_EMOTION_TYPES.SAD) {
                reactionArr[USER_EMOTION_TYPES.SAD] = reactionArr[USER_EMOTION_TYPES.SAD]
                    ? { ...reactionArr[USER_EMOTION_TYPES.SAD], count: reactionArr[USER_EMOTION_TYPES.SAD].count + 1 }
                    : { type: USER_EMOTION_TYPES.SAD, count: 1, image: sadReaction, className: 'sad' }
            } else if (r.EMOTION_TYPE === USER_EMOTION_TYPES.ANGRY) {
                reactionArr[USER_EMOTION_TYPES.ANGRY] = reactionArr[USER_EMOTION_TYPES.ANGRY]
                    ? { ...reactionArr[USER_EMOTION_TYPES.ANGRY], count: reactionArr[USER_EMOTION_TYPES.ANGRY].count + 1 }
                    : { type: USER_EMOTION_TYPES.ANGRY, count: 1, image: angryReaction, className: 'angry' }
            }
            return true
        });

        reactionArr.sort((a, b) => parseInt(b.count) - parseInt(a.count));

        return (
            <div className="current-reactions">
                {reactionArr.map((el, i) => {
                    if (i < 3) {
                        return <img key={el.type} src={el.image} className={el.className} alt="user-reaction" />
                    }
                    return null
                })}
            </div>
        );
    };
    const [reactions, setReactions] = useState(likedUsers);
    const [toggleReaction, setToggle] = useState(true);
    const reactionCount = reactions.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const reactionsCounts = useMemo(() => getReactionCounts(), [reactionCount, toggleReaction]);
    useEffect(() => {
        if (recentReaction) {
            let newReactions = reactions.filter(nr => { return nr.USER_ID !== localStorage.getItem('userId') });
            newReactions = [...newReactions, { _id: '0', ELEMENT_ID: postId, MODULE: 1, USER_ID: localStorage.getItem('userId'), EMOTION_TYPE: recentReaction }];
            setReactions(newReactions);
            setToggle(!toggleReaction);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recentReaction]);

    useEffect(() => {
        if (recentlyUnliked) {
            let newReactions = reactions.filter(nr => { return nr.USER_ID !== localStorage.getItem('userId') });
            setReactions(newReactions);
            setToggle(!toggleReaction);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recentlyUnliked]);

    if ((reactions && reactions.length > 0) || recentReaction) {
        return (
            <div
                className="comment-reactions-wrapper"
                onClick={() => openLikedUsers(postId)}
            >
                {reactionsCounts}
                <div className="number">{reactions.length}</div>
            </div>
        )
    }
    return (
        <div />
    );
};

const PublicPostReactionsCounts = ({ reactionCounts, commentCount = 0 }) => {

    const show = ((commentCount && commentCount > 0) || (reactionCounts && (reactionCounts.LIKES > 0 || reactionCounts.LOVE > 0 || reactionCounts.HAHA > 0 || reactionCounts.SAD > 0 || reactionCounts.ANGRY > 0)));
    const [totalReactions, setTotalReactions] = useState(0);
    const [reactions, setReactions] = useState([]);

    useEffect(() => {
        if (reactionCounts) {
            let all = 0;
            let reactionArr = Object.keys(reactionCounts).map(i => {
                all = all + reactionCounts[i];
                if (i === 'LIKES' && reactionCounts[i] > 0) {
                    return { type: i, count: reactionCounts[i], image: likeReaction, className: 'like' };
                } else if (i === 'LOVE' && reactionCounts[i] > 0) {
                    return { type: i, count: reactionCounts[i], image: loveReaction, className: 'love' };
                } else if (i === 'INSIGHTFUL' && reactionCounts[i] > 0) {
                    return { type: i, count: reactionCounts[i], image: insightfulReaction, className: 'insightful' };
                } else if (i === 'HAHA' && reactionCounts[i] > 0) {
                    return { type: i, count: reactionCounts[i], image: hahaReaction, className: 'haha' };
                } else if (i === 'WOW' && reactionCounts[i] > 0) {
                    return { type: i, count: reactionCounts[i], image: wowReaction, className: 'wow' };
                } else if (i === 'SAD' && reactionCounts[i] > 0) {
                    return { type: i, count: reactionCounts[i], image: sadReaction, className: 'sad' };
                } else if (i === 'ANGRY' && reactionCounts[i] > 0) {
                    return { type: i, count: reactionCounts[i], image: angryReaction, className: 'angry' };
                } else {
                    return {}
                }
            });
            setTotalReactions(all);
            reactionArr = reactionArr.filter(a => { return (a && a.count && a.count > 0) });
            reactionArr.sort((a, b) => parseInt(b.count) - parseInt(a.count));
            setReactions(reactionArr);
        }

    }, [reactionCounts]);

    if (show) {
        return (
            <div className="col-md-12 action__row recent-posts">
                <div className="current-reactions m-r8" hidden={totalReactions === 0}>
                    {(reactions && reactions.length > 0) && reactions.map((el, i) => {
                        if (i < 3 && el && el.count !== null && el.count > 0) {
                            return <img key={i} src={el.image} className={el.className} alt="user-reaction" />
                        }
                        return null
                    })}
                </div>
                {totalReactions === 0 ? '' : totalReactions}
                <i className="far fa-comment-alt-lines comment-icon font_22 m-l20 m-r8" hidden={commentCount === 0} /> {commentCount === 0 ? '' : commentCount}
            </div>
        )
    } else {
        return (<div />)
    }
};

export { PostReactionCounts, PostCommentReactionCounts, PublicPostReactionsCounts }