import ProductImg from "../assets/img/fundraisers-default.jpg";
import { getCFObjURL, BUCKET } from './cfHelper';
import { IMAGE_TYPE } from '../types/common';

export function getFileType(fileName) {
    return fileName.split('.').pop();
}

export function imageExists(url, callback, isProfilePic = false) {
    let img = new Image();
    img.onload = function () { callback(true); };
    img.onerror = function () {
        if (!isProfilePic) callback(false);
        else if (localStorage.getItem('unsigned_profile_image')) {
            const imageName = localStorage.getItem('unsigned_profile_image');
            const getImage = async (imageName) => {
                try {
                    const image = getCFObjURL(BUCKET.COMMON)(imageName, true);
                    if (image) {
                        return image;
                    } else {
                        callback(false);
                    }
                } catch (ex) {
                    callback(false);
                }
            }

            getImage('mediam-' + imageName)
                .then(profilePicture => {
                    localStorage.removeItem('profilePicture');
                    localStorage.setItem('profilePicture', profilePicture);

                    getImage('small-' + imageName)
                        .then(profileThumbnail => {
                            localStorage.removeItem('profileThumbnail');
                            localStorage.setItem('profileThumbnail', profileThumbnail);

                            getImage(imageName)
                                .then(publicProfilePicture => {
                                    localStorage.removeItem('publicProfilePicture');
                                    localStorage.setItem('publicProfilePicture', publicProfilePicture);
                                });
                        });
                    callback(true);
                })
            callback(false);
        } else callback(false);
    };
    img.referrerPolicy = 'no-referrer'
    img.src = url??'';
}

export const getImageUrl = async (imgName, size, path = BUCKET.COMMON) => {
    try {
        const url = getCFObjURL(path)((size + imgName), true);
        if (url) {
            return url
        } else {
            return null
        }
    } catch (ex) {
        return null
    }
};

export const getMarketplaceWidgetImage = async (alternative, companyLogo, companyId) => {
    try {
        let res = ProductImg;
        if(alternative){
            const url = await getCFObjURL(BUCKET.MARKET_PLACE)(alternative, companyId);
            if (url) {
                res = url;
            }
        } 
        if (companyLogo) {
            const isThumb = companyId === IMAGE_TYPE.THUMBNAIL;
            const clogo = await getCFObjURL(BUCKET.COMMON)(companyLogo, isThumb);
            if (clogo) {
                res = clogo;
            }
        }
        return res
    } catch (ex) {
        return ProductImg;
    }
};

export const getGroupBanner = async (imgName) => {
    try {
        const url = getCFObjURL(BUCKET.COMMON)(imgName, false);
        return url
    } catch (ex) {
        return null
    }
};

export const uploadProgress = ({ loaded, total }) => {
    try {
        const progress = (loaded / total);
        return Math.round(progress * 100);
    } catch (ex) {
        return null
    }
};

