// import React from "react";
// import { Route, Redirect } from "react-router-dom";


// export default ({ component: Component, props: cProps, ...rest }) => {
//     return (
//         <Route
//             {...rest}
//             render={props =>
//                 cProps.isAuthenticated && cProps.isAdmin
//                     ? <Component {...props} {...cProps} />
//                     : <Redirect to={`/`} />}
//         />
//     );
// };


import React from "react";
import { connect } from 'react-redux';
import { Route, Redirect } from "react-router-dom";
import { Loading } from '../../components/common';
import {routeLogin} from './../../actions/route';

/*const fakeAuth = {
    isAuthenticated: true,
    authenticate(cb) {
        this.isAuthenticated = true
        setTimeout(cb, 1000)
    }
}*/

export class AdminRoute extends React.Component {

    //export default ({ component: Component, props: cProps, ...rest }) => {

    // constructor(props) {
    //     super(props);

    //     this.state = {
    //         loading: true,
    //         isAuthenticated: false,
    //     }

    // }

    // componentWillMount(){
    //     if(localStorage.getItem('token')){
    //         this.props.dispatch(routeLogin(localStorage.getItem('token')));
    //     }
    // }

    // componentDidMount() {
    //     if (localStorage.getItem('token')) {
    //         this.props.dispatch(initlogin(localStorage.getItem('token')));
    //         axios.post(`${ROUTES.USERDETAILS}`, {}, { headers: { Authorization: localStorage.getItem('token') } }).then(response => {
    //             if (response.data.code === 200) {
    //                 let user = response.data.data;
    //                 if (!user) { // user does not exisit
    //                     this.setState({
    //                         loading: false,
    //                         isAuthenticated: false,
    //                     });
    //                 } else if (LEAFWIRE_ADMIN_EMAILS.indexOf(user.EMAIL) >= 0) {
    //                     this.setState({
    //                         loading: false,
    //                         isAuthenticated: true,
    //                     });
    //                 } else {
    //                     this.setState({
    //                         loading: false,
    //                         isAuthenticated: false,
    //                     });
    //                 }
    //                 /*else if (user.MEMBER_TYPE === 3) { // company or networker user is ready to navigate
    //                     this.setState({
    //                         loading: false,
    //                         isAuthenticated: true,
    //                     });
    //                 } else { // authentication fail, user does not have priviledges to navigate
    //                     this.setState({
    //                         loading: false,
    //                         isAuthenticated: false,
    //                     });
    //                 }*/
    //             } else { // invalid token or server authenication fail
    //                 this.setState({
    //                     loading: false,
    //                     isAuthenticated: false,
    //                 });
    //             }
    //         }).catch((exception) => { // server does not response
    //             this.setState({
    //                 loading: false,
    //                 isAuthenticated: false,
    //             });
    //         });
    //     }
    // }

    componentWillMount(){
        if(localStorage.getItem('token') && !this.props.route.isAdminAuthenticated){
            this.props.dispatch(routeLogin(localStorage.getItem('token')));
        }
    }
    
    render() {
        const { component: Component, ...rest } = this.props;
        if(this.props.route.loading){
            return <Loading/>
        } else { 
            return (
                <Route {...rest} render={props => (
                    <div>
                        {!this.props.route.isAdminAuthenticated && <Redirect to={`/`} />}
                        <Component {...this.props} />
                    </div>
                )}
                />
            )
        }
    }

};

export default connect(state => {
    return {
        state,
        route: state.route
    };

})(AdminRoute);