import React, { Component } from "react";
import { withRouter } from 'react-router-dom'
import "./index.css";
import image from './../../../assets/img/placeholder.png';
import { ROUTES } from './../../../config/server';
import { imageExists } from "../../../libs/image";
import { safeUrls } from "../../../libs/commonFunctions";
import { post } from "../../../libs/apiConnector";
import { PublicPostReactionsCounts } from '../../../components/post/postReactions';
import { PostVideo } from "./../../../components/post";
import { getCFObjURL, BUCKET } from "../../../libs/cfHelper";
class RecentPostRow extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            text: '',
            showImage: image,
            linkPreviewFetching: false,
            linkPreviewImage: null,
            linkPreviewDescription: null,
            linkPreviewTitle: null,
            linkPreviewUrl: null,
        })
    }

    _isMounted = false;

    componentWillMount() {
        if (this.props.image) {
            this._getImage(this.props.image);
        }
        this._captureTheURL(this.props.text);
        this.setState({ text: this.props.text })
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.text !== nextProps.text) {
            this.setState({
                text: nextProps.text,
                showImage: image,
                linkPreviewFetching: false,
                linkPreviewImage: null,
                linkPreviewDescription: null,
                linkPreviewTitle: null,
                linkPreviewUrl: null,
            });
            if (nextProps.image) {
                this._getImage(nextProps.image);
            }
            this._captureTheURL(nextProps.text);
        }
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    _getImage = (image) => {
        const url = getCFObjURL(BUCKET.NEWSFEED)(('medium-' + image), true);
        if (url && this._isMounted) {
            this.setState({
                showImage: url
            })
        }
    };

    _captureTheURL = (text) => {

        let regex = /(&nbsp;|<([^>]+)>)/ig;
        text = text.replace(regex, "");

        let urlRegex = /(https?:\/\/[^\s]+)/g;
        let capturedUrl = urlRegex.exec(text);
        if (capturedUrl !== null) {
            this.setState({ linkPreviewFetching: true });
            let url = capturedUrl[0];
            post(`${ROUTES.LINK_PREVIEW}`, { url })
                .then(response => {
                    if (response.data.code === 200 && this._isMounted) {
                        this.setState({ linkPreviewFetching: false });
                        response.data.data.description !== null ? this.setState({ linkPreviewDescription: response.data.data.description }) : this.setState({ linkPreviewDescription: null });
                        response.data.data.title !== null ? this.setState({ linkPreviewTitle: response.data.data.title }) : this.setState({ linkPreviewTitle: null });
                        response.data.data.url !== null ? this.setState({ linkPreviewUrl: response.data.data.url }) : this.setState({ linkPreviewUrl: null });
                        // response.data.data.image !== null ? this.setState({ linkPreviewImage: response.data.data.image }) : response.data.data.logo !== null ? this.setState({ linkPreviewImage: response.data.data.logo }) : this.setState({ linkPreviewImage: null });
                        if (response.data.data.image) {
                            imageExists(response.data.data.image, (exists) => {
                                if (exists) {
                                    this.setState({ linkPreviewImage: response.data.data.image })
                                } else {
                                    this.setState({ linkPreviewImage: false });
                                }
                            });
                        } else if (response.data.data.logo) {
                            imageExists(response.data.data.logo, (exists) => {
                                if (exists) {
                                    this.setState({ linkPreviewImage: response.data.data.logo })
                                } else {
                                    this.setState({ linkPreviewImage: false });
                                }
                            });
                        } else {
                            this.setState({
                                linkPreviewFetching: false,
                                linkPreviewImage: null,
                                linkPreviewTitle: null,
                                linkPreviewDescription: null,
                                linkPreviewUrl: null
                            });
                        }
                    }
                })
                .catch((error) => {
                    if(this._isMounted){
                        this.setState({ 
                            linkPreviewFetching: false, 
                            linkPreviewImage: null, 
                            linkPreviewTitle: null, 
                            linkPreviewDescription: null, 
                            linkPreviewUrl: null 
                        });
                    }
                })
        }
    };

    urlify = (text) => {
        var Regex = /(https?:\/\/[^\s]+)/g;
        return text.replace(Regex, (url) => {
            // return '<a href="' + url + '">' + url + '</a>';
            return '';
        });
    }

    handleRedirect = () => {
        if (this.props.groupId && this.props.isActiveGroup) {
            this.props.history.push(`/groups/${this.props.groupId}/${this.props.id}`)
        } else {
            // this.props.history.push(`/post/${this.props.id}`)
            // use below to open singlepost in newsfeed
            this.props.history.push(`/?postId=${this.props.id}`)
        }
    }

    render() {
        
        let post = null;
        let actions = <PublicPostReactionsCounts
            reactionCounts={this.props.like}
            commentCount={this.props.comment}
        />;

        if (this.props.video) {
            post = (
                <div className="row  row___count">
                    <div className="col-md-5">
                        <PostVideo
                            customClass={"post_image "}
                            {...this.props.video}
                            isPublicFeed={false}
                            // openLogin={this.props.openLogin}
                        />
                    </div>
                    {this.state.linkPreviewImage && <div className="col-md-7">
                        <p>
                            <span style={{ fontSize: 1.2 + 'rem' }}>
                                <a href={safeUrls(this.state.linkPreviewUrl)} target="_blink" style={{ textDecoration: 'none' }}>
                                    {this.state.linkPreviewTitle}
                                </a>
                                <i className="fa fa-link font_purple m-l10 action__button" />
                            </span><br />
                            <div className="p-b20  profile-post-text has-link" dangerouslySetInnerHTML={{ __html: this.state.text.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} />
                            {/*removed '.substr(0, 400)' with tag user task*/}
                            <div className="font_purple font_bold show__more" onClick={this.handleRedirect}>
                                {" "}
                                Show More
                            </div>
                        </p>
                    </div>}
                    {!this.state.linkPreviewImage && <div className="col-md-7">
                        <p>
                            {/* {getPreview(this.state.text, textLength)} */}
                            <div className="p-b20 profile-post-text has-image" dangerouslySetInnerHTML={{ __html: this.state.text.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} />
                            <div className="font_purple font_bold show__more" onClick={this.handleRedirect} >
                                {" "}
                                Show More
                            </div>
                        </p>
                    </div>}
                    {actions}
                </div>
            );
        } else if (this.props.image) {
            post = (
                <div className="row  row___count">
                    <div className="col-md-3">
                        <img
                            alt="previewimage"
                            className="psot___row__image"
                            style={{ width: "100%", display: "flex", height: "auto" }}
                            src={safeUrls(this.state.showImage)}
                        />
                    </div>
                    <div className="col-md-9">
                        <p>
                            {/* {getPreview(this.state.text, textLength)} */}
                            <div className="p-b20 profile-post-text has-image" dangerouslySetInnerHTML={{ __html: this.state.text.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} />
                            <div className="font_purple font_bold show__more" onClick={this.handleRedirect} >
                                {" "}
                                Show More
                            </div>
                        </p>
                    </div>
                    {actions}
                </div>
            );
        } else if (this.state.linkPreviewImage) {
            post = (
                <div className="row  row___count">
                    <div className="col-md-3">
                        <img
                            alt="previewimage"
                            className="psot___row__image"
                            style={{ width: "100%", display: "flex", height: "auto" }}
                            src={safeUrls(this.state.linkPreviewImage)}
                        />
                    </div>
                    <div className="col-md-9">
                        <p>
                            <span style={{ fontSize: 1.2 + 'rem' }}>
                                <a href={safeUrls(this.state.linkPreviewUrl)} target="_blink" style={{ textDecoration: 'none' }}>
                                    {this.state.linkPreviewTitle}
                                </a>
                                <i className="fa fa-link font_purple m-l10 action__button" />
                            </span><br />
                            <div className="p-b20  profile-post-text has-link" dangerouslySetInnerHTML={{ __html: this.state.text.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} />
                            {/*removed '.substr(0, 400)' with tag user task*/}
                            <div className="font_purple font_bold show__more" onClick={this.handleRedirect}>
                                {" "}
                                Show More
                            </div>
                        </p>
                    </div>
                    {actions}
                </div>
            );
        } else {
            post = (
                <div className="row  row___count">
                    <div className="col-md-12">
                        <p>
                            <div className="p-b20  profile-post-text" dangerouslySetInnerHTML={{ __html: this.state.text.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} />
                            <div className="font_purple font_bold show__more" onClick={this.handleRedirect} >
                                Show More
              </div>
                        </p>
                    </div>
                    {actions}
                </div>
            );
        }
        return post;
    }
}

const RecentPostRowRoute = withRouter(RecentPostRow)

export { RecentPostRowRoute as RecentPostRow };
