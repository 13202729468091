import axios from 'axios';
import SearchTypes from '../types/search';
import ConnectionTypes from '../types/connections';
import {PROFILE_TYPES, MEMBER_TYPE, SEARCH_TYPE} from '../types/common';
//import { showAlert } from './alert';
import {ROUTES} from '../config';
import {SET_IMAGES} from './../types/images';
import {post} from "../libs/apiConnector";
import { getCFObjURL, BUCKET } from '../libs/cfHelper';

export function advancedSearch(data, cancelToken) {
    return function (dispatch, getState) {
        dispatch({type: SearchTypes.FETCHING_SEARCH_INDIVIDUALS});
        post(`${ROUTES.SEARCH_INDIVIDUALS}`, data, {
            cancelToken
        })
            .then(response => {
                if (response.data.code === 200) {
                    if (data.skip > 0) {
                        dispatch({
                            type: SearchTypes.FETCHING_MORE_RESULTS_SUCCESS,
                            payload: response.data.data,
                            memberType: data.memberType,
                            profileType: PROFILE_TYPES.INDIVIDUAL
                        });
                        dispatch({type: SearchTypes.CLEAR_SEARCH_TYPES});
                    } else {
                        dispatch({
                            type: SearchTypes.FETCHING_SEARCH_INDIVIDUALS_SUCCESS,
                            payload: response.data.data,
                            memberType: data.memberType
                        })
                        dispatch({
                            type: ConnectionTypes.FETCHING_RECOMMONDED_CONNECTIONS_SUCCESS,
                            payload: response.data.data
                        });
                        dispatch({type: SearchTypes.CLEAR_SEARCH_TYPES});
                    }

                    if (response.data.data && response.data.data.length > 0) {
                        let count = response.data.data.length;
                        let images = {};
                        let cacheImages = {};
                        response.data.data.forEach(item => {
                            if (!item.PROFILE_PHOTO || item.PROFILE_PHOTO === '') {
                                images[item._id] = '';
                                if (Object.keys(images).length === count) {
                                    dispatch({
                                        type: SearchTypes.SET_SEARCH_INDIVIDUALS_IMAGES,
                                        payload: images,
                                        memberType: data.memberType,
                                        profileType: PROFILE_TYPES.INDIVIDUAL
                                    });
                                }
                            } else {
                                const {images_L} = (getState().images);
                                if (images_L[item.PROFILE_PHOTO]) {
                                    images[item._id] = images_L[item.PROFILE_PHOTO];
                                    if (Object.keys(images).length === count) {
                                        dispatch({
                                            type: SearchTypes.SET_SEARCH_INDIVIDUALS_IMAGES,
                                            payload: images,
                                            memberType: data.memberType,
                                            profileType: PROFILE_TYPES.INDIVIDUAL
                                        });
                                    }
                                } else {
                                    const url = getCFObjURL(BUCKET.COMMON)((item.PROFILE_PHOTO), true);
                                    if (url) {
                                        images[item._id] = url;
                                        cacheImages[item.PROFILE_PHOTO] = url;
                                        if (Object.keys(images).length === count) {
                                            dispatch({
                                                type: SearchTypes.SET_SEARCH_INDIVIDUALS_IMAGES,
                                                payload: images,
                                                memberType: data.memberType,
                                                profileType: PROFILE_TYPES.INDIVIDUAL
                                            });
                                            dispatch({type: SET_IMAGES, payload: cacheImages})
                                        }
                                    } else {
                                        images[item._id] = '';
                                        if (Object.keys(images).length === count) {
                                            dispatch({
                                                type: SearchTypes.SET_SEARCH_INDIVIDUALS_IMAGES,
                                                payload: images,
                                                memberType: data.memberType,
                                                profileType: PROFILE_TYPES.INDIVIDUAL
                                            });
                                            dispatch({type: SET_IMAGES, payload: cacheImages})
                                        }
                                    }
                                }
                            }
                        })
                    }

                } else {
                    dispatch({type: SearchTypes.FETCHING_SEARCH_INDIVIDUALS_FAIL})
                }
                dispatch({type: ConnectionTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: SearchTypes.FETCHING_SEARCH_INDIVIDUALS_FAIL})
                    dispatch({type: SearchTypes.CLEAR_SEARCH_TYPES});
                }
            })
    }
}

export function advancedSearchV2(data, cancelToken) {
    return function (dispatch) {
        dispatch({type: SearchTypes.V2_ADVANCED_SEARCH, isInit: data.skip === 0,});

        if (data.skip === 0) {
            dispatch(advancedSearchCountV2(data))
        } //get count if first fetching

        post(`${ROUTES.TOP_SEARCH}`, data, {
            cancelToken,
            headers: {'X-Custom-Header': data}
        })
            .then(response => {
                if (response.data.code === 200 || 212) {
                    if (response.config.headers['X-Custom-Header'] === data) {
                        dispatch({
                            type: SearchTypes.V2_ADVANCED_SEARCH_SUCCESS,
                            payload: response.data.data,
                            isInit: data.skip === 0,
                            memberType: data.memberType,
                            isCompanyLoadMore: data.isCompanyLoadMore ? data.isCompanyLoadMore : false,
                            isUsersLoadMore: data.isUsersLoadMore ? data.isUsersLoadMore : false,
                        });
                    }

                } else {
                    dispatch({type: SearchTypes.V2_ADVANCED_SEARCH_FAIL});
                }
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: SearchTypes.V2_ADVANCED_SEARCH_FAIL});
                }
            });
    };
}

export function advancedSearchCountV2(data) {
    return function (dispatch) {
        dispatch({type: SearchTypes.V2_ADVANCED_SEARCH_COUNT});
        post(`${ROUTES.TOP_SEARCH_COUNT}`, data, {
            headers: {
                'X-Custom-Header': data
            }
        })
            .then(response => {
                if (response.data.code === 200 || 212) {
                    if (response.config.headers['X-Custom-Header'] === data) {
                        dispatch({
                            type: SearchTypes.V2_ADVANCED_SEARCH_COUNT_SUCCESS,
                            payload: response.data.data,
                            memberType: data.memberType
                        });
                    }
                } else {
                    dispatch({type: SearchTypes.V2_ADVANCED_SEARCH_COUNT_FAIL});
                }
            })
            .catch(error => {
                dispatch({type: SearchTypes.V2_ADVANCED_SEARCH_COUNT_FAIL});
            });
    }
}

export function getSearchCount(data, cancelToken) {
    return function (dispatch) {
        dispatch({type: SearchTypes.FETCHING_SEARCH_COUNT_INDIVIDUALS});
        post(`${ROUTES.GET_SEARCH_COUNT}`, data, {cancelToken})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({
                        type: SearchTypes.FETCHING_SEARCH_COUNT_INDIVIDUALS_SUCCESS,
                        payload: response.data.data,
                        userType: data.memberType
                    })
                } else {
                    dispatch({type: SearchTypes.FETCHING_SEARCH_COUNT_INDIVIDUALS_FAIL})
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: SearchTypes.FETCHING_SEARCH_COUNT_INDIVIDUALS_FAIL})
                }
            });
    }
}

export function searchCompanies(data) {
    return function (dispatch) {
        dispatch({type: SearchTypes.FETCHING_SEARCH_COMPANIES});
        post(`${ROUTES.SEARCH_COMPANIES}`, data)
            .then(response => {
                if (response.data.code === 200) {
                    if (data.skip > 0) {
                        dispatch({
                            type: SearchTypes.FETCHING_MORE_RESULTS_SUCCESS,
                            payload: response.data.data,
                            memberType: data.memberType,
                            profileType: PROFILE_TYPES.COMPANY
                        })
                    } else {
                        dispatch({
                            type: SearchTypes.FETCHING_SEARCH_COMPANIES_SUCCESS,
                            payload: response.data.data,
                            memberType: data.memberType
                        })
                    }
                } else {
                    dispatch({type: SearchTypes.FETCHING_SEARCH_COMPANIES_FAIL})
                }
            })
            .catch((error) => {
                dispatch({type: SearchTypes.FETCHING_SEARCH_COMPANIES_FAIL})
            })
    }
}

export function topSearchCount(event) {
    let data = {
        "searchType": SEARCH_TYPE.TOP_SEARCH,
        "memberType": MEMBER_TYPE.ALL,
        "searchTerm": event.target.value.trim()
    };

    return function (dispatch) {
        event.persist();
        dispatch({type: SearchTypes.FETCHING_TOP_SEARCH_COUNT});
        post(`${ROUTES.TOP_SEARCH_COUNT}`, data, {
            headers: {
                'X-Custom-Header': event.target.value.trim()
            }
        })
            .then(response => {
                if (response.data.code === 200) {
                    if (response.config.headers['X-Custom-Header'] === event.target.value.trim()) {
                        dispatch({type: SearchTypes.FETCH_TOP_SEARCH_COUNT_SUCCESS, payload: response.data.data});
                    }
                } else {
                    dispatch({type: SearchTypes.FETCH_TOP_SEARCH_COUNT_FAIL})
                }
            })
            .catch((error) => {
                dispatch({type: SearchTypes.FETCH_TOP_SEARCH_COUNT_FAIL})
            });
    }
}

export function topSearch(event) {

    let data = {
        "searchType": SEARCH_TYPE.TOP_SEARCH,
        "memberType": MEMBER_TYPE.ALL,
        "searchTerm": event.target.value.trim(),
        "skip": 0,
        "limit": 10
    };

    return function (dispatch) {
        event.persist();
        dispatch({type: SearchTypes.FETCHING_GENERAL_SEARCH});
        post(`${ROUTES.TOP_SEARCH}`, data, {
            headers: {
                'X-Custom-Header': event.target.value.trim()
            }
        })
            .then(response => {
                if (response.data.code === 200) {
                    if (response.config.headers['X-Custom-Header'] === event.target.value.trim()) {
                        dispatch({type: SearchTypes.FETCHING_GENERAL_SEARCH_SUCCESS, payload: response.data.data});
                    }
                } else {
                    dispatch({type: SearchTypes.FETCHING_GENERAL_SEARCH_FAIL})
                }
            })
            .catch((error) => {
                dispatch({type: SearchTypes.FETCHING_GENERAL_SEARCH_FAIL})
            });
    }

    /*let data = {
        "keywords": event.target.value.trimStart(),
        "skip": 0,
        "limit": 10
    };

    return function (dispatch) {
        event.persist();
        (event.which !== 32 || event.which === 8) && dispatch({ type: SearchTypes.FETCHING_GENERAL_SEARCH, str: event.target.value });
        post(`${ROUTES.TOP_SEARCH}`, data)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: SearchTypes.FETCHING_GENERAL_SEARCH_SUCCESS, payload: response.data.data, searchStr: event.target.value});
                } else {
                    dispatch({ type: SearchTypes.FETCHING_GENERAL_SEARCH_FAIL })
                }
            })
            .catch((error) => {
                dispatch({ type: SearchTypes.FETCHING_GENERAL_SEARCH_FAIL })
            });
    }*/
}

export function clearTopSearchText() {
    return function (dispatch) {
        dispatch({type: SearchTypes.CLEAR_TOP_SEARCH});
        dispatch({type: SearchTypes.CLEAR_ACTION});
    }
}

export function clearTopSearchResults() {
    return function (dispatch){
        dispatch({type: SearchTypes.CLEAR_TOP_SEARCH_RESULTS})
    }
}

export function requestToFetchMore(usertype) {
    return function (dispatch) {
        dispatch({type: SearchTypes.FETCHING_MORE_RESULTS, usertype: usertype});
    }
}

export function clearUserSearch() {
    return function (dispatch) {
        dispatch({type: SearchTypes.CLEAR_SEARCH_STATES});
    }
}

export function clearSearchText() {
    return function (dispatch) {
        dispatch({type: SearchTypes.CLEAR_SEARCH_TEXT});
        dispatch({type: SearchTypes.CLEAR_SEARCH_STATES});
    }
}

export function setInvestmentTypes(types) {
    return function (dispatch) {
        dispatch({type: SearchTypes.SETTING_INVESTMENT_TYPES, payload: types});
    }
}

export function setSearchTypes(companyType, plantTouching) {

    return function (dispatch) {
        dispatch({type: SearchTypes.SET_SEARCH_TYPES, companyType: companyType, plantTouching: plantTouching});
    }
}


export function clearSearchTypes() {
    return function (dispatch) {
        dispatch({type: SearchTypes.CLEAR_SEARCH_TYPES});
    }
}

export function clearInvestmentTypes() {
    return function (dispatch) {
        dispatch({type: SearchTypes.CLEAR_INVESTMENT_TYPES});
    }
}

export function clearTopSearchCount() {
    return function (dispatch) {
        dispatch({type: SearchTypes.CLEAR_TOP_SEARCH_COUNT});
    }
}

export function clearSearchResults() {
    return function (dispatch) {
        dispatch({type: SearchTypes.V2_CLEAR_SEARCH_RESULTS});
    }
}
