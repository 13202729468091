import React from "react";
import classname from "classnames";
//import Measure from "react-measure";
import "./styles.css";

class Accordion extends React.Component {
  constructor() {
    super();
    this.state = {
      dimensions: {
        width: -1,
        height: -1
      }
    };
  }

  setHeight = (height, isOpen) => {
    if (isOpen) {
      return height + "px";
    } else {
      return 0 + "px";
    }
  };

  render() {
    let cx = classname(
      "accordion_panel",
      this.props.isOpen === true
        ? "accordion_panel__light"
        : "accordion_panel__dim"
    );

    return (

        <div
          style={{
            height: this.setHeight(this.props.height, this.props.isOpen),
          }}
          className={cx}
        >
          {this.props.children}
        </div>
        

    );

  }
}

export { Accordion };
