import axios from 'axios';
import NewsFeedTypes from '../types/newsFeed';
import { DECREMENT_PENDING_CONNECTION, SEND_CONNECTION_REQUEST_SUCCESS } from '../types/connections';
import AuthTypes from '../types/authentication';
import { ROUTES } from '../config';

import {
    ALERT,
    POP_ALERT,
    NOTIFICATION_TYPES,
    CONNECTION_REJECT_TYPES,
    USER_EMOTION_TYPES,
    VIDEO_PROCESS_STATUS,
    BLOCKUSER_ACCEPT_ERROR_MSG,
    BLOCKUSER_REJECT_ERROR_MSG,
    BLOCKUSER_CONNECT_ERROR_MSG,
    BLOCKUSER_POPUP_CLASSNAME,
    BLOCKPOST_SUCCESS,
    BLOCKPOST_FAIL
} from '../types/common';
import { NEWSFEED_ADD_CONNECTION_SUCCESS } from '../types/profile';
import { showAlert, showPopupAlert } from './alert';
import { notifyReceiver } from '../libs/socket';
import { reloadUserNotificationCounters } from './auth';
import { refeshChat, getChatConversations } from './chat'

import { errorHandler, notifyVideoProcessStatus } from '../libs/commonFunctions';
import { get, post, put, imgPut } from '../libs/apiConnector'
import { getLeftPanelCoversationCounts } from './message-v2'
import { ADD_LAST_ACCEPTED_USER_MESSAGE } from '../types/message-v2'
import { notifyUserAccountDeletion } from './profile';

export function getUserProfileInfo(cancelToken) {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.FETCHING_PROFILE_DATA });
        post(`${ROUTES.USERDETAILS}`, {}, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: NewsFeedTypes.FETCH_PROFILE_DATA_SUCCESS, payload: response.data });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                } else if (response.data.code === 222) {
                    dispatch({ type: AuthTypes.ACTIVATION_REQUIRED, payload: response.data })
                    showAlert(dispatch, ALERT.ERROR, response.data.message);
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: NewsFeedTypes.FETCH_PROFILE_DATA_FAIL });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                }
            })
    }
}

async function uploadPostVideo(postVideoFile, videoToUpload, dispatch) {
    try {
        let fileData = postVideoFile[0]

        let signedUrl = fileData.SIGNED_PUT_URL;
        let options = {
            headers: { 'Content-Type': videoToUpload.type },
            onUploadProgress: (progressEvent) => {
                let progress = (progressEvent.loaded / progressEvent.total);
                if (progress === 1) {
                    dispatch({ type: NewsFeedTypes.PROCESSING_NEWSFEED_VIDEO, videoKey: fileData.FILE_NAME })
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                } else {
                    dispatch({ type: NewsFeedTypes.UPLOADING_NEWSFEED_VIDEO, videoKey: fileData.FILE_NAME, progress: Math.round(progress * 100) })
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                }
            },
            onerror: () => {
                return false
            }
        };
        await imgPut(signedUrl, videoToUpload, options)
        return true;
    } catch (ex) { return false }
}

export function addPost(postCover, postCreator, postDescription, postMediaType, postVideoFile, postType, richText, videoToUpload = null, hideNewPosts = false) {
    return async function (dispatch, getState) {
        let videoUploaded = true
        if (videoToUpload) {
            localStorage.setItem('isVideoUploading', true)
            videoUploaded = await uploadPostVideo(postVideoFile, videoToUpload, dispatch)
        }
        if (videoUploaded) {
            post(`${ROUTES.ADD_POST}`, { postCover, postCreator, postDescription, postMediaType, postVideoFile, postType, richText })
                .then(async response => {
                    localStorage.removeItem('isVideoUploading')
                    if (response.data.code === 200) {
                        await dispatch({ type: NewsFeedTypes.ADD_POST_SUCCESS });
                        if (videoToUpload) {
                            let { user } = getState().auth;
                            let creatorDetails = {
                                PROFILE_PHOTO: user.PHOTO,
                                MEMBER_TYPE: user.MEMBER_TYPE,
                                FIRST_NAME: user.FIRST_NAME,
                                LAST_NAME: user.LAST_NAME,
                                HEADLINE: user.HEADLINE,
                                JOB_TITLE: user.JOB_TITLE,
                                COMPANY_NAME: user.COMPANY_NAME,
                                IS_EXPERT_CONTRIBUTOR: user.EXPERT_CONTRIBUTOR,
                                IS_PRO_MEMBER: user.IS_PRO_MEMBER,
                            };
                            showPopupAlert(dispatch, POP_ALERT.SUCCESS, 'We are processing your video & will notify you when it completes. Your post will not be seen by anyone other than you until processing completes.');
                            if(!hideNewPosts) { dispatch({ type: NewsFeedTypes.SET_PENDING_POST, pendingPost: { ...response.data.data[0], POST_CREATOR_DETAILS: creatorDetails } }); }
                        } else {
                            if(!hideNewPosts) {
                                const response1 = await get(`${ROUTES.LOAD_SINGLE_POST_V_1}/${response.data.data._id}`)
                                await dispatch({ type: NewsFeedTypes.SET_RECENT_POST, recentPost: response1.data.data });
                            }
                        }
                        dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                    } else if (response.data.code === 216) {
                        await dispatch({ type: NewsFeedTypes.FETCH_PROFILE_DATA_FAIL });
                        dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                    } else {
                        showPopupAlert(dispatch, POP_ALERT.ERROR, response.data.message);
                        await dispatch({ type: NewsFeedTypes.ADD_POST_EXCEPTION });
                        dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                    }
                })
                .catch(async (error) => {
                    if (videoToUpload && !videoUploaded) {
                        showPopupAlert(dispatch, POP_ALERT.ERROR, 'Network Error');
                    }
                    localStorage.removeItem('isVideoUploading')
                    await dispatch({ type: NewsFeedTypes.ADD_POST_EXCEPTION });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                })
        } else {
            if (videoToUpload) {
                showPopupAlert(dispatch, POP_ALERT.ERROR, 'Network Error');
            }
            localStorage.removeItem('isVideoUploading')
            await dispatch({ type: NewsFeedTypes.ADD_POST_EXCEPTION });
            dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
        }
    }
}

export function editPost(postId, postCover, postCreator, postDescription, postMediaType, postVideoFile, postType, richText, callbackFunc, videoToUpload = null) {
    return async function (dispatch) {
        let videoUploaded = true
        if (videoToUpload) {
            localStorage.setItem('isVideoUploading', true)
            videoUploaded = await uploadPostVideo(postVideoFile, videoToUpload, dispatch)
        }
        if (videoUploaded) {
            put(`${ROUTES.EDIT_POST}${postId}`, { postId, postCover, postCreator, postDescription, postMediaType, postVideoFile, postType, richText })
                .then(async response => {
                    localStorage.removeItem('isVideoUploading')
                    if (response.data.code === 200) {
                        let videoFile = (response.data && response.data.data && response.data.data.length > 0 && response.data.data[0].POST_VIDEO) ? response.data.data[0].POST_VIDEO : [];
                        await dispatch({ type: NewsFeedTypes.EDIT_POST_SUCCESS, postId, postDescription, richText, postMediaType, postVideoFile: videoFile, isPostVideoIncluded: (postVideoFile && postVideoFile.length > 0) });
                        await dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                        showPopupAlert(dispatch, POP_ALERT.SUCCESS, videoToUpload ? 'We are processing your video & will notify you when it completes. Your edited post will not be seen by anyone other than you until processing completes.' : response.data.message);
                    } else if (response.data.code === 216) {
                        await dispatch({ type: NewsFeedTypes.FETCH_PROFILE_DATA_FAIL });
                        await dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                        showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                        notifyVideoProcessStatus(postId, VIDEO_PROCESS_STATUS.COMPLETED)
                    } else {
                        await dispatch({ type: NewsFeedTypes.EDIT_POST_EXCEPTION });
                        dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                        showPopupAlert(dispatch, POP_ALERT.ERROR, 'Unexpected error occured while updating the post');
                        notifyVideoProcessStatus(postId, VIDEO_PROCESS_STATUS.COMPLETED)
                    }
                    callbackFunc()
                })
                .catch(async (error) => {
                    callbackFunc()
                    localStorage.removeItem('isVideoUploading')
                    await dispatch({ type: NewsFeedTypes.EDIT_POST_EXCEPTION });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                    showPopupAlert(dispatch, POP_ALERT.ERROR, 'Unexpected error occured while updating the post');
                    notifyVideoProcessStatus(postId, VIDEO_PROCESS_STATUS.COMPLETED)
                })
        } else {
            localStorage.removeItem('isVideoUploading')
            callbackFunc()
            await dispatch({ type: NewsFeedTypes.EDIT_POST_EXCEPTION });
            dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
            notifyVideoProcessStatus(postId, VIDEO_PROCESS_STATUS.COMPLETED)
        }

    }
}

export function clearNewsFeeds() {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.CLEAR_NEWS_FEEDS });
    }
}

export function clearNewsFeedCount() {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.CLEAR_NEWS_FEED_COUNT });
    }
}

export function fetchNewsFeed(skip, limit, cancelToken, marketplaceProPlusAdsCache) {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.FETCHING_NEWS_FEEDS });

        // load open newsfeed for user untill newsfeed generation
        const userId = (localStorage.getItem("userId") && localStorage.getItem("personalNewsfeedAvailable") === 'true') ? localStorage.getItem("userId") : ""

        Promise.allSettled([
            post(
                `${ROUTES.NEWSFEED}/${userId}`,
                {
                    skip,
                    limit,
                    userId: userId,
                },
                {
                    cancelToken,
                    headers: { "X-Custom-Header": skip },
                }
            ),
            get(
                `${ROUTES.GREEN_PAGE_NEWSFEED_ADVERTISEMENT}`, 
                { cancelToken }
            )
            /*post(
                `${ROUTES.NEWSFEED_ADVERTISEMENT}/${localStorage.getItem("userId")
                    ? localStorage.getItem("userId")
                    : ""
                }`,
                { check: marketplaceProPlusAdsCache },
                { cancelToken }
            ),*/
        ])
            .then(axios.spread(async (res1, res2) => {
                let advertisement = null;
                if (res2 && res2.status === 'fulfilled' && res2.value && res2.value.data && res2.value.data.code === 200 && res2.value.data.data) {
                    advertisement = res2.value.data.data
                }

                if (res1 && res1.status === "fulfilled" && res1.value) {
                    const response = res1.value;
                    if (response && response.data.code === 200) {
                        if (response.data.data && response.data.data.length > 0 /*limit*/) {
                            let newsfeedUpdatedValues = [];
                            if (response.data.data && response.data.data.length >= 2) {
                                newsfeedUpdatedValues = [...response.data.data.slice(0, 2)];
                            }
                            if (advertisement) {
                                newsfeedUpdatedValues = [...newsfeedUpdatedValues, advertisement];
                            }
                            if (response.data.data && response.data.data.length > 2) {
                                newsfeedUpdatedValues = [
                                    ...newsfeedUpdatedValues,
                                    ...response.data.data.slice(2),
                                ];
                            }
                            response.data.data = newsfeedUpdatedValues;

                            dispatch({
                                type: NewsFeedTypes.FETCH_NEWS_FEED_SUCCESS,
                                payload: response.data,
                                isInit: skip === 0,
                                isAdvertisementIncluded: advertisement !== null,
                                skip: skip
                            });
                            if (response.data.data && response.data.data.length > 0) {
                                dispatch({
                                    type: NewsFeedTypes.CLEAR_PENDING_POST,
                                    publishedPostes: response.data.data.map((p) => {
                                        return p._id;
                                    }),
                                });
                            }
                        } else {
                            try {
                                const res3 = await post(ROUTES.EXTRA_NEWSFEED, { skip, limit }, { cancelToken, headers: { "X-Custom-Header": skip }, })
                                if (
                                    res3 &&
                                    res3.data.code === 200 &&
                                    res3.data.data &&
                                    res3.data.data.length > 0
                                ) {
                                    let newsfeedUpdatedValues = [];
                                    if (res3.data.data && res3.data.data.length >= 2) {
                                        newsfeedUpdatedValues = [
                                            ...res3.data.data.slice(0, 2),
                                        ];
                                    }
                                    if (advertisement) newsfeedUpdatedValues = [...newsfeedUpdatedValues, advertisement];
                                    if (res3.data.data && res3.data.data.length > 2) newsfeedUpdatedValues = [...newsfeedUpdatedValues, ...res3.data.data.slice(2)];

                                    res3.data.data = newsfeedUpdatedValues;

                                    dispatch({
                                        type: NewsFeedTypes.FETCH_NEWS_FEED_SUCCESS,
                                        payload: res3.data,
                                        isInit: skip === 0,
                                        isAdvertisementIncluded: advertisement !== null
                                    });
                                    if (res3.data.data && res3.data.data.length > 0) {
                                        dispatch({
                                            type: NewsFeedTypes.CLEAR_PENDING_POST,
                                            publishedPostes: res3.data.data.map((p) => {
                                                return p._id;
                                            }),
                                        });
                                    }
                                } else if (skip === 0) {
                                    await dispatch({
                                        type: NewsFeedTypes.FETCH_NEWS_FEED_INIT_ERROR,
                                    });
                                    await dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                                } else {
                                    dispatch({ type: NewsFeedTypes.FETCH_NEWS_FEED_FAIL });
                                }
                            } catch (error) {
                                dispatch({ type: NewsFeedTypes.FETCH_NEWS_FEED_FAIL });
                            }

                        }

                    } else if (response && response.data.code === 212) {
                        // Data Not Found
                        dispatch({
                            type: NewsFeedTypes.FETCH_NEWS_FEED_SUCCESS,
                            payload: response.data,
                            isAdvertisementIncluded: advertisement !== null
                        });
                    } else if (response && response.data.code === 216) {
                        // DB Error Occured
                        dispatch({ type: NewsFeedTypes.FETCH_NEWS_FEED_FAIL });
                    } else if (response && response.data.code === 404) {
                        // Server Side Exception Occured
                        dispatch({ type: NewsFeedTypes.FETCH_NEWS_FEED_EXCEPTION });
                    }
                }

                if (res1.status === "rejected" && skip === 0) {
                    await dispatch({ type: NewsFeedTypes.FETCH_NEWS_FEED_INIT_ERROR });
                    await dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                }

            }))
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    errorHandler(dispatch, error);
                    dispatch({ type: NewsFeedTypes.FETCH_NEWS_FEED_EXCEPTION });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                }
            });
    };
}

// export function fetchNewsFeed(skip, limit, cancelToken, marketplaceProPlusAdsCache) {
//     return function (dispatch) {
//         dispatch({type: NewsFeedTypes.FETCHING_NEWS_FEEDS});
//         get(`${ROUTES.NEWSFEED}/${skip}/${limit}/${marketplaceProPlusAdsCache}${localStorage.getItem('userId') ? '/' + localStorage.getItem('userId') : ''}`, {
//             cancelToken,
//             headers: {'X-Custom-Header': skip}
//         })
//             .then(response => {
//                 if (response && response.data.code === 200) {
//                     dispatch({ type: NewsFeedTypes.FETCH_NEWS_FEED_SUCCESS, payload: response.data, isInit: skip === 0 });
//                     if(response.data.data && response.data.data.length > 0){
//                         dispatch({ type: NewsFeedTypes.CLEAR_PENDING_POST, publishedPostes: response.data.data.map( p=> {return p._id}) });
//                     }
//                 } else if (response && response.data.code === 212) { // Data Not Found
//                     dispatch({type: NewsFeedTypes.FETCH_NEWS_FEED_SUCCESS, payload: response.data});
//                 } else if (response && response.data.code === 216) { // DB Error Occured
//                     dispatch({type: NewsFeedTypes.FETCH_NEWS_FEED_FAIL});
//                 } else if (response && response.data.code === 404) { // Server Side Exception Occured
//                     dispatch({type: NewsFeedTypes.FETCH_NEWS_FEED_EXCEPTION});
//                 }
//             })
//             .catch((error) => {
//                 if (axios.isCancel(error)) {
//                     return;
//                 } else {
//                     errorHandler(dispatch, error);
//                     dispatch({type: NewsFeedTypes.FETCH_NEWS_FEED_EXCEPTION});
//                     dispatch({type: NewsFeedTypes.CLEAR_ACTION});
//                 }
//             })
//     }
// }

export function likePost(postId, reaction = 1) {
    return function (dispatch) {
        put(`${ROUTES.LIKE_POST}${postId}`, { userEmotion: reaction })
            .then(response => {
                if (response.data.code === 200) {
                    if (response.data.data && reaction !== USER_EMOTION_TYPES.NONE) {
                        dispatch({ type: NewsFeedTypes.LIKE_POST_SUCCESS, payload: postId, reaction })
                    } else {
                        dispatch({ type: NewsFeedTypes.UNLIKE_POST_SUCCESS, payload: postId })
                    }
                }
            })
            .catch((error) => {
                dispatch({ type: NewsFeedTypes.UNLIKE_POST_SUCCESS, payload: postId })
            });
    }
}

export function dislikePost(postId) {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.UNLIKE_POST_SUCCESS, payload: postId })
    }
}

export function newsFeedCount(cancelToken) {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.FETCHING_NEWS_FEED_COUNT });
        get(`${ROUTES.NEWSFEED_COUNT}/${localStorage.getItem('userId') ? localStorage.getItem('userId') : ''}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: NewsFeedTypes.FETCH_NEWS_FEED_COUNT_SUCCESS, payload: response.data });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                } else if (response.data.code === 212) { // Data Not Found
                    dispatch({ type: NewsFeedTypes.FETCH_NEWS_FEED_COUNT_SUCCESS, payload: response.data });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                } else if (response.data.code === 216) { // DB Error Occured
                    dispatch({ type: NewsFeedTypes.FETCH_NEWS_FEED_COUNT_FAIL });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                } else if (response.data.code === 404) { // Server Side Exception Occured
                    dispatch({ type: NewsFeedTypes.FETCH_NEWS_FEED_COUNT_EXCEPTION });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: NewsFeedTypes.FETCH_NEWS_FEED_COUNT_EXCEPTION });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                }
            })
    }
}

export function archivePost(postId) {
    return function (dispatch) {
        put(`${ROUTES.ARCHIVE_POST}${postId}`, {})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: NewsFeedTypes.POST_ARCHIVE_SUCCESS, payload: postId });
                    showPopupAlert(dispatch, POP_ALERT.SUCCESS, response.data.message);
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                } else {
                    dispatch({ type: NewsFeedTypes.POST_ARCHIVE_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                }
            })
            .catch((error) => {
                dispatch({ type: NewsFeedTypes.POST_ARCHIVE_FAIL });
                showPopupAlert(dispatch, POP_ALERT.WARNING, "Unexpected error occured");
                dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
            });
    }
}

export function removePostFromFeed(postId, action) {
    return function(dispatch){
        dispatch({ type: NewsFeedTypes.POST_REMOVE, payload: postId, callAction: action });
    }
}

export function getSinglePost(postId, commentId, cancelToken, reportedCommentId = null, groupId) {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.FETCHING_POST });
        get(`${ROUTES.VIEW_POST}/${postId}${groupId ? `/${groupId}` : ''}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: NewsFeedTypes.FETCH_POST_SUCCESS, payload: response.data });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                    if (reportedCommentId) {
                        dispatch(getComments(postId, 0, 500, commentId, null, true));
                    } else {
                        dispatch(getComments(postId, 0, 5, commentId, null, true));
                    }

                } else if (response.data.code === 212) { // Data Not Found
                    dispatch({ type: NewsFeedTypes.FETCH_POST_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                } else if (response.data.code === 216) { // DB Error Occured
                    dispatch({ type: NewsFeedTypes.FETCH_POST_FAIL });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                } else if (response.data.code === 404) { // Server Side Exception Occured
                    dispatch({ type: NewsFeedTypes.FETCH_POST_EXCEPTION });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: NewsFeedTypes.FETCH_POST_EXCEPTION });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                }
            })
    }
}

export function clearSinglePost() {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.CLEAR_SINGLE_POST });
    }
}

export function getSuggestions(cancelToken) {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.FETCHING_SUGGESTIONS });
        get(`${ROUTES.GET_WIDGET_V2_SUGGESTIONS}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: NewsFeedTypes.FETCHING_SUGGESTIONS_SUCCESS, payload: response.data.data });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                } else {
                    dispatch({ type: NewsFeedTypes.FETCHING_SUGGESTIONS_FAIL });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: NewsFeedTypes.FETCHING_SUGGESTIONS_FAIL });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                }
            })
    }
}

export function clearSuggestions() {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.CLEAR_SUGGESTIONS });
    }
}

export function addSuggestion(id, msg = '', viewId, isFromRecommended = null, socketInfo = null, successCallback = null, errorCallback = null) {
    return function (dispatch, getState) {
        let cachedData = {};
        if (isFromRecommended) {
            cachedData = {
                cacheIndex: isFromRecommended.cacheIndex,
                pageNo: isFromRecommended.pageNo,
                includeResults: true
            }
        } else {
            cachedData = { cacheIndex: null, pageNo: null }
        }
        let data = { "userId": id, "text": msg, viewId, ...cachedData };
        dispatch({ type: NewsFeedTypes.ADDING_SUGGESTIONS });
        post(`${ROUTES.ADD_CONNECTION}`, data)
            .then(response => {
                if (response.data.code === 200) {

                    //update conversation if connection request has message
                    if (msg !== '') {
                        dispatch(getChatConversations({
                            username: '',
                            module: 0,
                            skip: 0,
                            limit: 16
                        }));
                    }

                    dispatch({ type: NEWSFEED_ADD_CONNECTION_SUCCESS, requestId: id });
                    dispatch({ type: NewsFeedTypes.ADD_SUGGESTION_SUCCESS });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                    // dispatch(clearCachedConnection(isFromRecommended));

                    // emit socket
                    if (socketInfo && socketInfo.socket && socketInfo.userId && socketInfo.type && response.data.data.length) {
                        const conversationId = (msg !== '' && response.data.data[0].CONVERSATION) ? response.data.data[0].CONVERSATION._id : null;
                        let conversationData = (msg !== '' && response.data.data[0].CONVERSATION) ? response.data.data[0].CONVERSATION : null;
                        let connectionData = response.data.data[0];
                        delete connectionData.CONVERSATION

                        if (socketInfo.type === NOTIFICATION_TYPES.CONNECTION_REQUESTS) {
                            notifyReceiver(socketInfo.socket, socketInfo.userId, socketInfo.type, connectionData, conversationId, null);
                        } else {
                            notifyReceiver(socketInfo.socket, socketInfo.userId, NOTIFICATION_TYPES.CONNECTION_REQUESTS, connectionData, conversationId, null);

                            const user = getState().auth.user;
                            conversationData.RECIPENT = [
                                {
                                    SYSTEM_USER_ID: user._id,
                                    LOWER_NAME: (user.FIRST_NAME + ' ' + user.LAST_NAME).toLowerCase(),
                                    NAME: user.FIRST_NAME + ' ' + user.LAST_NAME,
                                    FIRST_NAME: user.FIRST_NAME,
                                    LAST_NAME: user.LAST_NAME,
                                    MEMBER_TYPE: user.MEMBER_TYPE,
                                    PHOTO: user.PHOTO,
                                    STATUS: 1
                                }
                            ];

                            notifyReceiver(socketInfo.socket, socketInfo.userId, NOTIFICATION_TYPES.CONNECTIONS_MSG, conversationData, conversationId, null);
                        }

                    } else {
                        console.error('socket connection failed, invalid data', {
                            userId: socketInfo.userId,
                            type: socketInfo.type
                        })
                    }

                    if (successCallback) {
                        successCallback();                       
                    }

                } else {
                    if (response.data && response.data.code === 221) {
                        let event = new CustomEvent("maxDailyConnectionLimit", {});
                        document.dispatchEvent(event);
                    } else if (response.data && response.data.code === 217) {
                        showPopupAlert(dispatch, POP_ALERT.OK, '', BLOCKUSER_CONNECT_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
                    }
                    dispatch({ type: NewsFeedTypes.ADD_SUGGESTION_FAIL });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });

                    if (errorCallback) {
                        errorCallback()
                    }
                }
            })
            .catch((error) => {
                dispatch({ type: NewsFeedTypes.ADD_SUGGESTION_FAIL });
                dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                if (errorCallback) {
                    errorCallback()
                }
            })
    }
}

export function getComments(postId, skip, limit, commentId = '', cancelToken, singlePost = false) {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.GET_COMMENTS });
        post(`${ROUTES.FETCH_COMMENTS}${postId}/${commentId}`, { "skip": skip, "limit": limit }, { cancelToken })
            .then(response => {
                if (response.data.code === 200 || response.data.code === 209) {
                    if (response.data.code === 209) showAlert(dispatch, ALERT.ERROR, response.data.message);
                    dispatch({
                        type: NewsFeedTypes.GET_COMMENTS_SUCCESS,
                        payload: response.data.data.COMMENTS ? response.data.data.COMMENTS : [],
                        commentCount: response.data.data.COMMENTS_COUNT,
                        id: postId,
                        singlePost
                    })
                } else {
                    dispatch({ type: NewsFeedTypes.GET_COMMENTS_FAIL, id: postId })
                }
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: NewsFeedTypes.GET_COMMENTS_FAIL, id: postId })
                }
            })
    }
}

export function addComment(postId, comment, type, limit, richText) {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.COMMENT_POST });
        put(`${ROUTES.ADD_COMMENT + postId}`, { comment, richText })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: NewsFeedTypes.COMMENT_POST_SUCCESS, payload: response.data, postId: postId });
                    if (type === 'single') {
                        dispatch(getSinglePost(postId))
                    } else {
                        dispatch(getComments(postId, 0, 10));
                    }
                } else {
                    dispatch({ type: NewsFeedTypes.COMMENT_POST_FAIL, payload: postId })
                }
            })
            .catch(err => {
                dispatch({ type: NewsFeedTypes.COMMENT_POST_FAIL, payload: postId })
            })
    };
}

export function editComment(postId, commentId, comment, type, limit, richText, callbackFunc) {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.COMMENT_POST });
        put(`${ROUTES.EDIT_COMMENT + postId}`, { commentId, comment, richText })
            .then(async response => {
                if (response.data.code === 200) {
                    await dispatch({ type: NewsFeedTypes.COMMENT_POST_SUCCESS, payload: response.data, postId: postId, isEdit: true });
                    if (type === 'single') {
                        dispatch(getSinglePost(postId))
                    } else {
                        dispatch(getComments(postId, 0, limit));
                    }
                    showPopupAlert(dispatch, POP_ALERT.SUCCESS, response.data.message);
                } else {
                    dispatch({ type: NewsFeedTypes.COMMENT_POST_FAIL, payload: postId })
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
                callbackFunc()
            })
            .catch(err => {
                dispatch({ type: NewsFeedTypes.COMMENT_POST_FAIL, payload: postId })
                showPopupAlert(dispatch, POP_ALERT.ERROR, 'Unexpected error occured while updating the comment');
            })
    };
}

export function deleteComment(postId, commentId, type, limit) {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.DELETE_COMMENT_POST });
        put(`${ROUTES.DELETE_COMMENT + postId}`, { "commentId": commentId })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: NewsFeedTypes.DELETE_COMMENT_POST_SUCCESS, postId: postId })
                    if (type === 'single') {
                        dispatch(getSinglePost(postId))
                    } else {
                        dispatch(getComments(postId, 0, limit));
                    }
                } else {
                    dispatch({ type: NewsFeedTypes.DELETE_COMMENT_POST_FAIL, payload: postId });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION })
                }
            })
            .catch(err => {
                dispatch({ type: NewsFeedTypes.DELETE_COMMENT_POST_FAIL, payload: postId });
                showPopupAlert(dispatch, POP_ALERT.WARNING, "Unexpected error occured");
                dispatch({ type: NewsFeedTypes.CLEAR_ACTION })
            })
    }
}

export function getUpCommingEvents(cancelToken) {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.FETCHING_UP_COMING_EVENTS });
        get(`${ROUTES.GET_UP_COMMING_EVENTS}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: NewsFeedTypes.FETCHING_UP_COMING_EVENTS_SUCCESS, payload: response.data.data });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                } else {
                    dispatch({ type: NewsFeedTypes.FETCHING_UP_COMING_EVENTS_FAIL });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: NewsFeedTypes.FETCHING_UP_COMING_EVENTS_FAIL });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                }
            })
    }
}

export function clearUpComingEvents() {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.CLEAR_UP_COMING_EVENTS });
    }
}

export function fetchFundrasingCompanies(cancelToken) {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.FETCHING_FUNDRAISING_COMPANIES });
        get(`${ROUTES.FETCH_FUNDRAISING_COMPANIES}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: NewsFeedTypes.FETCH_FUNDRAISING_COMPANIES_SUCCESS, payload: response.data.data });
                } else {
                    dispatch({ type: NewsFeedTypes.FETCH_FUNDRAISING_COMPANIES_FAIL });
                }
                dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: NewsFeedTypes.FETCH_FUNDRAISING_COMPANIES_FAIL });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                }
            })
    }
}

export function clearFundrasingCompanies() {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.CLEAR_FUNDRAISING_COMPANIES });
    }
}

export function getFundraisingCompanyCount(cancelToken) {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.FETCHING_FUNDRAISING_COMPANY_COUNT });
        get(`${ROUTES.FUNDRAISING_COMPANY_COUNT}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({
                        type: NewsFeedTypes.FETCH_FUNDRAISING_COMPANY_COUNT_SUCCESS,
                        payload: response.data.data
                    });
                } else {
                    dispatch({ type: NewsFeedTypes.FETCH_FUNDRAISING_COMPANY_COUNT_FAIL });
                }
                dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: NewsFeedTypes.FETCH_FUNDRAISING_COMPANY_COUNT_FAIL });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                }
            })
    }
}

export function clearFundrasingCompanyCount() {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.CLEAR_FUNDRAISING_COMPANY_COUNT });
    }
}

export function fetchLikedUsersByPostId(postId, skip, limit, cancelToken) {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.FETCHING_POST_LIKED_USERS });
        post(`${ROUTES.LIKED_USERS}/${postId}`, { "skip": skip, "limit": limit }, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: NewsFeedTypes.FETCH_POST_LIKED_USERS_SUCCESS, payload: response.data });
                } else {
                    dispatch({ type: NewsFeedTypes.FETCH_POST_LIKED_USERS_FAIL });
                }
                dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: NewsFeedTypes.FETCH_POST_LIKED_USERS_FAIL });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                }
            })
    }
}

export function clearPostLikedUserList() {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.CLEAR_POST_LIKED_USERS });
    }
}

export function fetchCommenterDetails(commenterId, cancelToken) {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.FETCHING_COMMENTER_DETAILS });
        get(`${ROUTES.COMMENTER_DETAILS}/${commenterId}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: NewsFeedTypes.FETCH_COMMENTER_DETAILS_SUCCESS, payload: response.data });
                } else {
                    dispatch({ type: NewsFeedTypes.FETCH_COMMENTER_DETAILS_FAIL });
                }
                dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: NewsFeedTypes.FETCH_COMMENTER_DETAILS_FAIL });
                    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
                }
            })
    }
}

export function acceptConnectionRequest(requesterUserId, index, location, socket = null) {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.ACCEPTING_CONNECTION });
        post(`${ROUTES.ACCEPT_CONNECTION}`, { requesterUserId })
            .then(async response => {
                if (response.data.code === 200) {
                    // reset chat
                    refeshChat(dispatch, requesterUserId);
                    setTimeout(() => {
                        dispatch(getLeftPanelCoversationCounts())
                    }, 4000)
                    if (response.data && response.data.data && response.data.data.conversationId) {
                        dispatch({ type: ADD_LAST_ACCEPTED_USER_MESSAGE, conversationId: response.data.data.conversationId })
                    }

                    dispatch({ type: NewsFeedTypes.CONNECTION_ACCEPT_SUCCESS, payload: index, location: location });
                    dispatch({ type: DECREMENT_PENDING_CONNECTION, userId: requesterUserId });

                    // Notify Reaction to requester

                    if (socket) {
                        let socketData = {
                            type: 'ACCEPT',
                            requester: requesterUserId,
                            recipient: localStorage.getItem('userId'),
                            newConversationId: (response.data && response.data.data && response.data.data.conversationId) ? response.data.data.conversationId : null
                        };
                        notifyReceiver(socket, requesterUserId, NOTIFICATION_TYPES.REACT_CONNECTION_REQUEST, socketData)
                    } else {
                        console.error('socket error')
                    }

                } else if (response.data.code === 217) {
                    dispatch({ type: NewsFeedTypes.CONNECTION_ACCEPT_FAIL, payload: index });
                    showPopupAlert(dispatch, POP_ALERT.OK, '', BLOCKUSER_ACCEPT_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
                } else {
                    dispatch({ type: NewsFeedTypes.CONNECTION_ACCEPT_FAIL, payload: index });
                    await notifyUserAccountDeletion(requesterUserId)
                }
                dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
            })
            .catch((error) => {
                dispatch({ type: NewsFeedTypes.CONNECTION_ACCEPT_FAIL, payload: index });
                dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
            })
    }
}

export function rejectConnectionRequest(rejectUserId, index, location, rejectType = CONNECTION_REJECT_TYPES.REJECT_REQUEST, socket = null) {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.REJECTING_CONNECTION });
        post(`${ROUTES.REJECT_CONNECTION}`, { rejectUserId, rejectType })
            .then(response => {
                if (response.data.code === 200) {

                    // Notify Reaction to requester
                    if (socket) {
                        let socketData = {
                            type: 'REJECT',
                            requester: rejectUserId,
                            recipient: localStorage.getItem('userId'),
                            newConversationId: null,
                            deletedConversation: (response.data.data && response.data.data.deletedConversationId) ? response.data.data.deletedConversationId : null
                        };
                        notifyReceiver(socket, rejectUserId, NOTIFICATION_TYPES.REACT_CONNECTION_REQUEST, socketData)
                    }

                    dispatch({ type: NewsFeedTypes.CONNECTION_REJECT_SUCCESS, payload: index, location: location });
                    dispatch({ type: DECREMENT_PENDING_CONNECTION, userId: rejectUserId });
                    dispatch(reloadUserNotificationCounters());
                } else if (response.data.code === 217) {
                    dispatch({ type: NewsFeedTypes.CONNECTION_REJECT_FAIL, payload: index });
                    showPopupAlert(dispatch, POP_ALERT.OK, '', BLOCKUSER_REJECT_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
                } else {
                    dispatch({ type: NewsFeedTypes.CONNECTION_REJECT_FAIL, payload: index });
                }
                dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
            })
            .catch((error) => {
                dispatch({ type: NewsFeedTypes.CONNECTION_REJECT_FAIL, payload: index });
                dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
            })
    }
}

export function addConnection(uid, index, location, msg = '', socketInfo = null) {
    return function (dispatch, getState) {
        let cachedData = { cacheIndex: null, pageNo: null };
        let data = { "userId": uid, "text": msg, ...cachedData };
        dispatch({ type: NewsFeedTypes.ADDING_CONNECTION });
        post(`${ROUTES.ADD_CONNECTION}`, data)
            .then(response => {
                if (response.data.code === 200) {
                    let payload = {
                        RECIPIENT: uid,
                        REQUESTER: localStorage.getItem('userId'),
                        STATUS: 1
                    };
                    dispatch({
                        type: NewsFeedTypes.CONNECTION_ADD_SUCCESS,
                        payload: payload,
                        index: index,
                        location: location
                    });

                    if (socketInfo && socketInfo.socket && socketInfo.userId && socketInfo.type && response.data.data.length) {
                        const connectionData = response.data.data[0];
                        notifyReceiver(socketInfo.socket, socketInfo.userId, socketInfo.type, connectionData, null, null);
                    } else {
                        console.error('socket connection failed, invalid data', {
                            userId: socketInfo.userId,
                            type: socketInfo.type
                        })
                    }

                } else {
                    if (response.data && response.data.code === 221) {
                        let event = new CustomEvent("maxDailyConnectionLimit", {});
                        document.dispatchEvent(event);
                    } else if (response.data && response.data.code === 217) {
                        showPopupAlert(dispatch, POP_ALERT.OK, '', BLOCKUSER_CONNECT_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
                    }
                    dispatch({ type: NewsFeedTypes.CONNECTION_ADD_FAIL, index: index });
                }
                dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
            })
            .catch((error) => {
                dispatch({ type: NewsFeedTypes.CONNECTION_ADD_FAIL, index: index });
            })
    }
}

export const fetchMoreComments = (postId, skip, limit, reset, cancelToken) => async dispatch => {
    try {
        if (localStorage.getItem('token')) {
            dispatch({ type: NewsFeedTypes.FETCH_MORE_COMMENTS });
            const response = await post(`${ROUTES.FETCH_COMMENTS}${postId}`, { skip, limit }, { cancelToken });
            if (response.data.code === 200) {
                dispatch({
                    type: NewsFeedTypes.FETCH_MORE_COMMENTS_SUCCESS,
                    payload: response.data.data.COMMENTS.reverse(),
                    id: postId,
                    reset
                })
            } else {
                dispatch({ type: NewsFeedTypes.FETCH_MORE_COMMENTS_FAIL, id: postId })
            }
        } else {
            console.error('Authorized route')
        }
    } catch (exception) {
        if (axios.isCancel(exception)) {
            return;
        } else {
            dispatch({ type: NewsFeedTypes.FETCH_MORE_COMMENTS_FAIL, id: postId })
        }
    }
}

export const updateCommentCount = (postId, newCount) => dispatch => dispatch({
    type: NewsFeedTypes.UPDATE_COMMENTS_COUNT,
    payload: { postId, newCount }
});

export const fetchTextLinksForNewsfeed = skip => async dispatch => {
    try {
        dispatch({ type: NewsFeedTypes.FETCH_TEXT_LINK });
        const response = await post(`${ROUTES.GET_TEXT_LINKS_FOR_NEWSFEED}`, { skip });
        if (response.data.code === 200) {
            dispatch({ type: NewsFeedTypes.FETCH_TEXT_LINK_SUCCESS, payload: response.data.data, init: skip === 0 })
        } else {
            dispatch({ type: NewsFeedTypes.FETCH_TEXT_LINK_FAIL })
        }
    } catch (exception) {
        dispatch({ type: NewsFeedTypes.FETCH_TEXT_LINK_FAIL })
    }
}

export const fetchTextLinksForNewsfeedCount = () => async dispatch => {
    try {
        dispatch({ type: NewsFeedTypes.FETCH_TEXT_LINK_COUNT });
        const response = await get(`${ROUTES.GET_TEXT_LINKS_FOR_NEWSFEED_COUNT}`);
        if (response.data.code === 200) {
            dispatch({ type: NewsFeedTypes.FETCH_TEXT_LINK_COUNT_SUCCESS, payload: response.data.data })
        } else {
            dispatch({ type: NewsFeedTypes.FETCH_TEXT_LINK_COUNT_FAIL })
        }
    } catch (exception) {
        dispatch({ type: NewsFeedTypes.FETCH_TEXT_LINK_COUNT_FAIL })
    }
}

export const clearNewsfeedAndCount = () => dispatch => {
    dispatch({ type: NewsFeedTypes.CLEAR_TEXT_LINK_AND_COUNT })
};

export const fetchTagList = async (searchString, cancelToken) => {
    try {
        // dispatch({ type: NewsFeedTypes.FETCH_TAG_SUGGESTION });
        const { data, config } = await post(`${ROUTES.SUGGEST_TAG}`, { searchString }, {
            cancelToken,
            headers: { 'X-Custom-Header': searchString }
        });

        if (data.code === 200 && config.headers['X-Custom-Header'] === searchString) {
            return { success: true, tagResults: data.data }
            // dispatch({ type: NewsFeedTypes.FETCH_TAG_SUGGESTION_SUCCESS, payload: data.data })
        } else {
            return { success: false, tagResults: [] }
            // dispatch({ type: NewsFeedTypes.FETCH_TAG_SUGGESTION_FAIL, payload: [] })
        }
    } catch (exception) {
        return { success: false, tagResults: [] }
    }
}

export const updateTagCount = (tagId, postId, commentId = null, cancelToken = null, groupId = null) => async dispatch => {
    try {
        // dispatch({ type: NewsFeedTypes.FETCH_TAG_SUGGESTION });
        const { data } = await get(`${ROUTES.UPDATE_TAG_EMAIL_COUNT}${tagId}`);
        /*dispatch(getSinglePost(postId, null, null, null, groupId))
        if (commentId) {
            dispatch(getSinglePost(postId, commentId, cancelToken, commentId))
        }
        commented on removing single post view
        */
        if (data.code === 200) {
            return { success: true, data: data.data }
            // dispatch({ type: NewsFeedTypes.FETCH_TAG_SUGGESTION_SUCCESS, payload: data.data })
        } else {
            return { success: false, data: [] }
            // dispatch({ type: NewsFeedTypes.FETCH_TAG_SUGGESTION_FAIL, payload: [] })
        }
    } catch (exception) {
        return { success: false, data: [] }
    }
}

export const fetchTrendingPost = (type, postId, cancelToken = null) => async dispatch => {
    try {
        dispatch({ type: NewsFeedTypes.FETCHING_TRENDING_POST });
        const response = await get(`${ROUTES.FETCH_TRENDING_POST}/${type}/${postId}`, { cancelToken });
        if (response.data.code === 200) {
            dispatch({ type: NewsFeedTypes.FETCH_TRENDING_POST_SUCCESS, payload: response.data.data });
        } else {
            dispatch({ type: NewsFeedTypes.FETCH_TRENDING_POST_FAIL });
        }
    } catch (exception) {
        dispatch({ type: NewsFeedTypes.FETCH_TRENDING_POST_FAIL });
    }
}

export function clearTrendingPost() {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.CLEAR_TRENDING_POST });
    }
}

export const setIndustryWidgetTitle = (title) => async dispatch => {
    dispatch({ type: NewsFeedTypes.SET_INDUSTRY_WIDGET_TITLE, payload: { title } });
}

export const setTrendingTopicWidgetTitle = (title) => async dispatch => {
    dispatch({ type: NewsFeedTypes.SET_TRENDING_TOPIC_WIDGET_TITLE, payload: { title } });
}

export const likeComment = (postId, commentId, userId, reaction = 1) => async dispatch => {
    dispatch({ type: NewsFeedTypes.LIKE_COMMENT });
    try {
        const response = await put(`${ROUTES.LIKE_COMMENT}${postId}/${commentId}`, { userEmotion: reaction });
        if (response && response.data && response.data.code === 200) {
            dispatch({ type: NewsFeedTypes.LIKE_COMMENT_SUCCESS, payload: { postId, commentId, userId, reaction } });
        }
    } catch (exception) {
        dispatch({ type: NewsFeedTypes.LIKE_COMMENT_FAIL });
    }
};

export const unlikeComment = (postId, commentId, userId) => async dispatch => {
    dispatch({ type: NewsFeedTypes.UNLIKE_COMMENT });
    try {
        const response = await put(`${ROUTES.UNLIKE_COMMENT}${postId}/${commentId}`, {});
        if (response && response.data && response.data.code === 200) {
            dispatch({ type: NewsFeedTypes.UNLIKE_COMMENT_SUCCESS, payload: { postId, commentId, userId } });
        }
    } catch (exception) {
        dispatch({ type: NewsFeedTypes.UNLIKE_COMMENT_FAIL });
    }
}

export const getLikesForComments = async (postId, commentId) => {
    try {
        const response = await get(`${ROUTES.COMMENT_LIKES}${postId}/${commentId}`);
        if (response && response.data && response.data.code === 200) {
            return ({ success: true, data: response.data.data });
        } else {
            return ({ success: false, data: [] });
        }
    } catch (exception) {
        return ({ success: false, data: [] });
    }
}

export const getLikesForPosts = async postId => {
    try {
        const response = await get(`${ROUTES.LIKED_USERS}/${postId}`)
        if (response && response.data && response.data.code === 200) {
            return ({ success: true, data: response.data.data });
        } else {
            return ({ success: false, data: [] });
        }
    } catch (exception) {
        return ({ success: false, data: [] });
    }
}

export const requestConnection = async (userId, text = '', socketInfo, dispatch = null) => {
    try {
        let cachedData = { cacheIndex: null, pageNo: null };
        let data = { "userId": userId, "text": text, ...cachedData };
        const response = await post(`${ROUTES.ADD_CONNECTION}`, data)

        if (response && response.data && response.data.code === 200) {
            if (socketInfo && socketInfo.socket && socketInfo.userId && socketInfo.type && response.data.data.length) {
                let connectionData = response.data.data[0];
                delete connectionData.CONVERSATION

                if (socketInfo.type === NOTIFICATION_TYPES.CONNECTION_REQUESTS) {
                    notifyReceiver(socketInfo.socket, socketInfo.userId, socketInfo.type, connectionData, null, null);
                }
            } else {
                console.error('socket connection failed, invalid data', { userId, type: socketInfo.type })
            }
            return ({ success: true });
        } else {
            if (response.data && response.data.code === 221) {
                let event = new CustomEvent("maxDailyConnectionLimit", {});
                document.dispatchEvent(event);
            } else if (response.data && dispatch && response.data.code === 217) {
                showPopupAlert(dispatch, POP_ALERT.OK, '', BLOCKUSER_CONNECT_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
            }
            return ({ success: false });
        }
    } catch (exception) {
        return ({ success: false, data: null });
    }
}

export const updateRequestedUser = (userId) => async dispatch => {
    dispatch({ type: SEND_CONNECTION_REQUEST_SUCCESS, userId })
};

export const fetchSinglePostForNewsFeed = postId => async dispatch => {
    try {
        dispatch({ type: NewsFeedTypes.FETCH_NEWSFEED_SINGLE_POST })
        const response = await post(`${ROUTES.LOAD_SINGLE_POST}/${postId}`, { skip: 0, limit: 1 })

        if (response && response.data && response.data.data && response.data.data.length > 0 && response.data.code === 200) {
            dispatch({ type: NewsFeedTypes.FETCH_NEWSFEED_SINGLE_POST_SUCCESS, payload: response.data.data[0] })
        } else {
            dispatch({ type: NewsFeedTypes.FETCH_NEWSFEED_SINGLE_POST_FAIL })
        }
    } catch (exception) {
        dispatch({ type: NewsFeedTypes.FETCH_NEWSFEED_SINGLE_POST_FAIL })
    }
};

export const fetchAllCommentsForSinglePost = (postId, commentsCount, cancelToken = null) => async dispatch => {

    try {
        const response = await post(`${ROUTES.FETCH_COMMENTS}${postId}`, {
            skip: 0,
            limit: commentsCount
        }, { cancelToken });

        if (response && response.data && response.data.code === 200) {
            dispatch({
                type: NewsFeedTypes.FETCH_NEWSFEED_SINGLE_POST_ALL_COMMENTS,
                payload: response.data.data.COMMENTS.reverse()
            })
        }

    } catch (ex) {

    }

};

export const clearSinglePostForNewsfeed = () => async dispatch => dispatch({ type: NewsFeedTypes.CLEAR_NEWSFEED_SINGLE_POST })

export const fetchUserNewsFeed = (skip, limit, reset) => async dispatch => {
    try {
        dispatch({ type: NewsFeedTypes.FETCH_USER_FEED });
        if (reset) {
            dispatch(clearNewsFeeds())
        }
        const response = await get(`${ROUTES.USER_FEED}${skip}/${limit}`)

        if (response && response.data && response.data.code === 200) {
            dispatch({ type: NewsFeedTypes.FETCH_USER_FEED_SUCCESS, payload: response.data.data, isInit: skip === 0 });
        } else {
            dispatch({ type: NewsFeedTypes.FETCH_USER_FEED_FAIL, isInit: skip === 0 });
        }

    } catch (ex) {
        dispatch({ type: NewsFeedTypes.FETCH_USER_FEED_FAIL, isInit: skip === 0 });
    }
}

export const fetchOtheUsersNewsFeed = (id, skip, limit, reset) => async dispatch => {
    try {
        dispatch({ type: NewsFeedTypes.FETCH_OTHER_USER_FEED });
        if (reset) {
            dispatch(clearNewsFeeds())
        }
        let url = localStorage.getItem('userId') ? `${ROUTES.SINGLE_USER_FEED}/${localStorage.getItem('userId')}` : `${ROUTES.SINGLE_USER_FEED}`;

        const response = await post(url, {
            skip: skip,
            limit: limit,
            userId: id
        });

        if (response && response.data && response.data.code === 200) {
            dispatch({ type: NewsFeedTypes.FETCH_OTHER_USER_FEED_SUCCESS, payload: response.data.data, isInit: skip === 0 });
        } else {
            dispatch({ type: NewsFeedTypes.FETCH_OTHER_USER_FEED_FAIL, isInit: skip === 0 });
        }

    } catch (ex) {
        dispatch({ type: NewsFeedTypes.FETCH_OTHER_USER_FEED_FAIL, isInit: skip === 0 });
    }
}

export const fetchUserNewsFeedCount = () => async dispatch => {
    try {
        dispatch({ type: NewsFeedTypes.FETCH_USER_FEED_COUNT });
        const response = await get(ROUTES.USER_FEED_COUNT)
        if (response && response.data && response.data.code === 200) {
            dispatch({ type: NewsFeedTypes.FETCH_USER_FEED_COUNT_SUCCESS, payload: response.data.data });
        } else {
            dispatch({ type: NewsFeedTypes.FETCH_USER_FEED_COUNT_FAIL });
        }

    } catch (ex) {
        dispatch({ type: NewsFeedTypes.FETCH_USER_FEED_COUNT_FAIL });
    }
}

export const fetchOtheUsersNewsFeedCount = (id) => async dispatch => {
    try {
        dispatch({ type: NewsFeedTypes.FETCH_OTHER_USER_FEED_COUNT });

        let url = localStorage.getItem('userId') ? `${ROUTES.SINGLE_USER_FEED_COUNT}/${localStorage.getItem('userId')}` : `${ROUTES.SINGLE_USER_FEED_COUNT}`;
        const response = await post(url, { userId: id});
        
        if (response && response.data && response.data.code === 200) {
            dispatch({ type: NewsFeedTypes.FETCH_OTHER_USER_FEED_COUNT_SUCCESS, payload: response.data.data });
        } else {
            dispatch({ type: NewsFeedTypes.FETCH_OTHER_USER_FEED_COUNT_FAIL });
        }

    } catch (ex) {
        dispatch({ type: NewsFeedTypes.FETCH_OTHER_USER_FEED_COUNT_FAIL });
    }
}

export const refreshNewsFeed = () => async dispatch => {
    await dispatch({ type: NewsFeedTypes.FORCE_REFRESH });
    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
};

export const createOpenNewsfeedTracking = async (openNewsfeedToken) => {
    try {
        const response = await post(`${ROUTES.CREATE_TRACKING_OPEN_NEWSFEED}`, { CUSTOM_TOKEN: openNewsfeedToken });

        if (response && response.data && response.data.code === 200) {
            return ({ success: true, data: response.data.data });
        } else {
            return ({ success: false, data: [] });
        }
    } catch (exception) {
        return ({ success: false, data: [] });
    }
}

export const trackPostView = async (data) => {
    try {
        if (data.postId && data.platform && data.openNewsfeedToken) {
            if (data.platform === 'undefined') data.platform = 0;
            if (data.openNewsfeedToken === 'undefined') data.openNewsfeedToken = 0;
            await post(`${ROUTES.TRACK_POST_SHARE}`, data);
        }
    } catch (ex) {
        console.error(ex)
    }
};

export const growYourNetworkConnectionsConnectClickTracking = async (VIEW_ID) => {
    try {
        await post(`${ROUTES.GYN_CONNECT_CLICK_TRACKING}`, { VIEW_ID });
    } catch (exception) {
        console.error(exception)
    }
};

export const clearNewsfeedSinglePost = () => async dispatch => {
    await dispatch({ type: NewsFeedTypes.CLEAR_NEWSFEED_SINGLE_POST_BY_USER });
    dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
};

export const adminRepost = (postId) => async dispatch => {
    try {
        const response = await post(`${ROUTES.NEWSFEED_REPOST}`, { postId });

        if (response && response.data && response.data.code === 200) {
            dispatch({ type: NewsFeedTypes.ADD_POST_SUCCESS });
            dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
        } else {
            showAlert(dispatch, ALERT.ERROR, response.data.message);
        }
    } catch (exception) {
        showAlert(dispatch, ALERT.ERROR, exception);
    }
};

export const videoProcessCompleted = (data) => async dispatch => {
    try {
        const response1 = await get(`${ROUTES.LOAD_SINGLE_POST_V_1}/${data._id}`)
        await dispatch({ type: NewsFeedTypes.CLEAR_PENDING_POST, publishedPostes: [data._id] });
        await dispatch({ type: NewsFeedTypes.SET_RECENT_POST, recentPost: response1.data.data });
    } catch (ex) {
        console.error(ex)
    }
}

export const showVideoPost = (postId) => async dispatch => {
    await dispatch({ type: NewsFeedTypes.PROCESS_COMPLETE_NEWSFEED_VIDEO, postId });
    dispatch({ type: NewsFeedTypes.CLEAR_PROCESS_COMPLETE_NEWSFEED_VIDEO });
};


export function blockPost(postId) {
    return async function (dispatch) {
        try {
            const res = await post(`${ROUTES.BLOCK_POST}${postId}`)
            if (res && res.data && res.data.code === 200) {
                dispatch({ type: NewsFeedTypes.BLOCK_POST_SUCCESS, payload: { postId } });
                showPopupAlert(dispatch, POP_ALERT.SUCCESS, BLOCKPOST_SUCCESS);
            } else {
                showPopupAlert(dispatch, POP_ALERT.ERROR, BLOCKPOST_FAIL);
            }
        } catch (ex) {
            console.error(ex);
            showPopupAlert(dispatch, POP_ALERT.ERROR, BLOCKPOST_FAIL);
        }
    }
}