module.exports = {
    POST: {
        SEE_M: 'SEE MORE',
        COMMENT: 'COMMENT',
        SHARE: 'SHARE',
        REPORT: 'REPORT',
        GUILD: 'GUIDELINES',
        EDIT: 'EDIT',
        DELETE: 'DELETE',
        COPY: 'COPY LINK',
        REPOST: 'REPOST',
        CONNECT: 'SEND REQUEST',
        ACCEPT: 'ACCEPT REQUEST',
        VIEW_PROF: 'VIEW PROFILE',
        REJECT: 'REJECT REQUEST',
        CLOSE: 'CLOSE ICON',
        OG: 'OG LINK',
        MSG: 'MESSAGE'
    },
    FEED: {
        PROFILE_PO_CONNECT: 'CONNECT',
        PROFILE_PO_ACCEPT: 'ACCEPT',
        PROFILE_PO_REJECT: 'REJECT',
        PROFILE_PO_MSG: 'MESSAGE',
        MP_AD_CONNECT: 'CONNECT',
        MP_AD_SEE_M: 'SEE MORE',
        NETWORK_PLUS: 'PLUS ICON',
        NETWORK_GROW_Y_N: 'GROW YOUR NETWORK TEXT',
        NETWORK_FIND_M: 'FIND MORE CONNECTIONS',
        QUICK_E_FB: 'FACEBOOK',
        QUICK_E_TWITTER: 'TWITTER',
        QUICK_E_INSTA: 'INSTAGRAM',
        QUICK_E_LINKEDIN: 'LINKEDIN',
        QUICK_E_SAVE: 'SAVE',
        QUICK_E_PROFESSIONAL_EXP: 'ADD NEW PROFESSIONAL EXPERIENCE',
        QUICK_E_JOB_HISTORY: 'EDIT JOB HISTORY',
    }, 
    EVENT: {
        CREATE_EV_PREV: 'PREVIEW',
        CREATE_EV_POST: 'POST',
        CREATE_EV_DRAFT: 'SAVE DRAFT',
        CREATE_EV_FEATURED: 'EVENT LISTING TYPE FEATURED',
        CREATE_EV_BASIC: 'EVENT LISTING TYPE BASIC',
        ATTENDEES_SEE: 'SEE ATTENDEES',
        ATTENDEES_CLOSE: 'CLOSE ICON',
        ATTENDEES_CONNECT: 'CONNECT',
        ATTENDEES_ACCEPT: 'ACCEPT',
        ATTENDEES_REJECT: 'REJECT',
        ATTENDEES_MSG: 'MESSAGE',
        ATTENDEES_VIEW_PROF: 'VIEW PROFILE',
        ALL_EV: 'ALL EVENTS',
        DIS_EV: 'EVENT DISCOUNTS',
        GLOBAL_EV: 'GLOBAL EVENTS',
        WEBINARS: 'VIRTUAL EVENTS/WEBINARS',
        MANAGE_EV: 'MANAGE EVENTS',
        CREATE_EV: 'CREATE EVENT'
        
    },
    PROF_CARD: {
        PC_CONNECT: 'CONNECT',
        PC_ACCEPT: 'ACCEPT',
        PC_REJECT: 'REJECT',
        PC_VIEW_PROF: 'VIEW PROFILE',
        PC_MSG: 'MESSAGE'
    },
    MUTUAL: {
        MUTUAL_CON: 'MUTUAL CONNECTIONS',
        MUTUAL_CON_VIEW_PROF: 'VIEW PROFILE',
        MUTUAL_CON_MSG: 'MESSAGE',
        MUTUAL_CON_CLOSE: 'CLOSE ICON',
    },
    COMMON: {
        UPLOAD_IMG: 'UPLOAD IMAGE',
        LOAD_M: 'LOAD MORE',
        SHOW_ALL_TAGS: 'SHOW ALL TAGS',
        SEE_M: 'SEE MORE',
        SHARE: 'SHARE',
        SEND: 'SEND',
        GUILD: 'GUIDELINES',
        EDIT: 'EDIT',
        DELETE: 'DELETE',
        CONNECT: 'CONNECT',
        ACCEPT: 'ACCEPT',
        REJECT: 'REJECT',
        CANCEL: 'CANCEL',
        MSG: 'MESSAGE',
        CLOSE: 'CLOSE ICON',
        VIEW_PROF: 'VIEW PROFILE',
        SEE_PREV_COMMENT: 'SEE PREVIOUS COMMENTS',
        SEARCH: 'SEARCH',
        CLEAR_FILTER: 'CLEAR FILTER',
        REPORT_CONV: 'REPORT CONVERSATION',
        DELETE_CONV: 'DELETE CONVERSATION',
        ELLIPSIS: 'ELLIPSIS ICON',
        INFO: 'SECTION INFO',
        SELECT_COM: 'SELECT COMPANY',
        SEE_M_PE: 'SHOW MORE PROFESSIONAL EXPERIENCE',
        WORKPLACE: 'WORKPLACE'
    },
    TOP_NAV: {
        MP: 'MARKETPLACE',
        NEW_LIST: 'CREATE A NEW LISTING',
        EDIT_LIST: 'EDIT AN EXISTING LISTING',
        REPORTING: 'REPORTING DASHBOARD',
        POST_JOB: 'POST A JOB',
        MANAGE_SUB: 'MANAGE SUBSCRIPTIONS',
        CONNECTIONS: 'CONNECTIONS',
        FIND_COMPANY: 'FIND FUNDRAISING COMPANIES',
        CREATE_COMPANY: 'CREATE NEW COMPANY',
        EDIT_PROF: 'EDIT PROFILE',
        SELECT_COM_CLOSE: 'SELECT COMPANY CLOSE',
        SELECT_COM: 'SELECT COMPANY',
    },
    CONNECTION: {
        M_CON: 'MY CONNECTIONS',
        PENDING_CON: 'PENDING CONNECTIONS',
        REC_CON: 'RECOMMENDED CONNECTIONS',
        NEW_CON: 'FIND NEW CONNECTIONS'
    },
    FOOTER: {
        HOME: 'HOME',
        FAQ: 'FAQ',
        CUSTOMER_CARE: 'CUSTOMER CARE',
        ADVERTISING: 'ADVERTISING',
        ABOUT_US: 'ABOUT US',
        PRIVACY_POLICY: 'PRIVACY POLICY',
        TERMS: 'TERMS OF USE',
        PRESS: 'PRESS',
        QnA: 'Q&A',
        TRAINING: 'TRAINING'
    },
    GP: {                   // Green Pages
        GREEN_BTN: 'HOW DO I FEATURE MY COMPANY ON GREEN PAGES?',
        LIST_IMG: 'LISTING_IMAGE',
        LEARN_M: 'LEARN MORE',
        FB: 'FACEBOOK',
        TWITTER: 'TWITTER',
        INSTA: 'INSTAGRAM',
        LINKEDIN: 'LINKEDIN',
        GLOBE: 'GLOBE',
        EMAIL: 'EMAIL',
        CALL: 'CALL',
        MAP: 'MAP',
        MORE_INFO: 'REQUEST MORE INFORMATION',
        CLAIM: 'CLAIM THIS LISTING',
        INFO_POPUP: 'INFORMATION REQUEST POPUP',
        SUBMIT: 'SUBMIT',
        CONFIRM: 'CONFIRMATION MESSAGE'
    }

};