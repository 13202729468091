import { ALERT, POP_ALERT, NOTIFICATION } from '../types/common';

export const initialState = {
    show: false,
    text: '',
    type: 0,
    duration: 2000,
    doNotHide: false,
    popAlertText: '',
    popAlertTitle: '',
    popAlertType: POP_ALERT.HIDE,
    popAlertEvent: '',
    actionEvent: '',
    data: '',
    description:'',
    notifications: [],
    customClass: ''
};

export const alert = (state = initialState, action) => {
    switch (action.type) {
        case ALERT.SHOW:
            return {
                ...state,
                show: true,
                text: action.payload.text,
                type: action.payload.type,
                duration: action.payload.duration,
                doNotHide: action.payload.doNotHide
            };
        case ALERT.HIDE:
            return {
                ...state,
                show: false,
                text: initialState.text,
                type: initialState.type
            };
        case POP_ALERT.SHOW:
            return {
                ...state,
                popAlertText: action.text,
                popAlertTitle: action.title,
                popAlertType: action.alertType,
                description:action.description,
                customClass: action.customClass
                // popAlertEvent: action.popAlertEvent,
            };
        case POP_ALERT.HIDE:
            return {
                ...state,
                popAlertText: '',
                popAlertTitle: '',
                popAlertType: POP_ALERT.HIDE,
                popAlertEvent: '',
                actionEvent: '',
                data: ''
            };
        case POP_ALERT.EVENT:
            return {
                ...state,
                actionEvent: action.actionEvent, // either user clicks on OK or CANCEL
                popAlertEvent: action.popAlertEvent, // exact performing action
                data: action.data,
            };
        case NOTIFICATION.PUSH:
            return {
                ...state,
                notifications: [...state.notifications].concat([action.payload])
            };
        case NOTIFICATION.PULL:
            return {
                ...state,
                notifications: state.notifications.filter(item => { return item.notificationId.toString() !== action.payload.toString() })
            };
        case NOTIFICATION.FILTER:
            return {
                ...state,
                notifications: state.notifications.filter(item => { return !(item.readData && item.readData.conversationId && item.readData.conversationId === action.payload)})
            };
        case NOTIFICATION.HIDE:
            return {
                ...state,
                notifications: []
            };
        default:
            return state
    }
}