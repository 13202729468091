import axios from 'axios';
import CompanyTypes from '../types/company';
import { ALERT, POP_ALERT } from '../types/common';
import { showAlert, showPopupAlert } from './alert';
import { ROUTES, SIGNED_COOKIES_CF_URL } from '../config';
import { initlogin, addEmployeeHistory } from "./auth";
import * as AuthTypes from '../types/authentication';
import { isURL } from '../libs/validation';
import { errorHandler } from '../libs/commonFunctions';
import {get, post, put, del} from "../libs/apiConnector";
import { fetchSinglePostForNewsFeed, fetchTrendingPost } from './../actions/newsFeed';
import { getCFObjURL, BUCKET } from '../libs/cfHelper';

export function addFundraisingRound(info, id, type) {
    let data = {
        "amountRaise": info.amountRaise,
        "companyStage": info.companyStage,
        "minInvestment": info.minInvestment,
        "estimatedValuation": info.estimatedValuation,
        "productStage": info.productStage,
        "plantTouching": info.plantTouching,
        "isFullTimeJob": info.fullTimeJob,
        "isLicensed": info.licensed,
        "founded": info.companyFounded,
        "investmentType": info.investmentType,
        "companySize": info.companySize,
        "raisedSoFar": info.raisedSoFar,
        "companyType": info.companyType,
        "checked": info.confirmed

    };

    return function (dispatch) {
        dispatch({ type: CompanyTypes.INSERTING_FUND_RAISING_ROUND });
        put(`${ROUTES.ADD_FUNDRAISING_ROUND}${id}`, data)
            .then(response => {
                if (response.data.code === 200) {
                    if (type === 'update') {
                        dispatch({ type: CompanyTypes.FUND_RAISING_ROUND_SUCCESS, payload: response.data });
                    } else {
                        dispatch({ type: CompanyTypes.FUND_RAISING_ROUND_CLEAR_SUCCESS, payload: response.data });
                    }
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                } else {
                    if (type === 'update') {
                        dispatch({ type: CompanyTypes.FUND_RAISING_ROUND_FAIL });
                    } else {
                        dispatch({ type: CompanyTypes.FUND_RAISING_ROUND_CLEAR_FAIL });
                    }
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                if (type === 'update') {
                    dispatch({ type: CompanyTypes.FUND_RAISING_ROUND_FAIL });
                } else {
                    dispatch({ type: CompanyTypes.FUND_RAISING_ROUND_CLEAR_FAIL });
                }
                dispatch({ type: CompanyTypes.CLEAR_ACTION });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            })
    }

}

export function getCompanyDetails(id, cancelToken) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.FETCHING_COMPANY_DATA });
        get(`${ROUTES.GET_COMPANY_DETAILS}${id}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.FETCHING_COMPANY_DATA_SUCCESS, payload: response.data });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                } else if (response.data.code === 212) {
                    dispatch({ type: CompanyTypes.FETCHING_COMPANY_DATA_NOT_FOUND });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_COMPANY_DATA_FAIL });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                }
            })
    }
}

export function clearCompanyDetails() {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.CLEAR_COMPANY_DATA });
    }
}

export function getCompanyDetailsNew(id, cancelToken) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.FETCHING_COMPANY_DATA });
        get(`${ROUTES.GET_COMPANY_DETAILS_NEW}${id}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.FETCHING_COMPANY_DATA_SUCCESS, payload: response.data });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                } else if (response.data.code === 203) {
                    dispatch({ type: CompanyTypes.FETCHING_COMPANY_DATA_FAIL });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                    showAlert(dispatch, ALERT.ERROR, response.data.message);                                        
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_COMPANY_DATA_NOT_FOUND });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_COMPANY_DATA_FAIL });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                }
            })
    }
}

export function getInvestors(id, cancelToken) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.FETCHING_INVESTORS });
        put(`${ROUTES.GET_NON_INVESTED_COMPANIES}/${id}`, { searchBy: '' }, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.FETCHING_INVESTORS_SUCCESS, payload: response.data });
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_INVESTORS_FAIL });
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_INVESTORS_FAIL });
                }
            })
    }
}

export function getIndividualInvestors(id, cancelToken) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.FETCHING_INDIVIDUAL_INVESTORS });
        put(`${ROUTES.GET_NON_INVESTED_INDIVIDUALS}/${id}`, { searchBy: '' }, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.FETCHING_INDIVIDUAL_INVESTORS_SUCCESS, payload: response.data });
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_INDIVIDUAL_INVESTORS_FAIL });
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_INDIVIDUAL_INVESTORS_FAIL });
                }
            })
    }
}

export function getCurrentInvestors(cid, cancelToken) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.FETCHING_CURRENT_INVESTORS });
        get(`${ROUTES.GET_CURRENT_INVESTORS}${cid}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.FETCHING_CURRENT_INVESTORS_SUCCESS, payload: response.data.data })
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_CURRENT_INVESTORS_FAIL });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                }

            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_CURRENT_INVESTORS_FAIL });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                }
            })
    }
}

export function addInvestor(cid, iid) {
    let data = {
        "investorId": iid
    };
    return function (dispatch) {
        dispatch({ type: CompanyTypes.ADDING_INVESTOR });
        put(`${ROUTES.ADD_INVESTOR}${cid}`, data)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.ADDING_INVESTOR_SUCCESS });
                    dispatch(getCurrentInvestors(cid));
                    dispatch(getIndividualInvestors(cid));
                } else {
                    dispatch({ type: CompanyTypes.ADDING_INVESTOR_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({ type: CompanyTypes.ADDING_INVESTOR_FAIL });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            })
    }
}

export function removeInvestor(cid, iid) {
    let data = {
        "investorId": iid
    };
    return function (dispatch) {
        dispatch({ type: CompanyTypes.DELETING_INVESTOR });
        put(`${ROUTES.REMOVE_INVESTOR}${cid}`, data)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.DELETING_INVESTOR_SUCCESS });
                    dispatch(getCurrentInvestors(cid));
                    dispatch(getIndividualInvestors(cid));
                } else {
                    dispatch({ type: CompanyTypes.DELETING_INVESTOR_SUCCESS });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }

            })
            .catch((error) => {
                dispatch({ type: CompanyTypes.DELETING_INVESTOR_FAIL });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            })
    }
}

export function updateCompanyOverview(cid, overview) {
    let data = {
        "overview": overview
    };
    return function (dispatch) {
        dispatch({ type: CompanyTypes.UPDATING_OVERVIEW });
        put(`${ROUTES.UPDATE_OVERVIEW}${cid}`, data)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.UPDATING_OVERVIEW_SUCCESS, payload: overview });
                    showPopupAlert(dispatch, POP_ALERT.SUCCESS, response.data.message);
                } else {
                    dispatch({ type: CompanyTypes.UPDATING_OVERVIEW_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({ type: CompanyTypes.UPDATING_OVERVIEW_FAIL });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            })
    }
}

export function addCompanyEmployee(cid, data) {
    let info = {
        "_id": data._id,
        "name": data.name,
        "jobTitle": data.job,
    };
    return function (dispatch) {
        dispatch({ type: CompanyTypes.ADDING_EMPLOYEE });
        put(`${ROUTES.ADD_EMPLOYEE}${cid}`, info)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.ADDING_EMPLOYEE_SUCCESS, payload: response.data.data.status === 1 });
                    dispatch(getRegularEmployees(cid));
                    dispatch(getNonAdvisers(cid));
                    dispatch(getCompanyEmployees(cid));
                    dispatch(getAllEmployees(cid));
                } else {
                    dispatch({ type: CompanyTypes.ADDING_EMPLOYEE_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({ type: CompanyTypes.ADDING_EMPLOYEE_FAIL });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            })
    }
}

export function editCompanyEmployee(cid, data) {
    let info = {
        "userId": data.id,
        "designation": data.jobTitle
    }
    return function (dispatch) {
        dispatch({ type: CompanyTypes.ADDING_EMPLOYEE });
        put(`${ROUTES.EDIT_EMPLOYEE}${cid}`, info)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.ADDING_EMPLOYEE_SUCCESS, payload: response.data.data.status === 0 });
                    dispatch(getCompanyEmployees(cid));
                    showAlert(dispatch, ALERT.SUCCESS, response.data.message);
                } else {
                    dispatch({ type: CompanyTypes.ADDING_EMPLOYEE_FAIL });
                    showAlert(dispatch, ALERT.ERROR, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({ type: CompanyTypes.ADDING_EMPLOYEE_FAIL });
                errorHandler(dispatch, error);
            })
    }
}

export function removeCompanyEmployee(cid, eid) {
    let info = {
        "userId": eid
    };
    return function (dispatch) {
        dispatch({ type: CompanyTypes.DELETING_EMPLOYEE });
        put(`${ROUTES.REMOVE_EMPLOYEE}${cid}`, info)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.DELETING_EMPLOYEE_SUCCESS });
                    dispatch(getCompanyEmployees(cid));
                    dispatch(getAllEmployees(cid));
                    dispatch(getNonAdvisers(cid));
                    dispatch(getRegularEmployees(cid));
                } else {
                    dispatch({ type: CompanyTypes.DELETING_EMPLOYEE_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({ type: CompanyTypes.DELETING_EMPLOYEE_FAIL });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            })
    }
}

export function getCompanyEmployees(cid, cancelToken) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.FETCHING_CURRENT_EMPLOYEES });
        get(`${ROUTES.GET_EMPLOYEES}${cid}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.FETCHING_CURRENT_EMPLOYEES_SUCCESS, payload: response.data });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_CURRENT_EMPLOYEES_FAIL });
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_CURRENT_EMPLOYEES_FAIL });
                }
            })
    }
}

export function getAllEmployees(cid, cancelToken) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.FETCHING_EMPLOYEES });
        put(`${ROUTES.GET_ALL_EMPLOYEES}${cid}`, { "searchBy": '' }, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.FETCHING_EMPLOYEES_SUCCESS, payload: response.data });
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_EMPLOYEES_FAIL });
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_EMPLOYEES_FAIL });
                }
            })
    }
}

export function getAllProduct(cid, cancelToken) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.FETCHING_CURRENT_PRODUCTS });
        get(`${ROUTES.GET_PRODUCTS}${cid}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    let imageArray = [];
                    let count = 0, completed = 0;
                    if (response.data.data.data.PRODUCT_SHOWCASE.length > 0) {
                        response.data.data.data.PRODUCT_SHOWCASE.forEach((item, i) => {

                            if (item && item.PATH) {
                                count++;
                                const url = getCFObjURL(BUCKET.COMMON)(('ex-mediam-' + item.PATH), true);
                                if (url) {
                                    item.FILE_NAME = item.PATH;
                                    item.PATH = isURL(url, { protocols: ['https'] }, SIGNED_COOKIES_CF_URL) ? url : item.PATH;
                                    imageArray.push(item);
                                    completed++;
                                    if (response.data.data.data.PRODUCT_SHOWCASE.length) {
                                        if (completed === count) {
                                            dispatch({ type: CompanyTypes.FETCHING_CURRENT_PRODUCTS_SUCCESS, payload: imageArray, cid: cid });
                                        }
                                    }
                                }
                            }
                            if (count === 0) {
                                dispatch({ type: CompanyTypes.FETCHING_CURRENT_PRODUCTS_SUCCESS, payload: [], cid: cid });
                            }

                        });
                    } else {
                        dispatch({ type: CompanyTypes.FETCHING_CURRENT_PRODUCTS_SUCCESS, payload: [], cid: cid })
                    }
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_CURRENT_PRODUCTS_FAIL });
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_CURRENT_PRODUCTS_FAIL });
                }
            })
    }
}

export function getSingleProduct(item) {
    return function (dispatch) {
        const url = getCFObjURL(BUCKET.COMMON)(('ex-mediam-' + item.PATH), true);
        if (url) {
            item.FILE_NAME = item.PATH;
            item.PATH = isURL(url, { protocols: ['https'] }, SIGNED_COOKIES_CF_URL) ? url : item.PATH;
            dispatch({ type: CompanyTypes.FETCHING_SINGLE_PRODUCTS_SUCCESS, payload: item });
            dispatch({ type: CompanyTypes.CLEAR_ACTION });
        } else {
            dispatch({ type: CompanyTypes.FETCHING_CURRENT_PRODUCTS_FAIL });
        }
    }
}

export function addProduct(cid, type, imageKey) {
    let info = {
        "product": {
            "TYPE": 2,
            "PATH": imageKey,
            "DESCRIPTION": ""
        }
    };
    return function (dispatch) {

        dispatch({ type: CompanyTypes.INSERTING_PRODUCT_IMAGE });
        if (info !== null) {
            put(`${ROUTES.ADD_PRODUCT}${cid}`, info)
                .then(response => {
                    if (response.data.code === 200) {
                        dispatch({ type: CompanyTypes.INSERTING_PRODUCT_IMAGE_SUCCESS, payload: response.data.data.PATH });
                        dispatch(getSingleProduct(response.data.data));
                    } else {
                        dispatch({ type: CompanyTypes.INSERTING_PRODUCT_IMAGE_FAIL });
                        showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                    }
                })
                .catch((error) => {
                    dispatch({ type: CompanyTypes.INSERTING_PRODUCT_IMAGE_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
                })
        } else {
            dispatch({ type: CompanyTypes.INSERTING_PRODUCT_IMAGE_FAIL });
            showPopupAlert(dispatch, POP_ALERT.WARNING, `Error! Please try again`);
        }
    }
}

export function updateProduct(cid, data) {
    let odata = [];
    data.forEach((item, i) => {
        odata.push({
            _id: item._id,
            DESCRIPTION: item.DESCRIPTION,
            PATH: item.FILE_NAME,
            TYPE: item.TYPE
        });
    });

    return function (dispatch) {
        put(`${ROUTES.UPDATE_PRODUCT}${cid}`, { "productShowcase": odata })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.UPDATING_PRODUCT_IMAGE_SUCCESS, payload: data });
                } else {
                    dispatch({ type: CompanyTypes.UPDATING_PRODUCT_IMAGE_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({ type: CompanyTypes.UPDATING_PRODUCT_IMAGE_FAIL });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            });
    }
}

export function deleteProduct(cid, pid, fileName) {
    let info = {
        "productId": pid,
        "fileName": fileName
    };
    return function (dispatch) {
        dispatch({ type: CompanyTypes.DELETING_PRODUCT_IMAGE });
        put(`${ROUTES.REMOVE_PRODUCT}${cid}`, info)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.DELETING_PRODUCT_IMAGE_SUCCESS, payload: pid });
                    //dispatch(getAllProduct(cid));
                } else {
                    dispatch({ type: CompanyTypes.DELETING_PRODUCT_IMAGE_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({ type: CompanyTypes.DELETING_PRODUCT_IMAGE_FAIL });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            })
    }
}

export function updatePersonalOverview(uid, overview) {
    let data = {
        "overview": overview
    };
    return function (dispatch) {
        dispatch({ type: AuthTypes.UPDATING_PERSONAL_OVERVIEW });
        put(`${ROUTES.UPDATE_PERSONAL_OVERVIEW}${uid}`, data)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: AuthTypes.UPDATING_PERSONAL_OVERVIEW_SUCCESS, payload: overview });
                    dispatch({ type: AuthTypes.UPDATE_USER_PERSONAL_SUMMARY_SUCCESS });
                    dispatch({ type: AuthTypes.CLEAR_ACTION });
                    showPopupAlert(dispatch, POP_ALERT.SUCCESS, response.data.message);
                } else {
                    dispatch({ type: AuthTypes.UPDATING_PERSONAL_OVERVIEW_FAIL });
                    dispatch({ type: AuthTypes.UPDATE_USER_PERSONAL_SUMMARY_FAIL });
                    dispatch({ type: AuthTypes.CLEAR_ACTION });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({ type: AuthTypes.UPDATING_PERSONAL_OVERVIEW_FAIL });
                dispatch({ type: AuthTypes.UPDATE_USER_PERSONAL_SUMMARY_FAIL });
                dispatch({ type: AuthTypes.CLEAR_ACTION });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            })
    }
}

export function getAllCompanies(uid) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.FETCHING_COMPANIES });
        put(`${ROUTES.GET_ALL_COMPANIES}${uid}`, { "searchBy": "" })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.FETCHING_COMPANIES_SUCCESS, payload: response.data });
                } else if (response.data.code === 222) {
                    dispatch({ type: AuthTypes.ACTIVATION_REQUIRED, payload: response.data })
                    // showAlert(dispatch, ALERT.ERROR, response.data.message);
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_COMPANIES_FAIL });
                }
            })
            .catch((error) => {
                dispatch({ type: CompanyTypes.FETCHING_COMPANIES_FAIL });
            })
    }
}

export function getCompanies(uid, cancelToken) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.FETCHING_COMPANIES });
        put(`${ROUTES.GET_COMPANIES}`, { "searchBy": "" }, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.FETCHING_COMPANIES_SUCCESS, payload: response.data });
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_COMPANIES_FAIL });
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_COMPANIES_FAIL });
                }
            })
    }
}

export const getCompaniesByName = async (search = '') => {
    try {
        const res = await put(ROUTES.GET_COMPANIES_BY_NAME, { searchBy: search });
        if (res && res.data && res.data.code === 200) {
            return {success: true, data: res.data.data}
        } else {
            return {success: false, data: []}
        }
    } catch (ex) {
        return {success: false, data: []}
    }
};

export function updatingCompanyUser() {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.UPDATING_COMPANY_USER });
    }
}

export function updateCompanyUser(uid, data, isImageChanged = false) {

    return function (dispatch) {
        dispatch(updatingCompanyUser());
        put(`${ROUTES.UPDATE_ABOUT}${uid}`, data)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: AuthTypes.CLEAR_PROFILE_IMAGE });
                    dispatch({ type: AuthTypes.CLEAR_PROFILE_THUMBNAIL_IMAGE });
                    localStorage.removeItem('profilePicture');
                    localStorage.removeItem('profileThumbnail');
                    localStorage.removeItem('publicProfilePicture');
                    
                    if (response.data.data.TOKEN) {
                        localStorage.setItem('token', response.data.data.TOKEN);
                    }
                    /*if(isImageChanged){
                        dispatch(getProfileImage(data.PHOTO))
                    }*/
                    dispatch({ type: CompanyTypes.UPDATING_COMPANY_USER_SUCCESS });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });

                    dispatch(initlogin(localStorage.getItem('token'), false, response.data.message));
                } else {
                    dispatch({ type: CompanyTypes.UPDATING_COMPANY_USER_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            }).catch((error) => {
                dispatch({ type: CompanyTypes.UPDATING_COMPANY_USER_FAIL });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            })
    }
}

export function addPublicVideo(cid, link) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.ADDING_PUBLIC_VIDEO });
        put(`${ROUTES.ADD_VIDEO}${cid}`, { "video": link })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.ADDING_PUBLIC_VIDEO_SUCCESS, payload: link });
                    dispatch(getCompanyDetailsNew(cid));
                    showPopupAlert(dispatch, POP_ALERT.SUCCESS, response.data.message);
                } else {
                    dispatch({ type: CompanyTypes.ADDING_PUBLIC_VIDEO_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({ type: CompanyTypes.ADDING_PUBLIC_VIDEO_FAIL });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            })
    }
}

export function updateCompanyName(uid, data) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.UPDATING_COMPANY_NAME });
        put(`${ROUTES.UPDATE_ABOUT_COMPANY}${uid}`, data)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.UPDATING_COMPANY_NAME_SUCCESS });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                    dispatch(initlogin(localStorage.getItem('token'), false, response.data.message));
                } else {
                    dispatch({ type: CompanyTypes.UPDATING_COMPANY_NAME_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({ type: CompanyTypes.UPDATING_COMPANY_NAME_FAIL });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message)
            })
    }
}

export function getRegularEmployees(companyId, cancelToken) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.FETCHING_REGULAR_EMPLOYEES });
        get(`${ROUTES.GET_REGULAR_EMPLOYEES}${companyId}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.FETCHING_REGULAR_EMPLOYEES_SUCCESS, payload: response.data });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_REGULAR_EMPLOYEES_FAIL });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                }

            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_REGULAR_EMPLOYEES_FAIL });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                }
            })
    }
}

export function getNonAdvisers(companyId, cancelToken) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.FETCHING_NON_ADVISORS });
        get(`${ROUTES.GET_NON_ADVISORS}${companyId}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.FETCHING_NON_ADVISORS_SUCCESS, payload: response.data });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_NON_ADVISORS_FAIL });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                }

            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_NON_ADVISORS_FAIL });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                }
            })
    }
}

export function addAdvisor(cid, uid) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.ADD_ADVISER });
        put(`${ROUTES.ADD_ADVISER}${cid}`, { "userId": uid })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.ADD_ADVISER_SUCCESS, payload: response.data.data });
                    dispatch(getAllEmployees(cid));
                    dispatch(getNonAdvisers(cid));
                    dispatch(getAdvisors(cid));
                    dispatch(getCompanyEmployees(cid));
                } else {
                    dispatch({ type: CompanyTypes.ADD_ADVISER_FAIL })
                }
            })
            .catch((err) => {
                dispatch({ type: CompanyTypes.ADD_ADVISER_FAIL })
                showPopupAlert(dispatch, POP_ALERT.WARNING, err.message)
            });
    }
}

export function removeAdvisor(cid, uid) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.REMOVE_ADVISER });
        put(`${ROUTES.REMOVE_ADVISERS}${cid}`, { "userId": uid })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.REMOVE_ADVISER_SUCCESS });
                    dispatch(getNonAdvisers(cid));
                    dispatch(getAdvisors(cid));
                } else {
                    dispatch({ type: CompanyTypes.REMOVE_ADVISER_FAIL })
                }
            })
            .catch((err) => {
                dispatch({ type: CompanyTypes.REMOVE_ADVISER_FAIL })
                showPopupAlert(dispatch, POP_ALERT.WARNING, err.message)
            });

    }
}

export function resendAdvisorRequest(cid, uid) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.RESEND_ADVISER_REQUEST_SUCCESS });
        put(`${ROUTES.RESEND_ADVISER_REQUEST}${cid}`, { "userId": uid })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.RESEND_ADVISER_REQUEST_SUCCESS });
                    showPopupAlert(dispatch, POP_ALERT.SUCCESS, response.data.message);
                } else {
                    dispatch({ type: CompanyTypes.RESEND_ADVISER_REQUEST_FAIL })
                }
            })
            .catch((err) => {
                dispatch({ type: CompanyTypes.RESEND_ADVISER_REQUEST_FAIL })
            });

    }
}

export function acceptAdvisorRequest(cid, requestToken) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.ACCEPT_ADVISER_REQUEST_SUCCESS });
        put(`${ROUTES.ACCEPT_ADVISER_REQUEST}${cid}`, { "requestToken": requestToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.ACCEPT_ADVISER_REQUEST_SUCCESS });
                    showPopupAlert(dispatch, POP_ALERT.SUCCESS, response.data.message);
                } else {
                    dispatch({ type: CompanyTypes.ACCEPT_ADVISER_REQUEST_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.ERROR, response.data.message);
                }
            })
            .catch((err) => {
                dispatch({ type: CompanyTypes.ACCEPT_ADVISER_REQUEST_FAIL })
            });
    }
}

export function rejectAdvisorRequest(cid, requestToken) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.REJECT_ADVISER_REQUEST_SUCCESS });
        put(`${ROUTES.REJECT_ADVISER_REQUEST}${cid}`, { "requestToken": requestToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.REJECT_ADVISER_REQUEST_SUCCESS });
                    showPopupAlert(dispatch, POP_ALERT.SUCCESS, response.data.message);
                } else {
                    dispatch({ type: CompanyTypes.REJECT_ADVISER_REQUEST_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.ERROR, response.data.message);
                }
            })
            .catch((err) => {
                dispatch({ type: CompanyTypes.REJECT_ADVISER_REQUEST_FAIL })
            });
    }
}

export function getAdvisors(companyId, cancelToken) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.GET_ADVISER });
        get(`${ROUTES.GET_ADVISERS}${companyId}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.GET_ADVISER_SUCCESS, payload: response.data.data })
                } else {
                    dispatch({ type: CompanyTypes.GET_ADVISER_FAIL })
                }
            })
            .catch((err) => {
                if (axios.isCancel(err)) {
                    return;
                } else {
                    dispatch({ type: CompanyTypes.GET_ADVISER_FAIL })
                }
            });
    }
}

export function getKeyEmployeePublicProfileImagesList(list, key, action, cancelToken) {
    /*
    * @params:list - data list
    * @params:key - field contains image name
    * @params:action - action to dispatch after getting urls
    *
    * this function accepts a data list, replaces image names with image links and dispatches a given action with modified list as payload
    */
    return function (dispatch) {
        let count = 0, completed = 0;
        list.forEach((item, i) => {
            if (item[key]) {
                count++;
                const url = getCFObjURL(BUCKET.COMMON)(('ex-mediam-' + item[key]), true);
                if (url) {
                    list[i][key] = isURL(url, { protocols: ['https'] }, SIGNED_COOKIES_CF_URL) ? url : list[i][key];
                }
                completed++;
                if (completed === count) {
                    dispatch({ type: action, payload: list });
                }
            } else {
                count++;
                completed++;
                if (completed === count) {
                    dispatch({ type: action, payload: list });
                }
            }
        })
    }
}

export function getTools(cid, cancelToken) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.GETTING_TOOLS });
        get(`${ROUTES.GET_TOOLS}/${cid}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.GETTING_TOOLS_SUCCESS, payload: response.data.data })
                } else {
                    dispatch({ type: CompanyTypes.GETTING_TOOLS_FAIL })
                }
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: CompanyTypes.GETTING_TOOLS_FAIL });
                }
            })
    }
}

export function getKeyEmployeedCompanyList(cancelToken) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.FETCHING_FUNDING_COMPANY_LIST });
        get(`${ROUTES.GET_KEY_EMPLOYEED_COMPANIES}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.FETCHING_FUNDING_COMPANY_LIST_SUCCESS, payload: response.data });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_FUNDING_COMPANY_LIST_FAIL });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: CompanyTypes.FETCHING_FUNDING_COMPANY_LIST_FAIL });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                }
            })
    }
}

export function getKeyEmployeedCompanyImagesList(list, key, action, cancelToken) {
    return function (dispatch) {
        let count = 0, completed = 0;
        list.forEach((item, i) => {
            if (item[key]) {
                count++;
                const url = getCFObjURL(BUCKET.COMMON)(('ex-mediam-' + item[key]), true);
                if (url) {
                    list[i][key] = isURL(url, { protocols: ['https'] }, SIGNED_COOKIES_CF_URL) ? url : list[i][key];
                }
                completed++;
                if (completed === count) {
                    dispatch({ type: action, payload: list });
                }
            } else {
                count++;
                completed++;
                if (completed === count) {
                    dispatch({ type: action, payload: list });
                }
            }
        })
    }
}

export function setFundrasingCompanyToDisplay(companyId) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.SETTING_FUNDRAISING_COMPANY });
        put(`${ROUTES.SET_FUNDRAISING_COMPANY}`, { companyId })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.SETTING_FUNDRAISING_COMPANY_SUCCESS, payload: companyId });
                } else {
                    dispatch({ type: CompanyTypes.SETTING_FUNDRAISING_COMPANY_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({ type: CompanyTypes.SETTING_FUNDRAISING_COMPANY_FAIL });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            })
    }
}

export function bluesnapCustomer() {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.BLUESNAP_CUSTOMER });
    }
}

export function getFundraisingQuestions(compantId, cancelToken) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.GET_COMPANY_QUESTIONS });
        get(`${ROUTES.GET_COMPANY_QUESTIONS}${compantId}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.GET_COMPANY_QUESTIONS_SUCCESS, payload: response.data.data[0].FUND_RAISER_QUESTIONS });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });
                } else {
                    dispatch({ type: CompanyTypes.GET_COMPANY_QUESTIONS_FAIL });
                }
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: CompanyTypes.GET_COMPANY_QUESTIONS_FAIL });
                }
            })
    }
}

export function saveFundraisingQuestions(compantId, data) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.SAVE_COMPANY_QUESTIONS });
        put(`${ROUTES.SAVE_COMPANY_QUESTIONS}${compantId}`, data)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.SAVE_COMPANY_QUESTIONS_SUCCESS, payload: response.data });
                } else {
                    dispatch({ type: CompanyTypes.SAVE_COMPANY_QUESTIONS_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch(error => {
                dispatch({ type: CompanyTypes.SAVE_COMPANY_QUESTIONS_FAIL });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            })
    }
}

export function createCompany(data, cancelToken) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.CREATE_COMPANY });
        post(`${ROUTES.CREATE_COMPANY}`, data)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: CompanyTypes.CREATE_COMPANY_SUCCESS, payload: response.data.data._id });
                    dispatch({ type: CompanyTypes.CLEAR_NEW_COMPANY });

                    /*add newly added company to navbar action list*/
                    let data = {
                        _id: response.data.data._id,
                        COMPANY_NAME: response.data.data.COMPANY_NAME,
                        LOGO: response.data.data.LOGO,
                        IMAGE_NAME: response.data.data.LOGO
                    };

                    /*generate image url*/
                    if (data.LOGO) {
                        const url = getCFObjURL(BUCKET.COMMON)(('small-' + response.data.data.LOGO), true);
                        if (url) {
                            data.LOGO = url;
                            dispatch({ type: AuthTypes.ADD_TO_ADMIN_COMPANY_LIST, payload: data });
                        } else {
                            data.LOGO = "";
                            dispatch({ type: AuthTypes.ADD_TO_ADMIN_COMPANY_LIST, payload: data });
                        }
                    } else {
                        dispatch({ type: AuthTypes.ADD_TO_ADMIN_COMPANY_LIST, payload: data });
                    }
                } else {
                    dispatch({ type: CompanyTypes.CREATE_COMPANY_FAIL });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION});
                    showPopupAlert(dispatch, POP_ALERT.ERROR, response.data.message, 'Error');
                }
            })
            .catch(err => {
                dispatch({ type: CompanyTypes.CREATE_COMPANY_FAIL });
                dispatch({ type: CompanyTypes.CLEAR_ACTION});
                showPopupAlert(dispatch, POP_ALERT.ERROR, err.message, 'Error');
            });
    }
}

export function deleteCompany(companyId, sweetAlert) {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.DELETE_COMPANY });
        del(`${ROUTES.DELETE_COMPANY}${companyId}`)
            .then(response => {
                if (response.status === 200) {
                    dispatch({ type: CompanyTypes.DELETE_COMPANY_SUCCESS });
                } else if (response.status === 209) {
                    sweetAlert(
                        'Warning',
                        'The company is having active subscriptions. Please cancel those before deleting the company page',
                        false,
                        true,
                        true,
                        true,
                        true
                    );
                    dispatch({ type: CompanyTypes.DELETE_COMPANY_FAIL });
                } else {
                    dispatch({ type: CompanyTypes.DELETE_COMPANY_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
                dispatch({ type: CompanyTypes.CLEAR_ACTION });
            })
            .catch(err => {
                dispatch({ type: CompanyTypes.DELETE_COMPANY_FAIL });
                showPopupAlert(dispatch, POP_ALERT.WARNING, err.message);
            });
    }
}

export function switchCompany() {
    return function (dispatch) {
        dispatch({ type: CompanyTypes.SWITCH_COMPANY });
        setTimeout(5, () => {
            dispatch({ type: CompanyTypes.CLEAR_ACTION });
        });
    }
}

// quick edit temporary fix
export function updateCompanyUserQuickEdit(uid, data, empHistoryObj = false) {

    return function (dispatch, getState) {
        dispatch(updatingCompanyUser());
        put(`${ROUTES.UPDATE_ABOUT}${uid}`, data)
            .then(response => {
                if (response.data.code === 200) {

                    dispatch({ type: AuthTypes.CLEAR_PROFILE_IMAGE });
                    dispatch({ type: AuthTypes.CLEAR_PROFILE_THUMBNAIL_IMAGE });
                    localStorage.removeItem('profilePicture');
                    localStorage.removeItem('profileThumbnail');
                    localStorage.removeItem('publicProfilePicture');
                    if (response.data.data.TOKEN) {
                        localStorage.setItem('token', response.data.data.TOKEN);
                    }
                    dispatch({ type: CompanyTypes.UPDATING_COMPANY_USER_SUCCESS });
                    dispatch({ type: CompanyTypes.CLEAR_ACTION });

                    if (empHistoryObj) {
                        dispatch(addEmployeeHistory(uid, empHistoryObj));
                    }

                    dispatch(initlogin(localStorage.getItem('token'), false, response.data.message));

                    if (getState().newsFeed.newsfeedSinglePost) {
                        dispatch(fetchSinglePostForNewsFeed(getState().newsFeed.newsfeedSinglePost._id));
                    }
                    if (getState().newsFeed.trendingPost) {
                        const trendingPost = getState().newsFeed.trendingPost;
                        if (trendingPost.IS_TRENDING_TOPIC) {
                            dispatch(fetchTrendingPost('t', trendingPost._id));
                        }
                        if (trendingPost.IS_INDUSTRY_EVENT) {
                            dispatch(fetchTrendingPost('i', trendingPost._id));
                        }
                    }
                } else {
                    dispatch({ type: CompanyTypes.UPDATING_COMPANY_USER_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            }).catch((error) => {
                dispatch({ type: CompanyTypes.UPDATING_COMPANY_USER_FAIL });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            })
    }
}
