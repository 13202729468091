import React from "react";
import { connect } from 'react-redux';
import { Route, Redirect } from "react-router-dom";
import { Loading } from '../../components/common';
import {routeLogin} from './../../actions/route';



class PrivateRoute extends React.Component {

    // constructor(props) {
    //     super(props);

    //     this.state = {
    //         loading: true,
    //         isAuthenticated: false,
    //     }

    // }

    // componentWillMount() {
    //     if(localStorage.getItem('token')){
    //         this.props.dispatch(AuthActions.initlogin(localStorage.getItem('token')));
    //     }
    // }

    // componentDidMount() {
    //     // 1 = Investor, 2 = Professional, 3 = Admin
    //     axios.post(`${ROUTES.USERDETAILS}`, {}, { headers: { Authorization: localStorage.getItem('token') } }).then(response => {
    //         if (response.data.code === 200) {
    //             let user = response.data.data;
    //             if (!user) { // user does not exisit
    //                 this.setState({
    //                     loading: false,
    //                     isAuthenticated: false,
    //                 });
    //             } else if (user.MEMBER_TYPE === MEMBER_TYPE.INVESTOR && user.REGISTRATION_STEP === REGISTRATION_STEP.INVESTOR_STEP_04) { 
    //                 this.setState({
    //                     loading: false,
    //                     isAuthenticated: true,
    //                 });
    //             } else if (user.MEMBER_TYPE === MEMBER_TYPE.PROFESSIONAL && user.REGISTRATION_STEP === REGISTRATION_STEP.PROFESSIONAL_STEP_03) { 
    //                 this.setState({
    //                     loading: false,
    //                     isAuthenticated: true,
    //                 });
    //             } else if (user.MEMBER_TYPE === 3) { // admin user
    //                 this.setState({
    //                     loading: false,
    //                     isAuthenticated: true,
    //                 });
    //             } else { // authentication fail, user does not have priviledges to navigate
    //                 this.setState({
    //                     loading: false,
    //                     isAuthenticated: false,
    //                 });
    //             }
    //         } else { // invalid token or server authenication fail
    //             this.setState({
    //                 loading: false,
    //                 isAuthenticated: false,
    //             });
    //         }
    //     }).catch((exception) => { // server does not response
    //         this.setState({
    //             loading: false,
    //             isAuthenticated: false,
    //         });
    //     });
    // }

    componentWillMount(){
        if(localStorage.getItem('token') && !this.props.route.isAuthenticated){
            this.props.dispatch(routeLogin(localStorage.getItem('token')));
        }
    }

    render() {
        const { component: Component, ...rest } = this.props;
        if(this.props.route.loading){
            return <Loading/>
        } else if(rest.redirect){
            return <Redirect to={rest.url} />
        } else {
            return (
                <Route {...rest} render={props => (
                    <div>
                        {!this.props.route.isAuthenticated && <Redirect to={`/`} />}
                        <Component {...this.props} {...props} />
                    </div>
                )}
                />
            ) 
        }
    }

};

export default connect(state => {
    return {
        state,
        route: state.route
    };

})(PrivateRoute);
