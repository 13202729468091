import React from "react"
import ContentLoader from "react-content-loader"

const Loader = props => {
    const random = Math.random() * (1 - 0.7) + 0.7
    return (
        <ContentLoader
            height={40}
            width={1060}
            speed={2}
            primaryColor="#d9d9d9"
            secondaryColor="#ecebeb"
            {...props}
        >
            <rect x="34" y="13" rx="6" ry="6" width={200 * random} height="12" />
            <rect x="653" y="13" rx="6" ry="6" width={78 * random} height="12" />
            <rect x="755" y="13" rx="6" ry="6" width={117 * random} height="12" />
            <rect x="938" y="13" rx="6" ry="6" width={83 * random} height="12" />

            <rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
        </ContentLoader>
    )
}

const TableLoader = () => (
    <React.Fragment>
        {Array(6)
            .fill("")
            .map((e, i) => (
                <Loader key={i} style={{ opacity: Number(2 / i).toFixed(1) }} />
            ))}
    </React.Fragment>
)

const CardContentLoader = props => (
    <ContentLoader
        rtl
        height={55}
        width={400}
        speed={2}
        primaryColor="#e2e2e2"
        secondaryColor="#ecebeb"
        {...props}
    >
        <rect x="60.84" y="25" rx="4" ry="4" width="156.22" height="8.32" />
        <rect x="282.84" y="17.67" rx="4" ry="4" width="17.5" height="14.54" />
        <rect x="9" y="16" rx="5" ry="5" width="41.11" height="25.16" />
        <rect x="343.84" y="62.67" rx="0" ry="0" width="0" height="0" />
        <rect x="229.84" y="23.67" rx="4" ry="4" width="34.37" height="7.54" />
        <rect x="316.84" y="17.67" rx="4" ry="4" width="17.5" height="14.54" />
    </ContentLoader>
)

const CardWrapper = props => {
    return (
        <React.Fragment>
            <CardContentLoader />
            <CardContentLoader />
            <CardContentLoader />
        </React.Fragment>
    )
};

const Subscriptions = props => (
    <ContentLoader
        rtl
        height={55}
        width={400}
        speed={2}
        primaryColor="#e2e2e2"
        secondaryColor="#ecebeb"
        {...props}
    >
        <rect x="96.01" y="18.94" rx="4" ry="4" width="103" height="13.26" />
        <circle cx="29.84" cy="46.67" r="1" />
        <rect x="8.47" y="18.98" rx="0" ry="0" width="68.75" height="35.48" />
        <rect x="256.84" y="79.07" rx="0" ry="0" width="129.62" height="27.54" />
        <rect x="29.84" y="31.67" rx="0" ry="0" width="0" height="0" />
        <rect x="95.01" y="35.2" rx="4" ry="4" width="266.77" height="8.75" />
        <rect x="94.01" y="62.29" rx="4" ry="4" width="207.03" height="6.76" />
    </ContentLoader>
)

const MarketplaceReportLoader = props => (
    <ContentLoader
        rtl
        height={100}
        width={400}
        speed={2}
        primaryColor="#e2e2e2"
        secondaryColor="#ecebeb"
        {...props}
    >
        <rect x="13" y="12" rx="0" ry="0" width="127" height="7" />
        <rect x="39" y="37" rx="0" ry="0" width="61" height="15" />
        <rect x="104" y="37" rx="0" ry="0" width="61" height="15" />
        <rect x="169" y="37" rx="0" ry="0" width="61" height="15" />
        <rect x="234" y="37" rx="0" ry="0" width="61" height="15" />
        <rect x="298" y="37" rx="0" ry="0" width="61" height="15" />
        <rect x="74" y="64" rx="0" ry="0" width="61" height="15" />
        <rect x="139" y="64" rx="0" ry="0" width="61" height="15" />
        <rect x="204" y="64" rx="0" ry="0" width="61" height="15" />
        <rect x="269" y="64" rx="0" ry="0" width="61" height="15" />
    </ContentLoader>
)

const SubscriptionsWrapper = props => {
    return (
        <React.Fragment>
            <Subscriptions />
            <Subscriptions />
        </React.Fragment>
    )
}

const ProductCategories = props => {
    return (
        <ContentLoader
            height={220}
            width={250}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            {...props}
        >
            <rect x="19.84" y="20.96" rx="0" ry="0" width="158.46" height="10.7" />
            <rect x="58.84" y="48.67" rx="0" ry="0" width="0" height="1" />
            <rect x="35.84" y="46.67" rx="0" ry="0" width="17" height="15" />
            <rect x="64.84" y="54.24" rx="0" ry="0" width="137.76" height="5.6" />
            <rect x="35.84" y="68.67" rx="0" ry="0" width="17" height="15" />
            <rect x="35.84" y="91.67" rx="0" ry="0" width="17" height="15" />
            <rect x="35.84" y="116.67" rx="0" ry="0" width="17" height="15" />
            <rect x="35.84" y="139.67" rx="0" ry="0" width="17" height="15" />
            <rect x="66.84" y="72.67" rx="0" ry="0" width="123" height="5" />
            <rect x="66.84" y="95.89" rx="0" ry="0" width="142.68" height="5.8" />
            <rect x="66.84" y="122.67" rx="0" ry="0" width="123" height="5" />
            <rect x="66.84" y="142.97" rx="0" ry="0" width="140.22" height="5.7" />
            <rect x="21.84" y="168.67" rx="0" ry="0" width="214" height="33" />
        </ContentLoader>
    )
}

TableLoader.metadata = {
    name: "DaniloWoz",
    github: "danilowoz",
    description: "Table with the width of the dynamic rows",
    filename: "danilowoz-table"
}

/*const ProductForm = props => {
    return (
        <ContentLoader
            height={800}
            width={700}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
            <rect x="7" y="19" rx="0" ry="0" width="663" height="22" />
            <rect x="7" y="59" rx="0" ry="0" width="663" height="101" />
            <rect x="8" y="200" rx="0" ry="0" width="106" height="25" />
            <rect x="9" y="239" rx="0" ry="0" width="144" height="25" />
            <rect x="127" y="187" rx="0" ry="0" width="106" height="39" />
            <rect x="174" y="249" rx="0" ry="0" width="77" height="12" />
            <rect x="260" y="249" rx="0" ry="0" width="77" height="12" />
            <rect x="514" y="201" rx="0" ry="0" width="154" height="65" />
            <circle cx="500" cy="233" r="51" />
            <rect x="11" y="306" rx="0" ry="0" width="355" height="6" />
            <rect x="30" y="332" rx="0" ry="0" width="104" height="11" />
            <rect x="186" y="333" rx="0" ry="0" width="104" height="11" />
            <rect x="549" y="333" rx="0" ry="0" width="104" height="11" />
            <rect x="366" y="333" rx="0" ry="0" width="104" height="11" />
            <rect x="30" y="361" rx="0" ry="0" width="20" height="21" />
            <rect x="30" y="390" rx="0" ry="0" width="20" height="21" />
            <rect x="30" y="418" rx="0" ry="0" width="20" height="21" />
            <rect x="30" y="447" rx="0" ry="0" width="20" height="21" />
            <rect x="30" y="477" rx="0" ry="0" width="20" height="21" />
            <rect x="190" y="361" rx="0" ry="0" width="20" height="21" />
            <rect x="189" y="389" rx="0" ry="0" width="20" height="21" />
            <rect x="189" y="418" rx="0" ry="0" width="20" height="21" />
            <rect x="189" y="449" rx="0" ry="0" width="20" height="21" />
            <rect x="189" y="477" rx="0" ry="0" width="20" height="21" />
            <rect x="189" y="508" rx="0" ry="0" width="20" height="21" />
            <rect x="190" y="536" rx="0" ry="0" width="20" height="21" />
            <rect x="191" y="568" rx="0" ry="0" width="20" height="21" />
            <rect x="369" y="360" rx="0" ry="0" width="20" height="21" />
            <rect x="369" y="388" rx="0" ry="0" width="20" height="21" />
            <rect x="369" y="419" rx="0" ry="0" width="20" height="21" />
            <rect x="369" y="447" rx="0" ry="0" width="20" height="21" />
            <rect x="369" y="475" rx="0" ry="0" width="20" height="21" />
            <rect x="370" y="504" rx="0" ry="0" width="20" height="21" />
            <rect x="549" y="359" rx="0" ry="0" width="20" height="21" />
            <rect x="549" y="387" rx="0" ry="0" width="20" height="21" />
            <rect x="549" y="414" rx="0" ry="0" width="20" height="21" />
            <rect x="64" y="367" rx="0" ry="0" width="89" height="6" />
            <rect x="67" y="397" rx="0" ry="0" width="89" height="6" />
            <rect x="67" y="427" rx="0" ry="0" width="89" height="6" />
            <rect x="67" y="483" rx="0" ry="0" width="89" height="6" />
            <rect x="68" y="455" rx="0" ry="0" width="89" height="6" />
            <rect x="228" y="365" rx="0" ry="0" width="89" height="6" />
            <rect x="227" y="397" rx="0" ry="0" width="89" height="6" />
            <rect x="228" y="427" rx="0" ry="0" width="89" height="6" />
            <rect x="227" y="456" rx="0" ry="0" width="89" height="6" />
            <rect x="229" y="484" rx="0" ry="0" width="89" height="6" />
            <rect x="229" y="514" rx="0" ry="0" width="89" height="6" />
            <rect x="228" y="543" rx="0" ry="0" width="89" height="6" />
            <rect x="228" y="573" rx="0" ry="0" width="89" height="6" />
            <rect x="408" y="397" rx="0" ry="0" width="89" height="6" />
            <rect x="407" y="365" rx="0" ry="0" width="89" height="6" />
            <rect x="409" y="423" rx="0" ry="0" width="89" height="6" />
            <rect x="407" y="455" rx="0" ry="0" width="89" height="6" />
            <rect x="408" y="482" rx="0" ry="0" width="89" height="6" />
            <rect x="408" y="510" rx="0" ry="0" width="89" height="6" />
            <rect x="589" y="364" rx="0" ry="0" width="89" height="6" />
            <rect x="587" y="396" rx="0" ry="0" width="89" height="6" />
            <rect x="588" y="424" rx="0" ry="0" width="89" height="6" />
            <rect x="29" y="622" rx="0" ry="0" width="141" height="82" />
            <rect x="188" y="621" rx="0" ry="0" width="141" height="82" />
            <rect x="349" y="620" rx="0" ry="0" width="321" height="82" />
            <rect x="71" y="728" rx="0" ry="0" width="539" height="7" />
            <rect x="402" y="754" rx="0" ry="0" width="74" height="28" />
            <rect x="497" y="754" rx="0" ry="0" width="74" height="28" />
            <rect x="588" y="753" rx="0" ry="0" width="74" height="28" />
        </ContentLoader>
    )
}*/

export { TableLoader, CardWrapper, MarketplaceReportLoader, SubscriptionsWrapper, ProductCategories/*, ProductForm*/ }