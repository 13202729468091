import React, { Component } from "react"
class ModalFooter extends Component {

    render(){
        return(
                             
            <div className="modal-footer">
              <div className="d-flex align-items-center justify-content-between">
                   {this.props.children}
              </div>     
             </div>  
        )
    }

}
export {ModalFooter}