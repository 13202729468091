import SettingTypes from '../types/settings';

export const initialState = {
    fetching: false,
    action: 0,
    normalUsers: [],
    adminUsers: [],
};

export const settings = (state = initialState, action) => {
    switch (action.type) {
        case SettingTypes.FETCHING_NORMAL_USERS:
            return {
                ...state,
                fetching: true,
                action: SettingTypes.FETCHING_NORMAL_USERS
            };
        case SettingTypes.FETCHING_NORMAL_USERS_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: SettingTypes.FETCHING_NORMAL_USERS_SUCCESS,
                normalUsers: action.payload.data
            };
        case SettingTypes.FETCHING_NORMAL_USERS_FAIL:
            return {
                ...state,
                fetching: false,
                action: SettingTypes.FETCHING_NORMAL_USERS_FAIL
            };

        case SettingTypes.FETCHING_ADMINS:
            return {
                ...state,
                fetching: true,
                action: SettingTypes.FETCHING_ADMINS
            };
        case SettingTypes.FETCHING_ADMINS_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: SettingTypes.FETCHING_ADMINS_SUCCESS,
                adminUsers: action.payload.data
            };
        case SettingTypes.FETCHING_ADMINS_FAIL:
            return {
                ...state,
                fetching: false,
                action: SettingTypes.FETCHING_ADMINS_FAIL
            };

        case SettingTypes.SAVING_ADMIN_CHANGES:
            return {
                ...state,
                fetching: true,
                action: SettingTypes.SAVING_ADMIN_CHANGES
            };
        case SettingTypes.SAVING_ADMIN_CHANGES_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: SettingTypes.SAVING_ADMIN_CHANGES_SUCCESS,
            };
        case SettingTypes.SAVING_ADMIN_CHANGES_FAIL:
            return {
                ...state,
                fetching: false,
                action: SettingTypes.SAVING_ADMIN_CHANGES_FAIL
            };
    
        default:
            return state
    }
}