import React, { PureComponent, useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import './styles.css';
// import avatar from "./../../../assets/sample/avt.png";
import defaultAvatar from './../../../assets/img/default-male.jpg';
import { Link } from 'react-router-dom';
import { isURL, isValidImageURL } from '../../../libs/validation';
import {
  REPORT_LOCATIONS,
  PUBLIC_PENDING_ACTIONS,
  USER_EMOTION_TYPES,
  SYSTEM_USER_STATUS,
} from '../../../types/common';
import CommentorOverview from '../../../containers/feed/commentorOverview';
import {
  getPreview,
  formatRichText,
  createTextLinks,
  isTextContainsUrl,
  getMyReaction,
} from '../../../libs/textFormat';
// import commentTopImage from '../../../assets/img/expert-contributor.svg';
import PostOptions from '../../postOptions';
import { SIGNED_COOKIES_CF_URL } from '../../../config';

import {
  likeComment,
  unlikeComment,
  getLikesForComments,
} from '../../../actions/newsFeed';
import {
  LIKE_COMMENT,
  LIKE_COMMENT_SUCCESS,
  LIKE_COMMENT_FAIL,
} from '../../../types/newsFeed';
import LikesModal from '../../../containers/viewUsersModal';
import { removePublicAction } from '../../../actions/auth';
import { ReactionsPopup } from '../../reactions/reactionsPopup';
import { PostCommentReactionCounts } from '../postReactions';
import { isMobile } from '../../../libs/commonFunctions';
import { trackEvent } from '../../../actions/eventTracking';
import { EVENT_ACTION } from '../../../types/eventTracking';
import { COMMON } from '../../../types/dataTrackingElementIds';

import MentionTagInput from '../../input/mentionTagInput';
import { EditorState, ContentState, convertFromRaw } from 'draft-js';

const GetCommentLike = ({
  active,
  handleLike,
  toggleLikesModal,
  likesForComment,
  id,
  recentlyReacted,
  recentlyUnliked,
  isPublicFeed = false,
}) => {
  const [reactionsPopup, setReactionsPopup] = useState(false);
  const [loading, setLoading] = useState(false); // to avoid accidentally open reaction popup after reacting
  let myReaction = getMyReaction(recentlyReacted ? recentlyReacted : active);
  const isMobileView = isMobile();
  let mobileCounter = 0;

  // close reaction popup on click
  const onClickHandler = () => {
    setReactionsPopup(false);
    window.removeEventListener('click', onClickHandler);
    window.removeEventListener(
      'reactionsPopup',
      onOtherReactionPopupOpenhandler
    );
    window.removeEventListener('mousemove', onMouseMoveHandler);
  };

  // close reaction popup on another reaction popup open
  const onOtherReactionPopupOpenhandler = (event) => {
    if (event.detail.id !== id) {
      setReactionsPopup(false);
      window.removeEventListener('click', onClickHandler);
      window.removeEventListener(
        'reactionsPopup',
        onOtherReactionPopupOpenhandler
      );
      window.removeEventListener('mousemove', onMouseMoveHandler);
    }
  };

  // close reaction popup on mouse move out
  const onMouseMoveHandler = (e) => {
    if (
      !(
        document.getElementById(`user_reaction_popup_${id}`) &&
        document.getElementById(`user_reaction_popup_${id}`).contains(e.target)
      )
    ) {
      setReactionsPopup(false);
      window.removeEventListener('click', onClickHandler);
      window.removeEventListener(
        'reactionsPopup',
        onOtherReactionPopupOpenhandler
      );
      window.removeEventListener('mousemove', onMouseMoveHandler);
    }
  };

  const _react = (type) => {
    handleLike(type);
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
    if (isMobileView) {
      setReactionsPopup(false);
    }
  };

  return (
    <div className="comment-like-container disabled">
      <div
        id={`user_reaction_popup_${id}`}
        className={`comment-reaction ${myReaction.className} ${
          active ? 'reacted' : ''
        }`}
        onClick={() => {
          if (!isMobileView && !isPublicFeed) {
            if (recentlyReacted) {
              _react(USER_EMOTION_TYPES.NONE);
            } else if (active && !recentlyUnliked.includes(id)) {
              _react(USER_EMOTION_TYPES.NONE);
            } else {
              _react(USER_EMOTION_TYPES.LIKE);
            }
          } else if (isPublicFeed) {
            _react(USER_EMOTION_TYPES.LIKE);
          }
        }}
        onTouchStart={() => {
          if (isMobileView && !isPublicFeed) {
            mobileCounter = Date.now();
            setTimeout(() => {
              if (mobileCounter !== 0) {
                mobileCounter = 0;
                setReactionsPopup(true);
                window.addEventListener(
                  'reactionsPopup',
                  onOtherReactionPopupOpenhandler
                );
                window.dispatchEvent(
                  new CustomEvent('reactionsPopup', { detail: { id } })
                );
                setTimeout(
                  () => window.addEventListener('click', onClickHandler),
                  3000
                );
              }
            }, 300);
          } else if (isPublicFeed) {
            _react(USER_EMOTION_TYPES.LIKE);
          }
        }}
        onTouchEnd={() => {
          mobileCounter = 0;
          if (
            myReaction.className !== '' &&
            !reactionsPopup &&
            isMobileView &&
            !isPublicFeed
          ) {
            _react(USER_EMOTION_TYPES.NONE);
          } else if (
            !reactionsPopup &&
            myReaction.className === '' &&
            isMobileView &&
            !isPublicFeed
          ) {
            _react(USER_EMOTION_TYPES.LIKE);
          }
        }}
        onContextMenu={(event) => {
          event.preventDefault();
          event.stopPropagation();
          return false;
        }}
        onMouseEnter={(e) => {
          if (!loading && !isPublicFeed && !isMobileView) {
            setReactionsPopup(true);
            window.addEventListener(
              'reactionsPopup',
              onOtherReactionPopupOpenhandler
            );
            window.dispatchEvent(
              new CustomEvent('reactionsPopup', { detail: { id } })
            );
            window.addEventListener('click', onClickHandler);
            setTimeout(
              () => window.addEventListener('mousemove', onMouseMoveHandler),
              2000
            );
          }
        }}
      >
        <img src={myReaction.img} alt={myReaction.text} />
        <div>{`${myReaction.text}`}</div>
      </div>
      {reactionsPopup && <ReactionsPopup react={_react} />}
      {likesForComment && (
        <PostCommentReactionCounts
          likedUsers={likesForComment}
          recentReaction={recentlyReacted}
          recentlyUnliked={recentlyUnliked.includes(id)}
          openLikedUsers={toggleLikesModal}
          postId={id}
          ID={id}
        />
      )}
    </div>
  );
};

class Comment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      profileImage: this.props.imgSrc,
      avatar: defaultAvatar,
      queuedPoppingCommentId: null,
      currentPopover: {
        commentId: null,
        hoverLocation: null,
        onPopOver: false,
      },
      showAllLikesModal: false,
      disableLike: false,
      userReaction: this.props.likesForComment
        ? this.props.likesForComment.filter(
            (like) => like.USER_ID === localStorage.getItem('userId')
          )
        : [],
      isEditing: false,
      editedComment: null,
      editedCommentDescription: '',
      fetching: false,
      isChanged: false,
      isUserRecentlyDeleted: false, //if user deleted recently
    };
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true
    if (!isURL(String.toString(this.props.imgSrc), { protocols: ['https'] }, SIGNED_COOKIES_CF_URL) && this.props.imgSrc !== '') {
      isValidImageURL(this.props.imgSrc, 1).then(avatar => this.setState({ avatar })).catch(avatar => this.setState({ avatar }));
    }

    if (
      this.props.publicPendingAction &&
      this.props.publicPendingAction.type &&
      this.props.publicPendingAction.type ===
        PUBLIC_PENDING_ACTIONS.P_VIEW_COMMENT_LIKES
    ) {
      let action = this.props.publicPendingAction;
      if (
        action.data.postId === this.props.postId &&
        action.data.commentId === this.props.commentId &&
        action.data.postId &&
        (this.props.isNFSinglePost || action.data.isFirstPost)
      ) {
        this.props.dispatch(removePublicAction());
        this.toggleLikesModal();
      }
    }

    if (this.props.commenterId === localStorage.getItem('userId')) {
      if (this.props.richText) {
        this.setState({
          editedComment: EditorState.createWithContent(
            convertFromRaw(this.props.richText)
          ),
          editedCommentDescription: this.props.message,
        });
      } else {
        this.setState({
          editedComment: EditorState.createWithContent(
            ContentState.createFromText(this.props.message)
          ),
          editedCommentDescription: this.props.message,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.imgSrc && this.props.imgSrc !== '') && (this.props.imgSrc !== prevProps.imgSrc)) {
      if (!isURL(String.toString(this.props.imgSrc), { protocols: ['https'] }, SIGNED_COOKIES_CF_URL)) {
        isValidImageURL(this.props.imgSrc, 1).then((avatar) => {
          this.setState({ avatar });
        }).catch((avatar) => {
          this.setState({ avatar });
        });
      }
    }
    if (
      this.props.likeAction !== prevProps.likeAction &&
      this.props.likeAction === LIKE_COMMENT
    ) {
      // this.setState({disableLike: true}) // commented on reactions change - mewan
    } else if (
      this.props.likeAction !== prevProps.likeAction &&
      (this.props.likeAction === LIKE_COMMENT_SUCCESS ||
        this.props.likeAction === LIKE_COMMENT_FAIL)
    ) {
      this.setState({ disableLike: false });
    }
  }

  componentWillUnmount() {
    this.setState({
      currentPopover: {
        commentId: null,
        hoverLocation: null,
      },
    });
    this._isMounted = false;
  }

  _deleteComment = () => {
    if (!this.props.isDeleting) {
      /* data project - event trcking */
      if (this.props.user && this.props.page) {
        const eventPayload = {
          user_id: this.props.user._id,
          page: this.props.page,
          section: this.props.section,
          element_type: 'SPAN',
          element_id: COMMON.DELETE,
          post_id: this.props.postId,
          action_type: EVENT_ACTION.P_COMMENT_DELETE,
        };
        this.props.dispatch(trackEvent(eventPayload));
      }
      /* END : data project - event trcking */

      this.props.deleteComment(this.props.commentId);
    }
  };

  _setOnPopOver = (status) => {
    this.setState({ onPopOver: status });
  };

  _closeEdit = () => {
    this.setState(
      { isEditing: false, fetching: false, isChanged: false },
      () => {
        if (this.props.commenterId === localStorage.getItem('userId')) {
          if (this.props.richText) {
            this.setState({
              editedComment: EditorState.createWithContent(
                convertFromRaw(this.props.richText)
              ),
              editedCommentDescription: this.props.message,
            });
          } else {
            this.setState({
              editedComment: EditorState.createWithContent(
                ContentState.createFromText(this.props.message)
              ),
              editedCommentDescription: this.props.message,
            });
          }
        }
      }
    );
  };

  alreadyLikedComment = () => {
    if (this.props.likesForComment) {
      return (
        this.props.likesForComment.filter(
          (like) => like.USER_ID === localStorage.getItem('userId')
        ).length > 0
      );
    }
    return false;
  };

  handleLike = (reaction) => {
    if (!this.state.disableLike) {
      let liked = true;
      if (reaction === USER_EMOTION_TYPES.NONE) {
        liked = false;
        this.props.dispatch(
          unlikeComment(
            this.props.postId,
            this.props.commentId,
            localStorage.getItem('userId')
          )
        );
      } else {
        if (this.props.isPublicFeed) {
          this.props.openLogin(PUBLIC_PENDING_ACTIONS.P_LIKE_COMMENT, {
            postId: this.props.postId,
            commentId: this.props.commentId,
          });
        } else {
          this.props.dispatch(
            likeComment(
              this.props.postId,
              this.props.commentId,
              localStorage.getItem('userId'),
              reaction
            )
          );
        }
      }
      /* data project - event trcking */
      if (this.props.user && this.props.page) {
        const eventPayload = {
          user_id: this.props.user._id,
          page: this.props.page,
          section: this.props.section,
          element_type: 'BUTTON',
          element_id: this.props.commentId,
          post_id: this.props.postId,
          action_type: liked
            ? EVENT_ACTION.P_COMMENT_LIKE
            : EVENT_ACTION.P_COMMENT_DISLIKE,
        };
        this.props.dispatch(trackEvent(eventPayload));
      }
      /* END : data project - event trcking */
    }
  };

  toggleLikesModal = () => {
    if (this.props.isPublicFeed) {
      this.props.openLogin(PUBLIC_PENDING_ACTIONS.P_VIEW_COMMENT_LIKES, {
        postId: this.props.postId,
        commentId: this.props.commentId,
        isFirstPost: this.props.isFirstPost,
      });
    } else {
      this.setState((prevState) => {
        if (this.props.toggleZindex)
          this.props.toggleZindex(prevState.showAllLikesModal);
        return { showAllLikesModal: !prevState.showAllLikesModal };
      });
    }
  };

  handleChange = (text, richText) => {
    this.setState({
      editedCommentDescription: text,
      editedComment: richText,
      isChanged: !this.state.isChanged
        ? text !== this.state.editedCommentDescription
        : this.state.isChanged,
    });
  };

  _edit = () => {
    this.setState({ fetching: true }, () => {
      this.props.editComment(
        this.props.commentId,
        this.state.editedCommentDescription,
        this.state.editedComment,
        () => {
          setTimeout(() => this._closeEdit(), 2000);
        }
      );
    });
  };

  _setUserRecentlyDeleted = (status) => {
    this.setState({ isUserRecentlyDeleted: status });
  };

  render() {
    let topPopover = {
      placement: 'top',
    };

    let { commentId, hoverLocation } = this.state.currentPopover;
    let userActiveReaction =
      this.props.unlikedItems &&
      this.props.unlikedItems.includes(this.props.commentId)
        ? false
        : this.state.userReaction && this.state.userReaction[0]
        ? this.state.userReaction[0].EMOTION_TYPE
        : false;

    /* data project - event trcking */
    let eventPayload = {
      user_id: this.props.user ? this.props.user._id : null,
      page: this.props.page,
      section: this.props.section,
      element_type: null,
      element_id: null,
      action_type: EVENT_ACTION.CLICK,
      post_id: this.props.postId,
    };
    /* END : data project - event trcking */

    return (
      <div
        className={
          this.props.isReportedComment
            ? 'reported_comment added_comment'
            : 'added_comment'
        }
        style={{
          backgroundColor: this.props.commentId
            ? this.props.isDeleting
              ? '#ca69692e'
              : ''
            : '#ffff0061',
        }}
      >
        {this.state.showAllLikesModal && (
          <LikesModal
            socket={this.props.socket}
            title="Reactions"
            close={this.toggleLikesModal}
            isOpen={this.state.showAllLikesModal}
            fetchUsers={() =>
              getLikesForComments(this.props.postId, this.props.commentId)
            }
            openMsgPopup={this.props.openMsgPopup}
          />
        )}
        <div
          className={`d-flex flex-row ${
            this.props.feed ? 'added_comment_inner-feed' : 'added_comment_inner'
          }`}
        >
          <div className="commenter_left">
            {!this.props.isPublicFeed &&
              this.props.commenterId !== localStorage.getItem('userId') &&
              commentId === this.props.commentId &&
              hoverLocation === 'commenterImage' &&
              this.props.commenterStatus === SYSTEM_USER_STATUS.ACTIVE && (
                <CommentorOverview
                  user={this.props.user}
                  page={this.props.page}
                  section={this.props.section}
                  postId={this.props.postId}
                  setOnPopOver={this._setOnPopOver.bind(this)}
                  hidePopOver={this._clearCurrentPopover.bind(this)}
                  item={topPopover}
                  id={this.props.commentId}
                  commenterId={this.props.commenterId}
                  show={
                    commentId === this.props.commentId &&
                    hoverLocation === 'commenterImage'
                  }
                  openMsgPopup={this.props.openMsgPopup}
                  socket={this.props.socket}
                  setUserRecentlyDeleted={this._setUserRecentlyDeleted}
                />
              )}

            {this.getCommenterImage(this.state.avatar)}
          </div>

          <div className="commenter_right p-l10 p-r5">
            <div className="d-flex flex-row justify-content-between commenter_info align-items-start">
              <div className="rp_info w-100">
                {this.props.commenterId !== localStorage.getItem('userId') &&
                  commentId === this.props.commentId &&
                  hoverLocation === 'commenterName' &&
                  this.props.commenterStatus === SYSTEM_USER_STATUS.ACTIVE &&
                  !this.props.isPublicFeed && (
                    <CommentorOverview
                      user={this.props.user}
                      page={this.props.page}
                      section={this.props.section}
                      postId={this.props.postId}
                      setOnPopOver={this._setOnPopOver.bind(this)}
                      hidePopOver={this._clearCurrentPopover.bind(this)}
                      item={topPopover}
                      id={this.props.commentId + 'abc'}
                      commenterId={this.props.commenterId}
                      show={
                        commentId === this.props.commentId &&
                        hoverLocation === 'commenterName'
                      }
                      openMsgPopup={this.props.openMsgPopup}
                      socket={this.props.socket}
                      setUserRecentlyDeleted={this._setUserRecentlyDeleted}
                    />
                  )}

                <div className="d-flex align-items-center">
                  <h3
                    id={'Popover-' + this.props.commentId + 'abc'}
                    onMouseLeave={this._clearCurrentPopover.bind(this)}
                    onMouseEnter={this._getPopoverComment.bind(
                      this,
                      this.props.commentId,
                      'commenterName'
                    )}
                    className="font_14 font_medium font_dark_gray_two get_zero_space commenter-name"
                  >
                    {this.props.commenterStatus === SYSTEM_USER_STATUS.ACTIVE &&
                    !this.state.isUserRecentlyDeleted ? (
                      <Link
                        to={
                          this.props.isPublicFeed
                            ? '/'
                            : (localStorage.getItem('userId')===this.props.commenterId)? `/user-profile-edit/${this.props.commenterId}`: `/user-profile/${this.props.commenterId}`
                        }
                        onClick={() => {
                          if (this.props.isPublicFeed) {
                            this.props.openLogin(
                              PUBLIC_PENDING_ACTIONS.P_VIEW_USER,
                              `/user-profile/${this.props.commenterId}`
                            );
                          }
                        }}
                      >
                        {getPreview(this.props.commenter, 40)}
                      </Link>
                    ) : (
                      getPreview(this.props.commenter, 40)
                    )}
                  </h3>
                  <div className="badges d-flex align-items-centers m-l5">
                    {this.props.isProUser && (
                      <div className="badge badge-primary m-r5">PRO</div>
                    )}
                    {this.props.isExpertContributor && (
                      <div className="badge badge-primary m-r5 green">
                        EXPERT
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-row comment_time font_12">
                    <div className="position-relative text-right d-flex align-items-center">
                      <p>{this.props.time}</p>
                      {!this.props.isPublicFeed && (
                        <div className="comment-options-wrapper">
                          <PostOptions
                            dispatch={this.props.dispatch}
                            user={this.props.user}
                            page={this.props.page}
                            postId={this.props.postId}
                            section={this.props.section}
                            element_id={this.props.commentId}
                            creator={this.props.commenterId}
                            location={REPORT_LOCATIONS.NAMES.COMMENT}
                            report={() => {
                              if (this.props.user && this.props.page) {
                                /* data project - event trcking */
                                eventPayload.element_type = 'SPAN';
                                eventPayload.element_id = this.props.commentId;
                                if (this.props.user)
                                  this.props.dispatch(trackEvent(eventPayload));
                                /* END : data project - event trcking */
                              }

                              this.props.openReportModal({
                                contentOwnerId: this.props.commenterId,
                                contentId: this.props.commentId,
                                action: REPORT_LOCATIONS.COMMENT,
                                type: REPORT_LOCATIONS.NAMES.COMMENT,
                              });
                            }}
                            postingGuideLines={() => {
                              if (this.props.user && this.props.page) {
                                /* data project - event trcking */
                                eventPayload.element_type = 'SPAN';
                                eventPayload.element_id = COMMON.GUILD;
                                if (this.props.user)
                                  this.props.dispatch(trackEvent(eventPayload));
                                /* END : data project - event trcking */
                              }
                              this.props.openPostingGuideLinesModal();
                            }}
                            openEdit={() => {
                              /* data project - event trcking */
                              eventPayload.element_type = 'SPAN';
                              eventPayload.element_id = COMMON.EDIT;
                              if (this.props.user)
                                this.props.dispatch(trackEvent(eventPayload));
                              /* END : data project - event trcking */

                              this.setState({ isEditing: true });
                            }}
                            deleteElement={
                              this.props.showDelete ? this._deleteComment : null
                            }
                            showOnHover={true}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {this.props.headline && this.props.headline !== null && (
                  <div className="headline">{this.props.headline}</div>
                )}
                {(!this.props.headline || this.props.headline === null) && (
                  <div className="headline">
                    {getPreview(this.props.commenterDetails, 70)}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className="font_12 l-h180 font_regular comment-content">
                {this.props.richText && !this.state.isEditing && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: formatRichText(this.props.richText).replace(
                        /(?:\r\n|\r|\n)/g,
                        '<br/>'
                      ),
                    }}
                  />
                )}
                {!this.props.richText && !this.state.isEditing && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: isTextContainsUrl(this.props.message)
                        ? createTextLinks(this.props.message).replace(
                            /(?:\r\n|\r|\n)/g,
                            '<br/>'
                          )
                        : this.props.message.replace(/(?:\r\n|\r|\n)/g, '<br/>')
                    }}
                  />
                )}
                {this.state.isEditing && (
                  <div className="edit-comment-wrapper">
                    <MentionTagInput
                      handleText={this.handleChange}
                      value={this.state.editedComment}
                      ref="mentionTagInput"
                      className="post_input commentPost"
                      isEdit={true}
                      // toggleMobileNav={!this.props.isDesktop ? this.props.toggleMobileNav : null}
                    />
                    <div className="edit-comment-action-buttons">
                      <button
                        className="btn leaf-btn green m-r5"
                        disabled={
                          this.state.editedCommentDescription === '' ||
                          this.state.fetching ||
                          !this.state.isChanged
                        }
                        onClick={() => this._edit()}
                      >
                        Save Changes
                      </button>
                      <button
                        className="btn leaf-btn gray hollow"
                        disabled={this.state.fetching}
                        onClick={() => this._closeEdit()}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="comment-reactions-replies-wrapper d-flex">
                <GetCommentLike
                  active={userActiveReaction}
                  handleLike={this.handleLike}
                  toggleLikesModal={this.toggleLikesModal}
                  likesForComment={this.props.likesForComment}
                  id={this.props.commentId}
                  recentlyReacted={
                    this.props.recentlyReacted
                      ? this.props.recentlyReacted[this.props.commentId]
                      : null
                  }
                  recentlyUnliked={this.props.unlikedItems}
                  isPublicFeed={this.props.isPublicFeed}
                />
                {this.props.isEdited && (
                  <div className="m-l12 m-r12 divider">|</div>
                )}
                {this.props.isEdited && (
                  <div className="comment-edited-label">Edited</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _getPopoverComment = (commentId, location) => {
    if (!this.state.isUserRecentlyDeleted) {
      this.setState({ queuedPoppingCommentId: commentId })
      setTimeout(() => {
        if (this.state.queuedPoppingCommentId === commentId) {
          this.setState({
            currentPopover: {
              commentId: commentId,
              hoverLocation: location,
            },
          });
        }
      }, 3000);
    }
  };

  _clearCurrentPopover = () => {
    this.setState({ queuedPoppingCommentId: null });
    setTimeout(() => {
      if (!this.state.onPopOver) {
        this.setState({
          currentPopover: {
            commentId: null,
            hoverLocation: null,
          },
        });
      }
    }, 1000);
  };

  getCommenterImage = (imgSrc) => {
    let avatarStyle;
    if (imgSrc) {
      avatarStyle = {
        backgroundImage: 'url(' + imgSrc + ')',
      };
      return (
        <div
          id={'Popover-' + this.props.commentId}
          onMouseLeave={this._clearCurrentPopover.bind(this)}
          onMouseEnter={this._getPopoverComment.bind(
            this,
            this.props.commentId,
            'commenterImage'
          )}
          className="commenter_image"
          style={avatarStyle}
        />
      );
    } else {
      avatarStyle = {
        backgroundImage: 'url(' + defaultAvatar + ')',
      };
      return (
        <div
          id={'Popover-' + this.props.commentId}
          onMouseLeave={this._clearCurrentPopover.bind(this)}
          onMouseEnter={this._getPopoverComment.bind(
            this,
            this.props.commentId,
            'commenterImage'
          )}
          className="commenter_image"
          style={avatarStyle}
        />
      );
    }
  };

  getLike = (activeLike) => {
    let cx = classnames('far fa-thumbs-up font_18 post_action_icon m-l10', {
      font_blue: activeLike,
    });

    return (
      <span className="post_action_button" onClick={this.props.addLike}>
        <i className={cx} />
      </span>
    );
  };
}

const mapStateToProps = function (state) {
  return {
    likeAction: state.newsFeed.likeAction,
    publicPendingAction: state.auth.publicPendingAction,
    recentlyReacted: state.newsFeed.recentlyReacted,
    unlikedItems: state.newsFeed.unlikedItems,
  };
};

const ReduxComment = connect(mapStateToProps)(Comment);

export { ReduxComment as Comment };
