import vwTypes from '../types/vanityWords';

export const initialState = {
    fetching: false,
    doNotUseWordsList: [],
};

export const vanityWords = (state = initialState, action) => {
    switch (action.type) {
        case vwTypes.FETCHING_VWL:
            return {
                ...state,
                fetching: true,
            };
        case vwTypes.FETCHING_VWL_SUCCESS:
            return {
                ...state,
                fetching: false,
                doNotUseWordsList: action.payload
            };
        case vwTypes.FETCHING_VWL_FAIL:
            return {
                ...state,
                fetching: false,
            };
        default:
            return state;
    };
};