import React from "react";
import classNames from "classnames";
import "./index.css";
import { PureComponent } from "react";
import { rawMarkup } from "./../../../../libs/textFormat"

class NonFadeOutAlert extends PureComponent {

  render() {
    let { type, message, showBrowserCompatibilityAlert, emulateSession, logoutFromEmulateSession, isProUser } = this.props;
    const alertClasses = classNames(
      "alert-top justify-content-center d-flex align-items-center",
      { "alert--message-info": type === 1 ? true : false },
      { "alert--message-sucess": type === 2 ? true : false },
      { "alert--message-warning": type === 3 ? true : false },
      { "alert--message-danger": type === 4 ? true : false }
    );

    const showAlert = (alertClasses, message) => {
      return (
        <div className="pps browserVersion">
          <div>
            <div className={`alert_bodys browserVersionWrp`}>
              <div className={`${alertClasses} ${!isProUser ? 'alert-margin-top' : ''}`}>
                <i className="fas fa-exclamation-circle alert_icon" />{" "}
                <p className="alert-message" dangerouslySetInnerHTML={rawMarkup(message.replace(/(?:\r\n|\r|\n)/g, '<br/>'))}></p>
                &nbsp;&nbsp;{emulateSession && <button type="button" style={{ cursor: "pointer" }} className="btn-info" onClick={logoutFromEmulateSession}>Logout</button>}
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <React.Fragment>
        {showBrowserCompatibilityAlert && showAlert(alertClasses, message)}
        {emulateSession && showAlert(alertClasses, 'You are on emulated session of ' + localStorage.getItem('name'))}
      </React.Fragment>
    );
  }
}

export default NonFadeOutAlert;

