import React from "react";
import classNames from "classnames";
import "./index.css";
import { Component } from "react";
import { connect } from "react-redux";
import { resendActivationEmail } from "./../../../../actions/auth";
class ActionAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }
  
  temporaryActivationPeriod = () => {
    const toDays = (1000*60*60*24);
    const now = Date.now() / toDays;
    const createdDate = Date.parse(this.props.auth.user.CREATEDAT) / toDays;
    return (7 - (Math.floor(now-createdDate)));
  }

  hideAlert = () => {
    this.setState({
      isOpen: false,
      duration : 0
    });
  };

  render() {
    const alertClasses = classNames(
      "alert-top justify-content-center d-flex align-items-center alert--message-danger",
      { "alert--message-info": this.state.type === 1 ? true : false },
      { "alert--message-sucess": this.state.type === 2 ? true : false },
      { "alert--message-warning": this.state.type === 3 ? true : false },
      { "alert--message-danger": this.state.type === 4 ? true : false }
    );
    if(this.props.showActivationBanner){
      return ( 
        <div className="pps verificationEmailWrp">
            <div>
              <div className="alert_bodys verificationEmail">
                <div className={alertClasses}>
                  <i className="fas fa-exclamation-circle alert_icon" />{" "}
                  <p className="alert-message ">
                  We have sent you a verification email. Please verify your email address within {this.temporaryActivationPeriod()} day{this.temporaryActivationPeriod() !== 1 && 's'} to keep access to the account.
                  </p>
                  <button disabled={this.state.isResend}
                    onClick={() => {
                      this.props.dispatch(resendActivationEmail());
                    }}
                    className="alert_button"
                  >
                    RESEND
                  </button>
                </div>
              </div>
            </div>
        </div>
      );
    } else {
      return <span></span>;
    }
  }
}

export default connect(
    state => {
      return{
        auth: state.auth
      }
    }
)( ActionAlert );

