import {
    CLEAR_ACTION,
    STATUS,
    GET_CONVERSATIONS,
    GET_CONVERSATIONS_COUNT,
    EMPTY_MESSAGES,
    GET_MESSAGES,
    GET_MESSAGES_COUNT,
    GET_CONVERSATION_PARTICIPANTS,
    GOT_EXISTING_CONVERSATION_ID,
    GET_CONVERSATION_PARTICIPANT_IMG,
    SET_RECIPIENT_DETAILS,
    CLEAR_RECIPIENT_DETAILS,
    SEND_MESSAGE,
    DELETE_CONVERSATION_THREAD,
    EMPTY_MESSAGES_PARTICIPANTS,
    EMPTY_MESSAGES_COUNT,
    CHANGE_ACTIVE_SECTION,
    CHANGE_ACTIVE_CONVERSATION,
    NEW_MESSAGE_NOTIFICATION,
    UPDATE_LAST_SEEN_MSG,
    UPDATE_ACTIVE_CONVERSATION_MSG,
    UPDATE_USER_TYPING_STATUS,
    GETTING_NOTIFIED_CONV_ID_SET,
    GETTING_NOTIFIED_CONV_ID_SET_SUCCESS,
    GETTING_NOTIFIED_CONV_ID_SET_FAIL,
    GETTING_NOTIFIED_CHAT_ID_SET,
    GETTING_NOTIFIED_CHAT_ID_SET_SUCCESS,
    GETTING_NOTIFIED_CHAT_ID_SET_FAIL,
    MARK_ALL_CONVERSATIONS_READ,
    OPEN_MESSAGE,
    RESET_MESSAGE,
    REMOVE_CONNECTION_CONVERSATION,
    GETTING_UNSEEN_TOP_NAV_CONV_ID_SET,
    GETTING_UNSEEN_TOP_NAV_CONV_ID_SET_SUCCESS,
    GETTING_UNSEEN_TOP_NAV_CONV_ID_SET_FAIL,
    FILTER_DELETED_CONVERSATION,
    ADD_LAST_ACCEPTED_USER_MESSAGE,
    GET_WARNING_DISCLAIMER_TERMS,
    GET_WARNING_DISCLAIMER_TEXT
} from '../types/message-v2';
import {
    orderConversations,
    deleteSpecificConversation,
    seenConversation,
    removeNotifiedConversationId,
    removeUnSeenTopNavConversationId
} from "./../libs/textFormat";
import { MESSAGE_SECTION_TYPES } from './../types/common';

export const initialState = {
    action: 0,
    gettingConversations: false,
    gettingMoreConversations: false,
    conversations: [],
    conversationsCount: 0,
    gettingMessages: false,
    gettingMoreMessages: false,
    messagesCount: 0,
    messages: [],
    participants: [],
    existingConversationId: null,
    senderImage: '',
    isSenderActive: false,
    activeSection: MESSAGE_SECTION_TYPES.All.id,
    activeConversation: null,
    notifiedConversationIdSet: [],
    notifiedChatIdSet: [],
    topNavUnSeenConvIdSet: [],
    isTyping: false,
    setMessage: {},
    lastReactedRequest: null,
    lastDeletedConversation: null,
    conversationToDelete: null,
    warningDisclaimerTerms: [],
    warningDisclaimerText: null
};

export const messages = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_ACTION:
            return {
                ...state,
                action: 0
            };

        case CHANGE_ACTIVE_SECTION:
            return {
                ...state,
                activeSection: action.activeSection,
                gettingMessages: false,
                gettingMoreMessages: false
            };

        case CHANGE_ACTIVE_CONVERSATION:
            return {
                ...state,
                activeConversation: action.activeConversation ? action.activeConversation : null,
                senderImage: '',
                isTyping: false
            };

        // fetch conversations
        case GET_CONVERSATIONS + STATUS.FETCHING:
            return {
                ...state,
                gettingConversations: action.shouldReset,
                gettingMoreConversations: !action.shouldReset,
                action: GET_CONVERSATIONS + STATUS.FETCHING,
            };
        case GET_CONVERSATIONS + STATUS.SUCCESS:
            return {
                ...state,
                gettingConversations: false,
                gettingMoreConversations: false,
                conversations: action.shouldReset ? action.conversations : state.conversations.concat(action.conversations),
                action: GET_CONVERSATIONS + STATUS.SUCCESS,
            };
        case GET_CONVERSATIONS + STATUS.FAIL:
            return {
                ...state,
                gettingConversations: false,
                gettingMoreConversations: false,
                action: GET_CONVERSATIONS + STATUS.FAIL,
            };

        // fetch conversation count
        case GET_CONVERSATIONS_COUNT + STATUS.FETCHING:
            return {
                ...state,
                action: GET_CONVERSATIONS_COUNT + STATUS.FETCHING
            };
        case GET_CONVERSATIONS_COUNT + STATUS.SUCCESS:
            return {
                ...state,
                action: GET_CONVERSATIONS_COUNT + STATUS.SUCCESS,
                conversationsCount: action.count
            };
        case GET_CONVERSATIONS_COUNT + STATUS.FAIL:
            return {
                ...state,
                action: GET_CONVERSATIONS_COUNT + STATUS.FAIL
            };

        // clear messages
        case EMPTY_MESSAGES:
            return {
                ...state,
                messages: [],
                action: EMPTY_MESSAGES
            };

        // fetch messages
        case GET_MESSAGES + STATUS.FETCHING:
            return {
                ...state,
                gettingMessages: action.shouldReset,
                gettingMoreMessages: !action.shouldReset,
                action: GET_MESSAGES + STATUS.FETCHING
            };
        case GET_MESSAGES + STATUS.SUCCESS:
            if (action.conversationId !== state.activeConversation && action.checkId) { return state }
            return {
                ...state,
                gettingMessages: false,
                gettingMoreMessages: false,
                messages: action.shouldReset ? action.messages : state.messages.concat(action.messages),
                action: GET_MESSAGES + STATUS.SUCCESS
            };
        case GET_MESSAGES + STATUS.FAIL:
            return {
                ...state,
                gettingMessages: false,
                gettingMoreMessages: false,
                // messages: [],
                action: GET_MESSAGES + STATUS.FAIL
            };

        // fetch messages count
        case GET_MESSAGES_COUNT + STATUS.FETCHING:
            return {
                ...state,
                action: GET_MESSAGES_COUNT + STATUS.FETCHING
            };
        case GET_MESSAGES_COUNT + STATUS.SUCCESS:
            if (action.conversationId !== state.activeConversation && action.checkId) { return state }
            return {
                ...state,
                action: GET_MESSAGES_COUNT + STATUS.SUCCESS,
                messagesCount: action.count
            };
        case GET_MESSAGES_COUNT + STATUS.FAIL:
            return {
                ...state,
                action: GET_MESSAGES_COUNT + STATUS.FAIL
            };

        // clear messages count
        case EMPTY_MESSAGES_COUNT:
            return {
                ...state,
                messagesCount: 0
            };

        // get participants details
        case GET_CONVERSATION_PARTICIPANTS + STATUS.FETCHING:
            return {
                ...state,
                participants: [],
                existingConversationId: null,
                senderImage: '',
                isSenderActive: false,
                recipientId: false,
                action: GET_CONVERSATION_PARTICIPANTS + STATUS.FETCHING
            };
        case GET_CONVERSATION_PARTICIPANTS + STATUS.SUCCESS:
            return {
                ...state,
                participants: action.participants,
                senderImage: '',
                existingConversationId: null,
                action: GET_CONVERSATION_PARTICIPANTS + STATUS.SUCCESS
            };
        case GET_CONVERSATION_PARTICIPANTS + STATUS.FAIL:
            return {
                ...state,
                participants: [],
                existingConversationId: null,
                action: GET_CONVERSATION_PARTICIPANTS + STATUS.FAIL
            };
        case GOT_EXISTING_CONVERSATION_ID:
            return {
                ...state,
                existingConversationId: action.payload,
                action: GOT_EXISTING_CONVERSATION_ID
            };

        case GET_CONVERSATION_PARTICIPANT_IMG:
            return {
                ...state,
                senderImage: action.conversationId === state.activeConversation ? action.img : state.senderImage,
                action: GET_CONVERSATION_PARTICIPANT_IMG
            };
        case SET_RECIPIENT_DETAILS:
            return {
                ...state,
                isSenderActive: action.isSenderActive,
                recipientId: action.recipientId,
                action: SET_RECIPIENT_DETAILS
            };

        case CLEAR_RECIPIENT_DETAILS:
            return {
                ...state,
                isSenderActive: false,
                recipientId: false,
                action: CLEAR_RECIPIENT_DETAILS
            };

        //  clear participants
        case EMPTY_MESSAGES_PARTICIPANTS:
            return {
                ...state,
                participants: [],
                senderImage: '',
                isSenderActive: false,
            };

        // send message
        case SEND_MESSAGE + STATUS.FETCHING:
            return {
                ...state,
                action: SEND_MESSAGE + STATUS.FETCHING,
                messages: [action.newMessage, ...state.messages]
            };
        case SEND_MESSAGE + STATUS.SUCCESS:
            const orderedList = orderConversations(state.conversations, action.message._id, action.message.RECENT_MESSAGE);
            return {
                ...state,
                conversations: orderedList,
                action: SEND_MESSAGE + STATUS.SUCCESS
            };
        case SEND_MESSAGE + STATUS.FAIL:
            let newMessages = state.messages;
            if (newMessages.length > 1) {
                newMessages = [{
                    ...state.messages[0],
                    hasError: true,
                    isSending: false
                }, ...state.messages.slice(1, state.messages.length)];
            } else if (newMessages.length > 0) {
                newMessages = [{ ...newMessages[0], hasError: true, isSending: false }]
            }
            return {
                ...state,
                messages: newMessages,
                action: SEND_MESSAGE + STATUS.FAIL
            };
        case SEND_MESSAGE + STATUS.BLOCKED:
            let newMsges = state.messages;
            if (newMsges.length > 1) {
                newMsges = [{
                    ...state.messages[0],
                    hasBlocked: true,
                    isSending: false
                }, ...state.messages.slice(1, state.messages.length)];
            } else if (newMsges.length > 0) {
                newMsges = [{ ...newMsges[0], hasBlocked: true, isSending: false }]
            }
            return {
                ...state,
                messages: newMsges,
                action: SEND_MESSAGE + STATUS.BLOCKED
            };

        // delete conversation thread
        case DELETE_CONVERSATION_THREAD + STATUS.DELETING:
            return {
                ...state,
                conversationToDelete: action.conversationId,
                action: DELETE_CONVERSATION_THREAD + STATUS.DELETING
            };
        case DELETE_CONVERSATION_THREAD + STATUS.SUCCESS:
            const newConversationList = deleteSpecificConversation(state.conversations, action.conversationId);
            return {
                ...state,
                conversations: newConversationList,
                conversationsCount: state.conversationsCount - 1,
                recipientId: state.conversationToDelete === action.conversationId ? null : state.recipientId,
                conversationToDelete: null,
                action: DELETE_CONVERSATION_THREAD + STATUS.SUCCESS
            };
        case DELETE_CONVERSATION_THREAD + STATUS.FAIL:
            return {
                ...state,
                action: DELETE_CONVERSATION_THREAD + STATUS.FAIL
            };

        // filter deleted conversation from conversation list
        case FILTER_DELETED_CONVERSATION:
            const filteredConversationList = deleteSpecificConversation(state.conversations, action.conversationId);
            return {
                ...state,
                conversations: filteredConversationList,
                conversationsCount: state.conversationsCount - 1,
                action: FILTER_DELETED_CONVERSATION,
                lastDeletedConversation: action.conversationId
            };

        // update new message notification
        case NEW_MESSAGE_NOTIFICATION:
            if (state.activeSection === MESSAGE_SECTION_TYPES.All.id || state.activeSection === action.module) {
                const formattedNotifications = orderConversations(state.conversations, action.data._id, action.data.RECENT_MESSAGE, action.data);
                return {
                    ...state,
                    conversations: formattedNotifications,
                    action: NEW_MESSAGE_NOTIFICATION,
                    notifiedConversationIdSet: (state.activeConversation !== action.data._id && !state.notifiedConversationIdSet.includes(action.data._id)) ? [action.data._id, ...state.notifiedConversationIdSet] : state.notifiedConversationIdSet,
                    topNavUnSeenConvIdSet: (state.activeConversation !== action.data._id && !state.topNavUnSeenConvIdSet.includes(action.data._id)) ? [action.data._id, ...state.topNavUnSeenConvIdSet] : state.topNavUnSeenConvIdSet
                };
            } else {
                return {
                    ...state,
                    action: NEW_MESSAGE_NOTIFICATION,
                    notifiedConversationIdSet: (state.activeConversation !== action.data._id && !state.notifiedConversationIdSet.includes(action.data._id)) ? [action.data._id, ...state.notifiedConversationIdSet] : state.notifiedConversationIdSet,
                    topNavUnSeenConvIdSet: (state.activeConversation !== action.data._id && !state.topNavUnSeenConvIdSet.includes(action.data._id)) ? [action.data._id, ...state.topNavUnSeenConvIdSet] : state.topNavUnSeenConvIdSet
                };
            }
        case ADD_LAST_ACCEPTED_USER_MESSAGE:
            return {
                ...state,
                notifiedConversationIdSet: (state.activeConversation !== action.conversationId && !state.notifiedConversationIdSet.includes(action.conversationId)) ? [action.conversationId, ...state.notifiedConversationIdSet] : state.notifiedConversationIdSet,
                topNavUnSeenConvIdSet: (state.activeConversation !== action.conversationId && !state.topNavUnSeenConvIdSet.includes(action.conversationId)) ? [action.conversationId, ...state.topNavUnSeenConvIdSet] : state.topNavUnSeenConvIdSet
            }
        case UPDATE_LAST_SEEN_MSG:
            const formattedConversations = action.index !== null
                ? seenConversation(state.conversations, action.conversationId, action.index, action.loggedInUserId)
                : state.conversations;
            const newNotifiedIdSet = removeNotifiedConversationId(state.notifiedConversationIdSet, action.conversationId);
            const newUnSeenTopNavConvIdSet = removeUnSeenTopNavConversationId(state.topNavUnSeenConvIdSet, action.conversationId);
            const newNotifiedChatIdSet = removeNotifiedConversationId(state.notifiedChatIdSet, action.conversationId);
            if (formattedConversations) {
                return {
                    ...state,
                    conversations: formattedConversations,
                    notifiedConversationIdSet: newNotifiedIdSet,
                    notifiedChatIdSet: newNotifiedChatIdSet,
                    topNavUnSeenConvIdSet: newUnSeenTopNavConvIdSet
                };
            }

            return state

        // update active conversation
        case UPDATE_ACTIVE_CONVERSATION_MSG:
            return {
                ...state,
                messages: [action.data, ...state.messages],
                messagesCount: state.messagesCount + 1,
                isTyping: false,
                action: UPDATE_ACTIVE_CONVERSATION_MSG
            };

        case UPDATE_USER_TYPING_STATUS:
            if (action.conversationId === state.activeConversation) {
                return {
                    ...state,
                    isTyping: action.status
                }
            } else {
                return { ...state }
            }

        case GETTING_NOTIFIED_CONV_ID_SET:
            return {
                ...state,
                action: GETTING_NOTIFIED_CONV_ID_SET
            };
        case GETTING_NOTIFIED_CONV_ID_SET_SUCCESS:
            return {
                ...state,
                action: GETTING_NOTIFIED_CONV_ID_SET_SUCCESS,
                notifiedConversationIdSet: action.payload
            };
        case GETTING_NOTIFIED_CONV_ID_SET_FAIL:
            return {
                ...state,
                action: GETTING_NOTIFIED_CONV_ID_SET_FAIL
            };

        case GETTING_NOTIFIED_CHAT_ID_SET:
            return {
                ...state,
                action: GETTING_NOTIFIED_CHAT_ID_SET
            };
        case GETTING_NOTIFIED_CHAT_ID_SET_SUCCESS:
            return {
                ...state,
                action: GETTING_NOTIFIED_CHAT_ID_SET_SUCCESS,
                notifiedChatIdSet: action.payload
            };
        case GETTING_NOTIFIED_CHAT_ID_SET_FAIL:
            return {
                ...state,
                action: GETTING_NOTIFIED_CHAT_ID_SET_FAIL
            };

        // remove blue dot from conversations
        case MARK_ALL_CONVERSATIONS_READ:
            let formatedConversations = state.conversations;
            if (state.activeSection === action.module) {
                formatedConversations = formatedConversations.map(c => {
                    return { ...c, LAST_MESSAGE_SEEN: { ...c.LAST_MESSAGE_SEEN, IS_SEEN: true } }
                })
            }

            return {
                ...state,
                conversations: formatedConversations
            };

        case OPEN_MESSAGE:
            return {
                ...state,
                setMessage: action.payload,
                activeSection: action.payload.MODULE,
                activeConversation: action.payload._id,
                action: OPEN_MESSAGE
            };

        case RESET_MESSAGE:
            return {
                ...state,
                setMessage: {},
                action: CLEAR_ACTION
            };

        case REMOVE_CONNECTION_CONVERSATION:
            return {
                ...state,
                lastReactedRequest: action.data,
                action: REMOVE_CONNECTION_CONVERSATION
            };

        case GETTING_UNSEEN_TOP_NAV_CONV_ID_SET:
            return {
                ...state,
                action: GETTING_UNSEEN_TOP_NAV_CONV_ID_SET
            };
        case GETTING_UNSEEN_TOP_NAV_CONV_ID_SET_SUCCESS:
            return {
                ...state,
                action: GETTING_UNSEEN_TOP_NAV_CONV_ID_SET_SUCCESS,
                topNavUnSeenConvIdSet: action.payload
            };
        case GETTING_UNSEEN_TOP_NAV_CONV_ID_SET_FAIL:
            return {
                ...state,
                action: GETTING_UNSEEN_TOP_NAV_CONV_ID_SET_FAIL
            };
        case GET_WARNING_DISCLAIMER_TERMS:
            return {
                ...state,
                warningDisclaimerTerms: action.payload.map(a => a.TERM)
            }
        case GET_WARNING_DISCLAIMER_TEXT:
            return {
                ...state,
                warningDisclaimerText: action.payload
            }

        default:
            return state
    }
};