module.exports = {
    CLEAR_ACTION: 'CLEAR_ACTION',

    FETCHING_SEARCH_INDIVIDUALS: "FETCHING_SEARCH_INDIVIDUALS",
    FETCHING_SEARCH_INDIVIDUALS_SUCCESS: "FETCHING_SEARCH_INDIVIDUALS_SUCCESS",
    FETCHING_SEARCH_INDIVIDUALS_FAIL: "FETCHING_SEARCH_INDIVIDUALS_FAIL",

    SET_SEARCH_INDIVIDUALS_IMAGES: 'SET_SEARCH_INDIVIDUALS_IMAGES',

    FETCHING_PEOPLE_PROFILE_IMAGES_SUCCESS: "FETCHING_PEOPLE_PROFILE_IMAGES_SUCCESS",
    FETCHING_COMPANY_PROFILE_IMAGES_SUCCESS: "FETCHING_COMPANY_PROFILE_IMAGES_SUCCESS",
    FETCHING_INVESTOR_PROFILE_IMAGES_SUCCESS: "FETCHING_INVESTOR_PROFILE_IMAGES_SUCCESS",

    FETCHING_SEARCH_COMPANIES: "FETCHING_SEARCH_COMPANIES",
    FETCHING_SEARCH_COMPANIES_SUCCESS: "FETCHING_SEARCH_COMPANIES_SUCCESS",
    FETCHING_SEARCH_COMPANIES_FAIL: "FETCHING_SEARCH_COMPANIES_FAIL",

    FETCHING_MORE_RESULTS: "FETCHING_MORE_RESULTS",
    FETCHING_MORE_RESULTS_SUCCESS: "FETCHING_MORE_RESULTS_SUCCESS",
    FETCHING_MORE_RESULTS_FAIL: "FETCHING_MORE_RESULTS_FAIL",


    FETCHING_SEARCH_COUNT_INDIVIDUALS: 'FETCHING_SEARCH_COUNT_INDIVIDUALS',
    FETCHING_SEARCH_COUNT_INDIVIDUALS_SUCCESS: 'FETCHING_SEARCH_COUNT_INDIVIDUALS_SUCCESS',
    FETCHING_SEARCH_COUNT_INDIVIDUALS_FAIL: 'FETCHING_SEARCH_COUNT_INDIVIDUALS_FAIL',

    FETCHING_GENERAL_SEARCH: 'FETCHING_GENERAL_SEARCH',
    FETCHING_GENERAL_SEARCH_SUCCESS: 'FETCHING_GENERAL_SEARCH_SUCCESS',
    FETCHING_GENERAL_SEARCH_FAIL: 'FETCHING_GENERAL_SEARCH_FAIL',

    SETTING_INVESTMENT_TYPES: 'SETTING_INVESTMENT_TYPES',
    CLEAR_INVESTMENT_TYPES: 'CLEAR_INVESTMENT_TYPES',

    SET_SEARCH_TYPES: 'SET_SEARCH_TYPES',
    CLEAR_SEARCH_TYPES: 'CLEAR_SEARCH_TYPES',

    FETCHING_TOP_SEARCH_COUNT: 'FETCHING_TOP_SEARCH_COUNT',
    FETCH_TOP_SEARCH_COUNT_SUCCESS: 'FETCH_TOP_SEARCH_COUNT_SUCCESS',
    FETCH_TOP_SEARCH_COUNT_FAIL: 'FETCH_TOP_SEARCH_COUNT_FAIL',

    CLEAR_SEARCH_STATES: 'CLEAR_SEARCH_STATES',
    CLEAR_SEARCH_TEXT: 'CLEAR_SEARCH_TEXT',
    CLEAR_TOP_SEARCH: 'CLEAR_TOP_SEARCH',
    CLEAR_TOP_SEARCH_RESULTS: 'CLEAR_TOP_SEARCH_RESULTS',
    CLEAR_TOP_SEARCH_COUNT: 'CLEAR_TOP_SEARCH_COUNT',

    V2_CLEAR_SEARCH_RESULTS: 'V2_CLEAR_SEARCH_RESULTS',

    V2_ADVANCED_SEARCH: 'V2_ADVANCED_SEARCH',
    V2_ADVANCED_SEARCH_SUCCESS: 'V2_ADVANCED_SEARCH_SUCCESS',
    V2_ADVANCED_SEARCH_FAIL: 'V2_ADVANCED_SEARCH_FAIL',

    V2_ADVANCED_SEARCH_COUNT: 'V2_ADVANCED_SEARCH_COUNT',
    V2_ADVANCED_SEARCH_COUNT_SUCCESS: 'V2_ADVANCED_SEARCH_COUNT_SUCCESS',
    V2_ADVANCED_SEARCH_COUNT_FAIL: 'V2_ADVANCED_SEARCH_COUNT_FAIL',

};