import React, { Component } from "react";
import { Container, Col, Row } from './../common';
import { Link } from 'react-router-dom';
import md5 from 'md5';
import './index.css';

export default class Breadcrumb extends Component {

    clearStorage = () => {
        if (localStorage.getItem('selectedCategories')) {
            localStorage.removeItem('selectedCategories');
        }
    }
    render() {

        let locations = this.props.locations || [];
        let items = locations.map(item => {
            return item.link === '' ?
                <span key={md5(item.name)} className="breadItem" >{item.name}</span> :
                <span key={md5(item.name)} className="breadItem tagLink"><Link onClick={this.clearStorage} to={`${item.link}`}>{item.name}</Link></span>

        });

        return (
            <div className="breadcrumbWrp">
                <Container normal={true}>
                    <Row customClass={"row align-items-baseline"}>
                        <Col className="col-md-4 breadContainer text-left">
                            {items}
                        </Col>
                        <Col className="col-md-4 breadContainer text-center">
                            <h4 className="page-title">{this.props.pagetitle}</h4>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}