/*
import React, { Component } from "react";
import "./index.css";
import classname from "classnames";
import { COMPANY_TYPE } from "../../../types/data";

class SearchDropdown extends Component {

  constructor(props) {
    super(props);
    this.state = {
      popupVisible: false,
      defaultData: this.props.data || COMPANY_TYPE,
      data: this.props.data || COMPANY_TYPE,
      selectedItem: {
        value: this.props.selectedValue || "",
        label: ''
      },
      prevItem: {
        value: '',
        label: ''
      }
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.searchDropdownInput = React.createRef();
  };

  _setInputFocus = () => {
    this.searchDropdownInput.current.focus();

    if (!this.state.popupVisible) {
      console.log('popupVisible invisible');
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
      console.log('event listner removed')
    }

    console.log(this.state.popupVisible);

    this.setState(prevState => ({
      popupVisible: !prevState.popupVisible
    }));
  };

  setDefault = () => {
    this.setState({
      selectedItem: {
        value: '',
        label: ''
      },
      prevItem: {
        value: '',
        label: ''
      }
    })
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.isMessageSearch) {
      if (nextProps.data !== this.props.data) {
        this.setState({ defaultData: nextProps.data, data: nextProps.data });
      }
    } else {
      if (nextProps.data !== this.props.data && !this.state.popupVisible) {
        this.setState({ defaultData: nextProps.data, data: nextProps.data });
      }
      if (nextProps.data !== this.props.data && this.props.investorStep) {
        this.setState({ defaultData: nextProps.data, data: nextProps.data });
      }
    }

    if (this.props.isAdminSearching && nextProps.data !== this.props.data) {
      this.setState({ defaultData: nextProps.data, data: nextProps.data });
    }
  }

  handleClick() {
    console.log('handle click');
    if (!this.state.popupVisible) {
        console.log('handle click 1');
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
        console.log('handle click 2');
      document.removeEventListener("click", this.handleOutsideClick, false);
    }

    if (!this.props.isFocusingSet) {
        console.log('handle click 3');
      this.setState(prevState => ({
        popupVisible: !prevState.popupVisible
      }));
    }

  }

  handleOutsideClick = (e) => {
    console.log('outside click')
    if (this.node && this.node.contains && this.node.contains(e.target)) {
        console.log('outside click sss')
      return;
    }
    this.handleClick();

    if (this.props.isFocusingSet) {
      this.setState({ popupVisible: false });
    }
  }

  search = (searchable, e) => {
    if (searchable) {
      if (this.props.getValues) {
        this.props.getValues(e.target.value)
      }
      let filterItems = [];
      let item = {
        value: "",
        label: e.target.value
      };
      this.setState({ selectedItem: item, popupVisible: true });

      if (e.target.value.trim()) {

        this.state.defaultData.forEach((item, i) => {
          if (item.label.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
            filterItems.push(item);
          }
        });

        if (filterItems.length === 1 && filterItems[0].label === e.target.value && this.props.getValues) {
          this.setItem(filterItems[0])
        }

        if ((filterItems.length < 1 || this.props.liveUpdate) && this.props.onchange) {
          if (filterItems.length < 1 || this.props.liveUpdate) {
            this.props.onchange(e.target.value);
          } else {
            this.props.onchange(true);
          }
        }

        if (filterItems.length < 1) {
          this.setState({
            popupVisible: false
          });
          document.removeEventListener("click", this.handleOutsideClick, false);
          if (this.props.noSuggestions) {
            this.props.noSuggestions(true)
          }
        } else {
          if (this.props.noSuggestions) {
            this.props.noSuggestions(false)
          }
        }
        this.setState({ data: filterItems });
      } else {
        if (this.props.isEmpty) {
          this.props.isEmpty(true);
        }
        this.setState({ data: this.state.defaultData });
      }
    } else {
      const key = e.keyCode || e.charCode;
      if ((key === 8 || key === 46) && this.props.onRemove) {
        this.props.onRemove();
        this.setDefault();
      }
    }

    return;
  };

  setItem = (item) => {
    this.setState({ selectedItem: item });
    this.handleClick();
    if (this.props.submit) {
      this.props.submit(item, this.props.inputname);
    }
  };

  setValue = (item) => {
    this.setState({ selectedItem: item });
    if (this.props.submit) {
      this.props.submit(item, this.props.inputname);
    }
  };

  setEmptyOptions = () => {
    this.setState({
      data: []
    })
  };

  _clearDefaultData = () => {
    this.setState({
      defaultData: []
    });
  }

  render() {

    let value = this.state.selectedItem.label;

    if (this.props.selectedValue && this.state.selectedItem.label === '') {
      this.state.defaultData.forEach((item, i) => {
        if (item.value === this.props.selectedValue) {
          value = item.label;
        }
      });
    }

    let dropdown = classname(
      "dropdown_body ",
      this.props.size === "small"
        ? "dropdown_body_small"
        : "dropdown_body_regular",
      this.state.popupVisible === true ? "media_active" : "media_hide"
    );

    let dropdownItem = classname(
      this.props.size === "small"
        ? "dropdown_item dropdown_item_small"
        : "dropdown_item"
    );

    let inpitStyle = classname(
      this.props.size === "small"
        ? " matirial_custom_input matirial_custom_input_small"
        : "matirial_custom_input"
    );

    let items = '';

    if (this.props.registration === true && value !== '' && value !== null) {
      items = this.state.data.map((item, i) => {
        return (
          <div
            className={
              this.state.selectedItem.value === item.value
                ? `${dropdownItem}  dropdown_item_active`
                : dropdownItem
            }
            key={i}
            onClick={this.setItem.bind(this, item)}
          >
            {item.label}
          </div>
        );
      });
    } else if (!this.props.registration) {
      items = this.state.data.map((item, i) => {
        return (
          <div
            className={
              this.state.selectedItem.value === item.value
                ? `${dropdownItem}  dropdown_item_active`
                : dropdownItem
            }
            key={i}
            onClick={this.setItem.bind(this, item)}
          >
            {item.label}
          </div>
        );
      });
    }

    return (
      <React.Fragment>
        {this.props.search && (
          <input id={this.props.elmId ? this.props.elmId : null} type={this.props.type} className={inpitStyle} onChange={this.search.bind(this, true)} value={value} onClick={this.handleClick} required />
        )}
        {!this.props.search && (
          <input style={{caretColor: '#f3f2f7'}} ref={this.searchDropdownInput} id={this.props.elmId ? this.props.elmId : null } type={this.props.type} className={inpitStyle} onChange={this.search.bind(this, false)} onKeyUp={this.search.bind(this, false)} value={value} onClick={this.handleClick} required />
        )}
        {(items.length > 0) && <div className={dropdown} ref={node => { this.node = node }} style={{ zIndex: 9999 }}>
          {items}
        </div>}
      </React.Fragment>
    );
  }
}

SearchDropdown.defaultProps = {
  type: "text",
  size: null
};

export { SearchDropdown };

*/

import React, { Component, useState, useEffect } from "react";
import "./index.css";
import classname from "classnames";
import { COMPANY_TYPE } from "../../../types/data";
import { debounced } from './../../../libs/textFormat';

class SearchDropdown extends Component {

  constructor(props) {
    super(props);
    this.state = {
      popupVisible: false,
      placeholder: "",
      defaultData: this.props.data || COMPANY_TYPE,
      data: this.props.data || COMPANY_TYPE,
      selectedItem: {
        value: this.props.selectedValue || "",
        label: ''
      },
      prevItem: {
        value: '',
        label: ''
      }
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.searchDropdownInput = React.createRef();
  }

  setDefault = (item = null) => {
    this.setState({
      selectedItem: {
        value: item ? item.value : '',
        label: item ? item.label : ''
      },
      prevItem: {
        value: '',
        label: ''
      },
      data: this.props.data
    })
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.isMessageSearch) {
      if (nextProps.data !== this.props.data) {
        this.setState({ defaultData: nextProps.data, data: nextProps.data });
      }
    } else {
      if (nextProps.data !== this.props.data && (!this.state.popupVisible || (this.props.employmentHistory && this.props.data.length === 0))) {
        this.setState({ defaultData: nextProps.data, data: nextProps.data });
      }
      if (nextProps.data !== this.props.data && this.props.investorStep) {
        this.setState({ defaultData: nextProps.data, data: nextProps.data });
      }
    }

    if (this.props.isAdminSearching && nextProps.data !== this.props.data) {
      this.setState({ defaultData: nextProps.data, data: nextProps.data });
    }

    if (this.props.isJobTitleSearch && nextProps.data !== this.props.data) {
      this.setState({ defaultData: nextProps.data, data: nextProps.data });
    }
  }

  handleClick(e = null) {
    if (!this.state.popupVisible) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    if (e) {
        if ((e.target.getAttribute('class') === 'dropdown_item') || (e.target.getAttribute('class') && e.target.getAttribute('class').includes('dropdown_item'))) {
            this.setState({ popupVisible: false })
        } else {
            this.setState(prevState => ({
                popupVisible: !prevState.popupVisible,
            }));
        }
    }
  }

  handleOutsideClick = (e = null) => {
    if ((this.node && this.node.contains && this.node.contains(e.target))) {
      return;
    }
    this.handleClick(e)
  };

  _toggleDropdown = (status) => {
      this.setState({
          popupVisible: status
      })
  };

  _setInputFocus = () => {
    if (!this.state.popupVisible) {
      document.addEventListener("click", this.handleOutsideClick, false);
      this.searchDropdownInput.current.focus();
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
      this.searchDropdownInput.current.blur();
    }
    this.setState({
      popupVisible: !this.state.popupVisible
    });
  };

  search = (searchable, e) => {
    if (!this.state.popupVisible) {
      this.handleClick(e)
    }
    if (searchable) {
      if (this.props.getValues) {
        this.props.getValues(e.target.value)
      }
      let filterItems = [];
      let item = {
        value: "",
        label: e.target.value
      };
      this.setState({ selectedItem: item });

      if (e.target.value.trim()) {
        this.state.defaultData.forEach((item, i) => {
            if(this.props.searchModeStart){
                if( item.label.toLowerCase().startsWith(e.target.value.toLowerCase())){
                    filterItems.push(item);
                }
            } else {
                if (item.label.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
                    filterItems.push(item);
                }
            }
        });

        if (filterItems.length === 1 && filterItems[0].label === e.target.value && this.props.getValues) {
          this.setItem(filterItems[0])
        }

        if ((filterItems.length < 1 || this.props.liveUpdate) && this.props.onchange) {
          if (filterItems.length < 1 || this.props.liveUpdate) {
            this.props.onchange(e.target.value);
          } else {
            this.props.onchange(true);
          }
        }

        if (filterItems.length < 1) {
          this.setState({
            popupVisible: false
          });
          document.removeEventListener("click", this.handleOutsideClick, false); /*LEAF_1698 - added to fix dropdown auto open*/
          if (this.props.noSuggestions) {
            this.props.noSuggestions(true)
          }
        } else {
          if (this.props.noSuggestions) {
            this.props.noSuggestions(false)
          }
        }

        this.setState({ data: filterItems });
      } else {
        if (this.props.isEmpty) {
          this.props.isEmpty(true);
        }
        this.setState({ data: this.state.defaultData });
      }
    } else {
      const key = e.keyCode || e.charCode;
      if ((key === 8 || key === 46) && this.props.onRemove) {
        this.props.onRemove();
        this.setDefault();
      }
    }

    return;
  };

  setItem = (item, e) => {
    this.setState({ selectedItem: item, popupVisible: false });
    // this.handleClick();
    this.handleOutsideClick(e);
    if (this.props.submit) {
      this.props.submit(item, this.props.inputname);
    }
  };

  setValue = (item) => {
    this.setState({ selectedItem: item });
    if (this.props.submit) {
      this.props.submit(item, this.props.inputname);
    }
  };

  setEmptyOptions = () => {
    this.setState({
      data: []
    })
  };

  render() {
    let value = this.state.selectedItem.label;

    if ((this.props.selectedValue || this.props.selectedValue === 0) && this.state.selectedItem.label === '') {
      this.state.defaultData.forEach((item, i) => {
        if (item.value === this.props.selectedValue) {
          value = item.label;
        }
      });
    }

    let dropdown = classname(
      "dropdown_body ",
      this.props.size === "small"
        ? "dropdown_body_small"
        : "dropdown_body_regular",
      this.state.popupVisible === true ? "media_active" : "media_hide"
    );

    let dropdownItem = classname(
      this.props.size === "small"
        ? "dropdown_item dropdown_item_small"
        : "dropdown_item"
    );

    let inpitStyle = classname(
      this.props.size === "small"
        ? ` matirial_custom_input matirial_custom_input_small ${this.state.selectedItem.value === '' && (this.props.hidePh || this.props.readOnly || !this.props.search) ? '' : 'hide-ph'}`
        : `matirial_custom_input ${this.state.selectedItem.value === '' && (this.props.hidePh || this.props.readOnly || !this.props.search) ? '' : ' hide-ph'}`
      );

    let items = '';

    if (this.props.registration === true && value !== '' && value !== null) {
      items = this.state.data.map((item, i) => {
        return (
          <div
            className={
              this.state.selectedItem.value === item.value
                ? `${dropdownItem}  dropdown_item_active`
                : dropdownItem
            }
            key={i}
            onClick={(e) => this.setItem.bind(this, item, e)}
          >
            {item.label}
          </div>
        );
      });
    } else if (!this.props.registration) {
      items = this.state.data.map((item, i) => {
        return (
          <div
            className={
              this.state.selectedItem.value === item.value
                ? `${dropdownItem}  dropdown_item_active`
                : dropdownItem
            }
            key={i}
            onClick={this.setItem.bind(this, item)}
          >
            {this.props.upperCaseLabel ? item.label.toUpperCase().replace(' AND ', ' and ').replace(' OR ', ' or ') : item.label}
          </div>
        );
      });
    }

    // let items = this.state.data.map((item, i) => {
    //   return (
    //     <div
    //       className={
    //         this.state.selectedItem.value === item.value
    //           ? `${dropdownItem}  dropdown_item_active`
    //           : dropdownItem
    //       }
    //       key={i}
    //       onClick={this.setItem.bind(this, item)}
    //     >
    //       {item.label}
    //     </div>
    //   );
    // });

    return (
      <React.Fragment>
        {/*<span ref={node => { this.node = node }} >*/}
        {this.props.search && (
          <input disabled={this.props.disabled ? true : false} readOnly={this.props.readOnly} role="presentation" placeholder={this.props.placeholder} autoComplete="off" id={this.props.elmId ? this.props.elmId : null} type={this.props.type} className={inpitStyle} onChange={this.search.bind(this, true)} value={value} onClick={this.handleClick} required />
        )}
        {!this.props.search && (
          <input readOnly={true} role="presentation" placeholder={this.props.placeholder} autoComplete="off" style={{ caretColor: '#f3f2f7' }} ref={this.searchDropdownInput} id={this.props.elmId ? this.props.elmId : null} type={this.props.type} className={inpitStyle} onChange={this.search.bind(this, false)} onKeyUp={this.search.bind(this, false)} value={value} onClick={this.handleClick} required />
        )}
        {this.props.showDownArrow && <i onClick={this.handleClick} className="fas fa-caret-down"/>}
        {(!this.props.waitCharCount || (this.props.waitCharCount && value.length >= this.props.waitCharCount)) && <div className={dropdown} style={{ zIndex: 9999 }}>
          {items}
        </div>}
        {/*</span>*/}
      </React.Fragment>
    );
  }
}

SearchDropdown.defaultProps = {
  type: "text",
  size: null
};

function SearchDropdownv2(
  {
    fetchingFunction,
    submit,
    defaultData = [],
    size,
    type = 'text',
    inputname = '',
    DropDownRef = React.createRef(),
    onchange,
    isEmpty,
    liveUpdate,
    placeholder = '',
    disabled = false,
    showDropdown = true,
    awaitCharLimit = 0,
    SearchItem,
    elmId=null,
    selectedValue = null,
    onkeypress
  }) {

  const [popupVisible, setPopup] = useState(false);
  const [options, setOptions] = useState(defaultData);
  const [showNotFound, setNotFound] = useState(false);
  const [fetching, setFetching] = useState(false);
  useEffect(()=>{
    if(selectedValue && selectedValue !== null && DropDownRef.current){
        DropDownRef.current.value = selectedValue.label
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const myHandler = async () => {
    if (DropDownRef.current) {
      let searchStr = DropDownRef.current.value;
      if(onkeypress){
          onkeypress(searchStr)
      }
      if (searchStr === '' || (awaitCharLimit !== 0 && searchStr !== '' && searchStr.length < awaitCharLimit )) {
        setOptions([]);
        setNotFound(false);
        setPopup(false);
        if (isEmpty) {
          isEmpty(true);
        }
      } else {
        setFetching(true);
        setOptions([]);
        const data = await fetchingFunction(searchStr);
        setNotFound(true);
        setOptions(data);
        setFetching(false);
        if (liveUpdate && onchange) {
          if (options.length < 1 || liveUpdate) {
            onchange(searchStr);
          } else {
            onchange(true);
          }
        }
      }
    }
  };
  const dHandler = debounced(350, myHandler);

  let dropdown = classname(
    "dropdown_body ",
    size === "small"
      ? "dropdown_body_small"
      : "dropdown_body_regular",
    popupVisible && (showNotFound || fetching || options.length > 0) ? "media_active" : "media_hide"
  );

  let dropdownItem = classname(
    size === "small"
      ? "dropdown_item dropdown_item_small"
      : "dropdown_item"
  );

  let inpitStyle = classname(
    size === "small"
      ? ` matirial_custom_input matirial_custom_input_small`
      : `matirial_custom_input`
  );

  let items = '';
  items = options.map((dataItem, i) => {
    if(SearchItem){
      return SearchItem(i, dataItem, () => {
          setPopup(false);
          if (DropDownRef.current) {
              DropDownRef.current.value = dataItem.label
          }
          if (submit) {
              submit(dataItem, inputname);
              setOptions([]);
              setNotFound(false);
          }
      })
    } else {
        return (
            <div
                className={dropdownItem}
                key={i}
                onClick={e => {
                    setPopup(false);
                    if (DropDownRef.current) {
                        DropDownRef.current.value = dataItem.label
                    }
                    if (submit) {
                        submit(dataItem, inputname);
                        setOptions([]);
                        setNotFound(false);
                    }
                }}
            >
                {dataItem.label}
            </div>
        )
    }

  });


  return (
    <React.Fragment>
      <input
        role="presentation"
        autoComplete="off"
        type={type}
        placeholder={placeholder ? placeholder : ''}
        ref={DropDownRef}
        className={inpitStyle}
        required
        onSelect={e => { setPopup(true) }}
        onBlur={e => { setTimeout(e => setPopup(false), 1000) }}
        onKeyUp={dHandler}
        disabled={disabled}
        id={elmId}
      />
      {showDropdown && <div className={dropdown} style={{ zIndex: 9999 }}>
        {items}
        {options.length === 0 && showNotFound && !fetching &&
          <div className="notFound">No results found</div>
        }
        {fetching &&
          <div className="notFound">Searching...</div>
        }
      </div>}
    </React.Fragment>
  )

}

export { SearchDropdown, SearchDropdownv2 };