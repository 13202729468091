import React, { useState, useRef } from 'react';
import './index.css';
import { trackEvent } from '../../actions/eventTracking';
import { EVENT_ACTION } from '../../types/eventTracking';
import { COMMON } from '../../types/dataTrackingElementIds';

function PostOptions({
  location,
  report,
  creator,
  postingGuideLines,
  deleteThread,
  isConversation,
  allowCopyLink,
  allowRepost,
  isChatPopUp = false,
  reportSpam,
  reportUser,
  blockPost = null,
  blockUser = null,
  openEdit,
  deleteElement,
  showOnHover = false,
  allowCopyLinkWithEmailSource,
  isVideoProcessing = false,
  dispatch = null,
  user = null,
  page = null,
  section = null,
  element_id = null,
  postId = null,
  showDisconnectBtn = false,
  disconnectHandler = () => {},
  personalNewsfeed = false,
}) {
  const wrapperRef = useRef(null);
  const optionWrapperRef = useRef(null);
  window.addEventListener('click', function (e) {
    if (wrapperRef.current && wrapperRef.current.contains(e.target)) {
      setDropdownOpen(true);
    } else {
      if (
        e.target.className !== 'dropdown-item options-item copy-to-clipboard'
      ) {
        setDropdownOpen(false);
        if (showOnHover && isHovered) {
          setIsHovered(false);
        }
      }
    }
  });

  const sendEventData = () => {
    if (page && user) {
      /* data project - event trcking */
      const eventPayload = {
        user_id: user._id,
        page: page,
        section: section,
        element_type: 'ICON',
        element_id: element_id ? element_id : COMMON.ELLIPSIS,
        action_type: EVENT_ACTION.CLICK,
        post_id: postId ? postId : null,
      };
      dispatch(trackEvent(eventPayload));
      /* END : data project - event trcking */
    }
  };

  const handleMouseMove = (e) => {
    let x = e.clientX,
      y = e.clientY,
      elementMouseIsOver = document.elementFromPoint(x, y);
    if (optionWrapperRef.current && elementMouseIsOver) {
      if (
        !(
          optionWrapperRef.current &&
          optionWrapperRef.current.contains(elementMouseIsOver)
        )
      ) {
        setIsHovered(false);
      }
      window.removeEventListener('mousemove', handleMouseMove);
    } else {
      setIsHovered(false);
      window.removeEventListener('mousemove', handleMouseMove);
    }
  };

  const [isOpen, setDropdownOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  return (
    <React.Fragment>
      <div className="position-relative text-right post-options-wrapper">
        <button
          onMouseOver={() => {
            if (showOnHover) {
              setIsHovered(true);
            }
          }}
          onMouseLeave={() => {
            if (showOnHover) {
              setTimeout(() => {
                window.addEventListener('mousemove', handleMouseMove);
              }, 1000);
            }
          }}
          type="button"
          className="btn post-options-button"
          onClick={() => sendEventData()}
          ref={wrapperRef}
        >
          <i className="fas fa-ellipsis-h" />
        </button>
        {(isOpen || isHovered) && (
          <OptionsDropdown
            visibility={isOpen || isHovered}
            openReportingWindow={report}
            location={location}
            isContentOwnser={creator === localStorage.getItem('userId')}
            postingGuideLines={postingGuideLines}
            deleteThread={deleteThread}
            isConversation={isConversation}
            allowCopyLink={allowCopyLink}
            allowCopyLinkWithEmailSource={allowCopyLinkWithEmailSource}
            allowRepost={allowRepost}
            isChatPopUp={isChatPopUp}
            reportSpam={reportSpam}
            blockPost={blockPost}
            blockUser={blockUser}
            reportUser={reportUser}
            openEdit={openEdit}
            deleteElement={deleteElement}
            optionWrapperRef={optionWrapperRef}
            onMouseLeaveOptions={() => {
              if (isHovered && showOnHover) {
                setIsHovered(false);
              }
            }}
            isVideoProcessing={isVideoProcessing}
            showDisconnectBtn={showDisconnectBtn}
            disconnectHandler={disconnectHandler}
            personalNewsfeed={personalNewsfeed}
          />
        )}
      </div>
    </React.Fragment>
  );
}

function OptionsDropdown({
  visibility,
  openReportingWindow,
  isContentOwnser,
  location,
  postingGuideLines,
  deleteThread,
  isConversation,
  allowCopyLink,
  allowRepost,
  isChatPopUp,
  reportSpam,
  reportUser,
  blockUser = null,
  blockPost = null,
  openEdit,
  deleteElement,
  optionWrapperRef,
  onMouseLeaveOptions,
  allowCopyLinkWithEmailSource,
  isVideoProcessing,
  showDisconnectBtn,
  disconnectHandler,
  personalNewsfeed,
}) {
  const [copy, setIsCopy] = useState(false);
  const [copyEmailSource, setIsCopyEmailSource] = useState(false);

  return (
    <div
      ref={optionWrapperRef}
      className={`dropdown-menu droupdownMenuBox options-dropdown-wrapper ${
        visibility ? ' show' : ''
      }`}
      aria-labelledby="navbarDropdown"
      onMouseLeave={onMouseLeaveOptions}
    >
      {visibility && (
        <div id="optionsdropdown" className="options-dropdown">
          {isContentOwnser && !isChatPopUp && openEdit && (
            <span
              className={`dropdown-item options-item close-on-click ${
                isVideoProcessing ? 'text-muted option-not-allowed' : ''
              }`}
              onClick={
                !isVideoProcessing
                  ? openEdit
                  : () => console.info('edit disabled')
              }
            >
              <i className="far fa-edit" />
              Edit {location}
            </span>
          )}
          {!isChatPopUp && deleteElement && (
            <span
              className={`dropdown-item options-item close-on-click ${
                isVideoProcessing ? 'text-muted option-not-allowed' : ''
              }`}
              onClick={
                !isVideoProcessing
                  ? deleteElement
                  : () => console.info('delete disabled')
              }
            >
              <i className="far fa-trash-alt" />
              Delete {location}
            </span>
          )}
          {!isContentOwnser && !isChatPopUp && (
            <span
              className="dropdown-item options-item close-on-click"
              onClick={openReportingWindow}
            >
              <i className="far fa-flag" />
              Report {location}
            </span>
          )}
          {!isContentOwnser && blockPost && personalNewsfeed && (
            <span
              className="dropdown-item options-item close-on-click"
              onClick={blockPost}
            >
              <i className="far fa-ban" />
              Hide Post
            </span>
          )}
          {!isContentOwnser && blockUser && personalNewsfeed && (
            <span
              className="dropdown-item options-item close-on-click"
              onClick={blockUser}
            >
              <i className="fas fa-user-slash" />
              Block User
            </span>
          )}
          {reportSpam && (
            <span
              className="dropdown-item options-item close-on-click"
              onClick={reportSpam}
            >
              <i className="far fa-comment-alt-exclamation" />
              Mark As Spam
            </span>
          )}
          {showDisconnectBtn && (
            <span
              className="dropdown-item options-item close-on-click"
              onClick={disconnectHandler}
            >
              <i className="far fa-users-slash" />
              Disconnect
            </span>
          )}
          {!isContentOwnser && !isChatPopUp && (
            <span
              className="dropdown-item options-item close-on-click"
              onClick={postingGuideLines}
            >
              <i className="fa fa-book" />
              Guidelines
            </span>
          )}
          {allowCopyLink && !isChatPopUp && (
            <span
              className="dropdown-item options-item copy-to-clipboard"
              onClick={() => {
                allowCopyLink();
                setIsCopy(true);
              }}
            >
              <i className="fas fa-copy" />
              {copy ? 'Copied!' : 'Copy URL'}
            </span>
          )}
          {allowCopyLinkWithEmailSource && !isChatPopUp && (
            <span
              className="dropdown-item options-item copy-to-clipboard"
              onClick={() => {
                allowCopyLinkWithEmailSource();
                setIsCopyEmailSource(true);
              }}
            >
              <i className="fas fa-copy" />
              {copyEmailSource
                ? 'Copied With Email Source!'
                : 'Copy URL With Email Source'}
            </span>
          )}
          {allowRepost && !isChatPopUp && (
            <span
              className="dropdown-item options-item close-on-click"
              onClick={allowRepost}
            >
              <i className="fas fa-redo" />
              Re-post
            </span>
          )}
          {isConversation && !isChatPopUp && (
            <span
              className="dropdown-item options-item delete-item close-on-click"
              onClick={deleteThread}
            >
              <i className="fas fa-trash-alt" />
              Delete Conversation
            </span>
          )}
          {isChatPopUp && (
            <span
              className="dropdown-item options-item close-on-click"
              onClick={reportUser}
            >
              <i className="far fa-flag" />
              Report User
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export default PostOptions;
