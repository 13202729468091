import React from "react";
import "./index.css";

const ProgressBar = ({ propgress, color, height }) => {
  return (
    <div className="progress" style={{ height: height }}>
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: propgress + "%", background: color, height: height  }}
      />
      <span className="sr-only">60% Complete</span>
    </div>
  );
};
ProgressBar.defaultProps = {
  color: undefined,
  height: undefined
};
export { ProgressBar };
