import {CREATE_COMPANY_POPUP,COMPANY_LIST_POPUP_TEXT,COMPANY_LIST_POPUP} from './../types/common';

export function postAJob(companyList = [], push, createCompanyModel, selectCompanyModel) {
    try{
        if (companyList.length === 0) {
            push('/');
            if (createCompanyModel) {
                createCompanyModel(false, CREATE_COMPANY_POPUP.TO_POST_A_JOB)
            }
        } else if (companyList.length === 1) {
            push(`${COMPANY_LIST_POPUP_TEXT.JOB.BUTTON_LINK}${companyList[0]._id}${COMPANY_LIST_POPUP_TEXT.JOB.PARAMS}`);
        } else {
            push('/');
            selectCompanyModel(COMPANY_LIST_POPUP.POST_A_JOB)
        }
    } catch (ex) {
        console.error(ex)
    }

}
