import ImageTypes from '../types/images';

export const initialState = {
    images_L: {},
};

export const images = (state = initialState, action) => {
    switch (action.type) {
        case ImageTypes.SET_IMAGES:
            return {
                ...state,
                images_L: {
                    ...state.images_L,
                    ...action.payload
                },
                action: ImageTypes.SET_IMAGES
            };
        default:
            return state
    }
};