import React from "react";
import LogoMain from "../../assets/img/logo-main.svg";
import classname from "classnames";

class logoNav extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            scrollHeight: null,
            scrolled: false
        };
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        // window.onscroll = () => this.handleScroll();
        window.addEventListener('scroll', this.handleScroll)
    }

    handleScroll() {
        if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0) > 0) {
            this.setState({
                scrolled: true,
                scrollHeight: window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
            });
        } else {
            this.setState({ scrolled: false, scrollHeight: null });
        }
    }

    render() {

        let navLayout = classname("navbar", "justify-content-center", " fixed-top", "nav-relative", " d-flex align-items-center", this.state.scrollHeight <= 0 ? "navigation-animate-up" : "navigation-animate-down");

        return (
            <nav style={{ height: '55px', }} className={navLayout}>
                <span className="navbar-brand reg-nav-logo">
                    <img src={LogoMain} className="navigation_logo-l " alt="company logo" />
                </span>
            </nav>
        );
    }
}
export default logoNav;
