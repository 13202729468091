import React from "react";
import { connect } from 'react-redux';
import { Route, Redirect } from "react-router-dom";
import { Loading } from '../../components/common';
import * as AuthActions from './../../actions/auth';
import * as CompanyAction from './../../actions/company';
import { clearShopper } from "../../actions/managePayments"
import {MEMBER_TYPE, REGISTRATION_STEP} from './../../types/common';

import { ROUTES } from '../../config';
import {get, post} from "../../libs/apiConnector";


class PaymentRoute extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isAuthenticated: false,
            isBluesnapCustomer: false
        }
    }

    componentWillMount() {
        if(localStorage.getItem('token')){
            this.props.dispatch(AuthActions.initlogin(localStorage.getItem('token')));
        }
    }

    _isAdminAndBluesnapCustomer = (companyId) => {
        get(`${ROUTES.CHECK_COMPANY_ADMIN}${companyId}`)
            .then(cresponse => {
                if (cresponse.data && cresponse.data.data._id) {
                    get(`${ROUTES.IS_BLUESNAP_CUSTOMER}${companyId}`)
                        .then(response => {
                            if (response.data.data.length > 0) {
                                this.props.dispatch(CompanyAction.bluesnapCustomer());
                                this.setState({
                                    loading: false,
                                    isAuthenticated: true,
                                    isBluesnapCustomer: true
                                });
                            } else {
                                this.setState({
                                    loading: false,
                                    isAuthenticated: true,
                                    isBluesnapCustomer: false
                                });
                            }
                        })
                        .catch((error) => {
                            this.setState({
                                loading: false,
                                isAuthenticated: false,
                                isBluesnapCustomer: false
                            });
                        });
                } else {
                    this.setState({
                        loading: false,
                        isAuthenticated: false,
                        isBluesnapCustomer: false
                    });
                }
            })
            .catch((exception) => { // server does not response
                this.setState({
                    loading: false,
                    isAuthenticated: false,
                    isBluesnapCustomer: false
                });
            });
    }

    componentDidMount() {
        // 1 = Investor, 2 = Professional, 3 = Admin
        post(`${ROUTES.USERDETAILS}`, {}).then(response => {
            if (response.data.code === 200) {
                let user = response.data.data;
                if (!user) { // user does not exisit
                    this.setState({
                        loading: false,
                        isAuthenticated: false,
                    });
                } else if (user.MEMBER_TYPE === MEMBER_TYPE.PROFESSIONAL && user.REGISTRATION_STEP === REGISTRATION_STEP.PROFESSIONAL_STEP_03) { // professional user is ready to navigate
                    this._isAdminAndBluesnapCustomer(this.props.computedMatch.params.companyId);
                } else if (user.MEMBER_TYPE === MEMBER_TYPE.INVESTOR && user.REGISTRATION_STEP === REGISTRATION_STEP.INVESTOR_STEP_04) { // investor user is ready to navigate
                    this._isAdminAndBluesnapCustomer(this.props.computedMatch.params.companyId);
                } else if (user.MEMBER_TYPE === MEMBER_TYPE.ADMIN) { // admin user
                    this._isAdminAndBluesnapCustomer(this.props.computedMatch.params.companyId);
                } else { // authentication fail, user does not have priviledges to navigate
                    this.setState({
                        loading: false,
                        isAuthenticated: false,
                    });
                }
            } else { // invalid token or server authenication fail
                this.setState({
                    loading: false,
                    isAuthenticated: false,
                });
            }
        }).catch((exception) => { // server does not response
            this.setState({
                loading: false,
                isAuthenticated: false,
            });
        });
    }

    componentWillUnmount() {
        this.props.dispatch(clearShopper())
    }

    render() {
        const { component: Component, ...rest } = this.props;
        if (this.state.loading) { // wait until authenticating the user token
            return <Loading />;
        } else {
            return (
                <Route {...rest} render={props => (
                    <div>
                        {(!this.state.isAuthenticated || !this.state.isBluesnapCustomer) && <Redirect to={`/`} />}
                        
                        {this.state.isBluesnapCustomer && <Component {...this.props} />}
                    </div>
                )}
                />
            )
        }
    }

};

export default connect(state => {
    return {
        state
    };

})(PaymentRoute);
