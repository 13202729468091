import React, { Component, useEffect } from 'react';
import autosize from "autosize";
class AnimatedTextArea extends Component {

  componentDidMount() {
    // this.textarea.focus();
    autosize(this.textarea);
  }

  setFocus = () => {
    this.textarea.focus()
  };

  render() {
    return (
      <textarea
          value={this.props.value}
          maxLength={this.props.maxLength}
          id={this.props.elmId ? this.props.elmId : null}
          row={this.props.row}
          rows={this.props.rows}
          ref={c => (this.textarea = c)}
          type={this.props.type}
          placeholder={this.props.placeholder}
          className={this.props.className ? this.props.className : "matirial_custom_input"}
          style={{minHeight:this.props.height+'px',margintop: this.props.marginTop+'px', maxHeight: this.props.maxHeight ? this.props.maxHeight+'px' : "unset"}}
          onChange={this.props.submit}
          defaultValue={this.props.defaultval}
          required
      />
    );
  }
}
AnimatedTextArea.defaultProps = {
  type: "text",
  margintop: 18,
  height : 20,
  placeholder : null,
  defaultval : null,
  row : null

};

const MarketplaceTextBox = (props) => {

  useEffect(() => {
    autosize(props.refid.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  return(
      <textarea
          autoFocus={props.autoFocus}
          value={props.value}
          maxLength={props.maxLength}
          id={props.elmId ? props.elmId : null}
          row={props.row}
          rows={props.rows}
          type={props.type}
          placeholder={props.placeholder}
          className={props.className ? props.className : "matirial_custom_input"}
          style={{minHeight:props.height+'px',margintop: props.marginTop+'px', maxHeight: props.maxHeight ? props.maxHeight+'px' : "unset"}}
          onChange={props.submit}
          required
          ref={props.refid}
          onKeyUp={props.onkeyup ? props.onkeyup : null}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
      />
  );
};

export { AnimatedTextArea, MarketplaceTextBox };