import React, { Component } from "react";
import "./index.css";
import classname from "classnames";
import { COMPANY_TYPE } from "../../../types/data";

class SelectableSearchDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupVisible: false,
      defaultData: this.props.data || COMPANY_TYPE,
      data: this.props.data || COMPANY_TYPE,
      selectedItem: {
        value: this.props.selectedValue || "",
        label: ''
      },
      prevItem: {
        value: '',
        label: ''
      },
      itemList:[]
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  setDefault = () => {
    this.setState({
      selectedItem: {
        value: '',
        label: ''
      },
      prevItem: {
        value: '',
        label: ''
      }
    })
  };

  componentWillReceiveProps(nextProps) {

    if (this.props.isMessageSearch) {
      if (nextProps.data !== this.props.data) {
        this.setState({ defaultData: nextProps.data, data: nextProps.data });
      }
    } else {
      if (nextProps.data !== this.props.data && !this.state.popupVisible) {
        this.setState({ defaultData: nextProps.data, data: nextProps.data });
        //this.setState({ defaultData: nextProps.data });
      }
    }

    if (this.props.isAdminSearching && nextProps.data !== this.props.data) {
      this.setState({ defaultData: nextProps.data, data: nextProps.data });
    }
  }

  componentDidMount() {
    if(this.props.selectedList){
      this.setState({
          itemList: this.props.selectedList
      })
    }
  }

  handleClick() {
    if (!this.state.popupVisible) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      popupVisible: !prevState.popupVisible
    }));
  }

  handleOutsideClick = (e) => {
    if (this.node && this.node.contains && this.node.contains(e.target)) {
      return;
    }
    this.handleClick();
  }

  _formatItype(val){
    let arr = this.state.itemList;
    let index = arr.indexOf(val);
    if (index > -1) {
      arr.splice(index, 1);
      return arr;
    }else{
      return arr.concat([val])
    }
  }

  search = (searchable, e) => {
    if (searchable) {
      if (this.props.getValues) {
        this.props.getValues(e.target.value)
      }
      let filterItems = [];
      let item = {
        value: "",
        label: e.target.value
      };
      this.setState({ selectedItem: item, popupVisible: true });

      if (e.target.value.trim()) {

        this.state.defaultData.forEach((item, i) => {
          if (item.label.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
            filterItems.push(item);
          }
        });

        if (filterItems.length === 1 && filterItems[0].label === e.target.value && this.props.getValues) {
          this.setItem(filterItems[0])
        }

        if ((filterItems.length < 1 || this.props.liveUpdate)  && this.props.onchange) {
          if (filterItems.length < 1 || this.props.liveUpdate) {
            this.props.onchange(e.target.value);
          } else {
            this.props.onchange(true);
          }
        }

        if (filterItems.length < 1) {
          this.setState({
            popupVisible: false
          })
        }

        this.setState({ data: filterItems });
      } else {
        if (this.props.isEmpty) {
          this.props.isEmpty(true);
        }
        this.setState({ data: this.state.defaultData });
      }
    } else {
      const key = e.keyCode || e.charCode;
      if ((key === 8 || key === 46) && this.props.onRemove) {
        this.props.onRemove();
        this.setDefault();
      }
    }

    return;
  };

  setItem = (item) => {
    this.setState({ selectedItem: item });
    // this.handleClick();
    if (this.props.submit) {
      let selected = this._formatItype(item.value);
      this.setState({ itemList: selected});
      this.props.submit(selected, this.props.inputname);
    }
  };

  render() {
    let value = this.state.selectedItem.label;

    if (this.props.selectedValue && this.state.selectedItem.label === '') {
      this.state.defaultData.forEach((item, i) => {
        if (item.value === this.props.selectedValue) {
          value = item.label;
        }
      });
    }

    if (this.state.itemList.length > 1){
      value = this.state.itemList.length + ' types selected';
    }else if (this.state.itemList.length === 1 ){
        this.state.defaultData.forEach((item, i) => {
            if (item.value === this.state.itemList[0]) {
                value = item.label;
            }
        });
    }else{
        value = '';
    }

    let dropdown = classname(
      "dropdown_body ",
      this.props.size === "small"
        ? "dropdown_body_small"
        : "dropdown_body_regular",
      this.state.popupVisible === true ? "media_active" : "media_hide"
    );

    let dropdownItem = classname(
      this.props.size === "small"
        ? "dropdown_item dropdown_item_small d-flex align-items-center"
        : "dropdown_item d-flex align-items-center"
    );

    let inpitStyle = classname(
      this.props.size === "small"
        ? " matirial_custom_input matirial_custom_input_small"
        : "matirial_custom_input"
    );

    let items = '';
    if (this.props.registration === true && value !== '' && value !== null) {
      items = this.state.data.map((item, i) => {
        return (
          <div
            className={
              this.state.selectedItem.value === item.value
                ? `${dropdownItem}  dropdown_item_active`
                : dropdownItem
            }
            key={i}
            onClick={this.setItem.bind(this, item)}
          >
            {item.label}
          </div>
        );
      });
    } else if (!this.props.registration) {
      items = this.state.data.map((item, i) => {
        return (
          <div
            className={
              this.state.itemList.includes(item.value)
                ? `${dropdownItem}  dropdown_item_active`
                : dropdownItem
            }
            key={i}
            // onClick={this.setItem.bind(this, item)}
          >

            <input type="checkbox" className="m-r3" id={'exampleCheck' + i} checked={this.state.itemList.includes(item.value)} onChange={this.setItem.bind(this, item)}/>
            <label className="m-0" htmlFor={'exampleCheck' + i}>{item.label}</label>
          </div>
        );
      });
    }

    return (
      <React.Fragment>
        {this.props.search && (
          <input placeholder={this.props.placeholder} role="presentation" autoComplete="off" type={this.props.type} className={inpitStyle} onChange={this.search.bind(this, true)} value={value} onClick={this.handleClick} required />
        )}
        {!this.props.search && (
          <input placeholder={this.props.placeholder} role="presentation" autoComplete="off" type={this.props.type} className={inpitStyle} onChange={this.search.bind(this, false)} onKeyUp={this.search.bind(this, false)} value={value} onClick={this.handleClick} required />
        )}
        <div className={dropdown} ref={node => { this.node = node }} style={{ zIndex: 9999 }}>
          {items}
        </div>
      </React.Fragment>
    );
  }
}

SelectableSearchDropdown.defaultProps = {
  type: "text",
  size: null
};

export { SelectableSearchDropdown };
