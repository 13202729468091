import React from "react";
import { connect } from 'react-redux';
import { Route, Redirect } from "react-router-dom";
import { Loading } from '../../components/common';
import * as AuthActions from './../../actions/auth';
import * as DataRoomActions from './../../actions/dataRoom';
import { clearCards } from "../../actions/managePayments";
// import { SUBSCRIPTION_MODULES } from "./../../types/common";
import {MEMBER_TYPE, REGISTRATION_STEP} from './../../types/common';

import { ROUTES } from '../../config';
import {get, post} from "../../libs/apiConnector";


class PaidRoute extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isAuthenticated: false,
            isSubscribed: false,
            isExpired: false
        }
    }

    componentWillMount() {
        if (localStorage.getItem('token')) {
            this.props.dispatch(AuthActions.initlogin(localStorage.getItem('token')));
        }
    }

    _isAdminAndPaid = (companyId, moduleId) => {
        get(`${ROUTES.CHECK_COMPANY_ADMIN}${companyId}`)
            .then(cresponse => {
                if (cresponse.data && cresponse.data.data._id) {
                    /*commented since we are removing fundraising section from paid routes*/
                    /*remove paid status - start*/
                    this.props.dispatch(DataRoomActions.subscribedToFundraising());
                    this.setState({
                        loading: false,
                        isAuthenticated: true,
                        isSubscribed: true,
                        isExpired: false
                    });
                    /*remove paid status - end*/

                    // axios.get(`${ROUTES.IS_PAID}${companyId}/${moduleId}`, { headers: { Authorization: localStorage.getItem('token') } })
                    //     .then(response => {
                    //         if (response.data.data.length === 0) {
                    //             this.setState({
                    //                 loading: false,
                    //                 isAuthenticated: true,
                    //                 isSubscribed: false,
                    //                 isExpired: false
                    //             });
                    //         } else {
                    //             let count = 0;
                    //             let isSubscribed = false;
                    //             let isExpired = false;
                    //
                    //             response.data.data.forEach((item, i) => {
                    //                 count++;
                    //                 if (item.STATUS === 1 || item.STATUS === 2) {
                    //                     isSubscribed = true;
                    //                 } else if (item.STATUS === 3) {
                    //                     isExpired = true
                    //                 }
                    //
                    //                 if (count === response.data.data.length) {
                    //                     if (isSubscribed === true) {
                    //                         if (moduleId === SUBSCRIPTION_MODULES.EVENT) {
                    //                             this.props.dispatch(EventActions.subscribedToEvent(cresponse.data.data));
                    //                         } else if (moduleId === SUBSCRIPTION_MODULES.FUNDRAISING) {
                    //                             this.props.dispatch(DataRoomActions.subscribedToFundraising());
                    //                         }
                    //                         this.setState({
                    //                             loading: false,
                    //                             isAuthenticated: true,
                    //                             isSubscribed: true,
                    //                             isExpired: false
                    //                         });
                    //                     } else if (isExpired === true) {
                    //                         if (moduleId === SUBSCRIPTION_MODULES.EVENT) {
                    //                             this.props.dispatch(EventActions.eventSubscriptionExpired(cresponse.data.data));
                    //                         } else if (moduleId === SUBSCRIPTION_MODULES.FUNDRAISING) {
                    //                             this.props.dispatch(DataRoomActions.fundraisingSubscriptionExpired());
                    //                         }
                    //                         this.setState({
                    //                             loading: false,
                    //                             isAuthenticated: true,
                    //                             isSubscribed: false,
                    //                             isExpired: true
                    //                         });
                    //                     } else {
                    //                         this.setState({
                    //                             loading: false,
                    //                             isAuthenticated: true,
                    //                             isSubscribed: false,
                    //                             isExpired: false
                    //                         });
                    //                     }
                    //                 }
                    //             });
                    //         }
                    //     })
                    //     .catch((error) => {
                    //         this.setState({
                    //             loading: false,
                    //             isAuthenticated: false,
                    //             isSubscribed: false,
                    //             isExpired: false
                    //         });
                    //     });
                } else {
                    this.setState({
                        loading: false,
                        isAuthenticated: false,
                        isSubscribed: false,
                        isExpired: false
                    });
                }
            })
            .catch((exception) => { // server does not response
                this.setState({
                    loading: false,
                    isAuthenticated: false,
                    isSubscribed: false,
                    isExpired: false
                });
            });
    }

    componentWillUnmount() {
        this.props.dispatch(DataRoomActions.clearDataRoomReducer());
        this.props.dispatch(clearCards())
    }

    componentDidMount() {
        // 1 = Investor, 2 = Professional, 3 = Admin
        post(`${ROUTES.USERDETAILS}`, {}).then(response => {
            if (response.data.code === 200) {
                let user = response.data.data;
                if (!user) { // user does not exisit
                    this.setState({
                        loading: false,
                        isAuthenticated: false,
                    });
                } else if (user.MEMBER_TYPE === MEMBER_TYPE.INVESTOR && user.REGISTRATION_STEP === REGISTRATION_STEP.INVESTOR_STEP_04) {
                    this._isAdminAndPaid(this.props.computedMatch.params.companyId, this.props.module);
                } else if (user.MEMBER_TYPE === MEMBER_TYPE.PROFESSIONAL && user.REGISTRATION_STEP === REGISTRATION_STEP.PROFESSIONAL_STEP_03) {
                    this._isAdminAndPaid(this.props.computedMatch.params.companyId, this.props.module);
                } else if (user.MEMBER_TYPE === MEMBER_TYPE.ADMIN) { // admin user
                    this._isAdminAndPaid(this.props.computedMatch.params.companyId, this.props.module);
                } else { // authentication fail, user does not have priviledges to navigate
                    this.setState({
                        loading: false,
                        isAuthenticated: false,
                    });
                }
            } else { // invalid token or server authenication fail
                this.setState({
                    loading: false,
                    isAuthenticated: false,
                });
            }
        }).catch((exception) => { // server does not response
            this.setState({
                loading: false,
                isAuthenticated: false,
            });
        });
    }

    render() {
        const { component: Component, ...rest } = this.props;
        if (this.state.loading) { // wait until authenticating the user token
            return <Loading />;
        } else {
            return (
                <Route {...rest} render={props => (
                    <div>
                        {!this.state.isAuthenticated && <Redirect to={`/`} />}
                        <Component {...this.props} isSubscribed={this.state.isSubscribed} isExpired={this.state.isExpired} />
                    </div>
                )}
                />
            )
        }
    }

};

export default connect(state => {
    return {
        state
    };
})(PaidRoute);
