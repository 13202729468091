import React, { Component, useState } from "react";
import classnames from "classnames";
import { PUBLIC_PENDING_ACTIONS, SHARE_ACTION } from '../../../types/common';
import "./styles.css";
import "../../../styles/leafwire-fonts.css";
import { removePublicAction } from '../../../actions/auth';
import { PostShareOptionsPopup } from "../../postShareOptionsPopup";
import { ROUTES } from '../../../config';
import { post } from "../../../libs/apiConnector";
import { ReactionsPopup } from "./../../reactions/reactionsPopup";
import { USER_EMOTION_TYPES } from './../../../types/common';
import likeReaction from "./../../../assets/img/reactions/like.png";
import likeReactionHollow from "./../../../assets/img/reactions/likeHollow.svg";
import loveReaction from "./../../../assets/img/reactions/love.png";
import hahaReaction from "./../../../assets/img/reactions/haha.png";
import sadReaction from "./../../../assets/img/reactions/sad.png";
import angryReaction from "./../../../assets/img/reactions/angry.png";
import insightfulReaction from "./../../../assets/img/reactions/insightful.png";
import wowReaction from "./../../../assets/img/reactions/wow.png";
import { isMobile } from "../../../libs/commonFunctions"
import { trackEvent } from './../../../actions/eventTracking';
import { EVENT_ACTION } from './../../../types/eventTracking';
import { POST } from './../../../types/dataTrackingElementIds';

const GetLike = ({ active, id, handleLike, publicFeed = false }) => {

  const [reactionsPopup, setReactionsPopup] = useState(false);
  const [loading, setLoading] = useState(false); // to avoid accidentally open reaction popup after reacting
  const isMobileView = isMobile();
  let mobileCounter = 0;
  let myReaction = active[0].active === USER_EMOTION_TYPES.LIKE
    ? { text: 'Like', className: 'like', img: likeReaction }
    : active[0].active === USER_EMOTION_TYPES.HEART
      ? { text: 'Love', className: 'love', img: loveReaction }
      : active[0].active === USER_EMOTION_TYPES.INSIGHTFUL
        ? { text: 'Insightful', className: 'insightful', img: insightfulReaction }
        : active[0].active === USER_EMOTION_TYPES.HAHA
          ? { text: 'Haha', className: 'haha', img: hahaReaction }
          : active[0].active === USER_EMOTION_TYPES.WOW
            ? { text: 'Wow', className: 'wow', img: wowReaction }
            : active[0].active === USER_EMOTION_TYPES.SAD
              ? { text: 'Sad', className: 'sad', img: sadReaction }
              : active[0].active === USER_EMOTION_TYPES.ANGRY
                ? { text: 'Angry', className: 'angry', img: angryReaction }
                : { text: 'Like', className: '', img: likeReactionHollow };

  // close reaction popup on click
  const onClickHandler = () => {
    setReactionsPopup(false);
    window.removeEventListener("click", onClickHandler);
    window.removeEventListener("reactionsPopup", onOtherReactionPopupOpenhandler);
    window.removeEventListener('mousemove', onMouseMoveHandler);
  };

  // close reaction popup on another reaction popup open
  const onOtherReactionPopupOpenhandler = (event) => {
    if (event.detail.id !== id) {
      setReactionsPopup(false);
      window.removeEventListener("click", onClickHandler);
      window.removeEventListener("reactionsPopup", onOtherReactionPopupOpenhandler);
      window.removeEventListener('mousemove', onMouseMoveHandler);
    }
  };

  // close reaction popup on mouse move out
  const onMouseMoveHandler = (e) => {
    if (!(document.getElementById(`user_reaction_popup_${id}`) && document.getElementById(`user_reaction_popup_${id}`).contains(e.target))) {
      setReactionsPopup(false);
      window.removeEventListener("click", onClickHandler);
      window.removeEventListener("reactionsPopup", onOtherReactionPopupOpenhandler);
      window.removeEventListener('mousemove', onMouseMoveHandler);
    }
  };

  const _react = (type) => {
    handleLike(type);
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
    if (isMobileView) {
      setReactionsPopup(false);
    }
  };

  let pl = classnames(
    `post_action_button d-flex align-items-center font_medium m-r5 ${myReaction.className}`,
    { reacted: active[0].active },
  );
  return (
    <div className="post_action_width"
      id={`user_reaction_popup_${id}`}
      onTouchStart={() => {
        if (isMobileView && !publicFeed) {
          mobileCounter = Date.now();
          setTimeout(() => {
            if (mobileCounter !== 0) {
              mobileCounter = 0;
              setReactionsPopup(true);
              window.addEventListener('reactionsPopup', onOtherReactionPopupOpenhandler);
              window.dispatchEvent(new CustomEvent("reactionsPopup", { detail: { id } }));
              setTimeout(() => window.addEventListener("click", onClickHandler), 3000);
            }
          }, 300)
        } else if (publicFeed) {
          _react(active[0].active ? USER_EMOTION_TYPES.NONE : USER_EMOTION_TYPES.LIKE)
        }
      }}
      onTouchEnd={() => {
        mobileCounter = 0;
        if (isMobileView && active[0].active && !reactionsPopup && !publicFeed) {
          console.log('mobileCounter none');
          _react(USER_EMOTION_TYPES.NONE)
        } else if (isMobileView && !reactionsPopup && !publicFeed) {
          console.log('mobileCounter like');
          _react(USER_EMOTION_TYPES.LIKE)
        }
      }}
      onContextMenu={event => {
        event.preventDefault();
        event.stopPropagation();
        return false;
      }}
      onMouseEnter={(e) => {
        if (!loading && !publicFeed && !isMobileView) {
          setReactionsPopup(true);
          window.addEventListener('reactionsPopup', onOtherReactionPopupOpenhandler);
          window.dispatchEvent(new CustomEvent("reactionsPopup", { detail: { id } }));
          window.addEventListener("click", onClickHandler);
          setTimeout(() => window.addEventListener('mousemove', onMouseMoveHandler), 2000)
        }
      }}
    >
      <span
        className={pl}
        onClick={() => {
          if (!isMobileView) {
            _react(active[0].active ? USER_EMOTION_TYPES.NONE : USER_EMOTION_TYPES.LIKE)
          }
        }}>
        <img src={myReaction.img} alt="Like" />
        <span className="font_13" id={'like_' + id}>{`${myReaction.text}`}</span>
      </span>
      {reactionsPopup && <ReactionsPopup react={_react} />}
      {/* <p className="m-l5 m-t2">{active[1].count}</p> */}
    </div>
  );
};

class PostActivities extends Component {

  constructor(props) {
    super(props);
    this.shareRef = React.createRef();
    this.state = {
      isSharePopupEnabled: false,
      linkPreviewTitle: null
    };
  }

  componentDidMount() {
    if (this.props.publicPendingAction && this.props.publicPendingAction.type && this.props.publicPendingAction.type === PUBLIC_PENDING_ACTIONS.P_LIKE) {
      let action = this.props.publicPendingAction;
      if (action.data === this.props.postId && this.props.dispatch) {
        this.props.dispatch(removePublicAction());
        this.handleLike();
      }
    }
  }

  render() {
    return (
      <div className="d-flex justify-content-around">
        {this.props.likeAction && <GetLike
          id={this.props.ID}
          active={this.props.likeAction}
          handleLike={this.handleLike}
          isLikeClicked={this.props.isLikeClicked}
          publicFeed={this.props.isPublicFeed}
        />}
        {this.props.commentAction && this.getComment(this.props.commentAction)}
        {this.getShare(this.props.shareAction)}
      </div>
    );
  }

  handleLike = (reactionType) => {
    let liked = true;
    if (this.props.likeAction[0].active) {
      liked = false;
      this.props.removeLike(this.props.postId, reactionType)
    } else {
      if (this.props.isPublicFeed) {
        this.props.openLogin(PUBLIC_PENDING_ACTIONS.P_LIKE, this.props.postId)
      } else {
        this.props.addLike(this.props.postId, reactionType)
      }
    }

    /* data project - event trcking */
    if (this.props.user && this.props.page) {
      const eventPayload = {
        user_id: this.props.user._id,
        page: this.props.page,
        section: this.props.section,
        element_type: 'SPAN',
        element_id: null,
        action_type: liked ? EVENT_ACTION.P_LIKE : EVENT_ACTION.P_DISLIKE,
        post_id: this.props.postId
      }
      this.props.dispatch(trackEvent(eventPayload));
    }
    /* END : data project - event trcking */

  };



  getComment = active => {
    let cx = classnames("far fa-comment-alt-lines comment-icon font_15 post_action_icon m-r5", { font_blue: active[0].active });
    return (
      <div
        className="post_action_width"
        onClick={this.props.likedComment.bind(this)}
      >
        <span
          className="post_action_button d-flex align-items-center font_medium"
          onClick={() => {
            /* data project - event trcking */
            if (this.props.user && this.props.page) {
              const eventPayload = {
                user_id: this.props.user._id,
                page: this.props.page,
                section: this.props.section,
                element_type: 'SPAN',
                element_id: POST.COMMENT,
                action_type: EVENT_ACTION.CLICK,
                post_id: this.props.postId
              }
              this.props.dispatch(trackEvent(eventPayload));
            }
            /* END : data project - event trcking */

            if (this.props.isPublicFeed) {
              this.props.openLogin(PUBLIC_PENDING_ACTIONS.P_COMMENTS, this.props.postId)
            } else if (this.props.getComments) {
              this.props.getComments(this.props.postId, 0, 10)
            }
          }}
        >
          {/* <span className="post_action_button d-flex align-items-center font_medium" onClick={this.props.addComment}> */}
          <span className={cx} /> <span className="font_13">Comment</span></span>
        {/* <p className="m-l5 m-t2">{active[1].count}</p> */}
      </div>
    );
  };

  _enableShareBox = status => {
    /* data project - event trcking */
    if (this.props.page) {
      const eventPayload = {
        user_id: (localStorage.getItem('userId')) ? localStorage.getItem('userId') : localStorage.getItem('openNewsfeedToken'),
        page: this.props.page,
        section: this.props.section,
        element_type: 'SPAN',
        element_id: POST.SHARE,
        action_type: EVENT_ACTION.CLICK,
        post_id: this.props.postId
      }
      this.props.dispatch(trackEvent(eventPayload));
    }
    /* END : data project - event trcking */

    window.addEventListener('click', (e) => {
      if (this.shareRef.current && this.shareRef.current.contains(e.target)) {
        let urlRegex = /(https?:\/\/[^\s]+)/g;
        let capturedUrl = urlRegex.exec(this.props.postDescription);
        if (capturedUrl !== null) {
          let url = capturedUrl[0];
          post(`${ROUTES.LINK_PREVIEW}`, { url })
            .then((response) => {
              if (response.data.code === 200) {
                response.data.data.title !== null ? this.setState({ linkPreviewTitle: response.data.data.title }) : this.setState({ linkPreviewTitle: null });
              }
              this.setState({ isSharePopupEnabled: status });
            }).catch((ex) => {
              this.setState({ isSharePopupEnabled: status });
            });
        } else {
          this.setState({ isSharePopupEnabled: status });
        }
      } else {
        this.setState({ isSharePopupEnabled: false, linkPreviewTitle: null });
      }
    });
  };

  _trackShare = platform => {
    const body = { platform: platform, postId: this.props.postId, action: SHARE_ACTION.SHARE_FROM_LEAFWIRE, openNewsfeedToken: localStorage.getItem('openNewsfeedToken') };
    post(`${ROUTES.TRACK_POST_SHARE}`, body);
  };

  getShare = active => {
    let cx = classnames("far fa-share share-icon font_15 post_action_icon m-r5", { font_blue: active[0].active });
    return (
      <div className="post_action_width">
        <span
          onClick={this._enableShareBox.bind(this, true)}
          ref={this.shareRef}
          id={'share_' + this.props.ID}
          className="post_action_button d-flex align-items-center font_medium">
          <span className={cx} />
          <span className="font_13">Share</span>
        </span>
        {this.state.isSharePopupEnabled && <PostShareOptionsPopup
          dispatch={this.props.dispatch}
          user={this.props.user}
          page={this.props.page}
          section={this.props.section}
          postId={this.props.postId}
          track={this._trackShare}
          linkPreviewTitle={this.state.linkPreviewTitle}
          isPublicFeed={this.props.isPublicFeed}
          openLogin={this.props.openLogin}
          groupId={this.props.groupId}
          openSharePost={this.props.openSharePost}
          toggleZindex={this.props.toggleZindex}
          idPrefix={'share_' + this.props.ID}
        />}
      </div>
    );
  };
}

PostActivities.defaultProps = {
  likeActive: null,
  commentActive: null,
  shareActive: null
};

export { PostActivities };