import React, { Component } from 'react';

class TagInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            focused: false,
            input: '',
            initalValues: null
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
        this.handleRemoveItem = this.handleRemoveItem.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.uniqueElements = arr => [...new Set(arr)];

    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        if (nextProps.tags && prevState.initalValues !== nextProps.initalValues) {
            return {
                items: nextProps.tags,
                initalValues: "initalValues"
            }
        }
        return null;

    }

    setTags = (tags) => {
        this.setState({
            items: tags
        })
    };

    render() {
        const styles = {

            items: {
                display: 'inline-block',
                padding: '2px 5px',
                border: '1px solid #cdcdcd',
                fontFamily: 'Helvetica, sans-serif',
                borderRadius: '5px',
                marginRight: '5px',
                cursor: 'pointer',
                fontSize: '12px'

            },

            input: {
                outline: 'none',
                border: 'none',
                fontSize: '16px',
                backgroundColor: 'transparent'
            }
        };
        return (
            <label className="matirial_custom_input">
                <ul style={{ paddingLeft: 0 + 'px' }}>
                    {this.state.items.map((item, i) =>
                        <li key={i} style={styles.items}>
                            {item}
                            <span><i onClick={this.handleRemoveItem(i, item)} className="fas fa-window-close m-l5"></i></span>
                        </li>
                    )}
                    <input
                        disabled={this.props.initalValues === "initalValues"}
                        style={styles.input}
                        value={this.state.input}
                        onChange={this.handleInputChange}
                        onKeyDown={this.handleInputKeyDown}
                        onBlur={this.handleBlur}
                    />
                </ul>
            </label>
        );
    }

    handleInputChange(evt) {
        this.setState({ input: evt.target.value });
    }

    handleInputKeyDown(evt) {
        if (evt.keyCode === 13) {
            const { value } = evt.target;
            if (value !== '') {
                this.setState(state => ({
                    items: this.state.items.includes(value) ? state.items : [...state.items, value],
                    input: ''
                }));
                if (this.props.getTags) {
                    this.props.getTags(this.uniqueElements([...this.state.items, value]));
                }
            }
        }

        if (this.state.items.length && evt.keyCode === 8 && !this.state.input.length) {
            this.setState(state => ({
                items: state.items.slice(0, state.items.length - 1)
            }));
            if (this.props.getTags) {
                this.props.getTags(this.state.items.slice(0, this.state.items.length - 1));
            }
        }
    }

    handleBlur(evt) {
        const { value } = evt.target;
        if (value !== '') {
            this.setState(state => ({
                items: this.state.items.includes(value) ? state.items : [...state.items, value],
                input: ''
            }));
            if (this.props.getTags) {
                this.props.getTags(this.uniqueElements([...this.state.items, value]));
            }
        }
    }

    handleRemoveItem(index, item) {
        return () => {
            this.setState(state => ({
                items: state.items.filter((item, i) => i !== index)
            }));
            if (this.props.getTags) {
                this.props.getTags(this.state.items.filter((item, i) => i !== index), index, item);
            }
        }
    }
}

export { TagInput };