import React, { Component } from "react";
import "./styles.css";

class PostStatus extends Component {
  render() {
    switch (this.props.status) {
      case "sync":
        return (
          <div className="post_status post_status_sync d-flex justify-content-center align-items-center">
            <i className="fas fa-sync font_white font_20" />
          </div>
        );
        case "award":
        return (
          <div className="post_status post_status_award d-flex justify-content-center align-items-center">
            <i className="fas fa-trophy font_white font_20" />
          </div>
        );
        case "calendar":
        return (
          <div className="post_status post_status_calendar d-flex justify-content-center align-items-center">
            <i className="far fa-calendar-alt font_white font_20" />
          </div>
        );
        case "sponsored":
        return (
          <div className="post_status post_status_sponsored  d-flex justify-content-center align-items-center">
            <p className="font_12 get_zero_space">SPONSORED</p>
          </div>
        );
      default:
        return null;
    }
  }
}

export { PostStatus };
