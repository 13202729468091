import React from 'react'
import './index.css';

export const AlertBanner = ({ alerttitle, alertMessage, bannerClick=null }) => {
    return (
            <div className="icon-warning-wrapper d-flex m-b20 align-items-center pointer" onClick={bannerClick}>
              <div className="warning-text wordBreak">
                <h6>{alerttitle}</h6>
                <p className="font_d_blue font_16 font_regular">{alertMessage}</p>
              </div>
            </div>
    )
}
