import AuthTypes from '../types/authentication';
import InvestorTypes from '../types/investor';

export const initialState = {
    fetching: false,
    investorTypes: [],
    investorDetails: [],
    action: null,
    companyList: [],
    investedCompanies: [],
    selectedInvestors: {
        data: [],
        ids: []
    },
    allInvestors: [],
    allAuthorizedInvestors: [],
    searchStr: ''
};

export const investor = (state = initialState, action) => {
    switch (action.type) {
        case InvestorTypes.FETCHING_COMPANY_INVESTORS:
            return {
                ...state,
                fetching: true,
                searchStr: action.searchStr,
            };
        case InvestorTypes.FETCHING_COMPANY_INVESTORS_SUCCESS:
            return {
                ...state,
                allInvestors: state.searchStr === action.searchStr ? action.payload : state.allInvestors,
                fetching: state.searchStr === action.searchStr ? false : state.fetching,
            };
        case InvestorTypes.FETCHING_COMPANY_INVESTORS_FAIL:
            return {
                ...state,
                fetching: false,
                allInvestors: []
            };
        case InvestorTypes.FETCHING_AUTHORIZED_INVESTORS:
            return {
                ...state,
                fetching: true
            };
        case InvestorTypes.FETCHING_AUTHORIZED_INVESTORS_SUCCESS:
            return {
                ...state,
                fetching: false,
                allAuthorizedInvestors: action.payload,
                action: InvestorTypes.FETCHING_AUTHORIZED_INVESTORS_SUCCESS
            };

        case InvestorTypes.FETCHING_AUTHORIZED_INVESTORS_FAIL:
            return {
                ...state,
                fetching: false,
                allAuthorizedInvestors: [],
                action: InvestorTypes.FETCHING_AUTHORIZED_INVESTORS_FAIL
            };

        case InvestorTypes.SELECT_INVESTOR:
            return {
                ...state,
                selectedInvestors: {
                    data: state.selectedInvestors.data.concat(action.payload),
                    ids: state.selectedInvestors.ids.concat(action.payload._id)
                }
            }
        case InvestorTypes.DELETE_SELECTED_INVESTOR:
            return {
                ...state,
                selectedInvestors: {
                    data: [
                        ...state.selectedInvestors.data.slice(0, parseInt(action.payload, 10)),
                        ...state.selectedInvestors.data.slice(parseInt(action.payload, 10) + 1),
                    ],
                    ids: [
                        ...state.selectedInvestors.ids.slice(0, parseInt(action.payload, 10)),
                        ...state.selectedInvestors.ids.slice(parseInt(action.payload, 10) + 1),
                    ]
                }
            }
        case AuthTypes.FETCHING_INVESTOR_TYPES:
            return {
                ...state,
                fetching: true
            };
        case AuthTypes.GETTING_INVESTOR_TYPES_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.GETTING_INVESTOR_TYPES_SUCCESS,
                investorTypes: action.payload.data,
                response: action.payload
            };
        case AuthTypes.GETTING_INVESTOR_TYPES_FAIL:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.GETTING_INVESTOR_TYPES_FAIL,
                response: action.payload
            };
        case InvestorTypes.SET_INVESTOR_DETAILS:
            return {
                ...state,
                investorDetails: action.payload,
                action: InvestorTypes.SET_INVESTOR_DETAILS
            };

        case InvestorTypes.UPDATING_INVESTOR_OVERVIEW:
            return {
                ...state,
                fetching: true,
                action: InvestorTypes.UPDATING_INVESTOR_OVERVIEW
            };
        case InvestorTypes.UPDATING_INVESTOR_OVERVIEW_SUCCESS:
            return {
                ...state,
                fetching: false,
                investorDetails: {
                    ...state.investorDetails,
                    ...action.payload
                },
                action: InvestorTypes.UPDATING_INVESTOR_OVERVIEW_SUCCESS
            };
        case InvestorTypes.UPDATING_INVESTOR_OVERVIEW_FAIL:
            return {
                ...state,
                fetching: false,
                action: InvestorTypes.UPDATING_INVESTOR_OVERVIEW_FAIL
            };

        case InvestorTypes.UPDATING_INVESTOR_THESIS:
            return {
                ...state,
                fetching: true,
                action: InvestorTypes.UPDATING_INVESTOR_THESIS
            };
        case InvestorTypes.UPDATING_INVESTOR_THESIS_SUCCESS:
            return {
                ...state,
                fetching: false,
                investorDetails: {
                    ...state.investorDetails,
                    ...action.payload
                },
                action: InvestorTypes.UPDATING_INVESTOR_THESIS_SUCCESS
            };
        case InvestorTypes.UPDATING_INVESTOR_THESIS_FAIL:
            return {
                ...state,
                fetching: false,
                action: InvestorTypes.UPDATING_INVESTOR_THESIS_FAIL
            };

        case InvestorTypes.GET_COMPANY_LIST:
            return {
                ...state,
                fetching: true,
                action: InvestorTypes.GET_COMPANY_LIST
            };
        case InvestorTypes.GET_COMPANY_LIST_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: InvestorTypes.GET_COMPANY_LIST_SUCCESS,
                companyList: action.payload
            };
        case InvestorTypes.GET_COMPANY_LIST_FAIL:
            return {
                ...state,
                fetching: false,
                action: InvestorTypes.GET_COMPANY_LIST_FAIL
            };

        case InvestorTypes.GET_INVESTED_COMPANY_LIST:
            return {
                ...state,
                fetching: true,
                action: InvestorTypes.GET_INVESTED_COMPANY_LIST
            };
        case InvestorTypes.GET_INVESTED_COMPANY_LIST_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: InvestorTypes.GET_INVESTED_COMPANY_LIST_SUCCESS,
                investedCompanies: action.payload
            };
        case InvestorTypes.GET_INVESTED_COMPANY_LIST_FAIL:
            return {
                ...state,
                fetching: false,
                action: InvestorTypes.GET_INVESTED_COMPANY_LIST_FAIL
            };

        case InvestorTypes.ADD_INVESTED_COMPANY:
            return {
                ...state,
                fetching: true,
                action: InvestorTypes.ADD_INVESTED_COMPANY
            };
        case InvestorTypes.ADD_INVESTED_COMPANY_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: InvestorTypes.ADD_INVESTED_COMPANY_SUCCESS
            };
        case InvestorTypes.ADD_INVESTED_COMPANY_FAIL:
            return {
                ...state,
                fetching: false,
                action: InvestorTypes.ADD_INVESTED_COMPANY_FAIL
            };

        case InvestorTypes.CLEAR:
            return {
                ...state,
                action: null
            };

        case InvestorTypes.CLEAR_SELECTED_INVESTORS:
            return {
                ...state,
                selectedInvestors: {
                    data: [],
                    ids: []
                }
            }

        default:
            return state
    }
};