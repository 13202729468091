import React, { Component } from "react";
import "./index.css";
import { Break, Accordion } from "./../../../common";
import Measure from "react-measure";

class CollapsibleCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.open || false,
      onClick: false,
      hideHeader: false,
      dimensions: {
        height: -1
      }
    };
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.open !== this.props.open) {
      this.setState({ isOpen: nextProps.open });
    }
  };

  open = () => {
    if (!this.props.isCollapsibleFalse) {
      this.setState(prevState => {
        return { isOpen: !prevState.isOpen, onClick: true };
      });

      if (this.props.getSubscription && !this.state.isOpen) {
        this.props.getSubscription(this.props.subscription);
      }
    }
  };

  render() {
    let { isDataroomPublicView } = this.props;
    return (
      <div className="collapsible_card m-b25">
        {!this.props.hideHeader && <div
          className={`justify-content-between d-flex p-all15 p-t30 collasible_card_header ${isDataroomPublicView ? 'fundraising_dataRoom_card_header' : 'dataRoom_card_header'}`}
          onClick={this.open}
        >
          {this.getTitle(this.props.title)}
          {this.props.button}
          {!this.props.isCollapsibleFalse && this.getIcon(this.state.isOpen)}
          {this.props.optionButtons && this.props.optionButtons.length > 0 && this.getButtons(this.props.optionButtons)}
        </div>}
        <Accordion isOpen={this.state.onClick ? this.state.isOpen : this.props.open} height={this.state.dimensions.height}>
          {this.getCollaps(this.props.children)}
        </Accordion>
      </div>
    );
  }

  getCollaps = children => {
    let customClass = this.props.customClass || "p-all15 p-p-l-50 p-p-r-50 inner_collapser";
    if (children) {
      return (
        <Measure
          bounds
          onResize={contentRect => {
            this.setState({ dimensions: contentRect.bounds });
          }}
        >
          {({ measureRef }) => (
            <div ref={measureRef}>
              {!this.props.embed && <Break color="#979797" />}

              <div className={customClass}>
                {children}
              </div>
            </div>
          )}
        </Measure>
      );
    } else {
      return null;
    }
  };

  getTitle = title => {
    if (title) {
      return (
        <div className="d-flex align-items-center justify-content-between cp-card-flex-1">
          <div className="d-flex">
            <div className="collapsible_topic">
              <i className="font_20 fas fa-align-justify font_gray_05" />
            </div>
            <h4 className="font_dark_gray font_20 font_medium m-t11 ">{title}</h4>
          </div>
          {this.props.advertiserData && <div className="cpt-right">
            <div className="cpt-text-lg">Today's Date: {this.props.advertiserData.date}</div>
            <div className="cpt-text-sm">The time/date will be recorded with this record</div>
          </div>}
        </div>
      );
    } else {
      return null;
    }
  };

  getIcon = state => {
    if (state === true || state === false) {
      return (
        <div>
          <button
            type="button"
            className="card_header_button d-flex justify-content-center align-items-center"
            style={{ cursor: "pointer" }}
          >
            {state === false ? (
              <i
                className="fas fa-edit font_white font_16"
                style={{ marginLeft: "3px" }}
              />
            ) : (
              <i className="fas fa-chevron-up font_white font_16" />
            )}
          </button>
        </div>
      );
    } else {
      return null;
    }
  };

  getButtons = optionButtons => {
    const buttonStyle = {

    };
    return (
      <div className="dataRoom_ch_btn_wrp">
        {optionButtons.map((button, i) => {
          return (
            <button
              key={i}
              type="button"
              className={button.class ? button.class + " m-l5" : "btn_fill_ppl m-l5"}
              style={buttonStyle}
              onClick={button.action}
              disabled={button.isButtonDisabled}
            >
              {button.text}
            </button>
          )
        })}
      </div>
    );
  };
}

export { CollapsibleCard };
