module.exports = {
    CLEAR_ACTION: 'CLEAR_ACTION_MV2',
    STATUS: {
        FETCHING: '_FETCHING',
        SUCCESS: '_SUCCESS',
        FAIL: '_FAIL',
        DELETING: '_DELETING'
    },
    CREATE_GROUP_POST: 'CREATE_GROUP_POST',
    FETCHING_GROUPS: 'FETCHING_GROUPS',
    GROUP_FEED: 'GROUP_FEED',
    FETCH_GROUPS: 'FETCH_GROUPS',
    CLEAR_GROUPS: 'CLEAR_GROUPS',

    JOINING_GROUP: 'JOINING_GROUP',
    JOIN_GROUP: 'JOIN_GROUP',

    LEAVING_GROUP: 'LEAVING_GROUP',
    LEAVE_GROUP: 'LEAVE_GROUP',

    CLOSE_MOBILE_POPUP: 'CLOSE_MOBILE_POPUP'
}