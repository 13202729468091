import React, { Component } from "react";


const PlaceholderInput = ({ placeholder, type, onchange ,defaultvalue }) => {
  return (
    <input type={type} defaultValue={defaultvalue} className="matirial_custom_input matirial_custom_input_placeholder" placeholder={placeholder} required onChange={onchange.bind(this)}/>
  );
};

class PlaceholderInputControlled extends Component{

  constructor(props){
    super(props);
    this.state={
      value:this.props.defaultvalue || ''
    }
  }

  onChangeText = (e) => {
    this.setState({value:e.target.value});
    if(this.props.onchange){
        this.props.onchange(e);
    }
  };

  setEmpty = () => {
    this.setState({
        value:''
    })
  };

  setValue = (value) => {
    this.setState({
        value: value
    })
  };

  render(){

    const {type, placeholder } = this.props;

    return(
        <input
            type={type}
            value={this.state.value}
            className="matirial_custom_input matirial_custom_input_placeholder"
            placeholder={placeholder}
            required
            onChange={this.onChangeText.bind(this)}
            onBlur={this.onChangeText.bind(this)}
            role="presentation" autoComplete="off"
        />
    )
  }
}

PlaceholderInput.defaultProps = {
  type: "text",
};

export { PlaceholderInput, PlaceholderInputControlled };
