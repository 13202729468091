import React from "react";
import "./index.css";

const CardWithFooter = ({ children, bottomText }) => {
  return (
    <div className="employees_body p-all25 pt-0">
      <div /*className="card_width_footer_body"*/>{children}</div>
      {bottomText && <div className="card_width_footer_footer d-flex align-items-center justify-content-center">
        <h4 className="font_18 font_purple font_medium" style={{ 'textTransform': 'uppercase' }}>Contact {bottomText}</h4>
      </div>}
    </div>
  );
};

export { CardWithFooter };
