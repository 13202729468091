import React, { Component } from "react";
import "./index.css";
import classname from "classnames";
import { COMPANY_TYPE } from "../../../types/data";

class TagDropdown extends Component {

  constructor(props) {
    super(props);
    this.state = {
      popupVisible: false,
      defaultData: this.props.data || COMPANY_TYPE,
      data: this.props.data || COMPANY_TYPE,
      selectedItem: {
        value: this.props.selectedValue || "",
        label: ''
      },
      items: [],
      readyToHide: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.setToInitialValue = this.setToInitialValue.bind(this);
  }

  setDefault = () => {
    this.setState({
      selectedItem: {
        value: '',
        label: ''
      },
      items: []
    })
  };

  setToInitialValue = (e) => {
    let key = e.keyCode || e.charCode;
    if (key === 8) {
      this.setState({
        data: [],
        items: []
      }, () => {
        if (this.props.submit) {
          this.props.submit(this.state.items);
        }
      })
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.isMessageSearch) {
      if (nextProps.data !== this.props.data) {
        this.setState({ defaultData: nextProps.data, data: nextProps.data });
      }
    } else {
      if (nextProps.data !== this.props.data && !this.state.popupVisible) {
        this.setState({ defaultData: nextProps.data, data: nextProps.data });
      }
      if (nextProps.data !== this.props.data && this.props.investorStep) {
        this.setState({ defaultData: nextProps.data, data: nextProps.data });
      }
    }

    if (this.props.isAdminSearching && nextProps.data !== this.props.data) {
      this.setState({ defaultData: nextProps.data, data: nextProps.data });
    }
  }

  handleClick() {
    if (!this.state.popupVisible) {
      if (this.refs.searchText) {
        this.refs.searchText.focus();
      }
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      if (this.refs.searchText) {
        this.refs.searchText.focus();
      }
      document.removeEventListener("click", this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      popupVisible: !prevState.popupVisible
    }));
  }

  handleOutsideClick = (e) => {
    if (!this.state.readyToHide || (this.node && this.node.contains && this.node.contains(e.target))) {
      return;
    }
    this.handleClick();
  };

  search = (searchable, e) => {
    if (searchable) {
      if (this.props.getValues) {
        this.props.getValues(e.target.value)
      }
      let filterItems = [];
      let item = {
        value: "",
        label: e.target.value
      };
      this.setState({ selectedItem: item, popupVisible: true });

      if (e.target.value.trim()) {

        this.state.defaultData.forEach((item, i) => {
          if (item.label.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
            filterItems.push(item);
          }
        });

        if (filterItems.length === 1 && filterItems[0].label === e.target.value && this.props.getValues) {
          this.setItem(filterItems[0])
        }

        if ((filterItems.length < 1 || this.props.liveUpdate) && this.props.onchange) {
          if (filterItems.length < 1 || this.props.liveUpdate) {
            this.props.onchange(e.target.value);
          } else {
            this.props.onchange(true);
          }
        }

        if (filterItems.length < 1) {
          if (this.props.noSuggestions) {
            this.props.noSuggestions(true)
          }
        } else {
          if (this.props.noSuggestions) {
            this.props.noSuggestions(false)
          }
        }

        this.setState({ data: filterItems });
      } else {
        if (this.props.isEmpty) {
          this.props.isEmpty(true);
        }
        this.setState({ data: this.state.defaultData });
      }
    } else {
      const key = e.keyCode || e.charCode;
      if ((key === 8 || key === 46) && this.props.onRemove) {
        this.props.onRemove();
        this.setDefault();
      }
    }

    return;
  };

  setItem = (item) => {
    if (!this.state.items.includes(item)) {
      this.setState(
        { selectedItem: item, items: [...this.state.items, item] },
        () => {
          if (this.props.submit) {
            this.props.submit(this.state.items);
          }
        });
    }
    if (this.refs.searchText) {
      this.refs.searchText.value = '';
      this.refs.searchText.focus();
    }

  };

  removeItem = (item) => {
    let newItems = this.state.items.filter(arrayItem => { return arrayItem !== item });
    this.setState(
      { items: newItems, selectedItem: newItems.length === 0 ? { value: '', label: '' } : this.state.selectedItem },
      () => {
        if (this.props.submit) {
          this.props.submit(this.state.items);
        }
      });
  };

  setEmptyOptions = () => {
    this.setState({
      data: []
    })
  };

  render() {

    const itemStyles = {
      display: 'inline-block',
      padding: '2px 5px',
      border: '1px solid #cdcdcd',
      fontFamily: 'Helvetica, sans-serif',
      borderRadius: '5px',
      marginRight: '5px',
      cursor: 'pointer',
      fontSize: '12px'
    };

    let value = this.state.items.length > 1 ? `${this.state.items.length} keywords selected` : (this.state.items.length === 1 ? this.state.items[0].label : '');

    if (this.props.selectedValue && this.state.selectedItem.label === '') {
      this.state.defaultData.forEach((item, i) => {
        if (item.value === this.props.selectedValue) {
          value = item.label;
        }
      });
    }

    let dropdown = classname(
      "dropdown_body ",
      this.props.size === "small"
        ? "dropdown_body_small"
        : "dropdown_body_regular",
      this.state.popupVisible === true ? "media_active" : "media_hide"
    );

    let dropdownItem = classname(
      this.props.size === "small"
        ? "dropdown_item dropdown_item_small"
        : "dropdown_item"
    );

    let inpitStyle = classname(
      this.props.size === "small"
        ? " matirial_custom_input matirial_custom_input_small noselect"
        : "matirial_custom_input noselect"
    );

    let items = '';

    if (this.props.registration === true && value !== '' && value !== null) {
      items = this.state.data.map((item, i) => {
        return (
          <div
            className={
              this.state.selectedItem.value === item.value
                ? `${dropdownItem}  dropdown_item_active`
                : dropdownItem
            }
            key={i}
            onClick={this.setItem.bind(this, item)}
          >
            {item.label}
          </div>
        );
      });
    } else if (!this.props.registration) {
      items = this.state.data.map((item, i) => {

        let isSelected = this.state.items.filter(sitem => { return sitem.label === item.label });
        if (isSelected.length !== 0) { return null }
        return (
          <div
            className={dropdownItem}
            key={i}
            onClick={this.setItem.bind(this, item)}
          >
            {item.label}
          </div>
        );
      });
    }

    let tags = this.state.items.map(item => {
      return (
        <li style={itemStyles} key={item.value} onClick={() => this.removeItem(item)}>
          {item.label}
          <span><i className="fas fa-window-close m-l5" /></span>
        </li>
      )
    })

    return (
      <React.Fragment>
        {this.props.search && (
          <input
            id={this.props.elmId ? this.props.elmId : null}
            type={this.props.type}
            className={inpitStyle}
            value={value}
            onClick={this.handleClick}
            onKeyDown={this.setToInitialValue}
            required
          />
          // <label className={inpitStyle} onClick={this.handleClick}>{value}</label>
        )}
        {!this.props.search && (
          <input
            id={this.props.elmId ? this.props.elmId : null}
            type={this.props.type}
            className={inpitStyle}
            onChange={this.search.bind(this, false)}
            onKeyUp={this.search.bind(this, false)}
            value={value}
            onClick={this.handleClick}
            required
          />
        )}
        <div
          className={dropdown}
          ref={node => { this.node = node }}
          style={{ zIndex: 9999 }}
          onMouseLeave={() => { this.setState({ readyToHide: true }) }}
          onMouseEnter={() => { this.setState({ readyToHide: false }) }}
        >
          <div className="dropdown_item" style={{ padding: 5 + 'px' }}>
            <input ref="searchText" onChange={this.search.bind(this, true)} className={inpitStyle} placeholder="Search..." style={{ marginTop: 0 + 'px', border: 1 + 'px', borderStyle: 'solid', borderColor: '#c1c1c1' }} />
          </div>
          {tags.length > 0 &&
            <div className="dropdown_item" style={{ padding: 5 + 'px' }}>
              <ul style={{ paddingLeft: 0 + 'px' }}>
                {tags}
              </ul>
            </div>}
          {items}
        </div>
      </React.Fragment>
    );
  }
}

TagDropdown.defaultProps = {
  type: "text",
  size: null
};

export { TagDropdown };
