module.exports = {

    //Green Pages Categories
    FETCHING_GREEN_PAGES_CATEGORIES: "FETCHING_GREEN_PAGES_CATEGORIES",
    FETCH_GREEN_PAGES_CATEGORIES_SUCCESS: "FETCH_GREEN_PAGES_CATEGORIES_SUCCESS",
    FETCH_GREEN_PAGES_CATEGORIES_FAIL: "FETCH_GREEN_PAGES_CATEGORIES_FAIL",

    //Green Pages AD
    FETCHING_GREEN_PAGES_FULL_PAGE_LISTING: "FETCHING_GREEN_PAGES_FULL_PAGE_LISTING",
    FETCH_GREEN_PAGES_FULL_PAGE_LISTING_SUCCESS: "FETCH_GREEN_PAGES_FULL_PAGE_LISTING_SUCCESS",
    FETCH_GREEN_PAGES_FULL_PAGE_LISTING_FAIL: "FETCH_GREEN_PAGES_FULL_PAGE_LISTING_FAIL",
    CLEAR_GREEN_PAGES_FULL_PAGE_LISTING: "CLEAR_GREEN_PAGES_FULL_PAGE_LISTING",
    FETCH_GREEN_PAGES_MAIN_CATEGORY_IDS_SUCCESS: "FETCH_GREEN_PAGES_MAIN_CATEGORY_IDS_SUCCESS",
    FETCH_GREEN_PAGES_SUB_CATEGORY_IDS_SUCCESS:"FETCH_GREEN_PAGES_SUB_CATEGORY_IDS_SUCCESS",

    //Clear Action
    CLEAR_ACTION: "CLEAR_ACTION",

    //Clear States
    CLEAR_GREEN_PAGES_CATEGORIES: "CLEAR_GREEN_PAGES_CATEGORIES",
    CLEAR_GREEN_PAGES_LISTINGS: "CLEAR_GREEN_PAGES_LISTINGS",

    //Green Pages Listings
    FETCHING_GREEN_PAGES_LISTINGS_BY_MAIN: "FETCHING_GREEN_PAGES_LISTINGS_BY_MAIN",
    FETCH_GREEN_PAGES_LISTINGS_BY_MAIN_SUCCESS: "FETCH_GREEN_PAGES_LISTINGS_BY_MAIN_SUCCESS",
    FETCH_GREEN_PAGES_LISTINGS_BY_MAIN_FAIL: "FETCH_GREEN_PAGES_LISTINGS_BY_MAIN_FAIL",

    FETCHING_GREEN_PAGES_LISTINGS_BY_SUB: "FETCHING_GREEN_PAGES_LISTINGS_BY_SUB",
    FETCH_GREEN_PAGES_LISTINGS_BY_SUB_SUCCESS: "FETCH_GREEN_PAGES_LISTINGS_BY_SUB_SUCCESS",
    FETCH_GREEN_PAGES_LISTINGS_BY_SUB_FAIL: "FETCH_GREEN_PAGES_LISTINGS_BY_SUB_FAIL",

    FETCHING_GREEN_PAGES_LISTINGS_COUNT: "FETCHING_GREEN_PAGES_LISTINGS_COUNT",
    FETCH_GREEN_PAGES_LISTINGS_COUNT_SUCCESS: "FETCH_GREEN_PAGES_LISTINGS_COUNT_SUCCESS",
    FETCH_GREEN_PAGES_LISTINGS_COUNT_FAIL: "FETCH_GREEN_PAGES_LISTINGS_COUNT_FAIL",

    //Updating single list view
    UPDATING_GREEN_PAGES_SINGLE_LIST: "UPDATING_GREEN_PAGES_SINGLE_LIST",
    UPDATE_GREEN_PAGES_SINGLE_LIST_SUCCESS: "UPDATE_GREEN_PAGES_SINGLE_LIST_SUCCESS",
    UPDATE_GREEN_PAGES_SINGLE_LIST_FAIL: "UPDATE_GREEN_PAGES_SINGLE_LIST_FAIL",

    ADD_GREEN_PAGES_ADVERTISER: "ADD_GREEN_PAGES_ADVERTISER",
    ADD_GREEN_PAGES_ADVERTISER_SUCCESS: "ADD_GREEN_PAGES_ADVERTISER_SUCCESS",
    ADD_GREEN_PAGES_ADVERTISER_FAIL: "ADD_GREEN_PAGES_ADVERTISER_FAIL"

};