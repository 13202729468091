import React, { useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import { NOTIFICATION_TYPES, EVENT_NOTIFICATION_CONNECTED_STATUS, POP_ALERT, BLOCKUSER_CONNECT_ERROR_MSG, BLOCKUSER_POPUP_CLASSNAME,
    BLOCKUSER_ACCEPT_ERROR_MSG, BLOCKUSER_MSG_ERROR_MSG } from '../../types/common';
import { PRO_USER_INVITATION_STATUS, PRO_INVITATION_RECEIVER_ACTION_METHODS } from '../../types/pro';
import './index.css';
import {getPreview, messageTextChecker} from "../../libs/textFormat";
import IconPersonal from '../../assets/img/unread-message.svg';
import IconMarketplace from '../../assets/img/shopping-basket.svg';
import IconVideo from '../../assets/img/video.svg';
import { connect } from 'react-redux';
import { requestConnection, updateRequestedUser, showVideoPost } from '../../actions/newsFeed'
import { acceptConnection, setRequestedUser } from '../../actions/connections'
import { fetchWarningDisclaimerTerms, fetchWarningDisclaimerText } from '../../actions/message-v2';
import ChatWarning from "../chatPopup/chatWarning";
import {setUserProStatus} from "../../actions/pro";
import { showPopupAlert } from "../../actions/alert";
import { checkBlockStatus } from "../../actions/profile";

const ToastNotification = (
    {
        history,
        notifications,
        close,
        markAsRead,
        socket,
        viewMessage,
        openMsgPopup,
        openAttendeesPopup,
        requestedUID,
        acceptedUID,
        rejectedUID,
        requestedByUID,
        accepedByUID,
        rejectedByUID,
        warningDisclaimerTerms,
        warningDisclaimerText,

        acceptConnection,
        updateRequestedUser,
        showVideoPost,
        isLoggedIn,
        setRequestedUser,
        fetchWarningDisclaimerTerms,
        fetchWarningDisclaimerText,
        dispatch
    }) => {

    const inputEl = useRef(null);

    useEffect(() => {
        if(inputEl.current){inputEl.current.scrollTop = inputEl.current.scrollHeight - inputEl.current.clientHeight;}

        if(warningDisclaimerText === null){
            fetchWarningDisclaimerTerms()
            fetchWarningDisclaimerText()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications]);

    return (
        <React.Fragment>
            <div className="notification-wrapper" ref={inputEl} style={{ zIndex : isLoggedIn ? 999999999999 : 0 }}>
                {
                    notifications.map((toast, index) => {
                        if ([NOTIFICATION_TYPES.CONNECTION_REQUESTS, NOTIFICATION_TYPES.CONNECTIONS_MSG].includes(toast.type)) {
                            return (
                                <ToastWrapperConnections
                                    key={index}
                                    notificationId={toast.notificationId}
                                    url={toast.url}
                                    type={toast.type}
                                    title={toast.title}
                                    message={getPreview(toast.message, 500)}
                                    profileImage={toast.profileImage}
                                    close={() => close(toast.notificationId)}
                                    setRequestedUser={() => {
                                        if (toast._id) {
                                            setRequestedUser(toast._id)
                                        }
                                    }}
                                    warningDisclaimerTerms={warningDisclaimerTerms}
                                    warningDisclaimerText={warningDisclaimerText}
                                    requesterId={toast._id}
                                    socket={socket}
                                    acceptConnection={acceptConnection}
                                    dispatch={dispatch}
                                />
                            )
                        } else if ([NOTIFICATION_TYPES.EVENT_UPDATE].includes(toast.type)) {
                            return (
                                <ToastWrapperEvents
                                    key={index}
                                    notificationId={toast.notificationId}
                                    type={toast.type}
                                    profileImage={toast.profileImage}
                                    data={toast}
                                    close={() => close(toast.notificationId)}
                                    openMsgPopup={openMsgPopup}
                                    openAttendeesPopup={(eventId) => openAttendeesPopup(eventId)}
                                    socket={socket}
                                    requestedUID={requestedUID}
                                    acceptedUID={acceptedUID}
                                    rejectedUID={rejectedUID}
                                    requestedByUID={requestedByUID}
                                    accepedByUID={accepedByUID}
                                    rejectedByUID={rejectedByUID}

                                    acceptConnection={acceptConnection}
                                    updateRequestedUser={updateRequestedUser}
                                    dispatch={dispatch}
                                />
                            )
                        } else if([NOTIFICATION_TYPES.NEWSFEED_VIDEO_UPDATE].includes(toast.type)){
                            return (
                                <ToastWrapperPostPublished
                                    key={index}
                                    notificationId={toast.notificationId}
                                    postId={toast._id}
                                    title={toast.title}
                                    show={showVideoPost}
                                    close={() => close(toast.notificationId)}
                                />
                            )
                        }  else if(NOTIFICATION_TYPES.PRO_INVITATION === toast.type){
                            return (
                                <ToastWrapperPROInvitation
                                    key={index}
                                    notificationId={toast.notificationId}
                                    invitation={toast.invitation}
                                    message={toast.message}
                                    profileImage={toast.profileImage}
                                    close={() => close(toast.notificationId)}
                                    history={history}
                                />
                            );
                        }  else if(NOTIFICATION_TYPES.CONNECTION_LIMIT_EXTENDED === toast.type){
                            return (
                                <ToastWrapperConnectionLimit
                                    key={index}
                                    notificationId={toast.notificationId}
                                    invitation={toast.invitation}
                                    message={toast.message}
                                    close={() => close(toast.notificationId)}
                                    history={history}
                                />
                            );
                        } else {
                            return (
                                <ToastWrapperMessage
                                    key={index}
                                    notificationId={toast.notificationId}
                                    url={toast.url}
                                    title={toast.title}
                                    message={getPreview(toast.message, 500)}
                                    type={toast.type}
                                    close={() => close(toast.notificationId)}
                                    markAsRead={() => markAsRead({...toast.readData, socket})}
                                    viewMessage={() => {
                                        viewMessage(toast.setMessageData);
                                        close(toast.notificationId)
                                    }}
                                />
                            )
                        }
                    })
                }
            </div>
        </React.Fragment>
    )

};

const ToastWrapperPostPublished = ({ postId, title, show, close }) => {

    const isInNewsfeed = (window.location.pathname === '/')

    return (
        <div className="toast-container">
            <div className="notification-icon">
                <img src={ IconVideo } alt=''/>
            </div>
            <div className="notification-content">
                <div className="notification-title">
                    <span className="bold">{title}</span> 
                    </div>
                <div className="notification-description">
                    {/* {message} */}
                </div>
                {postId && <div className="notification-options">
                    <Link
                        className="open-notification"
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                        to={isInNewsfeed ? '/': `/?postId=${postId}`}
                        onClick={() => {
                            if(isInNewsfeed){
                                show(postId)
                            }
                            close()
                        }}
                    >
                        View Post
                    </Link>
                </div>}
            </div>
            <div className="close-icon" onClick={close}>
                <i className="fas fa-times"/>
            </div>
        </div>
    )
};

const ToastWrapperMessage = ({ url, title, message, close, type, markAsRead, viewMessage }) => {
    return (
        <div className="toast-container">
            <div className="notification-icon">
                <img src={type === NOTIFICATION_TYPES.PRODUCTS_MSG ? IconMarketplace: IconPersonal } alt=''/>
            </div>
            <div className="notification-content">
                <div className="notification-title">
                    <span className="bold">{title} sent you a {`${type === NOTIFICATION_TYPES.PRODUCTS_MSG ? 'Marketplace Info Request' : 'new message'}`} </span> 
                    </div>
                <div className="notification-description">
                    {message}
                </div>
                <div className="notification-options">
                <Link
                    className="mark-read"
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                    to={""}
                    onClick={e => {
                        e.preventDefault();
                        markAsRead();
                        close();
                    }}
                >
                    Mark as Read
                </Link>
                <Link className="open-notification" style={{ textDecoration: 'none', cursor: 'pointer' }} to={url} onClick={viewMessage}>
                        Open Message
                </Link>
                </div>
            </div>
            <div className="close-icon" onClick={close}>
                <i className="fas fa-times"/>
            </div>
        </div>
    )
};

const ToastWrapperConnections = ({ url, title, message, close, profileImage, type, setRequestedUser, warningDisclaimerTerms, warningDisclaimerText, requesterId, socket, acceptConnection, dispatch }) => {
   
    const acceptConnectionRequest = async (user) => {
        const {success} = await checkBlockStatus(user);
        if(success) {
            acceptConnection(user, null, null, socket);
        } else {
            showPopupAlert(dispatch, POP_ALERT.OK, '', BLOCKUSER_ACCEPT_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
        }
    }

    return (
        <div className="toast-container">
            <div className="notification-icon">
                <img src={profileImage} alt="" />
            </div>
            <div className="notification-content">
                <div className="notification-title">
                    <span className="bold">{title} has sent you a Connection Request {type === NOTIFICATION_TYPES.CONNECTIONS_MSG && " Message"} </span>
                </div>
                {message && <div className="notification-description">
                    {message}
                </div>}
                {message && messageTextChecker(warningDisclaimerTerms, message) && warningDisclaimerText !==null &&
                <ChatWarning title='Automated Message From Leafwire' messageText={warningDisclaimerText}/>
                }
                <div className="notification-options">
                    <Link
                        className="leaf-btn red text-only event-notif-action-button font_regular font_12 text-left"
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                        to={url}
                        onClick={() => {
                            setRequestedUser()
                            close()
                        }}
                    >
                        View Request
                    </Link>
                    <button
                        className="leaf-btn green event-notif-action-button"
                        onClick={() => {
                            acceptConnectionRequest(requesterId)
                            close()
                        }}
                    >
                        ACCEPT
                            </button>
                </div>
            </div>
            <div className="close-icon" onClick={close}>
                <i className="fas fa-times" />
            </div>
        </div>
    )
};

const ToastWrapperEvents = (
    {
        profileImage,
        close,
        data,
        openMsgPopup,
        openAttendeesPopup,
        socket,
        requestedUID,
        acceptedUID,
        rejectedUID,
        requestedByUID,
        accepedByUID,
        rejectedByUID,

        acceptConnection,
        updateRequestedUser,
        dispatch
    }) => {

    const sendConnectionRequest = async (user) => {
        const socketInfo = {
            socket,
            userId: user,
            type: NOTIFICATION_TYPES.CONNECTION_REQUESTS,
        };
        const { success } = await requestConnection(user, '', socketInfo, dispatch);
        if(success){
            updateRequestedUser(user)
        }
    };

    const acceptConnectionRequest = async (user) => {
        acceptConnection(user, null, null, socket);
    }

    const [connection, setConnection] = useState(data.connectionStatus);

    useEffect(() => {

        if(acceptedUID === data.userID && connection ===  EVENT_NOTIFICATION_CONNECTED_STATUS.ACCEPT_PENDING){
            setConnection(EVENT_NOTIFICATION_CONNECTED_STATUS.CONNECTED)
        }

        if(requestedUID === data.userID && connection ===  EVENT_NOTIFICATION_CONNECTED_STATUS.NOT_CONNECTED){
            setConnection(EVENT_NOTIFICATION_CONNECTED_STATUS.REQUESTED)
        }

        if(accepedByUID === data.userID && connection === EVENT_NOTIFICATION_CONNECTED_STATUS.REQUESTED){
            setConnection(EVENT_NOTIFICATION_CONNECTED_STATUS.CONNECTED)
        }

        if(requestedByUID === data.userID && connection === EVENT_NOTIFICATION_CONNECTED_STATUS.NOT_CONNECTED){
            setConnection(EVENT_NOTIFICATION_CONNECTED_STATUS.ACCEPT_PENDING)
        }

        if(rejectedUID === data.userID && connection === EVENT_NOTIFICATION_CONNECTED_STATUS.ACCEPT_PENDING){
            setConnection(EVENT_NOTIFICATION_CONNECTED_STATUS.NOT_CONNECTED)
        }

        if(rejectedByUID === data.userID && connection === EVENT_NOTIFICATION_CONNECTED_STATUS.REQUESTED){
            setConnection(EVENT_NOTIFICATION_CONNECTED_STATUS.NOT_CONNECTED)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ requestedUID, acceptedUID, rejectedUID, requestedByUID, accepedByUID, rejectedByUID]);

    return (
        <div className="toast-container">
            <div className="notification-icon">
                <img src={profileImage} alt="" />
            </div>
            <div className="notification-content">
                <div className="notification-title">
                    <span className="bold">{`${data.userFirstName} ${data.userLastName} is now attending ${data.eventName}.`} </span>
                    <span className="see-attendees-link" onClick={() => openAttendeesPopup(data.eventId)}>See all attendees</span>
                </div>
                <div className="notification-options">
                    {connection === EVENT_NOTIFICATION_CONNECTED_STATUS.NOT_CONNECTED &&
                    <div
                        className="leaf-btn green event-notif-action-button"
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                        onClick={async() => {
                                const {success} = await checkBlockStatus(data.userID);
                                if(success) {
                                    sendConnectionRequest(data.userID)
                                } else {
                                    showPopupAlert(dispatch, POP_ALERT.OK, '', BLOCKUSER_CONNECT_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
                                }
                            }
                        }
                    >
                        Connect
                    </div>}
                    {connection === EVENT_NOTIFICATION_CONNECTED_STATUS.REQUESTED &&
                    <div
                        className="leaf-btn gray event-notif-action-button"
                        style={{ textDecoration: 'none', cursor: 'not-allowed' }}
                    >
                        Requested
                    </div>}
                    {connection === EVENT_NOTIFICATION_CONNECTED_STATUS.ACCEPT_PENDING &&
                    <div
                        className="leaf-btn green event-notif-action-button"
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                        onClick={async() => {
                                const {success} = await checkBlockStatus(data.userID);
                                if(success) {
                                    acceptConnectionRequest(data.userID)
                                } else {
                                    showPopupAlert(dispatch, POP_ALERT.OK, '', BLOCKUSER_ACCEPT_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
                                }
                            }
                        }
                    >
                        Accept
                    </div>}
                    {connection === EVENT_NOTIFICATION_CONNECTED_STATUS.CONNECTED &&
                    <div
                        className="leaf-btn gray event-notif-action-button"
                        style={{ textDecoration: 'none', cursor: 'not-allowed' }}
                    >
                        Connected
                    </div>}
                    <div className="leaf-btn green event-notif-action-button" style={{ textDecoration: 'none', cursor: 'pointer' }} 
                        onClick={async() => {
                                const {success} = await checkBlockStatus(data.userID);
                                if(success) {
                                    openMsgPopup(data.msgData)
                                } else {
                                    showPopupAlert(dispatch, POP_ALERT.OK, '', BLOCKUSER_MSG_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
                                }
                            }
                        }
                    >
                        Message
                    </div>
                </div>
            </div>
            <div className="close-icon" onClick={close}>
                <i className="fas fa-times" />
            </div>
        </div>
    )
};

const ToastWrapperPROInvitation = ({ history, invitation, message, profileImage, close }) => {

    const response = (action) => {
        close();
        if(action) history.push(`/proInvitation/${PRO_USER_INVITATION_STATUS.ACCEPTED}/${PRO_INVITATION_RECEIVER_ACTION_METHODS.IN_APP_NOTIFICATION}/${invitation._id}/${localStorage.getItem('userId')}`)
        else {
            let obj = {
                INVITATION_ID: invitation._id, 
                STATUS: PRO_USER_INVITATION_STATUS.DECLINED, 
                ACTION_VIA: PRO_INVITATION_RECEIVER_ACTION_METHODS.IN_APP_NOTIFICATION
            }
            setUserProStatus(obj)
        }

        if(localStorage.getItem(invitation._id)) localStorage.removeItem(invitation._id);
    }
    return (
        <div className="toast-container pro-invitation-toast">
            <div className="notification-icon">
                <img src={profileImage} alt="" />
            </div>
            <div className="notification-content">
                <div className="notification-description" dangerouslySetInnerHTML={{__html: message}}></div>
                <div className="notification-options">
                    <div
                        className="leaf-btn red text-only event-notif-action-button font_regular font_12 text-left"
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                        onClick={() => response(false)}>
                        Decline
                    </div>
                    <div
                        className="leaf-btn green event-notif-action-button"
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                        onClick={() => response(true)}>
                        Accept Invite
                    </div>
                </div>
            </div>
            <div className="close-icon" onClick={close}>
                <i className="fas fa-times" />
            </div>
        </div>
    );
};

const ToastWrapperConnectionLimit = ({ title, message, close }) => {
    return (
        <div className="toast-container">
            <div className="notification-icon">
                <img src={IconPersonal } alt=''/>
            </div>
            <div className="notification-content">
                <div className="notification-title">
                    <span className="bold">{message} </span>
                </div>
                <div className="notification-description">
                    {/*{message}*/}
                </div>
                <div className="notification-options">
                    <Link
                        className="mark-read"
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                        to={""}
                        onClick={e => {
                            e.preventDefault()
                            close()
                        }}
                    >
                        Close
                    </Link>
                </div>
            </div>
            <div className="close-icon" onClick={close}>
                <i className="fas fa-times"/>
            </div>
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        requestedUID: state.connection.connectionSentUserId || null,
        acceptedUID: state.connection.connectionAcceptedUserId || null,
        rejectedUID: state.connection.connectionRejectedUserId || null,
        requestedByUID: state.auth.connectionRequestSentBy || null,
        accepedByUID: state.connection.connectionAcceptedByUserId || null,
        rejectedByUID: state.connection.connectionRejectedByUserId || null,
        warningDisclaimerTerms: state.messageV2.warningDisclaimerTerms,
        warningDisclaimerText: state.messageV2.warningDisclaimerText,
    }
};

const actions = {
    acceptConnection: acceptConnection,
    updateRequestedUser: updateRequestedUser,
    showVideoPost: showVideoPost,
    setRequestedUser: setRequestedUser,
    setUserProStatus: setUserProStatus,
    fetchWarningDisclaimerTerms: fetchWarningDisclaimerTerms,
    fetchWarningDisclaimerText: fetchWarningDisclaimerText
};

export default connect(mapStateToProps, actions)(ToastNotification);
