import React, { Component } from 'react'

class ReadMore extends Component {
    constructor(props){
        super(props);
        this.state = {
            description : this.props.item.DESCRIPTION || '',
            showFullDescription: false
        }
    }
    render() {
        return (
            <div>
                <p className="font_dark_gray_two  font_16 fontColorTest mt-3 text-justify ">{this.state.showFullDescription ? this.state.description : this.state.description.substring(0, this.props.descriptionLimit)}{this.state.description.length > this.props.descriptionLimit && !this.state.showFullDescription && '...'}</p>
                {this.state.description.length > this.props.descriptionLimit && <button className="read-button m-b25" onClick={() => this.setState({ showFullDescription: !this.state.showFullDescription })}>Read {this.state.showFullDescription ? 'Less' : 'More'}</button>}
            </div>
        )
    }
}

export {ReadMore};