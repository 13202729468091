import greenPagesTypes from '../types/greenPages';

export const initialState = {
  error: {},
  action: 0,
  allGreenPagesCatagories: [],
  greenPagesListings: [],
  isListingsFetching: false,
  greenPagesListingsCount: 0,
  fetchingGreenPagesCategories: false,
  fetchingGreenPagesListings: false,
  mainCategoryIdList: {},
  subCategoryIdList: {},
  mainCategoryIdListFetched: false,
  subCategoryIdListFetched: false,
  listingOrderCategory: null

};

export const greenPages = (state = initialState, action) => {
  switch (action.type) {
    case greenPagesTypes.FETCHING_GREEN_PAGES_CATEGORIES:
      return {
        ...state,
        fetchingGreenPagesCategories: true,
        action: greenPagesTypes.FETCHING_GREEN_PAGES_CATEGORIES,
      };
    case greenPagesTypes.FETCH_GREEN_PAGES_CATEGORIES_SUCCESS:
      return {
        ...state,
        fetchingGreenPagesCategories: false,
        action: greenPagesTypes.FETCH_GREEN_PAGES_CATEGORIES_SUCCESS,
        allGreenPagesCatagories: action.payload.data,
      };
      case greenPagesTypes.FETCH_GREEN_PAGES_MAIN_CATEGORY_IDS_SUCCESS:
      return {
        ...state,
        fetchingGreenPagesCategories: false,
        action: greenPagesTypes.FETCH_GREEN_PAGES_MAIN_CATEGORY_IDS_SUCCESS,
        mainCategoryIdList: action.payload,
        mainCategoryIdListFetched: true
      };
      case greenPagesTypes.FETCH_GREEN_PAGES_SUB_CATEGORY_IDS_SUCCESS:
      return {
        ...state,
        fetchingGreenPagesCategories: false,
        action: greenPagesTypes.FETCH_GREEN_PAGES_CATEGORIES_SUCCESS,
        subCategoryIdList: action.payload,
        subCategoryIdListFetched: true
      };
    case greenPagesTypes.FETCH_GREEN_PAGES_CATEGORIES_FAIL:
      return {
        ...state,
        fetchingGreenPagesCategories: false,
        action: greenPagesTypes.FETCH_GREEN_PAGES_CATEGORIES_FAIL,
      };

    case greenPagesTypes.CLEAR_ACTION:
      return {
        ...state,
        action: 0,
        error: {},
      };
    case greenPagesTypes.CLEAR_GREEN_PAGES_CATEGORIES:
      return {
        ...state,
        allGreenPagesCatagories: [],
      };
    case greenPagesTypes.FETCHING_GREEN_PAGES_LISTINGS_BY_MAIN:
      return {
        ...state,
        fetchingGreenPagesCategories: true,
        isListingsFetching: true,
        action: greenPagesTypes.FETCHING_GREEN_PAGES_LISTINGS_BY_MAIN,
      };
    case greenPagesTypes.FETCH_GREEN_PAGES_LISTINGS_BY_MAIN_SUCCESS:
      return {
        ...state,
        fetchingGreenPagesCategories: false,
        isListingsFetching: false,
        action: greenPagesTypes.FETCH_GREEN_PAGES_LISTINGS_BY_MAIN_SUCCESS,
        greenPagesListings: action.isInit ? action.payload.listingData : [...state.greenPagesListings, ...action.payload.listingData],
        listingOrderCategory: action.isInit ? action.payload.listingOrderCategory : state.listingOrderCategory
      };
    case greenPagesTypes.FETCH_GREEN_PAGES_LISTINGS_BY_MAIN_FAIL:
      return {
        ...state,
        fetchingGreenPagesCategories: false,
        isListingsFetching: false,
        action: greenPagesTypes.FETCH_GREEN_PAGES_LISTINGS_BY_MAIN_FAIL,
      };
      case greenPagesTypes.FETCHING_GREEN_PAGES_LISTINGS_BY_SUB:
      return {
        ...state,
        fetchingGreenPagesCategories: true,
        isListingsFetching: true,
        action: greenPagesTypes.FETCHING_GREEN_PAGES_LISTINGS_BY_SUB,
      };
    case greenPagesTypes.FETCH_GREEN_PAGES_LISTINGS_BY_SUB_SUCCESS:
      return {
        ...state,
        fetchingGreenPagesCategories: false,
        isListingsFetching: false,
        action: greenPagesTypes.FETCH_GREEN_PAGES_LISTINGS_BY_SUB_SUCCESS,
        greenPagesListings: action.isInit ? action.payload.listingData : [...state.greenPagesListings, ...action.payload.listingData],
        listingOrderCategory: action.isInit ? action.payload.listingOrderCategory : state.listingOrderCategory
      };
    case greenPagesTypes.FETCH_GREEN_PAGES_LISTINGS_BY_SUB_FAIL:
      return {
        ...state,
        fetchingGreenPagesCategories: false,
        isListingsFetching: false,
        action: greenPagesTypes.FETCH_GREEN_PAGES_LISTINGS_BY_SUB_FAIL,
      };
    case greenPagesTypes.FETCH_GREEN_PAGES_LISTINGS_COUNT_SUCCESS:
      return {
        ...state,
        greenPagesListingsCount: action.count,
        action: action.type
      }
    case greenPagesTypes.FETCH_GREEN_PAGES_LISTINGS_COUNT_FAIL:
      return {
        ...state,
        greenPagesListingsCount: 0,
        action: action.type
    }
    case greenPagesTypes.CLEAR_GREEN_PAGES_LISTINGS:
      return {
        ...state,
        greenPagesListings: [],
        greenPagesListingsCount: 0,
      };
    case greenPagesTypes.FETCHING_GREEN_PAGES_FULL_PAGE_LISTING:
      return {
        ...state,
        fetchingGreenPagesFullPageListing: true,
        action: greenPagesTypes.FETCHING_GREEN_PAGES_FULL_PAGE_LISTING,
      };
    case greenPagesTypes.FETCH_GREEN_PAGES_FULL_PAGE_LISTING_SUCCESS:
      return {
        ...state,
        fetchingGreenPagesFullPageListing: false,
        action: greenPagesTypes.FETCH_GREEN_PAGES_FULL_PAGE_LISTING_SUCCESS,
        greenPagesFullPageListing: action.payload.data,
      };
    case greenPagesTypes.FETCH_GREEN_PAGES_FULL_PAGE_LISTING_FAIL:
      return {
        ...state,
        fetchingGreenPagesFullPageListing: false,
        action: greenPagesTypes.FETCH_GREEN_PAGES_FULL_PAGE_LISTING_FAIL,
      };
    case greenPagesTypes.CLEAR_GREEN_PAGES_FULL_PAGE_LISTING:
      return {
        ...state,
        allGreenPagesCatagories: [],
      }; 
    case greenPagesTypes.UPDATING_GREENPAGES_SINGLE_LIST:
        return {
            ...state,
            action: action.type,
        };
    case greenPagesTypes.UPDATE_GREEN_PAGES_SINGLE_LIST_SUCCESS:
        return {
            ...state,
            action: action.type,
        };
    case greenPagesTypes.UPDATE_GREEN_PAGES_SINGLE_LIST_FAIL:
        return {
            ...state,
            action: action.type,
        };     
    default:
      return state;
  }
};
