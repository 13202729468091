import io from 'socket.io-client';
import { SOCKET_URL } from './../config/server';
import { clearTopNavCount, logout, reduceNotificationCountersWhenDeleteConversation, reloadUserNotificationCounters } from './../actions/auth';
import {
    updateNewMessageNotification,
    updateActiveConversation,
    pushNotifications,
    pullNotifications,
    generateImage,
    updateLastSeenMSG,
    markAsRead,
    resetMessageCounters,
    removeConnectionConversation,
    filterDeletedConversation,
} from './../actions/message-v2';
// import {
//     updateLastConversationSeenTime
// } from './../actions/chat';
import { updateConnectionRequestNotification, connectionAcceptedNotification, connectionRejectededNotification, clearPreviousRequest } from './../actions/connections';
import { reconnectToConversations, getChatConversations, updateChatOnNotification, refetchMyConversations } from './../actions/chat';
import { videoProcessCompleted } from './../actions/newsFeed'; 
import { NOTIFICATION_TYPES, VIDEO_PROCESS_STATUS } from './../types/common';
import { setTimeout } from 'timers';
import defaultAvatorPeople from "./../assets/img/default-male.jpg";
import { getIndividualUser } from './../actions/profile';
import {notifyConnectionReaction, notifyVideoProcessStatus} from './commonFunctions';

export const initSocket = (dispatch) => {

    const socket = io(SOCKET_URL, { transports: ["websocket"] });
    socket.on('connect', () => {
        socket.emit('join', {
            room: 'leafwire',
            token: localStorage.getItem('token')
        }, () => {
            console.log('user has connected to leafwire');
        });

        if(localStorage.getItem("userId")){
            socket.emit('PRIVATE_NOTIFICATION_LISTENER', localStorage.getItem("userId"), () => {
                console.log('user has connected to PRIVATE_NOTIFICATION_LISTENER');
            });
        }

    });
    socket.on('reconnect', function () {
        dispatch(reconnectToConversations(socket));
        console.error('Socket reconnected')
        // window.location.reload();
    });

    socket.on('USER_DELETED', (userid) => {
        if (userid === localStorage.getItem('userId')) {
            dispatch(logout());
            window.location.reload();
        }
    });

    socket.on('LISTENERS.NEWS_FEED_UPDATED_SUCCESS', (post) => {
        if(post && post.data && post.data.POST_CREATOR && post.data.POST_CREATOR === localStorage.getItem('userId')){
            pushNewsfeedNotification(post.data, dispatch)
            dispatch(videoProcessCompleted(post.data))
        }
    });

    socket.on('PERSONAL_NOTIFICATION', (type, data) => {
        try {
            type = parseInt(type);
            dispatch(clearPreviousRequest())
            // dispatch(updateLastConversationSeenTime())
            if (type === NOTIFICATION_TYPES.CONNECTION_REQUESTS) {
                // on connection request
                pushAConnectionNotification(type, data, dispatch);
                dispatch(updateConnectionRequestNotification(type, data));
                notifyConnectionReaction({
                    deletedConversation: null,
                    newConversationId: null,
                    recipient: localStorage.getItem('userId'),
                    requester: data._id,
                    type: "REQUEST",
                })
            } else if (type === NOTIFICATION_TYPES.CONNECTIONS_MSG) {
                if (data.msgSource && data.msgSource === 'connections') {
                    data = { ...data, PROFILE_PHOTO: data.RECIPENT[0].PHOTO ? data.RECIPENT[0].PHOTO : null }
                    // pushAMsgNotification(type, data, dispatch) // commented with chat popup
                }
                dispatch(updateNewMessageNotification(type, data));
            } else if ([NOTIFICATION_TYPES.PERSONAL_MSG, NOTIFICATION_TYPES.PRODUCTS_MSG, NOTIFICATION_TYPES.GREENPAGES].includes(type)) {
                // pushAMsgNotification(type, data, dispatch); // commented with chat popup
                dispatch(updateNewMessageNotification(type, data));
                // playNotificationSound(); Commented as per LEAF-6855
            } else if (type === NOTIFICATION_TYPES.CANCEL_CONNECTION_REQUEST) {
                dispatch(updateConnectionRequestNotification(type, data));
            } else if (type === NOTIFICATION_TYPES.REACT_CONNECTION_REQUEST) {
                dispatch(removeConnectionConversation(data));
                dispatch(getChatConversations({
                    username: '',
                    module: 0,
                    skip: 0,
                    limit: 16
                })); // added to update chat conversation
                if (data.type === 'ACCEPT') {
                    dispatch(connectionAcceptedNotification(data.requester === localStorage.getItem('userId') ? data.recipient : data.requester))
                }
                if (data.type === 'REJECT' && data.deletedConversation) {
                    dispatch(reloadUserNotificationCounters())
                    // dispatch(updateLastSeenMSG(data.deletedConversation, null, NOTIFICATION_TYPES.CONNECTIONS_MSG));
                } else if (data.type === 'REJECT') {
                    dispatch(connectionRejectededNotification(data.requester === localStorage.getItem('userId') ? data.recipient : data.requester))
                }
                notifyConnectionReaction(data)
            } else if (type === NOTIFICATION_TYPES.UPDATE_MY_CONVERSATION_LIST) {
                if(data && data.type && data.action === 'chat-delete'){
                    dispatch(reduceNotificationCountersWhenDeleteConversation(data.type, data.conversationId));
                    dispatch(filterDeletedConversation(data.type, data.conversationId))
                }
                dispatch(refetchMyConversations())
            }  else if (type === NOTIFICATION_TYPES.PRO_INVITATION) {
                pushPROInvitationNotification(type, data, dispatch);
            }  else if (type === NOTIFICATION_TYPES.CONNECTION_LIMIT_EXTENDED) {
                pushConnectionRequestLimitExtendedNotification(type, data, dispatch);
            } else {
                console.error('unknown notification')
            }
        } catch (ex) {
            console.error(ex)
        }
    });

    socket.on('PERSONAL_CONVERSATION', (data) => {

        if (
            data
            && data.type === "msg"
            && data.data
            && data.data.SENDER_DETAILS
            && data.data.SENDER_DETAILS.SYSTEM_USER_ID === localStorage.getItem('userId')
        ) {
            // if a user has open same conversation on multiple tabs, this section will remove sending status from new messages
            data.data = { ...data.data, isSending: false }
        } else {
            // update message as seen if conversation tread is active
            if (data.type === "msg" && data.data && window.location.pathname.includes('/message/')) { // to prevent when user isn't on main messaging
                let readData = {
                    conversationId: data.data.CONVERSATION_ID,
                    messageId: data.data._id,
                    module: data.data.module,
                    socket: socket
                };
                dispatch(markAsRead(readData))
            }
        }
        dispatch(updateActiveConversation(data));
        dispatch(updateChatOnNotification(data));
    });

    socket.on('READ_NOTIFICATION', (type, data) => {
        dispatch(updateLastSeenMSG(data.conversationId, data.index, type));
    });

    socket.on('READ_ALL_NOTIFICATION', (data) => {
        dispatch(resetMessageCounters(data.module, data.count));
    });

    socket.on('RE_SET_TOP_NAV', (moduleNo) => {
        dispatch(clearTopNavCount(moduleNo));
    });

    socket.on('DELETE_CONVERSATION_NOTIFICATION', (type, conversationId) => {
        dispatch(reduceNotificationCountersWhenDeleteConversation(type, conversationId));
        dispatch(filterDeletedConversation(type, conversationId))
    });

    socket.on('EVENTS_NOTIFICATION', (data) => {
        if(data.userID !== localStorage.getItem('userId')){
            pushEventNotification(data, dispatch);
        }
    });

    return socket
};

export const notifyPrivateConversation = (socket, conversationId, msgData) => {
    if(socket){
        socket.emit('CHAT_UPDATE_LISTENER',
            conversationId, 
            msgData,
            () => {
                console.info('notified to chat room')
            }
        )
    } else {
        console.error('socket not established 1')
    }

};


export const notifyReceiver = (socket, userId, type, data, conversationId = null, msgData = null) => {
    if(socket){
        socket.emit('NOTIFY_USER_LISTENER',
            userId,
            type,
            data
            , () => {
                console.info('notified to user', userId);
            });
    } else {
        console.error('socket not established 2')
    }

    if (type === NOTIFICATION_TYPES.REACT_CONNECTION_REQUEST) {
        notifyMyOtherTabs(socket)
    }

    if (![NOTIFICATION_TYPES.CONNECTION_REQUESTS, NOTIFICATION_TYPES.REACT_CONNECTION_REQUEST, NOTIFICATION_TYPES.CONNECTION_LIMIT_EXTENDED].includes(type) ) {
        notifyPrivateConversation(socket, conversationId, {
            type: 'msg',
            data: msgData
        });
    }
};

export const notifyMyOtherTabs = (socket, data = {}) => {
    if(socket){
        socket.emit('NOTIFY_USER_LISTENER',
            localStorage.getItem('userId'),
            NOTIFICATION_TYPES.UPDATE_MY_CONVERSATION_LIST, data, () => {
                console.info('notified to other tabs');
            });
    }
}

// const pushAMsgNotification = async (type, data, dispatch) => {
//     const notificationId = Date.now();
//     let profileImage = defaultAvatorPeople;
//     if (data.PROFILE_PHOTO) {
//         profileImage = await generateImage(data.PROFILE_PHOTO);
//     }
//     const notification = {
//         _id: data._id,
//         notificationId,
//         url: `/message/${type}/${data._id}`,
//         type,
//         title: data.RECIPENT[0].NAME,
//         message: data.RECENT_MESSAGE,
//         profileImage: profileImage,
//         readData: {
//             conversationId: data._id,
//             messageId: data.lastMessageId,
//             module: type,

//         },
//         setMessageData: data
//     };
//     dispatch(pushNotifications(notification));
//     playNotificationSound();
//     setTimeout(() => {
//         dispatch(pullNotifications(notification.notificationId));
//     }, 3600000);
// };

const pushAConnectionNotification = async (type, data, dispatch) => {
    const notificationId = Date.now();
    let profileImage = defaultAvatorPeople;
    if (data.PROFILE_PHOTO) {
        profileImage = await generateImage(data.PROFILE_PHOTO);
    }

    const notification = {
        _id: data._id,
        notificationId,
        url: `/filter-connections/q=eyJhY3Rpb24iOiJ0b2dnbGVTZWN0aW9uIiwiYWN0aXZlU2VjdGlvbiI6MiwiZGF0YSI6bnVsbH0=`,
        type,
        title: `${data.FIRST_NAME} ${data.LAST_NAME}`,
        message: data.CONNECTION_MESSAGE,
        profileImage: profileImage
    };

    dispatch(pushNotifications(notification));
    // playNotificationSound(); Commented as per LEAF-6855
    setTimeout(() => {
        dispatch(pullNotifications(notification.notificationId));
    }, 3600000);
};

export const playNotificationSound = () => {
    try {
        const audio = new Audio('/to-the-point.mp3');
        audio.play();
    } catch (ex) {
        console.log(ex);
    }
};

export const joinConversation = (socket, conversationId) => {
    if(socket){
        socket.emit('PRIVATE_CONVERSATION_LISTENER', conversationId, () => {
        });
    } else {
        console.info('socket not established 3')
    }


};

export const leaveConversation = (socket, conversationId) => {
    if(socket){
        socket.emit('LEAVE_USER_FROM_CHAT', conversationId, () => {
            console.info(`Leave conversation ${conversationId}`)
        })
    } else {
        console.error('socket not established 4')
    }

};

export const notifySeenNotification = (socket, userId, type, data) => {
    if(socket){
        socket.emit('READ_NOTIFICATION_LISTENER',
            userId,
            type,
            data
            , () => {
                console.info('read notified to user');
            });
    } else {
        console.error('socket not established 5')
    }

};

export const notifySeenAllNotification = (socket, userId, module) => {
    if(socket){
        socket.emit('READ_ALL_MESSAGES_LISTENER',
            userId,
            module,
            () => console.info('read all messages notified to user')
        );
    } else {
        console.error('socket not established')
    }

};

export const notifyResettingTopNavNotifications = (socket, userId, moduleNo) => {
    if(socket){
        socket.emit('RE_SET_TOP_NAV_NOTIFICATION',
            userId,
            moduleNo,
            () => console.info('re-set the top navigation notifications')
        );
    } else {
        console.error('socket not established')
    }

};

export const notifyDeleteConversation = (socket, type, conversationId, recipientId) => {
    if(socket){
        socket.emit('DELETE_CONVERSATION_LISTENER',
            type,
            conversationId,
            recipientId,
            () => console.info('delete conversation notifications')
        );
        notifyMyOtherTabs(socket, { action: 'chat-delete', type, conversationId })
    } else {
        console.error('socket not established')
    }

};

const pushEventNotification = async (data, dispatch) => {
    const notificationId = Date.now();
    let profileImage = defaultAvatorPeople;
    if (data.userImage) {
        profileImage = await generateImage(data.userImage);
    }
    const userDetails = await getIndividualUser(data.userID);
    let connectionStatus = 0;
    if(userDetails.success && userDetails.data.FRIEND_STATUS && userDetails.data.FRIEND_STATUS.length > 0){
        const connection = userDetails.data.FRIEND_STATUS[0];
        const userId = localStorage.getItem('userId');
        if (connection.REQUESTER === userId && connection.STATUS === 1) { // I'm the requester and request pending
            connectionStatus = 1;
        } else if (connection.RECIPIENT === userId && connection.STATUS === 1) { // I'm the recipient and request pending
            connectionStatus = 2;
        } else if (connection.STATUS === 2) { // Already connected profile
            connectionStatus = 3;
        } else { // Not a friend
            connectionStatus = 0;
        }
    } else {
        console.info('no connection info')
    }
    const notification = {
        notificationId,
        type: NOTIFICATION_TYPES.EVENT_UPDATE,
        ...data,
        profileImage,
        connectionStatus,
        msgData: {
            ...userDetails.data,
            SYSTEM_USER_ID: userDetails.data._id,
            CONNECTION_STATUS: (userDetails.success && userDetails.data.FRIEND_STATUS && userDetails.data.FRIEND_STATUS.length > 0) ? userDetails.data.FRIEND_STATUS[0] : null
        },
        url: `/message/1/${data.userID}`
    };
    dispatch(pushNotifications(notification));
    // playNotificationSound(); Commented as per LEAF-6855
    setTimeout(() => {
        dispatch(pullNotifications(notification.notificationId));
    }, 3600000);
};

const pushNewsfeedNotification = async ( data, dispatch) => {
    const notificationId = Date.now();

    const notification = {
        _id: data ? data._id : null,
        type: NOTIFICATION_TYPES.NEWSFEED_VIDEO_UPDATE,
        notificationId,
        title: `Your video processing has completed. The post has been added to the newsfeed.`,
    };

    dispatch(pushNotifications(notification));
    // playNotificationSound(); Commented as per LEAF-6855
    if(data && data._id)notifyVideoProcessStatus(data._id, VIDEO_PROCESS_STATUS.COMPLETED)
    setTimeout(() => {
        dispatch(pullNotifications(notification.notificationId));
    }, 3600000);
};

export const joinEventRoom = (socket, eventIds = []) => {
    if(socket){
        socket.emit('JOIN_USER_TO_EVENT_ROOM', eventIds, () => {
            console.info('Joined to event room');
        });
    } else {
        console.error('socket not established')
    }

};

export const joinAndEmitEventRoom = (socket, eventId, data) => {
    if(socket){
        socket.emit('JOIN_USER_TO_EVENT_ROOM', [eventId], () => {
            console.info('Joined to event room');
            socket.emit('NOTIFY_EVENT_CHAT_ROOM_USERS', eventId, data, () => {
                console.log('notified')
            })
        });
    } else {
        console.error('socket not established')
    }

};

export const leaveEventRoom = (socket, eventId) => {
    if(socket){
        socket.emit('LEAVE_EVENT_CHAT_ROOM', eventId, () => {
            console.info('Leave from event room')
        });
    } else {
        console.error('socket not established')
    }

};

const pushPROInvitationNotification = async (type, data, dispatch) => {
    const notificationId = Date.now();
    let profileImage = defaultAvatorPeople;

    if (data.sender.PHOTO) {
        profileImage = await generateImage(data.sender.PHOTO);
    }

    const message = `Great news! ${data.sender.FIRST_NAME} ${data.sender.LAST_NAME} just sent you an invite to get <strong>upgraded to Leafwire Pro</strong>.`;

    const notification = {
        notificationId,
        type,
        message: message,
        profileImage: profileImage,
        invitation: data.invitation,
    };

    localStorage.setItem(data.invitation._id, notificationId)

    dispatch(pushNotifications(notification));
    // playNotificationSound(); Commented as per LEAF-6855
    setTimeout(() => {
        dispatch(pullNotifications(notification.notificationId));
        if(localStorage.getItem(data.invitation._id)) localStorage.removeItem(data.invitation._id);
    }, 3600000);
};

const pushConnectionRequestLimitExtendedNotification = async (type, data, dispatch) => {
    const notificationId = Date.now();

    const message = `Your account is now able to send connection requests again.`;

    const notification = {
        notificationId,
        type,
        message: message,
        invitation: data.invitation,
    };

    dispatch(pushNotifications(notification));
    // playNotificationSound(); Commented as per LEAF-6855
    setTimeout(() => {
        dispatch(pullNotifications(notification.notificationId));
        if(localStorage.getItem(data.invitation._id)) localStorage.removeItem(data.invitation._id);
    }, 3600000);
};