import React from 'react';
import { Modal, ModalClose } from "./../../components/model";
import GroupsWidget from "../../containers/feed/groupsWidget";
import './index.css';

const MobileGroupPopup = (props) => {
    return(
        <Modal isOpen={true} className="modal-space-top-10 groups-mobile-popup">
                <GroupsWidget isMobile={true} closeModal={props.modelClose} joinGroup={props.joinGroup}>
                    <ModalClose light={true} className="text-right" close={props.modelClose} />
                </GroupsWidget>
        </Modal>
    )
};

export default MobileGroupPopup;