import CompanyTypes from '../types/company';
import _ from 'underscore';
import { FUNDRAISING_DETAILS_ACCESS } from '../types/common';



export const initialState = {
    fetching: false,
    isFundRaisingSuccess: false,
    action: 0,
    data: {
        COMPANY_NAME: null,
        COMPANY_TYPE: null,
        CREATEDAT: null,
        UPDATEDAT: null,
        CREATOR: null,
        FUND_RAISER: {
            AMOUNT_RAISE: null,
            AMOUNT_RAISED_SO_FAR: null,
            COMPANY_STAGE: null,
            COMPANY_SIZE: null,
            INVESTMENT_TYPE: null,
            INVESTORS: [],
            MIN_INVESTMENT: null,
            PRODUCT_SHOWCASE: null,
            PRODUCT_STAGE: null
        },
        INVESTOR: {
            INVESTED_COMPANIES: []
        },
        OVERVIEW: null,
        PRODUCT_SHOWCASE: [],
        USERS: []
    },
    companyDetails: [],
    investorList: {},
    individualInvestorList: {},
    currentInvestors: [],
    employeeList: {},
    currentEmployees: {},
    productShowcase: [],
    companyList: [],
    isNewEmployee: false,
    regularEmployees: [],
    advisors: [],
    nonAdvisors:[],
    tools: [],
    FundraisingList: [],
    isBluesnapCustomer: false,
    companyQuestions:{
        BUSINESS_MODEL: null,
        COMPETITIVE_ADVANTAGE: null,
        COMPETITORS: null,
        CUSTOMER_PROBLEM: null,
        CUSTOMER_SEGMENT: null,
        KEY_CUSTOMER_SEGMENT: null,
        MARKET_SIZE:0,
        SALES_AND_MARKETING_STRATEGY: null,
        TARGET_MARKET: null
    },
    CompanyQuestionsAccessPermission: null,
    newCompanyID: null,
    updatingUser: false,
};

export const company = (state = initialState, action) => {
    switch (action.type) {
        case CompanyTypes.INSERTING_FUND_RAISING_ROUND:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.INSERTING_FUND_RAISING_ROUND
            };
        case CompanyTypes.FUND_RAISING_ROUND_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FUND_RAISING_ROUND_SUCCESS,
                companyDetails: [{
                    ...state.companyDetails[0],
                    FUND_RAISER: {
                        ...state.companyDetails[0].FUND_RAISER,
                        ...action.payload.data
                    }
                }],
                data: {
                    ...state.data,
                    FUND_RAISER: {
                        ...state.data.FUND_RAISER,
                        ...action.payload.data
                    }
                }
            };
        case CompanyTypes.FUND_RAISING_ROUND_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FUND_RAISING_ROUND_FAIL
            };
        case CompanyTypes.FUND_RAISING_ROUND_CLEAR_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FUND_RAISING_ROUND_CLEAR_SUCCESS,
                companyDetails: [{
                    ...state.companyDetails[0],
                    FUND_RAISER: {
                        ...state.companyDetails[0].FUND_RAISER,
                        ...action.payload.data
                    }
                }],
                data: {
                    ...state.data,
                    FUND_RAISER: {
                        ...state.data.FUND_RAISER,
                        ...action.payload.data
                    }
                }
            };
        case CompanyTypes.FUND_RAISING_ROUND_CLEAR_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FUND_RAISING_ROUND_CLEAR_FAIL
            };
        case CompanyTypes.FETCHING_COMPANY_DATA:
            return {
                ...state,
                fetching: true,
                tools: [],
                data: {
                    COMPANY_NAME: null,
                    COMPANY_TYPE: null,
                    CREATEDAT: null,
                    UPDATEDAT: null,
                    CREATOR: null,
                    FUND_RAISER: {
                        AMOUNT_RAISE: null,
                        AMOUNT_RAISED_SO_FAR: null,
                        COMPANY_STAGE: null,
                        COMPANY_SIZE: null,
                        INVESTMENT_TYPE: null,
                        INVESTORS: [],
                        MIN_INVESTMENT: null,
                        PRODUCT_SHOWCASE: null,
                        PRODUCT_STAGE: null
                    },
                    INVESTOR: {
                        INVESTED_COMPANIES: []
                    },
                    OVERVIEW: null,
                    PRODUCT_SHOWCASE: [],
                    USERS: []
                },
                action: CompanyTypes.FETCHING_COMPANY_DATA
            };
        case CompanyTypes.FETCHING_COMPANY_DATA_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_COMPANY_DATA_SUCCESS,
                data: action.payload.data ? action.payload.data : initialState.data,
                companyDetails: [action.payload.data]
                // companyDetails: action.payload.data.length > 0 ? [action.payload.data] : []
            };
        case CompanyTypes.FETCHING_COMPANY_DATA_NOT_FOUND:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_COMPANY_DATA_NOT_FOUND,
                data: initialState.data,
                companyDetails: []
            };
        case CompanyTypes.FETCHING_COMPANY_DATA_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_COMPANY_DATA_FAIL
            };
        case CompanyTypes.CLEAR_COMPANY_DATA:
            return {
                ...state,
                data: {
                    COMPANY_NAME: null,
                    COMPANY_TYPE: null,
                    CREATEDAT: null,
                    UPDATEDAT: null,
                    CREATOR: null,
                    FUND_RAISER: {
                        AMOUNT_RAISE: null,
                        AMOUNT_RAISED_SO_FAR: null,
                        COMPANY_STAGE: null,
                        COMPANY_SIZE: null,
                        INVESTMENT_TYPE: null,
                        INVESTORS: [],
                        MIN_INVESTMENT: null,
                        PRODUCT_SHOWCASE: null,
                        PRODUCT_STAGE: null
                    },
                    INVESTOR: {
                        INVESTED_COMPANIES: []
                    },
                    OVERVIEW: null,
                    PRODUCT_SHOWCASE: [],
                    USERS: []
                },
                action: CompanyTypes.CLEAR_COMPANY_DATA
            };
        case CompanyTypes.FETCHING_INVESTORS:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.FETCHING_INVESTORS
            };
        case CompanyTypes.FETCHING_INVESTORS_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_INVESTORS_SUCCESS,
                investorList: action.payload.data
            };
        case CompanyTypes.FETCHING_INVESTORS_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_INVESTORS_FAIL
            };
        case CompanyTypes.FETCHING_INDIVIDUAL_INVESTORS:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.FETCHING_INDIVIDUAL_INVESTORS
            };
        case CompanyTypes.FETCHING_INDIVIDUAL_INVESTORS_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_INDIVIDUAL_INVESTORS_SUCCESS,
                individualInvestorList: action.payload.data
            };
        case CompanyTypes.FETCHING_INDIVIDUAL_INVESTORS_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_INDIVIDUAL_INVESTORS_FAIL
            };
        case CompanyTypes.FETCHING_CURRENT_INVESTORS_PROFILE_IMAGE_SUCCESS: {
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_CURRENT_INVESTORS_PROFILE_IMAGE_SUCCESS,
                currentInvestors: action.payload
            };
        }
        case CompanyTypes.FETCHING_CURRENT_INVESTORS:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.FETCHING_CURRENT_INVESTORS
            };
        case CompanyTypes.FETCHING_CURRENT_INVESTORS_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_CURRENT_INVESTORS_SUCCESS,
                currentInvestors: action.payload
            };
        case CompanyTypes.FETCHING_CURRENT_INVESTORS_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_CURRENT_INVESTORS_FAIL
            };
        case CompanyTypes.ADDING_INVESTOR:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.ADDING_INVESTOR
            };
        case CompanyTypes.ADDING_INVESTOR_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.ADDING_INVESTOR_SUCCESS,
            };
        case CompanyTypes.ADDING_INVESTOR_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.ADDING_INVESTOR_FAIL
            };
        case CompanyTypes.DELETING_INVESTOR:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.DELETING_INVESTOR
            };
        case CompanyTypes.DELETING_INVESTOR_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.DELETING_INVESTOR_SUCCESS,
            };
        case CompanyTypes.DELETING_INVESTOR_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.DELETING_INVESTOR_FAIL
            };
        case CompanyTypes.UPDATING_OVERVIEW:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.UPDATING_OVERVIEW
            };
        case CompanyTypes.UPDATING_OVERVIEW_SUCCESS:
            return {
                ...state,
                fetching: false,
                companyDetails: [{
                    ...state.companyDetails[0],
                    OVERVIEW: action.payload
                }],
                action: CompanyTypes.UPDATING_OVERVIEW_SUCCESS
            };
        case CompanyTypes.UPDATING_OVERVIEW_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.UPDATING_OVERVIEW_FAIL
            };
        case CompanyTypes.ADDING_EMPLOYEE:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.ADDING_EMPLOYEE,
                isNewEmployee: false
            };
        case CompanyTypes.ADDING_EMPLOYEE_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.ADDING_EMPLOYEE_SUCCESS,
                isNewEmployee: action.payload
            };
        case CompanyTypes.ADDING_EMPLOYEE_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.ADDING_EMPLOYEE_FAIL,
                isNewEmployee: false
            };
        case CompanyTypes.DELETING_EMPLOYEE:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.DELETING_EMPLOYEE
            };
        case CompanyTypes.DELETING_EMPLOYEE_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.DELETING_EMPLOYEE_SUCCESS
            };
        case CompanyTypes.DELETING_EMPLOYEE_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.DELETING_EMPLOYEE_FAIL
            };
        case CompanyTypes.FETCHING_CURRENT_EMPLOYEES:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.FETCHING_CURRENT_EMPLOYEES
            };
        case CompanyTypes.FETCHING_CURRENT_EMPLOYEES_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_CURRENT_EMPLOYEES_SUCCESS,
                currentEmployees: action.payload.data
            };
        case CompanyTypes.FETCHING_CURRENT_EMPLOYEES_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_CURRENT_EMPLOYEES_FAIL
            };
        case CompanyTypes.FETCHING_EMPLOYEES:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.FETCHING_EMPLOYEES
            };
        case CompanyTypes.FETCHING_EMPLOYEES_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_EMPLOYEES_SUCCESS,
                employeeList: action.payload.data
            };
        case CompanyTypes.FETCHING_EMPLOYEES_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_EMPLOYEES_FAIL
            };
        case CompanyTypes.FETCHING_CURRENT_PRODUCTS:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.FETCHING_CURRENT_PRODUCTS,
                productShowcase: []
            };
        case CompanyTypes.FETCHING_CURRENT_PRODUCTS_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_CURRENT_PRODUCTS_SUCCESS,
                productShowcase: (state.data._id && state.data._id === action.cid) ? action.payload : state.productShowcase,
            };

        case CompanyTypes.FETCHING_SINGLE_PRODUCTS_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_SINGLE_PRODUCTS_SUCCESS,
                singleProduct: action.payload
            };
        case CompanyTypes.FETCHING_CURRENT_PRODUCTS_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_CURRENT_PRODUCTS_FAIL
            };
        case CompanyTypes.INSERTING_PRODUCT_IMAGE:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.INSERTING_PRODUCT_IMAGE
            };
        case CompanyTypes.INSERTING_PRODUCT_IMAGE_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.INSERTING_PRODUCT_IMAGE_SUCCESS,
                //productShowcase: [...state.productShowcase, action.payload]
            };
        case CompanyTypes.INSERTING_PRODUCT_IMAGE_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.INSERTING_PRODUCT_IMAGE_FAIL
            };
        case CompanyTypes.UPDATING_PRODUCT_IMAGE:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.UPDATING_PRODUCT_IMAGE
            };
        case CompanyTypes.UPDATING_PRODUCT_IMAGE_SUCCESS:
            return {
                ...state,
                fetching: false,
                productShowcase: action.payload,
                action: CompanyTypes.UPDATING_PRODUCT_IMAGE_SUCCESS
            };
        case CompanyTypes.UPDATING_PRODUCT_IMAGE_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.UPDATING_PRODUCT_IMAGE_FAIL
            };
        case CompanyTypes.DELETING_PRODUCT_IMAGE:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.DELETING_PRODUCT_IMAGE
            };
        case CompanyTypes.DELETING_PRODUCT_IMAGE_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.DELETING_PRODUCT_IMAGE_SUCCESS,
                productShowcase: _.filter(state.productShowcase, (item) => { return item._id !== action.payload }),
                deletedProduct: action.payload
            };
        case CompanyTypes.DELETING_PRODUCT_IMAGE_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.DELETING_PRODUCT_IMAGE_FAIL
            };
        case CompanyTypes.FETCHING_COMPANIES:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.FETCHING_COMPANIES
            };
        case CompanyTypes.FETCHING_COMPANIES_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_COMPANIES_SUCCESS,
                companyList: action.payload.data
            };
        case CompanyTypes.FETCHING_COMPANIES_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_COMPANIES_FAIL
            };
        case CompanyTypes.UPDATING_COMPANY_USER:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.UPDATING_COMPANY_USER,
                updatingUser: true
            };
        case CompanyTypes.UPDATING_COMPANY_USER_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.UPDATING_COMPANY_USER_SUCCESS,
                updatingUser: false
            };
        case CompanyTypes.UPDATING_COMPANY_USER_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.UPDATING_COMPANY_USER_FAIL,
                updatingUser: false
            };
        case CompanyTypes.ADDING_PUBLIC_VIDEO:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.ADDING_PUBLIC_VIDEO,
                companyDetails: [{
                    ...state.companyDetails[0],
                    PUBLIC_VIDEO: action.payload
                }],
            };
        case CompanyTypes.ADDING_PUBLIC_VIDEO_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.ADDING_PUBLIC_VIDEO_SUCCESS
            };
        case CompanyTypes.ADDING_PUBLIC_VIDEO_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.ADDING_PUBLIC_VIDEO_FAIL
            };
        case CompanyTypes.UPDATING_COMPANY_NAME:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.UPDATING_COMPANY_NAME
            };
        case CompanyTypes.UPDATING_COMPANY_NAME_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.UPDATING_COMPANY_NAME_SUCCESS
            };
        case CompanyTypes.UPDATING_COMPANY_NAME_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.UPDATING_COMPANY_NAME_FAIL
            };
        case CompanyTypes.FETCHING_EMPLOYEE_PUBLIC_PROFILE_IMAGES_SUCCESS: {
            // return {
            //     ...state,
            //     companyDetails: {
            //         ...state.companyDetails,
            //         employeeList: { ...action.payload }
            //     }
            // }
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_EMPLOYEE_PUBLIC_PROFILE_IMAGES_SUCCESS,
                employeeList: action.payload
            };
        }


        case CompanyTypes.FETCHING_REGULAR_EMPLOYEES:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.FETCHING_REGULAR_EMPLOYEES
            };
        case CompanyTypes.FETCHING_REGULAR_EMPLOYEES_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_REGULAR_EMPLOYEES_SUCCESS,
                regularEmployees: action.payload.data
            };
        case CompanyTypes.FETCHING_REGULAR_EMPLOYEES_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_REGULAR_EMPLOYEES_FAIL
            };

        case CompanyTypes.FETCHING_NON_ADVISORS:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.FETCHING_NON_ADVISORS
            };
        case CompanyTypes.FETCHING_NON_ADVISORS_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_NON_ADVISORS_SUCCESS,
                nonAdvisors: action.payload.data
            };
        case CompanyTypes.FETCHING_NON_ADVISORS_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_NON_ADVISORS_FAIL,
                nonAdvisors: []
            };

        case CompanyTypes.GET_ADVISER:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.GET_ADVISER
            };
        case CompanyTypes.GET_ADVISER_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.GET_ADVISER_SUCCESS,
                advisors: action.payload
            };
        case CompanyTypes.GET_ADVISER_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.GET_ADVISER_FAIL
            };
        case CompanyTypes.GETTING_ADVISORS_PROFILE_IMAGE_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.GETTING_ADVISORS_PROFILE_IMAGE_SUCCESS,
                advisors: action.payload
            };
        case CompanyTypes.ADD_ADVISER:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.ADD_ADVISER
            };
        case CompanyTypes.ADD_ADVISER_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.ADD_ADVISER_SUCCESS
            };
        case CompanyTypes.ADD_ADVISER_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.ADD_ADVISER_FAIL
            };
        case CompanyTypes.REMOVE_ADVISER:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.REMOVE_ADVISER
            };
        case CompanyTypes.REMOVE_ADVISER_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.REMOVE_ADVISER_SUCCESS
            };
        case CompanyTypes.REMOVE_ADVISER_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.REMOVE_ADVISER_FAIL
            };
        case CompanyTypes.GETTING_TOOLS:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.GETTING_TOOLS
            };
        case CompanyTypes.GETTING_TOOLS_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.GETTING_TOOLS_SUCCESS,
                tools: action.payload
            };
        case CompanyTypes.GETTING_TOOLS_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.GETTING_TOOLS_FAIL
            };

        case CompanyTypes.CLEAR_ACTION:
            return {
                ...state,
                action: 0
            }

        case CompanyTypes.FETCHING_FUNDING_COMPANY_LIST:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.FETCHING_FUNDING_COMPANY_LIST
            };
        case CompanyTypes.FETCHING_FUNDING_COMPANY_LIST_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_FUNDING_COMPANY_LIST_SUCCESS,
                FundraisingList: action.payload.data
            };
        case CompanyTypes.FETCHING_FUNDING_COMPANY_LIST_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.FETCHING_FUNDING_COMPANY_LIST_FAIL
            };
        case CompanyTypes.GETTING_KEY_EMPLOYEED_COMPANY_IMAGE_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.GETTING_KEY_EMPLOYEED_COMPANY_IMAGE_SUCCESS,
                FundraisingList: action.payload
            };

        case CompanyTypes.SETTING_FUNDRAISING_COMPANY:
            return {
                ...state,
                fetching: true,
                action: CompanyTypes.SETTING_FUNDRAISING_COMPANY
            };
        case CompanyTypes.SETTING_FUNDRAISING_COMPANY_SUCCESS:

            let fundingCompanies = state.FundraisingList;
            fundingCompanies.forEach((item, i) => {
                if (item._id === action.payload) {
                    fundingCompanies[i].IS_FUNDRAISING = true
                } else {
                    fundingCompanies[i].IS_FUNDRAISING = false
                }
            });

            return {
                ...state,
                fetching: false,
                action: CompanyTypes.SETTING_FUNDRAISING_COMPANY_SUCCESS,
                FundraisingList: fundingCompanies
            };
        case CompanyTypes.SETTING_FUNDRAISING_COMPANY_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.SETTING_FUNDRAISING_COMPANY_FAIL
            };
        case CompanyTypes.ACCEPT_ADVISER_REQUEST:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.ACCEPT_ADVISER_REQUEST
            };
        case CompanyTypes.ACCEPT_ADVISER_REQUEST_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.ACCEPT_ADVISER_REQUEST_SUCCESS
            };
        case CompanyTypes.ACCEPT_ADVISER_REQUEST_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.ACCEPT_ADVISER_REQUEST_FAIL
            };
        case CompanyTypes.REJECT_ADVISER_REQUEST:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.REJECT_ADVISER_REQUEST
            };
        case CompanyTypes.REJECT_ADVISER_REQUEST_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.REJECT_ADVISER_REQUEST_SUCCESS
            };
        case CompanyTypes.REJECT_ADVISER_REQUEST_FAIL:
            return {
                ...state,
                fetching: false,
                action: CompanyTypes.REJECT_ADVISER_REQUEST_FAIL
            };
        case CompanyTypes.BLUESNAP_CUSTOMER:
            return {
                ...state,
                fetching: false,
                isBluesnapCustomer: true,
                action: CompanyTypes.BLUESNAP_CUSTOMER
            };
        case CompanyTypes.GET_COMPANY_QUESTIONS_SUCCESS:
            return {
                ...state,
                companyQuestions: {
                    ...state.companyQuestions,
                    ...action.payload
                },
                action: CompanyTypes.GET_COMPANY_QUESTIONS_SUCCESS
            };
        case CompanyTypes.GET_FUNDRAISING_DETAILS_ACCESS_PERMISSION:
            return {
                ...state,
                CompanyQuestionsAccessPermission: null
            };
        case CompanyTypes.GET_FUNDRAISING_DETAILS_ACCESS_PERMISSION_SUCCESS:
            return {
                ...state,
                CompanyQuestionsAccessPermission: action.access
            };
        case CompanyTypes.GET_FUNDRAISING_DETAILS_ACCESS_PERMISSION_FAIL:
            return {
                ...state,
                CompanyQuestionsAccessPermission: null
            };
        case CompanyTypes.REQUEST_FUNDRAISING_DETAILS_FAIL:
            return {
                ...state,
                action: CompanyTypes.REQUEST_FUNDRAISING_DETAILS_FAIL,
                CompanyQuestionsAccessPermission: FUNDRAISING_DETAILS_ACCESS.NOT_REQUESTED
            };
        case CompanyTypes.CREATE_COMPANY_SUCCESS:
            return {
                ...state,
                action: CompanyTypes.CREATE_COMPANY_SUCCESS,
                newCompanyID: action.payload
            };
        case CompanyTypes.CREATE_COMPANY_FAIL:
            return {
                ...state,
                action: CompanyTypes.CREATE_COMPANY_FAIL
            };
        case CompanyTypes.CLEAR_NEW_COMPANY:
            return {
                ...state,
                newCompanyID: null
            };
        case CompanyTypes.DELETE_COMPANY_SUCCESS:
            return {
                ...state,
                action: CompanyTypes.DELETE_COMPANY_SUCCESS
            };
        case CompanyTypes.DELETE_COMPANY_FAIL:
            return {
                ...state,
                action: CompanyTypes.DELETE_COMPANY_FAIL
            };
        case CompanyTypes.SWITCH_COMPANY:
            return {
                ...state,
                data: {
                    COMPANY_NAME: null,
                    COMPANY_TYPE: null,
                    CREATEDAT: null,
                    UPDATEDAT: null,
                    CREATOR: null,
                    FUND_RAISER: {
                        AMOUNT_RAISE: null,
                        AMOUNT_RAISED_SO_FAR: null,
                        COMPANY_STAGE: null,
                        COMPANY_SIZE: null,
                        INVESTMENT_TYPE: null,
                        INVESTORS: [],
                        MIN_INVESTMENT: null,
                        PRODUCT_SHOWCASE: null,
                        PRODUCT_STAGE: null
                    },
                    INVESTOR: {
                        INVESTED_COMPANIES: []
                    },
                    OVERVIEW: null,
                    PRODUCT_SHOWCASE: [],
                    USERS: []
                },
                companyDetails: [],
                currentInvestors: [],
                employeeList: {},
                currentEmployees: {},
                productShowcase: [],
                regularEmployees: [],
                nonAdvisors: [],
                advisors: [],
                action: CompanyTypes.SWITCH_COMPANY
            };
        case CompanyTypes.SAVE_COMPANY_QUESTIONS_FAIL:
            return {
                ...state,
                action: CompanyTypes.SAVE_COMPANY_QUESTIONS_FAIL
            };
        default:
            return state
    }
}