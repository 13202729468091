import React, { Component } from "react";
import "./styles.css";
import { getPreview, formatRichText } from '../../../libs/textFormat';
import {PUBLIC_PENDING_ACTIONS} from '../../../types/common'
import { trackEvent } from '../../../actions/eventTracking';
import {EVENT_ACTION} from '../../../types/eventTracking';
import {POST} from '../../../types/dataTrackingElementIds';

class Message extends Component {

  constructor(props) {
    super(props);
    this._navigateToPostView = this._navigateToPostView.bind(this);
    this.state = ({
      message: this.props.message || '',
      hidden: !this.props.isNFSinglePost
    })
    // document.addEventListener('click', (event) => console.log());
  }

  urlify = (text) => {
    try {
      var Regex = /(https?:\/\/[^\s]+)/g;
      return text.replace(Regex, (url) => {
        // return '<a href="' + url + '">' + url + '</a>';
        return '';
      });
    } catch (error) {
      return text
    }
    
  }

  urlifyRichText = (text) => {
    const url = this.state.message.match(/\bhttps?:\/\/\S+/gi)
    if (url && url.length > 0) {
      return text.replace(`>${url[0]}<`, '')
    } 
    return text
  }

  _navigateToPostView = (e, postId) => {

      /* data project - event trcking */
      let eventPayload = {
        user_id: (this.props.user) ? this.props.user._id :  null,
        page: this.props.page,
        section: this.props.section,
        element_type: 'POST',
        element_id: null,
        action_type: EVENT_ACTION.CLICK,
        post_id: this.props.postId
      }
      if(this.props.user) this.props.dispatch(trackEvent(eventPayload));
    /* END : data project - event trcking */

        if (e.target.className !== 'mentionTagLink' && e.target.className !== 'weblink') {
            if (this.props.isGroupActive && (this.props.match.params.groupId || (this.props.sharedGroups && this.props.sharedGroups.length > 0))) {

                if (!this.props.isGroupFeed) {
                    if(this.props.isPublicFeed){
                        this.props.openLogin( PUBLIC_PENDING_ACTIONS.P_VIEW_GROUP_POST, `/groups/${this.props.match.params.groupId ? this.props.match.params.groupId : this.props.sharedGroups[0]}/${this.props.postId}`)
                    } else {
                        this.props.history.push(`/groups/${this.props.match.params.groupId ? this.props.match.params.groupId : this.props.sharedGroups[0]}/${this.props.postId}`)
                    }
                } else {
                    if (this.props.openSinglePost) {
                        if (this.props.isFirstPost) {
                            this.setState({hidden: false});
                        }
                        this.props.openSinglePost(this.props.match.params.groupId ? this.props.match.params.groupId : this.props.sharedGroups[0], postId);
                    }
                }

            } else {
                if (this.props.openSinglePost) {
                    if(this.props.isPublicFeed){
                        this.props.openLogin( PUBLIC_PENDING_ACTIONS.P_VIEW_POST, { postId, isFirstPost: this.props.isFirstPost })
                    } else {
                        this.props.openSinglePost(postId, this.props.isFirstPost);
                        if (this.props.isFirstPost) {
                            this.setState({hidden: false});
                        }
                    }
                }
            }
        }
  }

  _show = () => {
      if(this.props.user && this.props.page && this.state.hidden) {
        /* data project - event trcking */
        const eventPayload = {
          user_id: this.props.user._id,
          page:  this.props.page, 
          section:  this.props.section,  
          element_type: 'LINK',
          element_id: POST.SEE_M,
          action_type: EVENT_ACTION.CLICK,
          post_id: this.props.postId
        }
        this.props.dispatch(trackEvent(eventPayload));
        /* END : data project - event trcking */
      }
    this.setState({
      hidden: !this.state.hidden
    })
  }

  getLength = () => {
    if (this.props.richText) {
      try {
        const tags = Object.values(this.props.richText.entityMap)
        const tagCount = tags.filter(v => { return v.type !== 'LINK'}).length;
        return (200 - tagCount * 25) + (100 * tagCount)
      } catch (error) {
        return 200
      }
    }
    return 200
  }

  render() {
    const { richText, message } = this.props;
    let msg = richText ? this.urlifyRichText(formatRichText(richText, true)).trim() : ((this.state.message !== null && this.state.message !== '') ? this.urlify(this.state.message) : '\n').trim();
    let msgLength = null;
    const prevMsgLength = msg.length;
    if (this.state.hidden) {
      msgLength = this.getLength();
      msg = getPreview(msg, msgLength);
    }
    let displayText = msg !== null ? msg.replace(/(?:\r\n|\r|\n)/g, '<br/>') : '';

    if (this.props.bold) {
      return (
        <div onClick={this._navigateToPostView(this, this.props.postId)} className={msg && "font_14 m-t5 more post-content"} dangerouslySetInnerHTML={{ __html: displayText }} />
      );
    } else {
      return (
        <div>
          <div ref="post_text" className={msg && "font_14 m-t5 more post-content"} >
            <span  onClick={(e, postId) => this._navigateToPostView(e, this.props.postId)} dangerouslySetInnerHTML={{ __html: displayText }}/>
            {msg !== null && msg !== '' && ((message && this.urlify(message.trim()).trim().length > 200) || (richText && prevMsgLength > msgLength)) && <span className="post-content" 
                                                                                                                style={{ color: 'blue', cursor: 'pointer' }} 
                                                                                                                onClick={this._show.bind(this)}
                                                                                                >{this.state.hidden ? '... see more' : ' see less'}</span>}
          </div>
        </div>
      );
    }
  }

}

Message.defaultProps = {
  bold: null,
  message: null,
  isFirstPost: false
};

export { Message };