import React from 'react'

const Col = ({ className ,children }) =>{

    return(
        <div className={className}>{children}</div>
    )
     
     
}
export {Col}