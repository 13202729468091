import React, { Component } from "react";
import { Break } from "../../components/common";
import {
    Modal,
    ModalTitle,
    ModalHeader,
    ModalClose,
    ModalFormGroup
} from "../../components/model";
import { Col, Row } from "../../components/common";

import RequestAvator from '../../assets/img/default-male.jpg'; // default-male.jpg, avatar.jpeg

import { isURL, isValidUrl } from './../../libs/validation';
import { SIGNED_COOKIES_CF_URL } from '../../config';
import { resizeTextArea } from './../../libs/textFormat';
import "./index.css";
import { getCFObjURL, BUCKET } from "../../libs/cfHelper";


class InviteUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            avatar: RequestAvator,
            message: '',
            withMessage: false,
            isTextMsgValid: true
        };
        this.enableMessageArea = this._enableMessageArea.bind(this);
        this.validateTextMsg = this._validateTextMsg.bind(this);
    }

    componentWillMount() {
        if (!isURL(this.props.recipientPhoto, { protocols: ['https'] }, SIGNED_COOKIES_CF_URL) && this.props.recipientPhoto !== '') {
            this._getImageUrl(this.props.recipientPhoto);
        } else if (this.props.recipientPhoto !== '') {
            this.setState({
                avatar: this.props.recipientPhoto
            })
        }
    }

    componentDidMount() {
        if(this.props.defaultMessage){
            this.setState({
                message: this.props.defaultMessage,
                withMessage: true,
                isTextMsgValid: this.validateTextMsg(this.props.defaultMessage)
            })
        }
    }

    _getImageUrl(imageName) {
        const url = getCFObjURL(BUCKET.COMMON)(('small-' + imageName), true);
        if (url) {
            this.setState({ avatar: url });
        } else{
            this.setState({ avatar: RequestAvator });
        }
    }

    _validateTextMsg = (msg) => {
        return (msg && msg !== '' && (msg.length <= 200 || (isValidUrl(msg) && msg.includes('/post/preview/'))))
    }

    _send = () => {
        let msg = '';
        if (this.state.isTextMsgValid) {
            if (this.state.withMessage && this.refs.msg.value !== '') {
                msg = this.refs.msg.value;
                this.props.connectWithMessage(this.props.recipientId, msg, this.props.viewId);
            } else {
                this.props.connectWithMessage(this.props.recipientId, msg, this.props.viewId);
            }
        }
    }

    _closeModal = () => {
        if (!this.props.isSendMsgBtnClicked) {
            this.props.modelClose();
        }
    }

    _enableMessageArea = () => {
        this.setState({
            withMessage: true
        }, () => {
            if (this.refs.msg) {
                this.refs.msg.focus();
            }
        })
    }

    render() {

        const style = {
            fontWeight: 'bold',
            textDecoration: 'underline',
            cursor: 'pointer'
        }

        return (
            <Modal isOpen={this.props.isOpenModel} className="modal-space-top-10 modal-lg connection-request_modal" style={{'z-index': 9}}>
                <ModalHeader>
                    <ModalTitle>Connection Request</ModalTitle>
                    <ModalClose close={this._closeModal} />
                </ModalHeader>
                <ModalFormGroup>
                    <div className="request_msg_popup d-flex align-items-center ">
                        <div className="requestMsg_profile">
                            <img src={this.state.avatar} className="Investor" alt="Request Profile" />
                        </div>
                        <div className="m-l15">
                            <h4 className="font_d_blue font_16 font_medium m-0">{this.props.recipientName}</h4>
                        </div>
                    </div>
                </ModalFormGroup>
                <Break color="#ababab" />
                <ModalFormGroup>
                    <div>
                        {!this.state.withMessage && <div>
                            <p><span style={style} onClick={this.enableMessageArea} >Click Here</span> to enter a personalized message. People who receive a personalized note are more likely to accept your connection request.</p>
                        </div>}
                        {this.state.withMessage && <textarea
                            ref="msg"
                            className="form-control"
                            id="msg"
                            onChange={
                                e => { 
                                    resizeTextArea(e, 2, 10);
                                    if (e.target.value !== '' && this.validateTextMsg(e.target.value)) {
                                        this.setState({ isTextMsgValid: true })
                                    } else {
                                        this.setState({ isTextMsgValid: false })
                                    }
                                }
                            }
                            defaultValue={this.state.message}>
                        </textarea>}
                    </div>
                </ModalFormGroup>

                <div>
                    {(!this.state.isTextMsgValid) && <span id="error-msg" >Your message may not exceed the maximum size of 200 characters</span>}
                </div>

                <Break color="#ababab" />

                <ModalFormGroup>
                    <Row>
                        <Col className="col-lg-6">
                            <div className="text-right">
                                <button className="btn base-button-modal-outline full_width" onClick={this.props.modelClose} disabled={this.props.isSendMsgBtnClicked}> Cancel</button>
                            </div>
                        </Col>
                        <Col className="col-lg-6">
                            <div className="text-right">
                                <button className="btn base-button-dim-green full_width" onClick={this._send.bind(this)} disabled={this.props.isSendMsgBtnClicked}><i className={this.props.isSendMsgBtnClicked ? "fa fa-spinner fa-spin" : "scc"}></i> {this.state.withMessage ? 'Send' : 'Send Without Message'}</button>
                            </div>
                        </Col>
                    </Row>
                </ModalFormGroup>
            </Modal>
        );
    }
}


export default InviteUser