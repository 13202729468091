/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import "./index.css";
import facebook from "./../../../assets/img/social/facebook.svg";
import linkdin from "./../../../assets/img/social/linkedin.svg";
import twitter from "./../../../assets/img/social/twitter.svg";
import instagram from "./../../../assets/img/social/instagram.svg";
import { resetUrl } from "../../../libs/textFormat"
import { trackEvent } from './../../../actions/eventTracking';
import {EVENT_ACTION} from './../../../types/eventTracking';

const SocilaLink = ({ name ,link, page=null, section=null, dispatch=null}) => {
  let icon = null;

  if (name === "facebook") {
    icon = facebook;
  } else if (name === "linkedin") {
    icon = linkdin;
  } else if (name === "twitter") {
    icon = twitter;
  } else if (name === "instagram") {
    icon = instagram;
  }

  /* data project - event trcking */
    const _setEventTrackingPayload = () => {
      if(dispatch && page && section) {
        let payload = {
            user_id: localStorage.getItem('userId'),
            page: page, 
            section: section,
            element_type: 'ICON',
            element_id: name.toUpperCase(),
            action_type: EVENT_ACTION.CLICK
        }
        dispatch(trackEvent(payload));
      }
  }
  /* END : data project - event trcking */

  return (
    <a
      className={
        link
          ? "profile_social_link profile_social_link_active"
          : "profile_social_link profile_social_link_disable"
      }
      href={ resetUrl(link) }
      onClick={_setEventTrackingPayload}
      target="_blank"
      rel="noopener"
    >
      <img className="profile_social_image" src={icon} alt="social media"/>
    </a>
  );
};
export { SocilaLink };
