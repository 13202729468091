import {ROUTES} from '../config';
import {get, post} from '../libs/apiConnector';
import {
    openExistingChat,
} from './chat';
import {
    CLEAR_ACTION,
    STATUS,
    GET_CONVERSATIONS,
    GET_CONVERSATIONS_COUNT,
    EMPTY_MESSAGES,
    GET_MESSAGES,
    GET_MESSAGES_COUNT,
    GET_CONVERSATION_PARTICIPANTS,
    GET_CONVERSATION_PARTICIPANT_IMG,
    GET_RECIPIENT_CONNECTION_STATUS,
    SET_RECIPIENT_DETAILS,
    SEND_MESSAGE,
    DELETE_CONVERSATION_THREAD,
    EMPTY_MESSAGES_PARTICIPANTS,
    EMPTY_MESSAGES_COUNT,
    RECEIVED_MARKETPLACE_MSG_NOTIFICATION,
    RECEIVED_GREENPAGES_MSG_NOTIFICATION,
    CHANGE_ACTIVE_SECTION,
    CHANGE_ACTIVE_CONVERSATION,
    NEW_MESSAGE_NOTIFICATION,
    UPDATE_LAST_SEEN_MSG,
    DECREMENT_MARKETPLACE_MSG_NOTIFICATION,
    RECEIVED_PERSONAL_MSG_NOTIFICATION,
    RECEIVED_CONNECTION_MSG_NOTIFICATION,
    DECREMENT_CONNECTION_MSG_NOTIFICATION,
    DECREMENT_PERSONAL_MSG_NOTIFICATION,
    DECREMENT_GREENPAGES_MSG_NOTIFICATION,
    UPDATE_ACTIVE_CONVERSATION_MSG,
    UPDATE_USER_TYPING_STATUS,
    MARK_ALL_MESSAGES_READ,
    MARK_ALL_CONVERSATIONS_READ,
    OPEN_MESSAGE,
    REMOVE_CONNECTION_CONVERSATION,
    FILTER_DELETED_CONVERSATION,
    GOT_EXISTING_CONVERSATION_ID,
    GET_LEFT_PANEL_CONVERSATION_COUNT, GET_WARNING_DISCLAIMER_TERMS, GET_WARNING_DISCLAIMER_TEXT,
    CLEAR_RECIPIENT_DETAILS
} from '../types/message-v2';
import {
    SEND_CHAT_MESSAGE,
    UPDATE_CHAT_THREAD,
    UPDATE_CHAT_THREAD_TYPING_STATUS,
    FILTER_DELETED_CHAT,
    UPDATE_CHAT_ONLINE_STATUS,
    NOTIFICATION_CHAT_POP,
    SOCKET_SEEN_CHAT_MESSAGE,
    NEW_CHAT_NOTIFICATION,
    RESET_PRO_MESSAGE_LIMIT
} from '../types/chat';
import {
    REMOVE_DELETED_CONNECTION_MESSAGE
} from '../types/connections';
import { THUMBNAIL, MESSAGE_SECTION_TYPES, NOTIFICATION_TYPES, NOTIFICATION } from "./../types/common";
import { notifyReceiver, notifySeenNotification, notifySeenAllNotification, notifyDeleteConversation } from './../libs/socket';
import { getUnseenTopNavConvIdSet, getNotifiedConvIdSet } from "./auth";
import { getChatConversations } from './chat';
import { getCFObjURL, BUCKET } from '../libs/cfHelper';

export const changeActiveSection = (activeSection) => {
    return function (dispatch) {
        dispatch({type: CHANGE_ACTIVE_SECTION, activeSection: activeSection})
    }
};

export const changeActiveConversation = (activeConversation) => {
    return function (dispatch) {
        dispatch({type: CHANGE_ACTIVE_CONVERSATION, activeConversation: activeConversation})
    }
};

export const emptyActiveConversation = () => {
    return function (dispatch) {
        dispatch({type: CHANGE_ACTIVE_CONVERSATION, activeConversation: null})
    }
};

export const getConversations = (data) => {
    return async function (dispatch) {
        const shouldReset = data.skip === 0;
        let checkedData = data;

        if(data.skip < 0){
            checkedData.skip = 0;
        }

        dispatch({type: GET_CONVERSATIONS + STATUS.FETCHING, shouldReset});
        try {
            const res = await post(ROUTES.LOAD_CONVERSATIONS, checkedData);
            if (res.data.code === 200) {
                // if (data.module === MESSAGE_SECTION_TYPES.PRODUCTS.id) {
                //     // dispatch({ type: SHOW_MARKETPLACE_MSG_NOTIFICATION_COUNT });
                // } else {
                //     // dispatch({ type: SHOW_MSG_NOTIFICATION_COUNT });
                // }
                
                dispatch({
                    type: GET_CONVERSATIONS + STATUS.SUCCESS,
                    conversations: res.data.data,
                    shouldReset
                });
                // dispatch({ type: SHOW_MSG_NOTIFICATION_COUNT });
            } else {
                dispatch({type: GET_CONVERSATIONS + STATUS.FAIL});
            }
        } catch (ex) {
            dispatch({type: GET_CONVERSATIONS + STATUS.FAIL});
        }
    }
};

export const getConversationsCount = (data) => {
    return async function (dispatch) {
        dispatch({type: GET_CONVERSATIONS_COUNT + STATUS.FETCHING});
        try {
            const res = await post(ROUTES.CONVERSATION_COUNT, data);
            if (res.data.code === 200) {
                dispatch({type: GET_CONVERSATIONS_COUNT + STATUS.SUCCESS, count: res.data.data.COUNT});
            } else {
                dispatch({type: GET_CONVERSATIONS + STATUS.FAIL});
            }
        } catch (ex) {
            dispatch({type: GET_CONVERSATIONS + STATUS.FAIL});
        }
    }
};

export const emptyMessages = () => {
    return async function (dispatch) {
        dispatch({type: EMPTY_MESSAGES})
    }
};

export const clearMessageCount = () => {
    return async function (dispatch) {
        dispatch({type: EMPTY_MESSAGES_COUNT})
    }
};

export const clearPartipants = () => {
    return async function (dispatch) {
        dispatch({type: EMPTY_MESSAGES_PARTICIPANTS})
    }
};

export const getMessages = (conversationId, data, checkId = true) => {
    return async function (dispatch) {
        const shouldReset = data.skip === 0;
        dispatch({type: GET_MESSAGES + STATUS.FETCHING, shouldReset});
        try {
            const res = await post(`${ROUTES.FETCH_COVERSATION_MESSAGES}/${conversationId}`, data);
            if (res.data.code === 200) {
                dispatch({
                    type: GET_MESSAGES + STATUS.SUCCESS,
                    messages: res.data.data,
                    shouldReset,
                    conversationId: conversationId,
                    checkId: checkId
                });
            } else {
                dispatch({type: GET_MESSAGES + STATUS.FAIL});
            }
        } catch (ex) {
            dispatch({type: GET_MESSAGES + STATUS.FAIL});
        }
    }
};

export const getMessagesCount = (conversationId, data = {}, checkId = true) => {
    return async function (dispatch) {
        dispatch({type: GET_MESSAGES_COUNT + STATUS.FETCHING});
        try {
            const res = await post(`${ROUTES.GET_COVERSATION_MESSAGE_COUNT}/${conversationId}`, {...data});
            if (res.data.code === 200) {
                dispatch({
                    type: GET_MESSAGES_COUNT + STATUS.SUCCESS,
                    count: res.data.data.COUNT,
                    conversationId: conversationId,
                    checkId: checkId
                });
            } else {
                dispatch({type: GET_MESSAGES_COUNT + STATUS.FAIL});
            }
        } catch (ex) {
            dispatch({type: GET_MESSAGES_COUNT + STATUS.FAIL});
        }
    }
};

export const getConversationParticipants = (messageModule, conversationId, cancelToken, isPopup = false) => {
    return async function (dispatch) {
        dispatch({type: GET_CONVERSATION_PARTICIPANTS + STATUS.FETCHING});
        try {
            const res = await get(`${ROUTES.GET_COVERSATION_PARTICIPANTS}/${messageModule}/${conversationId}`);
            if (res.data.code === 200) {
                if (res.data.data && res.data.data._id && res.data.data._id !== conversationId) {
                    dispatch({type: GOT_EXISTING_CONVERSATION_ID, payload: res.data.data._id});
                }
                dispatch({
                    type: GET_CONVERSATION_PARTICIPANTS + STATUS.SUCCESS,
                    participants: res.data.data.PARTICIPANTS
                });
                if (!isPopup) {
                    res.data.data.PARTICIPANTS.forEach(async p => {
                        if (p.SYSTEM_USER_ID !== localStorage.getItem("userId")) {
                            dispatch({
                                type: SET_RECIPIENT_DETAILS,
                                isSenderActive: p.SYSTEM_USER_STATUS === 1,
                                recipientId: p.SYSTEM_USER_ID
                            });
                            if (p.PHOTO !== "") {
                                const url = getCFObjURL(BUCKET.COMMON)((THUMBNAIL.SMALL + p.PHOTO), true);
                                if (url) {
                                    dispatch({
                                        type: GET_CONVERSATION_PARTICIPANT_IMG,
                                        img: url,
                                        isSenderActive: p.SYSTEM_USER_STATUS === 1,
                                        conversationId: conversationId
                                    })
                                }
                            }
                        }

                    })
                }
            } else {
                dispatch({type: GET_CONVERSATION_PARTICIPANTS + STATUS.FAIL});
            }
        } catch (ex) {
            dispatch({type: GET_CONVERSATION_PARTICIPANTS + STATUS.FAIL});
        }
    };
};

export const getRecipientConnectionStatus = (conversationId) => {
    return async function (dispatch) {
        dispatch({type: GET_RECIPIENT_CONNECTION_STATUS + STATUS.FETCHING});
        try {
            const res = await get(`${ROUTES.GET_RECIPIENT_CONNECTION_STATUS}/${conversationId}`);
            if (res.data.code === 200) {
                dispatch({type: GET_RECIPIENT_CONNECTION_STATUS + STATUS.SUCCESS, connectionStatus: res.data.data});
            } else {
                dispatch({type: GET_RECIPIENT_CONNECTION_STATUS + STATUS.FAIL});
            }
        } catch (ex) {
            dispatch({type: GET_RECIPIENT_CONNECTION_STATUS + STATUS.FAIL});
        }
    };
};

export const clearMessagingRecipiant = () => {
  return async function (dispatch) {
    dispatch({ type: CLEAR_RECIPIENT_DETAILS });
  };
};


const trimMessageRichText = (message, richText) => {
    const lineArray = Object.values(richText.line);
    for (let i = 0; i < lineArray.length; i++) {
        const text = lineArray[i].text;

        if(text.charAt(0) !== message.charAt(0)) {
            delete (richText.line)[i+1];
        } else {
            break;
        }
    }
    return richText;
}

export const sendMessage = (thread, viewData = null, productId = null, socketInfo = null, callBack = null, errorCallback = null) => {
    return async function (dispatch, getState) {
        trimMessageRichText(thread.message, thread.messageRichText)
        let newMessage = {
            _id: `${Math.floor(Date.now() / 100000)}-${Math.random()}`,
            TEXT: thread.message,
            RICH_TEXT: thread.messageRichText,
            MEDIA: [],
            STATUS: 1,
            SENDER_DETAILS: {
                SYSTEM_USER_ID: localStorage.getItem('userId'),
                PHOTO: localStorage.getItem('profileThumbnail'),
                SYSTEM_USER_STATUS: 1
            },
            UPDATED_DATE: new Date().toISOString(),
            IS_PRO_MESSAGE: thread.isProMessage,
            isSending: true
        };
        dispatch({type: SEND_MESSAGE + STATUS.FETCHING, newMessage});
        try {
            let onlineStatus = window.navigator.onLine;
            if (!onlineStatus) {
                throw new Error('Network Error')
            }
            const res = await post(`${ROUTES.SEND_MESSAGE}`, {
                ...thread,
                viewData,
                productId
            });

            if (res.data.code === 200) {
                dispatch({ type: SEND_MESSAGE + STATUS.SUCCESS, message: res.data.data });

                dispatch({ type: CLEAR_ACTION });
                //callback for chat popup
                if(callBack){
                    callBack(res.data.data)
                }
                //notify message receiver
                if (socketInfo && socketInfo.socket && socketInfo.userId && socketInfo.type && (res.data && res.data.data && res.data.data.CONVERSATION)) {
                    const user = getState().auth.user;
                    let userData = res.data.data.CONVERSATION;
                    userData.RECIPENT = [
                        {
                            SYSTEM_USER_ID: user._id,
                            LOWER_NAME: (user.FIRST_NAME + ' ' + user.LAST_NAME).toLowerCase(),
                            NAME: user.FIRST_NAME + ' ' + user.LAST_NAME,
                            FIRST_NAME: user.FIRST_NAME,
                            LAST_NAME: user.LAST_NAME,
                            MEMBER_TYPE: user.MEMBER_TYPE,
                            PHOTO: user.PHOTO,
                            STATUS: 1
                        }
                    ];
                    userData.RECENT_MESSAGE = thread.message;
                    userData.PRODUCT_NAME = socketInfo.productName;
                    userData.LAST_MESSAGE_PRO_STATUS = thread.isProMessage;
                    if (userData.MODULE.toString() === MESSAGE_SECTION_TYPES.CONNECTIONS.id) { userData.msgSource = 'connections' }
                    userData.lastMessageId = (res.data.data && res.data.data.NEW_MESSAGE && res.data.data.NEW_MESSAGE._id) ? res.data.data.NEW_MESSAGE._id : null;  /*added to perform "mark as read" function on toast notification*/
                    newMessage = { ...newMessage, ...res.data.data.NEW_MESSAGE, module: userData.MODULE };
                    if(thread.shouldConversationPanelUpdate){
                        dispatch(getChatConversations({
                            username: '',
                            module: 0,
                            skip: 0,
                            limit: 16
                        }));
                    }

                    notifyReceiver(socketInfo.socket, socketInfo.userId, socketInfo.type, userData, userData._id, newMessage);
                    dispatch({ type: SEND_CHAT_MESSAGE, userId:socketInfo.userId, data: newMessage});
                    if (socketInfo.callback) { socketInfo.callback(res.data.data.CONVERSATION._id) }
                } else {
                    console.error('socket connection failed, invalid data', {
                        userId: socketInfo.userId,
                        type: socketInfo.type
                    })
                }
                dispatch({ type: UPDATE_CHAT_ONLINE_STATUS, conversationId: thread.searchId, message: ''});
                if(thread.isProMessage && res.data.data){
                    dispatch({ type: RESET_PRO_MESSAGE_LIMIT, count: res.data.data.PENDING_PRO_MESSAGES_COUNT ? res.data.data.PENDING_PRO_MESSAGES_COUNT : 0});
                }
            } else if (res.data.code === 220) {
                if (socketInfo && socketInfo.notConnectedHandler) {
                    socketInfo.notConnectedHandler();
                }
                if(errorCallback) errorCallback()
                dispatch({ type: SEND_MESSAGE + STATUS.FAIL });
                dispatch({ type: UPDATE_CHAT_ONLINE_STATUS, conversationId: thread.searchId, message: thread.message});
            } else if (res.data.code === 217) {
                if (socketInfo && socketInfo.errorHandler) {
                    socketInfo.errorHandler(thread.searchId, true);
                }
                if(errorCallback) errorCallback()
                dispatch({ type: SEND_MESSAGE + STATUS.BLOCKED });
                dispatch({ type: UPDATE_CHAT_ONLINE_STATUS, conversationId: thread.searchId, message: thread.message, blockStatus: true});
            } else {
                if (socketInfo && socketInfo.errorHandler) {
                    socketInfo.errorHandler(thread.searchId, false);
                }
                if(errorCallback) errorCallback()
                dispatch({ type: SEND_MESSAGE + STATUS.FAIL });
                dispatch({ type: UPDATE_CHAT_ONLINE_STATUS, conversationId: thread.searchId, message: thread.message});
            }
        } catch (ex) {
            if(errorCallback) errorCallback()
            dispatch({ type: SEND_MESSAGE + STATUS.FAIL });
            dispatch({ type: UPDATE_CHAT_ONLINE_STATUS, conversationId: thread.searchId, message: thread.message});
        }
    }
};

export const deleteConversationThread = (conversationId, socket = null, module = null, recipientId = null) => {
    return async function (dispatch) {
        dispatch({type: DELETE_CONVERSATION_THREAD + STATUS.DELETING, conversationId: conversationId});
        try {
            const res = await post(`${ROUTES.DELETE_CONVERSATION_THREAD}`, {conversationId});
            if (res.data.code === 200) {
                dispatch({ type: DELETE_CONVERSATION_THREAD + STATUS.SUCCESS, conversationId: conversationId });
                dispatch({type: FILTER_DELETED_CHAT, conversationId});
                dispatch({ type: NOTIFICATION.FILTER, payload: conversationId });
                dispatch({ type: CLEAR_ACTION });
                if (socket && module && recipientId) {
                    notifyDeleteConversation(socket, module, conversationId, recipientId);
                }
            } else {
                dispatch({type: DELETE_CONVERSATION_THREAD + STATUS.FAIL});
            }
        } catch (ex) {
            dispatch({type: DELETE_CONVERSATION_THREAD + STATUS.FAIL});
        }
    }
};

export const updateNewMessageNotification = (module, data) => {
    return function (dispatch, getState) {
        try {
            const soFarNotifiedConversationIdSet = getState().messageV2.notifiedConversationIdSet;
            const soFarTopNavUnSeenConvIdSet = getState().messageV2.topNavUnSeenConvIdSet;
            dispatch({ type: NEW_MESSAGE_NOTIFICATION, module: module.toString(), data });
            // Green pages notificatin type is 12 and message module type is 4
            if (module.toString() === MESSAGE_SECTION_TYPES.PERSONAL.id || module.toString() === MESSAGE_SECTION_TYPES.PRODUCTS.id || module === NOTIFICATION_TYPES.GREENPAGES) {
                dispatch(openExistingChat({
                    name: data.RECIPENT[0].NAME,
                    photo: data.RECIPENT[0].PHOTO,
                    userId: data.RECIPENT[0].SYSTEM_USER_ID,
                    coversationId: data._id,
                    module: data.MODULE,
                    newNotification: true
                }));
                dispatch({ type: NEW_CHAT_NOTIFICATION, data });
                dispatch({ type: NOTIFICATION_CHAT_POP, conversationId: data._id });
            }

            const activeConversationId = getState().messageV2.activeConversation;
            const activeConversationSection = getState().messageV2.activeSection;

            if (module === NOTIFICATION_TYPES.PRODUCTS_MSG) {
                dispatch({
                    type: RECEIVED_MARKETPLACE_MSG_NOTIFICATION,
                    activeConversationId,
                    activeConversationSection,
                    soFarNotifiedConversationIdSet,
                    soFarTopNavUnSeenConvIdSet,
                    module,
                    data
                });
            } else if (module === NOTIFICATION_TYPES.PERSONAL_MSG) {
                dispatch({
                    type: RECEIVED_PERSONAL_MSG_NOTIFICATION,
                    activeConversationId,
                    activeConversationSection,
                    soFarNotifiedConversationIdSet,
                    soFarTopNavUnSeenConvIdSet,
                    module,
                    data
                });
            } else if (module === NOTIFICATION_TYPES.CONNECTIONS_MSG) {
                dispatch({
                    type: RECEIVED_CONNECTION_MSG_NOTIFICATION,
                    activeConversationId,
                    activeConversationSection,
                    soFarNotifiedConversationIdSet,
                    soFarTopNavUnSeenConvIdSet,
                    module,
                    data
                });
            } else if (module === NOTIFICATION_TYPES.GREENPAGES) {
                dispatch({
                    type: RECEIVED_GREENPAGES_MSG_NOTIFICATION,
                    activeConversationId,
                    activeConversationSection,
                    soFarNotifiedConversationIdSet,
                    soFarTopNavUnSeenConvIdSet,
                    module,
                    data
                });
            } 
        } catch (ex) {
            console.error('ex', ex)
        }
    }
};

export const updateLastSeenMSG = (conversationId, index, module) => {
    return async function (dispatch, getState) {
        const notifiedConversationIdSet = getState().messageV2.notifiedConversationIdSet;
        const soFarTopNavUnSeenConvIdSet = getState().messageV2.topNavUnSeenConvIdSet;
        if (Number(module) === NOTIFICATION_TYPES.PRODUCTS_MSG && notifiedConversationIdSet.includes(conversationId)) {
            dispatch({type: DECREMENT_MARKETPLACE_MSG_NOTIFICATION, conversationId, soFarTopNavUnSeenConvIdSet});
        } else if (Number(module) === NOTIFICATION_TYPES.GREENPAGES && notifiedConversationIdSet.includes(conversationId)) {
            dispatch({type: DECREMENT_GREENPAGES_MSG_NOTIFICATION, conversationId, soFarTopNavUnSeenConvIdSet});
        } else if (Number(module) === NOTIFICATION_TYPES.PERSONAL_MSG && notifiedConversationIdSet.includes(conversationId)) {
            dispatch({type: DECREMENT_PERSONAL_MSG_NOTIFICATION, conversationId, soFarTopNavUnSeenConvIdSet});
        } else if (Number(module) === NOTIFICATION_TYPES.CONNECTIONS_MSG && notifiedConversationIdSet.includes(conversationId)) {
            dispatch({type: DECREMENT_CONNECTION_MSG_NOTIFICATION, conversationId, soFarTopNavUnSeenConvIdSet});
        }
        dispatch({ type: UPDATE_LAST_SEEN_MSG, conversationId, index, loggedInUserId: localStorage.getItem('userId') });
        dispatch({ type: SOCKET_SEEN_CHAT_MESSAGE, conversationId, index, loggedInUserId: localStorage.getItem('userId')});
    }
};

export const updateActiveConversation = (data) => {
    return function (dispatch) {
        if (data.type === 'msg') {
            dispatch({ type: UPDATE_ACTIVE_CONVERSATION_MSG, data: data.data });
            dispatch({ type: UPDATE_CHAT_THREAD, data: data.data }); // chat message
            dispatch({ type: UPDATE_CHAT_THREAD_TYPING_STATUS, conversationId: data.data.CONVERSATION_ID, status: false }); // chat message
        } else if (data.type === 'typing') {
            dispatch({ type: UPDATE_USER_TYPING_STATUS, conversationId: data.data, status: true });
            setTimeout(() => dispatch({ type: UPDATE_USER_TYPING_STATUS, conversationId: data.data, status: false }), 3000)
            // chat message
            if(data.userId && data.userId !== localStorage.getItem('userId')){
                dispatch({ type: UPDATE_CHAT_THREAD_TYPING_STATUS, conversationId: data.data, status: true });
                setTimeout(() => dispatch({ type: UPDATE_CHAT_THREAD_TYPING_STATUS, conversationId: data.data, status: false }), 3000)
            }
        }
        dispatch({type: CLEAR_ACTION});
    }
};

export const pushNotifications = (notification) => {
    return function (dispatch, getState) {
        const activeConversation = (getState().messageV2 && getState().messageV2.activeConversation) ? getState().messageV2.activeConversation : null;
        if ((activeConversation === null) || (activeConversation && activeConversation.toString() !== notification._id.toString())) {
            dispatch({
                type: NOTIFICATION.PUSH, payload: notification
            });
        }
    }
};

export const pullNotifications = (notificationId) => {
    return function (dispatch) {
        dispatch({
            type: NOTIFICATION.PULL, payload: notificationId
        });
    }
};

export const generateImage = async (image) => {
    try {

        const img = getCFObjURL(BUCKET.COMMON)((THUMBNAIL.SMALL + image), true);
        if (img) {
            return img;
        }

        return null;

    } catch (e) {
        return null;
    }
};

export const markAsRead = (data) => {
    return async function (dispatch, getState) {

        const conversations = getState().messageV2.conversations;
        let index = null;
        if (conversations.length > 0) {
            conversations.some((item, count) => {
                if (item._id === data.conversationId) {
                    index = count
                }
                return item._id === data.conversationId
            });
        }

        if (data && data.conversationId && data.messageId) {

            if (data.socket && data.module) {
                let type = data.module.toString() === MESSAGE_SECTION_TYPES.GREENPAGES.id ? NOTIFICATION_TYPES.GREENPAGES : data.module
                notifySeenNotification(data.socket, localStorage.getItem('userId'), type, {
                    conversationId: data.conversationId,
                    index: index
                });
            } else {
                console.error('Notification count update Failed: Invalid data 1');
            }
            const res = await post(`${ROUTES.SET_CONVERSATION_MESSAGES_AS_READ}`,
                {makeSeenArray: [data.messageId], conversationId: data.conversationId, updateCounts: true});
            if (res.data.code !== 200) {
                console.error('Notification count update Failed: Server Error')
            }

        } else {
            console.error('Notification count update error: Invalid data 2');
        }
    }
};

export const openMessage = (data) => {
    return function (dispatch) {
        dispatch({type: OPEN_MESSAGE, payload: data});
    }
};

export const markAsReadByModule = (socket, data) => {
    return async function (dispatch) {

        try {
            const res = await post(`${ROUTES.MARK_ALL_MESSAGES_AS_READ}`, {module: data.module});
            if (res.data.code === 200) {
                notifySeenAllNotification(socket, localStorage.getItem('userId'), data);
            } else {
                console.error('See All Messages DB update failed')
            }
        } catch (ex) {
            console.error(ex)
        }

    }
};

export const resetMessageCounters = (module, count) => {
    return function (dispatch) {
        if ([MESSAGE_SECTION_TYPES.PERSONAL.id, MESSAGE_SECTION_TYPES.CONNECTIONS.id, MESSAGE_SECTION_TYPES.PRODUCTS.id, MESSAGE_SECTION_TYPES.GREENPAGES.id].includes(module.toString())) {
            dispatch({type: MARK_ALL_MESSAGES_READ, module, count});
            dispatch({type: MARK_ALL_CONVERSATIONS_READ, module});
            dispatch(getUnseenTopNavConvIdSet());
            dispatch(getNotifiedConvIdSet());
        } else {
            console.error('Unknown module id')
        }
    }
};

export const removeConnectionConversation = (data) => {
    return function (dispatch) {
        dispatch({type: REMOVE_CONNECTION_CONVERSATION, data})
    }
};

export const filterDeletedConversation = (msgType, conversationId) => {
    return function (dispatch) {
        dispatch({type: FILTER_DELETED_CONVERSATION, conversationId});
        dispatch({type: FILTER_DELETED_CHAT, conversationId});
        if (msgType.toString() === MESSAGE_SECTION_TYPES.CONNECTIONS.id){
            dispatch({type: REMOVE_DELETED_CONNECTION_MESSAGE, conversationId})
        }
    }
};

export const getLeftPanelCoversationCounts = () => {
    return async function (dispatch) {
        dispatch({type: GET_LEFT_PANEL_CONVERSATION_COUNT + STATUS.FETCHING});
        try {
            const res = await get(ROUTES.GET_LEFT_PANEL_CONVERSATION_COUNT);
            if (res.data.code === 200) {
                dispatch({type: GET_LEFT_PANEL_CONVERSATION_COUNT + STATUS.SUCCESS, payload: res.data.data[0]});
            } else {
                dispatch({type: GET_LEFT_PANEL_CONVERSATION_COUNT + STATUS.FAIL});
            }
        } catch (ex) {
            dispatch({type: GET_LEFT_PANEL_CONVERSATION_COUNT + STATUS.FAIL});
        }
    }
};

export const fetchWarningDisclaimerTerms = () => {
    return async function (dispatch) {
        try {
            const response = await get(ROUTES.GET_WARNING_DISCLAIMER_TERMS)

            if (response && response.data && response.data.code === 200) {
                dispatch({ type: GET_WARNING_DISCLAIMER_TERMS, payload: response.data.data });
            } else {
                dispatch({ type: GET_WARNING_DISCLAIMER_TERMS, payload: [] });
            }
        } catch (exception) {
            dispatch({ type: GET_WARNING_DISCLAIMER_TERMS, payload: [] });
        }
    }
};

export const fetchWarningDisclaimerText = () => {
    return async function (dispatch) {
        try {
            const response = await get(ROUTES.GET_WARNING_DISCLAIMER_TEXT)

            if (response && response.data && response.data.code === 200 && response.data.data && response.data.data.length > 0) {
                dispatch({ type: GET_WARNING_DISCLAIMER_TEXT, payload: response.data.data[0].WARNING_DISCLAIMER_TEXT });
            } else {
                dispatch({ type: GET_WARNING_DISCLAIMER_TEXT, payload: null });
            }
        } catch (exception) {
            dispatch({ type: GET_WARNING_DISCLAIMER_TEXT, payload: null });
        }
    }
};

export const clearAction = () => {
    return function (dispatch) {
        dispatch({type: CLEAR_ACTION})
    }
};
