import {
    STATUS,
    CLEAR_ACTION,
    FETCHING_GROUPS,
    FETCH_GROUPS,
    CLEAR_GROUPS,
    JOINING_GROUP,
    JOIN_GROUP,
    LEAVING_GROUP,
    LEAVE_GROUP,
    CLOSE_MOBILE_POPUP
} from '../types/groups';

export const initialState = {
    action: 0,
    fetchingGroups: false,
    groups: [],
    trendingGroupPost: {}
};

export const groups = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_ACTION:
            return {
                ...state,
                action: 0
            };
        case FETCHING_GROUPS:
            return {
                ...state,
                action: FETCHING_GROUPS,
                fetchingGroups: true,
            };
        case FETCH_GROUPS + STATUS.SUCCESS:
            return {
                ...state,
                fetchingGroups: false,
                groups: action.payload.data,
                action: FETCH_GROUPS + STATUS.SUCCESS,
            };
        case FETCH_GROUPS + STATUS.FAIL:
            return {
                ...state,
                fetchingGroups: false,
                action: FETCH_GROUPS + STATUS.FAIL,
            };
        case CLEAR_GROUPS:
            return {
                ...state,
                action: CLEAR_GROUPS,
                groups: []
            };
        case JOINING_GROUP:
            return {
                ...state,
                action: JOINING_GROUP
            };
        case JOIN_GROUP + STATUS.SUCCESS:
            const tempGroups = [...state.groups];
            tempGroups[action.payload.index].IS_JOINED = true;
            return {
                ...state,
                groups: tempGroups,
                action: JOIN_GROUP + STATUS.SUCCESS,
            };
        case JOIN_GROUP + STATUS.FAIL:
            return {
                ...state,
                action: JOIN_GROUP + STATUS.FAIL,
            };

        case LEAVING_GROUP:
            return {
                ...state,
                action: LEAVING_GROUP
            };
        case LEAVE_GROUP + STATUS.SUCCESS:
            const leaveGroups = state.groups.map(g => {return {...g , IS_JOINED: g._id === action.payload.groupId ? false : g.IS_JOINED}});
            return {
                ...state,
                groups: leaveGroups,
                action: LEAVE_GROUP + STATUS.SUCCESS,
            };
        case LEAVE_GROUP + STATUS.FAIL:
            return {
                ...state,
                action: LEAVE_GROUP + STATUS.FAIL,
            };
        case CLOSE_MOBILE_POPUP:
            return {
                ...state,
                action: CLOSE_MOBILE_POPUP
            };

        default:
            return state
    }
}