module.exports = {
    FETCHING_PROFILE_DATA: "FETCHING_PROFILE_DATA",
    FETCH_PROFILE_DATA_SUCCESS: "FETCH_PROFILE_DATA_SUCCESS",
    FETCH_PROFILE_DATA_FAIL: "FETCH_PROFILE_DATA_FAIL",

    ADD_POST_SUCCESS: "ADD_POST_SUCCESS",
    ADD_POST_FAIL: "ADD_POST_FAIL",
    ADD_POST_EXCEPTION: "ADD_POST_EXCEPTION",

    EDIT_POST_SUCCESS: "EDIT_POST_SUCCESS",
    EDIT_POST_FAIL: "EDIT_POST_FAIL",
    EDIT_POST_EXCEPTION: "EDIT_POST_EXCEPTION",

    FETCHING_NEWS_FEEDS: "FETCHING_NEWS_FEEDS",
    FETCH_NEWS_FEED_SUCCESS: "FETCH_NEWS_FEED_SUCCESS",
    FETCH_NEWS_FEED_FAIL: "FETCH_NEWS_FEED_FAIL",
    FETCH_NEWS_FEED_EXCEPTION: "FETCH_NEWS_FEED_EXCEPTION",
    FETCH_NEWS_FEED_INIT_ERROR: "FETCH_NEWS_FEED_INIT_ERROR",
    CLEAR_NEWS_FEEDS: "CLEAR_NEWS_FEEDS",

    LIKE_POST_SUCCESS: "LIKE_POST_SUCCESS",
    UNLIKE_POST_SUCCESS: "UNLIKE_POST_SUCCESS",

    FETCHING_NEWS_FEED_COUNT: "FETCHING_NEWS_FEED_COUNT",
    FETCH_NEWS_FEED_COUNT_SUCCESS: "FETCH_NEWS_FEED_COUNT_SUCCESS",
    FETCH_NEWS_FEED_COUNT_FAIL: "FETCH_NEWS_FEED_COUNT_FAIL",
    FETCH_NEWS_FEED_COUNT_EXCEPTION: "FETCH_NEWS_FEED_COUNT_EXCEPTION",
    CLEAR_NEWS_FEED_COUNT: "CLEAR_NEWS_FEED_COUNT",

    POST_ARCHIVE_SUCCESS: "POST_ARCHIVE_SUCCESS",
    POST_ARCHIVE_FAIL: "POST_ARCHIVE_FAIL",

    POST_REMOVE: "POST_REMOVE",

    FETCHING_POST: "FETCHING_POST",
    FETCH_POST_SUCCESS: "FETCH_POST_SUCCESS",
    FETCH_POST_FAIL: "FETCH_POST_FAIL",
    FETCH_POST_EXCEPTION: "FETCH_POST_EXCEPTION",
    CLEAR_SINGLE_POST: "CLEAR_SINGLE_POST",

    FETCHING_SUGGESTIONS: "FETCHING_SUGGESTIONS",
    FETCHING_SUGGESTIONS_SUCCESS: "FETCHING_SUGGESTIONS_SUCCESS",
    FETCHING_SUGGESTIONS_FAIL: "FETCHING_SUGGESTIONS_FAIL",
    CLEAR_SUGGESTIONS: "CLEAR_SUGGESTIONS",

    ADDING_SUGGESTIONS: "ADDING_SUGGESTIONS",
    ADD_SUGGESTION_SUCCESS: "ADD_SUGGESTION_SUCCESS",
    ADD_SUGGESTION_FAIL: "ADD_SUGGESTION_FAIL",

    COMMENT_POST: "COMMENT_POST",
    COMMENT_POST_SUCCESS: "COMMENT_POST_SUCCESS",
    COMMENT_POST_FAIL:"COMMENT_POST_FAIL",

    DELETE_COMMENT_POST: "DELETE_COMMENT_POST",
    DELETE_COMMENT_POST_SUCCESS: "DELETE_COMMENT_POST_SUCCESS",
    DELETE_COMMENT_POST_FAIL:"DELETE_COMMENT_POST_FAIL",

    GET_COMMENTS: "GET_COMMENTS",
    GET_COMMENTS_SUCCESS: "GET_COMMENTS_SUCCESS",
    GET_COMMENTS_FAIL: "GET_COMMENTS_FAIL",

    FETCHING_UP_COMING_EVENTS: "FETCHING_UP_COMING_EVENTS",
    FETCHING_UP_COMING_EVENTS_SUCCESS: "FETCHING_UP_COMING_EVENTS_SUCCESS",
    FETCHING_UP_COMING_EVENTS_FAIL: "FETCHING_UP_COMING_EVENTS_FAIL",
    CLEAR_UP_COMING_EVENTS: "CLEAR_UP_COMING_EVENTS",

    FETCHING_FUNDRAISING_COMPANIES: "FETCHING_FUNDRAISING_COMPANIES",
    FETCH_FUNDRAISING_COMPANIES_SUCCESS: "FETCH_FUNDRAISING_COMPANIES_SUCCESS",
    FETCH_FUNDRAISING_COMPANIES_FAIL: "FETCH_FUNDRAISING_COMPANIES_FAIL",
    CLEAR_FUNDRAISING_COMPANIES: "CLEAR_FUNDRAISING_COMPANIES",

    FETCHING_FUNDRAISING_COMPANY_COUNT: "FETCHING_FUNDRAISING_COMPANY_COUNT",
    FETCH_FUNDRAISING_COMPANY_COUNT_SUCCESS: "FETCH_FUNDRAISING_COMPANY_COUNT_SUCCESS",
    FETCH_FUNDRAISING_COMPANY_COUNT_FAIL: "FETCH_FUNDRAISING_COMPANY_COUNT_FAIL",
    CLEAR_FUNDRAISING_COMPANY_COUNT: "CLEAR_FUNDRAISING_COMPANY_COUNT",

    FETCHING_POST_LIKED_USERS: "FETCHING_POST_LIKED_USERS",
    FETCH_POST_LIKED_USERS_SUCCESS: "FETCH_POST_LIKED_USERS_SUCCESS",
    FETCH_POST_LIKED_USERS_FAIL: "FETCH_POST_LIKED_USERS_FAIL",
    CLEAR_POST_LIKED_USERS: "CLEAR_POST_LIKED_USERS",

    ACCEPTING_CONNECTION: "ACCEPTING_CONNECTION",
    CONNECTION_ACCEPT_SUCCESS: "CONNECTION_ACCEPT_SUCCESS",
    CONNECTION_ACCEPT_FAIL: "CONNECTION_ACCEPT_FAIL",

    REJECTING_CONNECTION: "REJECTING_CONNECTION",
    CONNECTION_REJECT_SUCCESS: "CONNECTION_REJECT_SUCCESS",
    CONNECTION_REJECT_FAIL: "CONNECTION_REJECT_FAIL",

    ADDING_CONNECTION : "ADDING_CONNECTION",
    CONNECTION_ADD_SUCCESS : "CONNECTION_ADD_SUCCESS",
    CONNECTION_ADD_FAIL : "CONNECTION_ADD_FAIL",

    FETCHING_COMMENTER_DETAILS: "FETCHING_COMMENTER_DETAILS",
    FETCH_COMMENTER_DETAILS_SUCCESS: "FETCH_COMMENTER_DETAILS_SUCCESS",
    FETCH_COMMENTER_DETAILS_FAIL: "FETCH_COMMENTER_DETAILS_FAIL",

    FETCH_MORE_COMMENTS: "FETCH_MORE_COMMENTS",
    FETCH_MORE_COMMENTS_SUCCESS: "FETCH_MORE_COMMENTS_SUCCESS",
    FETCH_MORE_COMMENTS_FAIL: "FETCH_MORE_COMMENTS_FAIL",


    FETCH_TEXT_LINK: "FETCH_TEXT_LINK",
    FETCH_TEXT_LINK_SUCCESS: "FETCH_TEXT_LINK_SUCCESS",
    FETCH_TEXT_LINK_FAIL: "FETCH_TEXT_LINK_FAIL",

    FETCH_TEXT_LINK_COUNT: "FETCH_TEXT_LINK_COUNT",
    FETCH_TEXT_LINK_COUNT_SUCCESS: "FETCH_TEXT_LINK_COUNT_SUCCESS",
    FETCH_TEXT_LINK_COUNT_FAIL: "FETCH_TEXT_LINK_COUNT_FAIL",

    FETCH_TAG_SUGGESTION: "FETCH_TAG_SUGGESTION",
    FETCH_TAG_SUGGESTION_SUCCESS: "FETCH_TAG_SUGGESTION_SUCCESS",
    FETCH_TAG_SUGGESTION_FAIL: "FETCH_TAG_SUGGESTION_FAIL",

    CLEAR_TEXT_LINK_AND_COUNT: 'CLEAR_TEXT_LINK_AND_COUNT',

    CLEAR_ACTION: "CLEAR_ACTION",

    // GET_IMAGE_URL: "GET_IMAGE_URL",

    UPDATE_COMMENTS_COUNT: "UPDATE_COMMENTS_COUNT",

    FETCHING_TRENDING_POST: 'FETCHING_TRENDING_POST',
    FETCH_TRENDING_POST_SUCCESS: 'FETCH_TRENDING_POST_SUCCESS',
    FETCH_TRENDING_POST_FAIL: 'FETCH_TRENDING_POST_FAIL',
    CLEAR_TRENDING_POST: 'CLEAR_TRENDING_POST',

    SET_INDUSTRY_WIDGET_TITLE: 'SET_INDUSTRY_WIDGET_TITLE',
    SET_TRENDING_TOPIC_WIDGET_TITLE: 'SET_TRENDING_TOPIC_WIDGET_TITLE',

    FETCHING_TRENDING_GROUP_POST: 'FETCHING_TRENDING_GROUP_POST',
    FETCH_TRENDING_GROUP_POST_SUCCESS: 'FETCH_TRENDING_GROUP_POST_SUCCESS',
    FETCH_TRENDING_GROUP_POST_FAIL: 'FETCH_TRENDING_GROUP_POST_FAIL',

    LIKE_COMMENT: 'LIKE_COMMENT',
    LIKE_COMMENT_SUCCESS: 'LIKE_COMMENT_SUCCESS',
    LIKE_COMMENT_FAIL: 'LIKE_COMMENT_FAIL',

    UNLIKE_COMMENT: 'UNLIKE_COMMENT',
    UNLIKE_COMMENT_SUCCESS: 'UNLIKE_COMMENT_SUCCESS',
    UNLIKE_COMMENT_FAIL: 'UNLIKE_COMMENT_FAIL',

    GET_LIKES_FOR_COMMENTS: 'GET_LIKES_FOR_COMMENTS',
    GET_LIKES_FOR_COMMENTS_SUCCESS: 'GET_LIKES_FOR_COMMENTS_SUCCESS',
    GET_LIKES_FOR_COMMENTS_FAIL: 'GET_LIKES_FOR_COMMENTS_FAIL',

    FETCH_NEWSFEED_SINGLE_POST: 'FETCH_NEWSFEED_SINGLE_POST',
    FETCH_NEWSFEED_SINGLE_POST_SUCCESS: 'FETCH_NEWSFEED_SINGLE_POST_SUCCESS',
    FETCH_NEWSFEED_SINGLE_POST_FAIL: 'FETCH_NEWSFEED_SINGLE_POST_FAIL',
    CLEAR_NEWSFEED_SINGLE_POST: 'CLEAR_NEWSFEED_SINGLE_POST',

    FETCH_NEWSFEED_SINGLE_POST_ALL_COMMENTS: 'FETCH_NEWSFEED_SINGLE_POST_ALL_COMMENTS',

    CLEAR_TRENDING_GROUP_POST: 'CLEAR_TRENDING_GROUP_POST',

    FETCH_USER_FEED: "FETCH_USER_FEED",
    FETCH_USER_FEED_SUCCESS: "FETCH_USER_FEED_SUCCESS",
    FETCH_USER_FEED_FAIL: "FETCH_USER_FEED_FAIL",
    CLEAR_USER_FEED: "CLEAR_USER_FEED",

    FETCH_OTHER_USER_FEED: "FETCH_OTHER_USER_FEED",
    FETCH_OTHER_USER_FEED_SUCCESS: "FETCH_OTHER_USER_FEED_SUCCESS",
    FETCH_OTHER_USER_FEED_FAIL: "FETCH_OTHER_USER_FEED_FAIL",
    CLEAR_OTHER_USER_FEED: "CLEAR_OTHER_USER_FEED",

    FETCH_USER_FEED_COUNT: "FETCH_USER_FEED_COUNT",
    FETCH_USER_FEED_COUNT_SUCCESS: "FETCH_USER_FEED_COUNT_SUCCESS",
    FETCH_USER_FEED_COUNT_FAIL: "FETCH_USER_FEED_COUNT_FAIL",

    FETCH_OTHER_USER_FEED_COUNT: "FETCH_OTHER_USER_FEED_COUNT",
    FETCH_OTHER_USER_FEED_COUNT_SUCCESS: "FETCH_OTHER_USER_FEED_COUNT_SUCCESS",
    FETCH_OTHER_USER_FEED_COUNT_FAIL: "FETCH_OTHER_USER_FEED_COUNT_FAIL",

    UPLOADING_NEWSFEED_VIDEO: 'UPLOADING_NEWSFEED_VIDEO',
    PROCESSING_NEWSFEED_VIDEO: 'PROCESSING_NEWSFEED_VIDEO',
    PROCESS_COMPLETE_NEWSFEED_VIDEO: 'PROCESS_COMPLETE_NEWSFEED_VIDEO',
    CLEAR_PROCESS_COMPLETE_NEWSFEED_VIDEO: 'CLEAR_PROCESS_COMPLETE_NEWSFEED_VIDEO',

    SET_PENDING_POST: 'SET_PENDING_POST',
    CLEAR_PENDING_POST: 'CLEAR_PENDING_POST',
    EMPTY_PENDING_POST: 'EMPTY_PENDING_POST',

    SET_RECENT_POST: 'SET_RECENT_POST',

    FORCE_REFRESH: 'FORCE_REFRESH',

    CLEAR_NEWSFEED_SINGLE_POST_BY_USER: 'CLEAR_NEWSFEED_SINGLE_POST_BY_USER',

    CLEAR_NEWSFEED_REACTED_DATA: 'CLEAR_NEWSFEED_REACTED_DATA',

    BLOCK_POST_SUCCESS: 'BLOCK_POST_SUCCESS',
    BLOCK_USER_SUCCESS: 'BLOCK_USER_SUCCESS'

}