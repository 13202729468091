import React, { Component } from 'react';
import './index.css';

class ChatWarning extends Component {

    render() {
        return (
            <div className="message-warning-disclaimer">
    <div className="warning-header"><i className="far fa-exclamation-triangle m-r10"></i>{this.props.title}</div>
                <div className="warning-body">{this.props.messageText}</div>
            </div>
        );
    }
}

export default ChatWarning;