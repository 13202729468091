module.exports = {
    FETCHING_NORMAL_USERS: 'FETCHING_NORMAL_USERS',
    FETCHING_NORMAL_USERS_SUCCESS: 'FETCHING_NORMAL_USERS_SUCCESS',
    FETCHING_NORMAL_USERS_FAIL: 'FETCHING_NORMAL_USERS_FAIL',

    FETCHING_ADMINS: 'FETCHING_ADMINS',
    FETCHING_ADMINS_SUCCESS: 'FETCHING_ADMINS_SUCCESS',
    FETCHING_ADMINS_FAIL: 'FETCHING_ADMINS_FAIL',

    SAVING_ADMIN_CHANGES: 'SAVING_ADMIN_CHANGES',
    SAVING_ADMIN_CHANGES_SUCCESS: 'SAVING_ADMIN_CHANGES_SUCCESS',
    SAVING_ADMIN_CHANGES_FAIL: 'SAVING_ADMIN_CHANGES_FAIL',

    CLEAR_ACTION: "CLEAR_ACTION",
}