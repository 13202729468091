import proTypes from '../types/pro';

export const initialState = {
    fetching: false,
    sending: false,
    isOpenInviteModal: false,
    isOpenQuotaExceededModal: false,
    currentUser: {
        IS_PRO_MEMBER: false,
        ALLOCATED_PRO_INVITES_COUNT: 0,
        SENT_PRO_INVITES_COUNT: 0,
        FIRST_NAME: '',
        LAST_NAME: '',
        PHOTO: '',
    },
    sentInvitations: [],
    trackingId: null,
    isFetchingCurrentUser: false,
    isFetchingReceivedInvitations: false,
    receivedInvitations: [],
    eventDiscountData:{}
};

export const pro = (state = initialState, action) => {
    switch (action.type) {
        case proTypes.OPEN_INVITE_MODAL:
            return {
                ...state,
                isOpenInviteModal: true,
            };
        case proTypes.CLOSE_INVITE_MODAL:
            return {
                ...state,
                isOpenInviteModal: false,
            };
        case proTypes.OPEN_QUOTA_EXCEEDED_MODAL:
            return {
                ...state,
                isOpenQuotaExceededModal: true,
            };
        case proTypes.CLOSE_QUOTA_EXCEEDED_MODAL:
            return {
                ...state,
                isOpenQuotaExceededModal: false,
            };
        case proTypes.START_FETCHING:
            return {
                ...state,
                fetching: true,
            };
        case proTypes.STOP_FETCHING:
            return {
                ...state,
                fetching: false,
            };
        case proTypes.START_SENDING:
            return {
                ...state,
                sending: true,
            };
        case proTypes.STOP_SENDING:
            return {
                ...state,
                sending: false,
            };
        case proTypes.FETCHING_USER_PRO_DATA:
            return {
                ...state,
                isFetchingCurrentUser: true
            }
        case proTypes.UPDATE_USER_PRO_DATA:
            return {
                ...state,
                isFetchingCurrentUser: false,
                currentUser: {
                    IS_PRO_MEMBER: action.payload.IS_PRO_MEMBER,
                    ALLOCATED_PRO_INVITES_COUNT: action.payload.ALLOCATED_PRO_INVITES_COUNT,
                    SENT_PRO_INVITES_COUNT: action.payload.SENT_PRO_INVITES_COUNT,
                    FIRST_NAME: action.payload.FIRST_NAME,
                    LAST_NAME: action.payload.LAST_NAME,
                    PHOTO: action.payload.PHOTO,
                },
            };
        case proTypes.FETCHING_USER_PRO_DATA_FAIL:
            return {
                ...state,
                isFetchingCurrentUser: false
            }
        case proTypes.UPDATE_SENT_INVITATIONS:
            return {
                ...state,
                sentInvitations: action.payload,
            };
        case proTypes.UPDATE_SENT_PRO_INVITES_COUNT:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    SENT_PRO_INVITES_COUNT: action.payload,
                },
            };
        case proTypes.FETCH_RECEIVED_INVITATIONS:
            return {
                ...state,
                isFetchingReceivedInvitations: true
            };
        case proTypes.FETCH_RECEIVED_INVITATIONS_SUCCESS:
            return {
                ...state,
                receivedInvitations: action.payload,
                isFetchingReceivedInvitations: false
            };
        case proTypes.FETCH_RECEIVED_INVITATIONS_FAIL:
            return {
                ...state,
                isFetchingReceivedInvitations: false
            };
        case proTypes.UPDATE_PRO_INVITES_TRACKING_DATA:
            return {
                ...state,
                trackingId: action.payload,
            };
        case proTypes.GET_PROFILE_EVENT_DISCOUNT:
            return {
                ...state,
                action: proTypes.GET_PROFILE_EVENT_DISCOUNT
            }
        case proTypes.GET_PROFILE_EVENT_DISCOUNT_SUCCESS:
            return {
                ...state,
                action:proTypes.GET_PROFILE_EVENT_DISCOUNT_SUCCESS,
                eventDiscountData: action.payload
            }
        case proTypes.GET_PROFILE_EVENT_DISCOUNT_FAIL:
            return {
                ...state,
                action:proTypes.GET_PROFILE_EVENT_DISCOUNT_FAIL,
            }

        default:
            return state;
    }
    ;
};