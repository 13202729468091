import React,{useEffect} from "react";

const TransparentCard = ({ children, title, icon, scrollToPeople}) => {

  useEffect(() => {
    if(scrollToPeople){
      var peopleSection = document.getElementById("company-people");
      peopleSection.scrollIntoView({behavior: "smooth"});
    }
  }, [scrollToPeople]);

  return (
    <div className="view_card mb-4" id={'company-people'}>
      <div className="tp_card_header d-flex p-all25">
        <div>{icon}</div>
        <h3 className="font_20 font_dark_gray_two font_medium m-l15">{title}</h3>
      </div>
      {children}
    </div>
  );
};
export { TransparentCard };
