import { WELCOME_POPUP_COMPULSORY_FROM } from './../types/common';
import moment from 'moment';
import * as ApiConnector from './apiConnector';
import { ROUTES } from './../config/server';

export async function authUser() {
    const token = localStorage.getItem('token');
    if (!token) {
        return false;
    }
    return true;
}

export const shouldShowWelcomePopup = (createdDate) => {

    let date = moment(WELCOME_POPUP_COMPULSORY_FROM)
    let created = moment(createdDate);
    return (created > date);
};

export async function checkIndustryRoles() {
    try {
        let res = await ApiConnector.get(ROUTES.CHECK_INDUSTRY_ROLES);
        if (res.data.code === 200) {
            return (res.data && res.data.data && res.data.data.shouldShowPopup);
        } else {
            return false
        }
    } catch (ex) {
        console.error(ex);
        return false
    }
}
