import axios from 'axios';
import * as ApiConnector from './../libs/apiConnector';
import { ROUTES } from '../config';
import {
    STATUS,
    FETCHING_GROUPS,
    FETCH_GROUPS,
    JOINING_GROUP,
    JOIN_GROUP,
    LEAVING_GROUP,
    LEAVE_GROUP,
    CLEAR_GROUPS,
    CLOSE_MOBILE_POPUP,
    CLEAR_ACTION
} from '../types/groups';
import NewsFeedTypes from '../types/newsFeed';
import * as NewsFeedAction from './newsFeed';
import { POP_ALERT } from '../types/common';
import { showPopupAlert } from './alert';
import {get, post, put} from "../libs/apiConnector";

export const createPost = (newPost) => async dispatch => {
    try {
        const { status } = await post(ROUTES.ADD_GROUP_POST, { ...newPost } )
        if (status === 200) {
            dispatch({ type: NewsFeedTypes.ADD_POST_SUCCESS, payload: newPost });
        } else {
            dispatch({ type: NewsFeedTypes.FETCH_PROFILE_DATA_FAIL });
        }
        dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
    } catch (ex) {
        console.log(ex);
        dispatch({ type: NewsFeedTypes.FETCH_PROFILE_DATA_FAIL });
        dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
    }
}

export const fetchGroups = () => async dispatch => {
    try {
        dispatch({ type: FETCHING_GROUPS });
        const response = await get(ROUTES.FETCH_GROUPS );
        if (response.data.code === 200) {
            dispatch({ type: FETCH_GROUPS + STATUS.SUCCESS, payload: response.data });
        } else {
            dispatch({ type: FETCH_GROUPS + STATUS.FAIL });
        }
        dispatch({ type: CLEAR_ACTION });
    } catch (ex) {
        console.error(ex);
        dispatch({ type: FETCH_GROUPS + STATUS.FAIL });
    }
}

export const fetchGroupFeed = (group, cancelToken) => async dispatch => {
    try {
        dispatch({ type: NewsFeedTypes.FETCHING_NEWS_FEEDS });
        const { status, data } = await get(`${ROUTES.FETCH_GROUP_FEED}/${group.id}/${group.skip}/${group.limit}`, { cancelToken })
        if (status === 200 && data.code === 200) {
            dispatch({ type: NewsFeedTypes.FETCH_NEWS_FEED_SUCCESS, payload: data, isInit: group.skip === 0 });
        } else if (data.code === 212) { // Data Not Found
            dispatch({ type: NewsFeedTypes.FETCH_NEWS_FEED_SUCCESS, payload: data });
        } else if (data.code === 216) { // DB Error Occured
            dispatch({ type: NewsFeedTypes.FETCH_NEWS_FEED_FAIL });
        } else if (data.code === 404) { // Server Side Exception Occured
            dispatch({ type: NewsFeedTypes.FETCH_NEWS_FEED_EXCEPTION });
        }
    } catch (ex) {
        if (axios.isCancel(ex)) {
            return;
        } else {
            dispatch({ type: NewsFeedTypes.FETCH_NEWS_FEED_EXCEPTION });
            dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
        }
    }
}

export const fetchGroupFeedCount = (groupId, cancelToken) => async dispatch => {

    try {
        dispatch({ type: NewsFeedTypes.FETCHING_NEWS_FEED_COUNT });
        const { status, data } = await get(`${ROUTES.GROUP_FEED_COUNT}/${groupId}`, { cancelToken })
        if (status === 200 && data.code === 200) {
            dispatch({ type: NewsFeedTypes.FETCH_NEWS_FEED_COUNT_SUCCESS, payload: data });
        } else if (data.code === 212) { // Data Not Found
            dispatch({ type: NewsFeedTypes.FETCH_NEWS_FEED_COUNT_SUCCESS, payload: data });
        } else if (data.code === 216) { // DB Error Occured
            dispatch({ type: NewsFeedTypes.FETCH_NEWS_FEED_COUNT_FAIL });
        } else if (data.code === 404) { // Server Side Exception Occured
            dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
        }
        dispatch({ type: NewsFeedTypes.CLEAR_ACTION });

    } catch (ex) {
        if (axios.isCancel(ex)) {
            return;
        } else {
            dispatch({ type: NewsFeedTypes.FETCH_NEWS_FEED_COUNT_EXCEPTION });
            dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
        }
    }
}

export const likePost = (groupId, postId, reaction) => async dispatch => {

    try {
        const { status, data } = await put(`${ROUTES.GROUP_ADD_LIKE}${groupId}/${postId}`, {userEmotion: reaction} )
        if (status === 200 && data.code === 200) {
            if (data.data) {
                dispatch({ type: NewsFeedTypes.LIKE_POST_SUCCESS, payload: postId, reaction })
            } else {
                dispatch({ type: NewsFeedTypes.UNLIKE_POST_SUCCESS, payload: postId })
            }
        }
    } catch (ex) {
        dispatch({ type: NewsFeedTypes.UNLIKE_POST_SUCCESS, payload: postId })
    }

}

export const addComment = (groupId, postId, comment, type, limit, richText) => async dispatch => {

    try {
        dispatch({ type: NewsFeedTypes.COMMENT_POST });
        const { status, data } = await put(`${ROUTES.GROUP_ADD_COMMENT + groupId + '/' + postId}`, { comment, richText })
        if (status === 200 && data.code === 200) {
            dispatch({ type: NewsFeedTypes.COMMENT_POST_SUCCESS, payload: data, postId: postId });
            if (type === 'single') {
                dispatch(NewsFeedAction.getSinglePost(postId))
            } else {
                dispatch(NewsFeedAction.getComments(postId, 0, 10));
            }
        } else {
            dispatch({ type: NewsFeedTypes.COMMENT_POST_FAIL, payload: postId })
        }

    } catch (ex) {
        dispatch({ type: NewsFeedTypes.COMMENT_POST_FAIL, payload: postId })
    }

}

export const archivePost = (postId) => async dispatch => {

    try {

        const { status, data } = await ApiConnector.put(ROUTES.ARCHIVE_GROUP_POST + postId, {} );

        if (status === 200 && data.code === 200) {
            dispatch({ type: NewsFeedTypes.POST_ARCHIVE_SUCCESS, payload: postId });
            showPopupAlert(dispatch, POP_ALERT.SUCCESS, data.message);
            dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
        } else {
            dispatch({ type: NewsFeedTypes.POST_ARCHIVE_FAIL });
            showPopupAlert(dispatch, POP_ALERT.WARNING, data.message);
            dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
        }

    } catch (ex) {
        dispatch({ type: NewsFeedTypes.POST_ARCHIVE_FAIL });
        showPopupAlert(dispatch, POP_ALERT.WARNING, "Unexpected error occured");
        dispatch({ type: NewsFeedTypes.CLEAR_ACTION });
    }

}

export function clearTrendingPost() {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.CLEAR_TRENDING_POST });
    }
}

export const clearGroups = () => async dispatch => { dispatch({ type: CLEAR_GROUPS }); }

export const joinGroup = (groupId, index) => async dispatch => {
    try {
        dispatch({ type: JOINING_GROUP });
        const response = await ApiConnector.put(ROUTES.JOIN_GROUP + groupId, {} );
        if (response.data.code === 200) {
            dispatch({ type: JOIN_GROUP + STATUS.SUCCESS, payload: { index } });
        } else {
            dispatch({ type: JOIN_GROUP + STATUS.FAIL });
            showPopupAlert(dispatch, POP_ALERT.ERROR, response.data.message);
        }
        dispatch({ type: CLEAR_ACTION });
    } catch (ex) {
        dispatch({ type: JOIN_GROUP + STATUS.FAIL });
        dispatch({ type: CLEAR_ACTION });
        showPopupAlert(dispatch, POP_ALERT.ERROR, 'Exception occurred while joining the group');
    }
};

export const leaveGroup = (groupId) => async dispatch => {
    try {
        dispatch({ type: LEAVING_GROUP });
        const response = await ApiConnector.put(ROUTES.LEAVE_GROUP + groupId, {} );
        if (response.data.code === 200) {
            dispatch({ type: LEAVE_GROUP + STATUS.SUCCESS, payload: { groupId } });
        } else {
            dispatch({ type: LEAVE_GROUP + STATUS.FAIL });
            showPopupAlert(dispatch, POP_ALERT.ERROR, response.data.message);
        }
        dispatch({ type: CLEAR_ACTION });
    } catch (ex) {
        dispatch({ type: LEAVE_GROUP + STATUS.FAIL });
        dispatch({ type: CLEAR_ACTION });
        showPopupAlert(dispatch, POP_ALERT.ERROR, 'Exception occurred while leaving the group');
    }
};

export const fetchTrendingGroupPost = (groupId, postId, cancelToken = null) => async dispatch => {
    try {
        dispatch({ type: NewsFeedTypes.FETCHING_TRENDING_GROUP_POST });
        const response = await get(`${ROUTES.FETCH_TRENDING_GROUP_POST}/${groupId}/${postId}`, { cancelToken });
        if (response.data.code === 200) {
            dispatch({ type: NewsFeedTypes.FETCH_TRENDING_GROUP_POST_SUCCESS, payload: response.data });
        } if (response.data.code === 212) {
            showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
            dispatch({ type: NewsFeedTypes.FETCH_TRENDING_GROUP_POST_FAIL });
        } else {
            dispatch({ type: NewsFeedTypes.FETCH_TRENDING_GROUP_POST_FAIL });
        }
        dispatch({ type: CLEAR_ACTION });
    } catch (exception) {
        dispatch({ type: NewsFeedTypes.FETCH_TRENDING_GROUP_POST_FAIL });
    }
}

export function clearTrendingGroupPost() {
    return function (dispatch) {
        dispatch({ type: NewsFeedTypes.CLEAR_TRENDING_GROUP_POST });
    }
}

export function closeMobileGroupsPopup() {
    return async function (dispatch) {
        await dispatch({type: CLOSE_MOBILE_POPUP});
        dispatch({type: CLEAR_ACTION});
    }
}