import React, { useState, useEffect, useCallback } from 'react';
import {
  Modal,
  ModalBody,
  ModalTitle,
  ModalHeader,
  ModalClose,
  ModalFormGroup,
} from '../model';
import { LikedUsersForPost } from '../common/contentLoaders/feed';
import { getMyFriendsConnections } from '../../actions/connections';
import { AvatarProfile, CardDefault } from '../common';
import {
  THUMBNAIL,
  MESSAGE_SECTION_TYPES,
  VIEW_CONNECTIONS_LIMIT,
  NOTIFICATION_TYPES,
  CONNECTION_REJECT_TYPES,
} from '../../types/common';
import { debounced } from '../../libs/textFormat';
import './index.css';
import {
  getConnectionStatus,
  systemFormatAddress,
} from '../../libs/commonFunctions';
import RequestMsg from '../../containers/userProfileView/requestMsg.modal';
import {
  acceptConnection,
  addConnection,
  rejectConnection,
} from '../../actions/profile';
import { connect } from 'react-redux';
import * as profileTypes from './../../types/profile';
import useEventListener from '../../libs/hooks/useEventListner';

function MyFriends({
  isOpen,
  count,
  close,
  redirect,
  user,
  dispatch = null,
  page = null,
  section = null,
  socket,
  connectionEvent,
  connectionAction,
  profile,

  addConnection,
  rejectConnection,
  acceptConnection,
}) {
  const [fetching, setFetching] = useState(false);
  const [myFriends, setMyFriends] = useState([]);
  const [redirection, setRedirection] = useState(false);
  const [skip, setSkip] = useState(0);
  const [isOpenMsgModel, setIsOpenMsgModel] = useState(false);
  const [isSendMsgBtnClicked, setIsSendMsgBtnClicked] = useState(false);

  const [recipientId, setRecipientId] = useState(null);
  const [recipientName, setRecipientName] = useState(null);
  const [recipientPhoto, setRecipientPhoto] = useState(null);

  const myFriendsDataDiv = React.createRef();

  useEffect(() => {
    try {
      if (redirection) {
        redirect(redirection);
        setRedirection(false);
        close();
      }
    } catch (ex) {
      console.error(ex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirection]);

  useEffect(() => {
    try {
      setFetching(true);
      const submit = async () =>
        await getMyFriendsConnections(user, skip, VIEW_CONNECTIONS_LIMIT);
      submit().then((data) => {
        if (data.success) {
          setFetching(false);
          setMyFriends([...myFriends, ...data.data]);
        }
      });
    } catch (ex) {
      console.error(ex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  useEffect(() => {
    if (
      profile.action === profileTypes.ADD_CONNECTION_SUCCESS ||
      profile.action === profileTypes.ADD_CONNECTION_FAIL
    ) {
      setIsSendMsgBtnClicked(false);
      setIsOpenMsgModel(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionEvent]);

  const openMsgModel = (recepient) => {
    setIsOpenMsgModel(true);
    setRecipientId(recepient._id);
    setRecipientName(recepient.FULL_NAME);
    setRecipientPhoto(recepient.PROFILE_PHOTO);
  };

  const closeModel = (type = null) => {
    close();
  };

  const setHeight = debounced(500, () => {
    if (
      myFriendsDataDiv.current &&
      (myFriendsDataDiv.current.scrollTop +
        myFriendsDataDiv.current.offsetHeight) /
        myFriendsDataDiv.current.scrollHeight >
        0.75
    ) {
      if (count > myFriends.length) {
        setSkip(skip + VIEW_CONNECTIONS_LIMIT);
      }
    }
  });

  const _connect = (recipientId, msg = '') => {
    setIsSendMsgBtnClicked(true);
    const socketInfo = {
      socket: socket,
      userId: recipientId,
      type:
        msg !== ''
          ? NOTIFICATION_TYPES.CONNECTIONS_MSG
          : NOTIFICATION_TYPES.CONNECTION_REQUESTS,
    };
    addConnection(recipientId, msg, 0, null, socketInfo);
  };

  const _reject = (recipientUserId, type, rejectType) => {
    const socketInfo = {
      socket: socket,
      recipientUserId: recipientUserId,
      type: NOTIFICATION_TYPES.CANCEL_CONNECTION_REQUEST,
    };
    rejectConnection(recipientUserId, type, null, null, socketInfo, rejectType);
  };

  const _accept = (recipientUserId) => {
    acceptConnection(recipientUserId, socket, (userStatus) => {
      if (!userStatus) {
      }
    });
  };

  return (
    <CardDefault customClass="user-profile-card  public-view">
      <Modal isOpen={isOpen} className="modal-dialog modal-md my-friends-modal">
        <ModalHeader>
          <ModalTitle>
            {count}
            {count === 1 ? ' Connection' : ' Connections'}
          </ModalTitle>
          <ModalClose close={() => closeModel('ICON')} />
        </ModalHeader>
        <ModalBody>
          <ModalFormGroup className="p-0 pb-3">
            <div
              className={
                count > 10
                  ? 'ml_item_wrp scrollbar post_view_content_padding'
                  : 'scrollbar'
              }
              ref={myFriendsDataDiv}
              onScroll={setHeight}
              id="style-1"
            >
              {myFriends.map((items) => {
                return (
                  <UserRow
                    key={items._id}
                    data={items}
                    setRedirection={setRedirection}
                    _reject={_reject}
                    _accept={_accept}
                    openMsgModel={openMsgModel}
                    connectionEvent={connectionEvent}
                    connectionAction={connectionAction}
                  />
                );
              })}
              {fetching && (
                <div className="loadersvg">
                  <LikedUsersForPost />
                </div>
              )}
            </div>
          </ModalFormGroup>
        </ModalBody>
      </Modal>
      {isOpenMsgModel && (
        <RequestMsg
          isFromViewConnections={true}
          isOpenModel={isOpenMsgModel}
          modelClose={() => {
            setIsOpenMsgModel(false);
          }}
          recipientId={recipientId}
          recipientName={recipientName}
          recipientPhoto={recipientPhoto}
          connectWithMessage={_connect}
          isSendMsgBtnClicked={isSendMsgBtnClicked}
        />
      )}
    </CardDefault>
  );
}

const mapStateToProps = (state) => {
  return {
    connectionEvent: state.profile.action,
    connectionAction: state.profile.connectionAction,
    profile: state.profile,
  };
};

const actions = {
  addConnection: addConnection,
  rejectConnection: rejectConnection,
  acceptConnection,
};

export default connect(mapStateToProps, actions)(MyFriends);

const UserRow = ({
  data,
  setRedirection,
  _reject,
  _accept,
  openMsgModel,
  connectionEvent,
  connectionAction,
}) => {
  const [connectedType, setConnectedType] = useState(getConnectionStatus(data));

  useEffect(() => {
    if (connectionEvent === profileTypes.ADD_CONNECTION_SUCCESS) {
      if (
        connectionAction.add.status &&
        connectionAction.add.id &&
        connectionAction.add.id === data._id
      ) {
        if (connectedType !== 1) setConnectedType(1);
      }
    } else if (connectionEvent === profileTypes.REJECT_CONNECTION_SUCCESS) {
      if (
        connectionAction.reject.status &&
        connectionAction.reject.id &&
        connectionAction.reject.id === data._id
      ) {
        if (connectedType !== 0) setConnectedType(0);
      }
    } else if (connectionEvent === profileTypes.ACCEPT_CONNECTION_SUCCESS) {
      if (
        connectionAction.accept.status &&
        connectionAction.accept.id &&
        connectionAction.accept.id === data._id
      ) {
        if (connectedType !== 3) setConnectedType(3);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionEvent]);
  const handler = useCallback(
    (e) => {
      if (
        e.detail &&
        ((e.detail.recipient === data._id &&
          e.detail.requester === localStorage.getItem('userId')) ||
          (e.detail.requester === data._id &&
            e.detail.recipient === localStorage.getItem('userId')))
      ) {
        if (e.detail.type === 'ACCEPT') {
          if (connectedType !== 3) setConnectedType(3);
        } else if (e.detail.type === 'REJECT') {
          if (connectedType !== 0) setConnectedType(0);
        } else if (e.detail.type === 'REQUEST') {
          if (connectedType !== 2) setConnectedType(2);
        } else {
          console.error('unknown reaction');
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [connectedType, data._id]
  );
  useEventListener('connectionReaction', handler, document);

  if (data._id !== null) {
    return (
      <div className="LikeList_row p-t15 m-b15" key={data._id}>
        <div className="d-flex justify-content-between align-items-center">
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              (localStorage.getItem('userId')===data._id)? setRedirection(`/user-profile-edit/${data._id}`): setRedirection(`/user-profile/${data._id}`);
            }}
          >
            <AvatarProfile
              avatar={data.PROFILE_PHOTO}
              size={50}
              key={data._id}
              avatarSize={THUMBNAIL.SMALL}
            />
          </span>
          <div className="LLPersonInfo d-flex flex-column">
            <div className="d-flex align-items-center">
              <h3
                style={{ cursor: 'pointer' }}
                className="font_14 font_bold font_dark_gray_two get_zero_space"
                onClick={() => {
                  (localStorage.getItem('userId')===data._id)? setRedirection(`/user-profile-edit/${data._id}`): setRedirection(`/user-profile/${data._id}`);
                }}
              >{`${data.FULL_NAME}`}</h3>
              <div className="badges d-flex align-items-centers m-l5">
                {data.IS_PRO_MEMBER && (
                  <div className="badge badge-primary m-r5">PRO</div>
                )}
                {data.IS_EXPERT_CONTRIBUTOR &&
                  data.IS_EXPERT_CONTRIBUTOR.STATUS && (
                    <div className="badge badge-primary m-r5 green">EXPERT</div>
                  )}
              </div>
            </div>
            {data.HEADLINE && data.HEADLINE !== null && (
              <div className="headline">{data.HEADLINE}</div>
            )}
            {(!data.HEADLINE || data.HEADLINE == null) &&
              data.JOB_TITLE &&
              data.COMPANY_NAME && (
                <h3 className="job-title">
                  {`${data.JOB_TITLE}`},
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      setRedirection(`/profile/company/${data.COMPANY_ID}`)
                    }
                  >
                    {` ${data.COMPANY_NAME}`}
                  </span>
                </h3>
              )}
            <div className="d-flex align-items-center user-info">
              <div className="location">
                {systemFormatAddress(data.CITY, data.STATE, data.COUNTRY)}
              </div>
              {data.CITY && data.MUTUAL_FRIENDS_COUNT > 0 &&
                localStorage.getItem('userId') !== data._id && (
                <div className="m-l5 m-r5">•</div>
              )}
              {data.MUTUAL_FRIENDS_COUNT > 0 && 
                localStorage.getItem('userId') !== data._id &&(
                <span>
                  {data.MUTUAL_FRIENDS_COUNT} Mutual{' '}
                  {data.MUTUAL_FRIENDS_COUNT > 1 ? 'Connections' : 'Connection'}
                </span>
              )}
            </div>
          </div>
        </div>
        {connectedType === 1 && ( // I'm the requester and request pending
          <div className="profile-button-row">
            <button
              className="btn leaf-btn dark-green"
              onClick={() =>
                _reject(data._id, 2, CONNECTION_REJECT_TYPES.CANCEL_REQUEST)
              }
            >
              Cancel Request
            </button>
          </div>
        )}
        {connectedType === 2 && ( // I'm the recipient and request pending
          <React.Fragment>
            <div className="profile-button-row">
              <button
                className="btn leaf-btn text-only red"
                onClick={() =>
                  _reject(data._id, 2, CONNECTION_REJECT_TYPES.REJECT_REQUEST)
                }
              >
                Reject
              </button>
              <button
                className="btn leaf-btn dark-green"
                onClick={() => _accept(data._id)}
              >
                Accept
              </button>
            </div>
          </React.Fragment>
        )}
        {connectedType === 3 && ( // Already connected profile
          <button
            className="btn gray_clr_line"
            onClick={() => {
              setRedirection(
                `/message/${MESSAGE_SECTION_TYPES.PERSONAL.id}/${data._id}`
              );
            }}
          >
            Message
          </button>
        )}
        {localStorage.getItem('userId') !== data._id &&
          connectedType === 0 && ( // Not a friend or a Disconnected/rejected friend
            <div className="profile-button-row">
              <button
                className="btn leaf-btn dark-green"
                onClick={() => openMsgModel(data)}
              >
                Connect
              </button>
            </div>
          )}
      </div>
    );
  } else {
    return <div />;
  }
};
