import React from "react";
import "./index.css";
import { AvatarProfile } from "./../images/avatarProfile";

import { THUMBNAIL } from '../../../types/common';

const UserRowSummery = ({ remove, name, role, avatar }) => {
  return (
    <div className="user___row__sum d-flex flex-row justify-content-between m-t15 ma_mob_wrp">
      <div className=" d-flex flex-row">
        <AvatarProfile size={60} avatar={avatar} avatarSize={THUMBNAIL.SMALL} />
        <div className="m-l15">
          <h4 className="font_d_blue font_16 font_medium m-t15 word-wrap">{name}</h4>
          <p className="font_12 font_light m-t1" style={{ marginTop: "-8px" }}>
            {role}
          </p>
        </div>
      </div>
      <span className="remove___admin" onClick={remove}>
        Remove Admin
      </span>
    </div>
  );
};
export { UserRowSummery };
