import landingPageTypes from '../types/landingPages';

export const initialState = {
    action: 0,
    landingPages: [],
    customLandingPage: null,
    landingPageCount: 0,
    fetching: false
};

export const landingPages = (state = initialState, action) => {
    switch (action.type) {
        case landingPageTypes.CLEAR_ACTION:
            return {
                ...state,
                action: action.type
            };

        case landingPageTypes.ADDING_LANDING_PAGE:
            return {
                ...state,
                action: action.type,
            };
        case landingPageTypes.ADD_LANDING_PAGE_SUCCESS:
            return {
                ...state,
                action: action.type,
            };
        case landingPageTypes.ADD_LANDING_PAGE_FAIL:
            return {
                ...state,
                action: action.type,
            };

        case landingPageTypes.GETTING_LANDING_PAGE_COUNT:
            return {
                ...state,
                action: action.type
            };
        case landingPageTypes.LANDING_PAGE_COUNT_SUCCESS:
            return {
                ...state,
                action: action.type,
                landingPageCount: action.payload
            };
        case landingPageTypes.LANDING_PAGE_COUNT_FAIL:
            return {
                ...state,
                action: action.type
            };

        case landingPageTypes.FETCHING_LANDING_PAGES:
            return {
                ...state,
                action: action.type,
                fetching: true
            };
        case landingPageTypes.FETCH_LANDING_PAGES_SUCCESS:
            return {
                ...state,
                action: action.type,
                fetching: false,
                landingPages: action.payload
            };
        case landingPageTypes.FETCH_LANDING_PAGES_FAIL:
            return {
                ...state,
                action: action.type,
                fetching: false
            };

        case landingPageTypes.UPDATING_LANDING_PAGE:
            return {
                ...state,
                action: action.type,
            };
        case landingPageTypes.UPDATE_LANDING_PAGE_SUCCESS:
            return {
                ...state,
                action: action.type,
            };
        case landingPageTypes.UPDATE_LANDING_PAGE_FAIL:
            return {
                ...state,
                action: action.type,
            };

        case landingPageTypes.FETCHING_CUSTOM_LANDING_PAGE:
            return {
                ...state,
                action: action.type,
                fetching: true
            };
        case landingPageTypes.FETCH_CUSTOM_LANDING_PAGE_SUCCESS:
            return {
                ...state,
                action: action.type,
                fetching: false,
                customLandingPage: action.payload
            };
        case landingPageTypes.FETCH_CUSTOM_LANDING_PAGE_FAIL:
            return {
                ...state,
                action: action.type,
                fetching: false
            };

        default:
            return state
    };
};