import React from 'react'
import { Modal, ModalBody, ModalClose } from '../model'
import "./index.css";

export default function SuccessPopupV2({ onClickClose, onClickOk, title, text, description}) {
    

    return (
        <Modal isOpen={true} className="modal-space-top-10 modal-md pro-invite-sent-modal">
            <ModalBody>
                <ModalClose close={onClickClose}/>
                <div className="modal-content-wrapper">
                    <div className="modal-text">{title}</div>
                    <div className="reciever">{text}</div>
                    <div className="modal-text">{description}</div>
                    <div className="button-row">
                        <button onClick={onClickOk} className="btn leaf-btn green">OK</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}
