import React, { useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';

import { SIGNED_COOKIES_CF_DOMAIN } from '../../config';

const CookiesContainer = (props) => {
    const MEDIA_DOMAIN = SIGNED_COOKIES_CF_DOMAIN;

    const navigationUnblockHandle = useRef();

    const { signedCookies: signedCookiesData } = props;
    const { fetchSignedCookies, dispatch, history/*, toggleLoading*/ } = props;

    const [cookies, setCookie, removeCookie] = useCookies([]);

    /**
     * If cookies are expired, execute cookies refetching function
     */
    useEffect(() => {
        navigationUnblockHandle.current = history.block(
            async (targetLocation) => {
                if( isCookiesNotExists() ) {
                    await dispatch(fetchSignedCookies());
                } else if (isCookiesExpired()) {
                    // re-fetch cookies with blocking navigation
                    // toggleLoading(true);
                    await dispatch(fetchSignedCookies());
                    // toggleLoading(false);
                    return true;
                } else if (isCookiesAboutToExpired()) {
                    // re-fetch cookies without blocking navigation
                    dispatch(fetchSignedCookies());
                    return true;
                } else {
                    return true;
                }
            }
        );

        if( isCookiesNotExists() ) {
            // when mounting, fetch cookies without reloading browser
            dispatch(fetchSignedCookies());
        } else if (isCookiesExpired()) {
            // when mounting, re-fetch cookies with reloading browser
            dispatch(fetchSignedCookies(true));
        } else if (isCookiesAboutToExpired()) {
            // when mounting, re-fetch cookies without reloading browser
            dispatch(fetchSignedCookies());
        } else {
            // when mounting, schedule cookies re-fetch timer
            clearPreviousTimerData();
            const cookiesExpireTimeStampWithBufferInLS = localStorage.getItem(
                'cookiesExpireTimeStampWithBuffer'
            );
            const cookiesRefetchTimerId = registerNewTimerToRefetchCookies(
                Number(cookiesExpireTimeStampWithBufferInLS)
            );
            localStorage.setItem(
                'cookiesRefetchTimerId',
                cookiesRefetchTimerId
            );
        }

        return () => {
            navigationUnblockHandle.current &&
                navigationUnblockHandle.current();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, fetchSignedCookies, history]);

    const isCookiesNotExists = () => {
        const cookieNames = {
            policyKey : 'CloudFront-Policy',
            signatureKey : 'CloudFront-Signature',
            pairId : 'CloudFront-Key-Pair-Id'
        };
        return (!cookies[cookieNames.policyKey] || !cookies[cookieNames.signatureKey] || !cookies[cookieNames.pairId]);
    };

    const isCookiesExpired = () => {
        const timeStampInLS =
            localStorage.getItem('cookiesExpireTimeStamp') || null;
        if (!timeStampInLS) {
            return true;
        } else {
            return new Date() > new Date(Number(timeStampInLS));
        }
    };

    const isCookiesAboutToExpired = () => {
        const timeStampInLS =
            localStorage.getItem('cookiesExpireTimeStampWithBuffer') || null;
        if (!timeStampInLS) {
            return true;
        } else {
            return new Date() > new Date(Number(timeStampInLS));
        }
    };

    /**
     * Set cookies to browser
     */
    useEffect(() => {
        const {
            cookies: newCookies,
            cookiesExpireTime: cookiesExpireTimeStampInRedux,
            cookiesExpireTimeWithBuffer:
                cookiesExpireTimeStampWithBufferInRedux,
        } = signedCookiesData;

        if (newCookies) {
            setCookiesToBrowser(newCookies, cookiesExpireTimeStampInRedux);

            clearPreviousTimerData();

            const cookiesRefetchTimerId = registerNewTimerToRefetchCookies(
                cookiesExpireTimeStampWithBufferInRedux
            );

            saveNewTimerDataInLocalstorage(
                cookiesExpireTimeStampInRedux,
                cookiesExpireTimeStampWithBufferInRedux,
                cookiesRefetchTimerId
            );
        }

        return () => {
            if (signedCookiesData & signedCookiesData.cookies) {
                removeCookie(signedCookiesData.cookies.cloudFrontPolicyKey
                    , {domain: MEDIA_DOMAIN,}
                );
                removeCookie(signedCookiesData.cookies.cloudFrontSignatureKey
                    , {domain: MEDIA_DOMAIN,}
                );
                removeCookie(signedCookiesData.cookies.cloudFrontKeyPairIdKey
                    , {domain: MEDIA_DOMAIN,}
                );
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [MEDIA_DOMAIN, signedCookiesData]);

    const setCookiesToBrowser = (newCookies, cookiesExpireTimeStamp) => {
        const cookiesExpireTime = new Date(cookiesExpireTimeStamp);
        setCookie(
            newCookies.cloudFrontPolicyKey,
            newCookies.cloudFrontPolicyValue,
            {
                domain: MEDIA_DOMAIN,
                expires: cookiesExpireTime,
                sameSite: 'none',
                secure: true,
                path: '/'
            }
        );
        setCookie(
            newCookies.cloudFrontSignatureKey,
            newCookies.cloudFrontSignatureValue,
            {
                domain: MEDIA_DOMAIN,
                expires: cookiesExpireTime,
                sameSite: 'none',
                secure: true,
                path: '/'
            }
        );
        setCookie(
            newCookies.cloudFrontKeyPairIdKey,
            newCookies.cloudFrontKeyPairIdValue,
            {
                domain: MEDIA_DOMAIN,
                expires: cookiesExpireTime,
                sameSite: 'none',
                secure: true,
                path: '/'
            }
        );
    };

    const clearPreviousTimerData = () => {
        /**If we have a scheduled timer id in localstorage
         * de-register and remove data from localstorage
         */
        const prevCookiesRefetchTimerId =
            localStorage.getItem('cookiesRefetchTimerId') || null;
        if (prevCookiesRefetchTimerId) {
            clearTimeout(prevCookiesRefetchTimerId);
            localStorage.removeItem('cookiesRefetchTimerId');
        }
    };

    const registerNewTimerToRefetchCookies = (
        cookiesExpireTimeStampWithBuffer
    ) => {
        /**New function will be registered to re-fetch cookies **/

        const cookiesExpireTimeWithBuffer = new Date(
            cookiesExpireTimeStampWithBuffer
        );
        const now = new Date();

        const cookiesRefetchDelay = cookiesExpireTimeWithBuffer - now; // miliseconds

        return setTimeout(() => {
            dispatch(fetchSignedCookies());
        }, cookiesRefetchDelay);
    };

    const saveNewTimerDataInLocalstorage = (
        cookiesExpireTimeStamp,
        cookiesExpireTimeStampWithBuffer,
        cookiesRefetchTimerId
    ) => {
        /** Setting up new timer data to localstorage */
        localStorage.setItem('cookiesExpireTimeStamp', cookiesExpireTimeStamp);
        localStorage.setItem(
            'cookiesExpireTimeStampWithBuffer',
            cookiesExpireTimeStampWithBuffer
        );
        localStorage.setItem('cookiesRefetchTimerId', cookiesRefetchTimerId);
    };

    return <></>;
};
export default CookiesContainer;
