import React from 'react'

const Row = ({ children, customClass }) => {

    return (
        <div className={customClass || "row"}>{children}</div>
    )


}
export { Row }