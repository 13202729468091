import React from 'react'
import './styles.css'


const Loading = (props) => {

    return <div className="loader-section">
        <div className="text-center">
            <div className="center">
                <div className="font-light large-18 m-t-n-lg loading-name"/>
            </div>
            <div className="loading center" style={{marginTop:'15px'}}>
                <div className="loading-bar" />
            </div>
        </div>
    </div>;
}
export { Loading };
