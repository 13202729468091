import React, { Component } from "react";
import "./index.css";
import classNames from "classnames";

class ButtonActionToolTip extends Component {
  constructor(props) {
    super(props);
    this.state = {
        active : false
    };
  }

  close = () =>{
    this.props.setToolTip()
  }
  

  render() {

    let activeToltip = classNames('button_tooltip' ,  this.props.toolTipisOpen === true ? 'media_active' : 'media_hide'  )

    return (
      <div className={activeToltip} style={{width:this.props.width + '%'}}>
        <div className="button_cont">
          <div className="button_base_tooltip_close" onClick={this.close.bind(this)}>
            <p className="button_base_tooltip_close_text">&times;</p>
          </div>
          <div className="button_base_tooltip_place" />
           {this.props.children}
        </div>
      </div>
    );
  }
}

export { ButtonActionToolTip };
