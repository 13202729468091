import AuthTypes from '../types/authentication';
import { SET_INVESTOR_DETAILS} from '../types/investor';
import { LEAFWIRE_ADMIN_EMAILS } from '../types/common';
export const initialState = {
    action: 0,
    loading: true,
    isAuthenticated: false,
    isAdminAuthenticated: false,
};

export const route = (state = initialState, action) => {
    switch (action.type) {
        case AuthTypes.ROUTE_ACCESS:
        return {
            ...state,
            action: AuthTypes.ROUTE_ACCESS,
            loading: true,
            isAuthenticated: false,
            isAdminAuthenticated: false
        };
        case AuthTypes.ROUTE_ACCESS_SUCCESS:
            return {
                ...state,
                action: AuthTypes.SUCCESS,
                loading: false,
                isAuthenticated: true,
                isAdminAuthenticated: LEAFWIRE_ADMIN_EMAILS.indexOf(action.payload.EMAIL) >= 0 ? true : false
            };
        case SET_INVESTOR_DETAILS:
            return {
                ...state,
                action: SET_INVESTOR_DETAILS,
                loading: false,
            };
        case AuthTypes.CLEAR_ROUTE_ACCESS:
            return {
                ...state,
                action: AuthTypes.CLEAR_ROUTE_ACCESS,
                loading: false,
                isAuthenticated: false,
                isAdminAuthenticated: false
            };
        default:
            return state;
        }
    }
    