import { post , get} from "../libs/apiConnector";
import { ROUTES } from '../config';
import proTypes from '../types/pro';
import { POP_ALERT, NOTIFICATION_TYPES } from '../types/common';
import { showPopupAlert} from './alert';
import * as ApiConnector from '../libs/apiConnector';
import { notifyReceiver } from '../libs/socket';


export const openInviteModal = () => async dispatch => {
    dispatch({ type: proTypes.OPEN_INVITE_MODAL });
};

export const closeInviteModal = () => async dispatch => {
    dispatch({ type: proTypes.CLOSE_INVITE_MODAL });
};


export const setUserProStatus = async (details) => {
    try {
        const response = await ApiConnector.post(ROUTES.PRO_INVITATION_RES, details, { headers: { Authorization: localStorage.getItem('token') } })
        if (response && response.data.code === 200) {
            return { success: true, data: response.data.data }
        } else {
            return { success: false, data: 0 }
        }
    } catch (exception) {
        return { success: false, data: 0 }
    }
};

export const trackInviteModalOpen = (data) => async (dispatch) => {
    try {
        const res = await post(ROUTES.TRACK_PRO_INVITES_MODAL_OPEN, data);
        if (res.data && res.data.code === 200) {
            dispatch({
                type: proTypes.UPDATE_PRO_INVITES_TRACKING_DATA,
                payload: res.data.data._id,
            });
        } else {
            console.error(res.data.message);
        }
    } catch (ex) {
        console.error(ex);
    }
};

export const fetchCurrentUserProStatus = () => async dispatch => {
    try {
        dispatch({ type: proTypes.START_FETCHING });
        dispatch({ type: proTypes.FETCHING_USER_PRO_DATA });
        const res = await post(ROUTES.USERDETAILS, {});
        if (res.data && res.data.code === 200) {
            dispatch({
                type: proTypes.UPDATE_USER_PRO_DATA,
                payload: {
                    IS_PRO_MEMBER: res.data.data.IS_PRO_MEMBER,
                    ALLOCATED_PRO_INVITES_COUNT: res.data.data.ALLOCATED_PRO_INVITES_COUNT,
                    SENT_PRO_INVITES_COUNT: res.data.data.SENT_PRO_INVITES_COUNT,
                    FIRST_NAME: res.data.data.FIRST_NAME,
                    LAST_NAME: res.data.data.LAST_NAME,
                    PHOTO: res.data.data.PHOTO,
                },
            });
        } else {
            dispatch({ type: proTypes.FETCHING_USER_PRO_DATA_FAIL });
            showPopupAlert(dispatch, POP_ALERT.ERROR, res.data.message, 'Error');
        }
        dispatch({ type: proTypes.STOP_FETCHING });
    } catch (ex) {
        showPopupAlert(dispatch, POP_ALERT.ERROR, 'Unexpected error occurred', 'Error');
        console.error(ex);
        dispatch({ type: proTypes.STOP_FETCHING });
    }
};

export const fetchSentInvitations = () => async dispatch => {
    try {
        dispatch({ type: proTypes.START_FETCHING });
        const res = await post(ROUTES.FIND_PRO_INVITATIONS, { type: "sent" });
        if (res.data && res.data.code === 200) {
            dispatch({
                type: proTypes.UPDATE_SENT_INVITATIONS,
                payload: res.data.data,
            });
        } else {
            showPopupAlert(
                dispatch,
                POP_ALERT.ERROR,
                res.data.message,
                'Error'
            );
        }
        dispatch({ type: proTypes.STOP_FETCHING });
    } catch (ex) {
        showPopupAlert(dispatch, POP_ALERT.ERROR, 'Unexpected error occurred', 'Error');
        console.error(ex);
        dispatch({ type: proTypes.STOP_FETCHING });
    }
};

export const getProfileEventDiscount = () => async dispatch => {
    try {
        dispatch({ type:proTypes.GET_PROFILE_EVENT_DISCOUNT})
        const response = await get(ROUTES.GET_EVENT_DISCOUNT_DETAILS);
        const { status, data } = response;

        if (status === 200) {
            dispatch({ type: proTypes.GET_PROFILE_EVENT_DISCOUNT_SUCCESS, payload: data.data})
        } else {
            dispatch({ type: proTypes.GET_PROFILE_EVENT_DISCOUNT_FAIL })
        }
    } catch (exception) {
        dispatch({ type: proTypes.GET_PROFILE_EVENT_DISCOUNT_FAIL })
    }
};

export const fetchReceivedInvitations = () => async dispatch => {
    try {
        dispatch({ type: proTypes.FETCH_RECEIVED_INVITATIONS });
        const res = await post(ROUTES.FIND_PRO_INVITATIONS, { type: "received", onlyActive: true });
        if (res.data && res.data.code === 200) {
            dispatch({
                type: proTypes.FETCH_RECEIVED_INVITATIONS_SUCCESS,
                payload: res.data.data,
            });
        } else {
            dispatch({ type: proTypes.FETCH_RECEIVED_INVITATIONS_FAIL });
        }
    } catch (ex) {
        dispatch({ type: proTypes.FETCH_RECEIVED_INVITATIONS_FAIL });
    }
};

export const searchUsers = (keyWord, setSearching, skip, limit) => async dispatch => {
    let data = [];
    try {
        setSearching(true);
        const res = await post(ROUTES.FIND_USER_TO_SEND_PRO_INVITATION, { searchKey: keyWord, skip, limit });
        if (res.data && res.data.code === 200) {
            data = res.data.data;
        } else {
            showPopupAlert(
                dispatch,
                POP_ALERT.ERROR,
                res.data.message,
                'Error'
            );
        }
        setSearching(false);
        return data;
    } catch (ex) {
        showPopupAlert(dispatch, POP_ALERT.ERROR, 'Unexpected error occurred', 'Error');
        console.error(ex);
        setSearching(false);
        return data;
    }
};

export const sendInvitation = (data, currentUser, openLastInvitePopup, clearSelectedUser, clearSelectedEmail, socket, name) => async (dispatch) => {
    try {
        dispatch({ type: proTypes.START_SENDING });
        const res = await post(ROUTES.SEND_PRO_INVITATION, data);
        if (res.data && res.data.code === 200) {

            const { invitation, user } = res.data.data;

            const sentInvitesCount = user.SENT_PRO_INVITES_COUNT;
            const toUserId = invitation.to;

            dispatch({
                type: proTypes.UPDATE_SENT_PRO_INVITES_COUNT,
                payload: sentInvitesCount,
            });

            if (currentUser.ALLOCATED_PRO_INVITES_COUNT === sentInvitesCount) {
                openLastInvitePopup();
            } else {
                showPopupAlert(
                        dispatch,
                        POP_ALERT.SUCCESS_V2,
                        name,
                        'The PRO Invite to:',
                        'has been sent succesfully',
                    );
            }

            clearSelectedUser();
            clearSelectedEmail();

            // send in-app notification to receiver who already in the system
            if (toUserId) {
                const receiver = {
                    _id: toUserId,
                };
                notifyViaInAppNotificationToInvitationReceiver(
                    socket,
                    currentUser,
                    receiver,
                    invitation
                );
            }
        } else {
            showPopupAlert(
                dispatch,
                POP_ALERT.ERROR,
                res.data.message,
                'Error'
            );
        }
        dispatch({ type: proTypes.STOP_SENDING });
    } catch (ex) {
        showPopupAlert(
            dispatch,
            POP_ALERT.ERROR,
            'Unexpected error occurred',
            'Error'
        );
        console.error(ex);
        dispatch({ type: proTypes.STOP_SENDING });
    }
};

const notifyViaInAppNotificationToInvitationReceiver = (socket, sender, reciever, invitation) => {
    if (socket) {
        let socketData = {
            sender,
            reciever,
            invitation,
        };
        notifyReceiver(
            socket,
            reciever._id,
            NOTIFICATION_TYPES.PRO_INVITATION,
            socketData
        );
    }
};
