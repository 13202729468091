import React, {useEffect, useState} from 'react';
import { ItemBox } from './../itemBox';
import { Link } from 'react-router-dom';
import { getPreview } from "../../../../libs/textFormat";
import { STATUS, THUMBNAIL } from "../../../../types/common";
import { SIGNED_COOKIES_CF_URL } from "../../../../config";
import { getImageUrl } from '../../../../libs/image';
import { isURL } from "../../../../libs/validation";
import { BUCKET } from '../../../../libs/cfHelper';

const ProfileSummary = ({ imgSrc, height, width, subTitle, isCompany = false, title, ondelete, redirectLink, isCreator, onedit, isConntectionSuggestion, onAdd, editOption, status, editElemId, fetchImage, page=null }) => {
  let editProps = editElemId ? { id: editElemId } : {};

  const [imgUrl, setImgUrl] = useState((!fetchImage) ? imgSrc : '');

    useEffect(() => {
        const fetchImg = async (img) => {
            try {
                const url = await getImageUrl(img, THUMBNAIL.SMALL, (page !== 'PROFILE') ? BUCKET.NEWSFEED : null);

                if (url) {
                    setImgUrl(url)
                }
            } catch (ex) {
                console.error(ex)
            }
        };
        if(fetchImage && imgSrc){
            fetchImg(imgSrc);
        }
    }, [imgSrc, fetchImage, page]);

  return (
    <div className="d-flex align-items-center">
      <ItemBox
          isCompany = {isCompany}
          onConntectionSuggestion={isConntectionSuggestion}
          height={height}
          width={width}
          imgSrc={isURL(imgSrc, { protocols: ['https'] }, SIGNED_COOKIES_CF_URL)? imgSrc : imgUrl }
          onClick={ondelete}
          onEdit={onedit}
          deleteOption={true}
          // editOption={editOption && isCreator}
          editOption={status === STATUS.NOT_DELETED ? editOption : false}
          onAdd={onAdd}
          editProps={editProps}
      />
      <div className="d-flex flex-column m-l10">
        {status === STATUS.NOT_DELETED 
        ? <Link style={{ textDecoration: 'none' }} to={redirectLink}>
          <h4 className="font_medium font_d_blue font_15 m-t10" style={{wordBreak: 'break-word'}}>
            {getPreview(title,40)}
          </h4>
        </Link> 
        : <h4 className="font_medium font_d_blue font_15 m-t10" style={{wordBreak: 'break-word'}}>
            {getPreview(title,40)}
          </h4>}
        <p className="font_regular  font_gray">
          {getPreview(subTitle,40)}
        </p>
      </div>
    </div>
  )
};
ProfileSummary.defaultProps = {
  imgSrc: null,
  height: '100%',
  width: '100%',
  title: null,
  subTitle: null,
  isCreator: null
};
export { ProfileSummary }
