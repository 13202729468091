import React from "react";
import { Link } from 'react-router-dom'
import { THUMBNAIL } from "../../../types/common";
import { AvatarProfile } from "../images/avatarProfile";
import defaultAvatorPeople from "./../../../assets/img/default-male.jpg";
import "./index.css";

export const MessageTyping = ({ senderImage = defaultAvatorPeople, userId }) => (
    <React.Fragment>
        <div className="d-flex flex-row align-items-center">
            <div className="m-r10 our_msg_right" style={{ width: "42px" }}>
                <Link to={`/user-profile/${userId}`}>
                    <AvatarProfile avatar={senderImage ? senderImage : defaultAvatorPeople} avatarSize={THUMBNAIL.SMALL} size={42} />
                </Link>
            </div>
            <div className="user-typing">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </React.Fragment>
)
