import eventTypes from '../types/events';

export const initialState = {
    action: 0,
    tags: [],
    featuredEventCount: 0,
    event: null,
    userEventCount: 0,
    isEventsFetching: false,
    userEvents: [],
    fetchingSubscriptions: false,
    subscriptions: null,
    adminOtherEventCount: 0,
    isAdminOtherEventsFetching: false,
    adminOtherEvents: [],
    showManageEvents: false
};

export const events = (state = initialState, action) => {
    switch (action.type) {

        case eventTypes.CLEAR_ACTION: {
            return {
                ...state,
                action: action.type
            };
        }

        case eventTypes.FETCH_TAGS : {
            return {
                ...state,
                tags: [],
                action: action.type
            }
        }
        case eventTypes.FETCH_TAGS_SUCCESS : {
            return {
                ...state,
                tags: action.payload,
                action: action.type
            }
        }
        case eventTypes.FETCH_TAGS_FAIL : {
            return {
                ...state,
                tags: [],
                action: action.type
            }
        }

        case eventTypes.GET_FEATURED_EVENTS_COUNT : {
            return {
                ...state,
                featuredEventCount: 0,
                action: action.type
            }
        }
        case eventTypes.GET_FEATURED_EVENTS_COUNT_SUCCESS : {
            return {
                ...state,
                featuredEventCount: action.payload,
                action: action.type
            }
        }
        case eventTypes.GET_FEATURED_EVENTS_COUNT_FAIL : {
            return {
                ...state,
                featuredEventCount: 0,
                action: action.type
            }
        }

        case eventTypes.CREATE_EVENT:
            return {
                ...state,
                action: action.type
            };
        case eventTypes.CREATE_EVENT_SUCCESS:
            return {
                ...state,
                action: action.type
            };
        case eventTypes.CREATE_EVENT_FAIL:
            return {
                ...state,
                action: action.type
            };

        case eventTypes.PUBLISH_LOCAL_EVENT:
            return {
                ...state,
                action: action.type
            };
        case eventTypes.PUBLISH_LOCAL_EVENT_SUCCESS:
            return {
                ...state,
                action: action.type
            };
        case eventTypes.PUBLISH_LOCAL_EVENT_FAIL:
            return {
                ...state,
                action: action.type
            };

        case eventTypes.PUBLISH_GLOBAL_EVENT:
            return {
                ...state,
                action: action.type
            };
        case eventTypes.PUBLISH_GLOBAL_EVENT_SUCCESS:
            return {
                ...state,
                action: action.type
            };
        case eventTypes.PUBLISH_GLOBAL_EVENT_FAIL:
            return {
                ...state,
                action: action.type
            };

        case eventTypes.FETCH_SINGLE_EVENT:
            return {
                ...state,
                event: null,
                action: action.type
            };
        case eventTypes.FETCH_SINGLE_EVENT_SUCCESS:
            return {
                ...state,
                event: action.payload,
                action: action.type
            };
        case eventTypes.FETCH_SINGLE_EVENT_FAIL:
            return {
                ...state,
                event: null,
                action: action.type
            };

        case eventTypes.EDIT_EVENT:
            return {
                ...state,
                action: action.type
            };
        case eventTypes.EDIT_EVENT_SUCCESS:
            return {
                ...state,
                action: action.type
            };
        case eventTypes.EDIT_EVENT_FAIL:
            return {
                ...state,
                action: action.type
            };

        case eventTypes.GET_USER_EVENTS_COUNT:
            return {
                ...state,
                action: action.type,
                userEventCount: 0
            };
        case eventTypes.GET_USER_EVENTS_COUNT_SUCCESS:
            return {
                ...state,
                action: action.type,
                userEventCount: action.payload,
                showManageEvents: action.payload > 0
            };
        case eventTypes.GET_USER_EVENTS_COUNT_FAIL:
            return {
                ...state,
                action: action.type,
                userEventCount: 0
            };

        case eventTypes.GET_USER_EVENTS:{
            return {
                ...state,
                isEventsFetching: true,
                userEvents: action.init ? [] : state.userEvents,
                action: action.type,
            }
        }
        case eventTypes.GET_USER_EVENTS_SUCCESS:{
            return {
                ...state,
                isEventsFetching: false,
                userEvents: action.init ? action.payload : [...state.userEvents, ...action.payload],
                action: action.type,
            }
        }
        case eventTypes.GET_USER_EVENTS_FAIL:{
            return {
                ...state,
                isEventsFetching: false,
                userEvents: action.init ? [] : state.userEvents,
                action: action.type,
            }
        }

        /*admin other events - start*/
        case eventTypes.GET_OTHER_EVENTS_COUNT:
            return {
                ...state,
                action: action.type,
                adminOtherEventCount: 0
            };
        case eventTypes.GET_OTHER_EVENTS_COUNT_SUCCESS:
            return {
                ...state,
                action: action.type,
                adminOtherEventCount: action.payload
            };
        case eventTypes.GET_OTHER_EVENTS_COUNT_FAIL:
            return {
                ...state,
                action: action.type,
                adminOtherEventCount: 0
            };

        case eventTypes.GET_OTHER_EVENTS:{
            return {
                ...state,
                isAdminOtherEventsFetching: true,
                adminOtherEvents: action.init ? [] : state.adminOtherEvents,
                action: action.type,
            }
        }
        case eventTypes.GET_OTHER_EVENTS_SUCCESS:{
            return {
                ...state,
                isAdminOtherEventsFetching: false,
                adminOtherEvents: action.init ? action.payload : [...state.adminOtherEvents, ...action.payload],
                action: action.type,
            }
        }
        case eventTypes.GET_OTHER_EVENTS_FAIL:{
            return {
                ...state,
                isAdminOtherEventsFetching: false,
                adminOtherEvents: action.init ? [] : state.adminOtherEvents,
                action: action.type,
            }
        }
        /*admin other event - end*/

        case eventTypes.FETCH_EVENT_SUBSCRIPTIONS:
            return {
                ...state,
                fetchingSubscriptions: true,
                subscriptions: null,
                action: action.type,
            };
        case eventTypes.FETCH_EVENT_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                fetchingSubscriptions: false,
                subscriptions: action.payload,
                action: action.type,
            };
        case eventTypes.FETCH_EVENT_SUBSCRIPTIONS_FAIL:
            return {
                ...state,
                fetchingSubscriptions: false,
                subscriptions: null,
                action: action.type,
            };

        case eventTypes.UNPUBLISH_EVENT:
            return {
                ...state,
                action: action.type,
            };
        case eventTypes.UNPUBLISH_EVENT_SUCCESS:
            return {
                ...state,
                action: action.type,
            };
        case eventTypes.UNPUBLISH_EVENT_FAIL:
            return {
                ...state,
                action: action.type,
            };

        case eventTypes.ADD_EVENT_TAG_SUCCESS:
            return {
                ...state,
                action: action.type,
            };
        case eventTypes.ADD_EVENT_TAG_FAIL:
            return {
                ...state,
                action: action.type,
            };

        case eventTypes.UPDATE_EVENT_TAG_SUCCESS:
            return {
                ...state,
                action: action.type,
            };
        case eventTypes.UPDATE_EVENT_TAG_FAIL:
            return {
                ...state,
                action: action.type,
            };

        case eventTypes.DELETE_EVENT_TAG_SUCCESS:
            return {
                ...state,
                action: action.type,
            };
        case eventTypes.DELETE_EVENT_TAG_FAIL:
            return {
                ...state,
                action: action.type,
            };


        default:
            return state
    }
};