import {ROUTES} from '../config';
import {JOB_BOARD_DATA} from '../types/common';
import {post} from '../libs/apiConnector';

export const createJobSeeker = async () => {
    try {
        const {data} = await post(ROUTES.CREATE_JOB_SEEKER, {});

        if (data.code === 200) {
            return {success: true, data: data.data};
        } else {
            return {success: false};
        }
    } catch (exception) {
        return {success: false};
    }
};

export const autoLoginJBHQ = (userDetails = {}) => {

    const {email, password, jobkey, keeplogin, employerKey} = userDetails;

    if (email && password && jobkey) {
        window.history.replaceState(null, null, "/");
        localStorage.removeItem('JBHQ_JOB_ID')
        window.jblogin(JOB_BOARD_DATA.DOMAIN, JOB_BOARD_DATA.BOARD_KEY, email, password, keeplogin, jobkey);
    } else if (email && password && employerKey) { //pass employer key to JBHQ
        window.jblogin_v3(JOB_BOARD_DATA.DOMAIN, JOB_BOARD_DATA.BOARD_KEY, email, password, employerKey, "true", "/employer/job");
    } else if (email && password) {
        window.jblogin_v3(JOB_BOARD_DATA.DOMAIN, JOB_BOARD_DATA.BOARD_KEY, email, password, "", "true", "");
    } else {
        console.error('Unexpected error occurred')
    }
};