import React, { PureComponent } from 'react';

class AnimatedInput extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type || "text",
            submit: this.props.submit || null,
            defaultvalue: this.props.defaultvalue || '',
            readonly: this.props.readonly === true
        }
    }

    setDefault = () => {
        this.refs.animatedin.value = '';
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            readonly: nextProps.readonly === true
        })
    }

    render() {
        return (
            <input
                {...(this.props.disableautocomplete ? { autoComplete: 'new-password' } : {})}
                disabled={this.props.disabled}
                id={this.props.elmId ? this.props.elmId : null}
                readOnly={this.state.readonly}
                type={this.state.type}
                ref="animatedin"
                className="matirial_custom_input"
                onChange={this.state.submit}
                required
                defaultValue={this.state.defaultvalue}
                {...(this.state.type === 'number' ? { onKeyDown: (e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault() }: {})}
            />
        )
    }
}

class NewAnimatedInput extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type || "text",
            submit: this.props.submit || null,
            defaultvalue: this.props.defaultvalue || '',
            value: this.props.defaultvalue || '',
        }
    }

    setDefault = () => {
        this.refs.animatedin.value = '';
    };

    submitVal = (e) => {
        if (this.props.submit) {
            this.props.submit(e)
        }
        this.setState({ value: e.target.value })
    };

    changeValue = (e) => {
        this.setState({ value: e.target.value })
    };

    setValue = (value) => {
        this.setState({ value: value })
    };

    render() {
        return (<input role="presentation" autoComplete="off" type={this.state.type} ref="animatedin" className="matirial_custom_input" value={this.state.value} onChange={this.submitVal.bind(this)} required />)
    }

}

export { AnimatedInput, NewAnimatedInput };

