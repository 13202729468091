import { ROUTES } from '../config';
import {post, get} from '../libs/apiConnector';
import { joinConversation, leaveConversation } from '../libs/socket';
import {
    STATUS,
    OPEN_EXISTING_CHAT,
    CLOSE_EXISTING_CHAT,
    GET_CHAT_MESSAGES,
    GET_CHAT_MESSAGES_COUNT,
    FILTER_DELETED_CHAT,
    MINIMIZE_CONVERSATIONS,
    CLEAN_CHATPOP_STATES,
    CLEAN_SINGLE_CHATPOP_STATES,
    GET_CHAT_CONVERSATIONS,
    GET_CHAT_CONVERSATIONS_COUNT,
    SOCKET_NEW_CHAT_MESSAGE,
    OPEN_NEW_CHAT,
    SET_CHAT_ACTIVE_STATUS,
    REPLACE_INACTIVE_CHAT,
    CLEAR_REPLACE_INACTIVE_CHAT,
    SEEN_CHAT_MESSAGE,
    INIT_UNSEEN_CHATS,
    CLEAR_INIT_UNSEEN_CHATS,
    REFETCH_MY_CONVERSATIONS,
    CLEAR_CHAT_ACTION,
    REPORT_SPAM
} from '../types/chat';
import {CHAT_MAX_INACTIVE_TIME} from '../types/common';
import { ALERT } from '../types/common';
import { showAlert } from './alert';

export const reconnectToConversations = (socket) => {
    return function (dispatch, getState) {
        const threads = getState().chats.threads;
        threads.forEach(element => {
            joinConversation(socket, element.coversationId);
        });
        // dispatch({ type: OPEN_EXISTING_CHAT, data: data });
    }
};

const getMaxChatBoxes = ({ threads, openNewChat }) => {
    try {
        const currentThreads = (threads.length + 1) + (openNewChat ? 1 : 0); 
        return { 
            maxChatBoxes: Math.floor(window.screen.width / 345), 
            currentThreads
        }
    } catch (ex) {
        console.error(ex);
    }
}

// when user get a new message and if user already opened max IM popups, this function selects the most suitable
// IM conversation to close to open new message
const getConversationToCLose = ({threads, minimizeCoversations, chatLastActive}) => {
    try {
        if(minimizeCoversations && minimizeCoversations.length > 0 && minimizeCoversations.length === 1){
            //remove minimized conversation
            return minimizeCoversations[0] //user have one minimized conversation -> remove
        } else if(minimizeCoversations && minimizeCoversations.length > 1){
            //remove first minimized conversation
            let reversedArray = [...threads].reverse()
            let orderedMinConversations = reversedArray.map(th => {
                return minimizeCoversations.indexOf(th.coversationId) !== -1 ? th.coversationId : null
            }).filter(th => { return th !== null})
            return orderedMinConversations.length > 0 ? orderedMinConversations[0] : null
        } else if(minimizeCoversations.length === 0){
            //remove inactive conversation
            let reversedArray = [...threads].reverse()
            let orderedInactiveConversations = reversedArray.map(th => {
                if(chatLastActive[th.coversationId]){
                    let timeInactive = (Date.now() - chatLastActive[th.coversationId]) / ( 1000 * 60 ) //in minutes
                    return timeInactive >= CHAT_MAX_INACTIVE_TIME ? th.coversationId : null
                } else {
                    return null
                }
            }).filter(th => { return th !== null})
            return orderedInactiveConversations.length > 0 ? orderedInactiveConversations[0] : null
        } else {
            return null
        }
    } catch (ex) {
        console.error('err', ex)
        return null;
    }
}

export const openExistingChat = (data) => {
    return async function (dispatch, getState) {
        const { maxChatBoxes, currentThreads } = getMaxChatBoxes(getState().chats);
        const { threads } = getState().chats;
        const found = threads.some(item => item.coversationId === data.coversationId);
        if (currentThreads < maxChatBoxes || found) {
            await dispatch({ type: OPEN_EXISTING_CHAT, data: data });
        } else {
            const conversationToClose = getConversationToCLose(getState().chats)
            if(conversationToClose){
                await dispatch({ type: REPLACE_INACTIVE_CHAT, oldConversation: conversationToClose, newConversation: data})
                await dispatch({ type: CLEAR_REPLACE_INACTIVE_CHAT})
            }
        }
    }
};

export const initChatPopups = () => {
    return async function (dispatch, getState) {
        try{
            const { maxChatBoxes } = getMaxChatBoxes(getState().chats);
            // get unseen conversations
            const res = await get(`${ROUTES.FETCH_USER_UNSEEN_CHATS}/${localStorage.getItem('userId')}`);
            if (res.data.code === 200) {
                let unseenConversations = res.data.data
                if (unseenConversations.length > 0) {
                    //if unseen conversation count excceds max chat box count, get the most recent conversations
                    if (unseenConversations.length > maxChatBoxes) {
                        unseenConversations = unseenConversations.slice(0, maxChatBoxes-1)
                    }
                    //set redux store unseen new messages
                    await dispatch({ type: INIT_UNSEEN_CHATS, conversations: unseenConversations.reverse() })
                }
            } else {
                console.error('get init unseen conversations failed')
            }
        } catch (ex) {
            console.error('get init unseen conversations failed')
        }
    }   
}

export const removeInitChat = (conversationId) => {
    return async function (dispatch){
        await dispatch({ type: CLEAR_INIT_UNSEEN_CHATS, conversationId })
    }
}

export const newChatWindow = (isOpen, socket) => {
    return function (dispatch, getState) {
        try {
            if(isOpen){
                const { threads, openNewChat } = getState().chats;
                const { maxChatBoxes, currentThreads } = getMaxChatBoxes({threads, openNewChat});
                if(currentThreads < maxChatBoxes){
                    dispatch({ type: OPEN_NEW_CHAT, isOpen });
                } else {
                    
                    const { threads } = getState().chats;
                    let item = threads.filter((item, index) => {
                        return index !== (threads.length - 1)
                    });
                    dispatch(closeExistingChat(item));
                    dispatch(cleanStates(threads[threads.length - 1].coversationId));
                    leaveConversation(socket, threads[threads.length - 1].coversationId);
                    dispatch({ type: OPEN_NEW_CHAT, isOpen });
                }
            } else {
                dispatch({ type: OPEN_NEW_CHAT, isOpen });
            }
        } catch (error) {
            console.error(error);
        }
    } 
};

export const closeExistingChat = (data) => {
    return function (dispatch) {
        dispatch({ type: CLOSE_EXISTING_CHAT, data: data });
    }
};

export const minimizeChat = (conversationId) => {
    return function (dispatch) {
        dispatch({ type: MINIMIZE_CONVERSATIONS, conversationId });
    }
};

export const cleanSingleChatPopup = (userId, module) => {
    return function (dispatch) {
        dispatch({ type: CLEAN_SINGLE_CHATPOP_STATES, userId, module });
    }
};

export const refeshChat = (dispatch, userId) => {
    dispatch(getChatConversations({
        username: '',
        module: 0,
        skip: 0,
        limit: 16
    }));
    dispatch(cleanSingleChatPopup(userId, 2));
}

export const cleanStates = (conversationId) => {
    return function (dispatch) {
        dispatch({ type: CLEAN_CHATPOP_STATES, conversationId });
    }
};

export const getChatMessages = (conversationId, data, checkId = true) => async dispatch => {
    const shouldReset = data.skip === 0;
    dispatch({ type: GET_CHAT_MESSAGES + STATUS.FETCHING, shouldReset, conversationId });
    try {
        const res = await post(`${ROUTES.FETCH_COVERSATION_MESSAGES}/${conversationId}`, {...data, keepNotificationCounts: true});
        if (res.data.code === 200) {
            dispatch({ type: GET_CHAT_MESSAGES + STATUS.SUCCESS, messages: res.data.data, shouldReset, userId: conversationId, checkId: checkId, conversationId });
        } else {
            if(res.data.code){
                dispatch({type: FILTER_DELETED_CHAT, conversationId});
            }
            dispatch({ type: GET_CHAT_MESSAGES + STATUS.FAIL, conversationId });
        }
    } catch (ex) {
        dispatch({ type: GET_CHAT_MESSAGES + STATUS.FAIL, conversationId });
    }
};

export const getChatMessagesCount = (conversationId, data = {}, checkId = true) => async dispatch => {
    dispatch({ type: GET_CHAT_MESSAGES_COUNT + STATUS.FETCHING });
    try {
        const res = await post(`${ROUTES.GET_COVERSATION_MESSAGE_COUNT}/${conversationId}`, { ...data });
        if (res.data.code === 200) {
            dispatch({ type: GET_CHAT_MESSAGES_COUNT + STATUS.SUCCESS, count: res.data.data.COUNT, userId: conversationId, checkId: checkId });
        } else {
            dispatch({ type: GET_CHAT_MESSAGES_COUNT + STATUS.FAIL });
        }
    } catch (ex) {
        dispatch({ type: GET_CHAT_MESSAGES_COUNT + STATUS.FAIL });
    }
};

export const getChatConversations = (data) => {
    return async function (dispatch) {
        const shouldReset = data.skip === 0;
        dispatch({ type: GET_CHAT_CONVERSATIONS + STATUS.FETCHING, shouldReset });
        try {
            const res = await post(ROUTES.LOAD_CHAT_CONVERSATIONS, data);
            if (res.data.code === 200) {
                dispatch({
                    type: GET_CHAT_CONVERSATIONS + STATUS.SUCCESS,
                    conversations: res.data.data,
                    shouldReset
                });
                // dispatch({ type: SHOW_MSG_NOTIFICATION_COUNT });
            } else {
                dispatch({ type: GET_CHAT_CONVERSATIONS + STATUS.FAIL });
            }
        } catch (ex) {
            dispatch({ type: GET_CHAT_CONVERSATIONS + STATUS.FAIL });
        }
    }
};

export const getChatConversationsCount = (data) => {
    return async function (dispatch) {
        dispatch({ type: GET_CHAT_CONVERSATIONS_COUNT + STATUS.FETCHING });
        try {
            const res = await post(ROUTES.CHAT_CONVERSATION_COUNT, data);
            if (res.data.code === 200) {
                dispatch({ type: GET_CHAT_CONVERSATIONS_COUNT + STATUS.SUCCESS, count: res.data.data });
            } else {
                dispatch({ type: GET_CHAT_CONVERSATIONS_COUNT + STATUS.FAIL });
            }
        } catch (ex) {
            dispatch({ type: GET_CHAT_CONVERSATIONS_COUNT + STATUS.FAIL });
        }
    }
};

export const updateChatOnNotification = (data) => async (dispatch, getState) => {
    if(data.type === 'msg'){
        const { chats } = (getState().chats);
        if(chats && chats.length > 0 && chats[0]._id === data.data.CONVERSATION_ID){
            dispatch({ type: SOCKET_NEW_CHAT_MESSAGE, message: data.data.TEXT, conversationId: data.data.CONVERSATION_ID})

        } else {
            dispatch(getChatConversations({
                username: '',
                module: 0,
                skip: 0,
                limit: 16
            }));
        }
    }

};

export const setChatActiveStatus = (conversationId, status) => dispatch => {
    dispatch({ type: SET_CHAT_ACTIVE_STATUS, conversationId, status})
}

export const updateChatConversationSeen = (conversationId) => async dispatch => {
    dispatch({ type: SEEN_CHAT_MESSAGE + STATUS.FETCHING });
    try {
        const res = await post(ROUTES.SEEN_CHAT_MESSAGE, {conversationId});
        if (res.data.code === 200) {
            dispatch({ type: SEEN_CHAT_MESSAGE + STATUS.SUCCESS });
        } else {
            dispatch({ type: SEEN_CHAT_MESSAGE + STATUS.FAIL });
        }
    } catch (ex) {
        dispatch({ type: SEEN_CHAT_MESSAGE + STATUS.FAIL });
    }
}

export const refetchMyConversations = () => async dispatch => {
    await dispatch({ type: REFETCH_MY_CONVERSATIONS })
    await dispatch({ type: CLEAR_CHAT_ACTION })
}

export const reportSpam = (data) => async dispatch => {
    dispatch({ type: REPORT_SPAM + STATUS.FETCHING });
    try {
        const res = await post(ROUTES.SPAM_REPORT, data);
        if (res.data.code === 200) {
            dispatch({ type: REPORT_SPAM + STATUS.SUCCESS });
            showAlert(dispatch, ALERT.SUCCESS, res.data.message, 15000, true);
        } else {
            dispatch({ type: REPORT_SPAM + STATUS.FAIL });
            showAlert(dispatch, ALERT.ERROR, res.data.message, 15000, true);
        }
    } catch (ex) {
        dispatch({ type: REPORT_SPAM + STATUS.FAIL });
        // showAlert(dispatch, ALERT.UNAVAILABLE, ERROR_MESSAGES.EXCEPTION_MESSAGE, 5000); removed to handle from webhelper.js
    }
}

// export const updateLastConversationSeenTime = () => async dispatch => {
//     try {
//         const res = await get(ROUTES.UPDATE_LAST_MESSAGE_ACCESS_TIME);
//         if (res.data.code === 200) {
//            console.info('last message seen updated')
//         } else {
//             console.info('last message seen update failed')
//         }
//     } catch (ex) {
//         console.error(ex)
//     }
// };