module.exports = {
    INSERTING_USER : "INSERTING_USER",
    SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
    SIGNUP_AS_PROFESSIONAL : "SIGNUP_AS_PROFESSIONAL",
    SIGNUP_FAIL: "SIGNUP_FAIL",

    INSERTING_USER_DATA_SUCCESS:"INSERTING_USER_DATA_SUCCESS",
    INSERTING_USER_DATA_FAIL:"INSERTING_USER_DATA_FAIL",

    SIGNUP_STEP_TWO: "SIGNUP_STEP_TWO",
    SIGNUP_STEP_TWO_SUCCESS: "SIGNUP_STEP_TWO_SUCCESS",
    SIGNUP_STEP_TWO_FAIL: "SIGNUP_STEP_TWO_FAIL",

    FETCHING_INVESTOR_TYPES:"FETCHING_INVESTOR_TYPES",
    GETTING_INVESTOR_TYPES_SUCCESS:"GETTING_INVESTOR_TYPES_SUCCESS",
    GETTING_INVESTOR_TYPES_FAIL:"GETTING_INVESTOR_TYPES_FAIL",

    AUTHENTICATING_USER:"AUTHENTICATING_USER",
    SIGNIN_SUCCESS:"SIGNIN_SUCCESS",
    SIGNIN_FAIL:"SIGNIN_FAIL",
    
    ACTIVATION_SUCCESS:"ACTIVATION_SUCCESS",
    ACTIVATION_REQUIRED:"ACTIVATION_REQUIRED",
    ACTIVATION_FAIL:"ACTIVATION_FAIL",
    TOKEN_EXPIRED: "TOKEN_EXPIRED",
    INVALID_TOKEN: "INVALID_TOKEN",

    RESEND_ACTIVATION_EMAIL:"RESEND_ACTIVATION_EMAIL",
    RESEND_ACTIVATION_EMAIL_SUCCESS:"RESEND_ACTIVATION_EMAIL_SUCCESS",
    RESEND_ACTIVATION_EMAIL_FAIL:"RESEND_ACTIVATION_EMAIL_FAIL",

    CLEAR_ACTION:"CLEAR_ACTION",
    FETCHING: "FETCHING",
    WELCOME_MESSAGE: "WELCOME_MESSAGE",
    CLEAR_WELCOME_MESSAGE: "CLEAR_WELCOME_MESSAGE",

    GETTING_WELCOME_MESSAGE_SUCCESS: "GETTING_WELCOME_MESSAGE_SUCCESS",
    GETTING_WELCOME_MESSAGE_FAIL: "GETTING_WELCOME_MESSAGE_FAIL",

    SESSION:"SESSION",
    CLEAR_SESSION:"CLEAR_SESSION",
    LOGOUT:"LOGOUT",
    INITAL_FETCHING:"INITAL_FETCHING",

    ADDING_EMPLOYMENT_HISTORY:"ADDING_EMPLOYMENT_HISTORY",
    ADDING_EMPLOYMENT_HISTORY_SUCCESS:"ADDING_EMPLOYMENT_HISTORY_SUCCESS",
    ADDING_EMPLOYMENT_HISTORY_FAIL:"ADDING_EMPLOYMENT_HISTORY_FAIL",

    FETCHING_EMPLOYMENT_HISTORY:"FETCHING_EMPLOYMENT_HISTORY",
    FETCHING_EMPLOYMENT_HISTORY_SUCCESS:"FETCHING_EMPLOYMENT_HISTORY_SUCCESS",
    FETCHING_EMPLOYMENT_HISTORY_FAIL:"FETCHING_EMPLOYMENT_HISTORY_FAIL",

    FETCHING_EMPLOYMENT_HISTORY_FOREDIT:"FETCHING_EMPLOYMENT_HISTORY_FOREDIT",
    FETCHING_EMPLOYMENT_HISTORY_SUCCESS_FOREDIT:"FETCHING_EMPLOYMENT_HISTORY_SUCCESS_FOREDIT",
    FETCHING_EMPLOYMENT_HISTORY_FAIL_FOREDIT:"FETCHING_EMPLOYMENT_HISTORY_FAIL_FOREDIT",

    DELETING_EMPLOYMENT_HISTORY:"DELETING_EMPLOYMENT_HISTORY",
    DELETING_EMPLOYMENT_HISTORY_SUCCESS:"DELETING_EMPLOYMENT_HISTORY_SUCCESS",
    DELETING_EMPLOYMENT_HISTORY_FAIL:"DELETING_EMPLOYMENT_HISTORY_FAIL",

    GETTING_PROFILE_IMAGE:"GETTING_PROFILE_IMAGE",
    GETTING_PROFILE_IMAGE_SUCCESS:"GETTING_PROFILE_IMAGE_SUCCESS",
    GETTING_PROFILE_IMAGE_FAIL:"GETTING_PROFILE_IMAGE_FAIL",

    PROFILE_IMAGE_REMOVED: "PROFILE_IMAGE_REMOVED",

    GETTING_PUBLIC_PROFILE_IMAGE_SUCCESS:"GETTING_PUBLIC_PROFILE_IMAGE_SUCCESS",
    GETTING_PUBLIC_PROFILE_IMAGE_FAIL:"GETTING_PUBLIC_PROFILE_IMAGE_FAIL",

    GETTING_PROFILE_THUMBNAIL_IMAGE_SUCCESS: "GETTING_PROFILE_THUMBNAIL_IMAGE_SUCCESS",
    GETTING_PROFILE_THUMBNAIL_IMAGE_FAIL: "GETTING_PROFILE_THUMBNAIL_IMAGE_FAIL",

    CLEAR_PROFILE_IMAGE:"CLEAR_PROFILE_IMAGE",
    CLEAR_PROFILE_THUMBNAIL_IMAGE: "CLEAR_PROFILE_THUMBNAIL_IMAGE",

    UPDATING_PERSONAL_OVERVIEW:"UPDATING_PERSONAL_OVERVIEW",
    UPDATING_PERSONAL_OVERVIEW_SUCCESS:"UPDATING_PERSONAL_OVERVIEW_SUCCESS",
    UPDATING_PERSONAL_OVERVIEW_FAIL:"UPDATING_PERSONAL_OVERVIEW_FAIL",

    RESETTING_PASSWORD:"RESETTING_PASSWORD",
    RESETTING_PASSWORD_SUCCESS:"RESETTING_PASSWORD_SUCCESS",
    RESETTING_PASSWORD_FAIL:"RESETTING_PASSWORD_FAIL",

    VALIDATE_RESET_TOKEN:'VALIDATE_RESET_TOKEN',
    VALIDATE_RESET_TOKEN_SUCCESS:'VALIDATE_RESET_TOKEN_SUCCESS',
    VALIDATE_RESET_TOKEN_FAIL:'VALIDATE_RESET_TOKEN_FAIL',

    UPDATE_PASSWORD:"UPDATE_PASSWORD",
    UPDATE_PASSWORD_SUCCESS:"UPDATE_PASSWORD_SUCCESS",
    UPDATE_PASSWORD_FAIL:"UPDATE_PASSWORD_FAIL",

    FETCHING_EMPLOYMENT_HISTORY_COMPANY_IMAGES_SUCCESS:"FETCHING_EMPLOYMENT_HISTORY_COMPANY_IMAGES_SUCCESS",
    UPDATE_INVESTOR_OVERVIEW_SUCCESS:"UPDATE_INVESTOR_OVERVIEW_SUCCESS",
    UPDATE_INVESTOR_THESIS_SUCCESS:"UPDATE_INVESTOR_THESIS_SUCCESS",

    UPDATING_EMPLOYMENT_HISTORY: 'UPDATING_EMPLOYMENT_HISTORY',
    UPDATING_EMPLOYMENT_HISTORY_SUCCESS: 'UPDATING_EMPLOYMENT_HISTORY_SUCCESS',
    UPDATING_EMPLOYMENT_HISTORY_FAIL: 'UPDATING_EMPLOYMENT_HISTORY_FAIL',

    DELETE_USER: "DELETE_USER",
    DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
    DELETE_USER_FAIL: "DELETE_USER_FAIL",
    CLEAR_DELETE_USER_FAIL_MESSAGE: "CLEAR_DELETE_USER_FAIL_MESSAGE",

    NOT_DELETABLE_COMPANY_ADMIN: "NOT_DELETABLE_COMPANY_ADMIN",

    GET_NONUPDATED_COMPANIES:"GET_NONUPDATED_COMPANIES",
    GET_NONUPDATED_COMPANIES_SUCCESS:"GET_NONUPDATED_COMPANIES_SUCCESS",
    GET_NONUPDATED_COMPANIES_FAIL:"GET_NONUPDATED_COMPANIES_FAIL",

    HIDE_NONUPDATED_COMPANIES_WARNING: "HIDE_NONUPDATED_COMPANIES_WARNING",

    CHANGE_PASSWORD : "CHANGE_PASSWORD",
    CHANGE_PASSWORD_SUCCESS : "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAIL : "CHANGE_PASSWORD_FAIL",

    CHANGE_EMAIL: "CHANGE_EMAIL",
    CHANGE_EMAIL_SUCCESS: "CHANGE_EMAIL_SUCCESS",
    CHANGE_EMAIL_FAIL: "CHANGE_EMAIL_FAIL",

    FETCHING_IS_LOGGED_IN_USER_COMPANY_ADMIN: "FETCHING_IS_LOGGED_IN_USER_COMPANY_ADMIN",
    FETCH_IS_LOGGED_IN_USER_COMPANY_ADMIN_SUCCESS: "FETCH_IS_LOGGED_IN_USER_COMPANY_ADMIN_SUCCESS",
    FETCH_IS_LOGGED_IN_USER_COMPANY_ADMIN_FAIL: "FETCH_IS_LOGGED_IN_USER_COMPANY_ADMIN_FAIL",

    FETCHING_ADMIN_COMPANY_LIST:'FETCHING_ADMIN_COMPANY_LIST',
    FETCHING_ADMIN_COMPANY_LIST_SUCCESS:'FETCHING_ADMIN_COMPANY_LIST_SUCCESS',
    FETCHING_ADMIN_COMPANY_LIST_FAIL:'FETCHING_ADMIN_COMPANY_LIST_FAIL',
    ADD_TO_ADMIN_COMPANY_LIST:'ADD_TO_ADMIN_COMPANY_LIST',
    REMOVE_FROM_ADMIN_COMPANY_LIST:'REMOVE_FROM_ADMIN_COMPANY_LIST',

    ROUTE_ACCESS: "ROUTE_ACCESS",
    ROUTE_ACCESS_SUCCESS: "ROUTE_ACCESS_SUCCESS",
    CLEAR_ROUTE_ACCESS: "CLEAR_ROUTE_ACCESS",

    SET_INDUSTRY_ROLES_SUCCESS: "SET_INDUSTRY_ROLES_SUCCESS",
    SET_INDUSTRY_ROLES_FAIL: "SET_INDUSTRY_ROLES_FAIL",    

    UPDATE_USER: "UPDATE_USER",
    CLEAR_IS_FIRST_TIME: "CLEAR_IS_FIRST_TIME",

    RESETTING_TOP_NAV_COUNTS: "RESETTING_THE_TOP_NAV_COUNTS",
    RESET_TOP_NAV_COUNTS_SUCCESS: "RESETT_TOP_NAV_COUNTS_SUCCESS",
    RESET_TOP_NAV_COUNTS_FAIL: "RESET_TOP_NAV_COUNTS_FAIL",

    UPDATE_NOTIFICATION_COUNTERS_AFTER_DELETE: "UPDATE_NOTIFICATION_COUNTERS_AFTER_DELETE",

    GETTING_UNSEEN_CONNECTION_REQUESTERS_ID_SET: "GETTING_UNSEEN_CONNECTION_REQUESTERS_ID_SET",
    GETTING_UNSEEN_CONNECTION_REQUESTERS_ID_SET_SUCCESS: "GETTING_UNSEEN_CONNECTION_REQUESTERS_ID_SET_SUCCESS",
    GETTING_UNSEEN_CONNECTION_REQUESTERS_ID_SET_FAIL: "GETTING_UNSEEN_CONNECTION_REQUESTERS_ID_SET_FAIL",

    RELOAD_TOP_NAV_COUNTS: "RELOAD_TOP_NAV_COUNTS",
    RELOAD_TOP_NAV_COUNTS_SUCCESS: "RELOAD_TOP_NAV_COUNTS_SUCCESS",
    RELOAD_TOP_NAV_COUNTS_FAIL: "RELOAD_TOP_NAV_COUNTS_FAIL",

    FETCH_TOOLTIP: "FETCH_TOOLTIP",
    FETCH_TOOLTIP_SUCCESS: "FETCH_TOOLTIP_SUCCESS",
    FETCH_TOOLTIP_FAIL: "FETCH_TOOLTIP_FAIL",

    CLOSE_CANDIDATE_SURVEY_SUCCESS: 'CLOSE_CANDIDATE_SURVEY_SUCCESS',
    CLOSE_CANDIDATE_SURVEY_FAIL: 'CLOSE_CANDIDATE_SURVEY_FAIL',

    ADD_INTERESTED_EVENT: 'ADD_INTERESTED_EVENT',
    REMOVE_INTERESTED_EVENT: 'REMOVE_INTERESTED_EVENT',

    CHANGE_EVENT_NOTIFICATION: 'CHANGE_EVENT_NOTIFICATION',

    SET_PUBLIC_ACTION: "SET_PUBLIC_ACTION",
    REMOVE_PUBLIC_ACTION: "REMOVE_PUBLIC_ACTION",
    SET_PUBLIC_ACTION_AUTH: "SET_PUBLIC_ACTION_AUTH",

    UPDATE_USER_PERSONAL_SUMMARY_SUCCESS: 'UPDATE_USER_PERSONAL_SUMMARY_SUCCESS',
    UPDATE_USER_PERSONAL_SUMMARY_FAIL: 'UPDATE_USER_PERSONAL_SUMMARY_FAIL',

    FETCH_SIGNED_COOKIES: "FETCH_SIGNED_COOKIES",
    FETCH_SIGNED_COOKIES_SUCCESS: "FETCH_SIGNED_COOKIES_SUCCESS",
    FETCH_SIGNED_COOKIES_FAIL: "FETCH_SIGNED_COOKIES_FAIL",
    
    UPDATE_USER_NEWSFEED_STATUS: "UPDATE_USER_NEWSFEED_STATUS",
    UPDATE_COREG_SEEN: "UPDATE_COREG_SEEN",

};