import connectionType from '../types/connections';
import { orderPendingConnections } from "./../libs/textFormat";

export const initialState = {
    fetchingMyConnections: false,
    fetchingRecommondedConnections: false,
    error: null,
    action: 0,
    users: [],
    recommondedConnections: [],
    recommondedConnectionsCount: 0,
    userCount: 0,
    searchMode: false,
    fetchingPendingConnections: false,
    pendingConnections: [],
    pendingConnectionCount: 0,
    connectionsCount: 0,
    myConnectionsCount: 0,
    showNextBtn: false,
    activeFilterConnectionSection: null,
    lastDeletedConnectionConversation: null,
    countLoading: false,
    isLastRecommendedPageFound: false,
    requestedUser: null
};

export const connections = (state = initialState, action) => {
    switch (action.type) {
        case connectionType.CLEAR_ACTION:
            return {
                ...state,
                action: 0,
                fetchingMyConnections: false
            };

        case connectionType.FETCHING_MY_CONNECTIONS:
            return {
                ...state,
                fetching: true,
                action: connectionType.FETCHING_MY_CONNECTIONS,
                fetchingMyConnections: true
            };
        case connectionType.FETCHING_MY_CONNECTIONS_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: connectionType.FETCHING_MY_CONNECTIONS_SUCCESS,
                users: action.reset ? action.payload : state.users.concat(action.payload),
                //users: action.payload,
                fetchingMyConnections: false
            };
        case connectionType.FETCHING_MY_CONNECTIONS_FAIL:
            return {
                ...state,
                fetching: false,
                action: connectionType.FETCHING_MY_CONNECTIONS_FAIL,
                fetchingMyConnections: false
            };

        case connectionType.FETCHING_MY_CONNECTIONS_COUNT:
            return {
                ...state,
                fetching: true,
                action: connectionType.FETCHING_MY_CONNECTIONS_COUNT,
                myConnectionsCount: 0
            };
        case connectionType.FETCHING_MY_CONNECTIONS_COUNT_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: connectionType.FETCHING_MY_CONNECTIONS_COUNT_SUCCESS,
                myConnectionsCount: action.payload,
            };
        case connectionType.FETCHING_MY_CONNECTIONS_COUNT_FAIL:
            return {
                ...state,
                fetching: false,
                action: connectionType.FETCHING_MY_CONNECTIONS_COUNT_FAIL,
                myConnectionsCount: 0
            };

        case connectionType.DISCONNECTING_MY_CONNECTION:
            return {
                ...state,
                fetching: true,
                action: connectionType.DISCONNECTING_MY_CONNECTION
            };
        case connectionType.DISCONNECTING_MY_CONNECTION_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: connectionType.DISCONNECTING_MY_CONNECTION_SUCCESS
            };
        case connectionType.DISCONNECTING_MY_CONNECTION_FAIL:
            return {
                ...state,
                fetching: false,
                action: connectionType.DISCONNECTING_MY_CONNECTION_FAIL
            };

        case connectionType.FETCHING_MORE_CONNECTIONS:
            return {
                ...state,
                action: action.type,
                fetchingMyConnections: true
            }

        case connectionType.FETCHING_RECOMMONDED_CONNECTIONS:
            return {
                ...state,
                action: action.type,
                fetchingRecommondedConnections: true
            };
        case connectionType.FETCHING_RECOMMONDED_CONNECTIONS_SUCCESS:
            return {
                ...state,
                action: action.type,
                recommondedConnections:/* action.reset ? action.payload :  */ state.recommondedConnections.concat(action.payload),
                //recommondedConnections: action.payload,
                fetchingRecommondedConnections: false
            };
        case connectionType.SET_RECOMMENDED_CONNECTIONS_IMAGES:
            let updated = state.recommondedConnections.map(item => {
                return { ...item, PROFILE_PHOTO: action.payload[item._id] ? action.payload[item._id] : item.PROFILE_PHOTO }
            });
            return {
                ...state,
                action: action.type,
                recommondedConnections: updated
            };
        case connectionType.FETCHING_RECOMMONDED_CONNECTIONS_FAIL:
            return {
                ...state,
                action: action.type,
                fetchingRecommondedConnections: false
            };
        case connectionType.FETCHING_MORE_RECOMMONDED_CONNECTIONS:
            return {
                ...state,
                action: action.type,
                fetchingRecommondedConnections: true
            };
        case connectionType.FETCHING_MORE_SEARCH_CONNECTIONS:
            return {
                ...state,
                action: action.type
            };
        case connectionType.TOGGLE_SEARCH_MODE:
            return {
                ...state,
                searchMode: action.payload,
                action: connectionType.TOGGLE_SEARCH_MODE
            };
        case connectionType.SET_TO_FIRST:
            return {
                ...state,
                action: connectionType.SET_TO_FIRST
            };
        case connectionType.CLEAR_RECOMMONDED_CONNECTIONS:
            return {
                ...state,
                recommondedConnections: []
            };
        case connectionType.REPLACE_RECOMMONDED_CONNECTIONS:
            return {
                ...state,
                recommondedConnections: state.recommondedConnections.slice(0, action.skip).concat(action.payload),
                action: connectionType.REPLACE_RECOMMONDED_CONNECTIONS
            };
        case connectionType.REPLACE_RECOMMONDED_CONNECTIONS_FAIL:
            return {
                ...state,
                action: connectionType.REPLACE_RECOMMONDED_CONNECTIONS_FAIL
            };
        case connectionType.FETCHING_RECOMMONDED_CONNECTIONS_COUNT:
            return {
                ...state,
                action: connectionType.FETCHING_RECOMMONDED_CONNECTIONS_COUNT
            };
        case connectionType.FETCHING_RECOMMONDED_CONNECTIONS_COUNT_SUCCESS:
            return {
                ...state,
                action: connectionType.FETCHING_RECOMMONDED_CONNECTIONS_COUNT_SUCCESS,
                recommondedConnectionsCount: action.payload
            };
        case connectionType.FETCHING_RECOMMONDED_CONNECTIONS_COUNT_FAIL:
            return {
                ...state,
                action: connectionType.FETCHING_RECOMMONDED_CONNECTIONS_COUNT_FAIL
            };

        case connectionType.CLEAR_MY_CONNECTIONS:
            return {
                ...state,
                users: []
            }
        case connectionType.FETCHING_PENDING_CONNECTIONS:
            return {
                ...state,
                action: action.type,
                fetchingPendingConnections: true
            };
        case connectionType.FETCHING_PENDING_CONNECTIONS_SUCCESS:
            return {
                ...state,
                action: action.type,
                pendingConnections: action.reset ? action.payload : state.pendingConnections.concat(action.payload),
                fetchingPendingConnections: false
            };
        case connectionType.FETCHING_PENDING_CONNECTIONS_FAIL:
            return {
                ...state,
                action: action.type,
                fetchingPendingConnections: false
            };
        case connectionType.FETCHING_PENDING_CONNECTIONS_EXCEPTION:
            return {
                ...state,
                action: action.type,
                fetchingPendingConnections: false
            };
        case connectionType.CLEAR_PENDING_CONNECTIONS:
            return {
                ...state,
                pendingConnections: []
            };

        case connectionType.PUSH_NEW_CONNECTION_REQUEST:
            return {
                ...state,
                action: action.type,
                pendingConnections: [action.data, ...state.pendingConnections],
                pendingConnectionCount: state.pendingConnectionCount + 1
            };
        case connectionType.POP_EXISTING_CONNECTION_REQUEST:
            let formattedPendingConnections = orderPendingConnections(state.pendingConnections, action.data.requesterUserId);
            return {
                ...state,
                action: action.type,
                pendingConnections: formattedPendingConnections,
                pendingConnectionCount: state.pendingConnectionCount - 1
            };

        case connectionType.FETCHING_PENDING_CONNECTIONS_COUNT:
            return {
                ...state,
                action: connectionType.FETCHING_PENDING_CONNECTIONS_COUNT
            };
        case connectionType.FETCHING_PENDING_CONNECTIONS_COUNT_SUCCESS:
            return {
                ...state,
                action: connectionType.FETCHING_PENDING_CONNECTIONS_COUNT_SUCCESS,
                pendingConnectionCount: action.payload.COUNT
            };
        case connectionType.FETCHING_PENDING_CONNECTIONS_COUNT_FAIL:
            return {
                ...state,
                action: connectionType.FETCHING_PENDING_CONNECTIONS_COUNT_FAIL
            };
        case connectionType.FETCHING_PENDING_CONNECTIONS_COUNT_EXCEPTION:
            return {
                ...state,
                action: connectionType.FETCHING_PENDING_CONNECTIONS_COUNT_EXCEPTION
            };

        case connectionType.ACCEPTING_PENDING_CONNECTION:
            return {
                ...state,
                action: connectionType.ACCEPTING_PENDING_CONNECTION
            };
        case connectionType.SEND_CONNECTION_REQUEST_SUCCESS:
            return {
                ...state,
                action: connectionType.SEND_CONNECTION_REQUEST_SUCCESS,
                connectionSentUserId: action.userId
            }
        case connectionType.ACCEPT_PENDING_CONNECTION_SUCCESS:
            return {
                ...state,
                action: connectionType.ACCEPT_PENDING_CONNECTION_SUCCESS,
                connectionAcceptedUserId: action.userId
            };
        case connectionType.ACCEPT_PENDING_CONNECTION_FAIL:
            return {
                ...state,
                action: connectionType.ACCEPT_PENDING_CONNECTION_FAIL
            };
        case connectionType.ACCEPT_PENDING_CONNECTION_EXCEPTION:
            return {
                ...state,
                action: connectionType.ACCEPT_PENDING_CONNECTION_EXCEPTION
            };

        case connectionType.REJECTING_PENDING_CONNECTION:
            return {
                ...state,
                action: connectionType.REJECTING_PENDING_CONNECTION
            };
        case connectionType.REJECT_PENDING_CONNECTION_SUCCESS:
            return {
                ...state,
                action: connectionType.REJECT_PENDING_CONNECTION_SUCCESS,
                connectionRejectedUserId: action.userId
            };
        case connectionType.REJECT_PENDING_CONNECTION_FAIL:
            return {
                ...state,
                action: connectionType.REJECT_PENDING_CONNECTION_FAIL
            };
        case connectionType.REJECT_PENDING_CONNECTION_EXCEPTION:
            return {
                ...state,
                action: connectionType.REJECT_PENDING_CONNECTION_EXCEPTION
            };
        case connectionType.GET_CONNECTIONS_COUNT_SUCCESS:
            return {
                ...state,
                action: connectionType.GET_CONNECTIONS_COUNT_SUCCESS,
                connectionsCount: action.payload.CONNECTIONS,
                myConnectionsCount: action.payload.CONNECTIONS
            };
        case connectionType.GET_CONNECTIONS_COUNT_FAIL:
            return {
                ...state,
                action: connectionType.GET_CONNECTIONS_COUNT_FAIL,
                connectionsCount: 0
            };
        case connectionType.GET_IMAGE_URL:
            const newData = [...state.recommondedConnections];
            newData[action.index] = action.payload;
            return {
                ...state,
                recommondedConnections: newData
            };
        case connectionType.REJECT_MY_CONNECTION:
            return {
                ...state,
                action: connectionType.REJECT_MY_CONNECTION,
                users: state.users.filter(user => user._id !== action.payload)
            };
        case connectionType.FETCH_RECOMMENDED_NEXT_BUTTON:
            return {
                ...state,
                action: connectionType.FETCH_RECOMMENDED_NEXT_BUTTON,
                countLoading: true,

            };
        case connectionType.FETCH_RECOMMENDED_NEXT_BUTTON_SUCCESS:
            return {
                ...state,
                action: connectionType.FETCH_RECOMMENDED_NEXT_BUTTON_SUCCESS,
                showNextBtn: action.payload,
                countLoading: false,
                isLastRecommendedPageFound: action.payload === false
            };
        case connectionType.FETCH_RECOMMENDED_NEXT_BUTTON_SUCCESS_ON_PREVIOUS:
            return {
                ...state,
                action: connectionType.FETCH_RECOMMENDED_NEXT_BUTTON_SUCCESS_ON_PREVIOUS,
                showNextBtn: true,
                countLoading: false
            };
        case connectionType.FETCH_RECOMMENDED_NEXT_BUTTON_FAIL:
            return {
                ...state,
                action: connectionType.FETCH_RECOMMENDED_NEXT_BUTTON_FAIL,
                showNextBtn: false,
                countLoading: false
            };
        case connectionType.SET_CONNECTION_MESSAGES_SEEN:
            return {
                ...state,
                pendingConnections: state.pendingConnections.map(connection => {
                    if (connection.CONVERSATION_ID === action.conversationId) {
                        connection.SEEN = true;
                        return connection
                    } else {
                        return connection
                    }
                })
            };
        case connectionType.SET_ACTIVE_FILTER_SECTION: 
            return {
                ...state,
                activeFilterConnectionSection: action.payload
            };
        case connectionType.REMOVE_DELETED_CONNECTION_MESSAGE:
            return {
                ...state,
                pendingConnections: state.pendingConnections.map(connection => {
                    return {
                        ...connection,
                        CONVERSATION_ID: connection.CONVERSATION_ID === action.conversationId ? null : connection.CONVERSATION_ID,
                        CONNECTION_MESSAGE: connection.CONVERSATION_ID === action.conversationId ? "" : connection.CONNECTION_MESSAGE
                    }
                }),
                lastDeletedConnectionConversation: action.conversationId,
                action: connectionType.REMOVE_DELETED_CONNECTION_MESSAGE
            };
        case connectionType.CONNECTION_REQ_ACCEPTED_BY_USER:
            return {
                ...state,
                connectionAcceptedByUserId: action.acceptedBy
            }
        case connectionType.CONNECTION_REQ_REJECTED_BY_USER:
            return {
                ...state,
                connectionRejectedByUserId: action.rejectedBy
            }
        case connectionType.CLEAR_PREVIOUS_REQUEST: 
            return {
                ...state,
                connectionRejectedUserId: null,
                connectionSentUserId: null,
                connectionAcceptedUserId: null,
                connectionRejectedByUserId: null, 
                connectionAcceptedByUserId: null
        }
        case connectionType.SET_REQUESTED_USER_NOTIFICATION:
            return {
                ...state,
                requestedUser: action.userId
            }
        case connectionType.CLEAR_REQUESTED_USER_NOTIFICATION:
            return{
                ...state,
                requestedUser: null
            }
        default:
            return state
    }
};