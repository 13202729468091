module.exports = {
    BANNED: 0, // seller
    RESERVE: 1, // settings

    CLEAR_ACTION: "CLEAR_ACTION",

    FETCHING_VWL: "FETCHING_VANITY_WORDS_LIST",
    FETCHING_VWL_SUCCESS: "FETCHING_VANITY_WORDS_LIST_SUCCESS",
    FETCHING_VWL_FAIL: "FETCHING_VANITY_WORDS_LIST_FAIL",

    CREATE_VW: "CREATE_VANITY_WORD",
    CREATE_VW_SUCCESS: "CREATE_VANITY_WORD_SUCCESS",
    CREATE_VW_FAIL: "CREATE_VANITY_WORD_FAIL",

    DELETE_VW: "DELETE_VANITY_WORD",
    DELETE_VW_SUCCESS: "DELETE_VANITY_WORD_SUCCESS",
    DELETE_VW_FAIL: "DELETE_VANITY_WORD_FAIL",


};