import React, { Component } from "react";
import "./index.css";
import { isURL } from './../../../../libs/validation';
import { imageExists } from './../../../../libs/image';

import defaultAvatorCompany from "./../../../../assets/img/default-corporate-image.jpg";
import defaultFundraisersCompany from "./../../../../assets/img/fundraisers-default.jpg";
import { getCFObjURL, BUCKET } from "../../../../libs/cfHelper";


class CompanyImageBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            avatar: this.props.slider ? defaultFundraisersCompany : defaultAvatorCompany,
            imageName: this.props.imgName
        }
    }

    componentWillMount() {
        if (this.props.imgName !== '' && !isURL(String.toString(this.props.imgName))) {
            this._getImageUrl(this.props.imgName);
        }
    }

    _getImageUrl(imageName) {
        const url = getCFObjURL(BUCKET.COMMON)(('ex-mediam-' + imageName), true);
        if (url) {
            imageExists(url, (exists) => {
                if (exists) {
                    this.setState({avatar: url})
                } else {
                    this.setState({avatar: this.props.slider ? defaultFundraisersCompany : defaultAvatorCompany})
                }
            });
        } else {
            this.setState({
                avatar: this.props.slider ? defaultFundraisersCompany : defaultAvatorCompany
            });
        }
    }

    render() {
        return (
            <div className="fundrasersCompnayLogo d-flex align-items-center justify-content-center">
                <img src={this.state.avatar} className="img-fluid" alt="Welnvest" />
            </div>
        );
    }

}

export { CompanyImageBox };