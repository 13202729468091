import React, { Component } from "react"
import "./styles.css";


class CheckBox extends Component {


    render() {
        return (
            <React.Fragment>
                <input type="checkbox" id={this.props.name} name={this.props.name} checked={this.props.checked} onChange={this.props.onChange}/>
                <label htmlFor={this.props.name} className="form-check-label font_16 font_light pl-2 mt_minus">{this.props.label}</label>
            </React.Fragment>
        )
    }

}
export { CheckBox };