module.exports = {

    INSERTING_FUND_RAISING_ROUND: "INSERTING_FUND_RAISING_ROUND",
    FUND_RAISING_ROUND_SUCCESS: "FUND_RAISING_ROUND_SUCCESS",
    FUND_RAISING_ROUND_FAIL: "FUND_RAISING_ROUND_FAIL",
    FUND_RAISING_ROUND_CLEAR_SUCCESS: "FUND_RAISING_ROUND_CLEAR_SUCCESS",
    FUND_RAISING_ROUND_CLEAR_FAIL: "FUND_RAISING_ROUND_CLEAR_FAIL",


    FETCHING_COMPANY_DATA: "GETTING_COMPANY_DATA",
    FETCHING_COMPANY_DATA_SUCCESS: "GETTING_COMPANY_DATA_SUCCESS",
    FETCHING_COMPANY_DATA_NOT_FOUND: "FETCHING_COMPANY_DATA_NOT_FOUND",
    FETCHING_COMPANY_DATA_FAIL: "GETTING_COMPANY_DATA_FAIL",
    CLEAR_COMPANY_DATA: "CLEAR_COMPANY_DATA",

    FETCHING_INVESTORS: "FETCHING_INVESTORS",
    FETCHING_INVESTORS_SUCCESS: "FETCHING_INVESTORS_SUCCESS",
    FETCHING_INVESTORS_FAIL: "FETCHING_INVESTORS_FAIL",

    FETCHING_INDIVIDUAL_INVESTORS: "FETCHING_INDIVIDUAL_INVESTORS",
    FETCHING_INDIVIDUAL_INVESTORS_SUCCESS: "FETCHING_INDIVIDUAL_INVESTORS_SUCCESS",
    FETCHING_INDIVIDUAL_INVESTORS_FAIL: "FETCHING_INDIVIDUAL_INVESTORS_FAIL",

    FETCHING_CURRENT_INVESTORS: "FETCHING_CURRENT_INVESTORS",
    FETCHING_CURRENT_INVESTORS_SUCCESS: "FETCHING_CURRENT_INVESTORS_SUCCESS",
    FETCHING_CURRENT_INVESTORS_FAIL: "FETCHING_CURRENT_INVESTORS_FAIL",

    ADDING_INVESTOR: "ADDING_INVESTOR",
    ADDING_INVESTOR_SUCCESS: "ADDING_INVESTOR_SUCCESS",
    ADDING_INVESTOR_FAIL: "ADDING_INVESTOR_FAIL",

    DELETING_INVESTOR: "DELETING_INVESTOR",
    DELETING_INVESTOR_SUCCESS: "DELETING_INVESTOR_SUCCESS",
    DELETING_INVESTOR_FAIL: "DELETING_INVESTOR_FAIL",

    UPDATING_OVERVIEW: "UPDATING_OVERVIEW",
    UPDATING_OVERVIEW_SUCCESS: "UPDATING_OVERVIEW_SUCCESS",
    UPDATING_OVERVIEW_FAIL: "UPDATING_OVERVIEW_FAIL",

    ADDING_EMPLOYEE: "ADDING_EMPLOYEE",
    ADDING_EMPLOYEE_SUCCESS: "ADDING_EMPLOYEE_SUCCESS",
    ADDING_EMPLOYEE_FAIL: "ADDING_EMPLOYEE_FAIL",

    DELETING_EMPLOYEE: "DELETING_EMPLOYEE",
    DELETING_EMPLOYEE_SUCCESS: "DELETING_EMPLOYEE_SUCCESS",
    DELETING_EMPLOYEE_FAIL: "DELETING_EMPLOYEE_FAIL",

    FETCHING_EMPLOYEES: "FETCHING_EMPLOYEES",
    FETCHING_EMPLOYEES_SUCCESS: "FETCHING_EMPLOYEES_SUCCESS",
    FETCHING_EMPLOYEES_FAIL: "FETCHING_EMPLOYEES_FAIL",

    FETCHING_CURRENT_EMPLOYEES: "FETCHING_CURRENT_EMPLOYEES",
    FETCHING_CURRENT_EMPLOYEES_SUCCESS: "FETCHING_CURRENT_EMPLOYEES_SUCCESS",
    FETCHING_CURRENT_EMPLOYEES_FAIL: "FETCHING_CURRENT_EMPLOYEES_FAIL",

    FETCHING_CURRENT_PRODUCTS : "FETCHING_CURRENT_PRODUCTS",
    FETCHING_CURRENT_PRODUCTS_SUCCESS : "FETCHING_CURRENT_PRODUCTS_SUCCESS",
    FETCHING_CURRENT_PRODUCTS_FAIL : "FETCHING_CURRENT_PRODUCTS_FAIL",

    FETCHING_SINGLE_PRODUCTS_SUCCESS: "FETCHING_SINGLE_PRODUCTS_SUCCESS",

    INSERTING_PRODUCT_IMAGE : "INSERTING_PRODUCT_IMAGE",
    INSERTING_PRODUCT_IMAGE_SUCCESS : "INSERTING_PRODUCT_IMAGE_SUCCESS",
    INSERTING_PRODUCT_IMAGE_FAIL : "INSERTING_PRODUCT_IMAGE_FAIL",

    ADDING_PRODUCT: "ADDING_PRODUCT",
    ADDING_PRODUCT_SUCCESS: "ADDING_PRODUCT_SUCCESS",
    ADDING_PRODUCT_FAIL: "ADDING_PRODUCT_FAIL",

    UPDATING_PRODUCT_IMAGE:"UPDATING_PRODUCT_IMAGE",
    UPDATING_PRODUCT_IMAGE_SUCCESS:"UPDATING_PRODUCT_IMAGE_SUCCESS",
    UPDATING_PRODUCT_IMAGE_FAIL:"UPDATING_PRODUCT_IMAGE_FAIL",

    DELETING_PRODUCT_IMAGE:"DELETING_PRODUCT_IMAGE",
    DELETING_PRODUCT_IMAGE_SUCCESS:"DELETING_PRODUCT_IMAGE_SUCCESS",
    DELETING_PRODUCT_IMAGE_FAIL:"DELETING_PRODUCT_IMAGE_FAIL",

    FETCHING_COMPANIES:"FETCHING_COMPANIES",
    FETCHING_COMPANIES_SUCCESS:"FETCHING_COMPANIES_SUCCESS",
    FETCHING_COMPANIES_FAIL:"FETCHING_COMPANIES_FAIL",

    UPDATING_COMPANY_USER:"UPDATING_COMPANY_USER",
    UPDATING_COMPANY_USER_SUCCESS:"UPDATING_COMPANY_USER_SUCCESS",
    UPDATING_COMPANY_USER_FAIL:"UPDATING_COMPANY_USER_FAIL",

    ADDING_PUBLIC_VIDEO:"ADDING_PUBLIC_VIDEO",
    ADDING_PUBLIC_VIDEO_SUCCESS:"ADDING_PUBLIC_VIDEO_SUCCESS",
    ADDING_PUBLIC_VIDEO_FAIL:"ADDING_PUBLIC_VIDEO_FAIL",

    UPDATING_COMPANY_NAME: "UPDATING_COMPANY_NAME",
    UPDATING_COMPANY_NAME_SUCCESS:"UPDATING_COMPANY_NAME_SUCCESS",
    UPDATING_COMPANY_NAME_FAIL:"UPDATING_COMPANY_NAME_FAIL",

    FETCHING_EMPLOYEE_PUBLIC_PROFILE_IMAGES_SUCCESS:"FETCHING_EMPLOYEE_PUBLIC_PROFILE_IMAGES_SUCCESS",

    FETCHING_REGULAR_EMPLOYEES: "FETCHING_REGULAR_EMPLOYEES",
    FETCHING_REGULAR_EMPLOYEES_SUCCESS: "FETCHING_REGULAR_EMPLOYEES_SUCCESS",
    FETCHING_REGULAR_EMPLOYEES_FAIL: "FETCHING_REGULAR_EMPLOYEES_FAIL",

    FETCHING_NON_ADVISORS: "FETCHING_NON_ADVISORS",
    FETCHING_NON_ADVISORS_SUCCESS: "FETCHING_NON_ADVISORS_SUCCESS",
    FETCHING_NON_ADVISORS_FAIL: "FETCHING_NON_ADVISORS_FAIL",

    ADD_ADVISER: "ADD_ADVISER",
    ADD_ADVISER_SUCCESS: "ADD_ADVISER_SUCCESS",
    ADD_ADVISER_FAIL: "ADD_ADVISER_FAIL",

    REMOVE_ADVISER: "REMOVE_ADVISER",
    REMOVE_ADVISER_SUCCESS: "REMOVE_ADVISER_SUCCESS",
    REMOVE_ADVISER_FAIL: "REMOVE_ADVISER_FAIL",

    RESEND_ADVISER_REQUEST: "RESEND_ADVISER_REQUEST",
    RESEND_ADVISER_REQUEST_SUCCESS: "RESEND_ADVISER_REQUEST_SUCCESS",
    RESEND_ADVISER_REQUEST_FAIL: "RESEND_ADVISER_REQUEST_FAIL",

    ACCEPT_ADVISER_REQUEST: "ACCEPT_ADVISER_REQUEST",
    ACCEPT_ADVISER_REQUEST_SUCCESS: "ACCEPT_ADVISER_REQUEST_SUCCESS",
    ACCEPT_ADVISER_REQUEST_FAIL: "ACCEPT_ADVISER_REQUEST_FAIL",

    REJECT_ADVISER_REQUEST: "REJECT_ADVISER_REQUEST",
    REJECT_ADVISER_REQUEST_SUCCESS: "REJECT_ADVISER_REQUEST_SUCCESS",
    REJECT_ADVISER_REQUEST_FAIL: "REJECT_ADVISER_REQUEST_FAIL",

    GET_ADVISER: "GET_ADVISER",
    GET_ADVISER_SUCCESS: "GET_ADVISER_SUCCESS",
    GET_ADVISER_FAIL: "GET_ADVISER_FAIL",

    GETTING_ADVISORS_PROFILE_IMAGE_SUCCESS: "GETTING_ADVISORS_PROFILE_IMAGE_SUCCESS",
    FETCHING_CURRENT_INVESTORS_PROFILE_IMAGE_SUCCESS: "FETCHING_CURRENT_INVESTORS_PROFILE_IMAGE_SUCCESS",
    GETTING_KEY_EMPLOYEED_COMPANY_IMAGE_SUCCESS: "GETTING_KEY_EMPLOYEED_COMPANY_IMAGE_SUCCESS",

    GETTING_TOOLS: "GETTING_TOOLS",
    GETTING_TOOLS_SUCCESS: "GETTING_TOOLS_SUCCESS",
    GETTING_TOOLS_FAIL: "GETTING_TOOLS_FAIL",

    FETCHING_FUNDING_COMPANY_LIST: "FETCHING_FUNDING_COMPANY_LIST",
    FETCHING_FUNDING_COMPANY_LIST_SUCCESS: "FETCHING_FUNDING_COMPANY_LIST_SUCCESS",
    FETCHING_FUNDING_COMPANY_LIST_FAIL: "FETCHING_FUNDING_COMPANY_LIST_FAIL",

    SETTING_FUNDRAISING_COMPANY: "SETTING_FUNDRAISING_COMPANY",
    SETTING_FUNDRAISING_COMPANY_SUCCESS: "SETTING_FUNDRAISING_COMPANY_SUCCESS",
    SETTING_FUNDRAISING_COMPANY_FAIL: "SETTING_FUNDRAISING_COMPANY_FAIL",

    STRIPE_CUSTOMER: 'STRIPE_CUSTOMER',
    BLUESNAP_CUSTOMER: 'BLUESNAP_CUSTOMER',

    GET_COMPANY_QUESTIONS: "GET_COMPANY_QUESTIONS",
    GET_COMPANY_QUESTIONS_SUCCESS: "GET_COMPANY_QUESTIONS_SUCCESS",
    GET_COMPANY_QUESTIONS_FAIL: "GET_COMPANY_QUESTIONS_FAIL",

    SAVE_COMPANY_QUESTIONS: "SAVE_COMPANY_QUESTIONS",
    SAVE_COMPANY_QUESTIONS_SUCCESS: "SAVE_COMPANY_QUESTIONS_SUCCESS",
    SAVE_COMPANY_QUESTIONS_FAIL: "SAVE_COMPANY_QUESTIONS_FAIL",

    CREATE_COMPANY: "CREATE_COMPANY",
    CREATE_COMPANY_SUCCESS: "CREATE_COMPANY_SUCCESS",
    CREATE_COMPANY_FAIL: "CREATE_COMPANY_FAIL",
    CLEAR_NEW_COMPANY: "CLEAR_NEW_COMPANY",

    CLEAR_ACTION: "CLEAR_ACTION",

    DELETE_COMPANY: "DELETE_COMPANY",
    DELETE_COMPANY_SUCCESS: "DELETE_COMPANY_SUCCESS",
    DELETE_COMPANY_FAIL: "DELETE_COMPANY_FAIL",

    SWITCH_COMPANY: 'SWITCH_COMPANY',
};