module.exports={
    // CREATEING_EVENT:"CREATEING_EVENT",
    // EVENT_CREATION_SUCCESS:"EVENT_CREATION_SUCCESS",
    // EVENT_CREATION_FAIL:"EVENT_CREATION_FAIL",

    // UPDATING_EVENT:"UPDATING_EVENT",
    // EVENT_UPDATE_SUCCESS:"EVENT_UPDATE_SUCCESS",
    // EVENT_UPDATE_FAIL:"EVENT_UPDATE_FAIL",

    // DELETING_EVENT:"DELETING_EVENT",
    // EVENT_DELETE_SUCCESS:"EVENT_DELETE_SUCCESS",
    // EVENT_DELETE_FAIL:"EVENT_DELETE_FAIL",

    // EVENT_CLEAR_ACTION:"EVENT_CLEAR_ACTION",

    // EVENT_FETCHING:"EVENT_FETCHING",
    // EVENT_FETCHING_SUCCESS:"EVENT_FETCHING_SUCCESS",
    // EVENT_FETCHING_FAIL:"EVENT_FETCHING_FAIL",

    // EVENT_GETTING_COUNT:"EVENT_GETTING_COUNT",
    // EVENT_GETTING_COUNT_SUCCESS:"EVENT_GETTING_COUNT_SUCCESS",
    // EVENT_GETTING_COUNT_FAIL:"EVENT_GETTING_COUNT_FAIL",

    // FETCHING_POST: "FETCHING_POST",
    // FETCHING_POST_SUCCESS: "FETCHING_POST_SUCCESS",
    // FETCHING_POST_FAIL: "FETCHING_POST_FAIL",

    // CREATING_POST: "CREATING_POST",
    // CREATING_POST_SUCCESS: "CREATING_POST_SUCCESS",
    // CREATING_POST_FAIL: "CREATING_POST_FAIL",

    // DELETING_POST: "DELETING_POST",
    // DELETING_POST_SUCCESS: "DELETING_POST_SUCCESS",
    // DELETING_POST_FAIL: "DELETING_POST_FAIL",

    // UPDATING_POST: "UPDATING_POST",
    // UPDATING_POST_SUCCESS: "UPDATING_POST_SUCCESS",
    // UPDATING_POST_FAIL: "UPDATING_POST_FAIL",

    // GETTING_POST_COUNT: "GETTING_POST_COUNT",
    // GETTING_POST_COUNT_SUCCESS: "GETTING_POST_COUNT_SUCCESS",
    // GETTING_POST_COUNT_FAIL: "GETTING_POST_COUNT_FAIL",

    // UPDATING_POSTS_SETTINGS: "UPDATING_POSTS_SETTINGS",
    // UPDATING_POSTS_SETTINGS_SUCCESS: "UPDATING_POSTS_SETTINGS_SUCCESS",
    // UPDATING_POSTS_SETTINGS_FAIL: "UPDATING_POSTS_SETTINGS_FAIL",

    // FETCHING_PERMISSIONS: "FETCHING_PERMISSIONS",
    // FETCHING_PERMISSIONS_SUCCESS: "FETCHING_PERMISSIONS_SUCCESS",
    // FETCHING_PERMISSIONS_FAIL: "FETCHING_PERMISSIONS_FAIL",

    FETCHING_ADMIN_DELETED_PRODUCTS: "FETCHING_ADMIN_DELETED_PRODUCTS",
    FETCHING_ADMIN_DELETED_PRODUCTS_SUCCESS: "FETCHING_ADMIN_DELETED_PRODUCTS_SUCCESS",
    FETCHING_ADMIN_DELETED_PRODUCTS_FAIL: "FETCHING_ADMIN_DELETED_PRODUCTS_FAIL",

    FETCHING_ADMIN_DELETED_PRODUCT_COUNT: "FETCHING_ADMIN_DELETED_PRODUCT_COUNT",
    FETCHING_ADMIN_DELETED_PRODUCT_COUNT_SUCCESS: "FETCHING_ADMIN_DELETED_PRODUCT_COUNT_SUCCESS",
    FETCHING_ADMIN_DELETED_PRODUCT_COUNT_FAIL: "FETCHING_ADMIN_DELETED_PRODUCT_COUNT_FAIL",

    CLEAR_DELETED_PRODUCTS: "CLEAR_DELETED_PRODUCTS",
    CLEAR_DELETED_PRODUCT_COUNT: "CLEAR_DELETED_PRODUCT_COUNT",

    CLEAR_ACTION: "CLEAR_ACTION",

    ADD_EXPERT_CONTRIBUTER : "ADD_EXPERT_CONTRIBUTER",
    ADD_EXPERT_CONTRIBUTER_SUCCESS : "ADD_EXPERT_CONTRIBUTER_SUCCESS",
    ADD_EXPERT_CONTRIBUTER_FAIL : "ADD_EXPERT_CONTRIBUTER_FAIL",
    REMOVE_EXPERT_CONTRIBUTER_SUCCESS : "REMOVE_EXPERT_CONTRIBUTER_SUCCESS",

    GET_ALL_EXPERT_CONTRIBUTER : "GET_ALL_EXPERT_CONTRIBUTER",
    GET_ALL_EXPERT_CONTRIBUTER_SUCCESS : "GET_ALL_EXPERT_CONTRIBUTER_SUCCESS",
    GET_ALL_EXPERT_CONTRIBUTER_FAIL : "GET_ALL_EXPERT_CONTRIBUTER_FAIL",

    GET_ALL_EXPERT_CONTRIBUTER_COUNT : "GET_ALL_EXPERT_CONTRIBUTER_COUNT",
    GET_ALL_EXPERT_CONTRIBUTER_COUNT_SUCCESS : "GET_ALL_EXPERT_CONTRIBUTER_COUNT_SUCCESS",
    GET_ALL_EXPERT_CONTRIBUTER_COUNT_FAIL : "GET_ALL_EXPERT_CONTRIBUTER_COUNT_FAIL",
    
    GET_MARKETPLACE_STATISTICS : "GET_MARKETPLACE_STATISTICS",
    GET_MARKETPLACE_STATISTICS_SUCCESS : "GET_MARKETPLACE_STATISTICS_SUCCESS",
    GET_MARKETPLACE_STATISTICS_FAIL : "GET_MARKETPLACE_STATISTICS_FAIL",

    GET_MARKETPLACE_STATISTICS_COUNT : "GET_MARKETPLACE_STATISTICS_COUNT",
    GET_MARKETPLACE_STATISTICS_COUNT_SUCCESS : "GET_MARKETPLACE_STATISTICS_COUNT_SUCCESS",
    GET_MARKETPLACE_STATISTICS_COUNT_FAIL : "GET_MARKETPLACE_STATISTICS_COUNT_FAIL",

    CLEAR_GET_STATISTICS_SEARCH: "CLEAR_GET_STATISTICS_SEARCH",
    GET_STATISTICS_SEARCH : "GET_STATISTICS_SEARCH",
    GET_STATISTICS_SEARCH_SUCCESS : "GET_STATISTICS_SEARCH_SUCCESS",
    GET_STATISTICS_SEARCH_FAIL: "GET_STATISTICS_SEARCH_FAIL",
    
    ADD_COUPON_DETAILS : "ADD_COUPON_DETAILS",
    ADD_COUPON_DETAILS_SUCCESS : "ADD_COUPON_DETAILS_SUCCESS",
    ADD_COUPON_DETAILS_FAIL : "ADD_COUPON_DETAILS_FAIL",

    GET_ALL_COUPON_DETAILS : "GET_ALL_COUPON_DETAILS",
    GET_ALL_COUPON_DETAILS_SUCCESS : "GET_ALL_COUPON_DETAILS_SUCCESS",
    GET_ALL_COUPON_DETAILS_FAIL : "GET_ALL_COUPON_DETAILS_FAIL",

    GET_ALL_COUPON_DETAILS_COUNT : "GET_ALL_COUPON_DETAILS_COUNT",
    GET_ALL_COUPON_DETAILS_COUNT_SUCCESS : "GET_ALL_COUPON_DETAILS_COUNT_SUCCESS",
    GET_ALL_COUPON_DETAILS_COUNT_FAIL : "GET_ALL_COUPON_DETAILS_COUNT_FAIL",

    CLEAR_MARKETPLACE_STATISTICS_AND_COUNT: "CLEAR_MARKETPLACE_STATISTICS_AND_COUNT",
    CLEAR_ALL_EXPERT_CONTRIBUTORS_AND_COUNT: "CLEAR_ALL_EXPERT_CONTRIBUTORS_AND_COUNT",
    CLEAR_ALL_COUPON_DETAILS_AND_COUNT: "CLEAR_ALL_COUPON_DETAILS_AND_COUNT",
    CLEAR_ALL_PRODUCTS_PRIORITIZE: "CLEAR_ALL_PRODUCTS_PRIORITIZE",

    FETCHING_PRODUCTS_FOR_PRIORITIZE: "FETCHING_PRODUCTS_FOR_PRIORITIZE",
    FETCH_PRODUCTS_FOR_PRIORITIZE_SUCCESS: "FETCH_PRODUCTS_FOR_PRIORITIZE_SUCCESS",
    FETCH_PRODUCTS_FOR_PRIORITIZE_FAIL: "FETCH_PRODUCTS_FOR_PRIORITIZE_FAIL",

    FETCHING_PUBLISHED_PRODUCTS_COUNT: "FETCHING_PUBLISHED_PRODUCTS_COUNT",
    FETCH_PUBLISHED_PRODUCTS_COUNT_SUCCESS: "FETCH_PUBLISHED_PRODUCTS_COUNT_SUCCESS",
    FETCH_PUBLISHED_PRODUCTS_COUNT_FAIL: "FETCH_PUBLISHED_PRODUCTS_COUNT_FAIL",

    SET_PRODUCTS_PRIORITY : "SET_PRODUCTS_PRIORITY",
    SET_PRODUCTS_PRIORITY_SUCCESS : "SET_PRODUCTS_PRIORITY_SUCCESS",
    SET_PRODUCTS_PRIORITY_FAIL : "SET_PRODUCTS_PRIORITY_FAIL",
    CLEAR_MARKETING_CAMPAIGN_STATISTICS_AND_COUNT: "CLEAR_MARKETING_CAMPAIGN_STATISTICS_AND_COUNT",
    CLEAR_GET_MARKETING_CAMPAIGN_SUMMARY_AND_COUNT: "CLEAR_GET_MARKETING_CAMPAIGN_SUMMARY_AND_COUNT",

    GET_MARKETING_CAMPAIGN_STATISTICS : "GET_MARKETING_CAMPAIGN_STATISTICS",
    GET_MARKETING_CAMPAIGN_STATISTICS_SUCCESS : "GET_MARKETING_CAMPAIGN_STATISTICS_SUCCESS",
    GET_MARKETING_CAMPAIGN_STATISTICS_FAIL : "GET_MARKETING_CAMPAIGN_STATISTICS_FAIL",

    GET_MARKETING_CAMPAIGN_STATISTICS_COUNT : "GET_MARKETING_CAMPAIGN_STATISTICS_COUNT",
    GET_MARKETING_CAMPAIGN_STATISTICS_COUNT_SUCCESS : "GET_MARKETING_CAMPAIGN_STATISTICS_COUNT_SUCCESS",
    GET_MARKETING_CAMPAIGN_STATISTICS_COUNT_FAIL: "GET_MARKETING_CAMPAIGN_STATISTICS_COUNT_FAIL",
    
    GET_MARKETING_CAMPAIGN_SUMMARY : "GET_MARKETING_CAMPAIGN_SUMMARY",
    GET_MARKETING_CAMPAIGN_SUMMARY_SUCCESS : "GET_MARKETING_CAMPAIGN_SUMMARY_SUCCESS",
    GET_MARKETING_CAMPAIGN_SUMMARY_FAIL : "GET_MARKETING_CAMPAIGN_SUMMARY_FAIL",

    GET_MARKETING_CAMPAIGN_SUMMARY_COUNT : "GET_MARKETING_CAMPAIGN_SUMMARY_COUNT",
    GET_MARKETING_CAMPAIGN_SUMMARY_COUNT_SUCCESS : "GET_MARKETING_CAMPAIGN_SUMMARY_COUNT_SUCCESS",
    GET_MARKETING_CAMPAIGN_SUMMARY_COUNT_FAIL: "GET_MARKETING_CAMPAIGN_SUMMARY_COUNT_FAIL",
    
    GET_NEWS_FEED_TEXT_LINK_SUMMARY : "GET_NEWS_FEED_TEXT_LINK_SUMMARY",
    GET_NEWS_FEED_TEXT_LINK_SUMMARY_SUCCESS : "GET_NEWS_FEED_TEXT_LINK_SUMMARY_SUCCESS",
    GET_NEWS_FEED_TEXT_LINK_SUMMARY_FAIL: "GET_NEWS_FEED_TEXT_LINK_SUMMARY_FAIL",
    
    GET_NEWS_FEED_TEXT_LINK_SUMMARY_COUNT : "GET_NEWS_FEED_TEXT_LINK_SUMMARY_COUNT",
    GET_NEWS_FEED_TEXT_LINK_SUMMARY_COUNT_SUCCESS : "GET_NEWS_FEED_TEXT_LINK_SUMMARY_COUNT_SUCCESS",
    GET_NEWS_FEED_TEXT_LINK_SUMMARY_COUNT_FAIL: "GET_NEWS_FEED_TEXT_LINK_SUMMARY_COUNT_FAIL",
    CLEAR_GET_NEWS_FEED_TEXT_LINK_SUMMARY_AND_COUNT: "CLEAR_GET_NEWS_FEED_TEXT_LINK_SUMMARY_AND_COUNT",

    ADD_REWARD_REDEMPTION : "ADD_REWARD_REDEMPTION",
    ADD_REWARD_REDEMPTION_SUCCESS : "ADD_REWARD_REDEMPTION_SUCCESS",
    ADD_REWARD_REDEMPTION_FAIL: "ADD_REWARD_REDEMPTION_FAIL",
    
    UPDATE_REWARD_REDEMPTION : "UPDATE_REWARD_REDEMPTION",
    UPDATE_REWARD_REDEMPTION_SUCCESS : "UPDATE_REWARD_REDEMPTION_SUCCESS",
    UPDATE_REWARD_REDEMPTION_FAIL : "UPDATE_REWARD_REDEMPTION_FAIL",

    GET_ALL_REWARD_REDEMPTION : "GET_ALL_REWARD_REDEMPTION",
    GET_ALL_REWARD_REDEMPTION_SUCCESS : "GET_ALL_REWARD_REDEMPTION_SUCCESS",
    GET_ALL_REWARD_REDEMPTION_FAIL : "GET_ALL_REWARD_REDEMPTION_FAIL",

    GET_ALL_REWARD_REDEMPTION_COUNT : "GET_ALL_REWARD_REDEMPTION_COUNT",
    GET_ALL_REWARD_REDEMPTION_COUNT_SUCCESS : "GET_ALL_REWARD_REDEMPTION_COUNT_SUCCESS",
    GET_ALL_REWARD_REDEMPTION_COUNT_FAIL: "GET_ALL_REWARD_REDEMPTION_COUNT_FAIL",
    
    CLEAR_ALL_REWARD_REDEMPTION_AND_COUNT: 'CLEAR_ALL_REWARD_REDEMPTION_AND_COUNT',
    
    UPDATE_EMAIL_DIET: "UPDATE_EMAIL_DIET",
    UPDATE_EMAIL_DIET_SUCCESS: "UPDATE_EMAIL_DIET_SUCCESS",
    UPDATE_EMAIL_DIET_FAIL: "UPDATE_EMAIL_DIET_FAIL",

    GET_EMAIL_CONFIG: "GET_EMAIL_CONFIG",
    GET_EMAIL_CONFIG_SUCCESS: "GET_EMAIL_CONFIG_SUCCESS",
    GET_EMAIL_CONFIG_FAIL: "GET_EMAIL_CONFIG_FAIL",

    GET_AUTO_CONNECTION_REQUESTS_STATISTICS : "GET_AUTO_CONNECTION_REQUESTS_STATISTICS",
    GET_AUTO_CONNECTION_REQUESTS_STATISTICS_SUCCESS : "GET_AUTO_CONNECTION_REQUESTS_STATISTICS_SUCCESS",
    GET_AUTO_CONNECTION_REQUESTS_STATISTICS_FAIL : "GET_AUTO_CONNECTION_REQUESTS_STATISTICS_FAIL",

    GET_AUTO_CONNECTION_REQUESTS_STATISTICS_COUNT : "GET_AUTO_CONNECTION_REQUESTS_STATISTICS_COUNT",
    GET_AUTO_CONNECTION_REQUESTS_STATISTICS_COUNT_SUCCESS : "GET_AUTO_CONNECTION_REQUESTS_STATISTICS_COUNT_SUCCESS",
    GET_AUTO_CONNECTION_REQUESTS_STATISTICS_COUNT_FAIL: "GGET_AUTO_CONNECTION_REQUESTS_STATISTICS_COUNT_FAIL",

    CLEAR_AUTO_CONNECTION_REQUESTS_STATISTICS_AND_COUNT: "CLEAR_AUTO_CONNECTION_REQUESTS_STATISTICS_AND_COUNT",

    UPDATING_EVENT_DISCOUNT:"UPDATING_EVENT_DISCOUNT",
    UPDATING_EVENT_DISCOUNT_SUCCESS:"UPDATING_EVENT_DISCOUNT_SUCCESS",
    UPDATING_EVENT_DISCOUNT_FAIL:"UPDATING_EVENT_DISCOUNT_FAIL",

}