import PaymentTypes from '../types/managePayments';


export const initialState = {
    fetching: false,
    isCardsFetching: false,
    action: 0,
    cards: [],
    isPaymentHistoryFetching: false,
    paymentHistory: [],
    isSubscriptionsFetching: false,
    subscriptions: [],
    subscriptionId: null,
    hostedPaymentFieldsToken: null,
    isPaymentFieldsTokenFetching: false,
    totalResults: 0,
    isCustomerCreating: false,
    isSubscribing: false,

    isCompanyPaymentGatewaysFetching: false,
    companyPaymentGateways: [],
    allCompanyCards: [],

    // single user
    SU_isPaymentGatewaysFetching: false,
    SU_paymentGateways: [],
    SU_cardsFetching: false,
    SU_cards: []
};

export const managePayment = (state = initialState, action) => {
    switch (action.type) {
        case PaymentTypes.CREATING_PAYMENT_FIELDS_TOKEN:
            return {
                ...state,
                isPaymentFieldsTokenFetching: true,
                hostedPaymentFieldsToken: null,
                action: PaymentTypes.CREATING_PAYMENT_FIELDS_TOKEN
            };
        case PaymentTypes.CREATE_PAYMENT_FIELDS_TOKEN_SUCCESS:
            return {
                ...state,
                hostedPaymentFieldsToken: action.payload.location.substring(action.payload.location.lastIndexOf('/') + 1),
                isPaymentFieldsTokenFetching: false,
                action: PaymentTypes.CREATE_PAYMENT_FIELDS_TOKEN_SUCCESS
            };
        case PaymentTypes.CREATE_PAYMENT_FIELDS_TOKEN_FAIL:
            return {
                ...state,
                hostedPaymentFieldsToken: null,
                isPaymentFieldsTokenFetching: false,
                action: PaymentTypes.CREATE_PAYMENT_FIELDS_TOKEN_FAIL
            };
        case PaymentTypes.CREATING_CUSTOMER:
            return {
                ...state,
                isCustomerCreating: true,
                action: PaymentTypes.CREATING_CUSTOMER
            };
        case PaymentTypes.CREATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                isCustomerCreating: false,
                action: PaymentTypes.CREATE_CUSTOMER_SUCCESS
            };
        case PaymentTypes.CREATE_CUSTOMER_FAIL:
            return {
                ...state,
                isCustomerCreating: false,
                action: PaymentTypes.CREATE_CUSTOMER_FAIL
            };

        case PaymentTypes.CREATING_SUBSCRIPTION:
            return {
                ...state,
                isSubscribing: true,
                action: PaymentTypes.CREATING_SUBSCRIPTION
            };
        case PaymentTypes.CREATE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                isSubscribing: false,
                action: PaymentTypes.CREATE_SUBSCRIPTION_SUCCESS
            };
        case PaymentTypes.CREATE_SUBSCRIPTION_FAIL:
            return {
                ...state,
                isSubscribing: false,
                action: PaymentTypes.CREATE_SUBSCRIPTION_FAIL
            };
        case PaymentTypes.FETCHING_COMPANY_PAYMENT_GATEWAYS:
            return {
                ...state,
                isCompanyPaymentGatewaysFetching: true,
                action: PaymentTypes.FETCHING_COMPANY_PAYMENT_GATEWAYS
            };

        case PaymentTypes.FETCH_COMPANY_PAYMENT_GATEWAYS_SUCCESS:
            return {
                ...state,
                isCompanyPaymentGatewaysFetching: false,
                companyPaymentGateways: action.payload.data,
                action: PaymentTypes.FETCH_COMPANY_PAYMENT_GATEWAYS_SUCCESS
            };
        case PaymentTypes.FETCH_COMPANY_PAYMENT_GATEWAYS_FAIL:
            return {
                ...state,
                isCompanyPaymentGatewaysFetching: false,
                action: PaymentTypes.FETCH_COMPANY_PAYMENT_GATEWAYS_FAIL
            };
        case PaymentTypes.RENEWING_SUBSCRIPTION:
            return {
                ...state,
                fetching: true,
                action: PaymentTypes.RENEWING_SUBSCRIPTION
            };
        case PaymentTypes.RENEW_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: PaymentTypes.RENEW_SUBSCRIPTION_SUCCESS
            };
        case PaymentTypes.RENEW_SUBSCRIPTION_FAIL:
            return {
                ...state,
                fetching: false,
                action: PaymentTypes.RENEW_SUBSCRIPTION_FAIL
            };
        case PaymentTypes.ATTACHING_NEW_CARD_TO_SUBSCRIPTION:
            return {
                ...state,
                fetching: true,
                action: PaymentTypes.ATTACHING_NEW_CARD_TO_SUBSCRIPTION
            };
        case PaymentTypes.ATTACHING_NEW_CARD_TO_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: PaymentTypes.ATTACHING_NEW_CARD_TO_SUBSCRIPTION_SUCCESS
            };
        case PaymentTypes.ATTACHING_NEW_CARD_TO_SUBSCRIPTION_FAIL:
            return {
                ...state,
                fetching: false,
                action: PaymentTypes.ATTACHING_NEW_CARD_TO_SUBSCRIPTION_FAIL
            };
        case PaymentTypes.FETCHING_CARDS_BY_COMPANY_ID:
            return {
                ...state,
                isCardsFetching: true,
                allCompanyCards: [],
                action: PaymentTypes.FETCHING_CARDS_BY_COMPANY_ID
            };
        case PaymentTypes.FETCHING_CARDS_BY_COMPANY_ID_SUCCESS:
            return {
                ...state,
                isCardsFetching: false,
                allCompanyCards: action.payload,
                action: PaymentTypes.FETCHING_CARDS_BY_COMPANY_ID_SUCCESS
            };
        case PaymentTypes.FETCHING_CARDS_BY_COMPANY_ID_FAIL:
            return {
                ...state,
                isCardsFetching: false,
                allCompanyCards: [],
                action: PaymentTypes.FETCHING_CARDS_BY_COMPANY_ID_FAIL
            };

        case PaymentTypes.FETCHING_CARDS:
            return {
                ...state,
                isCardsFetching: true,
                action: PaymentTypes.FETCHING_CARDS
            };
        case PaymentTypes.FETCH_CARDS_SUCCESS:
            return {
                ...state,
                isCardsFetching: false,
                action: PaymentTypes.FETCH_CARDS_SUCCESS,
                cards: action.payload.creditCardInfo
            };
        case PaymentTypes.FETCH_CARDS_FAIL:
            return {
                ...state,
                isCardsFetching: false,
                action: PaymentTypes.FETCH_CARDS_FAIL
            };
        case PaymentTypes.CLEAR_CARDS:
            return {
                ...state,
                isCardsFetching: false,
                action: PaymentTypes.CLEAR_CARDS,
                cards: [],
                allCompanyCards: []
            };

        case PaymentTypes.SETTING_DEFAULT_CARD:
            return {
                ...state,
                fetching: true,
                action: PaymentTypes.SETTING_DEFAULT_CARD
            };
        case PaymentTypes.SETTING_DEFAULT_CARD_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: PaymentTypes.SETTING_DEFAULT_CARD_SUCCESS
            };
        case PaymentTypes.SETTING_DEFAULT_CARD_FAIL:
            return {
                ...state,
                fetching: false,
                action: PaymentTypes.SETTING_DEFAULT_CARD_FAIL
            };

        case PaymentTypes.ADDING_NEW_CARD:
            return {
                ...state,
                fetching: true,
                action: PaymentTypes.ADDING_NEW_CARD
            };
        case PaymentTypes.ADDING_NEW_CARD_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: PaymentTypes.ADDING_NEW_CARD_SUCCESS,
                SU_cards: action.payload
            };
        case PaymentTypes.ADDING_NEW_CARD_FAIL:
            return {
                ...state,
                fetching: false,
                action: PaymentTypes.ADDING_NEW_CARD_FAIL
            };

        case PaymentTypes.DELETING_CARD:
            return {
                ...state,
                fetching: true,
                action: PaymentTypes.DELETING_CARD
            };
        case PaymentTypes.DELETE_CARD_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: PaymentTypes.DELETE_CARD_SUCCESS
            };
        case PaymentTypes.DELETE_CARD_FAIL:
            return {
                ...state,
                fetching: false,
                action: PaymentTypes.DELETE_CARD_FAIL
            };

        case PaymentTypes.FETCHING_PAYMENT_HISTORY:
            return {
                ...state,
                isPaymentHistoryFetching: true,
                action: PaymentTypes.FETCHING_PAYMENT_HISTORY,
                subscriptionId: action.subscriptionId,
            };
        case PaymentTypes.FETCH_PAYMENT_HISTORY_SUCCESS:
            return {
                ...state,
                isPaymentHistoryFetching: false,
                action: PaymentTypes.FETCH_PAYMENT_HISTORY_SUCCESS,
                paymentHistory: action.payload,
                subscriptionId: action.subscriptionId,
                totalResults: action.totalResults
            };
        case PaymentTypes.FETCH_PAYMENT_HISTORY_FAIL:
            return {
                ...state,
                isPaymentHistoryFetching: false,
                action: PaymentTypes.FETCH_PAYMENT_HISTORY_FAIL
            };
        case PaymentTypes.CLEAR_PAYMENT_HISTORY:
            return {
                ...state,
                isPaymentHistoryFetching: false,
                action: PaymentTypes.CLEAR_PAYMENT_HISTORY,
                paymentHistory: [],
                totalResults: 0,
                subscriptionId: null
            };

        case PaymentTypes.FETCHING_SUBSCRIPTIONS:
            return {
                ...state,
                isSubscriptionsFetching: true,
                action: PaymentTypes.FETCHING_SUBSCRIPTIONS
            };
        case PaymentTypes.FETCH_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                isSubscriptionsFetching: false,
                action: PaymentTypes.FETCH_SUBSCRIPTIONS_SUCCESS,
                subscriptions: action.payload.data || []
            };
        case PaymentTypes.FETCH_SUBSCRIPTIONS_FAIL:
            return {
                ...state,
                isSubscriptionsFetching: false,
                action: PaymentTypes.FETCH_SUBSCRIPTIONS_FAIL
            };
        case PaymentTypes.CLEAR_SUBSCRIPTIONS:
            return {
                ...state,
                isSubscriptionsFetching: false,
                action: PaymentTypes.CLEAR_SUBSCRIPTIONS,
                subscriptions: []
            };

        case PaymentTypes.CANCELING_SUBSCRIPTION:
            return {
                ...state,
                fetching: true,
                action: PaymentTypes.CANCELING_SUBSCRIPTION
            };
        case PaymentTypes.CANCEL_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: PaymentTypes.CANCEL_SUBSCRIPTION_SUCCESS
            };
        case PaymentTypes.CANCEL_SUBSCRIPTION_FAIL:
            return {
                ...state,
                fetching: false,
                action: PaymentTypes.CANCEL_SUBSCRIPTION_FAIL
            };

        case PaymentTypes.RE_ACTIVATING_SUBSCRIPTION:
            return {
                ...state,
                fetching: true,
                action: PaymentTypes.RE_ACTIVATING_SUBSCRIPTION
            };
        case PaymentTypes.RE_ACTIVATE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: PaymentTypes.RE_ACTIVATE_SUBSCRIPTION_SUCCESS
            };
        case PaymentTypes.RE_ACTIVATE_SUBSCRIPTION_FAIL:
            return {
                ...state,
                fetching: false,
                action: PaymentTypes.RE_ACTIVATE_SUBSCRIPTION_FAIL
            };

        // single user
        case PaymentTypes.FETCHING_USER_PAYMENT_GATEWAYS:
            return {
                ...state,
                SU_isPaymentGatewaysFetching: true,
                SU_paymentGateways: [],
                action: PaymentTypes.FETCHING_USER_PAYMENT_GATEWAYS
            };
        case PaymentTypes.FETCH_USER_PAYMENT_GATEWAYS_SUCCESS:
            return {
                ...state,
                SU_isPaymentGatewaysFetching: false,
                SU_paymentGateways: action.payload.data,
                action: PaymentTypes.FETCH_USER_PAYMENT_GATEWAYS_SUCCESS
            };
        case PaymentTypes.FETCH_USER_PAYMENT_GATEWAYS_FAIL:
            return {
                ...state,
                SU_isPaymentGatewaysFetching: false,
                action: PaymentTypes.FETCH_USER_PAYMENT_GATEWAYS_FAIL
            };
        case PaymentTypes.FETCHING_SHOPPER:
            return {
                ...state,
                SU_cards: [],
                SU_cardsFetching: true,
                action: PaymentTypes.FETCHING_SHOPPER
            };
        case PaymentTypes.FETCH_SHOPPER_SUCCESS:
            return {
                ...state,
                SU_cardsFetching: false,
                SU_cards: action.payload.creditCardInfo,
                action: PaymentTypes.FETCH_SHOPPER_SUCCESS
            };
        case PaymentTypes.FETCH_SHOPPER_FAIL:
            return {
                ...state,
                SU_cardsFetching: false,
                SU_cards: [],
                action: PaymentTypes.FETCH_SHOPPER_FAIL
            };
        case PaymentTypes.CLEAR_SHOPPER:
            return {
                ...state,
                SU_cards: [],
                action: PaymentTypes.CLEAR_SHOPPER
            };
        case PaymentTypes.CANCEL_JOB_BOARD_SUBSCRIPTION:
            return {
                ...state,
                action: PaymentTypes.CANCEL_JOB_BOARD_SUBSCRIPTION
            };
        case PaymentTypes.CANCEL_JOB_BOARD_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                action: PaymentTypes.CANCEL_JOB_BOARD_SUBSCRIPTION_SUCCESS
            };
        case PaymentTypes.CANCEL_JOB_BOARD_SUBSCRIPTION_FAIL:
            return {
                ...state,
                action: PaymentTypes.CANCEL_JOB_BOARD_SUBSCRIPTION_FAIL
            };
        case PaymentTypes.REACTIVATE_JOB_BOARD_SUBSCRIPTION:
            return {
                ...state,
                action: PaymentTypes.CANCEL_JOB_BOARD_SUBSCRIPTION
            };
        case PaymentTypes.REACTIVATE_JOB_BOARD_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                action: PaymentTypes.REACTIVATE_JOB_BOARD_SUBSCRIPTION_SUCCESS
            };
        case PaymentTypes.REACTIVATE_JOB_BOARD_SUBSCRIPTION_FAIL:
            return {
                ...state,
                action: PaymentTypes.REACTIVATE_JOB_BOARD_SUBSCRIPTION_FAIL
            };
        case PaymentTypes.GREENPAGES_PAYMENT_LOADING:
            return {
                ...state,
                action: PaymentTypes.GREENPAGES_PAYMENT_LOADING
            }
        case PaymentTypes.GREENPAGES_PAYMENT_SUCCESS:
            return {
                ...state,
                action: PaymentTypes.GREENPAGES_PAYMENT_SUCCESS
            }
        case PaymentTypes.GREENPAGES_PAYMENT_FAIL:
            return {
                ...state,
                action: PaymentTypes.GREENPAGES_PAYMENT_FAIL
            }

        case PaymentTypes.CLEAR_ACTION:
            return {
                ...state,
                fetching: false,
                action: 0,
            };
        default:
            return state
    }
}
