import dataRoomTypes from '../types/dataRoom';
import { DATAROOM_ACCESS_TYPE } from './../types/common';

export const initialState = {
    fetching: false,
    error: {},
    action: 0,
    message: null,
    isOpenUploadModal: false,
    isOpenEditModel: false,
    isOpenShareWithModel: false,
    isOpenVersionModal: false,
    isOpenVersionHistoryModal: false,
    fetchingVersionHistory: false,
    editDoc: null,
    document: {},
    documentHistory: [],
    dataRoomDocs: [],
    dataRoomDocCount: 0,
    dataRoomPermission: null,
    dataRoomRequestType: null,
    pendingDataRoomRequests: [],
    pendingDataRoomRequestCount: 0,
    sharedUsersDataRoomRequests: [],
    sharedUsersDataRoomRequestCount: 0,
    dataRoomActionUser: {
        type: 0,
        userId: null
    },
    statistics: {
        data: [],
        fetching: false,
        count: 0
    },
    searchDocs:[],
    fundRaisingActionUser: {
        type: 0,
        userId: null
    },
    isSubscribedToFundraising: false,
    isFundraisingSubscriptionExpired: false,
    documentNames:[]
};

export const dataRoom = (state = initialState, action) => {
    switch (action.type) {
        case dataRoomTypes.UPLOADING_DOCUMENTS:
            return {
                ...state,
                fetching: true,
                action: dataRoomTypes.UPLOADING_DOCUMENTS
            };
        case dataRoomTypes.UPLOADING_DOCUMENTS_DONE:
            return {
                ...state,
                fetching: false,
                action: dataRoomTypes.UPLOADING_DOCUMENTS_DONE
            };
        case dataRoomTypes.UPLOADING_DOCUMENTS_SUCCESS:
            return {
                ...state,
                action: dataRoomTypes.UPLOADING_DOCUMENTS_SUCCESS,
                message: action.payload.message
            };
        case dataRoomTypes.UPLOADING_DOCUMENTS_FAIL:
            return {
                ...state,
                fetching: false,
                action: dataRoomTypes.UPLOADING_DOCUMENTS_FAIL
            };
        case dataRoomTypes.DOCUMENT_DELETE_SUCCESS:
            return {
                ...state,
                action: dataRoomTypes.DOCUMENT_DELETE_SUCCESS,
                message: action.payload.message
            };
        case dataRoomTypes.DOCUMENT_DELETE_FAIL:
            return {
                ...state,
                fetching: false,
                action: dataRoomTypes.DOCUMENT_DELETE_FAIL
            };
        case dataRoomTypes.CLEAR_ACTION:
            return {
                ...state,
                fetching: false,
                action: 0
            };
        case dataRoomTypes.DOCUMENT_UPLOAD_MODAL:
            return {
                ...state,
                isOpenUploadModal: action.payload
            };
        case dataRoomTypes.ADD_NEW_VERSION_MODAL:
            return {
                ...state,
                isOpenVersionModal: action.payload.status,
                document: action.payload.document
            };
        case dataRoomTypes.VIEW_DOCUMENT_HISTORY:
            return {
                ...state,
                isOpenVersionHistoryModal: action.payload.status
            };
        case dataRoomTypes.CLEAR_DOCUMENT_HISTORY:
            return {
                ...state,
                action: dataRoomTypes.CLEAR_DOCUMENT_HISTORY,
                documentHistory: []
            };
        case dataRoomTypes.DOCUMENT_EDIT_MODAL:
            return {
                ...state,
                isOpenEditModel: action.payload,
                editDoc: action.docId
            };

        case dataRoomTypes.FETCHING_DATAROOM_DOCUMENTS:
            return {
                ...state,
                action: dataRoomTypes.FETCHING_DATAROOM_DOCUMENTS
            };
        case dataRoomTypes.FETCH_DATAROOM_DOCUMENTS_SUCCESS:
            return {
                ...state,
                action: dataRoomTypes.FETCH_DATAROOM_DOCUMENTS_SUCCESS,
                dataRoomDocs: action.reset ? action.payload.data : state.dataRoomDocs.concat(action.payload.data)
            };
        case dataRoomTypes.FETCH_DATAROOM_DOCUMENTS_FAIL:
            return {
                ...state,
                action: dataRoomTypes.FETCH_DATAROOM_DOCUMENTS_FAIL
            };
        case dataRoomTypes.CLEAR_DATAROOM_DOCS:
            return {
                ...state,
                action: dataRoomTypes.CLEAR_DATAROOM_DOCS,
                dataRoomDocs: []
            };

        case dataRoomTypes.FETCHING_DATAROOM_DOCUMENT_COUNT:
            return {
                ...state,
                action: dataRoomTypes.FETCHING_DATAROOM_DOCUMENT_COUNT
            };
        case dataRoomTypes.FETCH_DATAROOM_DOCUMENT_COUNT_SUCCESS:
            return {
                ...state,
                action: dataRoomTypes.FETCH_DATAROOM_DOCUMENT_COUNT_SUCCESS,
                dataRoomDocCount: action.payload.data
            };
        case dataRoomTypes.FETCH_DATAROOM_DOCUMENT_COUNT_FAIL:
            return {
                ...state,
                action: dataRoomTypes.FETCH_DATAROOM_DOCUMENT_COUNT_FAIL
            };
        case dataRoomTypes.CLEAR_DATAROOM_DOCUMENT_COUNT:
            return {
                ...state,
                action: dataRoomTypes.CLEAR_NEWS_FEED_COUNT,
                dataRoomDocCount: 0
            };
        case dataRoomTypes.EDIT_DATAROOM_DOC_SUCCESS:
            return {
                ...state,
                action: dataRoomTypes.EDIT_DATAROOM_DOC_SUCCESS
            };
        case dataRoomTypes.EDIT_DATAROOM_DOC_FAIL:
            return {
                ...state,
                action: dataRoomTypes.EDIT_DATAROOM_DOC_FAIL
            };

        case dataRoomTypes.FETCHING_DATAROOM_PERMISSION:
            return {
                ...state,
                action: dataRoomTypes.FETCHING_DATAROOM_PERMISSION
            };
        case dataRoomTypes.FETCH_DATAROOM_PERMISSION_SUCCESS:
        return {
                ...state,
                action: dataRoomTypes.FETCH_DATAROOM_PERMISSION_SUCCESS,
                dataRoomPermission: action.payload[0].AUTHORIZED_USERS[0].STATUS,
                dataRoomRequestType: action.payload[0].AUTHORIZED_USERS[0].REQUEST_TYPE,
            };
        case dataRoomTypes.CLEAR_DATAROOM_PERMISSIONS:
            return {
                ...state,
                action: dataRoomTypes.CLEAR_DATAROOM_PERMISSIONS,
                dataRoomPermission: null,
                dataRoomRequestType: null,
            };
        case dataRoomTypes.FETCH_DATAROOM_PERMISSION_FAIL:
            return {
                ...state,
                dataRoomPermission: null,
                action: dataRoomTypes.FETCH_DATAROOM_PERMISSION_FAIL
            };

        case dataRoomTypes.REQUESTING_DATAROOM_ACCESS:
            return {
                ...state,
                action: dataRoomTypes.REQUESTING_DATAROOM_ACCESS
            };
        case dataRoomTypes.REQUEST_DATAROOM_ACCESS_SUCCESS:
            return {
                ...state,
                dataRoomPermission: 3,
                dataRoomRequestType: 2,
                action: dataRoomTypes.REQUEST_DATAROOM_ACCESS_SUCCESS
            };
        case dataRoomTypes.REQUEST_DATAROOM_ACCESS_FAIL:
            return {
                ...state,
                action: dataRoomTypes.REQUEST_DATAROOM_ACCESS_FAIL
            };

        case dataRoomTypes.FETCHING_PENDING_DATAROOM_REQUESTS:
            return {
                ...state,
                action: dataRoomTypes.FETCHING_PENDING_DATAROOM_REQUESTS
            };
        case dataRoomTypes.FETCH_PENDING_DATAROOM_REQUESTS_SUCCESS:
            return {
                ...state,
                action: dataRoomTypes.FETCH_PENDING_DATAROOM_REQUESTS_SUCCESS,
                pendingDataRoomRequests: state.pendingDataRoomRequests.concat(action.payload.data)
            };
        case dataRoomTypes.FETCH_PENDING_DATAROOM_REQUESTS_FAIL:
            return {
                ...state,
                action: dataRoomTypes.FETCH_PENDING_DATAROOM_REQUESTS_FAIL
            };
        case dataRoomTypes.CLEAR_PENDING_DATAROOM_REQUESTS:
            return {
                ...state,
                action: dataRoomTypes.CLEAR_PENDING_DATAROOM_REQUESTS,
                pendingDataRoomRequests: []
            };

        case dataRoomTypes.ACCEPTING_DATAROOM_REQUEST:
            return {
                ...state,
                action: dataRoomTypes.ACCEPTING_DATAROOM_REQUEST
            };
        case dataRoomTypes.ACCEPT_DATAROOM_REQUEST_SUCCESS:
            return {
                ...state,
                action: dataRoomTypes.ACCEPT_DATAROOM_REQUEST_SUCCESS
            };
        case dataRoomTypes.ACCEPT_DATAROOM_REQUEST_FAIL:
            return {
                ...state,
                action: dataRoomTypes.ACCEPT_DATAROOM_REQUEST_FAIL
            };

        case dataRoomTypes.REJECTING_DATAROOM_REQUEST:
            return {
                ...state,
                action: dataRoomTypes.REJECTING_DATAROOM_REQUEST
            };
        case dataRoomTypes.REJECT_DATAROOM_REQUEST_SUCCESS:
            return {
                ...state,
                action: dataRoomTypes.REJECT_DATAROOM_REQUEST_SUCCESS
            };
        case dataRoomTypes.REJECT_DATAROOM_REQUEST_FAIL:
            return {
                ...state,
                action: dataRoomTypes.REJECT_DATAROOM_REQUEST_FAIL
            };

        case dataRoomTypes.FETCHING_PENDING_DATAROOM_REQUEST_COUNT:
            return {
                ...state,
                action: dataRoomTypes.FETCHING_PENDING_DATAROOM_REQUEST_COUNT
            };
        case dataRoomTypes.FETCH_PENDING_DATAROOM_REQUEST_COUNT_SUCCESS:
            return {
                ...state,
                action: dataRoomTypes.FETCH_PENDING_DATAROOM_REQUEST_COUNT_SUCCESS,
                pendingDataRoomRequestCount: action.payload.data.COUNT
            };
        case dataRoomTypes.FETCH_PENDING_DATAROOM_REQUEST_COUNT_FAIL:
            return {
                ...state,
                action: dataRoomTypes.FETCH_PENDING_DATAROOM_REQUEST_COUNT_FAIL
            };

        case dataRoomTypes.FETCHING_SHAREDUSERS_DATAROOM_REQUESTS:
            return {
                ...state,
                action: dataRoomTypes.FETCHING_SHAREDUSERS_DATAROOM_REQUESTS
            };
        case dataRoomTypes.FETCH_SHAREDUSERS_DATAROOM_REQUESTS_SUCCESS:
            return {
                ...state,
                action: dataRoomTypes.FETCH_SHAREDUSERS_DATAROOM_REQUESTS_SUCCESS,
                sharedUsersDataRoomRequests: action.reset ? action.payload.data : state.sharedUsersDataRoomRequests.concat(action.payload.data)
            };
        case dataRoomTypes.FETCH_SHAREDUSERS_DATAROOM_REQUESTS_FAIL:
            return {
                ...state,
                action: dataRoomTypes.FETCH_SHAREDUSERS_DATAROOM_REQUESTS_FAIL
            };
        case dataRoomTypes.FETCHING_SHAREDUSERS_DATAROOM_REQUEST_COUNT:
            return {
                ...state,
                action: dataRoomTypes.FETCHING_SHAREDUSERS_DATAROOM_REQUEST_COUNT
            };
        case dataRoomTypes.FETCH_SHAREDUSERS_DATAROOM_REQUEST_COUNT_SUCCESS:
            return {
                ...state,
                action: dataRoomTypes.FETCH_SHAREDUSERS_DATAROOM_REQUEST_COUNT_SUCCESS,
                sharedUsersDataRoomRequestCount: action.payload.data
            };
        case dataRoomTypes.FETCH_SHAREDUSERS_DATAROOM_REQUEST_COUNT_FAIL:
            return {
                ...state,
                action: dataRoomTypes.FETCH_SHAREDUSERS_DATAROOM_REQUEST_COUNT_FAIL
            };
        case dataRoomTypes.CLEAR_SEND_DATAROOM_INVITATION:
            return {
                ...state,
                action: dataRoomTypes.CLEAR_SEND_DATAROOM_INVITATION,
                sharedUsersDataRoomRequestCount: 0
            };
        case dataRoomTypes.CLEAR_DATAROOM_SHAREDUSERS:
            return {
                ...state,
                action: dataRoomTypes.CLEAR_DATAROOM_SHAREDUSERS,
                sharedUsersDataRoomRequests: []
            };
        case dataRoomTypes.REVOKE_DATAROOM_ACCESS_SUCCESS:
            return {
                ...state,
                action: dataRoomTypes.REVOKE_DATAROOM_ACCESS_SUCCESS,
                dataRoomActionUser: {
                    type: DATAROOM_ACCESS_TYPE.REVOKED,
                    userId: action.userId
                }
            };
        case dataRoomTypes.RE_GRANT_DATAROOM_ACCESS_SUCCESS:
            return {
                ...state,
                action: dataRoomTypes.RE_GRANT_DATAROOM_ACCESS_SUCCESS,
                dataRoomActionUser: {
                    type: DATAROOM_ACCESS_TYPE.REGRANTED,
                    userId: action.userId
                }
            };
        case dataRoomTypes.CLEAR_DATAROOM_USER_ACTION:
            return {
                ...state,
                action: dataRoomTypes.CLEAR_DATAROOM_USER_ACTION,
                dataRoomActionUser: {
                    type: 0,
                    userId: null
                }
            };
        case dataRoomTypes.SHAREWITH_MODAL:
            return {
                ...state,
                isOpenShareWithModel: action.payload,
            };
        case dataRoomTypes.SENDING_DATAROOM_INVITATION:
            return {
                ...state,
                action: dataRoomTypes.SENDING_DATAROOM_INVITATION
            };
        case dataRoomTypes.SEND_DATAROOM_INVITATION_SUCCESS:
            return {
                ...state,
                action: dataRoomTypes.SEND_DATAROOM_INVITATION_SUCCESS
            };
        case dataRoomTypes.CLEAR_RE_INVITED_DATAROOM_INVITATION:
            let reinvited = [];
            action.payload.forEach(inv => {
                reinvited.push(inv.USER_ID)
            });
            let newPendingRequests = state.pendingDataRoomRequests.map(investor => {
                if(reinvited.includes(investor._id)){
                    investor.REINVITED = true;
                    return investor
                }
                return investor
            });
            return {
                ...state,
                reInvitedInvestors: [],
                action: dataRoomTypes.FETCH_PENDING_DATAROOM_REQUESTS_SUCCESS,
                pendingDataRoomRequests: newPendingRequests
            };
        case dataRoomTypes.SEND_DATAROOM_INVITATION_FAIL:
            return {
                ...state,
                action: dataRoomTypes.SEND_DATAROOM_INVITATION_FAIL,
            };
        case dataRoomTypes.FETCHING_DATAROOM_DOCUMENT_HISTORY:
            return {
                ...state,
                action: dataRoomTypes.FETCHING_DATAROOM_DOCUMENT_HISTORY,
                fetchingVersionHistory: true
            }
        case dataRoomTypes.FETCHING_DATAROOM_DOCUMENT_HISTORY_SUCCESS:
            return {
                ...state,
                action: dataRoomTypes.FETCHING_DATAROOM_DOCUMENT_HISTORY_SUCCESS,
                documentHistory: action.payload,
                fetchingVersionHistory: false
            };
        case dataRoomTypes.FETCHING_DATAROOM_DOCUMENT_HISTORY_FAIL:
            return {
                ...state,
                action: dataRoomTypes.FETCHING_DATAROOM_DOCUMENT_HISTORY_FAIL,
                documentHistory: [],
                fetchingVersionHistory: false
        };
        case dataRoomTypes.FETCHING_STATISTICS:
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    fetching: true,
                    data: action.reset ? [] : state.statistics.data
                }
            };
        case dataRoomTypes.FETCHING_STATISTICS_SUCCESS:
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    fetching: false,
                    data: action.reset ? action.payload : state.statistics.data.concat(action.payload)
                }

            };
        case dataRoomTypes.FETCHING_STATISTICS_FAIL:
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    fetching: false,
                    data: []
                }
            };
        case dataRoomTypes.FETCHING_STATISTICS_COUNT:
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    fetching: true
                }
            };
        case dataRoomTypes.FETCHING_STATISTICS_COUNT_SUCCESS:
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    fetching: false,
                    count: action.payload
                }
            };
        case dataRoomTypes.FETCHING_STATISTICS_COUNT_FAIL:
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    fetching: false,
                    count: 0
                }
            };
        case dataRoomTypes.SEARCH_DATAROOM_DOCS_SUCCESS:
            let mainVersions = [];
            let versions = [];

            action.payload.forEach(item => {
                if(item.STATUS === 1){
                    mainVersions.push({ _id:item._id , DOCUMENT_NAME: (item.DOCUMENT_NAME+'')})
                }else{
                    if(versions[item.MAIN_VERSION]){
                        mainVersions.push({ _id:item._id , DOCUMENT_NAME: (item.DOCUMENT_NAME+' ( v'+ (versions[item.MAIN_VERSION].length+1) + ' )')});
                        versions[item.MAIN_VERSION].push( { _id:item._id , DOCUMENT_NAME: (item.DOCUMENT_NAME+' ( v'+ (versions[item.MAIN_VERSION].length+1) + ' )')});
                    }else{
                        mainVersions.push({ _id:item._id , DOCUMENT_NAME: (item.DOCUMENT_NAME+' ( v1 )')});
                        versions[item.MAIN_VERSION] = [{ _id:item._id , DOCUMENT_NAME: (item.DOCUMENT_NAME+' ( v1 )')}];
                    }
                }
            });

            return {
                ...state,
                searchDocs: mainVersions.reverse(),
                documentNames: action.payload.map(item => {return { _id:item._id , NAME: (item.DOCUMENT_NAME+'').toLowerCase()}}),
                action: dataRoomTypes.SEARCH_DATAROOM_DOCS_SUCCESS
            };

        case dataRoomTypes.SEARCH_DATAROOM_DOCS_FAIL:
            return {
                ...state,
                searchDocs: [],
                action: dataRoomTypes.SEARCH_DATAROOM_DOCS_FAIL
            };
        case dataRoomTypes.FUNDRAISING_SUBSCRIPTION_EXPIRED:
            return {
                ...state,
                fetching: false,
                isSubscribedToFundraising: false,
                isFundraisingSubscriptionExpired: true,
                action: dataRoomTypes.FUNDRAISING_SUBSCRIPTION_EXPIRED
            };
        case dataRoomTypes.SUBSCRIBED_TO_FUNDRAISING:
            return {
                ...state,
                fetching: false,
                isSubscribedToFundraising: true,
                isFundraisingSubscriptionExpired: false,
                action: dataRoomTypes.SUBSCRIBED_TO_FUNDRAISING
            };
        case dataRoomTypes.CLEAR_PENDING_DATAROOM_REQUEST_COUNT:
            return {
                ...state,
                action: dataRoomTypes.CLEAR_PENDING_DATAROOM_REQUEST_COUNT,
                pendingFundRaisingRequestCount: 0
            };

        case dataRoomTypes.CLEAR_FUND_RAISING_USER_ACTION:
            return {
                ...state,
                action: dataRoomTypes.CLEAR_FUND_RAISING_USER_ACTION,
                fundRaisingActionUser: {
                    type: 0,
                    userId: null
                }
            };
        case dataRoomTypes.CLEAR_DATAROOM_REDUCER:
            return {
                ...state,
                fetching: false,
                error: {},
                action: 0,
                message: null,
                isOpenUploadModal: false,
                isOpenEditModel: false,
                isOpenShareWithModel: false,
                isOpenVersionModal: false,
                isOpenVersionHistoryModal: false,
                fetchingVersionHistory: false,
                editDoc: null,
                document: {},
                documentHistory: [],
                dataRoomDocs: [],
                dataRoomDocCount: 0,
                dataRoomPermission: null,
                dataRoomRequestType: null,
                pendingDataRoomRequests: [],
                pendingDataRoomRequestCount: 0,
                sharedUsersDataRoomRequests: [],
                sharedUsersDataRoomRequestCount: 0,
                dataRoomActionUser: {
                    type: 0,
                    userId: null
                },
                statistics: {
                    data: [],
                    fetching: false,
                    count: 0
                },
                searchDocs:[],
                fundRaisingActionUser: {
                    type: 0,
                    userId: null
                },
                isSubscribedToFundraising: false,
                isFundraisingSubscriptionExpired: false,
                documentNames:[]
            }
        default:
            return state
    }
};