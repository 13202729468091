import React from "react";
import "./index.css";
import CompanyAvatar from "./../../../../assets/img/default-corporate-image.jpg";
import { ImageLoading } from "./../../loaders/imageLoader";
import classNames from "classnames";
// import { Link } from 'react-router-dom';

const FundingCompany = ({
    profile,
    height,
    width,
    title,
    onClick,
    redirectLink,
    imgSrc,
    boxShadow,
    fundingValue,
    isFundraising,
    isOnlyCompany
}) => {

    let avatarStyle = {
        backgroundImage: "url(" + imgSrc + ")",
        height: height + "px",
        width: width + "px"
    };
    let loaderBoxStyle = {
        height: height + "px",
        width: width + "px"
    };

    let display = null;
    let name = null;
    let media = classNames("media_item_box", { media_item_box_shdow: boxShadow });

    if (imgSrc === null) {
        display = (
            <div
                className="loader_box d-flex align-items-center justify-content-center"
                style={loaderBoxStyle}
            >
                <ImageLoading />
            </div>
        );
    } else if (profile === true) {
        display = (
            <div className={media} style={avatarStyle}>

            </div>
        );
    } else {
        display = <div className="media_item_box" />;
    }

    if (title) {
        name = (
            // <Link style={{ textDecoration: 'none' }} to={`/profile/${redirectLink}`}>
            <h3 className="text-center font_16 font_d_blue font_regular m-t15 ">
                {title}
            </h3>
            // </Link>
        );
    } else {
        name = null;
    }

    return (
        <div className="m-t10 m-b10 media_item_box_conten">
            {display}
            <div className='form-check' style={{ margin: 'inherit', textAlign: 'center' }}>
                <input
                    type='radio'
                    className='form-check-input'
                    id={'funrasingCompany' + fundingValue}
                    name='funrasingCompany'
                    defaultChecked={isFundraising || isOnlyCompany}
                    onChange={onClick}
                />
                <label style={{ marginTop: '-12px' }} className="form-check-label" htmlFor={'funrasingCompany' + fundingValue}>{name}</label>
            </div>
        </div>
    );
};

FundingCompany.defaultProps = {
    backgroundImage: null,
    height: "auto",
    width: "100%",
    title: null,
    profile: true,
    imgSrc: CompanyAvatar,
    boxShadow: false
};
export { FundingCompany };
