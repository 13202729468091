import React, { Component } from "react"

class ModalContentHeader extends Component {

    render(){
        return(
            <div className="modal-center-heade">           
            {this.props.children}           
          </div>
        )
    }

}
export { ModalContentHeader }