import React from "react";
import { connect } from 'react-redux';
import { Route } from "react-router-dom";

// import { ROUTES } from '../../config';
import {routeLogin} from './../../actions/route';

class ExternalRoutes extends React.Component {

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         loading: true,
    //         isAuthenticated: false,
    //         isBluesnapCustomer: false
    //     }
    // }

    // componentDidMount() {
    //     // 1 = Investor, 2 = Professional, 3 = Admin
    //     if(localStorage.getItem('token')){
    //         this.props.dispatch(AuthActions.initlogin(localStorage.getItem('token')));
    //         axios.post(`${ROUTES.USERDETAILS}`, {}, { headers: { Authorization: localStorage.getItem('token') } }).then(response => {
    //             if (response.data.code === 200) {
    //                 let user = response.data.data;
    //                 if (!user) { // user does not exisit
    //                     this.setState({
    //                         loading: false,
    //                         isAuthenticated: false,
    //                     });
    //                 } else { // invalid token or server authenication fail
    //                     setTimeout(() => {
    //                         this.setState({
    //                             loading: false,
    //                             isAuthenticated: true,
    //                         });
    //                     }, 2000);
    //                 }
    //             }
    //         }).catch((exception) => { // server does not response
    //             this.setState({
    //                 loading: false,
    //                 isAuthenticated: false,
    //             });
    //         });
    //     } else {
    //         setTimeout(() => {
    //             this.setState({
    //                 loading: false,
    //                 isAuthenticated: true,
    //             });
    //         }, 2000);
    //     }

    // }

    componentWillMount(){
        if(localStorage.getItem('token') && !this.props.route.isAuthenticated){
            this.props.dispatch(routeLogin(localStorage.getItem('token')));
        }
    }
    
    render() {
        const { component: Component, ...rest } = this.props;
        return (
            <Route {...rest} render={props => (
                <Component {...this.props} />
            )}
            />
        )
        // if (this.props.route.loading) { // wait until authenticating the user token
        //     return <Loading />;
        // } else {
        //     return (
        //         <Route {...rest} render={props => (
        //             <Component {...this.props} />
        //         )}
        //         />
        //     )
        // }
    }

};

export default connect(state => {
    return {
        state,
        route: state.route
    };

})(ExternalRoutes);