import _ from 'lodash';
import React from 'react';
import Geocode from "react-geocode";
import { ROUTES } from '../config';
import { THIRD_PARTY_URL, SIGNED_COOKIES_CF_URL } from '../config/server';
import { ALLOWED_TYPES, MARKETPLACE, EVENT_TYPE, ALERT, ERROR_MESSAGES, ALERT_TTL, NAVIGATION_PAGE } from "../types/common";
import { isURL } from '../libs/validation';
import { getCountryFromCode } from './textFormat';
import moment from 'moment';
import 'moment-duration-format';
import ToolTip from "./../components/common/tooltip";
import { Country } from '../types/country';
import { showAlert } from '../actions/alert';
import { post } from "../libs/apiConnector";
import { getCFObjURL, BUCKET } from './cfHelper';

const validator = require("validator");

export const errorHandler = (dispatch, error={}) => {

    try {
        if (error.response && error.response.status === ALERT.UNAUTORIZED) {
            // Request made and server responded
            showAlert(dispatch, ALERT.ERROR, ERROR_MESSAGES.UNAUTORIZED);
        } else if (error.response) {
            // Request made and server responded
            // showAlert(dispatch, ALERT.UNAVAILABLE, ERROR_MESSAGES.EXCEPTION_MESSAGE, 5000); removed to handle from webhelper.js
        } else if (error.request) {
            // The request was made but no response was received
            showAlert(dispatch, ALERT.ERROR, ERROR_MESSAGES.FORBIDDEN, ALERT_TTL.STAY_FIVE_SEC);
        } else if(error.message){
            // Something happened in setting up the request that triggered an Error
            // showAlert(dispatch, ALERT.UNAVAILABLE, ERROR_MESSAGES.EXCEPTION_MESSAGE, 5000); removed to handle from webhelper.js
        } else {
            console.error(error)
        }
    } catch (ex) {
        showAlert(dispatch, ALERT.ERROR, ex.message);
    }

}

// PASS FILE URL & COMPANY_ID TO OPEN FILE IN NEW TAB (AVOIDING POP_UP)
export const viewDocument = (fileURL, companyId, callback) => {
    const errorMsg = "ERROR OCCURED WHILE RETRIEVING THE FILE";
    const { GOOGLE_DOCS } = THIRD_PARTY_URL;

    try {
        let previewWindow = window.open("_blank");
        previewWindow.document.write("");

        const checkExtension = url =>
                    (url = url
                        .toLowerCase()
                        .substr(1 + url.lastIndexOf("/"))
                        .split("?")[0])
                        .split("#")[0]
                        .substr(url.lastIndexOf("."));

        const img = getCFObjURL(BUCKET.DATA_ROOM)(fileURL, companyId);
        if (img) {
            if (ALLOWED_TYPES.DOCS.indexOf(checkExtension(img)) > -1) {
                previewWindow.location.href = `${GOOGLE_DOCS}${encodeURIComponent(img)}`;
            } else {
                previewWindow.location.href = img;
            }
        } else {
            previewWindow.close();
        }
    } catch (err) {
        //error
        window.close();
        callback(errorMsg);
    }
}

export const calculateMarketpaceProductPrice = (count) => {
    if (count === 0) {
        return (0.00).toFixed(2);
    }

    return (((count - 1) * MARKETPLACE.PRICES.ADDITIONAL_ONE_CATEGORY) + MARKETPLACE.PRICES.ONE_CATEGORY).toFixed(2);
}

// Check Login Credentials are correct

export const signInValidator = (email, password) => {
    let error = { msg: null, status: true }
    if (!email || !password) {
        error.msg = 'Email or password fields cannot be blank.';
        error.status = false;
    } else {
        if (!validator.isEmail(email)) {
            error.msg = 'The email address that you have entered is invalid.';
            error.status = false;
            return error;
        }
        if (password.length < 6) {
            error.msg = 'The password that you have entered is incorrect.';
            error.status = false;
            return error;
        }
    }
    return error;
}

export const getTimeDifferent = (date, moment) => {
    const toDays = (1000 * 60 * 60 * 24);
    const msgCreatedDate = Date.parse(date) / toDays;
    const now = Date.now() / toDays;

    if ((Math.floor(now - msgCreatedDate)) === 0) {
        return moment(date, 'YYYY-MM-DD HH:mm:ss ZZ').format('hh:mm A');
    }
    return moment(date, 'YYYY-MM-DD HH:mm:ss ZZ').format('MMM DD, YYYY');
}


// Check the Extension of any file

export const checkExtension = url =>
    (url = url
        .toLowerCase()
        .substr(1 + url.lastIndexOf("/"))
        .split("?")[0])
        .split("#")[0]
        .substr(url.lastIndexOf("."));


// View MarketPlace Product
export function generateMarketPlaceViewUrl(fileName, companyId) {
    return new Promise((resolve, reject) => {
        const url = getCFObjURL(BUCKET.MARKET_PLACE)(fileName, companyId);
        if (url && isURL(url, { protocols: ['https'] }, SIGNED_COOKIES_CF_URL)) {
            resolve(url);
        } else {
            reject(false);
        }
    });
}

//Generate b64 data from any file
export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const getStateAbbrev = (state, country) => {
    if (country === 'US' && state) {
        switch (state.toUpperCase()) {
            case "ALABAMA": return "AL";
            case "ALASKA": return "AK";
            case "ARIZONA": return "AZ";
            case "ARKANSAS": return "AR";
            case "CALIFORNIA": return "CA";
            case "COLORADO": return "CO";
            case "CONNECTICUT": return "CT";
            case "DELAWARE": return "DE";
            case "DISTRICT OF COLUMBIA": return "DC";
            case "FLORIDA": return "FL";
            case "GEORGIA": return "GA";
            case "HAWAII": return "HI";
            case "IDAHO": return "ID";
            case "ILLINOIS": return "IL";
            case "INDIANA": return "IN";
            case "IOWA": return "IA";
            case "KANSAS": return "KS";
            case "KENTUCKY": return "KY";
            case "LOUISIANA": return "LA";
            case "MAINE": return "ME";
            case "MARYLAND": return "MD";
            case "MASSACHUSETTS": return "MA";
            case "MICHIGAN": return "MI";
            case "MINNESOTA": return "MN";
            case "MISSISSIPPI": return "MS";
            case "MISSOURI": return "MO";
            case "MONTANA": return "MT";
            case "NEBRASKA": return "NE";
            case "NEVADA": return "NV";
            case "NEW HAMPSHIRE": return "NH";
            case "NEW JERSEY": return "NJ";
            case "NEW MEXICO": return "NM";
            case "NEW YORK": return "NY";
            case "NORTH CAROLINA": return "NC";
            case "NORTH DAKOTA": return "ND";
            case "OHIO": return "OH";
            case "OKLAHOMA": return "OK";
            case "OREGON": return "OR";
            case "PENNSYLVANIA": return "PA";
            case "RHODE ISLAND": return "RI";
            case "SOUTH CAROLINA": return "SC";
            case "SOUTH DAKOTA": return "SD";
            case "TENNESSEE": return "TN";
            case "TEXAS": return "TX";
            case "UTAH": return "UT";
            case "VERMONT": return "VT";
            case "VIRGINIA": return "VA";
            case "WASHINGTON": return "WA";
            case "WEST VIRGINIA": return "WV";
            case "WISCONSIN": return "WI";
            case "WYOMING": return "WY";
            default: return state;
        }
    } else if (country === 'CA') {
        switch (state.toUpperCase()) {
            case "ALBERTA": return "AB";
            case "BRITISH COLUMBIA": return "BC";
            case "MANITOBA": return "MB";
            case "NEW BRUNSWICK": return "NB";
            case "NEWFOUNDLAND AND LABRADOR": return "NL";
            case "NORTHWEST TERRITORIES": return "NT";
            case "NOVA SCOTIA": return "NS";
            case "NUNAVUT": return "NU";
            case "ONTARIO": return "ON";
            case "PRINCE EDWARD ISLAND": return "PE";
            case "QUEBEC": return "QC";
            case "SASKATCHEWAN": return "SK";
            case "YUKON": return "YT";
            default: return state;
        }
    } else {
        return state;
    }
}


// get address
export const systemFormatAddress = (city = '', state = '', country = '') => {
    return (
        (city !== '' ? city : '') + ((((state !== '' || country !== '') && city !== '') && !(country === 'US' && state === '')) ? ', ' : '') + ((state !== '' && state !== null) ? getStateAbbrev(state, country) : '') + ((country !== '' && country !== 'US' && state !== '' && state !== null) ? ', ' : '') + ((country !== '' && country !== 'US') ? getCountryFromCode(country, true) !== '' ? getCountryFromCode(country, true) : country : '')
    )
}

const formatTime = (date, format) => moment().diff(moment(date), format);

const formatDuration = (format, desc) => (format === 0) ? 'a few seconds ago' : moment.duration(format, 'minutes').format(desc)
// get createdTime
export const getCreatedTime = (time = moment().toISOString()) => {
    return formatTime(time, 'minutes') < 60
        ? formatDuration(formatTime(time, 'minutes'), 'm [minutes] ago')
        : formatTime(time, 'minutes') >= 60 && formatTime(time, 'hours') <= 24
            ? formatDuration(formatTime(time, 'minutes'), 'h [hours] ago')
            : formatTime(time, 'hours') > 24 && formatTime(time, 'days') < 7
                ? formatDuration(formatTime(time, 'minutes'), 'd [days] ago')
                : moment(time).format("MMM DD [at] hh:mm A")
};

export const formatDate = (dateStr, format = 'MMM YYYY') => {
    try {
        return moment(dateStr).format(format);
    } catch (e) {
        console.error('Unable to format date');
        return false;
    }
};

// add views on click

export const addViewOnClick = async viewData => {
    try {
        const response = await post(ROUTES.ADD_VIEWS_COUNT_ON_CLICK, { viewData })
        if (response) return true
        else return false
    } catch (exception) {
        return false;
    }
} 


// add views

export const increaseView = async viewData => {
    try {
        const response = await post(ROUTES.INCREASE_VIEW, { viewData })
        if (response) {
            return true
        } else {
            return false
        }
    } catch (exception) {
        return false;
    }
} 

export const safeUrls = url => {
    try {
        return url.replace("http:", "https:")
    } catch (exception) {
        return undefined;
    }
} 

export const getRandomOneZero = () => {
    return Math.round(Math.random());
}

// like comment - helper
export const commentHandleLikeUnlike = (allPosts, postId, commentId, singlePost=false) => {
    let postIndex = null;
    let commentIndex = null;
    let postComments = null
    
    
    if (allPosts instanceof Array) {
        postComments = allPosts.filter((post, i) => {
            if (post._id === postId) {
                postIndex = i;
                return post;
            }
            return false
        })[0]
    } else {
        postComments = allPosts
    }

    if (!postComments) {
        return null
    }

    postComments = singlePost ? postComments.COMMENTS : postComments.POST_COMMENT
        
    const comment = postComments.filter((comment, i) => {
        if (comment._id === commentId) {
            commentIndex = i
            return comment;
        }
        return false
    })[0]

    
    if (comment) {
        return { comment, postIndex, commentIndex }
    } 
    return null;
}

// best available sortId/priorityNumber - pass all the existing priority ids as array
export const findBestSlot = (existingPriority) => {
    existingPriority.sort((a, b) => a-b);
    const allMissing = Array.from({length: existingPriority[existingPriority.length-1] - existingPriority[0] + 1}, 
                        (_, idx) => existingPriority[0] + idx).filter(elem => !existingPriority.includes(elem));
    return allMissing && (allMissing.length > 0) ? allMissing[0] : _.max(existingPriority) + 1
}

export const copyStringToClipboard = (str) => {
    try {

        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px'};
        document.body.appendChild(el);

        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
    } catch (ex) {
        console.error('error on copy to clipboard', ex)
    }
};

export const showTooltip = (toolTips, page, section) => {

    try {
        let show = '';
        if (toolTips && page && section && toolTips[page] && toolTips[page].length > 0) {
            toolTips[page].some(item => {
                if (item.SECTION === section && item.VISIBLE) {
                    show = <ToolTip tooltip={item}/>;
                    return true
                }
                return false
            });
        }
        return show
    } catch (ex) {
        console.error('tooltip', ex);
        return ''
    }
};

export const getEventPage = (url) => {
    if (url.includes('virtual-events')) {
        return EVENT_TYPE.LOCAL
    } else if (url.includes('global-events')) {
        return EVENT_TYPE.GLOBAL
    } else if (url.includes('all-events')) {
        return EVENT_TYPE.ALL
    }
    return 0
}

export const systemLocationFormater = (city = '', state = '', country = '') => {
    let location='';

    if (city && city.trim().length > 0) {
        location = location + city
    }
    if (state && state.trim().length > 0 && (country === 'US' || country === 'CA')) {
        if (city) {
            location = location + ', '
        }
        location = location + getStateAbbrev(state, country)
    }
    if (country && country.trim().length > 0 && (country !== 'US' && country !== 'CA')) {
        if (city || state) {
            location = location + ', '
        }
        location = location + Country[country]
    }
    return location
};

export const getRandomToken = () => {
    return `open_newsfeed_${Math.floor(100000 + Math.random() * 900000)}_${Date.now()}`;
};

export const getRandomFileName = () => {
    return `newsfeedvideo${Math.floor(100000 + Math.random() * 900000)}${Date.now()}`;
};

export const setCompanyPopupOpen = () => {
    if(!localStorage.getItem('companySurveyLastSeen')){
        let date = moment().toISOString();
        localStorage.setItem('companySurveyLastSeen', date)
    }
};

export const shouldShowCandidacyPopup = () => {
    if(localStorage.getItem('candidateSurveyRecordId') && localStorage.getItem('candidateSurveyStep')){
        return true
    } else if(localStorage.getItem('companySurveyLastSeen')){
        let companyPopupLastSeen = moment(localStorage.getItem('companySurveyLastSeen'));
        let v =moment().diff(companyPopupLastSeen, 'minutes');
        return v > 24*60
    }
    return true
};

export const shouldShowHiringPopup = () => { //if candidate popup partially completed return false
    return (!(
        localStorage.getItem('candidateSurveyRecordId')
        && localStorage.getItem('candidateSurveyStep')
    ));
};

export const isMobile = () => {
    return !(window.innerWidth > 991)
};

export function getFileExtension(filename) {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
}

export function pauseOtherVideos(vidId){ //current video id 
    try {
        let otherVideos = document.querySelectorAll('video')
          otherVideos.forEach((vid) => {
            if(vid.id !== vidId){
              vid.pause()
            }
          })
    } catch(ex){
        console.error(ex)
    }
}

export function isInViewport (elem) {
    var bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

export function lengthToCenter (elem) {
    let center = window.innerHeight / 2;
    let diffTop = Math.abs(center - elem.getBoundingClientRect().top)
    let diffBottom = Math.abs(center - elem.getBoundingClientRect().bottom)
    return diffTop < diffBottom ? diffTop : diffBottom
}

export function notifyVideoProcessStatus (postId, status) {
    let event = new CustomEvent("postVideoProcessStatus", { detail: { postId, status } });
    document.dispatchEvent(event)
}

export const getViewportHeight = () => {
    return window.innerHeight
}

export const getViewportWidth = () => {
    return window.innerWidth
}

export const getConnectionStatus = (data) => {
    try {
        let connectedType =0;
        const loggedInuserId = localStorage.getItem('userId');
        if(loggedInuserId === data._id){ //Logged user is the connection
            connectedType=4;
        }else if (data.FRIEND_STATUS!== null && data.FRIEND_STATUS.REQUESTER === loggedInuserId && data.FRIEND_STATUS.STATUS === 1) { // I'm the requester and request pending
            connectedType=1;
        } else if (data.FRIEND_STATUS!== null && data.FRIEND_STATUS.RECIPIENT === loggedInuserId && data.FRIEND_STATUS.STATUS === 1) { // I'm the recipient and request pending
            connectedType=2;
        } else if (data.FRIEND_STATUS!== null && data.FRIEND_STATUS.STATUS === 2) { // Already connected profile
            connectedType=3;
        } else if (data.FRIEND_STATUS!== null && data.FRIEND_STATUS.STATUS === 3) { // Connection Dis-connected
            connectedType=0;
        } else if (data.FRIEND_STATUS=== null || data.FRIEND_STATUS.STATUS === null) { // Not a friend
            connectedType=0;
        }
        return connectedType
    } catch (ex) {
        return 0
    }
}

export function notifyConnectionReaction (data) {
    let event = new CustomEvent("connectionReaction", { detail: data });
    document.dispatchEvent(event)
}

export function notifyConnectionRequestFail (uid) {
    let event = new CustomEvent("connectionRequestFail", { detail: {uid} });
    document.dispatchEvent(event)
}

export function isIOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export const openImChatWindow = (userId, userName, userImage, isProMessage=false) => {
    let event = new CustomEvent("openImChat", {
      detail: {
        value: userId,
        userId,
        label: userName,
        PHOTO: userImage,
        isProMessage,
      },
    });
    document.dispatchEvent(event)
}


export const getLocationLatLngFromAddress = async (address) => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_APIKEY);
    const response = await Geocode.fromAddress(address);
    if(response && response.results[0] && response.results[0].geometry.location) {
        const { lat, lng } = response.results[0].geometry.location;
        return { lat, lng }
    } else {
        return null;
    }
}

export const  getPreviousLocation = (path) => {
    let previousPage;
    if (path.includes('/message')) {
        previousPage = NAVIGATION_PAGE.MESSAGES
    }else if (path.includes('/filter-connections')) {
        previousPage = NAVIGATION_PAGE.FILTER_CONNECTIONS
    } else if (path.includes('/events/')) {
        previousPage = NAVIGATION_PAGE.EVENTS
    } else if (path.includes('/groups')) {
        previousPage = NAVIGATION_PAGE.GROUPS
    } else if (path === '/press/qa') {
        previousPage = NAVIGATION_PAGE.PRESS
    } else if (path.includes('/user-profile-edit')) {
        previousPage = NAVIGATION_PAGE.PERSONAL_PROFILE
    } else if (path.includes('/greenpages/')) {
        previousPage = NAVIGATION_PAGE.GREEN_PAGES
    } else if (path.includes('/training/')) {
        previousPage = NAVIGATION_PAGE.TRAINING
    } else if (path === '/') {
        previousPage = NAVIGATION_PAGE.HOME
    } else {
        previousPage = NAVIGATION_PAGE.DEFAULT
    }
    return previousPage;
};

export const  increasePressView = async (data) => {
    try {
        const res = await post(ROUTES.INCREASE_PRESS_VIEW_COUNT, data);
        console.log('res', res)
        if (res.data && res.data.code !== 200) {
            console.error('record press view failed', res.data)
        }
    } catch (ex) {
        console.error('record press view exception', ex)
    }
}


