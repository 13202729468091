module.exports = {

    FETCHING_TAGS: "FETCHING_TAGS",
    FETCHING_TAGS_SUCCESS: "FETCHING_TAGS_SUCCESS",
    FETCHING_TAGS_ERROR: "FETCHING_TAGS_ERROR",

    UPLOADING_PRODUCT_SHOWCASE: "UPLOADING_PRODUCT_SHOWCASE",
    UPLOADING_PRODUCT_SHOWCASE_SUCCESS: "UPLOADING_PRODUCT_SHOWCASE_SUCCESS",
    UPLOADING_PRODUCT_SHOWCASE_ERROR: "UPLOADING_PRODUCT_SHOWCASE_ERROR",



    FETCHING_MARKETPLACE_PRODUCT_CATEGORIES: "FETCHING_MARKETPLACE_PRODUCT_CATEGORIES",
    FETCH_MARKETPLACE_PRODUCT_CATEGORIES_SUCCESS: "FETCH_MARKETPLACE_PRODUCT_CATEGORIES_SUCCESS",
    FETCH_MARKETPLACE_PRODUCT_CATEGORIES_FAIL: "FETCH_MARKETPLACE_PRODUCT_CATEGORIES_FAIL",

    CREATING_PRODUCT_PROCESS: "CREATING_PRODUCT_PROCESS",

    VIEW_SINGLE_PRODUCT: "VIEW_SINGLE_PRODUCT",
    VIEW_SINGLE_PRODUCT_SUCCESS: "VIEW_SINGLE_PRODUCT_SUCCESS",
    VIEW_SINGLE_PRODUCT_FAIL: "VIEW_SINGLE_PRODUCT_FAIL",

    SINGLE_PRODUCT_COMPANY_IMAGE: "SINGLE_PRODUCT_COMPANY_IMAGE",
    SINGLE_PRODUCT_COMPANY_IMAGE_ALTERNATE: "SINGLE_PRODUCT_COMPANY_IMAGE_ALTERNATE",
    SINGLE_PRODUCT_COVER_IMAGE: "SINGLE_PRODUCT_COVER_IMAGE",
    SINGLE_PRODUCT_IMAGE1: "SINGLE_PRODUCT_IMAGE1",
    SINGLE_PRODUCT_IMAGE2: "SINGLE_PRODUCT_IMAGE2",

    FETCHING_MY_PRODUCTS: "FETCHING_MY_PRODUCTS",
    FETCHING_MY_PRODUCTS_SUCCESS: "FETCHING_MY_PRODUCTS_SUCCESS",
    FETCHING_MY_PRODUCTS_FAIL: "FETCHING_MY_PRODUCTS_FAIL",

    FETCHING_MY_PRODUCTS_COUNT: "FETCHING_MY_PRODUCTS_COUNT",
    FETCHING_MY_PRODUCTS_COUNT_SUCCESS: "FETCHING_MY_PRODUCTS_COUNT_SUCCESS",
    FETCHING_MY_PRODUCTS_COUNT_FAIL: "FETCHING_MY_PRODUCTS_COUNT_FAIL",

    CREATING_CUSTOMER: "CREATING_CUSTOMER",
    CREATE_CUSTOMER_SUCCESS: "CREATE_CUSTOMER_SUCCESS",
    CREATE_CUSTOMER_FAIL: "CREATE_CUSTOMER_FAIL",

    CREATING_PRODUCT_SUBSCRIPTION: "CREATING_PRODUCT_SUBSCRIPTION",
    CREATE_PRODUCT_SUBSCRIPTION_SUCCESS: "CREATE_PRODUCT_SUBSCRIPTION_SUCCESS",
    CREATE_PRODUCT_SUBSCRIPTION_FAIL: "CREATE_PRODUCT_SUBSCRIPTION_FAIL",

    DELETING_PRODUCT: "DELETING_PRODUCT",
    DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
    DELETE_PRODUCT_FAIL: "DELETE_PRODUCT_FAIL",

    UNPUBLISH_PRODUCT: "UNPUBLISH_PRODUCT",
    UNPUBLISH_PRODUCT_SUCCESS: "UNPUBLISH_PRODUCT_SUCCESS",
    UNPUBLISH_PRODUCT_FAIL: "UNPUBLISH_PRODUCT_FAIL",

    LIST_WIDGET_PRODUCTS: "LIST_WIDGET_PRODUCTS",
    LIST_WIDGET_PRODUCTS_SUCCESS: "LIST_WIDGET_PRODUCTS_SUCCESS",
    LIST_WIDGET_PRODUCTS_FAIL: "LIST_WIDGET_PRODUCTS_FAIL",

    CLEAR_WIDGET_PRODUCTS: "CLEAR_WIDGET_PRODUCTS",

    FETCH_MY_SINGLE_PRODUCT: "FETCH_MY_SINGLE_PRODUCT",
    FETCH_MY_SINGLE_PRODUCT_SUCCESS: "FETCH_MY_SINGLE_PRODUCT_SUCCESS",
    FETCH_MY_SINGLE_PRODUCT_FAIL: "FETCH_MY_SINGLE_PRODUCT_FAIL",

    CLEAR_MY_SINGLE_PRODUCT: 'CLEAR_MY_SINGLE_PRODUCT',

    FETCH_MY_SINGLE_PRODUCT_MEDIA_SUCCESS: "FETCH_MY_SINGLE_PRODUCT_MEDIA_SUCCESS",
    FETCH_MY_SINGLE_PRODUCT_IMAGE1_SUCCESS: "FETCH_MY_SINGLE_PRODUCT_IMAGE1_SUCCESS",
    FETCH_MY_SINGLE_PRODUCT_IMAGE2_SUCCESS: "FETCH_MY_SINGLE_PRODUCT_IMAGE2_SUCCESS",

    EDITING_PRODUCT: "EDITING_PRODUCT",
    EDIT_PRODUCT_SUCCESS: "EDIT_PRODUCT_SUCCESS",
    EDIT_PRODUCT_ERROR: "EDIT_PRODUCT_ERROR",
    FETCHING_PRODUCT_NAME_SUGGESTIONS: "FETCHING_PRODUCT_NAME_SUGGESTIONS",
    FETCHING_PRODUCT_NAME_SUGGESTIONS_SUCCESS: "FETCHING_PRODUCT_NAME_SUGGESTIONS_SUCCESS",
    FETCHING_PRODUCT_NAME_SUGGESTIONS_FAIL: "FETCHING_PRODUCT_NAME_SUGGESTIONS_FAIL",

    LISTING_COMPANIES: 'LISTING_COMPANIES',
    LISTING_COMPANIES_SUCCESS: "LISTING_COMPANIES_SUCCESS",
    LISTING_COMPANIES_FAIL: 'LISTING_COMPANIES_FAIL',

    FETCHING_PUBLIC_PRODUCTS: "FETCHING_PUBLIC_PRODUCTS",
    FETCHING_PUBLIC_PRODUCTS_SUCCESS: "FETCHING_PUBLIC_PRODUCTS_SUCCESS",
    FETCHING_PUBLIC_PRODUCTS_FAIL: "FETCHING_PUBLIC_PRODUCTS_FAIL",

    FETCHING_PUBLIC_PRODUCTS_COUNT: "FETCHING_PUBLIC_PRODUCTS_COUNT",
    FETCHING_PUBLIC_PRODUCTS_COUNT_SUCCESS: "FETCHING_PUBLIC_PRODUCTS_COUNT_SUCCESS",
    FETCHING_PUBLIC_PRODUCTS_COUNT_FAIL: "FETCHING_PUBLIC_PRODUCTS_COUNT_FAIL",

    FETCHING_LEAF_ADMIN_DELETED_PRODUCT_NAME_SUGGESTIONS: "FETCHING_LEAF_ADMIN_DELETED_PRODUCT_NAME_SUGGESTIONS",
    FETCHING_LEAF_ADMIN_DELETED_PRODUCT_NAME_SUGGESTIONS_SUCCESS: "FETCHING_LEAF_ADMIN_DELETED_PRODUCT_NAME_SUGGESTIONS_SUCCESS",
    FETCHING_LEAF_ADMIN_DELETED_PRODUCT_NAME_SUGGESTIONS_FAIL: "FETCHING_LEAF_ADMIN_DELETED_PRODUCT_NAME_SUGGESTIONS_FAIL",

    PREVIEW_PRODUCT: "PREVIEW_PRODUCT",
    CLEAR_PREVIEW: "CLEAR_PREVIEW",
    CHANGE_CATEGORIES: "CHANGE_CATEGORIES",

    FETCH_PRODUCT_LOC_COUNTRY: 'FETCH_PRODUCT_LOC_COUNTRY',
    FETCH_PRODUCT_LOC_COUNTRY_SUCCESS: 'FETCH_PRODUCT_LOC_COUNTRY_SUCCESS',
    FETCH_PRODUCT_LOC_COUNTRY_FAIL: 'FETCH_PRODUCT_LOC_COUNTRY_FAIL',

    FETCH_PRODUCT_LOC_STATE: 'FETCH_PRODUCT_LOC_STATE',
    FETCH_PRODUCT_LOC_STATE_SUCCESS: 'FETCH_PRODUCT_LOC_STATE_SUCCESS',
    FETCH_PRODUCT_LOC_STATE_FAIL: 'FETCH_PRODUCT_LOC_STATE_FAIL',

    FETCH_PRODUCT_LOC_CITY: 'FETCH_PRODUCT_LOC_CITY',
    FETCH_PRODUCT_LOC_CITY_SUCCESS: 'FETCH_PRODUCT_LOC_CITY_SUCCESS',
    FETCH_PRODUCT_LOC_CITY_FAIL: 'FETCH_PRODUCT_LOC_CITY_FAIL',

    UPDATE_TAGS: "UPDATE_TAGS",
    CLEAR_UPDATED_TAGS: "CLEAR_UPDATED_TAGS",

    CLEAR_MARKETPLACE_REDUCER: "CLEAR_MARKETPLACE_REDUCER",

    PRODUCT_IMAGE_ONE_UPLOAD: "PRODUCT_IMAGE_ONE_UPLOAD",
    PRODUCT_IMAGE_TWO_UPLOAD: "PRODUCT_IMAGE_TWO_UPLOAD",
    PRODUCT_IMAGE_THREE_UPLOAD: "PRODUCT_IMAGE_THREE_UPLOAD",
    PRODUCT_IMAGE_COVER_UPLOAD: "PRODUCT_IMAGE_COVER_UPLOAD",

    FETCH_MARKETPLACE_REPORTING_DASHBOARD_DETAILS : "FETCH_MARKETPLACE_REPORTING_DASHBOARD_DETAILS",
    FETCH_MARKETPLACE_REPORTING_DASHBOARD_DETAILS_SUCCESS : "FETCH_MARKETPLACE_REPORTING_DASHBOARD_DETAILS_SUCCESS",
    FETCH_MARKETPLACE_REPORTING_DASHBOARD_DETAILS_FAIL: "FETCH_MARKETPLACE_REPORTING_DASHBOARD_DETAILS_FAIL",
    
    FETCH_PROMO_TEXT: 'FETCH_PROMO_TEXTS',
    FETCH_PROMO_TEXT_SUCCESS: 'FETCH_PROMO_TEXT_SUCCESS',
    FETCH_PROMO_TEXT_FAIL: 'FETCH_PROMO_TEXT_FAIL',

    FETCH_PAYMENT_DETAILS: 'FETCH_PAYMENT_DETAILS',
    FETCH_PAYMENT_DETAILS_SUCCESS: 'FETCH_PAYMENT_DETAILS_SUCCESS',
    FETCH_PAYMENT_DETAILS_FAIL: 'FETCH_PAYMENT_DETAILS_FAIL',

    FETCHING_MARKETPLACE_PACKAGE_DESCRIPTION: "FETCHING_MARKETPLACE_PACKAGE_DESCRIPTION",
    FETCH_MARKETPLACE_PACKAGE_DESCRIPTION_SUCCESS: "FETCH_MARKETPLACE_PACKAGE_DESCRIPTION_SUCCESS",
    FETCH_MARKETPLACE_PACKAGE_DESCRIPTION_FAIL: "FETCH_MARKETPLACE_PACKAGE_DESCRIPTION_FAIL",

    REPLACE_PAYMENT_METHOD: 'REPLACE_PAYMENT_METHOD',

    CLEAR_ACTION: "CLEAR_ACTION",

};