module.exports = {

    CLEAR_ACTION: 'CLEAR_ACTION',
    SET_USER:"SET_USER",

    GET_CONVERSATION:"GET_CONVERSATION",
    GET_CONVERSATION_SUCCESS:"GET_CONVERSATION_SUCCESS",
    GET_CONVERSATION_FAIL:"GET_CONVERSATION_FAIL",

    SENDING_MESSAGE:"SENDING_MESSAGE",
    SEND_MESSAGE_SUCCESS:"SEND_MESSAGE_SUCCESS",
    SEND_MESSAGE_FAIL:"SEND_MESSAGE_FAIL",

    SHOW_MSG_NOTIFICATION_COUNT: 'SHOW_MSG_NOTIFICATION_COUNT',
    LOAD_CONVERSATIONS: "LOAD_CONVERSATIONS",
    LOAD_CONVERSATIONS_SUCCESS: "LOAD_CONVERSATIONS_SUCCESS",
    LOAD_CONVERSATIONS_FAIL: "LOAD_CONVERSATIONS_FAIL",

    SEND_MESSAGE_VALIDATION_ERROR:"SEND_MESSAGE_VALIDATION_ERROR",
    SEND_MESSAGE_EXCEPTION:"SEND_MESSAGE_EXCEPTION",

    FETCHING_MESSAGES: "FETCHING_MESSAGES",
    FETCHING_MESSAGES_SUCCESS: "FETCHING_MESSAGES_SUCCESS",
    FETCHING_MESSAGES_FAIL: "FETCHING_MESSAGES_FAIL",
    FETCHING_MESSAGES_EXCEPTION: "FETCHING_MESSAGES_EXCEPTION",
    FETCHING_MESSAGES_INVALID_REQUEST: "FETCHING_MESSAGES_INVALID_REQUEST",
    CLEAR_MESSAGES: "CLEAR_MESSAGES",

    MESSAGE_SEEN_SUCCESS: "MESSAGE_SEEN_SUCCESS",
    MESSAGE_SEEN_FAIL: "MESSAGE_SEEN_FAIL",
    MESSAGE_SEEN_EXCEPTION: "MESSAGE_SEEN_EXCEPTION",

    FETCHING_LATEST_UNSEEN_CONVERSATION: "FETCHING_LATEST_UNSEEN_CONVERSATION",
    FETCH_LATEST_UNSEEN_CONVERSATION_SUCCESS: "FETCH_LATEST_UNSEEN_CONVERSATION_SUCCESS",
    FETCH_LATEST_UNSEEN_CONVERSATION_FAIL: "FETCH_LATEST_UNSEEN_CONVERSATION_FAIL",
    FETCHING_UNSEEN_MESSAGE_COUNT: "FETCHING_UNSEEN_MESSAGE_COUNT",
    FETCH_UNSEEN_MESSAGE_COUNT_SUCCESS: "FETCH_UNSEEN_MESSAGE_COUNT_SUCCESS",
    FETCH_UNSEEN_MESSAGE_COUNT_FAIL: "FETCH_UNSEEN_MESSAGE_COUNT_FAIL",

    FETCHING_COMPANY_LATEST_UNSEEN_CONVERSATION: "FETCHING_COMPANY_LATEST_UNSEEN_CONVERSATION",
    FETCH_LATEST_COMPANY_UNSEEN_CONVERSATION_SUCCESS: "FETCH_LATEST_COMPANY_UNSEEN_CONVERSATION_SUCCESS",
    FETCH_LATEST_COMPANY_UNSEEN_CONVERSATION_FAIL: "FETCH_LATEST_COMPANY_UNSEEN_CONVERSATION_FAIL",
    CLEAR_LATEST_COMPANY_UNSEEN_CONVERSATION: "CLEAR_LATEST_COMPANY_UNSEEN_CONVERSATION",

    FETCHING_MESSAGE_COUNT: "FETCHING_MESSAGE_COUNT",
    FETCH_MESSAGE_COUNT_SUCCESS: "FETCH_MESSAGE_COUNT_SUCCESS",
    FETCH_MESSAGE_COUNT_FAIL: "FETCH_MESSAGE_COUNT_FAIL",
    FETCH_MESSAGE_COUNT_EXCEPTION: "FETCH_MESSAGE_COUNT_EXCEPTION",
    CLEAR_MESSAGE_COUNT: "CLEAR_MESSAGE_COUNT",

    FETCHING_PARTICIPANTS: "FETCHING_PARTICIPANTS",
    FETCH_PARTICIPANTS_SUCCESS: "FETCH_PARTICIPANTS_SUCCESS",
    FETCH_PARTICIPANTS_FAIL: "FETCH_PARTICIPANTS_FAIL",
    FETCH_PARTICIPANTS_EXCEPTION: "FETCH_PARTICIPANTS_EXCEPTION",
    GET_PARTICIPANTS_IMAGE_EXCEPTION: "GET_PARTICIPANTS_IMAGE_EXCEPTION",
    CLEAR_PARTICIPANTS: "CLEAR_PARTICIPANTS",

    FETCHING_RECIPIENT_CONNECTION_STATUS: "FETCHING_RECIPIENT_CONNECTION_STATUS",
    FETCH_RECIPIENT_CONNECTION_STATUS_SUCCESS: "FETCH_RECIPIENT_CONNECTION_STATUS_SUCCESS",
    FETCH_RECIPIENT_CONNECTION_STATUS_FAIL: "FETCH_RECIPIENT_CONNECTION_STATUS_FAIL",
    CLEAR_RECIPIENT_CONNECTION_STATUS: "CLEAR_RECIPIENT_CONNECTION_STATUS",

    CLEAR_CONVERSATIONS: 'CLEAR_CONVERSATIONS',

    ENABLE_DROP_DOWN: 'ENABLE_DROP_DOWN',
    SEARCHING_MY_CONNECTIONS : 'SEARCHING_MY_CONNECTIONS',
    SEARCHING_MY_CONNECTIONS_SUCCESS : 'SEARCHING_MY_CONNECTIONS_SUCCESS',
    SEARCHING_MY_CONNECTIONS_FAIL : 'SEARCHING_MY_CONNECTIONS_FAIL',
    SEARCHING_MY_CONNECTIONS_NOT_FOUND : 'SEARCHING_MY_CONNECTIONS_NOT_FOUND',
    SEARCHING_MY_CONNECTIONS_EXCEPTION: 'SEARCHING_MY_CONNECTIONS_EXCEPTION',
    CLEAR_MY_CONNECTIONS: 'CLEAR_MY_CONNECTIONS',

    SEARCH_CONVERSATIONS: "SEARCH_CONVERSATIONS",
    SEARCH_CONVERSATIONS_SUCCESS: "SEARCH_CONVERSATIONS_SUCCESS",
    SEARCH_CONVERSATIONS_FAIL: "SEARCH_CONVERSATIONS_FAIL",
    CLEAR_SEARCH_CONVERSATIONS: 'CLEAR_SEARCH_CONVERSATIONS',

    SEARCH_USERS_FOR_CONVERSATION: 'SEARCH_USERS_FOR_CONVERSATION',
    SEARCH_USERS_FOR_CONVERSATION_SUCCESS: 'SEARCH_USERS_FOR_CONVERSATION_SUCCESS',
    SEARCH_USERS_FOR_CONVERSATION_FAIL: 'SEARCH_USERS_FOR_CONVERSATION_FAIL',
    CLEAR_SEARCH_USERS_FOR_CONVERSATION: 'CLEAR_SEARCH_USERS_FOR_CONVERSATION',

    SEARCHING_USER_COUNT: 'SEARCHING_USER_COUNT',
    SEARCH_USER_COUNT_SUCCESS: 'SEARCH_USER_COUNT_SUCCESS',
    SEARCH_USER_COUNT_SUCCESS_FAIL: 'SEARCH_USER_COUNT_SUCCESS_FAIL',
    CLEAR_SEARCH_USER_COUNT: 'CLEAR_SEARCH_USER_COUNT',

    FETCHING_CONVERSATION_COUNT: "FETCHING_CONVERSATION_COUNT",
    FETCHING_CONVERSATION_COUNT_SUCCESS: "FETCHING_CONVERSATION_COUNT_SUCCESS",
    FETCHING_CONVERSATION_COUNT_FAIL: "FETCHING_CONVERSATION_COUNT_FAIL"

};