import React from 'react'


const Container = ({ children, fullscreen, normal = false, customClass = null }) => {
    let className = fullscreen === true ? "container-fluid" : `container ${!normal ? 'm-b30' : ''}`
    className = customClass ? className + " " + customClass : className

    return (
        <div className={className}>{children}</div>
    )
}

Container.defaultProps = {
    fullscreen: null
}

export { Container }