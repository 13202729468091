import React from "react";

const TabContent = ({ children }) => {
        return (
            <div className="tab-content">
                {children}
            </div>
        );
}

export { TabContent };